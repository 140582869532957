import _get from "lodash/get";
import * as actionTypes from "./actions";

export default function notifications(state = {}, action) {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATION:
      const notifications = state[action.quizId] || [];
      return Object.assign({}, state, {
        [action.quizId]: {
          ...notifications,
          [action.itemId]: {
            id: action.notificationId,
            error: action.error,
            message: action.data
          }
        }

        /*               id: action.notificationId,
            itemId: action.itemId,
            error: action.error,
            message: action.data
 */
      });
    case actionTypes.UNSET_NOTIFICATION:
      const newNotifications = (state[action.quizId] || []).filter(
        n => n.id !== action.notificationId
      );
      return Object.assign({}, state, {
        [action.quizId]: [...newNotifications]
      });
    default:
      return state;
  }
}
