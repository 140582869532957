// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  "globals.locale": "fr",
  "globals.signIn": "Se connecter",
  "globals.logIn": "Se connecter",
  "globals.signUp": "S’inscrire",
  "globals.signOut": "Se déconnecter",
  "globals.logOut": "Se déconnecter",
  "globals.submit": "Soumettre",
  "globals.submitting": "En train de soumettre...",
  "globals.yourEmail": "Votre courriel",
  "globals.yourPassword": "Votre mot de passe",
  "globals.courseOverview": "Aperçu du cours",
  "globals.forgotPassword": "Mot de passe oublié ?",
  "globals.invalidCredentials": "Références non valides",
  "globals.section": "Section",
  "globals.exercises": "Exercices",
  "globals.chapter": "Chapitre",
  "globals.introToAI": "Introduction à l’IA",
  "globals.buildingAI": "Construire l’IA",
  'globals.acceptPolicy': 'Accept policy',
  // section
  "section.footerEndOfTheChapter": "Vous avez atteint la fin du chapitre",
  "section.footerEndOfTheCourseTitle": "Vous avez atteint la fin du cours !",
  "section.footerEndOfTheCourseText": "Une fois que vous aurez terminé tous les exercices et les évaluations par les pairs, vos réponses seront évaluées par notre personnel.",
  "section.nextSection": "Section suivante",
  "section.nextChapter": "Chapitre suivant",
  "section.start": "Commencer",
  "section.logisticRegressionExample.slope": "Pente",
  "section.logisticRegressionExample.intercept": "Intercepter",
  "section.footerEndOfTheCourseBAI": "Continuez votre chemin pour pouvoir mettre en œuvre votre première idée d’IA.",
  // quiz peer review
  "quiz.awaitingPeerReview": "En attente de votre évaluation",
  "quiz.rejectedPeerReview": "Rejeté lors de l’évaluation par les pairs",
  "quiz.unsubmittedAnswer": "Réponse non soumise",
  "quiz.pendingPeerReview": "En attente de l’évaluation par les pairs",
  // quiz
  "quiz.status.answered": "Réponse reçue",
  "quiz.status.rejected": "Réponse rejetée, réessayer",
  "quiz.status.unanswered": "Sans réponse",
  "quiz.status.exercisesCompleted": "Exercices complétés",
  "quiz.status.correctAnswers": "Réponses correctes",
  "quiz.message.rejectedInReview": "Votre réponse précédente a été refusée en évaluation. Veuillez réessayer.",
  "quiz.youAnswered": "Vous avez répondu",
  "quiz.yourAnswer": "Votre réponse...",
  "quiz.yourScore": "Votre résultat :",
  "quiz.quizNotLoaded": "Impossible de charger le quiz",
  "quiz.words": "Mots:",
  "quiz.exampleAnswer": "Exemple de réponse :",
  // peer review
  "peerreview.question1": "La réponse porte sur le sujet",
  "peerreview.question2": "La réponse est détaillée",
  "peerreview.question3": "La réponse est bien argumentée",
  "peerreview.question4": "La réponse est facile à suivre",
  "peerreview.thankYou": "Merci d’avoir évalué vos pairs !",
  "peerreview.giveMoreReviews": "Si vous avez du temps à votre disposition,",
  "peerreview.giveMoreReviewsButton": "donner plus d’évaluations",
  "peerreview.notEnoughAnswers": "Normalement, il devrait y avoir une évaluation par les pairs ici, mais un nombre insuffisant d’autres personnes ont répondu à cet exercice.",
  "peerreview.selectAnswerMessage": "Il est temps de procéder à une évaluation par les pairs ! Vous trouverez ci-dessous quelques réponses d’autres étudiants. Sélectionnez l’une d’entre elles et évaluez-la. Répétez au moins trois fois.",
  "peerreview.minimumPeerReviewWarning": "L’exercice ne peut être accepté avant que vous avez donné au moins trois évaluations par les pairs.",
  "peerreview.select": "Sélectionner",
  "peerreview.reportSpam": "Signaler un spam",
  "peerreview.feedback": "Votre réponse a été évaluée et acceptée. Voici ce que vos pairs en ont pensé :",
  // exercise17
  "quiz.exercise17.xLabel": "Années de scolarité",
  "quiz.exercise17.yLabel": "Espérance de vie en années",
  "quiz.exercise17.textPart1": "Étudions le lien entre le nombre total d’années passées à l’école (y compris tout ce qui se passe entre la maternelle et l’université) et l’espérance de vie. Voici les données de trois pays différents, présentées dans un schéma et représentées par des points :",
  "quiz.exercise17.textPart2": "Nous avons un pays où le nombre moyen d’années de scolarité est de 10 et l’espérance de vie de 57 ans, un autre pays où le nombre moyen d’années de scolarité est de 13 et l’espérance de vie de 53 ans, et un troisième pays où le nombre moyen d’années de scolarité est de 20 et l’espérance de vie de 80 ans.",
  "quiz.exercise17.textPart3": "Vous pouvez faire glisser les points d’extrémité de la ligne continue pour positionner celle-ci de manière qu’elle suive la tendance des points représentant les données. Notez que vous ne pourrez pas faire en sorte que la ligne s’adapte parfaitement aux points de données, et c’est très bien ainsi : certains points de données se trouveront au-dessus de la ligne, et d’autres en dessous. La chose la plus importante est que la ligne décrit la tendance générale.",
  "quiz.exercise17.textPart4": "Après avoir positionné la ligne, vous pouvez l’utiliser pour prédire l’espérance de vie.",
  "quiz.exercise17.textPart5": "Au vu de ces données, que pouvez-vous dire quant à l’espérance de vie des personnes qui ont 15 ans d’études ? Important: notez que même si, en ajustant la ligne, vous pouvez obtenir une prévision spécifique, jusqu’à une fraction d’année, vous ne serez pas nécessairement en mesure de donner une prévision fiable. Tenez compte de la quantité limitée de données lorsque vous donnez votre réponse.",
  // exercise18
  "quiz.exercise18.xLabel": "Années de scolarité",
  "quiz.exercise18.yLabel": "Espérance de vie en années",
  "quiz.exercise18.textPart1": "Lors de l’exercice précédent, nous ne disposions de données que pour trois pays. L’ensemble complet des données est constitué de données provenant de 14 pays différents, présentées ici dans un graphique :",
  "quiz.exercise18.textPart2": "Sur la base de ces données, votre prédiction concernant l’espérance de vie des personnes ayant 15 ans de scolarité serait-elle modifiée ? Si oui, pourquoi ?",
  "quiz.exercise18.textPart3": "Parmi les options suivantes, laquelle correspondrait le mieux à votre estimation de l’espérance de vie des personnes ayant 15 ans de scolarité ? Choisissez l’option la plus spécifique qui vous semble justifiée en adaptant le modèle en ligne droite aux données ci-dessus.",
  // exercise19
  "quiz.exercise19.xLabel": "Heures d’étude",
  "quiz.exercise19.yLabel": "Probabilité de réussite",
  "quiz.exercise19.textPart1": "Chaque point sur la figure correspond à un élève. En bas de la figure, nous avons l’échelle du nombre d’heures consacrées par l’étudiant à l’examen. Les étudiants qui ont réussi l’examen sont indiqués par des points en haut du tableau, et ceux qui ont échoué sont indiqués en bas. Nous utiliserons l’échelle à gauche pour indiquer la probabilité prévue de réussite, que nous obtiendrons à partir du modèle de régression logistique comme nous l’expliquons ci-dessous. Sur la base de ce graphique, vous pouvez voir en gros que les étudiants qui ont consacré plus de temps à leurs études ont de meilleures chances de réussir le cours. Les cas extrêmes sont particulièrement intuitifs: avec moins d’une heure de travail, il est très difficile de réussir le cours, mais avec beaucoup de travail, la plupart y parviendront. Mais qu’en est-il de ceux qui passent du temps à étudier quelque part entre les deux extrêmes ? Si vous étudiez pendant 6 heures, quelles sont vos probabilités de réussite ?",
  "quiz.exercise19.textPart2": "Nous pouvons quantifier la probabilité de réussite à l’aide de la régression logistique. La courbe de la figure peut être interprétée comme la probabilité de réussite: par exemple, après avoir étudié pendant cinq heures, la probabilité de réussite est d’un peu plus de 20%. Nous n’entrerons pas dans les détails sur la façon d’obtenir la courbe, mais elle sera similaire à la façon dont nous apprenons les poids dans la régression linéaire.",
  "quiz.exercise19.textPart3": "Si vous vouliez avoir une probabilité de 80 % de réussir un examen universitaire, sur la base du graphique ci-dessus, pour combien d’heures environ devriez-vous étudier ?",
  "quiz.exercise19.textPart4": " ",
  // exercise22
  "quiz.exercise22.sigmoidXLabel": "Entrée",
  "quiz.exercise22.sigmoidYLabel": "Sortie Sigmoïde",
  "quiz.exercise22.stepXLabel": "Entrée",
  "quiz.exercise22.stepYLabel": "Sortie de la fonction d’étape",
  "quiz.exercise22.identityXLabel": "Entrée",
  "quiz.exercise22.identityYLabel": "Sortie d’identité",
  "quiz.exercise22.textPart1": "Vous trouverez ci-dessous des graphiques pour trois fonctions d’activation différentes avec des caractéristiques différentes. Nous avons d’abord la fonction sigmoïde, puis la fonction étape, et enfin la fonction identité.",
  // exercise23
  "quiz.exercise23.happyFaces": "visages heureux classés correctement",
  "quiz.exercise23.sadFaces": "visages tristes classés correctement",
  // certificate availability
  "certificate.failedToCheck": "Nous n’avons pas réussi à vérifier la disponibilité du certificat",
  "certificate.failedToGenerate": "Nous n’avons pas réussi à créer le certificat",
  "certificate.notYetAvailable": "Votre certificat sera disponible ici après confirmation officielle de notre part. Cela peut prendre un certain temps. Merci de bien vouloir patienter !",
  "certificate.updateName": "Votre certificat est disponible ! Veuillez mettre à jour vos nom et prénom pour qu'ils apparaissent sur votre certificat en utilisant un formulaire disponible sur votre page de profil.",
  "certificate.generate": "Créer votre certificat ci-dessous.",
  "certificate.generateTextPart1": "Le certificat sera créé pour",
  "certificate.generateTextPart2": "Veuillez vérifier que cela est correct avant la création du certificat, car le nom ne peut être modifié par la suite. Si vous souhaitez changer de nom, vous pouvez le faire à l’aide du formulaire ci-dessous.",
  "certificate.generateButton": "Créer votre certificat",
  "certificate.downloadButton": "Télécharger le certificat",
  "certificate.checkingAvailability": "Vérification de la disponibilité du certificat en cours...",
  // Visualizations
  "visualizations.logisticRegressionChart.classifiedCorrectly": "Classement correct",
  "visualizations.logisticRegressionChart.notClassifiedCorrectly": "Classement incorrect",
  // section blocks
  "section.block.note": "Note",
  "section.block.example": "Exemple",
  "section.block.keyTerminology": "Terminologie clé",
  "section.partSummary.spectrumTextPart1": "Nous vous invitons à rejoindre ",
  "section.partSummary.spectrum": "la communauté «Elements of AI»",
  "section.partSummary.spectrumTextPart2": " pour discuter et poser des questions sur ce chapitre.",
  "section.partSummary.feedbackTitle": "Donnez-nous votre avis sur cette partie",
  "section.partSummary.feedbackPlaceholder": "Ecrivez vos commentaires ici...",
  "section.partSummary.feedbackSuccess": "Merci pour vos commentaires !",
  // 404
  "globals.404.title": "404 - Page introuvable",
  "globals.404.intro": "Vous venez de prendre un chemin qui n’existe pas... quelle tristesse !",
  "globals.loading": "Chargement en cours...",
  // footer / menu
  "globals.menu": "Menu",
  "globals.menu.about": "A propos",
  "globals.menu.faq": "FAQ",
  "globals.menu.privacyPolicy": "Politique de confidentialité",
  "globals.menu.myProfile": "Mon profil",
  "globals.menu.termsofService": "Conditions d’utilisation",
  // Dashboard/Index
  "dashboard.title": "Bienvenue au cours !",
  "dashboard.congratulations.title": "Félicitations !",
  "dashboard.congratulations.message": "Vous avez terminé le cours «Elements of AI»",
  "dashboard.congratulations.downloadCertificate": "Télécharger le certificat",
  "dashboard.notLoggedIn.title": "Bienvenue !",
  "dashboard.notLoggedIn.message": "Inscrivez-vous ou connectez-vous pour obtenir un accès complet au cours. C’est gratuit !",
  // Account
  "account.certificate": "Certificat",
  "account.credits": "crédits ECTS",
  "account.myProfile": "Mon profil",
  "account.changePassword": "Changer le mot de passe",
  "account.changeLanguage": "Changer de langue",
  "account.profileInformation": "Informations sur le profil",
  "account.goToCourse": "Aller au cours",
  "account.buildingAINew": "Nouveau cours en anglais",
  'account.accept': 'Accepter',
  'account.decline': 'Décliner',
  'account.courses': 'Cours',
  // Sign Up page
  "signUp.description": "Remplissez le formulaire ci-dessous pour créer un nouveau compte mooc.fi",
  "signUp.email": "Votre courriel",
  "signUp.firstName": "Votre prénom",
  "signUp.lastName": "Votre nom de famille",
  "signUp.postalCode": "Code postal",
  "signUp.country": "Où habitez-vous ?",
  "signUp.Password": "Mot de passe",
  "signUp.confirmPassword": "Confirmer mot de passe",
  "signUp.howToStudyTitle": "Comment voulez-vous étudier ?",
  "signUp.6weeksTitle": "Cours de six semaines",
  "signUp.6weeksSubTitle": "Recommandé (environ 5h par semaine)",
  "signUp.6weeksText": "Avoir une date limite est un moyen reconnu pour vous aider à terminer le cours.",
  "signUp.selfPacedTitle": "A son propre rythme",
  "signUp.selfPacedText": "Vous pouvez suivre le cours à votre propre rythme.",
  "signUp.ectsTitleSwe": "Would you like to apply for ECTS credits ? ",
  "signUp.ectsTitle": "Souhaitez-vous obtenir des crédits ECTS ?",
  "signUp.ectsStudent": "Je suis étudiant à l’université d’Helsinki. Cochez cette case et indiquez votre numéro d’étudiant, et vous obtiendrez automatiquement les crédits ECTS.",
  "signUp.ectsStudentSwe": "Suoritettuasi kurssin voit hakea opintopisteitä Linköpingin yliopistosta. Löydät ohjeet ",
  "signup.ectsStudentSweLink": "täältä",
  "signUp.ectsOpenUni": "Cochez cette case pour recevoir un courriel contenant des instructions sur la manière de demander des crédits ECTS",
  "signUp.studentNumber": "Numéro d’étudiant de l’Université d’Helsinki (si vous êtes étudiant)",
  "signUp.privacyOptIn": "J’autorise l’utilisation à des fins de recherche des données collectées sur mon comportement d’étude. Ces données contiennent des informations sur l’utilisation du matériel et sur les exercices de cours. Aucun individu ne peut être identifié à partir ces publications.",
  "signUp.marketingOptIn": "Je suis d’accord pour recevoir des mises à jour sur des versions linguistiques à venir et des informations sur de nouveaux cours. J’accepte de partager mes coordonnées afin de recevoir des messages personnalisés sur des plateformes tierces. En savoir plus dans notre politique de confidentialité.",
  "signUp.privacyLink": "Lire notre politique de confidentialité",
  "signUp.error": "Il y a des erreurs dans votre formulaire. Veuillez vérifier votre formulaire.",
  "signUp.acceptPolicyFirst": "J’accepte la politique de confidentialité et les conditions d’utilisation.",
  // Sign in page
  "signIn.title": "S’inscrire",
  "signIn.intro": "Utilisez le formulaire ci-dessous pour vous connecter avec votre compte mooc.fi",
  "signIn.email": "Votre courriel",
  "signIn.password": "Votre mot de passe",
  "signIn.noAccount": "Vous n’avez pas de compte ?",
  "signIn.signUpNow": "S’inscrire maintenant",
  // Forgot password
  "pwForget.title": "J’ai oublié mon mot de passe",
  "pwForget.description": "Veuillez remplir l’e-mail de votre compte mooc.fi et nous vous enverrons un lien pour réinitialiser votre mot de passe",
  "pwForget.sendResetLink": "Envoyer le lien de réinitialisation",
  "pwForget.completedDescription": "Vous devriez bientôt recevoir un courriel de mooc.fi contenant un lien de réinitialisation du mot de passe. Si vous ne le voyez pas, vérifiez votre dossier de pourriels.",
  "pwForget.problemDescription": "Au cas de problèmes, veuillez contacter mooc@cs.helsinki.fi.",
  "pwForget.resetEmailSent": "Courriel de réinitialisation du mot de passe envoyé",
  "pwForget.emailPlaceholder": "Adresse courriel",
  // password change
  "pwChange.authError": "L’authentification a échoué. Veuillez essayer de vous inscrire à nouveau",
  "pwChange.unexpError": "Le mot de passe n’a pas été mis à jour en raison d’une erreur inattendue",
  "pwChange.required": "Obligatoire",
  "pwChange.pwMustMatch": "Les mots de passe doivent se correspondre.",
  "pwChange.currentPassword": "Mot de passe actuel",
  "pwChange.newPassword": "Nouveau mot de passe",
  "pwChange.confirmPassword": "Confirmer le nouveau mot de passe",
  "pwChange.changingPassword": "Changement de mot de passe en cours...",
  "pwChange.changePassword": "Changer le mot de passe",
  "pwChange.passwordChanged": "Mot de passe changé",
  // user details
  "userDetails.authError": "L’authentification a échoué. Veuillez essayer de vous inscrire à nouveau",
  "userDetails.unexpError": "Le profil n’a pas été mis à jour en raison d’une erreur inattendue",
  "userDetails.required": "Obligatoire",
  "userDetails.invalidEmail": "Adresse courriel invalide",
  "userDetails.email": "Courriel",
  "userDetails.emailAddress": "Adresse courriel",
  "userDetails.firstName": "Prénom",
  "userDetails.lastName": "Nom de famille",
  "userDetails.language": "Langue",
  "userDetails.updatingProfile": "Mise à jour du profil en cours...",
  "userDetails.updateProfile": "Mise à jour du profil",
  "userDetails.profileUpdated": "Profil mis à jour",
  // languages
  "languages.en.long": "anglais",
  "languages.fi.long": "finnois",
  "languages.se.long": "suédois",
  "languages.de.long": "allemand (Allemagne)",
  "languages.ee.long": "estonien",
  "languages.fr.long": "français (France)",
  "languages.no.long": 'norvégien',
  "languages.lt.long": 'lituanien',
  "languages.lv.long": 'letton',
  "languages.fr-be.long": "français (Belgique)",
  "languages.nl-be.long": "néerlandais (Belgique)",
  'languages.nl.long': "néerlandais (Pays-Bas)",
  "languages.it.long": 'italien',
  "languages.mt.long": 'maltais',
  "languages.pl.long": "polonais",
  "languages.en-ie.long": "anglais (Irlande)",
  'languages.ga.long': 'irlandais',
  'languages.sk.long': 'slovaque',
  'languages.da.long': 'danois',
  'languages.hr.long': 'croate',
  "languages.ro.long": "roumain",
  'languages.is.long': 'islandais',
  'languages.de-at.long': 'allemand (autriche)',
  'languages.en-lu.long': 'anglais (Luxembourg)',
  'languages.bg.long': 'bulgare',
  'languages.cs.long': 'tchèque',
  'languages.sl.long': 'slovène',
  'languages.pt.long': 'portugais',
  'languages.es.long': 'espagnol',
  'languages.el.long': 'grec',
  // Email verification
  "emailVerification.completedMessage": "Votre adresse électronique a été vérifiée",
  "emailVerification.verified": "Vérifié",
  // Exercises
  "exercise.loading": "Chargement de l’exercice en cours...",
  "exercise.signUp": "Inscrivez-vous pour faire les exercices",
  "exercise.score": "Votre résultat :",
  "exercise.wrongLocale": "Mauvais endroit",
  // Part tempplate
  "partTemplate.intro1": "Dans le chapitre ",
  "partTemplate.intro2": ", nous couvrirons les sections suivantes. Cliquez ci-dessous pour commencer :",
  "partTemplate.spectrumPart1": "Nous vous invitons à rejoindre ",
  "partTemplate.spectrum": "la communauté «Elements of AI»",
  "partTemplate.spectrumPart2": " pour discuter et poser des questions sur ce chapitre.",
  // missing info
  "missingInfo.title": "Informations manquantes",
  "missingInfo.body": "Nous posons en général ces questions lorsque les utilisateurs s’inscrivent, mais vous vous êtes connecté avec un compte mooc.fi existant. Veuillez remplir le formulaire ci-dessous pour continuer.",
  "missingInfo.privacyPolicy": "Lire notre politique de confidentialité",
  "missingInfo.error": "Il y a des erreurs dans votre formulaire. Veuillez vérifier votre formulaire.",
  "missingInfo.privacyPolicyUpdateBody": "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
  "missingInfo.privacyPolicyUpdateLink": "Lire notre politique de confidentialité ici.",
  // routes
  'routes.LANDING': '/fr/',
  'routes.SIGN_UP': '/fr/signup',
  'routes.SIGN_IN': '/fr/signin',
  'routes.MISSING_INFO': '/fr/missinginfo',
  'routes.PASSWORD_FORGET': '/fr/pw-forget',
  'routes.HOME': '/fr/home',
  'routes.ACCOUNT': '/fr/account',
  'routes.PRIVACY': 'https://www.elementsofai.fr/faq/politique-de-confidentialité',
  'routes.FAQ': 'https://www.elementsofai.fr/faq',
  'routes.ABOUT': 'https://www.elementsofai.fr',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/fr-francais',
  'routes.ects': 'https://mooc.fi/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/faq/how-can-i-get-2-ects-credits',
  'routes.ACCEPT_POLICY': '/fr/accept-policy',
  // MarkdownComponents
  "markdowncomponents.linearRegressionStatic.xLabel": "Tasses de café par jour",
  "markdowncomponents.linearRegressionStatic.yLabel": "Lignes de code écrit",
  // meta
  "meta.title": "Un cours en ligne gratuit",
  "meta.titleTemplate": "%s - Elements of AI",
  "meta.description": "En savoir plus sur l’Université d’Helsinki et le cours de l’IA de Reaktor pour les étudiants et les professionnels du monde des affaires - aucune connaissance en programmation ou en mathématiques n’est requise.",
  "meta.sitename": "Elements of AI",
  'meta.title.og': 'A free online introduction to artificial intelligence for non-experts',
  // ECTS
  "ects.text": "Vous recevrez ici des instructions sur la manière de demander des crédits ECTS après avoir terminé le cours.",
  "ects.textCompleted": "Si vous avez un numéro de sécurité sociale finlandais, vous pouvez demander des crédits en cliquant",
  "ects.link": "ici",
  'ects.info': 'Would you like to apply for ECTS credits?',
  'ects.readMore': 'Read more',
  // Countries
  'countryselect.select': "Please select",
  'Sweden': "Suède",
  'Estonia': "Estonie",
  'Finland': "Finlande",
  'Germany': "Allemagne",
  'France': 'France',
  "Countries": {
    "Afganistan": "Afganistan",
    "Åland Islands": "Îles Aland",
    "Albania": "Albanie",
    "Algeria": "Algérie",
    "American Samoa": "Samoa américaines",
    "Andorra": "Andorre",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antarctica": "Antarctique",
    "Antigua and Barbuda": "Antigua-et-Barbuda",
    "Argentina": "Argentine",
    "Armenia": "Arménie",
    "Aruba": "Aruba",
    "Australia": "Australie",
    "Austria": "L'Autriche",
    "Azerbaijan": "Azerbaïdjan",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahrein",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbade",
    "Belarus": "Biélorussie",
    "Belgium": "Belgique",
    "Belize": "Belize",
    "Benin": "Bénin",
    "Bermuda": "Bermudes",
    "Bhutan": "Bhoutan",
    "Bolivia": "Bolivie",
    "Bosnia and Herzegovina": "Bosnie Herzégovine",
    "Botswana": "Botswana",
    "Bouvet Island": "Île Bouvet",
    "Brazil": "Brésil",
    "British Indian Ocean Territory": "Territoire britannique de l’océan Indien",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgarie",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambodia": "Cambodge",
    "Cameroon": "Cameroun",
    "Canada": "Canada",
    "Cape Verde": "Cap-Vert",
    "Cayman Islands": "Îles Caïmans",
    "Central African Republic": "République centrafricaine",
    "Chad": "Tchad",
    "Chile": "Chili",
    "China": "Chine",
    "Christmas Island": "L’île de noël",
    "Cocos (Keeling) Islands": "Îles Cocos (Keeling)",
    "Colombia": "Colombie",
    "Comoros": "Comores",
    "Congo": "Congo",
    "Congo, The Democratic Republic of The": "Congo, République démocratique du",
    "Cook Islands": "les Îles Cook",
    "Costa Rica": "Costa Rica",
    "Cote D'ivoire": "Côte d’Ivoire",
    "Croatia": "Croatie",
    "Cuba": "Cuba",
    "Cyprus": "Chypre",
    "Czech Republic": "République Tchèque",
    "Denmark": "Danemark",
    "Djibouti": "Djibouti",
    "Dominica": "Dominique",
    "Dominican Republic": "République Dominicaine",
    "Ecuador": "Équateur",
    "Egypt": "Egypte",
    "El Salvador": "Le Salvador",
    "Equatorial Guinea": "Guinée Équatoriale",
    "Eritrea": "Érythrée",
    "Estonia": "Estonie",
    "Ethiopia": "Ethiopie",
    "Falkland Islands (Malvinas)": "Îles Falkland (Malvinas)",
    "Faroe Islands": "Îles Féroé",
    "Fiji": "Fidji",
    "Finland": "Finlande",
    "France": "France",
    "French Guiana": "Guyane Française",
    "French Polynesia": "Polynésie française",
    "French Southern Territories": "Terres australes françaises",
    "Gabon": "Gabon",
    "Gambia": "Gambie",
    "Georgia": "Géorgie",
    "Germany": "Allemagne",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Grèce",
    "Greenland": "Groenland",
    "Grenada": "Grenade",
    "Guadeloupe": "Guadeloupe",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernesey",
    "Guinea": "Guinée",
    "Guinea-Bissau": "Guinée-Bissau",
    "Guyana": "Guyane",
    "Haiti": "Haïti",
    "Heard Island and Mcdonald Islands": "Îles Heard et Mcdonald",
    "Holy See (Vatican City State)": "Saint-Siège (État de la Cité du Vatican)",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Hongrie",
    "Iceland": "Islande",
    "India": "Inde",
    "Indonesia": "Indonésie",
    "Iran, Islamic Republic of": "Iran (République islamique d’)",
    "Iraq": "Irak",
    "Ireland": "Irlande",
    "Isle of Man": "Île de Man",
    "Israel": "Israël",
    "Italy": "Italie",
    "Jamaica": "Jamaïque",
    "Japan": "Japon",
    "Jersey": "Jersey",
    "Jordan": "Jordan",
    "Kazakhstan": "Kazakhstan",
    "Kenya": "Kenya",
    "Kiribati": "Kiribati",
    "Korea, Democratic People's Republic of": "République populaire démocratique de Corée",
    "Korea, Republic of": "Corée, République de",
    "Kosovo": "Kosovo",
    "Kuwait": "Koweit",
    "Kyrgyzstan": "Kirghizistan",
    "Lao People's Democratic Republic": "République démocratique populaire Lao",
    "Latvia": "Lettonie",
    "Lebanon": "Liban",
    "Lesotho": "Lesotho",
    "Liberia": "Libéria",
    "Libyan Arab Jamahiriya": "Libye",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Lituanie",
    "Luxembourg": "Luxembourg",
    "Macao": "Macao",
    "Macedonia, The Former Yugoslav Republic of": "Macédoine, ancienne République yougoslave de",
    "Madagascar": "Madagascar",
    "Malawi": "Malawi",
    "Malaysia": "Malaisie",
    "Maldives": "Maldives",
    "Mali": "Mali",
    "Malta": "Malte",
    "Marshall Islands": "Iles Marshall",
    "Martinique": "Martinique",
    "Mauritania": "Mauritanie",
    "Mauritius": "Maurice",
    "Mayotte": "Mayotte",
    "Mexico": "Mexique",
    "Micronesia, Federated States of": "Micronésie, États fédérés de",
    "Moldova, Republic of": "Moldova, République de",
    "Monaco": "Monaco",
    "Mongolia": "Mongolie",
    "Montenegro": "Monténégro",
    "Montserrat": "Montserrat",
    "Marocco": "Maroc",
    "Mozambique": "Mozambique",
    "Myanmar": "Myanmar",
    "Namibia": "Namibie",
    "Nauru": "Nauru",
    "Nepal": "Népal",
    "Netherlands": "Pays-Bas",
    "Netherlands Antilles": "Antilles néerlandaises",
    "New Caledonia": "Nouvelle Calédonie",
    "New Zealand": "Nouvelle-Zélande",
    "Nicaragua": "Nicaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "Norfolk Island": "L’île de Norfolk",
    "Northern Mariana Islands": "Îles Mariannes du Nord",
    "Norway": "Norvège",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestinian Territory, Occupied": "Territoire palestinien, occupé",
    "Panama": "Panama",
    "Papua New Guinea": "Papouasie Nouvelle Guinée",
    "Paraguay": "Paraguay",
    "Peru": "Pérou",
    "Philippines": "Philippines",
    "Pitcairn": "Pitcairn",
    "Poland": "Pologne",
    "Portugal": "Portugal",
    "Puerto Rico": "Porto Rico",
    "Qatar": "Qatar",
    "Reunion": "Réunion",
    "Romania": "Roumanie",
    "Russian Federation": "Fédération Russe",
    "Rwanda": "Rwanda",
    "Saint Helena": "Sainte-Hélène",
    "Saint Kitts and Nevis": "Saint-Christophe-et-Niévès",
    "Saint Lucia": "Sainte-Lucie",
    "Saint Pierre and Miquelon": "Saint-Pierre-et-Miquelon",
    "Saint Vincent and The Grenadines": "Saint-Vincent-et-les-Grenadines",
    "Samoa": "Samoa",
    "San Marino": "Saint Marin",
    "Sao Tome and Principe": "Sao Tomé et Principe",
    "Saudi Arabia": "Arabie Saoudite",
    "Senegal": "Sénégal",
    "Serbia": "Serbie",
    "Seychelles": "les Seychelles",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapour",
    "Slovakia": "Slovaquie",
    "Slovenia": "Slovénie",
    "Solomon Islands": "Les îles Salomon",
    "Somalia": "Somalie",
    "South Africa": "Afrique du Sud",
    "South Georgia and The South Sandwich Islands": "Géorgie du Sud et les îles Sandwich du Sud",
    "Spain": "Espagne",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Soudan",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard et Jan Mayen",
    "Swaziland": "Swaziland",
    "Sweden": "Suède",
    "Swizerland": "Suisse",
    "Syrian Arab Republic": "République arabe syrienne",
    "Taiwan": "Taïwan",
    "Tajikistan": "Tadjikistan",
    "Tanzania, United Republic of": "Tanzanie, République-Unie de",
    "Thailand": "Thaïlande",
    "Timor-leste": "Timor-leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinité-et-Tobago",
    "Tunisia": "Tunisie",
    "Turkey": "Turquie",
    "Turkmenistan": "Turkménistan",
    "Turks and Caicos Islands": "îles Turques-et-Caïques",
    "Tuvalu": "Tuvalu",
    "Uganda": "Ouganda",
    "Ukraine": "Ukraine",
    "United Arab Emirates": "Emirats Arabes Unis",
    "United Kingdom": "Royaume-Uni",
    "United States": "États Unis",
    "United States Minor Outlying Islands": "Îles mineures éloignées des États-Unis",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Ouzbékistan",
    "Vanuatu": "Vanuatu",
    "Venezuela": "Venezuela",
    "Viet Nam": "Viet Nam",
    "Virgin Islands, British": "Îles Vierges britanniques",
    "Virgin Islands, U.S.": "Îles Vierges américaines",
    "Wallis and Futuna": "Wallis et Futuna",
    "Western Sahara": "Sahara occidental",
    "Yemen": "Yémen",
    "Zambia": "Zambie",
    "Zimbabwe": "Zimbabwe"
  },
  //Alt texts
  'alt.example-1': 'deux voitures autonomes',
  'alt.example-2': 'main tenant un téléphone portable',
  'alt.example-3': "visage de femme",
  'alt.defining-ai': 'méli-mélo de mots liés à l’IA',
  'alt.turing-test': 'conversation par ordinateur entre un homme, un robot et une femme',
  'alt.chinese-room': 'l’expérience de la chambre chinoise',
  'alt.chicken-crossing': 'robot dans une barque regardant un poulet, un renard et un sac de grains',
  'alt.a-to-b': 'main tenant un téléphone et utilisant une application de navigation',
  'alt.tic-tac-toe': 'deux enfants jouant au morpion',
  'alt.self-driving-car': 'femme marchant devant une voiture autonome',
  'alt.spam-or-ham': 'e-mail normal et e-mail de spam',
  'alt.mnist': 'ensemble de données MNIST',
  'alt.nearest-neighbor': 'deux personnes faisant des achats',
  'alt.nearest-neighbor-graph': 'classifieur du plus proche voisin',
  'alt.recommendation': 'homme et femme écoutant de la musique sur le téléphone',
  'alt.price-real-estate': 'trois maisons différentes',
  'alt.supervised-learning': 'homme enseignant à un robot',
  'alt.dice': "dés à jouer",
  'alt.chess': 'jeu d’échecs',
  'alt.poker': 'main tenant des cartes à jouer',
  'alt.eyes': 'trois personnes avec des yeux bruns, une personne avec des yeux bleus',
  'alt.search-1': 'chemin unique menant à un objectif',
  'alt.search-2': 'quatre chemins différents menant au même objectif',
  'alt.chicken-crossing-1': 'état initial de la traversée du poulet',
  'alt.chicken-crossing-2': 'première transition de la traversée du poulet',
  'alt.chicken-crossing-3': 'toutes les transitions de la traversée du poulet',
  'alt.chicken-crossing-4': 'traversée du poulet avec le meilleur chemin',
  'alt.chicken-crossing-5': 'état initial de la traversée du poulet',
  'alt.implication-1': 'homme et femme"',
  'alt.implication-2': 'yeux',
  'alt.implication-3': 'caméra de surveillance ',
  'alt.implication-4': 'robot travaillant sur un ordinateur portable ',
  'alt.oddchange': 'soleil et nuages',
  'alt.brain': 'dessin d’un cerveau ',
  'alt.carstop': 'voiture autonome à un stop',
  'alt.gan': 'robot tenant une image de chat et une image de stop  ',
  'alt.hammock': 'robot éventant un homme couché dans un hamac',
  'alt.game-tree-1': 'arbre de jeu 1',
  'alt.game-tree-2': 'arbre de jeu 2',
  'alt.game-tree-3': 'arbre de jeu 3',
  'alt.game-tree-4': 'arbre de jeu 4',
  'alt.bayes-rule-1': 'image illustrant les faux positifs et les négatifs du dépistage du cancer ',
  'alt.bayes-rule-2': 'image illustrant les faux positifs et les négatifs du dépistage du cancer ',
  'alt.defineai': 'jumble of words related to AI',
  'alt.winter': 'robot dormant dans la neige',
  'alt.terminator': 'robot effrayant'
}
