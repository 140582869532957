import { breakpoints } from "./breakpoints";
import { colors } from "./colors";
import { space } from "./space";
import { maxWidths } from "./maxWidths";
import { fontSizes, fonts, lineHeights, textStyles } from "./typography";

const theme = {
  breakpoints,
  colors,
  space,
  maxWidths,
  fontSizes,
  fonts,
  lineHeights,
  textStyles
};

export default theme;
