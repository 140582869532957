// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'no',
  'globals.signIn': 'Logg inn',
  'globals.logIn': 'Logg inn',
  'globals.signUp': 'Registrer deg',
  'globals.signOut': 'Logg ut',
  'globals.logOut': 'Logg ut',
  'globals.submit': 'Send inn',
  'globals.submitting': 'Sender...',
  'globals.yourEmail': 'Din e-post',
  'globals.yourPassword': 'Ditt passord',
  'globals.courseOverview': 'Kursoversikt',
  'globals.forgotPassword': 'Glemt passord?',
  'globals.invalidCredentials': 'Feil brukernavn eller passord',
  'globals.section': 'Del',
  'globals.exercises': 'Oppgaver',
  'globals.chapter': 'Kapittel',
  'globals.introToAI': 'Introduksjon til AI',
  'globals.buildingAI': 'Bygge AI',
  'globals.acceptPolicy': 'Accept policy',
  // section
  'section.footerEndOfTheChapter':
    'Du har kommet til slutten av kapittelet {chapter}!',
  'section.footerEndOfTheCourseTitle': 'Du har kommet gjennom kurset! ',
  'section.footerEndOfTheCourseText':
    'Når du har fullført alle oppgavene og fagfellevurderingene, blir besvarelsene dine vurdert av staben vår.',
  'section.nextSection': 'Neste avsnitt',
  'section.nextChapter': 'Neste kapittel',
  'section.start': 'Start',
  'section.logisticRegressionExample.slope': 'Skråning',
  'section.logisticRegressionExample.intercept': 'Kryss',
  'section.footerEndOfTheCourseBAI': 'Fortsett reisen mot å kunne implementere din første AI-idé.',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Venter på dine vurderinger',
  'quiz.rejectedPeerReview': 'Avvist i fagfellevurdering',
  'quiz.unsubmittedAnswer': 'Besvarelse ikke sendt',
  'quiz.pendingPeerReview': 'Venter på fagfellevurdering',
  // quiz
  'quiz.status.answered': 'Besvart',
  'quiz.status.rejected': 'Svar avvist, prøv igjen. ',
  'quiz.status.unanswered': 'Ubesvart',
  'quiz.status.exercisesCompleted': 'Fullførte oppgaver',
  'quiz.status.correctAnswers': 'Riktige svar',
  'quiz.message.rejectedInReview':
    'Din forrige besvarelse ble avvist i fagfellevurdering. Prøv igjen.',
  'quiz.youAnswered': 'Du svarte',
  'quiz.yourAnswer': 'Ditt svar...:',
  'quiz.yourScore': 'Dine poeng:',
  'quiz.quizNotLoaded': 'Kunne ikke laste ned quiz',
  'quiz.words': 'Ord:',
  'quiz.exampleAnswer': 'Eksempelsvar :',
  // peer review
  'peerreview.question1': 'Besvarelsen holder seg til tema',
  'peerreview.question2': 'Besvarelsen er forståelig',
  'peerreview.question3': 'Besvarelsen er velbegrunnet',
  'peerreview.question4': 'Besvarelsen er lett å følge',
  'peerreview.thankYou': 'Takk for at du har vurdert en medstudent!',
  'peerreview.giveMoreReviews': 'Om du har tid til overs, ',
  'peerreview.giveMoreReviewsButton': 'kan du gi flere vurderinger.',
  'peerreview.notEnoughAnswers':
    'Vanligvis ville det være en fagfellevurdering her, men det er ikke nok brukere som har besvart denne oppgaven. ',
  'peerreview.selectAnswerMessage':
    'Det er tid for fagfellevurdering! Under ser du besvarelser fra andre studenter. Velg én av dem og vurder den. Gjenta minst tre ganger. ',
  'peerreview.minimumPeerReviewWarning':
    'Oppgaven kan bare bli godkjent etter at du har gitt minst tre fagfellevurderinger.',
  'peerreview.select': 'Velg',
  'peerreview.reportSpam': 'Rapporter spam',
  'peerreview.feedback':
    'Svaret ditt er vurdert og godkjent. Her er hva medstudentene dine mener: ',
  // exercise17
  'quiz.exercise17.xLabel': 'Utdanning i år',
  'quiz.exercise17.yLabel': 'Forventet levealder i år ',
  'quiz.exercise17.textPart1':
    'La oss se på sammenhengen mellom antall år på skolen (alt fra barnehage til universitet) og forventet levealder. Her er data fra tre forskjellige land vist som punkter i skjemaet: ',
  'quiz.exercise17.textPart2':
    'Vi har ett land hvor gjennomsnittlig antall år på skolen er 10 og forventet levealder er 57, et annet land hvor gjennomsnittlige antall år på skolen er 13 og forventet levealder er 53 år, og et tredje land hvor gjennomsnittlig antall år på skolen er 20 og forventet levealder er 80 år. ',
  'quiz.exercise17.textPart3':
    'Du kan dra i endepunktet på den trukne linjen slik at den best mulig. Merk at linjen ikke vil passe helt med dataene du har, men det er helt ok: noen av datapunktene vil ligge over linjen og noen under. Det viktigste er at linjen beskriver hovedtrenden. ',
  'quiz.exercise17.textPart4':
    'Når du er fornøyd med linjens tilpasning, kan du bruke den til å forutsi forventet levealder.',
  'quiz.exercise17.textPart5':
    'Ut ifra de dataene du har, hva kan du si om forventet levealder hos de som har 15 års utdanning? Viktig: Legg merke til at selv om du kan få en prediksjon ved å justere linjen ned til en brøkdel av et år, kan du ikke nødvendigvis gi en sikker forutsigelse. Ta hensyn til den begrensede datamengden når du svarer.',
  // exercise18
  'quiz.exercise18.xLabel': 'År med skolegang',
  'quiz.exercise18.yLabel': 'Forventet levealder i år ',
  'quiz.exercise18.textPart1':
    'I forrige oppgave hadde vi bare data fra tre land. Hele datasettet inneholder data fra 14 land slik denne grafen viser:',
  'quiz.exercise18.textPart2':
    'Blir forutsigelsen din om forventet levetid for mennesker med 15 års skolegang annerledes når du ser denne grafen? Om den gjør det: hvorfor? ',
  'quiz.exercise18.textPart3':
    'Hvilket av følgende beskriver best forventet levealder for de som har gått på skole i 15 år? Velg det mest nøyaktige alternativet du kan rettferdiggjøre ved å passe en rett linje til dataene ovenfor. Merk at det nå er mer data enn før, men det er fortsatt begrenset.',
  // exercise19
  'quiz.exercise19.xLabel': 'Antall timer med lesing',
  'quiz.exercise19.yLabel': 'Sannsynlighet for bestått',
  'quiz.exercise19.textPart1':
    'Hver prikk i figuren tilsvarer én student. Nederst på figuren ser du hvor mange timer studenten leste til eksamen. Antall studenter som besto eksamen er vist som prikker på toppen av diagrammet, mens de som strøk er vist på bunnen. Vi bruker skalaen til venstre for å indikere den forutsagte sannsynligheten for å bestå, som vi får fra den logistiske regresjonsmodellen (som vi vil forklare lengre ned). I denne figuren ser du at studenter som brukte mye tid på å lese, hadde større sjanser for å bestå kurset. Spesielt de ekstreme tilfellene er intuitive: med mindre enn én times innsats er det veldig vanskelig å bestå kurset, men med høy innsats vil de fleste lykkes. Men hva med dem som ligger midt på treet? Hvor stor er sjansen for å bestå om du leser i 6 timer? ',
  'quiz.exercise19.textPart2':
    'Vi kan kvantifisere sannsynligheten for å bestå ved å bruke logistisk regresjon. Kurven i figuren kan tolkes som sannsynligheten for å bestå: for eksempel, etter å ha lest i fem timer er sannsynligheten for å bestå litt over 20%. Vi skal ikke gå inn i detaljer om hvordan vi finner kurven, men det tilsvarer hvordan vi lærer vekter i lineær regresjon. ',
  'quiz.exercise19.textPart3':
    'Ta utgangspunkt i figuren over. Omtrent hvor mange timer må du lese om du vil ha 80% sjanse for å bestå en universitetseksamen? ',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Inndata',
  'quiz.exercise22.sigmoidYLabel': 'Sigmoid-utverdi',
  'quiz.exercise22.stepXLabel': 'Inndata',
  'quiz.exercise22.stepYLabel': 'Stegfunksjons-utverdi',
  'quiz.exercise22.identityXLabel': 'Inndata',
  'quiz.exercise22.identityYLabel': 'Identitetfunksjons-utverdi ',
  'quiz.exercise22.textPart1':
    'Under ser du grafer for tre forskjellige aktiveringsfunksjoner med forskjellige egenskaper. Først er det sigmoidfunksjonen, så stegfunksjonen og til slutt identitetsfunksjonen.',
  // exercise23
  'quiz.exercise23.happyFaces': 'antall smilefjes som er riktig klassifisert ',
  'quiz.exercise23.sadFaces': 'antall triste fjes som er riktig klassifisert ',
  // certificate availability
  'certificate.failedToCheck':
    'Kunne ikke kontrollere tilgjengeligheten til kursbevis',
  'certificate.failedToGenerate': 'Kunne ikke generere kursbevis',
  'certificate.notYetAvailable':
    'Kursbeviset ditt blir tilgjengelig her etter bekreftelse fra oss. Dette kan ta litt tid. Takk for tålmodigheten! ',
  'certificate.updateName':
    'Kurabeviset ditt er tilgjengelig! Oppdater for- og etternavnet ditt slik at det vises på sertifikatet ditt ved å bruke et skjema som er tilgjengelig på profilsiden din.',
  'certificate.generate': 'Generer kursbevis nedenfor.',
  'certificate.generateTextPart1': 'Kursbeviset blir generert for',
  'certificate.generateTextPart2':
    'Navnet kan ikke endres i etterkant, derfor bør du forsikre deg om at alt er riktig før du genererer kursbeviset. Om du vil endre navnet, kan du gjøre det i skjemaet nedenfor.',
  'certificate.generateButton': 'Generer kursbevis',
  'certificate.downloadButton': 'Last ned kursbevis',
  'certificate.checkingAvailability':
    'Kontrollerer tilgjengeligheten til kursbevis',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly':
    'Riktig klassifisert',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly':
    'Ikke riktig klassifisert ',
  // section blocks
  'section.block.note': 'Obs!',
  'section.block.example': 'Eksempel',
  'section.block.keyTerminology': 'Nøkkelterminologi',
  'section.partSummary.spectrumTextPart1':
    'Bli med i ',
  'section.partSummary.spectrum': 'Elements of AIs nettsamfunn',
  'section.partSummary.spectrumTextPart2':
    ' for å diskutere og stille spørsmål om dette kapittelet. ',
  'section.partSummary.feedbackTitle': 'Gi oss tilbakemelding på denne delen ',
  'section.partSummary.feedbackPlaceholder': 'Skriv tilbakemelding din her...',
  'section.partSummary.feedbackSuccess': 'Takk for tilbakemelding!',
  // 404
  'globals.404.title': '404 - Siden ble ikke funnet',
  'globals.404.intro': 'Du har kommet til en side som ikke eksisterer. ',
  'globals.loading': 'Laster ned...',
  // footer / menu
  'globals.menu': 'Meny',
  'globals.menu.about': 'Om',
  'globals.menu.faq': 'Spørsmål og svar',
  'globals.menu.privacyPolicy': 'Personvernerklæring',
  'globals.menu.myProfile': 'Min profil',
  'globals.menu.termsofService': 'Vilkår for bruk',
  // Dashboard/Index
  'dashboard.title': 'Velkommen til kurset!',
  'dashboard.congratulations.title': 'Gratulerer!',
  'dashboard.congratulations.message': 'Du har fullført Elements of AI-kurset',
  'dashboard.congratulations.downloadCertificate': 'Last ned kursbevis',
  'dashboard.notLoggedIn.title': 'Velkommen!',
  'dashboard.notLoggedIn.message':
    'Registrer deg eller logg inn for å få full tilgang til kurset. Det er gratis! ',
  // Account
  'account.certificate': 'Kursbevis',
  'account.credits': 'Studiepoeng',
  'account.myProfile': 'Min profil',
  'account.changePassword': 'Bytt passord',
  'account.changeLanguage': 'Bytt språk',
  'account.profileInformation': 'Profil informasjon',
  'account.goToCourse': 'Gå på kurs',
  'account.buildingAINew': 'Nytt kurs i engelsk',
  'account.accept': 'Jeg aksepterer',
  'account.decline': 'Jeg avslår',
  'account.courses': 'Kurs',
  // Sign Up page
  'signUp.description': 'Fyll ut skjemaet under for å lage en ny mooc.fi-konto',
  'signUp.email': 'Din e-postadresse',
  'signUp.firstName': 'Fornavn',
  'signUp.lastName': 'Etternavn',
  'signUp.postalCode': 'Postnummer',
  'signUp.country': 'Hvor bor du?',
  'signUp.Password': 'Passord',
  'signUp.confirmPassword': 'Bekreft passord',
  'signUp.howToStudyTitle': 'Hvordan vil du studere?',
  'signUp.6weeksTitle': '6-ukers kurs',
  'signUp.6weeksSubTitle': 'Anbefalt (omtrent 5t/uken) ',
  'signUp.6weeksText': 'Å ha en deadline hjelper deg å fullføre kurset. ',
  'signUp.selfPacedTitle': 'Eget tempo',
  'signUp.selfPacedText': 'Du kan fullføre kurset i ditt eget tempo.',
  'signUp.ectsTitleSwe': 'Would you like to apply for ECTS credits? ',
  'signUp.ectsTitle': 'Vil du søke om studiepoeng?',
  'signUp.ectsStudent':
    'Jeg er student ved Helsingfors universitet. Hvis du merker av i denne boksen og fyller ut studentnummeret ditt, vil du automatisk få ECTS-studiepoeng.',
  'signUp.ectsStudentSwe': '',
  'signup.ectsStudentSweLink': '',
  'signUp.ectsOpenUni':
    'Sjekk denne boksen for å motta e-post med instruksjoner om hvordan du søker om studiepoeng. ',
  'signUp.studentNumber':
    'Studentnummer fra Helsingfors universitet (hvis du er student)',
  'signUp.privacyOptIn':
    'Jeg tillater bruk av samlet data om mine studievaner for forskningsøyemed. Dataen inneholder informasjon fra materialbruk og kursoppgaver. Ingen personer kan identifiseres i publikasjonene. ',
  'signUp.marketingOptIn':
'Jeg mottar gjerne oppdateringer om nye språkversjoner og informasjon om nye kurs. Jeg godtar å dele kontaktinformasjonen min for å motta skreddersydde meldinger på tredjepartsplattformer. Les mer i vår personvernerklæring.',
  'signUp.privacyLink': 'Les personvernerklæringen',
  'signUp.error': 'Det er feil i skjemaet ditt. Kontroller skjemaet. ',
  'signUp.acceptPolicyFirst': 'Jeg godtar personvernerklæringen og vilkårene for bruk.',
  // Sign in page
  'signIn.title': 'Logg inn',
  'signIn.intro': 'Bruk skjemaet under for å logge inn med din mooc.fi-konto',
  'signIn.email': 'Din e-postadresse',
  'signIn.password': 'Ditt passord',
  'signIn.noAccount': 'Har du ingen konto?',
  'signIn.signUpNow': 'Registrer deg nå',
  // Forgot password
  'pwForget.title': 'Glemt passord?',
  'pwForget.description':
    'Fyll inn e-postadressen du har i mooc.fi-kontoen, så sender vi deg en lenke til å tilbakestille passordet.',
  'pwForget.sendResetLink': 'Send tilbakestillingslenke ',
  'pwForget.completedDescription':
    'Du vil snart motta en e-post fra mooc.fi med en lenke til å tilbakestille passord. Om du ikke ser den, kan du sjekke spamposten.',
  'pwForget.problemDescription':
    'Om du støter på problemer, ta kontakt med mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent':
    'Vi har sendt deg en e-post om tilbakestilling passordet.',
  'pwForget.emailPlaceholder': 'E-postadresse',
  // password change
  'pwChange.authError':
    'Feil brukernavn eller passord. Prøv å logge deg inn igjen. ',
  'pwChange.unexpError':
    'Det oppsto en uventet feil. Oppdatering av passord mislyktes. ',
  'pwChange.required': 'Påkrevd',
  'pwChange.pwMustMatch': 'Passordet må være likt',
  'pwChange.currentPassword': 'Nåværende passord',
  'pwChange.newPassword': 'Nytt passord',
  'pwChange.confirmPassword': 'Bekreft nytt passord',
  'pwChange.changingPassword': 'Bytter passord...',
  'pwChange.changePassword': 'Bytt passord',
  'pwChange.passwordChanged': 'Passord endret',
  // user details
  'userDetails.authError': 'Feil brukernavn eller passord. Prøv igjen. ',
  'userDetails.unexpError':
    'Det oppsto en uventet feil. Kunne ikke oppdatere profilen. ',
  'userDetails.required': 'Påkrevd',
  'userDetails.invalidEmail': 'Ugyldig e-postadresse',
  'userDetails.email': 'E-post',
  'userDetails.emailAddress': 'E-postadresse',
  'userDetails.firstName': 'Fornavn',
  'userDetails.lastName': 'Etternavn',
  'userDetails.language': 'Språk',
  'userDetails.updatingProfile': 'Oppdaterer profilen...',
  'userDetails.updateProfile': 'Oppdater profilen',
  'userDetails.profileUpdated': 'Oppdatert profil',
  // languages
  'languages.en.long': 'Engelsk',
  'languages.fi.long': 'Finsk',
  'languages.se.long': 'Svensk',
  'languages.de.long': 'Tysk (Tyskland)',
  'languages.ee.long': 'Estisk',
  'languages.no.long': 'Norsk',
  'languages.lv.long': 'Latvisk',
  'languages.ga.long': 'Irsk',
  'languages.sl.long': 'Slovensk',
  'languages.el.long': 'Gresk',
  'languages.sk.long': 'Slovakisk',
  'languages.cs.long': 'Tsjekkisk',
  'languages.da.long': 'Dansk',
  'languages.ro.long': 'Rumensk',
  'languages.pl.long': 'Polsk',
  'languages.en-ie.long': 'Engelsk (Irland)',
  'languages.mt.long': 'Maltesisk',
  'languages.fr-be.long': 'Fransk (Belgia)',
  'languages.lt.long': 'Litauisk',
  'languages.fr.long': 'Fransk (Frankrike)',
  'languages.nl-be.long': 'Nederlandsk (Belgia)',
  'languages.nl.long': 'Nederlandsk (Nederland)',
  'languages.it.long': 'Italiensk',
  'languages.hr.long': 'Kroatisk',
  'languages.is.long': 'Islandsk',
  'languages.de-at.long': 'Tysk (Østerrike)',
  'languages.en-lu.long': 'Engelsk (Luxembourg)',
  'languages.bg.long': 'Bulgarsk',
  'languages.pt.long': 'Portugisisk',
  'languages.es.long': 'Spansk',
  // Email verification
  'emailVerification.completedMessage': 'E-postadressen din ble bekreftet ',
  'emailVerification.verified': 'Bekreftet',
  // Exercises
  'exercise.loading': 'Laster ned oppgaver...',
  'exercise.signUp': 'Logg inn for å løse oppgaver ',
  'exercise.score': 'Dine poeng: ',
  'exercise.wrongLocale': 'Feil språkvalg ',
  // Part tempplate
  'partTemplate.intro1': 'Kapittel ',
  'partTemplate.intro2':
    ' inneholder følgende seksjoner. Velg et avsnitt nedenfor: ',
  'partTemplate.spectrumPart1': 'Bli med i ',
  'partTemplate.spectrum': 'Elements of AIs nettsamfunn',
  'partTemplate.spectrumPart2':
    ' for å diskutere og stille spørsmål om kapittelet.',
  // missing info
  'missingInfo.title': 'Manglende informasjon',
  'missingInfo.body':
    'Vanligvis stiller vi dette spørsmålet når brukere registrerer seg, men du har logget inn med en eksisterende mooc.fi konto. Fyll ut sjemaet under for å fortsette. ',
  'missingInfo.privacyPolicy': 'Les personvernerklæringen',
  'missingInfo.error':
    'Det er feil i skjemaet. Kontroller at du har skrevet riktig. ',
  'missingInfo.privacyPolicyUpdateBody': "Vi har oppdatert personvernreglene våre. Her er en oppsummering av endringene. Vi annonserer kursene våre for potensielle nye studenter ved å danne publikum i markedsføringsplattformer. Dette kan innebære deling av e-postadresser med disse plattformene. For det samme formålet vil anonymiserte aktivitetsdata bli delt med markedsføringsnettverk. Du kan forhindre deling av e-postinformasjon ved å fjerne merket for avmerkingsboksen for markedsføringstillatelse i kursprofilen din. For å forhindre deling av aktivitetsdata, kan du endre informasjonskapselinnstillingene på kursstedet. Å godta disse vilkårene er nødvendig for å fortsette å bruke tjenesten.",
  'missingInfo.privacyPolicyUpdateLink': 'Les vår personvernerklæring her.',
  // routes
  'routes.LANDING': '/no/',
  'routes.SIGN_UP': '/no/signup',
  'routes.SIGN_IN': '/no/signin',
  'routes.MISSING_INFO': '/no/missinginfo',
  'routes.PASSWORD_FORGET': '/no/pw-forget',
  'routes.HOME': '/no/home',
  'routes.ACCOUNT': '/no/account',
  'routes.PRIVACY': 'https://www.elementsofai.com/no/faq/personvernerklæring',
  'routes.FAQ': 'https://www.elementsofai.com/no/faq',
  'routes.ABOUT': 'https://www.elementsofai.com/no',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/no-norsk-samfunn',
  'routes.ects': 'https://mooc.fi/no/register-completion/elements-of-ai/',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/faq/how-can-i-get-2-ects-credits',
  'routes.ACCEPT_POLICY': '/no/accept-policy',

  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel':
    'Antall kopper kaffe per dag',
  'markdowncomponents.linearRegressionStatic.yLabel':
    'Antall skrevne kodelinjer',
  // meta
  'meta.title': 'Gratis nettkurs',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description':
    'Les mer om Universitetet i Helsingfors og Reaktors KI-kurs for det som studerer eller er i arbeid. Forkunnskaper i matematikk og programmering er ikke nødvendig. ',
  'meta.sitename': 'Elements of AI',
  'meta.title.og': 'A free online introduction to artificial intelligence for non-experts',
  // ECTS
  'ects.text':
    'Du vil få instruksjoner om hvordan du søker på ECTS-studiepoeng her etter endt kurs',
  'ects.textCompleted':
    'Hvis du har et finsk personnummer, kan du søke om studiepoeng ved å klikke ',
  'ects.link': 'her.',
  'ects.info': 'Would you like to apply for ECTS credits?',
  'ects.readMore': 'Read more',
  // Countries
  'countryselect.select': 'Velg',
  'Sweden': 'Sverige',
  'Estonia': 'Estland',
  'Finland': 'Finland',
  'Germany': 'Tyskland',
  'Norway': 'Norge',
  'Latvia': 'Latvia',
  'Countries': {
    'Afganistan': 'Afganistan',
    'Åland Islands': 'Åland',
    'Albania': 'Albania',
    'Algeria': 'Algerie',
    'American Samoa': 'Amerikansk Samoa',
    'Andorra': 'Andorra',
    'Angola': 'Angola',
    'Anguilla': 'Anguilla',
    'Antarctica': 'Antarktis',
    'Antigua and Barbuda': 'Antigua og Barbuda',
    'Argentina': 'Argentina',
    'Armenia': 'Armenia',
    'Aruba': 'Aruba',
    'Australia': 'Australia',
    'Austria': 'Østerrike',
    'Azerbaijan': 'Aserbajdsjan',
    'Bahamas': 'Bahamas',
    'Bahrain': 'Bahrain',
    'Bangladesh': 'Bangladesh',
    'Barbados': 'Barbados',
    'Belarus': 'Hviterussland',
    'Belgium': 'Belgia',
    'Belize': 'Belize',
    'Benin': 'Benin',
    'Bermuda': 'Bermuda',
    'Bhutan': 'Bhutan',
    'Bolivia': 'Bolivia',
    'Bosnia and Herzegovina': 'Bosnia og Herzegovina',
    'Botswana': 'Botswana',
    'Bouvet Island': 'Bouvet Island',
    'Brazil': 'Brasil',
    'British Indian Ocean Territory': 'Britiske territoriet i Indiahavet',
    'Brunei Darussalam': 'Brunei Darussalam',
    'Bulgaria': 'Bulgaria',
    'Burkina Faso': 'Burkina Faso',
    'Burundi': 'Burundi',
    'Cambodia': 'Kambodsja',
    'Cameroon': 'Kamerun',
    'Canada': 'Canada',
    'Cape Verde': 'Kapp Verde',
    'Cayman Islands': 'Caymanøyene',
    'Central African Republic': 'Den sentralafrikanske republikk',
    'Chad': 'Tsjad',
    'Chile': 'Chile',
    'China': 'Kina',
    'Christmas Island': 'Juleøya',
    'Cocos (Keeling) Islands': 'Cocos (Keeling) øyene',
    'Colombia': 'Colombia',
    'Comoros': 'Komorene',
    'Congo': 'Kongo',
    'Congo, The Democratic Republic of The':
      'Kongo, Den demokratiske republikken',
    'Cook Islands': 'Cook-øyene',
    'Costa Rica': 'Costa Rica',
    "Cote D'ivoire": 'Elfenbenskysten',
    'Croatia': 'Kroatia',
    'Cuba': 'Cuba',
    'Cyprus': 'Kypros',
    'Czech Republic': 'Tsjekkisk Republikk',
    'Denmark': 'Danmark',
    'Djibouti': 'Djibouti',
    'Dominica': 'Dominica',
    'Dominican Republic': 'Den dominikanske republikk',
    'Ecuador': 'Ecuador',
    'Egypt': 'Egypt',
    'El Salvador': 'El Salvador',
    'Equatorial Guinea': 'Ekvatorial-Guinea',
    'Eritrea': 'Eritrea',
    'Estonia': 'Estland',
    'Ethiopia': 'Etiopia',
    'Falkland Islands (Malvinas)': 'Falklandsøyene (Malvinas)',
    'Faroe Islands': 'Færøyene',
    'Fiji': 'Fiji',
    'Finland': 'Finland',
    'France': 'Frankrike',
    'French Guiana': 'Fransk Guyana',
    'French Polynesia': 'Fransk Polynesia',
    'French Southern Territories': 'Franske sørlige territorier',
    'Gabon': 'Gabon',
    'Gambia': 'Gambia',
    'Georgia': 'Georgia',
    'Germany': 'Tyskland',
    'Ghana': 'Ghana',
    'Gibraltar': 'Gibraltar',
    'Greece': 'Hellas',
    'Greenland': 'Grønland',
    'Grenada': 'Grenada',
    'Guadeloupe': 'Guadeloupe',
    'Guam': 'Guam',
    'Guatemala': 'Guatemala',
    'Guernsey': 'Guernsey',
    'Guinea': 'Guinea',
    'Guinea-Bissau': 'Guinea-Bissau',
    'Guyana': 'Guyana',
    'Haiti': 'Haiti',
    'Heard Island and Mcdonald Islands': 'Heard Island og Mcdonald Islands',
    'Holy See (Vatican City State)': 'Den hellige stol (Vatikanstaten)',
    'Honduras': 'Honduras',
    'Hong Kong': 'Hong Kong',
    'Hungary': 'Ungarn',
    'Iceland': 'Island',
    'India': 'India',
    'Indonesia': 'Indonesia',
    'Iran, Islamic Republic of': 'Iran, Den islamske republikken',
    'Iraq': 'Irak',
    'Ireland': 'Irland',
    'Isle of Man': 'Isle of Man',
    'Israel': 'Israel',
    'Italy': 'Italia',
    'Jamaica': 'Jamaica',
    'Japan': 'Japan',
    'Jersey': 'Jersey',
    'Jordan': 'Jordan',
    'Kazakhstan': 'Kasakhstan',
    'Kenya': 'Kenya',
    'Kiribati': 'Kiribati',
    "Korea, Democratic People's Republic of":
      'Korea, Den demokratiske folkerepublikken',
    'Korea, Republic of': 'Korea, Republikken',
    'Kosovo': 'Kosovo',
    'Kuwait': 'Kuwait',
    'Kyrgyzstan': 'Kirgisistan',
    "Lao People's Democratic Republic": 'Lao folkets Demokratiske Republikk',
    'Latvia': 'Latvia',
    'Lebanon': 'Libanon',
    'Lesotho': 'Lesotho',
    'Liberia': 'Liberia',
    'Libyan Arab Jamahiriya': 'Libyan Arab Jamahiriya',
    'Liechtenstein': 'Liechtenstein',
    'Lithuania': 'Litauen',
    'Luxembourg': 'Luxembourg',
    'Macao': 'Macao',
    'Macedonia, The Former Yugoslav Republic of':
      'Makedonia, Den tidligere jugoslaviske republikken',
    'Madagascar': 'Madagaskar',
    'Malawi': 'Malawi',
    'Malaysia': 'Malaysia',
    'Maldives': 'Maldivene',
    'Mali': 'Mali',
    'Malta': 'Malta',
    'Marshall Islands': 'Marshalløyene',
    'Martinique': 'Martinique',
    'Mauritania': 'Mauritania',
    'Mauritius': 'Mauritius',
    'Mayotte': 'Mayotte',
    'Mexico': 'Mexico',
    'Micronesia, Federated States of': 'Mikronesia, Fødererte stater av',
    'Moldova, Republic of': 'Moldova, Republikken',
    'Monaco': 'Monaco',
    'Mongolia': 'Mongolia',
    'Montenegro': 'Montenegro',
    'Montserrat': 'Montserrat',
    'Marocco': 'Marocco',
    'Mozambique': 'Mosambik',
    'Myanmar': 'Myanmar',
    'Namibia': 'Namibia',
    'Nauru': 'Nauru',
    'Nepal': 'Nepal',
    'Netherlands': 'Nederland',
    'Netherlands Antilles': 'De nederlandske Antillene',
    'New Caledonia': 'Ny Caledonia',
    'New Zealand': 'New Zealand',
    'Nicaragua': 'Nicaragua',
    'Niger': 'Niger',
    'Nigeria': 'Nigeria',
    'Niue': 'Niue',
    'Norfolk Island': 'Norfolk Island',
    'Northern Mariana Islands': 'Nord-Marianene',
    'Norway': 'Norge',
    'Oman': 'Oman',
    'Pakistan': 'Pakistan',
    'Palau': 'Palau',
    'Palestinian Territory, Occupied': 'Palestinsk territorium, okkupert',
    'Panama': 'Panama',
    'Papua New Guinea': 'Papua Ny-Guinea',
    'Paraguay': 'Paraguay',
    'Peru': 'Peru',
    'Philippines': 'Filippinene',
    'Pitcairn': 'Pitcairn',
    'Poland': 'Polen',
    'Portugal': 'Portugal',
    'Puerto Rico': 'Puerto Rico',
    'Qatar': 'Qatar',
    'Reunion': 'Réunion',
    'Romania': 'Romania',
    'Russian Federation': 'Russland',
    'Rwanda': 'Rwanda',
    'Saint Helena': 'St. Helena',
    'Saint Kitts and Nevis': 'Saint Kitts og Nevis',
    'Saint Lucia': 'Saint Lucia',
    'Saint Pierre and Miquelon': 'Saint Pierre og Miquelon',
    'Saint Vincent and The Grenadines': 'Saint Vincent og Grenadinene',
    'Samoa': 'Samoa',
    'San Marino': 'San Marino',
    'Sao Tome and Principe': 'Sao Tome og Principe',
    'Saudi Arabia': 'Saudi-Arabia',
    'Senegal': 'Senegal',
    'Serbia': 'Serbia',
    'Seychelles': 'Seychellene',
    'Sierra Leone': 'Sierra Leone',
    'Singapore': 'Singapore',
    'Slovakia': 'Slovakia',
    'Slovenia': 'Slovenia',
    'Solomon Islands': 'Solomon øyene',
    'Somalia': 'Somalia',
    'South Africa': 'Sør-Afrika',
    'South Georgia and The South Sandwich Islands':
      'Sør-Georgia og Sør-Sandwichøyene',
    'Spain': 'Spania',
    'Sri Lanka': 'Sri Lanka',
    'Sudan': 'Sudan',
    'Suriname': 'Surinam',
    'Svalbard and Jan Mayen': 'Svalbard og Jan Mayen',
    'Swaziland': 'Swaziland',
    'Sweden': 'Sverige',
    'Swizerland': 'Swizerland',
    'Syrian Arab Republic': 'Syria',
    'Taiwan': 'Taiwan',
    'Tajikistan': 'Tadsjikistan',
    'Tanzania, United Republic of': 'Tanzania, Forente republikk',
    'Thailand': 'Thailand',
    'Timor-leste': 'Øst-Timor',
    'Togo': 'Togo',
    'Tokelau': 'Tokelau',
    'Tonga': 'Tonga',
    'Trinidad and Tobago': 'Trinidad og Tobago',
    'Tunisia': 'Tunisia',
    'Turkey': 'Tyrkia',
    'Turkmenistan': 'Turkmenistan',
    'Turks and Caicos Islands': 'Turks- og Caicosøyene',
    'Tuvalu': 'Tuvalu',
    'Uganda': 'Uganda',
    'Ukraine': 'Ukraina',
    'United Arab Emirates': 'De forente arabiske emirater',
    'United Kingdom': 'Storbritannia',
    'United States': 'De forente stater',
    'United States Minor Outlying Islands': 'USAs ytre småøyer',
    'Uruguay': 'Uruguay',
    'Uzbekistan': 'Usbekistan',
    'Vanuatu': 'Vanuatu',
    'Venezuela': 'Venezuela',
    'Viet Nam': 'Vietnam',
    'Virgin Islands, British': 'Jomfruøyene, Britiske',
    'Virgin Islands, U.S.': 'Jomfruøyene, U.S.',
    'Wallis and Futuna': 'Wallis og Futuna',
    'Western Sahara': 'Vestre Sahara',
    'Yemen': 'Jemen',
    'Zambia': 'Zambia',
    'Zimbabwe': 'Zimbabwe',
  },
  //Alt texts
  'alt.example-1': 'to selvkjørende biler',
  'alt.example-2': 'hånd som holder en mobiltelefon',
  'alt.example-3': 'kvinneansikt',
  'alt.defining-ai': 'virvar av KI-relaterte ord',
  'alt.turing-test': 'mann som chatter over data med en robot og en kvinne',
  'alt.chinese-room': 'eksperimentet Det kinesiske rom',
  'alt.chicken-crossing':
    'robot i en båt som ser på en høne, en rev og en sekk med korn',
  'alt.a-to-b': 'hånd som holder en telefon og bruker navigeringsapp',
  'alt.tic-tac-toe': 'to barn som spiller bondesjakk',
  'alt.self-driving-car': 'kvinne som går foran en selvkjørende bil',
  'alt.spam-or-ham': 'vanlig epost og søppelepost',
  'alt.mnist': 'MNIST datasett',
  'alt.nearest-neighbor': 'to personer som handler',
  'alt.nearest-neighbor-graph': 'nærmeste nabo-klassifikator',
  'alt.recommendation': 'mann og kvinne som lytter til musikk på telefonen',
  'alt.price-real-estate': 'tre forskjellige hus',
  'alt.supervised-learning': 'menneske som lærer en robot',
  'alt.dice': 'dice',
  'alt.chess': 'sjakkbrett',
  'alt.poker': 'hånd som holder spillkort',
  'alt.eyes': 'tre personer med brune øyne, én person med blå øyne',
  'alt.search-1': 'en enkeltsti som fører til mål',
  'alt.search-2': 'fire stier som fører til det samme målet',
  'alt.chicken-crossing-1': 'høne som krysser opprinnelig tilstand',
  'alt.chicken-crossing-2': 'høne som krysser første overgang',
  'alt.chicken-crossing-3': 'høne som krysser alle overgangene',
  'alt.chicken-crossing-4': 'høne som krysser med best rute',
  'alt.chicken-crossing-5': 'chicken crossing initial state',
  'alt.implication-1': 'mann og kvinne',
  'alt.implication-2': 'øyne',
  'alt.implication-3': 'overvåkningskamera',
  'alt.implication-4': 'robot som jobber på en laptop',
  'alt.oddchange': 'sol og skyer',
  'alt.brain': 'tegning av en hjerne',
  'alt.carstop': 'selvkjørende bil vet stoppskilt',
  'alt.gan':
    'robot som holder et bilde av en katt og et bilde av et stoppskilt',
  'alt.hammock': 'robot med håndvifte foran mann i hengekøye',
  'alt.game-tree-1': 'spilltre 1',
  'alt.game-tree-2': 'spilltre 2',
  'alt.game-tree-3': 'spilltre 3',
  'alt.game-tree-4': 'spilltre 4',
  'alt.bayes-rule-1':
    'illustrasjon som viser falske positiver og negativer ved kreftscanning',
  'alt.bayes-rule-2':
    'illustrasjon som viser falske positiver og negativer ved kreftscanning',
  'alt.defineai': 'jumble of words related to AI',
  'alt.winter': 'robot som sover i snøen',
  'alt.terminator': 'skummel robot',
}
