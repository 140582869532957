// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'sl',
  'globals.signIn': 'Prijava',
  'globals.logIn': 'Prijava',
  'globals.signUp': 'Registracija',
  'globals.signOut': 'Odjava',
  'globals.logOut': 'Odjava',
  'globals.submit': 'Pošlji',
  'globals.submitting': 'Pošiljanje ...',
  'globals.yourEmail': 'Vaš e-naslov',
  'globals.yourPassword': 'Vaše geslo',
  'globals.courseOverview': 'Pregled tečaja',
  'globals.forgotPassword': 'Ste pozabili geslo?',
  'globals.invalidCredentials': 'Neveljavni varnostni elementi',
  'globals.section': 'Tema',
  'globals.exercises': 'Naloge',
  'globals.chapter': 'Poglavje',
  'globals.introToAI': 'Uvod v AI',
  'globals.buildingAI': 'Zgradba AI',
  'globals.acceptPolicy': 'Sprejmite pravilnik',
  // section
  'section.footerEndOfTheChapter': 'Prišli ste do konca poglavja',
  'section.footerEndOfTheCourseTitle': 'Prišli ste do konca tečaja.',
  'section.footerEndOfTheCourseText': 'Ko boste izpolnili vse naloge in oddali medsebojne preglede, bo naše osebje pregledalo vaše odgovore.',
  'section.footerEndOfTheCourseBAI': 'Nadaljujte svojo pot, da boste lahko uresničili svojo prvo idejo o AI.',
  'section.nextSection': 'Naslednja tem',
  'section.nextChapter': 'Naslednje poglavje',
  'section.start': 'Začetek',
  'section.logisticRegressionExample.slope': 'Nagib',
  'section.logisticRegressionExample.intercept': 'Regresijska konstanta',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Čakanje na vaš pregled',
  'quiz.rejectedPeerReview': 'Zavrnjeno pri medsebojnem pregledu',
  'quiz.unsubmittedAnswer': 'Neposlan odgovor',
  'quiz.pendingPeerReview': 'Manjkajoči medsebojni pregled',
  // quiz
  'quiz.status.answered': 'Odgovorjeno',
  'quiz.status.rejected': 'Odgovor zavrnjen, poskusite znova',
  'quiz.status.unanswered': 'Brez odgovora',
  'quiz.status.exercisesCompleted': 'Opravljene naloge',
  'quiz.status.correctAnswers': 'Pravilni odgovori',
  'quiz.message.rejectedInReview': 'Zavrnjeno pri medsebojnem pregledu',
  'quiz.youAnswered': 'Vaš odgovor je',
  'quiz.yourAnswer': 'Vaš odgovor ...',
  'quiz.yourScore': 'Vaš rezultat:',
  'quiz.quizNotLoaded': 'Kviza ni mogoče naložiti',
  'quiz.words': 'Število besed:',
  'quiz.exampleAnswer': 'Primer odgovora:',
  // peer review
  'peerreview.question1': 'Odgovor obravnava zadevno temo',
  'peerreview.question2': 'Odgovor je celovit',
  'peerreview.question3': 'Odgovor je dobro utemeljen',
  'peerreview.question4': 'Odgovor je razumljiv',
  'peerreview.thankYou': 'Hvala, ker ste pregledali odgovore soudeležencev.',
  'peerreview.giveMoreReviews': 'Če utegnete,',
  'peerreview.giveMoreReviewsButton': 'oddajte več pregledov',
  'peerreview.notEnoughAnswers': 'Običajno bi bil tukaj objavljen medsebojni pregled, vendar te naloge ni izpolnilo dovolj ljudi.',
  'peerreview.selectAnswerMessage': 'Čas je za medsebojni pregled V nadaljevanju je nekaj odgovorov drugih udeležencev. Izberite enega in ga preglejte. Ponovite vsaj trikrat.',
  'peerreview.minimumPeerReviewWarning': 'Naloga se lahko sprejme, šele ko boste oddali vsaj tri medsebojne preglede.',
  'peerreview.select': 'Izberi',
  'peerreview.reportSpam': 'Prijavite kot neželeno vsebino',
  'peerreview.feedback': 'Vaš odgovor je bil pregledan in je sprejet. Drugi udeleženci o njem menijo:',
  // exercise17
  'quiz.exercise17.xLabel': 'Število let izobraževanja',
  'quiz.exercise17.yLabel': 'Pričakovana življenjska doba v letih',
  'quiz.exercise17.textPart1': 'Preučimo povezavo med skupnim številom let obiskovanja šole (vključno z vsemi leti od predšolskega do univerzitetnega izobraževanja) in pričakovano življenjsko dobo. Na spodnji sliki so prikazani podatki iz treh različnih držav, predstavljeni s pikami:',
  'quiz.exercise17.textPart2': 'Imamo eno državo, v kateri je povprečno število let v šoli 10, pričakovana življenjska doba pa 57 let, drugo državo, v kateri je povprečno število let v šoli 13, pričakovana življenjska doba pa 53 let, in tretjo državo, v kateri je povprečno število let v šoli 20, pričakovana življenjska doba pa 80 let.',
  'quiz.exercise17.textPart3': 'Končne točke neprekinjene črte lahko povlečete tako, da črto umestite tako, da sledi trendu podatkovnih točk. Upoštevajte, da se črta ne bo mogla popolnoma prilegati podatkovnim točkam, s čimer ni nič narobe: nekatere podatkovne točke bodo ležale nad črto, nekatere pa pod njo. Najpomembneje je, da črta opisuje splošni trend.',
  'quiz.exercise17.textPart4': 'Ko črto umestite, jo lahko uporabite za napovedovanje pričakovane življenjske dobe.',
  'quiz.exercise17.textPart5': 'Kaj lahko na podlagi podatkov poveste o pričakovani življenjski dobi ljudi, ki so se izobraževali 15 let? Opozorilo: upoštevajte, da morda ne boste mogli dati zanesljive napovedi, tudi če lahko s prilagajanjem črte dobite določeno napoved, ki je natančna do dela leta. Pri odgovoru upoštevajte, da je količina podatkov omejena.',
  // exercise18
  'quiz.exercise18.xLabel': 'Število let izobraževanja',
  'quiz.exercise18.yLabel': 'Pričakovana življenjska doba v letih',
  'quiz.exercise18.textPart1': 'V prejšnji nalogi smo imeli podatke samo iz treh držav. Celotni nabor podatkov zajema podatke iz 14 različnih držav, ki so predstavljeni v spodnjem grafu:',
  'quiz.exercise18.textPart2': 'Ali bi se vaša napoved o pričakovani življenjski dobi ljudi s 15 leti izobraževanja glede na te podatke spremenila? Če bi se, zakaj bi se?',
  'quiz.exercise18.textPart3': 'Katera od naslednjih možnosti bi se najbolje ujemala z vašo oceno pričakovane življenjske dobe ljudi s 15 leti izobraževanja? Izberite najnatančnejšo možnost, ki se vam zdi utemeljena, tako da model ravne črte prilagodite zgornjim podatkom.',
  // exercise19
  'quiz.exercise19.xLabel': 'Število ur učenja',
  'quiz.exercise19.yLabel': 'Verjetnost uspešno opravljenega izpita',
  'quiz.exercise19.textPart1': 'Vsaka pika na sliki predstavlja enega študenta. Na dnu slike je lestvica, ki kaže, koliko ur se je študent učil za izpit, študenti, ki so uspešno opravili izpit, so prikazani kot pike na vrhu grafa, tisti, ki ga niso uspešno opravili, pa so prikazani na dnu. Lestvico na levi bomo uporabili za prikaz predvidene verjetnosti uspešno opravljenega izpita, ki jo bomo dobili na podlagi modela logistične regresije, kot je pojasnjeno v nadaljevanju. Slika v grobem kaže, da so imeli študenti, ki so se učili več, boljše možnosti, da uspešno opravijo izpit. Zlasti skrajni primeri so intuitivni: z manj kot eno uro dela je zelo težko uspešno opraviti izpit, medtem ko bo večina tistih, ki so vložili veliko dela, uspešna. Kaj pa tisti, ki so po trajanju učenja nekje med obema skrajnostma? Kakšne so vaše možnosti za uspešno opravljen izpit, če se učite šest ur?',
  'quiz.exercise19.textPart2': 'Verjetnost uspešno opravljenega izpita lahko količinsko opredelimo z logistično regresijo. Krivuljo na sliki je mogoče razložiti kot verjetnost uspešno opravljenega izpita: po petih urah učenja je na primer verjetnost uspešno opravljenega izpita nekaj več kot 20-odstotna. Ne bomo podrobno navajali, kako dobiti krivuljo, je pa to podobno načinu, kako dobiti uteži pri linearni regresiji.',
  'quiz.exercise19.textPart3': 'Približno koliko ur bi se morali glede na zgornjo sliko učiti, če bi hoteli z 80-odstotno verjetnostjo uspešno opraviti univerzitetni izpit?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Vhodne vrednosti',
  'quiz.exercise22.sigmoidYLabel': 'Izhodne vrednosti pri sigmoidni funkciji',
  'quiz.exercise22.stepXLabel': 'Vhodne vrednosti',
  'quiz.exercise22.stepYLabel': 'Izhodne vrednosti pri stopničasti funkciji',
  'quiz.exercise22.identityXLabel': 'Vhodne vrednosti',
  'quiz.exercise22.identityYLabel': 'Izhodne vrednosti pri identični funkciji',
  'quiz.exercise22.textPart1': 'Spodaj so prikazani grafi za tri različne aktivacijske funkcije z različnimi lastnostmi. Najprej imamo sigmoidno funkcijo, nato stopničasto funkcijo in na koncu identično funkcijo.',
  // exercise23
  'quiz.exercise23.happyFaces': 'nasmejani emotikoni, pravilno razvrščeni',
  'quiz.exercise23.sadFaces': 'žalostni emotikoni, pravilno razvrščeni',
  // certificate availability
  'certificate.failedToCheck': 'Preverjanje razpoložljivosti potrdila ni bilo uspešno',
  'certificate.failedToGenerate': 'Ustvarjanje potrdila ni bilo uspešno',
  'certificate.notYetAvailable': 'Vaše potrdilo bo tukaj na voljo, ko ga bomo uradno potrdili. To lahko traja nekaj časa. Hvala za potrpljenje!',
  'certificate.updateName': 'Vaše potrdilo je na voljo. Svoje ime in priimek za prikaz na potrdilu posodobite z obrazcem, ki je na voljo na vaši profilni strani.',
  'certificate.generate': 'Spodaj ustvarite potrdilo.',
  'certificate.generateTextPart1': 'Potrdilo bo ustvarjeno za',
  'certificate.generateTextPart2': 'Pred ustvarjanjem potrdila preverite, da je ime pravilno, ker ga pozneje ne bo mogoče spremeniti. Ime lahko spremenite s spodnjim obrazcem.',
  'certificate.generateButton': 'Ustvari potrdilo',
  'certificate.downloadButton': 'Prenesi potrdilo',
  'certificate.checkingAvailability': 'Preverjanje razpoložljivosti potrdila ...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Pravilno razvrščeno',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Nepravilno razvrščeno',
  // section blocks
  'section.block.note': 'Opomba',
  'section.block.example': 'Primer',
  'section.block.keyTerminology': 'Ključna terminologija',
  'section.partSummary.spectrumTextPart1': 'Pridružite se ',
  'section.partSummary.spectrum': 'skupnosti Elements of AI',
  'section.partSummary.spectrumTextPart2': ', če želite razpravljati o tem poglavju ali imate vprašanja o njem.',
  'section.partSummary.feedbackTitle': 'Pošljite nam svoje mnenje o tem delu',
  'section.partSummary.feedbackPlaceholder': 'Napišite svoje mnenje tukaj ...',
  'section.partSummary.feedbackSuccess': 'Hvala za vaše mnenje.',
  // 404
  'globals.404.title': '404 – Strani ni mogoče najti',
  'globals.404.intro': "Izbrali ste pot, ki ne obstaja ... Oprostite.",
  'globals.loading': 'Nalaganje …',
  // footer / menu
  'globals.menu': 'Meni',
  'globals.menu.about': 'O tečaju',
  'globals.menu.faq': 'Pogosta vprašanja',
  'globals.menu.privacyPolicy': 'Izjava o varstvu osebnih podatkov',
  'globals.menu.termsofService': 'Pogoji storitve',
  'globals.menu.myProfile': 'Moj profil',
  // Dashboard/Index
  'dashboard.title': 'Dobrodošli na tečaju.',
  'dashboard.congratulations.title': 'Čestitamo!',
  'dashboard.congratulations.message': 'Tečaj Elements of AI ste zaključili',
  'dashboard.congratulations.downloadCertificate': 'Prenesi potrdilo',
  'dashboard.notLoggedIn.title': 'Pozdravljeni.',
  'dashboard.notLoggedIn.message': 'Za popoln dostop do tečaja se registrirajte ali prijavite. Oboje je brezplačno.',
  // Account
  'account.certificate': 'Potrdilo',
  'account.myProfile': 'Moj profil',
  'account.profileInformation': 'Informacije o profilu',
  'account.credits': 'Kreditne točke ECTS',
  'account.changePassword': 'Spremeni geslo',
  'account.changeLanguage': 'Spremeni jezik',
  'account.goToCourse': 'Pojdi na tečaj',
  'account.buildingAINew': 'Nov tečaj angleščine',
  'account.accept': 'Sprejmite',
  'account.decline': 'Zavrnite',
  'account.courses': 'Tečaji',
  // Sign Up page
  'signUp.description': 'Za ustvarjenje novega računa mooc.fi izpolnite spodnji obrazec',
  'signUp.email': 'Vaš e-naslov',
  'signUp.firstName': 'Ime',
  'signUp.lastName': 'Priimek',
  'signUp.postalCode': 'Poštna številka',
  'signUp.country': 'Kje živite?',
  'signUp.Password': 'Geslo',
  'signUp.confirmPassword': 'Potrdite geslo',
  'signUp.howToStudyTitle': 'Kako se želite učiti?',
  'signUp.6weeksTitle': '6-tedenski tečaj',
  'signUp.6weeksSubTitle': 'Priporočeno (npr. 5 ur na teden)',
  'signUp.6weeksText': 'Določitev roka dokazano prispeva k zaključku tečaja.',
  'signUp.selfPacedTitle': 'V svojem tempu',
  'signUp.selfPacedText': 'Tečaj lahko opravite v svojem tempu.',
  'signUp.ectsTitle': 'Boste zaprosili za kreditne točke ECTS?',
  'signUp.ectsTitleSwe': ' ',
  'signUp.ectsStudent': 'Sem študent/-ka na Univerzi v Helsinkih. Če obkljukate to okence in vpišete svojo študentsko številko, boste samodejno prejeli kreditne točke ECTS.',
  'signUp.ectsStudentSwe': ' ',
  'signup.ectsStudentSweLink': ' ',
  'signUp.ectsOpenUni': 'Obkljukajte to okence, da boste prejeli e-pošto z navodili za uporabo kreditnih točk ECTS',
  'signUp.studentNumber': 'Študentska številka Univerze v Helsinkih (če ste študent/-ka)',
  'signUp.privacyOptIn': 'Za raziskovalne namene dovoljujem uporabo zbranih podatkov o mojem učnem vedenju. Podatki vsebujejo informacije o uporabi gradiva in nalogah v okviru tečaja. Iz publikacij ni mogoče identificirati nobenega posameznika.',
  'signUp.marketingOptIn': 'Strinjam se s prejemanjem obvestil o prihodnjih jezikovnih različicah in informacij o novih tečajih. Strinjam se, da bom svoje kontaktne podatke delil z namenom prejemanja prilagojenih sporočil na neodvisnih platformah. Preberite več o našem varstvu osebnih podatkov.',
  'signUp.acceptPolicyFirst': 'Sprejmem politiko zasebnosti in pogoje storitve.',
  'signUp.privacyLink': 'Preberite si našo izjavo o varstvu osebnih podatkov.',
  'signUp.error': 'V vašem obrazcu so napake. Preverite svoj obrazec.',
  // Sign in page
  'signIn.title': 'Prijava',
  'signIn.intro': 'Za registracijo s svojim računom mooc.fi uporabite spodnji obrazec',
  'signIn.email': 'Vaš e-naslov',
  'signIn.password': 'Vaše geslo',
  'signIn.noAccount': "Nimate računa?",
  'signIn.signUpNow': 'Registrirajte se zdaj',
  // Forgot password
  'pwForget.title': 'Pozabljeno geslo',
  'pwForget.description': 'Izpolnite svoj e-naslov za račun mooc.fi in poslali vam bomo povezavo na ponastavitev gesla',
  'pwForget.sendResetLink': 'Pošlji povezavo na ponastavitev',
  'pwForget.completedDescription': 'Kmalu boste prejeli elektronsko sporočilo od mooc.fi s povezavo na ponastavitev gesla. Če sporočila niste opazili, preverite mapo z neželeno pošto.',
  'pwForget.problemDescription': 'Če naletite na težavo, pišite na e-naslov mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent': 'Elektronsko sporočilo o ponastavitvi gesla poslano',
  'pwForget.emailPlaceholder': 'E-naslov',
  // password change
  'pwChange.authError': 'Neuspešna avtentikacija. Poskusite se ponovno registrirati',
  'pwChange.unexpError': 'Posodobitev gesla ni uspela zaradi nepričakovane napake',
  'pwChange.required': 'Obvezno',
  'pwChange.pwMustMatch': 'Gesli se morata ujemati',
  'pwChange.currentPassword': 'Sedanje geslo',
  'pwChange.newPassword': 'Novo geslo',
  'pwChange.confirmPassword': 'Potrdi novo geslo',
  'pwChange.changingPassword': 'Sprememba gesla ...',
  'pwChange.changePassword': 'Spremeni geslo',
  'pwChange.passwordChanged': 'Geslo je spremenjeno',
  // user details
  'userDetails.authError': 'Neuspešna avtentikacija. Poskusite se ponovno registrirati',
  'userDetails.unexpError': 'Posodobitev profila ni uspela zaradi nepričakovane napake',
  'userDetails.required': 'Obvezno',
  'userDetails.invalidEmail': 'Neveljaven e-naslov',
  'userDetails.email': 'E-naslov',
  'userDetails.emailAddress': 'Elektronski naslov',
  'userDetails.firstName': 'Ime',
  'userDetails.lastName': 'Priimek',
  'userDetails.language': 'Jezik',
  'userDetails.updatingProfile': 'Posodabljanje profila ...',
  'userDetails.updateProfile': 'Posodobitev profila',
  'userDetails.profileUpdated': 'Profil posodobljen',
  // languages
  'languages.en.long': 'Angleščina',
  'languages.fi.long': 'Finščina',
  'languages.se.long': 'Švedščina',
  'languages.de.long': 'Nemščina (Nemčija)',
  'languages.ee.long': 'Estonščina',
  'languages.it.long': 'Italijanščina',
  'languages.fr.long': 'Francoščina (Francija)',
  'languages.no.long': 'Norveščina',
  'languages.cs.long': 'Češčina',
  'languages.lv.long': 'Latvijščina',
  'languages.da.long': 'Danščina',
  'languages.ro.long': 'Romunščina',
  'languages.pl.long': 'Poljščina',
  'languages.en-ie.long': 'Angleščina (Irska)',
  'languages.mt.long': 'Malteščina',
  'languages.fr-be.long': 'Francoščina (Belgija)',
  'languages.lt.long': 'Litovščina',
  'languages.nl-be.long': 'Nizozemščina (Belgija)',
  'languages.hr.long': 'Hrváščina',
  'languages.en-lu.long': 'Angleščina (Luksemburg)',
  'languages.bg.long': 'Bolgarščina',
  'languages.sl.long': 'Slovenščina',
  'languages.sk.long': 'Slovaščina',
  'languages.pt.long': 'Portugalščina',
  'languages.nl.long': 'Nizozemščina (Nizozemska)',
  'languages.de-at.long': 'Nemščina (Avstrija)',
  'languages.el.long': 'Grščina',
  'languages.es.long': 'Španščina',
  'languages.is.long': 'Islandščina',
  'languages.ga.long': 'Írska gélščina',
  // Email verfification
  'emailVerification.completedMessage': 'Veljavnost vašega e-poštnega naslova je bila preverjena',
  'emailVerification.verified': 'Preverjeno',
  // Exercises
  'exercise.loading': 'Nalaganje naloge ...',
  'exercise.signUp': 'Za reševanje nalog se registrirajte',
  'exercise.score': 'Vaš rezultat:',
  'exercise.wrongLocale': 'Napačen jezik',
  // Part tempplate
  'partTemplate.intro1': 'V poglavju ',
  'partTemplate.intro2': ' bomo obravnavali naslednja tema. Za začetek kliknite spodaj:',
  'partTemplate.spectrumPart1': 'Pridružite se ',
  'partTemplate.spectrum': 'skupnosti Elements of AI',
  'partTemplate.spectrumPart2': ', če želite razpravljati o tem poglavju ali imate vprašanja o njem.',
  // missing info
  'missingInfo.title': 'Manjkajoče informacije',
  'missingInfo.body': 'Ta vprašanja običajno zastavljamo, ko se uporabniki registrirajo, vi pa ste se prijavili v obstoječi račun mooc.fi. Za nadaljevanje izpolnite spodnji obrazec.',
  'missingInfo.privacyPolicy': 'Preberite si našo izjavo o varstvu osebnih podatkov.',
  'missingInfo.error': 'V vašem obrazcu so napake. Preverite svoj obrazec.',
  'missingInfo.privacyPolicyUpdateBody': "Posodobili smo naš pravilnik o zasebnosti. Tu je povzetek sprememb. Naše tečaje oglašujemo potencialnim novim študentom z namenom oblikovanja občinstva na tržnih platformah. To lahko vključuje izmenjavo e-poštnih naslovov s temi platformami. Za isti namen se anonimizirani podatki o dejavnosti delijo s tržnimi mrežami. Skupno rabo e-poštnih informacij lahko preprečite tako, da ne potrdite polje za dovoljenje promocij v profilu tečaja. Če želite preprečiti skupno rabo podatkov o dejavnosti, lahko spremenite nastavitve piškotkov na spletnem mestu tečaja. Če želite še naprej uporabljati storitev, morate sprejeti te pogoje.",
  'missingInfo.privacyPolicyUpdateLink': 'Pravilnik o zasebnosti si lahko ogledate tukaj.',
  // routes
  'routes.LANDING': '/sl/',
  'routes.SIGN_UP': '/sl/signup',
  'routes.SIGN_IN': '/sl/signin',
  'routes.MISSING_INFO': '/sl/missinginfo',
  'routes.PASSWORD_FORGET': '/sl/pw-forget',
  'routes.HOME': '/sl/home',
  'routes.ACCOUNT': '/sl/account',
  'routes.ACCEPT_POLICY': '/sl/accept-policy',
  'routes.PRIVACY': 'https://www.elementsofai.si/faq/izjava-o-varstvu-osebnih-podatkov',
  'routes.FAQ': 'https://www.elementsofai.si/faq',
  'routes.ABOUT': 'https://www.elementsofai.si',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/sl-slovenska-skupnost',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/sl/faq/how-can-i-get-2-ects-credits',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Skodelic kave na dan',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Napisanih vrstic kode',
  // meta
  'meta.title': 'Brezplačni spletni tečaj',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Več informacij o tečaju o umetni inteligenci za študente in poslovneže Univerze v Helsinkih in Reaktorja. Znanje programiranja ali zahtevne matematike ni potrebno.',
  'meta.sitename': 'Elements of AI',
  // ECTS
  'ects.text': 'Po zaključku tečaja boste tukaj prejeli navodila, kako zaprositi za kreditne točke ECTS.',
  'ects.textCompleted': 'Če imate finsko številko socialnega zavarovanja, lahko zaprosite za kreditne točke, tako da kliknete ',
  'ects.link': 'tukaj.',
  'ects.info': 'Bi se radi prijavili za kredite točke ECTS?',
  'ects.readMore': 'Preberi več',
  // Countries
  'countryselect.select': "Prosim izberite",
  'Finland': "Finska",
  'Estonia': "Estonija",
  'Germany': "Nemčija",
  'Sweden': "Švedska",
  "Countries": {
    "Afganistan": "Afganistan",
    "Åland Islands": "Olandski otoki",
    "Albania": "Albanija",
    "Algeria": "Alžirija",
    "American Samoa": "Ameriška Samoa",
    "Andorra": "Andora",
    "Angola": "Angola",
    "Anguilla": "Angvila",
    "Antarctica": "Antarktika",
    "Antigua and Barbuda": "Antigva in Barbuda",
    "Argentina": "Argentina",
    "Armenia": "Armenija",
    "Aruba": "Aruba",
    "Australia": "Avstralija",
    "Austria": "Avstrija",
    "Azerbaijan": "Azerbejdžan",
    "Bahamas": "Bahami",
    "Bahrain": "Bahrajn",
    "Bangladesh": "Bangladeš",
    "Barbados": "Barbados",
    "Belarus": "Belorusija",
    "Belgium": "Belgija",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermudi",
    "Bhutan": "Butan",
    "Bolivia": "Bolivija",
    "Bosnia and Herzegovina": "Bosna in Hercegovina",
    "Botswana": "Bocvana",
    "Bouvet Island": "Otok Bouvet",
    "Brazil": "Brazilija",
    "British Indian Ocean Territory": "Britansko ozemlje Indijskega oceana",
    "Brunei Darussalam": "Brunej Darussalam",
    "Bulgaria": "Bolgarija",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambodia": "Kambodža",
    "Cameroon": "Kamerun",
    "Canada": "Kanada",
    "Cape Verde": "Zelenortski otoki",
    "Cayman Islands": "Kajmanski otoki",
    "Central African Republic": "centralna afriška republika",
    "Chad": "Čad",
    "Chile": "Čile",
    "China": "Kitajska",
    "Christmas Island": "Božični otok",
    "Cocos (Keeling) Islands": "Kokosovi (Keeling) otoki",
    "Colombia": "Kolumbija",
    "Comoros": "Komori",
    "Congo": "Kongo",
    "Congo, The Democratic Republic of The": "Kongo, Demokratična republika",
    "Cook Islands": "Cookovi otoki",
    "Costa Rica": "Kostarika",
    "Cote D'ivoire": "Slonokoščena obala",
    "Croatia": "Hrvaška",
    "Cuba": "Kuba",
    "Cyprus": "Ciper",
    "Czech Republic": "Češka",
    "Denmark": "Danska",
    "Djibouti": "Džibuti",
    "Dominica": "Dominika",
    "Dominican Republic": "Dominikanska republika",
    "Ecuador": "Ekvador",
    "Egypt": "Egipt",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Ekvatorialna Gvineja",
    "Eritrea": "Eritreja",
    "Estonia": "Estonija",
    "Ethiopia": "Etiopija",
    "Falkland Islands (Malvinas)": "Falklandski otoki (Malvinas)",
    "Faroe Islands": "Ferski otoki",
    "Fiji": "Fidži",
    "Finland": "Finska",
    "France": "Francija",
    "French Guiana": "Francoska Gvajana",
    "French Polynesia": "Francoska Polinezija",
    "French Southern Territories": "Francoska južna ozemlja",
    "Gabon": "Gabon",
    "Gambia": "Gambija",
    "Georgia": "Gruzija",
    "Germany": "Nemčija",
    "Ghana": "Gana",
    "Gibraltar": "Gibraltar",
    "Greece": "Grčija",
    "Greenland": "Grenlandije",
    "Grenada": "Grenada",
    "Guadeloupe": "Gvadalupe",
    "Guam": "Guam",
    "Guatemala": "Gvatemala",
    "Guernsey": "Guernsey",
    "Guinea": "Gvineja",
    "Guinea-Bissau": "Gvineja Bissau",
    "Guyana": "Gvajana",
    "Haiti": "Haiti",
    "Heard Island and Mcdonald Islands": "Otok Heard in Mcdonald",
    "Holy See (Vatican City State)": "Sveti sedež (Vatikanska mestna država)",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Madžarska",
    "Iceland": "Islandija",
    "India": "Indija",
    "Indonesia": "Indonezija",
    "Iran, Islamic Republic of": "Iran, Islamska republika",
    "Iraq": "Irak",
    "Ireland": "Irska",
    "Isle of Man": "Otok Man",
    "Israel": "Izrael",
    "Italy": "Italija",
    "Jamaica": "Jamajka",
    "Japan": "Japonska",
    "Jersey": "Jersey",
    "Jordan": "Jordan",
    "Kazakhstan": "Kazahstan",
    "Kenya": "Kenija",
    "Kiribati": "Kiribati",
    "Korea, Democratic People's Republic of": "Koreja, Demokratična ljudska republika",
    "Korea, Republic of": "Koreja, Republika",
    "Kosovo": "Kosovo",
    "Kuwait": "Kuvajt",
    "Kyrgyzstan": "Kirgizistan",
    "Lao People's Democratic Republic": "Laoška ​​ljudska demokratična republika",
    "Latvia": "Latvija",
    "Lebanon": "Libanon",
    "Lesotho": "Lesoto",
    "Liberia": "Liberija",
    "Libyan Arab Jamahiriya": "Libijska arabska Jamahirija",
    "Liechtenstein": "Lihtenštajn",
    "Lithuania": "Litva",
    "Luxembourg": "Luksemburg",
    "Macao": "Macao",
    "Macedonia, The Former Yugoslav Republic of": "Makedonija, Nekdanja jugoslovanska republika",
    "Madagascar": "Madagaskar",
    "Malawi": "Malavi",
    "Malaysia": "Malezija",
    "Maldives": "Maldivi",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Maršalovi otoki",
    "Martinique": "Martinik",
    "Mauritania": "Mavretanija",
    "Mauritius": "Mavricij",
    "Mayotte": "Majota",
    "Mexico": "Mehika",
    "Micronesia, Federated States of": "Mikronezija, Federativne države",
    "Moldova, Republic of": "Moldavija, Republika",
    "Monaco": "Monako",
    "Mongolia": "Mongolija",
    "Montenegro": "Črna gora",
    "Montserrat": "Montserrat",
    "Marocco": "Maroko",
    "Mozambique": "Mozambik",
    "Myanmar": "Mjanmar",
    "Namibia": "Namibija",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Nizozemska",
    "Netherlands Antilles": "Nizozemski Antili",
    "New Caledonia": "Nova Kaledonija",
    "New Zealand": "Nova Zelandija",
    "Nicaragua": "Nikaragva",
    "Niger": "Niger",
    "Nigeria": "Nigerija",
    "Niue": "Niue",
    "Norfolk Island": "Otok Norfolk",
    "Northern Mariana Islands": "Severno Mariansko otočje",
    "Norway": "Norveška",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestinian Territory, Occupied": "Palestinsko ozemlje, zasedeno",
    "Panama": "Panama",
    "Papua New Guinea": "Papua Nova Gvineja",
    "Paraguay": "Paragvaj",
    "Peru": "Peru",
    "Philippines": "Filipini",
    "Pitcairn": "Pitcairn",
    "Poland": "Na Poljskem",
    "Portugal": "Portugalska",
    "Puerto Rico": "Portoriko",
    "Qatar": "Katar",
    "Reunion": "Reunion",
    "Romania": "Romunija",
    "Russian Federation": "Ruska federacija",
    "Rwanda": "Ruanda",
    "Saint Helena": "Sveta Helena",
    "Saint Kitts and Nevis": "Saint Kitts in Nevis",
    "Saint Lucia": "Sveta Lucija",
    "Saint Pierre and Miquelon": "Saint Pierre in Miquelon",
    "Saint Vincent and The Grenadines": "Sveti Vincent in Grenadini",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "Sao Tome in Principe",
    "Saudi Arabia": "Savdska Arabija",
    "Senegal": "Senegal",
    "Serbia": "Srbija",
    "Seychelles": "Sejšeli",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapur",
    "Slovakia": "Slovaška",
    "Slovenia": "Slovenija",
    "Solomon Islands": "Salomonovi otoki",
    "Somalia": "Somalija",
    "South Africa": "Južna Afrika",
    "South Georgia and The South Sandwich Islands": "Južna Džordžija in Južni sendvič",
    "Spain": "Španija",
    "Sri Lanka": "Šrilanka",
    "Sudan": "Sudan",
    "Suriname": "Surinam",
    "Svalbard and Jan Mayen": "Svalbard in Jan Mayen",
    "Swaziland": "Svaziland",
    "Sweden": "Švedska",
    "Swizerland": "Swizerland",
    "Syrian Arab Republic": "Sirska arabska republika",
    "Taiwan": "Tajvan",
    "Tajikistan": "Tadžikistan",
    "Tanzania, United Republic of": "Tanzanija, Združena republika",
    "Thailand": "Tajska",
    "Timor-leste": "Timor-leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad in Tobago",
    "Tunisia": "Tunizija",
    "Turkey": "Turčija",
    "Turkmenistan": "Turkmenistan",
    "Turks and Caicos Islands": "Otoki Turks in Caicos",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukrajina",
    "United Arab Emirates": "Združeni Arabski Emirati",
    "United Kingdom": "Združeno kraljestvo",
    "United States": "Združene države",
    "United States Minor Outlying Islands": "United States Minor Outlying Islands",
    "Uruguay": "Urugvaj",
    "Uzbekistan": "Uzbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela": "Venezuela",
    "Viet Nam": "Vietnam",
    "Virgin Islands, British": "Deviški otoki, britanski",
    "Virgin Islands, U.S.": "Deviški otoki, ZDA",
    "Wallis and Futuna": "Wallis in Futuna",
    "Western Sahara": "Zahodna Sahara",
    "Yemen": "Jemen",
    "Zambia": "Zambija",
    "Zimbabwe": "Zimbabve"
  },
  //Alt texts
  'alt.example-1': 'dva avtomobila brez voznika',
  'alt.example-2': 'roka drži mobilni telefon',
  'alt.example-3': "ženski obraz",
  'alt.defining-ai': 'oblak besed v zvezi z umetno inteligenco',
  'alt.turing-test': 'moški na računalniku klepeta z robotom in žensko',
  'alt.chinese-room': 'eksperiment kitajske sobe',
  'alt.chicken-crossing': 'robot na čolnu, ki gleda na kokoš, lisico in vrečo s krmilom za kokoši',
  'alt.a-to-b': 'roka drži telefon z aplikacijo za navigacijo',
  'alt.tic-tac-toe': 'otroka igrata Križce in krožce',
  'alt.self-driving-car': 'avtomobil brez voznika pred znakom „ustavi“',
  'alt.spam-or-ham': 'običajna elektronska pošta in neželena elektronska pošta',
  'alt.mnist': 'podatkovni niz MNIST',
  'alt.nearest-neighbor': 'dve osebi nakupujeta',
  'alt.nearest-neighbor-graph': 'klasifikator najbližjega soseda',
  'alt.recommendation': 'moški in ženska poslušata glasbo na telefonu',
  'alt.price-real-estate': 'tri različne hiše',
  'alt.supervised-learning': 'človek poučuje robota',
  'alt.dice': 'kocke',
  'alt.chess': 'šahovnica',
  'alt.poker': 'roka drži igralne karte',
  'alt.eyes': 'tri osebe z rjavimi očmi, ena oseba z modrimi očmi',
  'alt.search-1': 'ena pot do cilja',
  'alt.search-2': 'štiri različne poti do istega cilja',
  'alt.chicken-crossing-1': 'prečkanje kokoši – začetno stanje',
  'alt.chicken-crossing-2': 'prečkanje kokoši – prvi prehod',
  'alt.chicken-crossing-3': 'prečkanje kokoši – vsi prehodi',
  'alt.chicken-crossing-4': 'prečkanje kokoši z najboljšo potjo',
  'alt.chicken-crossing-5': 'prečkanje kokoši – začetno stanje',
  'alt.implication-1': 'moški in ženska',
  'alt.implication-2': 'oči',
  'alt.implication-3': 'nadzorna kamera',
  'alt.implication-4': 'robot, ki dela na prenosnem računalniku',
  'alt.oddchange': 'sonce in oblaki',
  'alt.brain': 'risba možganov',
  'alt.carstop': 'avtomobil brez voznika pred znakom „ustavi“',
  'alt.gan': 'robot, ki drži sliko mačke in sliko znaka „ustavi“',
  'alt.hammock': 'robot pahlja moškega v viseči mreži',
  'alt.game-tree-1': 'drevo igre 1',
  'alt.game-tree-2': 'drevo igre 2',
  'alt.game-tree-3': 'drevo igre 3',
  'alt.game-tree-4': 'drevo igre 4',
  'alt.bayes-rule-1': 'slika, ki ponazarja lažno pozitivne in negativne rezultate presejalnih pregledov za odkrivanje raka',
  'alt.bayes-rule-2': 'slika, ki ponazarja lažno pozitivne in negativne rezultate presejalnih pregledov za odkrivanje raka',
  'alt.defineai': 'oblak besed v zvezi z umetno inteligenco',
  'alt.winter': 'robot spi v snegu',
  'alt.terminator': 'srhljiv robot',
}
