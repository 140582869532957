import _ from "lodash";
import dateToUnix from "../../utilities/datetounix";

const getQuizzes = state => _.get(state, "quizzes", {});

export const getQuiz = (state, quizId) => _.get(getQuizzes(state), quizId);
export const quizzesInitializing = state =>
  _.get(state, "quizzes.initializing");
export const getExpiredQuizIds = state => {
  const { quizzes } = getQuizzes(state);

  return Object.keys(quizzes).filter(
    quizId => quizzes[quizId].expireAt < dateToUnix(new Date())
  );
};
