import React from "react";
import Helmet from "react-helmet";
import { withPrefix } from "gatsby-link";

const Meta = ({ messages }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: messages["globals.locale"]
      }}

      title={messages["meta.title"]}
      titleTemplate={messages["meta.titleTemplate"]}
    >
      <meta name="description" content={messages["meta.description"]} />
      <meta
        property="og:image"
        content="https://elementsofai.s3.amazonaws.com/facebook.png"
      />
      <meta property="og:title" content={messages["meta.title.og"]} />
      <meta property="og:description" content={messages["meta.description"]} />
      <meta property="og:site_name" content={messages["meta.sitename"]} />
      <meta property="og:locale" content={messages["globals.locale"]} />
      <meta property="og:url" content="https://course.elementsofai.com/" />
      <meta property="og:type" content="website" />

      <meta property="fb:app_id" content="" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={withPrefix("/apple-touch-icon.png")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={withPrefix("/favicon-32x32.png")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={withPrefix("/favicon-16x16.png")}
      />
      <script type="application/ld+json">
        {`
        {
          "@context": "http://schema.org",
          "@type": "Course",
          "name": "Elements of AI",
          "description": "Intro to artificial intelligence, no math/programming needed",
          "provider": {
            "@type": "Organization",
            "name": "University of helsinki",
            "sameAs": "https://www.helsinki.fi/"
          }
        }
    `}
      </script>
    </Helmet>
  );
};

export default Meta;
