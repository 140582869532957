import styled from "styled-components";
import {
  space,
  width,
  color,
  util,
  responsiveStyle,
  textAlign,
  borders,
  borderColor,
  justifyContent,
  maxWidth,
  flexDirection
} from "styled-system";

const getOffset = n => (!util.num(n) || n > 1 ? util.px(n) : `${n * 100}%`);
const offset = responsiveStyle({
  prop: "offset",
  getter: getOffset,
  cssProperty: "marginLeft"
});

export const MiniContainer = styled.div`
  ${color};
  ${space};
  ${borders};
  ${borderColor};
  max-width: 480px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media screen and (min-width: 72em) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  margin-left: auto;
  margin-right: auto;
`;

export const SmallContainer = styled.div`
  ${color};
  ${space};
  ${borders};
  ${borderColor};
  max-width: 780px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media screen and (min-width: 72em) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  margin-left: auto;
  margin-right: auto;
`;

export const MediumContainer = styled.div`
  ${color};
  ${space};
  ${borders};
  ${borderColor};
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media screen and (min-width: 72em) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`;

export const LargeContainer = styled.div`
  ${color};
  ${space};
  ${borders};
  ${borderColor};
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media screen and (min-width: 72em) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`;

export const Row = styled.section`
  ${space};
  ${justifyContent};
  ${flexDirection};
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
`;

export const Col = styled.div`
  ${space};
  ${width};
  ${offset};
  ${color};
  ${textAlign};
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Container = styled.div`
  ${color};
  ${space};
  ${borders};
  ${borderColor};
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;
