import React from "react";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";
import _ from "lodash";

import * as accountState from "../../state/account";
import {
  onLoginStateChanged,
  loggedIn,
  userDetails
} from "../../services/moocfi";
import { currentLocale } from "../../services/locale";
import fiMessages from "data/messages/fi";
import enMessages from "data/messages/en";
import seMessages from "data/messages/se";
import frMessages from "data/messages/fr";
import eeMessages from "data/messages/ee";
import deMessages from "data/messages/de";
import noMessages from "data/messages/no";
import lvMessages from "data/messages/lv";
import mtMessages from "data/messages/mt";
import ltMessages from "data/messages/lt";
import nlbeMessages from "data/messages/nl-be";
import frbeMessages from "data/messages/fr-be";
import itMessages from "data/messages/it";
import plMessages from "data/messages/pl";
import enieMessages from "data/messages/en-ie";
import gaMessages from "data/messages/ga";
import nlMessages from "data/messages/nl";
import hrMessages from "data/messages/hr";
import skMessages from "data/messages/sk";
import daMessages from "data/messages/da";
import roMessages from "data/messages/ro";
import isMessages from "data/messages/is";
import deatMessages from "data/messages/de-at";
import enluMessages from "data/messages/en-lu";
import bgMessages from "data/messages/bg";
import csMessages from "data/messages/cs";
import slMessages from "data/messages/sl";
import ptMessages from "data/messages/pt";
import esMessages from "data/messages/es";
import elMessages from "data/messages/el";

export const messages = {
  en: enMessages,
  fi: fiMessages,
  se: seMessages,
  fr: frMessages,
  ee: eeMessages,
  de: deMessages,
  lt: ltMessages,
  lv: lvMessages,
  sl: slMessages,
  "de-at": deatMessages,
  pt: ptMessages,
  sk: skMessages,
  bg: bgMessages,
  mt: mtMessages,
  no: noMessages,
  "nl-be": nlbeMessages,
  "fr-be": frbeMessages,
  it: itMessages,
  pl: plMessages,
  "en-ie": enieMessages,
  ga: gaMessages,
  nl: nlMessages,
  hr: hrMessages,
  "en-lu": enluMessages,
  da: daMessages,
  ro: roMessages,
  is: isMessages,
  cs: csMessages,
  es: esMessages,
  el: elMessages
};

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.messages = currentLocale() ? messages[currentLocale()] : enMessages;

      this.state = {
        authUser: null,
        loggedIn: loggedIn()
      };
    }

    getChildContext() {
      return {
        authUser: this.state.authUser,
        loggedIn: this.state.loggedIn
      };
    }

    componentDidMount() {
      onLoginStateChanged(loggedIn => {
        loggedIn
          ? this.setState(() => ({ loggedIn }))
          : this.setState(() => ({ loggedIn: false }));
      });

      userDetails()
        .then(details => {
          this.setState({ user: details });
        })
        .catch(err => {
          this.setState({ user: undefined });
        });
    }

    render() {
      let path;

      if (this.props && this.props.location) {
        path = this.props.location.pathname;
      }

      const user = accountState.getUserDetails() || this.state.user;

      // let's try to prevent redirect ping pong
      // was: path !== this.messages['routes.MISSING_INFO'] &&
      // path !== this.messages['routes.SIGN_IN']

      if (
        user &&
        path &&
        !~path.indexOf("missinginfo") &&
        !~path.indexOf("accept-policy") &&
        !~path.indexOf("signin")
      ) {
        // TODO: check if user has an empty language field and force fill it?
        let data;
        if (user && user.extra_fields) {
          data = _.omit(user.extra_fields, "language");
        }
        if (!data || Object.keys(data).length === 0) {
          navigate(`${this.messages["routes.MISSING_INFO"]}?${path}`);
          return null;
        }
        if (user && user.extra_fields) {
          console.log(process.env.PRIVACY_VERSION)
          if (user.extra_fields.accept_policy_versions === undefined) {
            if (user.extra_fields.accept_policy) {
              if (process.env.PRIVACY_VERSION !== '1.0.0') {
                navigate(`${this.messages["routes.ACCEPT_POLICY"]}?${path}`);
                return null;
              }
            } else {
              navigate(`${this.messages["routes.ACCEPT_POLICY"]}?${path}`);
              return null;
            }
          } else {
            if (!user.extra_fields.accept_policy_versions.includes(process.env.PRIVACY_VERSION)) {
              navigate(`${this.messages["routes.ACCEPT_POLICY"]}?${path}`);
              return null;
            }
          }
        }
      }

      return <Component {...this.props} />;
    }
  }

  WithAuthentication.childContextTypes = {
    authUser: PropTypes.object,
    loggedIn: PropTypes.bool
  };

  return WithAuthentication;
};

export default withAuthentication;
