import * as actionTypes from "./actions";
import { RESET_STATE } from "../actions";
import * as _ from "lodash";

const initialState = {
  completed: {},
  answered: [], //{},
  notAnswered: [], //{},
  rejected: [],
  essaysAwaitingPeerReviewsGiven: [],
  essaysAwaitingConfirmation: [],
  confirmedAmount: 0,
  confirmedIgnoredAmount: 0,
  ignoredAmount: 0,
  points: 0,
  maxPoints: 0,
  pointsPercentage: 0,
  score: 0,
  maxNormalizedPoints: 0,
  maxCompletedPoints: 0,
  maxCompletedNormalizedPoints: 0,
  progress: 0,
  validating: undefined, // whee
  error: "",
  initializing: true
};

export default function stats(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATS_INIT_STATE:
    case RESET_STATE:
      return { ...initialState };
    case actionTypes.SET_COMPLETED:
    case actionTypes.SET_BATCH_COMPLETED:
      let completed = state.completed || [];

      let quizIds =
        action.type === actionTypes.SET_BATCH_COMPLETED
          ? action.payload.quizIds
          : [action.payload.quizId];

      quizIds.map(
        quizId =>
          (completed = {
            ...completed,
            [quizId]: {
              points: 0, // these are not used
              maxPoints: 0
            }
          })
      );

      return Object.assign({}, state, {
        completed
      });
    case actionTypes.FETCH_PROGRESS_WITH_VALIDATION:
      return Object.assign({}, state, {
        validating: true,
        error: ""
      });
    case actionTypes.FETCH_PROGRESS_WITH_VALIDATION_SUCCESS:
      /*
          validation object:
            answered
            notAnswered
            rejected
              validation
            essaysAwaitingPeerReviewsGiven
            essaysAwaitingConfirmation
            points
            maxPoints
            confirmedAmount
            normalizedPoints
            maxNormalizedPoints
            progress
            score
            pointsPercentage
        */

      if (!action.payload) {
        return Object.assign({}, state, {
          ...initialState // TODO: really reset if no data?
        });
      }

      return Object.assign({}, state, {
        ...action.payload,
        validating: false,
        initializing: false
      });
    case actionTypes.FETCH_PROGRESS_WITH_VALIDATION_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        validating: false,
        initializing: false
      });
    case actionTypes.START_RECALCULATE_VALIDATION:
      return Object.assign({}, state, {
        validating: true
      });
    case actionTypes.FINISH_RECALCULATE_VALIDATION:
      return Object.assign({}, state, {
        ...action.data
      });
    case actionTypes.UPDATE_ESSAYS_WAITING_PEER_REVIEWS:
      let {
        essaysAwaitingPeerReviewsGiven,
        essaysAwaitingConfirmation
      } = action.payload;

      return Object.assign({}, state, {
        essaysAwaitingPeerReviewsGiven,
        essaysAwaitingConfirmation
      });
    case actionTypes.UPDATE_PROGRESS:
      return Object.assign({}, state, {
        validating: true,
        error: ""
      });
    case actionTypes.UPDATE_PROGRESS_SUCCESS:
      return Object.assign({}, state, {
        validating: false
      });
    case actionTypes.UPDATE_PROGRESS_FAIL:
      return Object.assign({}, state, {
        validating: false,
        error: action.error
      });
    default:
      return state;
  }
}
