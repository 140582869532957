// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
    // Globals
    'globals.locale': 'el',
    'globals.signIn': 'Σύνδεση',
    'globals.logIn': 'Σύνδεση',
    'globals.signUp': 'Εγγραφή',
    'globals.signOut': 'Αποσύνδεση',
    'globals.logOut': 'Αποσύνδεση',
    'globals.submit': 'Υποβολή',
    'globals.submitting': 'Γίνεται υποβολή...',
    'globals.yourEmail': 'Η ηλεκτρονική σας διεύθυνση',
    'globals.yourPassword': 'Ο κωδικός πρόσβασής σας',
    'globals.courseOverview': 'Επισκόπηση κύκλου μαθημάτων',
    'globals.forgotPassword': 'Ξεχάσατε τον κωδικό σας;',
    'globals.invalidCredentials': 'Μη έγκυρα διαπιστευτήρια',
    'globals.section': 'Ενότητα',
    'globals.exercises': 'Ασκήσεις',
    'globals.chapter': 'Κεφάλαιο',
    'globals.introToAI': 'Εισαγωγή στην ΤΝ',
    'globals.buildingAI': 'Χτίζοντας εφαρμογές ΤΝ',
    'globals.acceptPolicy': 'Αποδοχή πολιτικής',
    // section
    'section.footerEndOfTheChapter': 'Φτάσατε στο τέλος του κεφαλαίου {chapter}',
    'section.footerEndOfTheCourseTitle': 'Φτάσατε στο τέλος του κύκλου μαθημάτων!',
    'section.footerEndOfTheCourseText': 'Αφού ολοκληρώσετε όλες τις ασκήσεις και αξιολογήσεις άλλων συμμετεχόντων, οι απαντήσεις σας θα αξιολογηθούν από το προσωπικό μας.',
    'section.footerEndOfTheCourseBAI': 'Συνεχίστε το ταξίδι σας για να μπορέσετε να εφαρμόσετε την πρώτη σας ιδέα για ΤΝ.',
    'section.nextSection': 'Επόμενη ενότητα',
    'section.nextChapter': 'Επόμενο κεφάλαιο',
    'section.start': 'Έναρξη',
    'section.logisticRegressionExample.slope': 'Κλίση',
    'section.logisticRegressionExample.intercept': 'Σημείο τομής',
    // quiz peer review
    'quiz.awaitingPeerReview': 'Αναμένουμε την αξιολόγησή σας',
    'quiz.rejectedPeerReview': 'Απορρίφθηκε στο πλαίσιο της αξιολόγησης από άλλους συμμετέχοντες',
    'quiz.unsubmittedAnswer': 'Lähettämätön vastaus',
    'quiz.pendingPeerReview': 'Εκκρεμεί αξιολόγηση από άλλους συμμετέχοντες',
    // quiz
    'quiz.status.answered': 'Απαντήθηκε',
    'quiz.status.rejected': 'Η απάντηση απορρίφθηκε, δοκιμάστε ξανά',
    'quiz.status.unanswered': 'Δεν απαντήθηκε',
    'quiz.status.exercisesCompleted': 'Ολοκληρωμένες ασκήσεις',
    'quiz.status.correctAnswers': 'Σωστές απαντήσεις',
    'quiz.message.rejectedInReview': 'Η προηγούμενη απάντησή σας απορρίφθηκε κατά την αξιολόγηση. Δοκιμάστε ξανά.',
    'quiz.youAnswered': 'Απαντήσατε',
    'quiz.yourAnswer': 'Η απάντησή σας...',
    'quiz.yourScore': 'Η βαθμολογία σας:',
    'quiz.quizNotLoaded': 'Δεν είναι δυνατή η φόρτωση του τεστ',
    'quiz.words': 'Λέξεις:',
    'quiz.exampleAnswer': 'Απάντηση στο παράδειγμα:',
    // peer review
    'peerreview.question1': 'Η απάντηση είναι επί του θέματος',
    'peerreview.question2': 'Η απάντηση είναι ολοκληρωμένη',
    'peerreview.question3': 'Η απάντηση είναι καλά αιτιολογημένη',
    'peerreview.question4': 'Η απάντηση είναι εύληπτη',
    'peerreview.thankYou': 'Ευχαριστούμε που αξιολογήσατε άλλους συμμετέχοντες!',
    'peerreview.giveMoreReviews': 'Εάν έχετε χρόνο,',
    'peerreview.giveMoreReviewsButton': 'παράσχετε περισσότερες αξιολογήσεις',
    'peerreview.notEnoughAnswers': 'Κανονικά θα έπρεπε να υπάρχει εδώ αξιολόγηση από άλλους συμμετέχοντες, αλλά ο αριθμός των ατόμων που απάντησαν σε αυτή την άσκηση δεν είναι επαρκής.',
    'peerreview.selectAnswerMessage': 'Ώρα για αξιολόγηση άλλων συμμετεχόντων! Ακολουθούν απαντήσεις από άλλους συμμετέχοντες. Επιλέξτε μία από αυτές και αξιολογήστε την. Επαναλάβετε τουλάχιστον τρεις φορές.',
    'peerreview.minimumPeerReviewWarning': 'Η άσκηση μπορεί να γίνει δεκτή μόνο αφού παράσχετε τουλάχιστον τρεις αξιολογήσεις για άλλους συμμετέχοντες.',
    'peerreview.select': 'Επιλογή',
    'peerreview.reportSpam': 'Αναφορά ανεπιθύμητης αλληλογραφίας',
    'peerreview.feedback': 'Η απάντησή σας αξιολογήθηκε και έγινε δεκτή. Δείτε τι σκέφτονται για την απάντησή σας και οι άλλοι συμμετέχοντες:',
    // exercise17
    'quiz.exercise17.xLabel': 'Έτη εκπαίδευσης',
    'quiz.exercise17.yLabel': 'Προσδόκιμο ζωής σε έτη',
    'quiz.exercise17.textPart1': 'Ας εξετάσουμε τη σχέση μεταξύ του συνολικού αριθμού των ετών φοίτησης στο σχολείο (σε όλες τις βαθμίδες από την προσχολική μέχρι την τριτοβάθμια εκπαίδευση) και του προσδόκιμου ζωής. Στο παρακάτω γράφημα παρουσιάζονται τα δεδομένα από τρεις διαφορετικές χώρες, οι οποίες αναπαριστώνται με κουκκίδες:',
    'quiz.exercise17.textPart2': 'Στην πρώτη χώρα ο μέσος αριθμός ετών φοίτησης στο σχολείο είναι 10 και το προσδόκιμο ζωής είναι 57 έτη, στη δεύτερη χώρα ο μέσος αριθμός ετών φοίτησης στο σχολείο είναι 13 και το προσδόκιμο ζωής 53 έτη και στην τρίτη χώρα ο μέσος αριθμός ετών φοίτησης στο σχολείο είναι 20 και το προσδόκιμο ζωής 80 έτη.',
    'quiz.exercise17.textPart3': 'Μπορείτε να σύρετε τα άκρα της συνεχούς γραμμής για να την τοποθετήσετε με τέτοιο τρόπο ώστε να ακολουθεί την τάση των σημείων των δεδομένων. Έχετε υπόψη σας ότι η γραμμή δεν θα ταιριάζει απόλυτα με όλα τα σημεία δεδομένων, οπότε μην ανησυχείτε: ορισμένα σημεία δεδομένων θα βρίσκονται πάνω από τη γραμμή και άλλα κάτω από αυτή. Το βασικό ζητούμενο είναι η γραμμή να δείχνει τη γενική τάση.',
    'quiz.exercise17.textPart4': 'Αφού τοποθετήσετε τη γραμμή στη σωστή θέση, μπορείτε να τη χρησιμοποιήσετε για να προβλέψετε το προσδόκιμο ζωής.',
    'quiz.exercise17.textPart5': 'Με βάση τα δεδομένα που έχετε στη διάθεσή σας, τι συμπέρασμα θα βγάζατε για το προσδόκιμο ζωής των ατόμων με 15 έτη εκπαίδευσης; Σημαντικό στοιχείο: επισημαίνεται ότι, ακόμη κι αν μπορείτε να εξαγάγετε συγκεκριμένη πρόβλεψη, με ακρίβεια έτους, προσαρμόζοντας τη γραμμή, ίσως να μην είναι δυνατό να προβείτε σε αξιόπιστη πρόβλεψη. Επομένως, λάβετε υπόψη σας τον περιορισμένο αριθμό δεδομένων κατά την απάντησή σας.',
    // exercise18
    'quiz.exercise18.xLabel': 'Έτη εκπαίδευσης',
    'quiz.exercise18.yLabel': 'Προσδόκιμο ζωής σε έτη',
    'quiz.exercise18.textPart1': 'Στην προηγούμενη άσκηση, είχαμε στη διάθεσή μας δεδομένα από μόνο τρεις χώρες. Το πλήρες σύνολο δεδομένων αποτελείται από δεδομένα 14 διαφορετικών χωρών, τα οποία παρουσιάζονται στο παρακάτω γράφημα:',
    'quiz.exercise18.textPart2': 'Με βάση τα εν λόγω δεδομένα, θα αλλάζατε την πρόβλεψή σας σχετικά με το προσδόκιμο ζωής των ατόμων με 15 έτη εκπαίδευσης; Αν ναι, γιατί;',
    'quiz.exercise18.textPart3': 'Ποια από τις παρακάτω επιλογές ανταποκρίνεται καλύτερα στην εκτίμησή σας για το προσδόκιμο ζωής των ατόμων με 15 έτη εκπαίδευσης; Επιλέξτε την πιο συγκεκριμένη επιλογή που θεωρείτε ότι είναι αιτιολογημένη εφαρμόζοντας το μοντέλο της ευθείας γραμμής στα παραπάνω δεδομένα.',
    // exercise19
    'quiz.exercise19.xLabel': 'Ώρες μελέτης',
    'quiz.exercise19.yLabel': 'Πιθανότητα επιτυχίας',
    'quiz.exercise19.textPart1': 'Κάθε κουκκίδα στο γράφημα αντιστοιχεί σε έναν σπουδαστή. Στη βάση του γραφήματος έχουμε τον άξονα με το πόσες ώρες μελέτησε ο σπουδαστής για τις εξετάσεις. Οι σπουδαστές που πέρασαν τις εξετάσεις αναπαριστώνται ως κουκκίδες στην κορυφή του γραφήματος, ενώ αυτοί που δεν πέρασαν στη βάση. Θα χρησιμοποιήσουμε τον κατακόρυφο άξονα για να βρούμε την προβλεπόμενη πιθανότητα επιτυχίας, την οποία θα υπολογίσουμε με βάση το μοντέλο της λογιστικής παλινδρόμησης, όπως θα εξηγήσουμε αμέσως παρακάτω.',
    'quiz.exercise19.textPart2': 'Όπως φαίνεται χονδρικά στο παραπάνω γράφημα, οι σπουδαστές που μελέτησαν για περισσότερες ώρες είχαν μεγαλύτερες πιθανότητες να περάσουν με επιτυχία τις εξετάσεις. Ειδικά οι ακραίες περιπτώσεις προκύπτουν διαισθητικά: με λιγότερο από μία ώρα μελέτης είναι πολύ δύσκολο να περάσει κανείς τις εξετάσεις, αλλά με πολύ διάβασμα οι περισσότεροι θα τα καταφέρουν. Τι γίνεται, όμως, με αυτούς των οποίων οι ώρες μελέτης βρίσκονται κάπου μεταξύ των δύο άκρων; Αν μελετήσετε για 6 ώρες, ποιες είναι οι πιθανότητες επιτυχίας σας;',
    'quiz.exercise19.textPart3': 'Μπορούμε να ποσοτικοποιήσουμε την πιθανότητα επιτυχίας χρησιμοποιώντας τη λογιστική παλινδρόμηση. Η καμπύλη του γραφήματος μπορεί να ερμηνευτεί ως η πιθανότητα επιτυχίας: για παράδειγμα, μετά από πέντε ώρες μελέτης, η πιθανότητα επιτυχίας είναι λίγο πάνω από 20%. Δεν θα υπεισέλθουμε σε λεπτομέρειες ως προς τον τρόπο σχεδίασης της καμπύλης, αλλά είναι παρόμοιος με τον τρόπο με τον οποίο υπολογίζουμε τα βάρη στη γραμμική παλινδρόμηση.',
    'quiz.exercise19.textPart4': 'Με βάση το παραπάνω γράφημα, αν θέλετε να έχετε 80% πιθανότητα να περάσετε με επιτυχία τις εξετάσεις σε ένα μάθημα στη σχολή σας, πόσες ώρες περίπου θα πρέπει να μελετήσετε;',
    // exercise22
    'quiz.exercise22.sigmoidXLabel': 'Είσοδος',
    'quiz.exercise22.sigmoidYLabel': 'Έξοδος σιγμοειδούς συνάρτησης',
    'quiz.exercise22.stepXLabel': 'Είσοδος',
    'quiz.exercise22.stepYLabel': 'Έξοδος βηματικής συνάρτησης',
    'quiz.exercise22.identityXLabel': 'Είσοδος',
    'quiz.exercise22.identityYLabel': 'Έξοδος ταυτοτικής συνάρτησης',
    'quiz.exercise22.textPart1': 'Ακολούθως παρατίθενται τρία γραφήματα για τρεις διαφορετικές συναρτήσεις ενεργοποίησης με διαφορετικές ιδιότητες. Το πρώτο για τη σιγμοειδή συνάρτηση, το δεύτερο για τη βηματική συνάρτηση και το τελευταίο για την ταυτοτική συνάρτηση.',
    // exercise23
    'quiz.exercise23.happyFaces': 'γελαστά πρόσωπα που έχουν ταξινομηθεί σωστά',
    'quiz.exercise23.sadFaces': 'λυπημένα πρόσωπα που έχουν ταξινομηθεί σωστά',
    // certificate availability
    'certificate.failedToCheck': 'Ο έλεγχος της διαθεσιμότητας πιστοποιητικού απέτυχε',
    'certificate.failedToGenerate': 'Η δημιουργία πιστοποιητικού απέτυχε',
    'certificate.notYetAvailable': 'Το πιστοποιητικό σας θα είναι διαθέσιμο εδώ μόλις δώσουμε σχετική επίσημη επιβεβαίωση. Για να ολοκληρωθεί αυτή η διαδικασία ενδέχεται να χρειαστεί λίγος χρόνος. Σας ευχαριστούμε για την υπομονή σας!',
    'certificate.updateName': 'Το πιστοποιητικό σας είναι διαθέσιμο! Επικαιροποιήστε το ονοματεπώνυμό σας στο προφίλ σας, χρησιμοποιώντας την κατωτέρω φόρμα, για να μπορέσουμε να το ενσωματώσουμε στο πιστοποιητικό.',
    'certificate.generate': 'Δημιουργήστε το πιστοποιητικό σας παρακάτω.',
    'certificate.generateTextPart1': 'Το πιστοποιητικό θα δημιουργηθεί για',
    'certificate.generateTextPart2': 'Βεβαιωθείτε ότι το ονοματεπώνυμο είναι ορθό πριν δημιουργήσετε το πιστοποιητικό, διότι δεν είναι δυνατή η εκ των υστέρων αλλαγή του. Εάν επιθυμείτε να αλλάξετε το ονοματεπώνυμό σας, μπορείτε να χρησιμοποιήσετε την παρακάτω φόρμα.',
    'certificate.generateButton': 'Δημιουργία πιστοποιητικού',
    'certificate.downloadButton': 'Λήψη πιστοποιητικού',
    'certificate.checkingAvailability': 'Γίνεται έλεγχος της διαθεσιμότητας πιστοποιητικού...',
    // Visualizations
    'visualizations.logisticRegressionChart.classifiedCorrectly': 'Έχουν ταξινομηθεί σωστά',
    'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Δεν έχουν ταξινομηθεί σωστά',
    // section blocks
    'section.block.note': 'Σημείωση',
    'section.block.example': 'Παράδειγμα',
    'section.block.keyTerminology': 'Βασική ορολογία',
    'section.partSummary.spectrumTextPart1': 'Γίνετε μέλη της ',
    'section.partSummary.spectrum': 'κοινότητας του κύκλου μαθημάτων Elements of AI',
    'section.partSummary.spectrumTextPart2': ' για να συμμετάσχετε σε συζητήσεις και να υποβάλετε ερωτήσεις σχετικά με αυτό το κεφάλαιο.',
    'section.partSummary.feedbackTitle': 'Πείτε μας τη γνώμη και τα σχόλιά σας για αυτό το μέρος',
    'section.partSummary.feedbackPlaceholder': 'Γράψτε εδώ τα σχόλιά σας...',
    'section.partSummary.feedbackSuccess': 'Σας ευχαριστούμε για τα σχόλιά σας!',
    // 404
    'globals.404.title': '404 - Η σελίδα δεν βρέθηκε',
    'globals.404.intro': 'Επιλέξατε διαδρομή που δεν υπάρχει... τι θλίψη.',
    'globals.loading': 'Γίνεται φόρτωση...',
    // footer / menu
    'globals.menu': 'Μενού',
    'globals.menu.about': 'Σχετικά',
    'globals.menu.faq': 'Συχνές ερωτήσεις',
    'globals.menu.privacyPolicy': 'Πολιτική απορρήτου',
    'globals.menu.termsofService': 'Όροι χρήσης',
    'globals.menu.myProfile': 'Το προφίλ μου',
    // Dashboard/Index
    'dashboard.title': 'Καλωσήρθατε στον κύκλο μαθημάτων!',
    'dashboard.congratulations.title': 'Συγχαρητήρια!',
    'dashboard.congratulations.message': 'Ολοκληρώσατε τον κύκλο μαθημάτων Elements of AI',
    'dashboard.congratulations.downloadCertificate': 'Λήψη πιστοποιητικού',
    'dashboard.notLoggedIn.title': 'Καλωσήρθατε!',
    'dashboard.notLoggedIn.message': 'Εγγραφείτε ή συνδεθείτε για να αποκτήσετε πλήρη πρόσβαση στον κύκλο μαθημάτων. Είναι δωρεάν!',
    // Account
    'account.certificate': 'Πιστοποιητικό',
    'account.credits': 'Μονάδες ECTS',
    'account.myProfile': 'Το προφίλ μου',
    'account.profileInformation': 'Πληροφορίες προφίλ',
    'account.changePassword': 'Αλλαγή κωδικού πρόσβασης',
    'account.changeLanguage': 'Αλλαγή γλώσσας',
    'account.goToCourse': 'Πηγαίνετε στο μάθημα',
    'account.buildingAINew': 'Νέο μάθημα στα Αγγλικά',
    'account.accept': 'Αποδοχή',
    'account.decline': 'Απόρριψη',
    'account.courses': 'Μαθήματα',
    // Sign Up page
    'signUp.description': 'Συμπληρώστε την παρακάτω φόρμα για να δημιουργήσετε νέο λογαριασμό mooc.fi',
    'signUp.email': 'Η ηλεκτρονική σας διεύθυνση',
    'signUp.firstName': 'Το όνομά σας',
    'signUp.lastName': 'Το επώνυμό σας',
    'signUp.postalCode': 'Ταχυδρομικός Κώδικας',
    'signUp.country': 'Πού κατοικείτε;',
    'signUp.Password': 'Κωδικός πρόσβασης',
    'signUp.confirmPassword': 'Επιβεβαίωση κωδικού πρόσβασης',
    'signUp.howToStudyTitle': 'Με ποιον τρόπο επιθυμείτε να παρακολουθήσετε τον κύκλο μαθημάτων;',
    'signUp.6weeksTitle': 'Κύκλος μαθημάτων 6 εβδομάδων',
    'signUp.6weeksSubTitle': 'Συνιστώμενο (περίπου 5 ώρες την εβδομάδα)',
    'signUp.6weeksText': 'Η πρόβλεψη προθεσμίας μπορεί αποδεδειγμένα να σας βοηθήσει να ολοκληρώσετε τον κύκλο μαθημάτων.',
    'signUp.selfPacedTitle': 'Ρυθμός προσαρμοσμένος στον συμμετέχοντα',
    'signUp.selfPacedText': 'Μπορείτε να παρακολουθήσετε τον κύκλο μαθημάτων με τον δικό σας ρυθμό.',
    'signUp.ectsTitleSwe': ' ',
    'signUp.ectsTitle': 'Υποβάλλετε αίτηση για μονάδες ECTS;',
    'signUp.ectsStudent': 'Είμαι σπουδαστής στο πανεπιστήμιο του Ελσίνκι. Εάν επιλέξετε αυτό το τετραγωνίδιο και συμπληρώσετε τον αριθμό μητρώου σας θα λάβετε αυτόματα τις μονάδες ECTS.',
    'signUp.ectsStudentSwe': ' ',
    'signup.ectsStudentSweLink': ' ',
    'signUp.ectsOpenUni': 'Επιλέξτε αυτό το τετραγωνίδιο για να λάβετε email με οδηγίες σχετικά με την υποβολή αίτησης για μονάδες ECTS',
    'signUp.studentNumber': 'Αριθμός μητρώου σπουδαστή του πανεπιστημίου του Ελσίνκι (εάν είστε σπουδαστής)',
    'signUp.privacyOptIn': 'Επιτρέπω τη χρήση των συλλεχθέντων δεδομένων σχετικά με τη μαθησιακή συμπεριφορά μου για ερευνητικούς σκοπούς. Τα δεδομένα περιλαμβάνουν πληροφορίες που προκύπτουν από τη χρήση του υλικού και τις ασκήσεις. Δεν είναι δυνατή η εξακρίβωση της ταυτότητας κανενός φυσικού προσώπου από τις δημοσιεύσεις.',
    'signUp.marketingOptIn': 'Συμφωνώ να λαμβάνω ενημερώσεις σχετικά με προσεχείς γλωσσικές εκδόσεις και πληροφορίες για νέους κύκλους μαθημάτων. Συμφωνώ να μοιραστώ τα στοιχεία επικοινωνίας μου για να λαμβάνω προσαρμοσμένα μηνύματα σε πλατφόρμες τρίτων. Διαβάστε την πολιτική απορρήτου μας.',
    'signUp.acceptPolicyFirst': 'Αποδέχομαι την Πολιτική απορρήτου και τους Όρους Παροχής Υπηρεσιών.',
    'signUp.privacyLink': 'Διαβάστε την πολιτική απορρήτου μας',
    'signUp.error': 'Υπάρχουν σφάλματα στη φόρμα σας. Ελέγξτε τη φόρμα σας.',
    // Sign in page
    'signIn.title': 'Σύνδεση',
    'signIn.intro': 'Χρησιμοποιήστε την παρακάτω φόρμα για να συνδεθείτε με τον λογαριασμό σας mooc.fi',
    'signIn.email': 'Η ηλεκτρονική σας διεύθυνση',
    'signIn.password': 'Ο κωδικός πρόσβασής σας',
    'signIn.noAccount': 'Δεν έχετε λογαριασμό;',
    'signIn.signUpNow': 'Εγγραφείτε τώρα',
    // Forgot password
    'pwForget.title': 'Ξέχασα τον κωδικό μου',
    'pwForget.description': 'Συμπληρώστε την ηλεκτρονική σας διεύθυνση στον λογαριασμό mooc.fi και θα σας αποστείλουμε σύνδεσμο επαναφοράς του κωδικού πρόσβασης.',
    'pwForget.sendResetLink': 'Αποστολή συνδέσμου επαναφοράς του κωδικού πρόσβασης',
    'pwForget.completedDescription': 'Σύντομα θα λάβετε email από τον ιστότοπο mooc.fi που θα περιέχει σύνδεσμο επαναφοράς του κωδικού πρόσβασης. Εάν δεν το βλέπετε, ελέγξτε τον φάκελο ανεπιθύμητης αλληλογραφίας.',
    'pwForget.problemDescription': 'Εάν συναντήσετε προβλήματα, επικοινωνήστε μαζί μας στη διεύθυνση mooc@cs.helsinki.fi.',
    'pwForget.resetEmailSent': 'Το email για την επαναφορά του κωδικού πρόσβασης εστάλη',
    'pwForget.emailPlaceholder': 'Ηλεκτρονική διεύθυνση',
    // password change
    'pwChange.authError': 'Η επαλήθευση ταυτότητας απέτυχε. Προσπαθήστε να συνδεθείτε ξανά',
    'pwChange.unexpError': 'Ο κωδικός πρόσβασης δεν ενημερώθηκε λόγω απροσδόκητου σφάλματος',
    'pwChange.required': 'Υποχρεωτικό',
    'pwChange.pwMustMatch': 'Οι κωδικοί πρόσβασης πρέπει να ταιριάζουν',
    'pwChange.currentPassword': 'Ισχύων κωδικός πρόσβασης',
    'pwChange.newPassword': 'Νέος κωδικός πρόσβασης',
    'pwChange.confirmPassword': 'Επιβεβαίωση νέου κωδικού πρόσβασης',
    'pwChange.changingPassword': 'Γίνεται αλλαγή κωδικού πρόσβασης...',
    'pwChange.changePassword': 'Αλλαγή κωδικού πρόσβασης',
    'pwChange.passwordChanged': 'Ο κωδικός πρόσβασης άλλαξε',
    // user details
    'userDetails.authError': 'Η επαλήθευση ταυτότητας απέτυχε. Προσπαθήστε να συνδεθείτε ξανά',
    'userDetails.unexpError': 'Το προφίλ δεν ενημερώθηκε λόγω απροσδόκητου σφάλματος',
    'userDetails.required': 'Υποχρεωτικό',
    'userDetails.invalidEmail': 'Μη έγκυρη ηλεκτρονική διεύθυνση',
    'userDetails.email': 'Email',
    'userDetails.emailAddress': 'Ηλεκτρονική διεύθυνση',
    'userDetails.firstName': 'Όνομα',
    'userDetails.lastName': 'Επώνυμο',
    'userDetails.language': 'Γλώσσα',
    'userDetails.updatingProfile': 'Γίνεται ενημέρωση του προφίλ...',
    'userDetails.updateProfile': 'Ενημέρωση προφίλ',
    'userDetails.profileUpdated': 'Το προφίλ ενημερώθηκε',
    // languages
    'languages.en.long': 'Αγγλικά',
    'languages.fi.long': 'Φινλανδικά',
    'languages.se.long': 'Σουηδικά',
    'languages.de.long': 'Γερμανικά (Γερμανία)',
    'languages.ee.long': 'Εσθονικά',
    'languages.no.long': 'Νορβηγικά',
    'languages.lv.long': 'Λετονικά',
    'languages.el.long': 'Ελληνικά',
    'languages.it.long': 'Ιταλικά',
    'languages.fr.long': 'Γαλλικά (Γαλλία)',
    'languages.mt.long': 'Μαλτέζικα',
    'languages.sk.long': 'Σλοβακικά',
    'languages.fr-be.long': 'Γαλλικά (Βέλγιο)',
    'languages.lt.long': 'Λιθουανικά',
    'languages.nl-be.long': 'Ολλανδικά (Βέλγιο)',
    'languages.en-ie.long': 'Αγγλικά (Ιρλανδία)',
    'languages.pl.long': 'Πολωνικά',
    'languages.da.long': 'δανικά',
    'languages.ro.long': 'Ρουμανικά',
    'languages.hr.long': 'Κροατικά',
    'languages.en-lu.long': 'Αγγλικά (Λουξεμβούργο)',
    'languages.bg.long': 'βουλγαρικά',
    'languages.cs.long': 'Τσέχικα',
    'languages.pt.long': 'Πορτογαλικά',
    'languages.nl.long': 'Ολλανδικά (Ολλανδία)',
    'languages.de-at.long': 'Γερμανικά (Αυστρία)',
    'languages.sl.long': 'Σλοβένικα',
    'languages.is.long': 'Ισλανδική',
    'languages.es.long': 'Ισπανικά',
    'languages.ga.long': 'Ιρλανδικά',
    // Email verification
    'emailVerification.completedMessage': 'Η ηλεκτρονική σας διεύθυνση επαληθεύτηκε',
    'emailVerification.verified': 'Επαληθεύτηκε',
    // Exercises
    'exercise.loading': 'Γίνεται φόρτωση της άσκησης...',
    'exercise.signUp': 'Εγγραφείτε για να λύσετε ασκήσεις',
    'exercise.score': 'Η βαθμολογία σας:',
    'exercise.wrongLocale': 'Εσφαλμένες τοπικές ρυθμίσεις',
    // Part tempplate
    'partTemplate.intro1': 'Στο κεφάλαιο ',
    'partTemplate.intro2': '  θα καλύψουμε τις ακόλουθες ενότητες. Κάντε κλικ παρακάτω για να ξεκινήσετε:',
    'partTemplate.spectrumPart1': 'Γίνετε μέλη της ',
    'partTemplate.spectrum': 'κοινότητας του κύκλου μαθημάτων Elements of AI',
    'partTemplate.spectrumPart2': ' για να συμμετάσχετε σε συζητήσεις και να υποβάλετε ερωτήσεις σχετικά με αυτό το κεφάλαιο.',
    // missing info
    'missingInfo.title': 'Λείπουν πληροφορίες',
    'missingInfo.body': 'Συνήθως θέτουμε αυτές τις ερωτήσεις κατά την εγγραφή των χρηστών, αλλά συνδεθήκατε με υφιστάμενο λογαριασμό mooc.fi. Συμπληρώστε την παρακάτω φόρμα για να συνεχίσετε.',
    'missingInfo.privacyPolicy': 'Διαβάστε την πολιτική απορρήτου μας',
    'missingInfo.error': 'Υπάρχουν σφάλματα στη φόρμα σας. Ελέγξτε τη φόρμα σας.',
    'missingInfo.privacyPolicyUpdateBody': 'Ενημερώσαμε την πολιτική απορρήτου μας. Ακολουθεί μια σύνοψη των αλλαγών. Διαφημίζουμε τα μαθήματά μας σε πιθανούς νέους φοιτητές δημιουργώντας κοινό σε πλατφόρμες μάρκετινγκ Αυτό μπορεί να περιλαμβάνει την κοινή χρήση διευθύνσεων email με αυτές τις πλατφόρμες. Για τον ίδιο σκοπό, τα ανώνυμα δεδομένα δραστηριότητας θα κοινοποιούνται στα δίκτυα μάρκετινγκ. Μπορείτε να αποτρέψετε την κοινή χρήση πληροφοριών ηλεκτρονικού ταχυδρομείου καταργώντας την επιλογή του πλαισίου ελέγχου συναίνεσης μάρκετινγκ στο προφίλ μαθημάτων σας Για να αποτρέψετε την κοινή χρήση δεδομένων δραστηριότητας, μπορείτε να αλλάξετε τις ρυθμίσεις cookie στον ιστότοπο του μαθήματος. Η αποδοχή αυτών των όρων απαιτείται για να συνεχίσετε να χρησιμοποιείτε την υπηρεσία.',
    'missingInfo.privacyPolicyUpdateLink': 'Μπορείτε να διαβάσετε την πολιτική απορρήτου εδώ.',
    // routes
    'routes.LANDING': '/el/',
    'routes.SIGN_UP': '/el/signup',
    'routes.SIGN_IN': '/el/signin',
    'routes.MISSING_INFO': '/el/missinginfo',
    'routes.PASSWORD_FORGET': '/el/pw-forget',
    'routes.HOME': '/el/home',
    'routes.ACCOUNT': '/el/account',
    'routes.ACCEPT_POLICY': '/el/accept-policy',
    'routes.PRIVACY': 'https://www.elementsofai.gr/faq/%CF%80%CE%BF%CE%BB%CE%B9%CF%84%CE%B9%CE%BA%CE%AE-%CE%B1%CF%80%CE%BF%CF%81%CF%81%CE%AE%CF%84%CE%BF%CF%85-1',
    'routes.FAQ': 'https://www.elementsofai.gr/faq',
    'routes.ABOUT': 'https://www.elementsofai.gr/',
    'routes.SPECTRUM': 'https://community.elementsofai.com/c/el-ellhnikh-koinothta',
    'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
    'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
    'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
    'routes.ects.faq': 'https://www.elementsofai.gr/faq/%CF%80%CF%8E%CF%82-%CE%BC%CF%80%CE%BF%CF%81%CF%8E-%CE%BD%CE%B1-%CE%BB%CE%AC%CE%B2%CF%89-2-%CE%BC%CE%BF%CE%BD%CE%AC%CE%B4%CE%B5%CF%82-ects-%CE%BC%CF%8C%CE%BD%CE%BF-%CE%B3%CE%B9%CE%B1-%CF%84%CE%B7-%CF%86%CE%B9%CE%BD%CE%BB%CE%B1%CE%BD%CE%B4%CE%AF%CE%B1',
    // MarkdownComponents
    'markdowncomponents.linearRegressionStatic.xLabel': 'Κούπες καφέ ημερησίως',
    'markdowncomponents.linearRegressionStatic.yLabel': 'Αριθμός γραμμών κώδικα',
    // meta
    'meta.title': 'Ένας δωρεάν διαδικτυακός κύκλος μαθημάτων',
    'meta.titleTemplate': '%s - Elements of AI',
    'meta.description': 'Μάθετε περισσότερα για τον κύκλο μαθημάτων για φοιτητές και επαγγελματίες του πανεπιστημίου του Ελσίνκι και του Reaktor – δεν απαιτούνται δεξιότητες προγραμματισμού ή μαθηματικών.',
    'meta.sitename': 'Elements of AI',
    // ECTS
    'ects.text': 'Θα λάβετε οδηγίες σχετικά με την υποβολή αίτησης για μονάδες ECTS εδώ μετά την ολοκλήρωση του κύκλου μαθημάτων.',
    'ects.textCompleted': 'Εάν διαθέτετε φινλανδικό αριθμό κοινωνικής ασφάλισης, μπορείτε να υποβάλετε αίτηση για μονάδες κάνοντας κλικ ',
    'ects.link': 'εδώ.',
    'ects.info': 'Θα θέλατε να υποβάλετε αίτηση για μονάδες ECTS?',
    'ects.readMore': 'Συνεχίστε το διάβασμα',
    // Countries
    'countryselect.select': "Παρακαλώ επιλέξτε",
    'Sweden': "Σουηδία",
    'Estonia': "Εσθονία",
    'Finland': "Φινλανδία",
    'Germany': "Γερμανία",
    "Countries": {
        "Afganistan": "Αφγανιστάν",
        "Åland Islands": "Νησιά Åland",
        "Albania": "Αλβανία",
        "Algeria": "Αλγερία",
        "American Samoa": "Αμερικάνικη Σαμόα",
        "Andorra": "Ανδόρα",
        "Angola": "Αγκόλα",
        "Anguilla": "Ανγκουίλα",
        "Antarctica": "Ανταρκτική",
        "Antigua and Barbuda": "Αντίγκουα και Μπαρμπούντα",
        "Argentina": "Αργεντίνη",
        "Armenia": "Αρμενία",
        "Aruba": "Αρούμπα",
        "Australia": "Αυστραλία",
        "Austria": "Αυστρία",
        "Azerbaijan": "Αζερμπαϊτζάν",
        "Bahamas": "Μπαχάμες",
        "Bahrain": "Μπαχρέιν",
        "Bangladesh": "Μπαγκλαντές",
        "Barbados": "Μπαρμπάντος",
        "Belarus": "Λευκορωσία",
        "Belgium": "Βέλγιο",
        "Belize": "Μπελίζ",
        "Benin": "Μπενίν",
        "Bermuda": "Βερμούδα",
        "Bhutan": "Μπουτάν",
        "Bolivia": "Βολιβία",
        "Bosnia and Herzegovina": "Βοσνία και Ερζεγοβίνη",
        "Botswana": "Μποτσουάνα",
        "Bouvet Island": "Νησί Μπούβετ",
        "Brazil": "Βραζιλία",
        "British Indian Ocean Territory": "Έδαφος Βρετανικού Ινδικού Ωκεανού",
        "Brunei Darussalam": "Μπρουνάι Νταρουσαλάμ",
        "Bulgaria": "Βουλγαρία",
        "Burkina Faso": "Μπουρκίνα Φάσο",
        "Burundi": "Μπουρούντι",
        "Cambodia": "Καμπότζη",
        "Cameroon": "Καμερούν",
        "Canada": "Καναδάς",
        "Cape Verde": "Πράσινο Ακρωτήριο",
        "Cayman Islands": "Νησιά Καϊμάν",
        "Central African Republic": "Δημοκρατία Κεντρικής Αφρικής",
        "Chad": "Τσαντ",
        "Chile": "χιλή",
        "China": "Κίνα",
        "Christmas Island": "Νησί των Χριστουγέννων",
        "Cocos (Keeling) Islands": "Νησιά Κόκος (Κίλινγκ)",
        "Colombia": "Κολομβία",
        "Comoros": "Κομόρες",
        "Congo": "Κογκό",
        "Congo, The Democratic Republic of The": "Λαϊκή Δημοκρατία του Κονγκό",
        "Cook Islands": "Νησιά Κουκ",
        "Costa Rica": "Κόστα Ρίκα",
        "Cote D'ivoire": "Cote D'ivoire",
        "Croatia": "Κροατία",
        "Cuba": "Κούβα",
        "Cyprus": "Κύπρος",
        "Czech Republic": "Τσεχική Δημοκρατία",
        "Denmark": "Δανία",
        "Djibouti": "Τζιμπουτί",
        "Dominica": "Ντομίνικα",
        "Dominican Republic": "Δομινικανή Δημοκρατία",
        "Ecuador": "Εκουαδόρ",
        "Egypt": "Αίγυπτος",
        "El Salvador": "Ελ Σαλβαδόρ",
        "Equatorial Guinea": "Ισημερινή Γουινέα",
        "Eritrea": "Ερυθραία",
        "Estonia": "Εσθονία",
        "Ethiopia": "Αιθιοπία",
        "Falkland Islands (Malvinas)": "Νήσοι Φώκλαντ (Μαλβινάς)",
        "Faroe Islands": "Νήσοι Φερόες",
        "Fiji": "Φίτζι",
        "Finland": "Φινλανδία",
        "France": "Γαλλία",
        "French Guiana": "Γαλλική Γουιάνα",
        "French Polynesia": "Γαλλική Πολυνησία",
        "French Southern Territories": "Γαλλικά νότια εδάφη",
        "Gabon": "Γκαμπόν",
        "Gambia": "Γκάμπια",
        "Georgia": "Γεωργία",
        "Germany": "Γερμανία",
        "Ghana": "Γκάνα",
        "Gibraltar": "Γιβραλτάρ",
        "Greece": "Ελλάδα",
        "Greenland": "Γροιλανδία",
        "Grenada": "Γρενάδα",
        "Guadeloupe": "Γουαδελούπη",
        "Guam": "Γκουάμ",
        "Guatemala": "Γουατεμάλα",
        "Guernsey": "Γκέρνσεϊ",
        "Guinea": "Γκινέα",
        "Guinea-Bissau": "Γουινέα-Μπισάου",
        "Guyana": "Γουιάνα",
        "Haiti": "Αΐτη",
        "Heard Island and Mcdonald Islands": "Νησιά Χερντ και Νησιά Μακντόναλντ",
        "Holy See (Vatican City State)": "Αγία Έδρα (Πόλη του Βατικανού)",
        "Honduras": "Ονδούρα",
        "Hong Kong": "Χονγκ Κονγκ",
        "Hungary": "Ουγγαρία",
        "Iceland": "Ισλανδία",
        "India": "Ινδία",
        "Indonesia": "Ινδονησία",
        "Iran, Islamic Republic of": "Ιράν, Ισλαμική Δημοκρατία της",
        "Iraq": "Ιράκ",
        "Ireland": "Ιρλανδία",
        "Isle of Man": "Νήσος του Μαν",
        "Israel": "Ισραήλ",
        "Italy": "Ιταλία",
        "Jamaica": "Ιαμαϊκή",
        "Japan": "Ιαπωνία",
        "Jersey": "Τζέρσεϊ",
        "Jordan": "Ιορδανία",
        "Kazakhstan": "Καζακστάν",
        "Kenya": "Κενύα",
        "Kiribati": "Κιριμπάτι",
        "Korea, Democratic People's Republic of": "Κορέα, Λαϊκή Δημοκρατία της",
        "Korea, Republic of": "Δημοκρατία της Κορέας",
        "Kosovo": "Κοσσυφοπέδιο",
        "Kuwait": "Κουβέιτ",
        "Kyrgyzstan": "Κιργιζιστάν",
        "Lao People's Democratic Republic": "Λαϊκή Δημοκρατία του Λάος",
        "Latvia": "Λετονία",
        "Lebanon": "Λίβανος",
        "Lesotho": "Λεσόθο",
        "Liberia": "Λιβερία",
        "Libyan Arab Jamahiriya": "Λιβυκή Αραβική Jamahiriya",
        "Liechtenstein": "Λιχτενστάιν",
        "Lithuania": "Λιθουανία",
        "Luxembourg": "Λουξεμβούργο",
        "Macao": "Μακάο",
        "Macedonia, The Former Yugoslav Republic of": "Μακεδονία, Πρώην Γιουγκοσλαβική Δημοκρατία της",
        "Madagascar": "Μαδαγασκάρη",
        "Malawi": "Μαλάουι",
        "Malaysia": "Μαλαισία",
        "Maldives": "Μαλδίβες",
        "Mali": "Μάλι",
        "Malta": "Μάλτα",
        "Marshall Islands": "Νήσοι Μάρσαλ",
        "Martinique": "Μαρτινίκα",
        "Mauritania": "Μαυριτανία",
        "Mauritius": "Μαυρίκιος",
        "Mayotte": "Μαγιότ",
        "Mexico": "Μεξικό",
        "Micronesia, Federated States of": "Μικρονησία, Ομοσπονδιακά κράτη",
        "Moldova, Republic of": "Μολδαβία, Δημοκρατία της",
        "Monaco": "Μονακό",
        "Mongolia": "Μογγολία",
        "Montenegro": "Μαυροβούνιο",
        "Montserrat": "Μοντσερράτ",
        "Marocco": "Μαρόκο",
        "Mozambique": "Μοζαμβίκη",
        "Myanmar": "Μιανμάρ",
        "Namibia": "Ναμίμπια",
        "Nauru": "Ναουρού",
        "Nepal": "Νεπάλ",
        "Netherlands": "Ολλανδία",
        "Netherlands Antilles": "Ολλανδικές Αντίλλες",
        "New Caledonia": "Νέα Καληδονία",
        "New Zealand": "Νέα Ζηλανδία",
        "Nicaragua": "Νικαράγουα",
        "Niger": "Νίγηρας",
        "Nigeria": "Νιγηρία",
        "Niue": "Νιούε",
        "Norfolk Island": "Νησί Νόρφολκ",
        "Northern Mariana Islands": "Βορεια Νησια Μαριανα",
        "Norway": "Νορβηγία",
        "Oman": "Ομάν",
        "Pakistan": "Πακιστάν",
        "Palau": "Παλάου",
        "Palestinian Territory, Occupied": "Παλαιστινιακή Επικράτεια, Κατεχόμενη",
        "Panama": "Παναμάς",
        "Papua New Guinea": "Παπούα Νέα Γουινέα",
        "Paraguay": "Παραγουάη",
        "Peru": "Περού",
        "Philippines": "Φιλιππίνες",
        "Pitcairn": "Πίτκαρν",
        "Poland": "Πολωνία",
        "Portugal": "Πορτογαλία",
        "Puerto Rico": "Πουέρτο Ρίκο",
        "Qatar": "Κατάρ",
        "Reunion": "Ρεϋνιόν",
        "Romania": "Ρουμανία",
        "Russian Federation": "Ρωσική Ομοσπονδία",
        "Rwanda": "Ρουάντα",
        "Saint Helena": "Αγία Ελένη",
        "Saint Kitts and Nevis": "Άγιος Χριστόφορος και Νέβις",
        "Saint Lucia": "Αγία Λουκία",
        "Saint Pierre and Miquelon": "Άγιος Πιέρ και Μικελόν",
        "Saint Vincent and The Grenadines": "Άγιος Βικέντιος και Γρεναδίνες",
        "Samoa": "Σαμόα",
        "San Marino": "Σαν Μαρίνο",
        "Sao Tome and Principe": "Σάο Τομέ και Πρίνσιπε",
        "Saudi Arabia": "Σαουδική Αραβία",
        "Senegal": "Σενεγάλη",
        "Serbia": "Σερβία",
        "Seychelles": "Σεϋχέλλες",
        "Sierra Leone": "Σιέρρα Λεόνε",
        "Singapore": "Σιγκαπούρη",
        "Slovakia": "Σλοβακία",
        "Slovenia": "Σλοβενία",
        "Solomon Islands": "Νησιά του Σολομώντα",
        "Somalia": "Σομαλία",
        "South Africa": "Νότια Αφρική",
        "South Georgia and The South Sandwich Islands": "Νότια Γεωργία και Νησιά Νότιου Σάντουιτς",
        "Spain": "Ισπανία",
        "Sri Lanka": "Σρι Λάνκα",
        "Sudan": "Σουδάν",
        "Suriname": "Σουρινάμ",
        "Svalbard and Jan Mayen": "Svalbard και Jan Mayen",
        "Swaziland": "Σουαζιλάνδη",
        "Sweden": "Σουηδία",
        "Swizerland": "Σουζέρλαντ",
        "Syrian Arab Republic": "Αραβική Δημοκρατία της Συρίας",
        "Taiwan": "Ταϊβάν",
        "Tajikistan": "Τατζικιστάν",
        "Tanzania, United Republic of": "Τανζανία, Ηνωμένη Δημοκρατία της",
        "Thailand": "Ταϊλάνδη",
        "Timor-leste": "Τιμόρ-Λέστε",
        "Togo": "Τόγκο",
        "Tokelau": "Τοκελάου",
        "Tonga": "Τόνγκα",
        "Trinidad and Tobago": "Τρινιντάντ και Τομπάγκο",
        "Tunisia": "Τυνησία",
        "Turkey": "Τουρκία",
        "Turkmenistan": "Τουρκμενιστάν",
        "Turks and Caicos Islands": "Νήσοι Τερκς και Κάικος",
        "Tuvalu": "Τουβαλού",
        "Uganda": "Ουγκάντα",
        "Ukraine": "Ουκρανία",
        "United Arab Emirates": "Ηνωμένα Αραβικά Εμιράτα",
        "United Kingdom": "Ηνωμένο Βασίλειο",
        "United States": "Ηνωμένες Πολιτείες",
        "United States Minor Outlying Islands": "Απομακρυσμένες Νησίδες των Ηνωμένων Πολιτειών",
        "Uruguay": "Ουρουγουάη",
        "Uzbekistan": "Ουζμπεκιστάν",
        "Vanuatu": "Βανουάτου",
        "Venezuela": "Βενεζουέλα",
        "Viet Nam": "Βιετνάμ",
        "Virgin Islands, British": "Παρθένοι Νήσοι, Βρετανοί",
        "Virgin Islands, U.S.": "Παρθένοι Νήσοι, ΗΠΑ",
        "Wallis and Futuna": "Γουόλις και Φουτούνα",
        "Western Sahara": "Δυτική Σαχάρα",
        "Yemen": "Γέμενη",
        "Zambia": "Ζάμπια",
        "Zimbabwe": "Ζιμπάμπουε"
    },
    //Alt texts
    'alt.example-1': 'δύο αυτοοδηγούμενα αυτοκίνητα',
    'alt.example-2': 'χέρι που κρατά ένα κινητό τηλέφωνο',
    'alt.example-3': "πρόσωπο γυναίκας",
    'alt.defining-ai': 'στοίβα λέξεων που αφορούν την ΤΝ',
    'alt.turing-test': 'άνδρας που συζητά στον υπολογιστή με ένα ρομπότ και μια γυναίκα',
    'alt.chinese-room': 'το πείραμα του κινέζικου δωματίου',
    'alt.chicken-crossing': 'ρομπότ σε βάρκα που κοιτά μια κότα, μια αλεπού και μια σακούλα με καλαμπόκι',
    'alt.a-to-b': 'χέρι που κρατά τηλέφωνο που χρησιμοποιεί εφαρμογή πλοήγησης',
    'alt.tic-tac-toe': 'δύο παιδιά που παίζουν τρίλιζα',
    'alt.self-driving-car': 'αυτοοδηγούμενο αυτοκίνητο σε σήμα στοπ',
    'alt.spam-or-ham': 'ένα κανονικό email και ένα email spam',
    'alt.mnist': 'σύνολο δεδομένων MNIST',
    'alt.nearest-neighbor': 'δύο άνθρωποι που ψωνίζουν',
    'alt.nearest-neighbor-graph': 'ταξινομητής πλησιέστερου γείτονα',
    'alt.recommendation': 'γυναίκα και άνδρας που ακούν μουσική στο τηλέφωνο',
    'alt.price-real-estate': 'τρία διαφορετικά σπίτια',
    'alt.supervised-learning': 'ένας άνθρωπος που διδάσκει ένα ρομπότ',
    'alt.dice': 'ζάρια',
    'alt.chess': 'σκακιέρα',
    'alt.poker': 'χέρι που κρατά χαρτιά τράπουλας',
    'alt.eyes': 'τρία άτομα με καστανά μάτια, ένα άτομο με μπλε μάτια',
    'alt.search-1': 'μία διαδρομή που οδηγεί σε έναν στόχο',
    'alt.search-2': 'τέσσερις διαφορετικές διαδρομές που οδηγούν στον ίδιο στόχο',
    'alt.chicken-crossing-1': 'το πέρασμα της κότας, αρχική κατάσταση',
    'alt.chicken-crossing-2': 'το πέρασμα της κότας, πρώτη μετάβαση',
    'alt.chicken-crossing-3': 'το πέρασμα της κότας, όλες οι μεταβάσεις',
    'alt.chicken-crossing-4': 'το πέρασμα της κότας με τη βέλτιστη διαδρομή',
    'alt.chicken-crossing-5': 'το πέρασμα της κότας, αρχική κατάσταση',
    'alt.implication-1': 'άνδρας και γυναίκα',
    'alt.implication-2': 'μάτια',
    'alt.implication-3': 'κάμερα παρακολούθησης',
    'alt.implication-4': 'ρομπότ που εργάζεται σε λάπτοπ',
    'alt.oddchange': 'ήλιος και σύννεφα',
    'alt.brain': 'σχέδιο εγκεφάλου',
    'alt.carstop': 'αυτοοδηγούμενο αυτοκίνητο σε σήμα στοπ',
    'alt.gan': 'ρομπότ που κρατά την εικόνα μιας γάτας και την εικόνα ενός σήματος στοπ',
    'alt.hammock': 'ρομπότ που κάνει αέρα σε έναν άνδρα σε αιώρα',
    'alt.game-tree-1': 'δέντρο παιχνιδιού 1',
    'alt.game-tree-2': 'δέντρο παιχνιδιού 2',
    'alt.game-tree-3': 'δέντρο παιχνιδιού 3',
    'alt.game-tree-4': 'δέντρο παιχνιδιού 4',
    'alt.bayes-rule-1': 'εικόνα που απεικονίζει ψευδή θετικά και αρνητικά ευρήματα στο πλαίσιο ελέγχου για καρκίνο',
    'alt.bayes-rule-2': 'εικόνα που απεικονίζει ψευδή θετικά και αρνητικά ευρήματα στο πλαίσιο ελέγχου για καρκίνο',
    'alt.defineai': 'στοίβα λέξεων που αφορούν την ΤΝ',
    'alt.winter': 'ρομπότ που κοιμάται στο χιόνι',
    'alt.terminator': 'τρομακτικό ρομπότ',
}
