import styled from "styled-components";
import { themeGet } from "styled-system";

export default styled.div`
  height: ${props => (!props.disabled ? "auto" : "150px")};
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  overflow: hidden;
  ${props =>
    !props.disabled
      ? `@media only screen and (min-width: 900px){
    overflow: visible;
  }`
      : ""}
  padding: 0 0.5rem;
  margin-top: 2rem;
  position: relative;
  :after {
    content: "";
    display: ${props => (!props.disabled ? "none" : "block")};
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(
      to bottom,
      rgba(246, 244, 244, 0),
      rgba(246, 244, 244, 0.9),
      rgba(246, 244, 244, 1)
    );
  }
  a {
    color: #9c329c;
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: 2rem 0;
  }
  .tablewrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin: 2rem 0;
    max-width: 90vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;

    @media screen and (max-width: 900px) {
      background-image:
        linear-gradient(to right, #f6f4f4, #f6f4f4),
        linear-gradient(to left, #f6f4f4, #f6f4f4),
        linear-gradient(to right, rgba(0, 0, 0,.25), #f6f4f4),
        linear-gradient(to left, rgba(0, 0, 0,.25), #f6f4f4);
      background-position: left center, right center, left center, right center;
      background-repeat: no-repeat;
      background-color: rgba(246, 244, 244, 0);
      background-size: 30px 100%, 30px 100%, 15px 100%, 15px 100%;
      background-attachment: local, local, scroll, scroll;
    }

    @media only screen and (min-width: 900px) {
      overflow: visible;
    }
  }
  table {
    border: none;
    font-size: 1rem;
    font-family: ${themeGet("fonts.TTNormsProRegular")};
    font-weight: normal;
    border-collapse: collapse;
    overflow: auto;
    width: 100%;

    th,
    td {
      border: none;
      text-align: left;
      padding: 1rem 1rem;
    }
    thead tr {
      font-family: ${themeGet("fonts.TTNormsProBold")};
      font-weight: normal;
      background-color: rgba(0, 0, 0, .1);
    }
    tbody tr:nth-child(even) {
      background-color: rgba(0, 0, 0, .1);
    }
    tr {
      td,
      th {
        border: 1px solid #29264c;
      }
    }
  }
  @media (max-width: 660px) {
    table.mobile-responsive {
      word-wrap: break-word;
      overflow: hidden;
      border: none;
    }
    table.mobile-responsive thead {
      display: none;
    }
    table.mobile-responsive td {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      align-items: baseline;
      width: 100%;
      clear: both;
      border: none;
      font-weight: 100;
      padding: 0.5rem 1rem 0rem 1rem;
      margin-bottom: 0;
      text-align: right;
    }
    table.mobile-responsive tbody {
      display: block;
      overflow: hidden;
    }
    table.mobile-responsive tr {
      display: block;
      overflow: hidden;
      padding: 0rem 0rem 1rem 0rem;
    }
    table.mobile-responsive td[data-th] {
      margin-top: 0.5rem;
    }
    table.mobile-responsive td:not([data-th]) {
      justify-content: flex-end;
    }
    table.mobile-responsive td[data-th]:before  {
      content: attr(data-th);
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      font-weight: bold;
      text-align: left;
    }
  }

  /* padding on sides for quiz text */
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    div:nth-of-type(1) {
      p {
        padding: 0 0.5rem;
      }
    }
  }
`;
