// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
    // Globals
    'globals.locale': 'hr',
    'globals.signIn': 'Prijava',
    'globals.logIn': 'Prijava',
    'globals.signUp': 'Registracija',
    'globals.signOut': 'Odjava',
    'globals.logOut': 'Odjava',
    'globals.submit': 'Pošalji',
    'globals.submitting': 'Slanje...',
    'globals.yourEmail': 'Vaša e-adresa',
    'globals.yourPassword': 'Vaša lozinka',
    'globals.courseOverview': 'Pregled tečaja',
    'globals.forgotPassword': 'Zaboravili ste lozinku?',
    'globals.invalidCredentials': 'Netočni podaci za prijavu',
    'globals.section': 'Dio',
    'globals.exercises': 'Zadaci',
    'globals.chapter': 'Poglavlje',
    'globals.introToAI': 'Uvod u umjetnu inteligenciju',
    'globals.buildingAI': 'Umjetna inteligencija u praksi',
    'globals.acceptPolicy': 'Prihvatite politiku',  
    // section
    'section.footerEndOfTheChapter': 'Došli ste do kraja poglavlja',
    'section.footerEndOfTheCourseTitle': 'Došli ste do kraja tečaja!',
    'section.footerEndOfTheCourseText': 'Nakon što riješite sve zadatke i ocijenite odgovore drugih polaznika, vaše će odgovore pregledati član nastavnog osoblja.',
    'section.footerEndOfTheCourseBAI': 'Nastavite svoje putovanje prema mogućnosti da implementirate svoju prvu ideju o umjetnoj inteligenciji.',
    'section.nextSection': 'Sljedeći dio',
    'section.nextChapter': 'Sljedeće poglavlje',
    'section.start': 'Započnite',
    'section.logisticRegressionExample.slope': 'Nagib krivulje',
    'section.logisticRegressionExample.intercept': 'Odsječak',
    // quiz peer review
    'quiz.awaitingPeerReview': 'Odgovori za ocjenjivanje',
    'quiz.rejectedPeerReview': 'Odbijeno u postupku međusobnog ocjenjivanja',
    'quiz.unsubmittedAnswer': 'Odgovor nije poslan',
    'quiz.pendingPeerReview': 'Čeka se međusobno ocjenjivanje',
    // quiz
    'quiz.status.answered': 'Odgovoreno',
    'quiz.status.rejected': 'Odgovor je odbijen, pokušajte ponovno.',
    'quiz.status.unanswered': 'Bez odgovora',
    'quiz.status.exercisesCompleted': 'Dovršeni zadaci',
    'quiz.status.correctAnswers': 'Točni odgovori',
    'quiz.message.rejectedInReview': 'Vaš prethodni odgovor odbijen je u postupku ocjenjivanja. Pokušajte ponovno.',
    'quiz.youAnswered': 'Vaš odgovor',
    'quiz.yourAnswer': 'Vaš odgovor...',
    'quiz.yourScore': 'Broj bodova:',
    'quiz.quizNotLoaded': 'Učitavanje testa nije uspjelo.',
    'quiz.words': 'Broj riječi:',
    'quiz.exampleAnswer': 'Primjer odgovora:',
    // peer review
    'peerreview.question1': 'Odgovor obrađuje zadanu temu',
    'peerreview.question2': 'Odgovor je potpun',
    'peerreview.question3': 'Odgovor je dobro obrazložen',
    'peerreview.question4': 'Odgovor je lako razumljiv',
    'peerreview.thankYou': 'Hvala što ste ocijenili rad svojih kolega!',
    'peerreview.giveMoreReviews': 'Ako imate vremena,',
    'peerreview.giveMoreReviewsButton': 'ocijenite još odgovorâ drugih polaznika.',
    'peerreview.notEnoughAnswers': 'Za ovaj bi zadatak trebalo biti dostupno međusobno ocjenjivanje, ali na njega nije odgovorilo dovoljno polaznika.',
    'peerreview.selectAnswerMessage': 'Vrijeme je za međusobno ocjenjivanje! U nastavku je nekoliko odgovora drugih polaznika. Odaberite jedan od njih i ocijenite ga. Učinite to za barem tri odgovora.',
    'peerreview.minimumPeerReviewWarning': 'Zadatak možemo prihvatiti tek nakon što ocijenite barem tri odgovora drugih polaznika.',
    'peerreview.select': 'Odaberite',
    'peerreview.reportSpam': 'Prijavite neprimjeren sadržaj',
    'peerreview.feedback': 'Vaš je odgovor pregledan i prihvaćen. Ovdje možete vidjeti što o njemu misle vaši kolege:',
    // exercise17
    'quiz.exercise17.xLabel': 'Godine obrazovanja',
    'quiz.exercise17.yLabel': 'Očekivani životni vijek u godinama',
    'quiz.exercise17.textPart1': 'Razmotrimo vezu između ukupnog broja godina školovanja (koji obuhvaća cijelo razdoblje između vrtića i fakulteta) i očekivanog životnog vijeka. U slici u nastavku podaci iz triju zemalja prikazani su u obliku točaka:',
    'quiz.exercise17.textPart2': 'U prvoj zemlji prosječan broj godina školovanja iznosi 10, a očekivani životni vijek 57 godina, u drugoj zemlji prosječan broj godina školovanja iznosi 13, a očekivani životni vijek 53 godine, a u trećoj zemlji prosječan broj godina školovanja iznosi 20, a očekivani životni vijek 80 godina.',
    'quiz.exercise17.textPart3': 'Krajnje točke pune linije možete povlačiti i postavljati u odgovarajući položaj tako da prate trend podatkovnih elemenata. Napominjemo da liniju nećete moći potpuno poravnati s podatkovnim elementima i to je u redu: neki će se podatkovni elementi nalaziti iznad, a neki ispod linije. Najvažnije je da linija pokazuje opća kretanja.',
    'quiz.exercise17.textPart4': 'Nakon što postavite liniju, možete je upotrijebiti za predviđanje očekivanog životnog vijeka.',
    'quiz.exercise17.textPart5': 'Što iz tih podataka možete zaključiti o očekivanom životnom vijeku osoba s 15 godina obrazovanja? Važno: čak i ako prilagodbom linije očekivani životni vijek uspijete izraziti u konkretnim godinama i mjesecima, to predviđanje neće nužno biti pouzdano. Pri davanju odgovora uzmite u obzir da je količina podataka ograničena.',
    // exercise18
    'quiz.exercise18.xLabel': 'Godine obrazovanja',
    'quiz.exercise18.yLabel': 'Očekivani životni vijek u godinama',
    'quiz.exercise18.textPart1': 'U prethodnom smo zadatku na raspolaganju imali samo podatke iz triju zemalja. Potpuni skup podataka sastoji se od podataka iz 14 različitih zemalja te je prikazan na grafikonu u nastavku:',
    'quiz.exercise18.textPart2': 'Bi li se na temelju tih podataka vaše predviđanje o očekivanom životnom vijeku osoba s 15 godina obrazovanja promijenilo? Ako je odgovor potvrdan, zašto?',
    'quiz.exercise18.textPart3': 'Koja bi se od sljedećih opcija najviše podudarala s vašom procjenom očekivanog životnog vijeka osoba s 15 godina obrazovanja? Odaberite opciju koju smatrate prihvatljivom tako da model ravne linije prilagodite gore navedenim podacima.',
    // exercise19
    'quiz.exercise19.xLabel': 'Broj sati učenja',
    'quiz.exercise19.yLabel': 'Vjerojatnost uspješnog polaganja ispita',
    'quiz.exercise19.textPart1': 'Svaka točka na slici označava jednog studenta. Na dnu slike nalazi se ljestvica s brojem sati koje je student proveo učeći za ispit. Studenti koji su položili ispit prikazani su u obliku točaka na vrhu grafikona, dok su oni koji nisu položili ispit prikazani na dnu grafikona. Za označavanje predviđene vjerojatnosti uspješnog polaganja ispita upotrijebit ćemo ljestvicu s lijeve strane. Vjerojatnost ćemo izračunati na temelju modela logističke regresije, a postupak izračuna objasnit ćemo u nastavku.',
    'quiz.exercise19.textPart2': 'Na temelju te slike možemo otprilike zaključiti da su studenti koji su dulje učili imali veće izglede da će položiti ispit. Intuitivna predviđanja posebno su točna u krajnjim slučajevima: uči li se kraće od jednog sata, ispit će biti vrlo teško položiti, ali uz ulaganje velikog truda većina će studenata ispit uspješno položiti. No što je s onima čiji se broj sati učenja nalazi negdje u sredini? Ako učite šest sati, koliki su izgledi da ćete položiti ispit?',
    'quiz.exercise19.textPart3': 'Vjerojatnost uspješnog polaganja ispita možemo izračunati metodom logističke regresije. Krivulju na slici možemo tumačiti kao vjerojatnost uspješnog polaganja ispita: na primjer, nakon što učite pet sati, vjerojatnost uspješnog polaganja ispita iznosi malo više od 20 %. Nećemo detaljnije analizirati način na koji se dobiva ta krivulja, no sličan je načinu na koji se uče težine u metodi linearne regresije.',
    'quiz.exercise19.textPart4': 'Na temelju prethodne slike, kad biste htjeli da vaša vjerojatnost uspješnog polaganja ispita na fakultetu iznosi 80 %, koliko biste približno sati trebali učiti?',
    // exercise22
    'quiz.exercise22.sigmoidXLabel': 'Ulaz',
    'quiz.exercise22.sigmoidYLabel': 'Izlaz sigmoidne funkcije',
    'quiz.exercise22.stepXLabel': 'Ulaz',
    'quiz.exercise22.stepYLabel': 'Izlaz funkcije praga',
    'quiz.exercise22.identityXLabel': 'Ulaz',
    'quiz.exercise22.identityYLabel': 'Izlaz funkcije identiteta',
    'quiz.exercise22.textPart1': 'U nastavku su prikazani grafikoni za tri različite aktivacijske funkcije s različitim svojstvima. Prvo je prikazana sigmoidna funkcija, zatim funkcija praga i na kraju funkcija identiteta.',
    // exercise23
    'quiz.exercise23.happyFaces': 'točno klasificirani emotikoni nasmiješenog lica',
    'quiz.exercise23.sadFaces': 'točno klasificirani emotikoni tužnog lica',
    // certificate availability
    'certificate.failedToCheck': 'Provjera dostupnosti certifikata nije uspjela.',
    'certificate.failedToGenerate': 'Izrada certifikata nije uspjela.',
    'certificate.notYetAvailable': 'Vaš će certifikat biti dostupan ovdje nakon što ga službeno potvrdimo. To može potrajati neko vrijeme. Hvala na strpljenju!',
    'certificate.updateName': 'Vaš certifikat je dostupan! Ažurirajte svoje ime i prezime kako bi se prikazalo na vašem certifikatu pomoću obrasca dostupnog na stranici vašeg profila.',
    'certificate.generate': 'Certifikat možete izraditi u nastavku.',
    'certificate.generateTextPart1': 'Ime polaznika za kojeg se izrađuje certifikat:',
    'certificate.generateTextPart2': 'Prije izrade certifikata provjerite je li ime točno uneseno jer ga poslije nije moguće promijeniti. Ako želite promijeniti ime, možete to učiniti s pomoću obrasca u nastavku.',
    'certificate.generateButton': 'Izradite certifikat',
    'certificate.downloadButton': 'Preuzmi certifikat',
    'certificate.checkingAvailability': 'U tijeku je provjera dostupnosti certifikata...',
    // Visualizations
    'visualizations.logisticRegressionChart.classifiedCorrectly': 'Točno klasificirani',
    'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Netočno klasificirani',
    // section blocks
    'section.block.note': 'Dobro je znati',
    'section.block.example': 'Primjer',
    'section.block.keyTerminology': 'Ključni pojmovi',
    'section.partSummary.spectrumTextPart1': 'Pridružite se ',
    'section.partSummary.spectrum': 'zajednici polaznika tečaja „Elements of AI”',
    'section.partSummary.spectrumTextPart2': ' kako biste raspravljali i postavljali pitanja o ovom poglavlju.',
    'section.partSummary.feedbackTitle': 'Pošaljite nam povratne informacije o ovom dijelu.',
    'section.partSummary.feedbackPlaceholder': 'Ovdje unesite svoje mišljenje.',
    'section.partSummary.feedbackSuccess': 'Hvala što ste podijelili svoje mišljenje s nama!',
    // 404
    'globals.404.title': '404 – Stranica nije pronađena',
    'globals.404.intro': 'Krenuli ste u smjeru koji ne postoji... Šteta!',
    'globals.loading': 'Učitavanje...',
    // footer / menu
    'globals.menu': 'Izbornik',
    'globals.menu.about': 'O tečaju',
    'globals.menu.faq': 'Česta pitanja',
    'globals.menu.privacyPolicy': 'Politika zaštite privatnosti',
    'globals.menu.termsofService': 'Uvjeti i odredbe',
    'globals.menu.myProfile': 'Moj profil',
    // Dashboard/Index
    'dashboard.title': 'Dobro došli na tečaj!',
    'dashboard.congratulations.title': 'Čestitamo!',
    'dashboard.congratulations.message': 'Završili ste tečaj Elements of AI.',
    'dashboard.congratulations.downloadCertificate': 'Preuzimanje certifikata',
    'dashboard.notLoggedIn.title': 'Dobro došli!',
    'dashboard.notLoggedIn.message': 'Za pristup cijelom tečaju morate se registrirati ili prijaviti. Besplatno je!',
    // Account
    'account.certificate': 'Certifikat',
    'account.credits': 'ECTS bodovi',
    'account.myProfile': 'Moj profil',
    'account.profileInformation': 'Podaci o profilu',
    'account.changePassword': 'Promjena lozinke',
    'account.changeLanguage': 'Odabir jezika',
    'account.goToCourse': 'Idite na tečaj',
    'account.buildingAINew': 'Novi tečaj engleskog jezika',
    'account.accept': 'Prihvatite',
    'account.decline': 'Odbijte',
    'account.courses': 'Tečajevi',  
    // Sign Up page
    'signUp.description': 'Za izradu novog računa na portalu mooc.fi ispunite obrazac u nastavku.',
    'signUp.email': 'Vaša e-adresa',
    'signUp.firstName': 'Ime',
    'signUp.lastName': 'Prezime',
    'signUp.postalCode': 'Poštanski broj',
    'signUp.country': 'Zemlja u kojoj živite',
    'signUp.Password': 'Lozinka',
    'signUp.confirmPassword': 'Potvrdi lozinku',
    'signUp.howToStudyTitle': 'Odaberite kako ćete pohađati tečaj:',
    'signUp.6weeksTitle': 'Tečaj u trajanju od 6 tjedana',
    'signUp.6weeksSubTitle': 'Preporučeni format (otprilike 5 sati tjedno)',
    'signUp.6weeksText': 'Tečaj je lakše završiti ako si za to zadate rok.',
    'signUp.selfPacedTitle': 'Tečaj bez vremenskog ograničenja',
    'signUp.selfPacedText': 'Sami odredite ritam pohađanja tečaja.',
    'signUp.ectsTitleSwe': '',
    'signUp.ectsTitle': 'Želite li zatražiti ECTS bodove?',
    'signUp.ectsStudent': 'Student sam Sveučilišta u Helsinkiju. Ako označite ovaj okvir i unesete matični broj studenta, ECTS bodovi dodijelit će vam se automatski.',
    'signUp.ectsStudentSwe': '',
    'signup.ectsStudentSweLink': '',
    'signUp.ectsOpenUni': 'Označite ovaj okvir da vam pošaljemo e-poruku s uputama o tome kako zatražiti ECTS bodove.',
    'signUp.studentNumber': 'Matični broj studenta Sveučilišta u Helsinkiju (ako ste student)',
    'signUp.privacyOptIn': 'Pristajem na to da se prikupljeni podaci o mojem ponašanju tijekom učenja upotrebljavaju za potrebe istraživanja. Podaci sadržavaju informacije o uporabi materijala i informacije iz zadataka u okviru tečaja. Na temelju objavljenih podataka nije moguće identificirati pojedinačne korisnike.',
    'signUp.marketingOptIn': 'Pristajem primati novosti o planiranim inačicama na drugim jezicima i informacije o novim tečajevima. Pristajem podijeliti svoje podatke za kontakt kako bih primao/primala prilagođene poruke na platformama trećih strana. Saznajte više o našoj politici zaštite privatnosti.',
    'signUp.acceptPolicyFirst': 'Prihvaćam Politiku privatnosti i Uvjete pružanja usluge.',
    'signUp.privacyLink': 'Doznajte više o našoj politici zaštite privatnosti.',
    'signUp.error': 'Provjerite obrazac jer u njemu ima pogrešaka.',
    // Sign in page
    'signIn.title': 'Prijava',
    'signIn.intro': 'Za prijavu na račun na portalu mooc.fi ispunite obrazac u nastavku.',
    'signIn.email': 'Vaša e-adresa',
    'signIn.password': 'Vaša lozinka',
    'signIn.noAccount': 'Nemate račun?',
    'signIn.signUpNow': 'Registrirajte se',
    // Forgot password
    'pwForget.title': 'Zaboravili ste lozinku?',
    'pwForget.description': 'Unesite e-adresu s kojom se prijavljujete na račun na portalu mooc.fi i poslat ćemo vam poveznicu za ponovno postavljanje lozinke.',
    'pwForget.sendResetLink': 'Pošaljite poveznicu za novu lozinku',
    'pwForget.completedDescription': 'Uskoro biste od portala mooc.fi trebali primiti e-poruku s poveznicom za ponovno postavljanje lozinke. Ako je ne vidite, provjerite pretinac za neželjenu poštu.',
    'pwForget.problemDescription': 'Ako naiđete na problem, obratite se na adresu mooc@cs.helsinki.fi.',
    'pwForget.resetEmailSent': 'Poslali smo vam e-poruku za ponovno postavljanje lozinke.',
    'pwForget.emailPlaceholder': 'E-adresa',
    // password change
    'pwChange.authError': 'Autentifikacija nije uspjela. Pokušajte se ponovno prijaviti.',
    'pwChange.unexpError': 'Lozinka nije ažurirana zbog neočekivane pogreške.',
    'pwChange.required': 'Obvezno',
    'pwChange.pwMustMatch': 'Lozinke se moraju podudarati.',
    'pwChange.currentPassword': 'Postojeća lozinka',
    'pwChange.newPassword': 'Nova lozinka',
    'pwChange.confirmPassword': 'Potvrda nove lozinke',
    'pwChange.changingPassword': 'Promjena lozinke...',
    'pwChange.changePassword': 'Promijenite lozinku',
    'pwChange.passwordChanged': 'Lozinka je promijenjena.',
    // user details
    'userDetails.authError': 'Autentifikacija nije uspjela. Pokušajte se ponovno prijaviti.',
    'userDetails.unexpError': 'Profil nije ažuriran zbog neočekivane pogreške.',
    'userDetails.required': 'Obvezno',
    'userDetails.invalidEmail': 'Nevaljana e-adresa',
    'userDetails.email': 'E-pošta',
    'userDetails.emailAddress': 'E-adresa',
    'userDetails.firstName': 'Ime',
    'userDetails.lastName': 'Prezime',
    'userDetails.language': 'Jezik',
    'userDetails.updatingProfile': 'Profil se ažurira...',
    'userDetails.updateProfile': 'Ažuriraj profil',
    'userDetails.profileUpdated': 'Profil je ažuriran.',
    // languages
    'languages.en.long': 'engleski',
    'languages.fi.long': 'finski',
    'languages.se.long': 'švedski',
    'languages.de.long': 'njemački (Njemačka)',
    'languages.ee.long': 'estonski',
    'languages.no.long': 'norveški',
    'languages.lv.long': 'letonski',
    'languages.hr.long': 'hrvatski',
    'languages.fr.long': 'francuski (Francuska)',
    'languages.lt.long': 'litavski',
    'languages.ga.long': 'irski',
    'languages.fr-be.long': 'francuski (Belgija)',
    'languages.nl-be.long': 'nizozemski (Belgija)',
    'languages.it.long': 'talijanski',
    'languages.sk.long': 'slovački',
    'languages.mt.long': 'malteški',
    'languages.pl.long': 'poljski',
    'languages.en-ie.long': 'engleski (Irska)',
    'languages.da.long': 'danski',
    'languages.ro.long': 'rumunjski',
    'languages.is.long': 'islandski',
    'languages.de-at.long': 'njemački (Austrija)',
    'languages.en-lu.long': 'engleski (Luksemburg)',
    'languages.bg.long': 'bugarski',
    'languages.cs.long': 'češki',
    'languages.sl.long': 'slovenski',
    'languages.pt.long': 'portugalski',
    'languages.nl.long': 'nizozemski (Nizozemska)',
    'languages.es.long': 'španjolski',
    'languages.el.long': 'grčki',
    // Email verification
    'emailVerification.completedMessage': 'Vaša e-adresa je potvrđena.',
    'emailVerification.verified': 'Potvrđeno',
    // Exercises
    'exercise.loading': 'Učitavanje zadatka...',
    'exercise.signUp': 'Da biste mogli rješavati zadatke, morate se prijaviti',
    'exercise.score': 'Broj bodova:',
    'exercise.wrongLocale': 'Pogrešne regionalne postavke',
    // Part tempplate
    'partTemplate.intro1': 'U poglavlju ',
    'partTemplate.intro2': ' obradit ćemo teme navedene u nastavku. Da biste započeli, kliknite na odgovarajući naslov:',
    'partTemplate.spectrumPart1': 'Pridružite se ',
    'partTemplate.spectrum': 'zajednici polaznika tečaja „Elements of AI”',
    'partTemplate.spectrumPart2': ' kako biste raspravljali i postavljali pitanja o ovom poglavlju.',
    // missing info
    'missingInfo.title': 'Nedostaju neke informacije',
    'missingInfo.body': 'Ta pitanja obično postavljamo pri registraciji, ali vi ste se prijavili s postojećim računom za portal mooc.fi. Ispunite ovaj obrazac kako biste nastavili.',
    'missingInfo.privacyPolicy': 'Saznajte više o našoj politici zaštite privatnosti',
    'missingInfo.error': 'Provjerite obrazac jer u njemu ima pogrešaka.',
    'missingInfo.privacyPolicyUpdateBody': "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
    'missingInfo.privacyPolicyUpdateLink': 'Ovdje možete pregledati pravila o privatnosti.',
      // routes
    'routes.LANDING': '/hr/',
    'routes.SIGN_UP': '/hr/signup',
    'routes.SIGN_IN': '/hr/signin',
    'routes.MISSING_INFO': '/hr/missinginfo',
    'routes.PASSWORD_FORGET': '/hr/pw-forget',
    'routes.HOME': '/hr/home',
    'routes.ACCOUNT': '/hr/account',
    'routes.PRIVACY': 'https://www.elementsofai.com/hr/faq/politika-za%C5%A1tite-privatnosti',
    'routes.TERMS': 'https://www.elementsofai.com/faq/terms-and-conditions',
    'routes.FAQ': 'https://www.elementsofai.com/hr/faq',
    'routes.ABOUT': 'https://www.elementsofai.com/hr',
    'routes.SPECTRUM': 'https://community.elementsofai.com/c/hr-hrvatska-zajednica',
    'routes.ects': 'https://mooc.fi/hr/register-completion/elements-of-ai',
    'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
    'routes.ects.faq':'https://www.elementsofai.com/faq/how-can-i-get-ects-credits',
    'routes.ACCEPT_POLICY': '/hr/accept-policy',
    // MarkdownComponents
    'markdowncomponents.linearRegressionStatic.xLabel': 'Broj šalica kave po danu',
    'markdowncomponents.linearRegressionStatic.yLabel': 'Broj napisanih redaka koda',
    // meta
    'meta.title': 'Besplatan internetski tečaj',
    'meta.titleTemplate': '%s - Elements of AI',
    'meta.description': 'Doznajte više o tečaju o umjetnoj inteligenciji namijenjenom studentima i poslovnim stručnjacima. Tečaj su izradili Sveučilište u Helsinkiju i Reaktor. Ne morate znati ništa o programiranju ili višoj matematici.',
    'meta.sitename': 'Elements of AI',
    'meta.title.og': 'Besplatni internetski uvod u umjetnu inteligenciju za nestručnjake',
    // ECTS
    'ects.text': 'Nakon završetka tečaja primit ćete upute o tome kako zatražiti ECTS bodove.',
    'ects.textCompleted': 'Ako imate broj finskog socijalnog osiguranja, bodove možete zatražiti ',
    'ects.link': 'ovdje.',
    'ects.info': 'Želite li se prijaviti za ECTS bodove?',
    'ects.readMore': 'Pročitajte više',
    // Countries
    'countryselect.select': "Molimo izaberite",
    'Sweden': "Švedska",
    'Estonia': "Estonija",
    'Finland': "Finska",
    'Germany': "Njemačka",
    "Countries": {
      "Afganistan": "Afganistan",
      "Åland Islands": "Olandski otoci",
      "Albania": "Albanija",
      "Algeria": "Alžir",
      "American Samoa": "Američka Samoa",
      "Andorra": "Andora",
      "Angola": "Angola",
      "Anguilla": "Angvila",
      "Antarctica": "Antarktika",
      "Antigua and Barbuda": "Antigva i Barbuda",
      "Argentina": "Argentina",
      "Armenia": "Armenija",
      "Aruba": "Aruba",
      "Australia": "Australija",
      "Austria": "Austrija",
      "Azerbaijan": "Azerbejdžan",
      "Bahamas": "Bahami",
      "Bahrain": "Bahrein",
      "Bangladesh": "Bangladeš",
      "Barbados": "Barbados",
      "Belarus": "Bjelorusija",
      "Belgium": "Belgija",
      "Belize": "Belize",
      "Benin": "Benin",
      "Bermuda": "Bermuda",
      "Bhutan": "Butan",
      "Bolivia": "Bolivija",
      "Bosnia and Herzegovina": "Bosna i Hercegovina",
      "Botswana": "Bocvana",
      "Bouvet Island": "Otok Bouvet",
      "Brazil": "Brazil",
      "British Indian Ocean Territory": "Britanski teritorij Indijskog oceana",
      "Brunei Darussalam": "Brunei Darussalam",
      "Bulgaria": "Bugarska",
      "Burkina Faso": "Burkina Faso",
      "Burundi": "Burundi",
      "Cambodia": "Kambodža",
      "Cameroon": "Kamerun",
      "Canada": "Kanada",
      "Cape Verde": "Zelenortski otoci",
      "Cayman Islands": "Kajmanski otoci",
      "Central African Republic": "Centralna Afrička Republika",
      "Chad": "Čad",
      "Chile": "Čile",
      "China": "Kina",
      "Christmas Island": "Božićni otok",
      "Cocos (Keeling) Islands": "Kokosovi (Keeling) otoci",
      "Colombia": "Kolumbija",
      "Comoros": "Komori",
      "Congo": "Kongo",
      "Congo, The Democratic Republic of The": "Kongo, Demokratska Republika",
      "Cook Islands": "Kukovi otoci",
      "Costa Rica": "Kostarika",
      "Cote D'ivoire": "Obala Bjelokosti",
      "Croatia": "Hrvatska",
      "Cuba": "Kuba",
      "Cyprus": "Kipar",
      "Czech Republic": "Češka Republika",
      "Denmark": "Danska",
      "Djibouti": "Džibuti",
      "Dominica": "Dominika",
      "Dominican Republic": "Dominikanska Republika",
      "Ecuador": "Ekvador",
      "Egypt": "Egipat",
      "El Salvador": "El Salvador",
      "Equatorial Guinea": "Ekvatorijalna Gvineja",
      "Eritrea": "Eritreja",
      "Estonia": "Estonija",
      "Ethiopia": "Etiopija",
      "Falkland Islands (Malvinas)": "Falklandski otoci (Malvinas)",
      "Faroe Islands": "Farski otoci",
      "Fiji": "Fidži",
      "Finland": "Finska",
      "France": "Francuska",
      "French Guiana": "Francuska Gvajana",
      "French Polynesia": "Francuska Polinezija",
      "French Southern Territories": "Francuski južni teritoriji",
      "Gabon": "Gabon",
      "Gambia": "Gambija",
      "Georgia": "Gruzija",
      "Germany": "Njemačka",
      "Ghana": "Gana",
      "Gibraltar": "Gibraltar",
      "Greece": "Grčka",
      "Greenland": "Grenland",
      "Grenada": "Grenada",
      "Guadeloupe": "Gvadalupa",
      "Guam": "Guam",
      "Guatemala": "Gvatemala",
      "Guernsey": "mornarska majica",
      "Guinea": "Gvineja",
      "Guinea-Bissau": "Gvineja Bisau",
      "Guyana": "Gvajana",
      "Haiti": "Haiti",
      "Heard Island and Mcdonald Islands": "Otoci Heard i Mcdonald",
      "Holy See (Vatican City State)": "Sveta Stolica (Vatikanska gradska država)",
      "Honduras": "Honduras",
      "Hong Kong": "Hong Kong",
      "Hungary": "Mađarska",
      "Iceland": "Island",
      "India": "Indija",
      "Indonesia": "Indonezija",
      "Iran, Islamic Republic of": "Iran, Islamska Republika",
      "Iraq": "Irak",
      "Ireland": "Irska",
      "Isle of Man": "Otok Man",
      "Israel": "Izrael",
      "Italy": "Italija",
      "Jamaica": "Jamajka",
      "Japan": "Japan",
      "Jersey": "Jersey",
      "Jordan": "Jordan",
      "Kazakhstan": "Kazahstan",
      "Kenya": "Kenija",
      "Kiribati": "Kiribati",
      "Korea, Democratic People's Republic of": "Koreja, Demokratska Narodna Republika",
      "Korea, Republic of": "Republika Koreja",
      "Kosovo": "Kosovo",
      "Kuwait": "Kuvajt",
      "Kyrgyzstan": "Kirgistan",
      "Lao People's Democratic Republic": "Laoška ​​narodna demokratska republika",
      "Latvia": "Letonija",
      "Lebanon": "Liban",
      "Lesotho": "Lesoto",
      "Liberia": "Liberija",
      "Libyan Arab Jamahiriya": "Libijska arapska Jamahirija",
      "Liechtenstein": "Lihtenštajn",
      "Lithuania": "Litva",
      "Luxembourg": "Luksemburg",
      "Macao": "Makao",
      "Macedonia, The Former Yugoslav Republic of": "Makedonija, Bivša Jugoslavenska Republika",
      "Madagascar": "Madagaskar",
      "Malawi": "Malavi",
      "Malaysia": "Malezija",
      "Maldives": "Maldivi",
      "Mali": "Mali",
      "Malta": "Malta",
      "Marshall Islands": "Maršalovi otoci",
      "Martinique": "Martinik",
      "Mauritania": "Mauritanija",
      "Mauritius": "Mauricijus",
      "Mayotte": "Mayotte",
      "Mexico": "Meksiko",
      "Micronesia, Federated States of": "Mikronezija, Federativne države",
      "Moldova, Republic of": "Moldavija, Republika",
      "Monaco": "Monaco",
      "Mongolia": "Mongolija",
      "Montenegro": "Crna Gora",
      "Montserrat": "Montserrat",
      "Marocco": "Maroko",
      "Mozambique": "Mozambik",
      "Myanmar": "Mjanmar",
      "Namibia": "Namibija",
      "Nauru": "Nauru",
      "Nepal": "Nepal",
      "Netherlands": "Nizozemska",
      "Netherlands Antilles": "Nizozemski Antili",
      "New Caledonia": "nova Kaledonija",
      "New Zealand": "Novi Zeland",
      "Nicaragua": "Nikaragva",
      "Niger": "Niger",
      "Nigeria": "Nigerija",
      "Niue": "Niue",
      "Norfolk Island": "Otok Norfolk",
      "Northern Mariana Islands": "Sjeverna Marijanska ostrva",
      "Norway": "Norveška",
      "Oman": "Oman",
      "Pakistan": "Pakistan",
      "Palau": "Palau",
      "Palestinian Territory, Occupied": "Palestinsko područje, okupirano",
      "Panama": "Panama",
      "Papua New Guinea": "Papua Nova Gvineja",
      "Paraguay": "Paragvaj",
      "Peru": "Peru",
      "Philippines": "Filipini",
      "Pitcairn": "Pitcairn",
      "Poland": "Poljska",
      "Portugal": "Portugal",
      "Puerto Rico": "Portoriko",
      "Qatar": "Katar",
      "Reunion": "Réunion",
      "Romania": "Rumunjska",
      "Russian Federation": "Ruska Federacija",
      "Rwanda": "Ruanda",
      "Saint Helena": "Sveta Helena",
      "Saint Kitts and Nevis": "Saint Kitts i Nevis",
      "Saint Lucia": "Sveta Lucija",
      "Saint Pierre and Miquelon": "Sveti Pierre i Miquelon",
      "Saint Vincent and The Grenadines": "Sveti Vincent i Grenadini",
      "Samoa": "Samoa",
      "San Marino": "San Marino",
      "Sao Tome and Principe": "Sao Tome i Principe",
      "Saudi Arabia": "Saudijska Arabija",
      "Senegal": "Senegal",
      "Serbia": "Srbija",
      "Seychelles": "Sejšeli",
      "Sierra Leone": "Sierra Leone",
      "Singapore": "Singapur",
      "Slovakia": "Slovačka",
      "Slovenia": "Slovenija",
      "Solomon Islands": "Salomonovi otoci",
      "Somalia": "Somalija",
      "South Africa": "Južna Afrika",
      "South Georgia and The South Sandwich Islands": "Južna Džordžija i Južni sendvič",
      "Spain": "Španija",
      "Sri Lanka": "Šri Lanka",
      "Sudan": "Sudan",
      "Suriname": "Surinam",
      "Svalbard and Jan Mayen": "Svalbard i Jan Mayen",
      "Swaziland": "Svazi",
      "Sweden": "Švedska",
      "Swizerland": "Švicarska",
      "Syrian Arab Republic": "Sirijska Arapska Republika",
      "Taiwan": "Tajvan",
      "Tajikistan": "Tadžikistan",
      "Tanzania, United Republic of": "Tanzanija, Ujedinjena Republika",
      "Thailand": "Tajland",
      "Timor-leste": "Timor-Leste",
      "Togo": "Togo",
      "Tokelau": "Tokelau",
      "Tonga": "Laka dvokolica",
      "Trinidad and Tobago": "Trinidad i Tobago",
      "Tunisia": "Tunis",
      "Turkey": "Turska",
      "Turkmenistan": "Turkmenistan",
      "Turks and Caicos Islands": "Otoci Turks i Caicos",
      "Tuvalu": "Tuvalu",
      "Uganda": "Uganda",
      "Ukraine": "Ukrajina",
      "United Arab Emirates": "Ujedinjeni Arapski Emirati",
      "United Kingdom": "Ujedinjeno Kraljevstvo",
      "United States": "Ujedinjene države",
      "United States Minor Outlying Islands": "Mali udaljeni otoci SAD-a",
      "Uruguay": "Urugvaj",
      "Uzbekistan": "Uzbekistan",
      "Vanuatu": "Vanuatu",
      "Venezuela": "Venecuela",
      "Viet Nam": "Vijetnam",
      "Virgin Islands, British": "Djevičanski otoci, britanski",
      "Virgin Islands, U.S.": "Djevičanski otoci, Sjedinjene Američke Države",
      "Wallis and Futuna": "Wallis i Futuna",
      "Western Sahara": "Zapadna Sahara",
      "Yemen": "Jemen",
      "Zambia": "Zambija",
      "Zimbabwe": "Zimbabve",  
  
    },
    //Alt texts
      'alt.example-1':'dva autonomna automobila',
      'alt.example-2':'ruka koja drži mobilni telefon',
      'alt.example-3':"žensko lice",
      'alt.defining-ai':'gomila riječi o umjetnoj inteligenciji',
      'alt.turing-test':'muškarac preko računala razgovara s robotom i ženom',
      'alt.chinese-room':'eksperiment s kineskom sobom',
      'alt.chicken-crossing':'robot koji iz čamca gleda pile, lisicu i vrećicu hrane',
      'alt.a-to-b':'ruka koja drži telefon s otvorenom aplikacijom za navigaciju',
      'alt.tic-tac-toe':'dvoje djece igra križić-kružić',
      'alt.self-driving-car':'autonomni automobil ispred znaka za obvezno zaustavljanje',
      'alt.spam-or-ham':'normalna e-poruka i neželjena e-poruka',
      'alt.mnist':'skup podataka MNIST',
      'alt.nearest-neighbor':'dvoje ljudi u kupnji',
      'alt.nearest-neighbor-graph':'klasifikator najbližih susjeda',
      'alt.recommendation':'muškarac i žena slušaju glazbu na mobitelu',
      'alt.price-real-estate':'tri različite kuće',
      'alt.supervised-learning':'čovjek uči robota',
      'alt.dice':'kocke',
      'alt.chess':'šahovska ploča',
      'alt.poker':'ruka koja drži igraće karte',
      'alt.eyes':'troje ljudi smeđih očiju, jedna osoba plavih očiju',
      'alt.search-1':'do cilja vodi jedan put',
      'alt.search-2':'do istog cilja vode četiri različita puta',
      'alt.chicken-crossing-1':'mozgalica s čamcem – početno stanje',
      'alt.chicken-crossing-2':'mozgalica s čamcem – prvi prijelaz',
      'alt.chicken-crossing-3':'mozgalica s čamcem – svi prijelazi',
      'alt.chicken-crossing-4':'mozgalica s čamcem – najbolji put',
      'alt.chicken-crossing-5':'mozgalica s čamcem – početno stanje',
      'alt.implication-1':'muškarac i žena',
      'alt.implication-2':'oči',
      'alt.implication-3':'nadzorna kamera',
      'alt.implication-4':'robot radi na prijenosnom računalu',
      'alt.oddchange':'sunce i oblaci',
      'alt.brain':'crtež mozga',
      'alt.carstop':'autonomni automobil ispred znaka STOP',
      'alt.gan':'robot drži sliku mačke i sliku znaka za obvezno zaustavljanje',
      'alt.hammock':'robot hladi muškarca u ležaljci',
      'alt.game-tree-1':'stablo igre 1',
      'alt.game-tree-2':'stablo igre 2',
      'alt.game-tree-3':'stablo igre 3',
      'alt.game-tree-4':'stablo igre 4',
      'alt.bayes-rule-1':'ilustracija lažno pozitivnih i negativnih rezultata probira raka',
      'alt.bayes-rule-2':'ilustracija lažno pozitivnih i negativnih rezultata probira raka',
      'alt.defineai':'gomila riječi o UI-ju',
      'alt.winter':'robot spava na snijegu',
      'alt.terminator':'strašan robot',
  }
  
  
