// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
    // Globals
    'globals.locale': 'lt',
    'globals.signIn': 'Prisijungti',
    'globals.logIn': 'Prisijungti',
    'globals.signUp': 'Registruotis',
    'globals.signOut': 'Atsijungti',
    'globals.logOut': 'Atsijungti',
    'globals.submit': 'Pateikti',
    'globals.submitting': 'Pateikiama...',
    'globals.yourEmail': 'Jūsų e. pašto adresas',
    'globals.yourPassword': 'Jūsų slaptažodis',
    'globals.courseOverview': 'Kurso apžvalga',
    'globals.forgotPassword': 'Pamiršote slaptažodį?',
    'globals.invalidCredentials': 'Netinkami identifikavimo duomenys',
    'globals.section': 'Skirsnis',
    'globals.exercises': 'Pratimai',
    'globals.chapter': 'Skyrius',
    'globals.introToAI': 'Dirbtinio intelekto mokslo įvadas',
    'globals.buildingAI': 'Dirbtinio intelekto kūrimas',
    'globals.acceptPolicy': 'Accept policy',
    // section
    'section.footerEndOfTheChapter': 'Jūs baigėte skyrių Nr. {chapter}',
    'section.footerEndOfTheCourseTitle': 'Jūs baigėte kursą!',
    'section.footerEndOfTheCourseText': 'Kai atliksite visus pratimus ir pateiksite visus tarpusavio įvertinimus, jūsų atsakymus peržiūrės mūsų darbuotojai.',
    'section.nextSection': 'Kitas skirsnis',
    'section.nextChapter': 'Kitas skyrius',
    'section.start': 'Pradžia',
    'section.logisticRegressionExample.slope': 'Krypties koeficientas',
    'section.logisticRegressionExample.intercept': 'Laisvasis narys',
    'section.footerEndOfTheCourseBAI': 'Tęskite savo kelią, kad galėtumėte įgyvendinti savo pirmąją DI idėją.',
    // quiz peer review
    'quiz.awaitingPeerReview': 'Laukiama, kol įvertinsite.',
    'quiz.rejectedPeerReview': 'Atmesta atlikus tarpusavio vertinimą',
    'quiz.unsubmittedAnswer': 'Atsakymas nepateiktas',
    'quiz.pendingPeerReview': 'Laukiama tarpusavio įvertinimo',
    // quiz
    'quiz.status.answered': 'Atsakyta',
    'quiz.status.rejected': 'Atsakymas atmestas, bandykite dar kartą.',
    'quiz.status.unanswered': 'Neatsakyta',
    'quiz.status.exercisesCompleted': 'Atlikti pratimai',
    'quiz.status.correctAnswers': 'Teisingi atsakymai',
    'quiz.message.rejectedInReview': 'Jūsų ankstesnis atsakymas buvo įvertintas ir atmestas. Bandykite dar kartą.',
    'quiz.youAnswered': 'Jūsų atsakymas',
    'quiz.yourAnswer': 'Jūsų atsakymas...',
    'quiz.yourScore': 'Jūsų rezultatas:',
    'quiz.quizNotLoaded': 'Nepavyko įkelti klausimyno.',
    'quiz.words': 'Žodžių skaičius:',
    'quiz.exampleAnswer': 'Atsakymo pavyzdys:',
    // peer review
    'peerreview.question1': 'Atsakymas atitinka temą.',
    'peerreview.question2': 'Atsakymas išsamus.',
    'peerreview.question3': 'Atsakymas tinkamai pagrįstas.',
    'peerreview.question4': 'Atsakymas lengvai suprantamas.',
    'peerreview.thankYou': 'Dėkojame, kad įvertinote savo bendramokslių darbą!',
    'peerreview.giveMoreReviews': 'Jei turite laisvo laiko,',
    'peerreview.giveMoreReviewsButton': 'pateikite daugiau įvertinimų.',
    'peerreview.notEnoughAnswers': 'Paprastai čia turėtų būti atliekamas tarpusavio vertinimas, tačiau šio pratimo atsakymą pateikė nepakankamai žmonių.',
    'peerreview.selectAnswerMessage': 'Metas atlikti tarpusavio vertinimą! Toliau rasite keletą kitų bendramokslių pateiktų atsakymų. Pasirinkite ir įvertinkite vieną iš jų. Pakartokite tai bent tris kartus.',
    'peerreview.minimumPeerReviewWarning': 'Pratimas bus laikomas atliktu tik kai pateiksite bent tris tarpusavio įvertinimus.',
    'peerreview.select': 'Pasirinkti',
    'peerreview.reportSpam': 'Pranešti apie brukalą',
    'peerreview.feedback': 'Jūsų atsakymas įvertintas ir priimtas. Štai ką apie jį mano jūsų bendramoksliai:',
    // exercise17
    'quiz.exercise17.xLabel': 'Mokymosi metai',
    'quiz.exercise17.yLabel': 'Tikėtina gyvenimo trukmė metais',
    'quiz.exercise17.textPart1': 'Išnagrinėkime, kaip mokymosi metų (įskaitant viską nuo ikimokyklinio ugdymo iki universiteto) skaičius siejasi su tikėtina gyvenimo trukme. Toliau pateiktame paveikslėlyje taškais išreikšti trijų skirtingų šalių duomenys.',
    'quiz.exercise17.textPart2': 'Vienoje šalyje vidutinė mokymosi trukmė yra 10 metų, o tikėtina gyvenimo trukmė – 57 metai, kitoje šalyje vidutinė mokymosi trukmė – 13 metų, o tikėtina gyvenimo trukmė – 53 metai, trečioje šalyje vidutinė mokymosi trukmė – 20 metų, o tikėtina gyvenimo trukmė – 80 metų.',
    'quiz.exercise17.textPart3': 'Traukdamas pateiktą ištisinę liniją už galų galite ją perkelti taip, kad ji rodytų duomenų taškus siejančią tendenciją. Atkreipkite dėmesį į tai, kad linija su duomenų taškais tobulai nesutaps, bet taip ir turi būti – kai kurie duomenų taškai bus virš linijos, kai kurie – po ja. Svarbiausia, kad linija žymėtų bendrą tendenciją.',
    'quiz.exercise17.textPart4': 'Perkėlę liniją į reikiamą padėtį, pagal ją galite prognozuoti tikėtiną gyvenimo trukmę.',
    'quiz.exercise17.textPart5': 'Remdamiesi duomenimis atsakykite, kokia yra tikėtina 15 metų besimokiusių žmonių gyvenimo trukmė? Svarbu. Atsiminkite, kad nors koreguodami linijos padėtį galite gauti konkrečią metų dalies tikslumo prognozę, ji nebūtinai bus patikima. Pateikdami atsakymą atsižvelkite į tai, kad duomenų kiekis nedidelis.',
    // exercise18
    'quiz.exercise18.xLabel': 'Mokymosi metai',
    'quiz.exercise18.yLabel': 'Tikėtina gyvenimo trukmė metais',
    'quiz.exercise18.textPart1': 'Ankstesniame pratime naudojome tik trijų šalių duomenis. Visą duomenų rinkinį sudaro 14 skirtingų šalių duomenys, pateikti tolesnėje diagramoje.',
    'quiz.exercise18.textPart2': 'Ar remiantis šiais duomenimis jūsų prognozė dėl tikėtinos 15 metų besimokiusių žmonių gyvenimo trukmės pasikeistų? Jei taip, kodėl?',
    'quiz.exercise18.textPart3': 'Kuris iš toliau pateiktų variantų labiausiai atitiktų jūsų apytikriai nustatytą tikėtiną 15 metų besimokiusių žmonių gyvenimo trukmę? Pasirinkite tiksliausią variantą, kurį laikote pagrįstu pritaikęs tiesės modelį pateiktiems duomenims.',
    // exercise19
    'quiz.exercise19.xLabel': 'Mokymosi trukmė valandomis',
    'quiz.exercise19.yLabel': 'Tikimybė išlaikyti egzaminą',
    'quiz.exercise19.textPart1': 'Kiekvienas taškas diagramoje žymi vieną studentą. Diagramos apačioje esanti skalė skirta parodyti, kiek valandų studentas ruošėsi egzaminui. Egzaminą išlaikę studentai pavaizduoti taškais diagramos viršuje, o jo neišlaikiusieji – apačioje. Kairėje esančią skalę naudosime egzamino išlaikymo tikimybei prognozuoti, o šią tikimybę nustatysime taikydami logistinės regresijos modelį pagal tolesnius paaiškinimus.',
    'quiz.exercise19.textPart2': 'Iš šios diagramos apytikriai matyti, kad ilgiau besimokiusių studentų tikimybė išlaikyti egzaminą buvo didesnė. Ypač intuityvūs kraštutiniai atvejai: pasimokius mažiau nei valandą išlaikyti egzaminą labai sunku, o įdėję daug darbo dauguma studentų sėkmingai jį išlaikys. Tačiau ką galima pasakyti apie studentus, kurių mokymosi laikas patenka į intervalą tarp šių kraštutinumų? Kokia tikimybė išlaikyti egzaminą, jeigu mokėtės 6 valandas?',
    'quiz.exercise19.textPart3': 'Kiekybiškai išreikšti egzamino išlaikymo tikimybę galime taikydami logistinės regresijos metodą. Diagramoje pavaizduota kreivė gali būti laikoma egzamino išlaikymo tikimybės rodiniu. Pavyzdžiui, pasimokius penkias valandas tikimybė išlaikyti egzaminą yra šiek tiek didesnė nei 20 %. Išsamiau neaiškinsime, kaip ši kreivė sudaroma, tačiau tai panašu į svorinių daugiklių nustatymą taikant tiesinės regresijos metodą.',
    'quiz.exercise19.textPart4': 'Remiantis pateikta diagrama, kiek apytiksliai valandų turėtumėte mokytis, kad tikimybė išlaikyti universiteto egzaminą būtų 80 %?',
    // exercise22
    'quiz.exercise22.sigmoidXLabel': 'Pradiniai duomenys',
    'quiz.exercise22.sigmoidYLabel': 'Sigmoidinės funkcijos rezultatas',
    'quiz.exercise22.stepXLabel': 'Pradiniai duomenys',
    'quiz.exercise22.stepYLabel': 'Slenkstinės funkcijos rezultatas',
    'quiz.exercise22.identityXLabel': 'Pradiniai duomenys',
    'quiz.exercise22.identityYLabel': 'Tiesinės funkcijos rezultatas',
    'quiz.exercise22.textPart1': 'Toliau pateikiamos trijų skirtingų aktyvacijos funkcijų, pasižyminčių skirtingomis savybėmis, diagramos. Pirmiausia parodyta sigmoidinė funkcija, tada – slenkstinė funkcija ir galiausiai – tiesinė funkcija.',
    // exercise23
    'quiz.exercise23.happyFaces': 'tinkamai kategorijai priskirtų linksmų veidų',
    'quiz.exercise23.sadFaces': 'tinkamai kategorijai priskirtų liūdnų veidų',
    // certificate availability
    'certificate.failedToCheck': 'Nepavyko patikrinti galimybės gauti pažymėjimą.',
    'certificate.failedToGenerate': 'Nepavyko sugeneruoti pažymėjimo.',
    'certificate.notYetAvailable': 'Savo pažymėjimą čia galėsite gauti po oficialaus mūsų patvirtinimo. Tai gali šiek tiek užtrukti. Dėkojame už kantrybę!',
    'certificate.updateName': 'Jau galite gauti savo pažymėjimą. Atnaujinkite savo vardą ir pavardę, kad jie būtų nurodyti jūsų sertifikate, naudodami formą, kurią rasite savo profilio puslapyje.',
    'certificate.generate': 'Sugeneruokite savo pažymėjimą.',
    'certificate.generateTextPart1': 'Asmuo, kurio vardu bus sugeneruotas pažymėjimas:',
    'certificate.generateTextPart2': 'Prieš generuodamas pažymėjimą, įsitikinkite, kad ši informacija teisinga, nes vėliau vardo ir pavardės keisti nebegalėsite. Vardą ir pavardę galite pakeisti toliau pateiktoje formoje.',
    'certificate.generateButton': 'Generuoti pažymėjimą',
    'certificate.downloadButton': 'Atsisiųsti pažymėjimą',
    'certificate.checkingAvailability': 'Tikrinama galimybė gauti pažymėjimą...',
    // Visualizations
    'visualizations.logisticRegressionChart.classifiedCorrectly': 'Priskirta tinkamai kategorijai',
    'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Nepriskirta tinkamai kategorijai',
    // section blocks
    'section.block.note': 'Pastaba',
    'section.block.example': 'Pavyzdys',
    'section.block.keyTerminology': 'Svarbiausi terminai',
    'section.partSummary.spectrumTextPart1': 'Prisijunkite prie ',
    'section.partSummary.spectrum': 'Elements of AI bendruomenės',
    'section.partSummary.spectrumTextPart2': ' – ten galėsite aptarti šį skyrių ir užduoti su juo susijusių klausimų.',
    'section.partSummary.feedbackTitle': 'Pateikite atsiliepimą apie šią dalį.',
    'section.partSummary.feedbackPlaceholder': 'Čia pateikite savo atsiliepimą...',
    'section.partSummary.feedbackSuccess': 'Dėkojame už pateiktą atsiliepimą!',
    // 404
    'globals.404.title': '404 – puslapis nerastas.',
    'globals.404.intro': 'Pasukote niekur nevedančiu keliu... Užjaučiame.',
    'globals.loading': 'Įkeliama...',
    // footer / menu
    'globals.menu': 'Meniu',
    'globals.menu.about': 'Apie kursą',
    'globals.menu.faq': 'DUK',
    'globals.menu.privacyPolicy': 'Privatumo politika',
    'globals.menu.myProfile': 'Mano profilis',
    'globals.menu.termsofService': 'Paslaugų teikimo sąlygos',
    // Dashboard/Index
    'dashboard.title': 'Sveikiname pradėjus kursą!',
    'dashboard.congratulations.title': 'Sveikinimai!',
    'dashboard.congratulations.message': 'Jūs baigėte kursą „Dirbtinio intelekto pradmenys“.',
    'dashboard.congratulations.downloadCertificate': 'Atsisiųsti pažymėjimą',
    'dashboard.notLoggedIn.title': 'Sveiki!',
    'dashboard.notLoggedIn.message': 'Norėdamas gauti visateisę prieigą prie kurso, užsiregistruokite arba prisijunkite. Tai nemokama!',
    // Account
    'account.certificate': 'Pažymėjimas',
    'account.credits': 'ECTS kreditai',
    'account.myProfile': 'Mano profilis',
    'account.changePassword': 'Keisti slaptažodį',
    'account.changeLanguage': 'Keisti kalbą',
    'account.profileInformation': 'Profilio informacija',
    'account.goToCourse': 'Eik į kursą',
    'account.buildingAINew': 'Naujas kursas anglų kalba',
    'account.accept': 'Piekrītu',
    'account.decline': 'Nepiekrītu',
    'account.courses': 'Kursai',
    // Sign Up page
    'signUp.description': 'Jei norite susikurti naują mooc.fi paskyrą, užpildykite toliau pateiktą formą.',
    'signUp.email': 'Jūsų e. pašto adresas',
    'signUp.firstName': 'Jūsų vardas',
    'signUp.lastName': 'Jūsų pavardė',
    'signUp.postalCode': 'Pašto kodas',
    'signUp.country': 'Jūsų gyvenamoji šalis:',
    'signUp.Password': 'Slaptažodis',
    'signUp.confirmPassword': 'Patvirtinti slaptažodį',
    'signUp.howToStudyTitle': 'Kaip norite mokytis?',
    'signUp.6weeksTitle': '6 savaičių kursas',
    'signUp.6weeksSubTitle': 'Rekomenduojamas variantas (apie 5 val. per savaitę)',
    'signUp.6weeksText': 'Nustatytas terminas – patikima priemonė, padedanti baigti kursą.',
    'signUp.selfPacedTitle': 'Mokymasis savo tempu',
    'signUp.selfPacedText': 'Galite mokytis savo tempu.',
    'signUp.ectsTitleSwe': '',
    'signUp.ectsTitle': 'Ar norite pateikti prašymą dėl ECTS kreditų?',
    'signUp.ectsStudent': 'Esu Helsinkio universiteto studentas. Jei pažymėsite šį langelį ir įrašysite savo studento numerį, jums bus automatiškai suteikti ECTS kreditai.',
    'signUp.ectsStudentSwe': '',
    'signup.ectsStudentSweLink': '',
    'signUp.ectsOpenUni': 'Pažymėkite šį langelį, jei norite e. paštu gauti nurodymus, kaip pateikti prašymą dėl ECTS kreditų.',
    'signUp.studentNumber': 'Helsinkio universiteto studento numeris (jei esate studentas)',
    'signUp.privacyOptIn': 'Leidžiu mokslinių tyrimų tikslais naudoti surinktus duomenis apie mano mokymosi pobūdį. Šie duomenys apima informaciją, susijusią su naudojimusi medžiaga ir kurso pratimų sprendimu. Iš publikuotos informacijos neįmanoma nustatyti konkrečių asmenų tapatybės.',
    'signUp.marketingOptIn': 'Sutinku gauti pranešimus apie kurso pateikimą kitomis kalbomis ir informaciją apie naujus kursus. Sutinku pasidalinti savo kontaktine informacija, kad galėčiau gauti pritaikytus pranešimus trečiųjų šalių platformose. Skaitykite daugiau mūsų privatumo politika.',
    'signUp.privacyLink': 'Susipažinkite su privatumo politika.',
    'signUp.error': 'Formoje yra klaidų. Patikrinkite formą.',
    'signUp.acceptPolicyFirst': 'Sutinku su privatumo politika ir paslaugų teikimo sąlygomis.',
    // Sign in page
    'signIn.title': 'Prisijungti',
    'signIn.intro': 'Jei norite prisijungti, įrašykite į tolesnę formą savo mooc.fi paskyros duomenis.',
    'signIn.email': 'Jūsų e. pašto adresas',
    'signIn.password': 'Jūsų slaptažodis',
    'signIn.noAccount': 'Neturite paskyros?',
    'signIn.signUpNow': 'Užsiregistruokite',
    // Forgot password
    'pwForget.title': 'Pamirštas slaptažodis',
    'pwForget.description': 'Įrašykite savo mooc.fi paskyros e. pašto adresą – juo atsiųsime jums slaptažodžio keitimo nuorodą.',
    'pwForget.sendResetLink': 'Siųsti slaptažodžio keitimo nuorodą',
    'pwForget.completedDescription': 'Netrukus iš mooc.fi turėtumėte gauti e. laišką su slaptažodžio keitimo nuoroda. Jei jo negaunate, patikrinkite brukalo aplanką.',
    'pwForget.problemDescription': 'Jei kiltų problemų, kreipkitės adresu mooc@cs.helsinki.fi.',
    'pwForget.resetEmailSent': 'Slaptažodžio keitimo e. laiškas išsiųstas.',
    'pwForget.emailPlaceholder': 'E. pašto adresas',
    // password change
    'pwChange.authError': 'Nepavyko nustatyti tapatumo. Pabandykite prisijungti dar kartą.',
    'pwChange.unexpError': 'Nepavyko atnaujinti slaptažodžio dėl netikėtos klaidos.',
    'pwChange.required': 'Būtina įrašyti.',
    'pwChange.pwMustMatch': 'Slaptažodžiai turi sutapti.',
    'pwChange.currentPassword': 'Dabartinis slaptažodis',
    'pwChange.newPassword': 'Naujas slaptažodis',
    'pwChange.confirmPassword': 'Patvirtinti naują slaptažodį',
    'pwChange.changingPassword': 'Keičiamas slaptažodis...',
    'pwChange.changePassword': 'Keisti slaptažodį',
    'pwChange.passwordChanged': 'Slaptažodis pakeistas.',
    // user details
    'userDetails.authError': 'Nepavyko nustatyti tapatumo. Pabandykite prisijungti dar kartą.',
    'userDetails.unexpError': 'Nepavyko atnaujinti profilio dėl netikėtos klaidos.',
    'userDetails.required': 'Būtina įrašyti.',
    'userDetails.invalidEmail': 'Netinkamas e. pašto adresas.',
    'userDetails.email': 'E. paštas',
    'userDetails.emailAddress': 'E. pašto adresas',
    'userDetails.firstName': 'Vardas',
    'userDetails.lastName': 'Pavardė',
    'userDetails.language': 'Kalba',
    'userDetails.updatingProfile': 'Atnaujinamas profilis...',
    'userDetails.updateProfile': 'Atnaujinti profilį',
    'userDetails.profileUpdated': 'Profilis atnaujintas.',
    // languages
    'languages.sk.long': 'Slovakų',
    'languages.en.long': 'Anglų',
    'languages.fi.long': 'Suomių',
    'languages.se.long': 'Švedų',
    'languages.de.long': 'Vokiečių (Vokietija)',
    'languages.ee.long': 'Estų',
    'languages.lv.long': 'Latvių',
    'languages.no.long': 'Norvegų',
    'languages.lt.long': 'Lietuvių',
    'languages.fr.long': 'Prancūzų (Prancūzija)',
    'languages.fr-be.long': 'Prancūzų (Belgija)',
    'languages.ga.long': 'Airių',
    'languages.nl-be.long': 'Olandų (Belgija)',
    'languages.it.long': 'Italų',
    'languages.mt.long': 'Maltiečių',
    'languages.pl.long': 'Lenkų',
    'languages.en-ie.long': 'Anglų (Airija)',
    'languages.da.long': 'Danų',
    'languages.ro.long': 'Rumunų',
    'languages.hr.long': 'Kroatų',
    'languages.is.long': 'Islandų',
    'languages.de-at.long': 'Vokiečių (Austrija)',
    'languages.en-lu.long': 'Anglų (Liuksemburgas)',
    'languages.bg.long': 'Bulgarų',
    'languages.cs.long': 'Čekų',
    'languages.sl.long': 'Slovėnų',
    'languages.pt.long': 'Portugalų',
    'languages.nl.long': 'Olandų (Nyderlandai)',
    'languages.es.long': 'Ispanų',
    'languages.el.long': 'Graikų',
    // Email verification
    'emailVerification.completedMessage': 'Jūsų e. pašto adresas patvirtintas.',
    'emailVerification.verified': 'Patvirtinta.',
    // Exercises
    'exercise.loading': 'Įkeliamas pratimas...',
    'exercise.signUp': 'Jei norite spręsti pratimus, turite užsiregistruoti',
    'exercise.score': 'Jūsų rezultatas:',
    'exercise.wrongLocale': 'Netinkama lokalė.',
    // Part tempplate
    'partTemplate.intro1': 'Skyrius Nr. ',
    'partTemplate.intro2': ' apima toliau išvardytus skirsnius. Jei norite pradėti, spustelėkite nuorodą:',
    'partTemplate.spectrumPart1': 'Prisijunkite prie ',
    'partTemplate.spectrum': 'Elements of AI bendruomenės',
    'partTemplate.spectrumPart2': ' – ten galėsite aptarti šį skyrių ir užduoti su juo susijusių klausimų.',
    // missing info
    'missingInfo.title': 'Trūksta informacijos.',
    'missingInfo.body': 'Paprastai šiuos klausimus užduodame naudotojams registruojantis, tačiau jūs prisijungėte naudodamasis turima mooc.fi paskyra. Jei norite tęsti, užpildykite toliau pateiktą formą.',
    'missingInfo.privacyPolicy': 'Susipažinkite su privatumo politika.',
    'missingInfo.error': 'Formoje yra klaidų. Patikrinkite formą.',
    'missingInfo.privacyPolicyUpdateBody': "Atnaujinome privatumo politiką. Čia pateikiama pakeitimų santrauka. Mes reklamuojame savo kursus potencialiems naujiems studentams, formuodami auditorijas rinkodaros platformose. Tai gali apimti el. pašto adresų bendrinimą su šiomis platformomis. Tuo pačiu tikslu anonimizuotais veiklos duomenimis bus dalijamasi su rinkodaros tinklais. Galite užkirsti kelią dalijimuisi el. pašto informaciją savo kurso profilyje atšaukdami savo  sutikimą. Norėdami neleisti dalytis duomenimis, galite pakeisti slapukų nustatymus kurso svetainėje. Norint toliau naudotis paslauga, būtina sutikti su šiomis sąlygomis.",
    'missingInfo.privacyPolicyUpdateLink': 'Susipažinkite su privatumo politika čia.',
    // routes
    'routes.LANDING': '/lt/',
    'routes.SIGN_UP': '/lt/signup',
    'routes.SIGN_IN': '/lt/signin',
    'routes.MISSING_INFO': '/lt/missinginfo',
    'routes.PASSWORD_FORGET': '/lt/pw-forget',
    'routes.HOME': '/lt/home',
    'routes.ACCOUNT': '/lt/account',
    'routes.PRIVACY': 'https://www.elementsofai.lt/faq/privatumo-politika',
    'routes.FAQ': 'https://www.elementsofai.lt/faq',
    'routes.ABOUT': 'https://www.elementsofai.lt/',
    'routes.SPECTRUM': 'https://community.elementsofai.com/c/lt-lietuviu-bendruomene',
    'routes.ects': 'https://mooc.fi/lt/register-completion/elements-of-ai',
    'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
    'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
    'routes.ects.faq': 'https://www.elementsofai.com/faq/how-can-i-get-2-ects-credits',
    'routes.ACCEPT_POLICY': '/lt/accept-policy',

    // MarkdownComponents
    'markdowncomponents.linearRegressionStatic.xLabel': 'Kavos puodelių skaičius per dieną',
    'markdowncomponents.linearRegressionStatic.yLabel': 'Parašytų kodo eilučių skaičius',
    // meta
    'meta.title': 'Nemokamas internetinis kursas',
    'meta.titleTemplate': '%s – Dirbtinio intelekto pradmenys',
    'meta.description': 'Sužinokite daugiau apie Helsinkio universiteto ir įmonės „Reaktor“ dirbtinio intelekto kursą studentams ir verslo specialistams – jam nereikia programavimo ar matematinių įgūdžių.',
    'meta.sitename': 'Dirbtinio intelekto pradmenys',
    'meta.title.og': 'A free online introduction to artificial intelligence for non-experts',
    // ECTS
    'ects.text': 'Baigęs kursą čia rasite nurodymus, kaip pateikti prašymą dėl ECTS kreditų.',
    'ects.textCompleted': 'Jei turite Suomijos socialinės apsaugos numerį, galite pateikti prašymą dėl kreditų spustelėjęs ',
    'ects.link': 'čia.',
    'ects.info': 'Would you like to apply for ECTS credits?',
    'ects.readMore': 'Read more',
    // Countries
    'countryselect.select': "Prašome pasirinkti",
    'Sweden': "Švedija",
    'Estonia': "Estija",
    'Finland': "Suomija",
    'Germany': "Vokietija",
    "Countries": {
        "Afganistan": "Afganistanas",
        "Åland Islands": "Alandų salos",
        "Albania": "Albanija",
        "Algeria": "Alžyras",
        "American Samoa": "Amerikos Samoa",
        "Andorra": "Andora",
        "Angola": "Angoloje",
        "Anguilla": "Angilija",
        "Antarctica": "Antarktida",
        "Antigua and Barbuda": "Antigva ir Barbuda",
        "Argentina": "Argentina",
        "Armenia": "Armėnija",
        "Aruba": "Aruba",
        "Australia": "Australija",
        "Austria": "Austrijoje",
        "Azerbaijan": "Azerbaidžanas",
        "Bahamas": "Bahamos",
        "Bahrain": "Bahreinas",
        "Bangladesh": "Bangladešas",
        "Barbados": "Barbadosas",
        "Belarus": "Baltarusija",
        "Belgium": "Belgija",
        "Belize": "Belizas",
        "Benin": "Beninas",
        "Bermuda": "Bermudai",
        "Bhutan": "Butanas",
        "Bolivia": "Bolivija",
        "Bosnia and Herzegovina": "Bosnija ir Hercegovina",
        "Botswana": "Botsvana",
        "Bouvet Island": "Bouvet sala",
        "Brazil": "Brazilija",
        "British Indian Ocean Territory": "Britanijos Indijos vandenyno teritorija",
        "Brunei Darussalam": "Brunėjaus Darusalamas",
        "Bulgaria": "Bulgarija",
        "Burkina Faso": "Burkina Fasas",
        "Burundi": "Burundis",
        "Cambodia": "Kambodža",
        "Cameroon": "Kamerūnas",
        "Canada": "Kanada",
        "Cape Verde": "Žaliasis Kyšulys",
        "Cayman Islands": "Kaimanų salos",
        "Central African Republic": "Centrine Afrikos Respublika",
        "Chad": "Čadas",
        "Chile": "Čilė",
        "China": "Kinija",
        "Christmas Island": "Kalėdų sala",
        "Cocos (Keeling) Islands": "Kokosų (Kylingo) salos",
        "Colombia": "Kolumbija",
        "Comoros": "Komorai",
        "Congo": "Kongas",
        "Congo, The Democratic Republic of The": "Kongo Demokratinė Respublika",
        "Cook Islands": "Kuko salos",
        "Costa Rica": "Kosta Rika",
        "Cote D'ivoire": "Dramblio Kaulo Krantas",
        "Croatia": "Kroatija",
        "Cuba": "Kuba",
        "Cyprus": "Kipras",
        "Czech Republic": "Čekijos Respublika",
        "Denmark": "Danija",
        "Djibouti": "Džibutis",
        "Dominica": "Dominika",
        "Dominican Republic": "Dominikos Respublika",
        "Ecuador": "Ekvadoras",
        "Egypt": "Egiptas",
        "El Salvador": "Salvadoras",
        "Equatorial Guinea": "Pusiaujo Gvinėja",
        "Eritrea": "Eritrėja",
        "Estonia": "Estija",
        "Ethiopia": "Etiopija",
        "Falkland Islands (Malvinas)": "Folklando salos (Malvinai)",
        "Faroe Islands": "Farerų salos",
        "Fiji": "Fidžis",
        "Finland": "Suomija",
        "France": "Prancūzijoje",
        "French Guiana": "Prancūzijos Gviana",
        "French Polynesia": "Prancūzų Polinezija",
        "French Southern Territories": "Prancūzijos pietinės teritorijos",
        "Gabon": "Gabonas",
        "Gambia": "Gambija",
        "Georgia": "Gruzijoje",
        "Germany": "Vokietija",
        "Ghana": "Gana",
        "Gibraltar": "Gibraltaras",
        "Greece": "Graikija",
        "Greenland": "Grenlandija",
        "Grenada": "Grenada",
        "Guadeloupe": "Gvadelupa",
        "Guam": "Guamas",
        "Guatemala": "Gvatemala",
        "Guernsey": "Gernsis",
        "Guinea": "Gvinėjos",
        "Guinea-Bissau": "Bisau Gvinėja",
        "Guyana": "Gajana",
        "Haiti": "Haitis",
        "Heard Island and Mcdonald Islands": "Girdi sala ir Mcdonaldo salos",
        "Holy See (Vatican City State)": "Šventasis Sostas (Vatikano miesto valstybė)",
        "Honduras": "Hondūras",
        "Hong Kong": "Honkongas",
        "Hungary": "Vengrija",
        "Iceland": "Islandija",
        "India": "Indija",
        "Indonesia": "Indonezija",
        "Iran, Islamic Republic of": "Iranas, Islamo Respublika",
        "Iraq": "Irakas",
        "Ireland": "Airija",
        "Isle of Man": "Meno sala",
        "Israel": "Izraelis",
        "Italy": "Italijoje",
        "Jamaica": "Jamaika",
        "Japan": "Japonija",
        "Jersey": "Džersis",
        "Jordan": "Jordanija",
        "Kazakhstan": "Kazachstanas",
        "Kenya": "Kenija",
        "Kiribati": "Kiribatis",
        "Korea, Democratic People's Republic of": "Korėjos Liaudies Demokratinė Respublika",
        "Korea, Republic of": "Korėja, Respublika",
        "Kosovo": "Kosovas",
        "Kuwait": "Kuveitas",
        "Kyrgyzstan": "Kirgizija",
        "Lao People's Democratic Republic": "Laoso liaudies demokratinė respublika",
        "Latvia": "Latvijoje",
        "Lebanon": "Libanas",
        "Lesotho": "Lesotas",
        "Liberia": "Liberija",
        "Libyan Arab Jamahiriya": "Libijos arabų Jamahiriya",
        "Liechtenstein": "Lichtenšteinas",
        "Lithuania": "Lietuva",
        "Luxembourg": "Liuksemburgas",
        "Macao": "Makao",
        "Macedonia, The Former Yugoslav Republic of": "Makedonija, Buvusioji Jugoslavijos Respublika",
        "Madagascar": "Madagaskaras",
        "Malawi": "Malavis",
        "Malaysia": "Malaizija",
        "Maldives": "Maldyvai",
        "Mali": "Malis",
        "Malta": "Malta",
        "Marshall Islands": "Maršalo salos",
        "Martinique": "Martinika",
        "Mauritania": "Mauritanija",
        "Mauritius": "Mauricijus",
        "Mayotte": "Majotas",
        "Mexico": "Meksika",
        "Micronesia, Federated States of": "Mikronezijos Federacinės Valstijos",
        "Moldova, Republic of": "Moldovos Respublika",
        "Monaco": "Monakas",
        "Mongolia": "Mongolija",
        "Montenegro": "Juodkalnija",
        "Montserrat": "Montserratas",
        "Marocco": "Marokas",
        "Mozambique": "Mozambikas",
        "Myanmar": "Mianmaras",
        "Namibia": "Namibija",
        "Nauru": "Nauru",
        "Nepal": "Nepalas",
        "Netherlands": "Nyderlandai",
        "Netherlands Antilles": "Nyderlandų Antilai",
        "New Caledonia": "Naujoji Kaledonija",
        "New Zealand": "Naujoji Zelandija",
        "Nicaragua": "Nikaragva",
        "Niger": "Nigeris",
        "Nigeria": "Nigerija",
        "Niue": "Niue",
        "Norfolk Island": "Norfolko sala",
        "Northern Mariana Islands": "Šiaurinės Marianos salos",
        "Norway": "Norvegija",
        "Oman": "Omanas",
        "Pakistan": "Pakistanas",
        "Palau": "Palau",
        "Palestinian Territory, Occupied": "Palestinos teritorija, okupuota",
        "Panama": "Panama",
        "Papua New Guinea": "Papua Naujoji Gvinėja",
        "Paraguay": "Paragvajus",
        "Peru": "Peru",
        "Philippines": "Filipinai",
        "Pitcairn": "Pitkernas",
        "Poland": "Lenkija",
        "Portugal": "Portugalija",
        "Puerto Rico": "Puerto Rikas",
        "Qatar": "Kataras",
        "Reunion": "Reunjonas",
        "Romania": "Rumunija",
        "Russian Federation": "Rusijos Federacija",
        "Rwanda": "Ruanda",
        "Saint Helena": "Šventoji Helena",
        "Saint Kitts and Nevis": "Sent Kitsas ir Nevis",
        "Saint Lucia": "Sent Lusija",
        "Saint Pierre and Miquelon": "Sent Pjeras ir Mikelonas",
        "Saint Vincent and The Grenadines": "Sent Vinsentas ir Grenadinai",
        "Samoa": "Samoa",
        "San Marino": "San Marinas",
        "Sao Tome and Principe": "San Tomė ir Principe",
        "Saudi Arabia": "Saudo Arabija",
        "Senegal": "Senegalas",
        "Serbia": "Serbija",
        "Seychelles": "Seišeliai",
        "Sierra Leone": "Siera Leonė",
        "Singapore": "Singapūras",
        "Slovakia": "Slovakija",
        "Slovenia": "Slovėnija",
        "Solomon Islands": "Saliamono salos",
        "Somalia": "Somalis",
        "South Africa": "pietų Afrika",
        "South Georgia and The South Sandwich Islands": "Pietų Džordžija ir Pietų Sandvičo salos",
        "Spain": "Ispanija",
        "Sri Lanka": "Šri Lanka",
        "Sudan": "Sudanas",
        "Suriname": "Surinamas",
        "Svalbard and Jan Mayen": "Svalbardas ir Janas Mayenas",
        "Swaziland": "Svazilandas",
        "Sweden": "Švedija",
        "Swizerland": "Swizerland",
        "Syrian Arab Republic": "Sirijos Arabų Respublika",
        "Taiwan": "Taivanas",
        "Tajikistan": "Tadžikistanas",
        "Tanzania, United Republic of": "Tanzanija, Jungtinė Karalystė",
        "Thailand": "Tailandas",
        "Timor-leste": "Rytų Timoras",
        "Togo": "Togo",
        "Tokelau": "Tokelau",
        "Tonga": "Tonga",
        "Trinidad and Tobago": "Trinidadas ir Tobagas",
        "Tunisia": "Tunisas",
        "Turkey": "Turkija",
        "Turkmenistan": "Turkmėnistanas",
        "Turks and Caicos Islands": "Terkso ir Kaikoso salos",
        "Tuvalu": "Tuvalu",
        "Uganda": "Uganda",
        "Ukraine": "Ukraina",
        "United Arab Emirates": "Jungtiniai Arabų Emyratai",
        "United Kingdom": "Jungtinė Karalystė",
        "United States": "Jungtinės Valstijos",
        "United States Minor Outlying Islands": "Mažosios JAV salos",
        "Uruguay": "Urugvajus",
        "Uzbekistan": "Uzbekistanas",
        "Vanuatu": "Vanuatu",
        "Venezuela": "Venesuela",
        "Viet Nam": "Vietnamas",
        "Virgin Islands, British": "Britų Mergelių salos",
        "Virgin Islands, U.S.": "Mergelių salos, JAV",
        "Wallis and Futuna": "Volis ir Futuna",
        "Western Sahara": "Vakarų Sachara",
        "Yemen": "Jemenas",
        "Zambia": "Zambija",
        "Zimbabwe": "Zimbabvė"

    },
    //Alt texts
    'alt.example-1': 'Du savivaldžiai automobiliai',
    'alt.example-2': 'Rankoje laikomas mobilusis telefonas',
    'alt.example-3': "Moters veidas",
    'alt.defining-ai': 'Su DI susijusių žodžių kratinys',
    'alt.turing-test': 'Vyras, kompiuteriu bendraujantis su robotu ir su moterimi',
    'alt.chinese-room': 'Kinų kambario eksperimentas',
    'alt.chicken-crossing': 'Valtyje sėdintis robotas, žiūrintis į viščiuką, lapę ir viščiukų lesalo maišą',
    'alt.a-to-b': 'Rankoje laikomas telefonas su įjungta navigacijos programėle',
    'alt.tic-tac-toe': 'Du vaikai, žaidžiantys „Kryžiukus ir nuliukus“',
    'alt.self-driving-car': 'Savivaldis automobilis prie ženklo „Stop“',
    'alt.spam-or-ham': 'Paprastas e. laiškas ir brukalas',
    'alt.mnist': 'MNIST duomenų rinkinys',
    'alt.nearest-neighbor': 'Du apsiperkantys žmonės',
    'alt.nearest-neighbor-graph': 'Artimiausio kaimyno klasifikatorius',
    'alt.recommendation': 'Vyras ir moteris, besiklausantys muzikos telefonu',
    'alt.price-real-estate': 'Trys skirtingi namai',
    'alt.supervised-learning': 'Žmogus, mokantis robotą',
    'alt.dice': 'kauliukai',
    'alt.chess': 'Šachmatų lenta',
    'alt.poker': 'Rankoje laikomos lošimo kortos',
    'alt.eyes': 'Trys rudakiai ir vienas mėlynakis',
    'alt.search-1': 'Vienas į tikslą vedantis kelias',
    'alt.search-2': 'Keturi skirtingi į tą patį tikslą vedantys keliai',
    'alt.chicken-crossing-1': 'Viščiuko perkėlimas: pradinė būsena',
    'alt.chicken-crossing-2': 'Viščiuko perkėlimas: pirmasis perkėlimas',
    'alt.chicken-crossing-3': 'Viščiuko perkėlimas: visi perkėlimai',
    'alt.chicken-crossing-4': 'Geriausias viščiuko perkėlimo kelias',
    'alt.chicken-crossing-5': 'Viščiuko perkėlimas: pradinė būsena',
    'alt.implication-1': 'Vyras ir moteris',
    'alt.implication-2': 'Akys',
    'alt.implication-3': 'Stebėjimo kamera',
    'alt.implication-4': 'Knyginiu kompiuteriu dirbantis robotas',
    'alt.oddchange': 'Saulė ir debesys',
    'alt.brain': 'Smegenų paveikslas',
    'alt.carstop': 'Savivaldis automobilis prie ženklo „Stop“',
    'alt.gan': 'Robotas, laikantis katės ir ženklo „Stop“ paveikslus ',
    'alt.hammock': 'Robotas, vėduokle vėduojantis hamake gulintį žmogų',
    'alt.game-tree-1': '1 žaidimo medis',
    'alt.game-tree-2': '2 žaidimo medis',
    'alt.game-tree-3': '3 žaidimo medis',
    'alt.game-tree-4': '4 žaidimo medis',
    'alt.bayes-rule-1': 'Paveikslėlis, kuriame parodyti neigiami ir klaidingai teigiami vėžio tyrimo rezultatai',
    'alt.bayes-rule-2': 'Paveikslėlis, kuriame parodyti neigiami ir klaidingai teigiami vėžio tyrimo rezultatai',
    'alt.defineai': 'Su DI susijusių žodžių kratinys',
    'alt.winter': 'Sniege miegantis robotas',
    'alt.terminator': 'Baisus robotas',

}
