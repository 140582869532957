import {
  fetchQuiz,
  fetchStrippedQuizzes,
  fetchGivenPeerReviews
} from "../../services/quiznator";
import { receiveGivenPeerReview } from "../peerReview/actions";

import _ from "lodash";

import { ESSAY } from "../../constants/quiztypes";

export const SET_QUIZZES_INIT_STATE = "SET_QUIZZES_INIT_STATE";

export const CHANGE_QUIZ_STATE = "CHANGE_QUIZ_STATE";

export const REQUEST_QUIZ = "REQUEST_QUIZ";
export const RECEIVE_QUIZ = "RECEIVE_QUIZ";

export const FETCH_QUIZ = "FETCH_QUIZ";
export const FETCH_QUIZ_SUCCESS = "FETCH_QUIZ_SUCCESS";
export const FETCH_QUIZ_FAILURE = "FETCH_QUIZ_FAILURE";

export const FETCH_STRIPPED_QUIZZES_SUCCESS = "FETCH_STRIPPED_QUIZZES_SUCCESS";
export const FETCH_STRIPPED_QUIZZES_FAILURE = "FETCH_STRIPPED_QUIZZES_FAILURE";

export const REQUEST_QUIZZES = "REQUEST_QUIZZES";
export const RECEIVE_QUIZZES = "RECEIVE_QUIZZES";

export const INIT_QUIZZES = "INIT_QUIZZES";
export const INIT_QUIZZES_DONE = "INIT_QUIZZES_DONE";
export const INIT_QUIZZES_ERROR = "INIT_QUIZZES_ERROR";

export function setQuizzesInitState() {
  return {
    type: SET_QUIZZES_INIT_STATE
  };
}

export function changeQuizState(quizId, data) {
  return {
    type: CHANGE_QUIZ_STATE,
    quizId,
    data
  };
}

export function requestQuiz(quizId) {
  return {
    type: REQUEST_QUIZ,
    quizId
  };
}

export function requestQuizzes(quizIds) {
  return {
    type: REQUEST_QUIZZES,
    quizIds
  };
}

export function receiveQuizzes(quizIds, data) {
  return {
    type: RECEIVE_QUIZZES,
    quizIds,
    data,
    receivedAt: Date.now()
  };
}

export function initQuizzesStripped(quizIds) {
  return (dispatch, getState) => {
    const { quizzes } = getState();

    if (!quizIds || (!!quizIds && quizIds.length === 0)) {
      return;
    }

    return dispatch(fetchStrippedQuizData(quizIds));
  };
}

export function receiveQuiz(quizId, data) {
  return {
    type: RECEIVE_QUIZ,
    quizId,
    data,
    receivedAt: Date.now()
  };
}

export function initQuizzesWithProgress(data) {
  return (dispatch, getState) => {
    const { quizzes, answers } = getState();

    if (!data || _.get(data, "error")) {
      return Promise.reject(data);
    }

    const allQuizzes = _.flatten([
      data.answered,
      data.notAnswered,
      data.rejected
    ]).map(entry => entry.quiz);

    let quizIds = [];
    let quizzesReceived = [];

    quizzesReceived = allQuizzes
      .map(quiz => {
        const stateQuiz = _.get(quizzes, `[${quiz._id}]`) || null;
        const answer = _.get(answers, `[${quiz._id}]`) || null;

        // TODO: check this as well
        if (
          !stateQuiz ||
          (!!stateQuiz && stateQuiz.receivedAt < Date.now() - 10000)
        ) {
          return quiz;
        }
        return null;
      })
      .filter(v => !!v);

    dispatch(
      receiveQuizzes(
        quizzesReceived.map(quiz => quiz._id),
        quizzesReceived
      )
    );

    return Promise.resolve(data);
  };
}

export function fetchQuizData(quizId) {
  return dispatch => {
    dispatch(requestQuiz(quizId));

    return fetchQuiz(quizId)
      .then(data => {
        if (!!data && data.type === ESSAY) {
          fetchGivenPeerReviews(quizId)
            .then(res => {
              //data.givenPeerReviews = res
              dispatch(receiveQuiz(quizId, data));
              if (!!res && res.length > 0) {
                res.forEach(review =>
                  dispatch(receiveGivenPeerReview(quizId, review))
                );
              }
            })
            .catch(err => {}); // TODO? error handling - this is a corner case
        } else {
          dispatch(receiveQuiz(quizId, data));
        }
      })
      .catch(err => dispatch({ type: FETCH_QUIZ_FAILURE, quizId, error: err }));
  };
}

export function fetchStrippedQuizData(quizIds) {
  return dispatch => {
    dispatch(requestQuizzes(quizIds));

    return fetchStrippedQuizzes(quizIds)
      .then(data => {
        dispatch(receiveQuizzes(quizIds, data));
      })
      .catch(err =>
        dispatch({ type: FETCH_STRIPPED_QUIZZES_FAILURE, quizIds, error: err })
      );
  };
}
