import React, { Fragment } from "react";
import Box from "./Box";
import Footer from "./Footer";
import withAuthentication from "./Session/withAuthentication";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import fi from "react-intl/locale-data/fi";
import se from "react-intl/locale-data/se";
import ee from "react-intl/locale-data/ee";
import de from "react-intl/locale-data/de";
import it from "react-intl/locale-data/it";
import fr from "react-intl/locale-data/fr";
import lv from "react-intl/locale-data/lv";
import mt from "react-intl/locale-data/mt";
import frbe from "react-intl/locale-data/fr";
import lt from "react-intl/locale-data/lt";
import no from "react-intl/locale-data/no";
import nlbe from "react-intl/locale-data/nl";
import hr from "react-intl/locale-data/hr";
import pl from "react-intl/locale-data/pl";
import enie from "react-intl/locale-data/en";
import ga from "react-intl/locale-data/ga";
import nl from "react-intl/locale-data/nl";
import sk from "react-intl/locale-data/sk";
import enlu from "react-intl/locale-data/en";
import da from "react-intl/locale-data/da";
import ro from "react-intl/locale-data/ro";
import is from "react-intl/locale-data/is";
import deat from "react-intl/locale-data/de";
import bg from "react-intl/locale-data/bg";
import cs from "react-intl/locale-data/cs";
import sl from "react-intl/locale-data/sl";
import pt from "react-intl/locale-data/pt";
import es from "react-intl/locale-data/es";
import el from "react-intl/locale-data/el"
import "intl";
import { graphql, StaticQuery } from "gatsby";
import { getCurrentLangKey } from "ptz-i18n";
import { messages } from "./Session/withAuthentication";

import enMessages from "data/messages/en";

import { ThemeProvider } from "styled-components";
import theme from "../styles/theme";
import Globals from "../styles/globals";
import Reset from "../styles/reset";
import Meta from "./Meta";

import ThemeProviderImpl from "./QuizzesTheme";
import { CourseStatusProvider, ThemeProviderContext } from "moocfi-quizzes";

import { getUser } from "../state/account";

//Reset()
// globals()
addLocaleData([
  ...en,
  ...fi,
  ...se,
  ...ee,
  ...de,
  ...fr,
  ...frbe,
  ...lv,
  ...lt,
  ...no,
  ...nlbe,
  ...it,
  ...mt,
  ...hr,
  ...pl,
  ...enie,
  ...ga,
  ...nl,
  ...sk,
  ...enlu,
  ...da,
  ...ro,
  ...is,
  ...deat,
  ...bg,
  ...cs,
  ...sl,
  ...pt,
  ...es,
  ...el
]);


  export const mapLocaleToLanguage = {
    en: "en_US",
    fi: "fi_FI",
    se: "sv_SE",
    ee: "et_EE",
    de: "de_DE",
    fr: "fr_FR",
    no: "nb_NO",
    lv: "lv_LV",
    mt: "mt_MT",
    lt: "lt_LT",
    "nl-be": "nl_BE",
    "fr-be": "fr_BE",
    it: "it_IT",
    hr: "hr_HR",
    pl: "pl_PL",
    "en-ie": "en_IE",
    ga: "ga_IE",
    nl: "nl_NL",
    sk: "sk_SK",
    "en-lu": "en_LU",
    da: "da_DK",
    ro: "ro_RO",
    is: "is_IS",
    "de-at": "de_AT",
    bg: "bg_BG",
    cs: "cs_CZ",
    sl: "sl_SI",
    pt: "pt_PT",
    es: "es_ES",
    el: "el_GR"
  };

const LayoutWrapper = ({ data, children, location }) => {
  const url = location.pathname;
  let { langs, defaultLangKey } = data.site.siteMetadata.languages;
  langs = langs.sort((o1, o2) => o2.length - o1.length);

  const courseIds = {
    en: "55dff8af-c06c-4a97-88e6-af7c04d252ca",
    fi: "08c4757a-51ec-4551-bdb6-46fba3da765b",
    se: "2ae73653-29b3-4760-a380-675aae543982",
    ee: "ed26fde3-bdea-4d49-9d06-317a34adeaff",
    de: "4126d5cb-fd94-4c66-a1bd-20c4ba0aaf38",
    fr: "02448205-5082-45f6-93f6-4f7b9fefbc13",
    lt: "3c46f0da-69db-48cc-ac99-ba002b47257f",
    lv: "4c7bd67b-0502-465b-b44b-bc25940505e3",
    hr: "55250c5a-7487-4a13-b4f8-b4a498a320a9",
    mt: "b75b8f8b-1a5a-47ae-8f68-6e28e851b348",
    no: "87a3ab4d-555f-4843-92f3-a946144b92ad",
    "nl-be": "274287af-2926-43c0-9e96-a63a80acc249",
    "fr-be": "fc1374dd-1e6b-4675-9d1e-627e3a3acf15",
    it: "9ff096e5-4a3a-4bea-bd7a-a1b7d0d9c36c",
    pl: "3f8c012e-47cb-4281-a6a3-ae02df77e809",
    "en-ie": "5c6b9569-e518-47d3-84ca-9775f172eaf2",
    ga: "062efe0c-d35a-40a7-86ea-a2ff687fca69",
    nl: "bc1e5ba9-292e-4732-ad36-bec168f19a40",
    sk: "54c517cc-85be-4070-ae67-87fe1f989d70",
    "en-lu": "19d7abb7-1903-4eb3-b5f4-6ee9e18bdeaa",
    da: "0f4831ce-90d1-4d53-954f-2bf9adadf09d",
    ro: "e483be77-3494-4c59-898c-f1768dd8ab32",
    is: "73dfcbf8-6e67-4a2f-b367-7d44a2027294",
    "de-at": "cd2a3afc-88eb-418f-9323-c1a99584f861",
    bg: "5000e0bd-329a-4a19-a1e4-7bb316c37aaa",
    cs: "b5f04d9d-ebc1-4595-b6bf-07727ca3fbd1",
    sl: "038da9e8-a3f0-4982-8fbc-17e7543f903e",
    es: "070e5d68-3f72-4255-9159-6bbcec6cef23",
    pt: "d2bf1151-a2eb-4d3f-a52e-ab4bb979a0e4",
    el: "96035def-8608-4642-bf45-fd9f346aa413"
  };


  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  console.log(`langKey: ${langKey}`);
  const i18nMessages = messages[langKey] || enMessages;
  const user = getUser();
  const languageId = mapLocaleToLanguage[langKey] || "en_US";
  return (
    <ThemeProviderContext.Provider value={ThemeProviderImpl}>
      <Fragment>
        <Meta messages={i18nMessages} />
        <ThemeProvider theme={theme}>
          <IntlProvider locale={langKey} messages={i18nMessages}>
            <Fragment>
              <CourseStatusProvider
                courseId={courseIds[langKey]}
                languageId={languageId}
                accessToken={user && user.accessToken}
              >
                <Box bg="#FCFBF8">{children}</Box>
                <Footer />
              </CourseStatusProvider>
            </Fragment>
          </IntlProvider>
          <Reset />
          <Globals />
        </ThemeProvider>
      </Fragment>
    </ThemeProviderContext.Provider>
  );
};

const Layout = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            languages {
              defaultLangKey
              langs
            }
          }
        }
      }
    `}
    render={data => (
      <LayoutWrapper data={data} location={props.location} {...props} />
    )}
  />
);

export default withAuthentication(Layout);