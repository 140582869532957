// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'lv',
  'globals.signIn': 'Pierakstīties',
  'globals.logIn': 'Pierakstīties',
  'globals.signUp': 'Reģistrēties',
  'globals.signOut': 'Izrakstīties',
  'globals.logOut': 'Iziet',
  'globals.submit': 'Iesniegt',
  'globals.submitting': 'Rit iesniegšana...',
  'globals.yourEmail': 'E-pasta adrese:',
  'globals.yourPassword': 'Parole:',
  'globals.courseOverview': 'Kursa pārskats',
  'globals.forgotPassword': 'Aizmirsās parole?',
  'globals.invalidCredentials': 'Nederīgi akreditācijas dati',
  'globals.section': 'Sadaļa',
  'globals.exercises': 'Uzdevumi',
  'globals.chapter': 'Nodaļa',
  'globals.introToAI': 'Ievads MI',
  'globals.buildingAI': 'MI būvēšana',
  'globals.acceptPolicy': 'Accept policy',
  // section
  'section.footerEndOfTheChapter': 'Nodaļa ir pabeigta!',
  'section.footerEndOfTheCourseTitle': 'Kurss ir pabeigts!',
  'section.footerEndOfTheCourseText':
    'Pēc visu uzdevumu pabeigšanas un atzinumu sniegšanas līdzbiedriem jūsu atbildes izskatīs mūsu darbinieki.',
  'section.nextSection': 'Nākamā sadaļa',
  'section.nextChapter': 'Nākamā nodaļa',
  'section.start': 'Sākums',
  'section.logisticRegressionExample.slope': 'Kritums',
  'section.logisticRegressionExample.intercept': 'Krustpunkts',
  'section.footerEndOfTheCourseBAI': 'Turpiniet savu ceļojumu, lai īstenotu savu pirmo AI ideju.',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Tiek gaidīts jūsu atzinums.',
  'quiz.rejectedPeerReview': 'Recenzēšanā noraidīts.',
  'quiz.unsubmittedAnswer': 'Neiesniegta atbilde.',
  'quiz.pendingPeerReview': 'Recenzēšana nav beigusies.',
  // quiz
  'quiz.status.answered': 'Atbildēts',
  'quiz.status.rejected': 'Atbilde noraidīta, mēģiniet vēl!',
  'quiz.status.unanswered': 'Nav atbildēts.',
  'quiz.status.exercisesCompleted': 'Izpildītie uzdevumi',
  'quiz.status.correctAnswers': 'Pareizās atbildes',
  'quiz.message.rejectedInReview':
    'Jūsu iepriekšējā atbilde recenzējot tika noraidīta. Mēģiniet vēlreiz!',
  'quiz.youAnswered': 'Jūs atbildējāt',
  'quiz.yourAnswer': 'Jūsu atbilde...',
  'quiz.yourScore': 'Jūsu rezultāts:',
  'quiz.quizNotLoaded': 'Nevarēja ielādēt kontroldarbu.',
  'quiz.words': 'Vārdi:',
  'quiz.exampleAnswer': 'Atbildes piemērs:',
  // peer review
  'peerreview.question1': 'Atbilde ir par tematu.',
  'peerreview.question2': 'Atbilde ir izsmeļoša.',
  'peerreview.question3': 'Atbilde ir pamatota.',
  'peerreview.question4': 'Atbilde ir viegli uztverama.',
  'peerreview.thankYou': 'Pateicamies par līdzbiedru atbilžu recenzēšanu!',
  'peerreview.giveMoreReviews': 'Ja varat veltīt mazliet laika,',
  'peerreview.giveMoreReviewsButton': 'sniedziet vairāk atzinumu!',
  'peerreview.notEnoughAnswers':
    'Šajā vietā parasti ir līdzbiedru atzinumi, taču uz šo uzdevumu citi nav atbildējuši pietiekamā skaitā.',
  'peerreview.selectAnswerMessage':
    'Laiks sniegt atzinumu! Tālāk būs kādas citu kursantu atbildes. Izvēlieties vienu un recenzējiet to! Atkārtojiet vismaz trīs reizes.',
  'peerreview.minimumPeerReviewWarning':
    'Uzdevuma izpildi var akceptēt tikai tad, kad saņemti vismaz trīs līdzbiedru atzinumi.',
  'peerreview.select': 'Atlasīt',
  'peerreview.reportSpam': 'Sūdzēties par mēstuli',
  'peerreview.feedback':
    'Jūsu atbilde ir recenzēta un akceptēta. Lūk, ko par to domā līdzbiedri:',
  // exercise17
  'quiz.exercise17.xLabel': 'Izglītības gadi',
  'quiz.exercise17.yLabel': 'Paredzamais mūža ilgums gados',
  'quiz.exercise17.textPart1':
    'Izpētīsim sakaru starp kopējo skolā pavadīto gadu skaitu (visu pēc pirmsskolas iestādes un pirms augstskolas) un paredzamo mūža ilgumu! Te būs trīs dažādu valstu dati, kas parādīti punktu veidotā attēlā:',
  'quiz.exercise17.textPart2':
    'Vienā vidējais gadu skaits skolā ir 10 gadi un paredzamais mūža ilgums ir 57 gadi, otrā vidējais gadu skaits skolā ir 13 gadi un paredzamais mūža ilgums ir 53 gadi, trešā – vidējais gadu skaits skolā ir 20 gadi un paredzamais mūža ilgums ir 80 gadi.',
  'quiz.exercise17.textPart3':
    'Varat pārvietot nepārtrauktās līnijas beigu punktus, līniju novietojot tā, ka tā seko datu punktu tendencei. Ievērojiet, ka nevarēs panākt līnijas nevainojamu saderību ar datu punktiem, bet tas nekas – daži datu punkti paliks virs līnijas, daži zem tās. Svarīgākais ir tas, ka līnija raksturo vispārējo tendenci.',
  'quiz.exercise17.textPart4':
    'Kad līnija novietota, to var izmantot paredzamā mūža ilguma prognozēšanai.',
  'quiz.exercise17.textPart5':
    'Ko pēc šiem datiem var pastāstīt par 15 gadus izglītojušos cilvēku paredzamo mūža ilgumu? Svarīga piezīme! Ievērojiet, ka pat tad, ja ar līnijas pieregulēšanu var iegūt specifisku prognozi pat par gada daļām, var nebūt iespējams iegūt pārliecinošu prognozi. Atbildot ņemiet vērā, ka datu apjoms ir ierobežots.',
  // exercise18
  'quiz.exercise18.xLabel': 'Izglītības gadi',
  'quiz.exercise18.yLabel': 'Paredzamais mūža ilgums gados',
  'quiz.exercise18.textPart1':
    'Iepriekšējā uzdevumā mums bija dati no tikai trim valstīm. Pilnais datu kopums sastāv no 14 dažādu valstu datiem, kas parādīti diagrammā:',
  'quiz.exercise18.textPart2':
    'Vai uz šo datu pamata jūsu prognoze par 15 gadus izglītojušos cilvēku paredzamo mūža ilgumu mainās? Ja tā, tad kāpēc?',
  'quiz.exercise18.textPart3':
    'Kurš no tālāk redzamajiem variantiem vislabāk atbilst jūsu aplēsei par 15 gadus izglītojušos cilvēku paredzamo mūža ilgumu? Izvēlieties precīzāko, jūsuprāt, pamatotu variantu, minētajiem datiem pielāgojot taisnās līnijas modeli!',
  // exercise19
  'quiz.exercise19.xLabel': 'Mācīšanās stundas',
  'quiz.exercise19.yLabel': 'Nolikšanas varbūtība',
  'quiz.exercise19.textPart1':
    'Katrs punkts attēlā atbilst vienam studentam. Attēla apakšējā daļā skala rāda, cik stundu students mācījās eksāmenam, un eksāmenu nolikušie studenti ir parādīti kā punkti diagrammas augšpusē, bet izkritušie – apakšpusē. Mēs izmantosim skalu kreisajā pusē, lai norādītu prognozēto eksāmena nolikšanas varbūtību, ko iegūsim no loģistiskās regresijas modeļa, ko tūlīt izskaidrosim. No attēla aptuveni noprotams, ka studentiem, kuri pavadījuši ilgāku laiku studijās, bijušas labākas izredzes pabeigt kursu. Īpaši intuitīvi ir ekstremālie gadījumi: ar mazāk nekā stundu darba ir ļoti grūti pabeigt kursu, bet ar pamatīgu darbu sekmīgi būs lielākā daļa studentu. Bet kā ar tiem, kuri mācībām veltīja laiku, kas ir kaut kur starp ekstrēmiem? Kādas izredzes nolikt eksāmenu ir tiem, kuri mācās 6 stundas?',
  'quiz.exercise19.textPart2':
    'Nolikšanas varbūtību varam izteikt skaitļos, izmantojot loģistisko regresiju. Līkni attēlā var interpretēt kā nolikšanas varbūtību: piemēram, pēc piecu stundu mācīšanās nolikšanas varbūtība ir mazliet virs 20 %. ',
  'quiz.exercise19.textPart3':
    'Mēs neiedziļināsimies sīkumos par to, kā tiek iegūta līkne, bet tas būs līdzīgi tam, kā uzzinām svarus lineārajā regresijā.',
  'quiz.exercise19.textPart4':
    'Ja vēlaties 80 % iespēju nokārtot universitātes eksāmenu, cik stundu jums būtu jāmācās, spriežot pēc parādītā attēla?',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Ievadlielumi',
  'quiz.exercise22.sigmoidYLabel': 'Sigmoidālie izvaddati',
  'quiz.exercise22.stepXLabel': 'Ievadlielumi',
  'quiz.exercise22.stepYLabel': 'Sliekšņveida funkcijas izvaddati',
  'quiz.exercise22.identityXLabel': 'Ievadlielumi',
  'quiz.exercise22.identityYLabel': 'Identitātes izvaddati',
  'quiz.exercise22.textPart1':
    'Tālāk redzami grafiki trim dažādām aktivizācijas funkcijām ar atšķirīgām īpašībām. Vispirms mums ir sigmoidālā funkcija, tad sliekšņveida funkcija un beigās identitātes funkcija..',
  // exercise23
  'quiz.exercise23.happyFaces': 'pareizi klasificētas priecīgās sejas',
  'quiz.exercise23.sadFaces': 'pareizi klasificētas bēdīgās sejas',
  // certificate availability
  'certificate.failedToCheck': 'Neizdevās pārbaudīt apliecības pieejamību.',
  'certificate.failedToGenerate': 'Neizdevās ģenerēt apliecību.',
  'certificate.notYetAvailable':
    'Jūsu apliecība te būs pieejama, kad būsim to oficiāli apstiprinājuši. Tas var prasīt kādu brīdi. Pateicamies par pacietību!',
  'certificate.updateName':
    'Jūsu apliecība ir pieejama! Lūdzu, atjauniniet savu vārdu un uzvārdu, lai tas būtu redzams jūsu sertifikātā, izmantojot veidlapu, kas pieejama jūsu profila lapā.',
  'certificate.generate': 'Tālāk var ģenerēt apliecību',
  'certificate.generateTextPart1': 'Persona, kam apliecība tiks ģenerēta:',
  'certificate.generateTextPart2':
    'Pirms apliecības ģenerēšanas pārbaudiet informācijas pareizību, jo vēlāk vārdu nevarēs mainīt! Ja vēlaties mainīt vārdu, to var izdarīt tālāk veidlapā.',
  'certificate.generateButton': 'Ģenerēt apliecību',
  'certificate.downloadButton': 'Ielādēt apliecību savā ierīcē',
  'certificate.checkingAvailability':
    'Tiek pārbaudīta apliecības pieejamība...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly':
    'Pareizi klasificēts.',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly':
    'Nav pareizi klasificēts.',
  // section blocks
  'section.block.note': 'Piezīme',
  'section.block.example': 'Piemērs',
  'section.block.keyTerminology': 'Galvenie termini',
  'section.partSummary.spectrumTextPart1':
    'Piebiedrojieties ',
  'section.partSummary.spectrum': '"Elements of AI" kopienai',
  'section.partSummary.spectrumTextPart2':
    ' – diskutējiet un jautājiet par šo nodaļu!',
  'section.partSummary.feedbackTitle':
    'Sniedziet mums atsauksmi par šo kursa daļu! Sniedziet mums atsauksmi par šo kursa daļu!',
  'section.partSummary.feedbackPlaceholder': 'Te ierakstiet atsauksmi!',
  'section.partSummary.feedbackSuccess': 'Pateicamies par atsauksmi!',
  // 404
  'globals.404.title': '404 – Lapa nav atrasta',
  'globals.404.intro': 'Jūs devāties pa neesošu ceļu... skumji.',
  'globals.loading': 'Notiek ielāde...',
  // footer / menu
  'globals.menu': 'Izvēlne',
  'globals.menu.about': 'Par kursu',
  'globals.menu.faq': 'Bieži uzdoti jautājumi',
  'globals.menu.privacyPolicy': 'Privātuma politika',
  'globals.menu.myProfile': 'Mans profils',
  'globals.menu.termsofService': 'Pakalpojuma noteikumi',
  // Dashboard/Index
  'dashboard.title': 'Laipni lūdzam kursā!',
  'dashboard.congratulations.title': 'Apsveicam!',
  'dashboard.congratulations.message':
    'Kurss “Mākslīgā intelekta elementi” ir pabeigts.',
  'dashboard.congratulations.downloadCertificate':
    'Ielādēt apliecību savā ierīcē',
  'dashboard.notLoggedIn.title': 'Laipni lūdzam!',
  'dashboard.notLoggedIn.message':
    'Reģistrējieties vai ieejiet, lai pilnībā piekļūtu kursam! Tas ir par brīvu.',
  // Account
  'account.certificate': 'Apliecība',
  'account.credits': 'ECTS kredītpunkti',
  'account.myProfile': 'Mans profils',
  'account.changePassword': 'Mainīt paroli',
  'account.changeLanguage': 'Mainīt valodu',
  'account.profileInformation': 'Informācija par profilu',
  'account.goToCourse': 'Iet uz kursu',
  'account.buildingAINew': 'Jauns kurss angļu valodā',
  'account.accept': 'Sutinku',
  'account.decline': 'Nesutinku',
  'account.courses': 'Kursi',
  // Sign Up page
  'signUp.description':
    'Aizpildiet veidlapu, lai izveidotu jaunu mooc.fi kontu!',
  'signUp.email': 'E-pasta adrese',
  'signUp.firstName': 'Vārds',
  'signUp.lastName': 'Uzvārds',
  'signUp.postalCode': 'Pasta indekss',
  'signUp.country': 'Kur jūs dzīvojat?',
  'signUp.Password': 'Parole',
  'signUp.confirmPassword': 'Apstiprināt paroli',
  'signUp.howToStudyTitle': 'Kā vēlaties studēt?',
  'signUp.6weeksTitle': '6 nedēļu kurss',
  'signUp.6weeksSubTitle': 'Ieteicams (ap 5 stundām nedēļā)',
  'signUp.6weeksText': 'Nolikts termiņš ir pārbaudīts palīgs kursa pabeigšanā.',
  'signUp.selfPacedTitle': 'Savā tempā',
  'signUp.selfPacedText': 'Kursu var apgūt pašu izraudzītā tempā.',
  'signUp.ectsTitleSwe': '',
  'signUp.ectsTitle': 'Vai pieprasāt ECTS kredītpunktus?',
  'signUp.ectsStudent':
    'Esmu Helsinku Universitātes students. Ja iezīmēsit šo rūtiņu un ievadīsit savu imatrikulācijas numuru, ECTS kredītpunktus saņemsit automātiski.',
  'signUp.ectsStudentSwe': '',
  'signup.ectsStudentSweLink': '',
  'signUp.ectsOpenUni':
    'Iezīmējiet šo rūtiņu, lai saņemtu e-vēstuli ar norādījumiem par ECTS kredītpunktu pieprasīšanu!',
  'signUp.studentNumber':
    'Helsinku Universitātes imatrikulācijas numurs (ja esat students)',
  'signUp.privacyOptIn':
    'Atļauju pētniecības vajadzībām izmantot apkopotus datus par manu rīcību kursa apguvē. Datos ir informācija par materiālu izmantojumu un kursā izpildītajiem uzdevumiem. No publikācijām nebūs iespējams identificēt individuālas personas.',
  'signUp.marketingOptIn':
    'Piekrītu saņemt ziņas par gaidāmām valodu versijām un informāciju par jauniem kursiem. Es piekrītu kopīgot savu kontaktinformāciju, lai saņemtu pielāgotus ziņojumus trešo pušu platformās. Lasiet vairāk mūsu Privātuma politiku.',
  'signUp.privacyLink': 'Izlasiet par mūsu privātuma politiku!',
  'signUp.error': 'Jūsu veidlapā ir kļūdas. Pārbaudiet veidlapu!',
  'signUp.acceptPolicyFirst': 'Es piekrītu privātuma politikai un pakalpojumu sniegšanas noteikumiem.',
  // Sign in page
  'signIn.title': 'Pieslēgties',
  'signIn.intro': 'Ieiešanai ar savu mooc.fi kontu izmantojiet šos laukus:',
  'signIn.email': 'E-pasta adrese:',
  'signIn.password': 'Parole:',
  'signIn.noAccount': 'Jums nav konta?',
  'signIn.signUpNow': 'Reģistrēties tūlīt',
  // Forgot password
  'pwForget.title': 'Aizmirsās parole.',
  'pwForget.description':
    'Ierakstiet savu mooc.fi konta e-pasta adresi, un mēs jums atsūtīsim paroles pārstatīšanas saiti.',
  'pwForget.sendResetLink': 'Atsūtīt saiti pārstatīšanai',
  'pwForget.completedDescription':
    'Pēc brīža no mooc.fi saņemsit e-vēstuli ar paroles pārstatīšanas saiti. Ja tā neparādās, ieskatieties pie mēstulēm.',
  'pwForget.problemDescription':
    'Ja rodas problēmas, sazinieties ar mooc@cs.helsinki.fi!',
  'pwForget.resetEmailSent': 'Paroles pārstatīšanas e-vēstule nosūtīta.',
  'pwForget.emailPlaceholder': 'E-pasta adrese',
  // password change
  'pwChange.authError':
    'Autentifikācija neizdevās. Mēģiniet pierakstīties vēlreiz!',
  'pwChange.unexpError':
    'Negaidītas kļūdas dēļ nav izdevies atjaunināt paroli.',
  'pwChange.required': 'Noteikti jāaizpilda',
  'pwChange.pwMustMatch': 'Noteikti jāaizpilda',
  'pwChange.currentPassword': 'Pašreizējā parole',
  'pwChange.newPassword': 'Jaunā parole',
  'pwChange.confirmPassword': 'Apstiprināt jauno paroli',
  'pwChange.changingPassword': 'Maina paroli...',
  'pwChange.changePassword': 'Mainīt paroli',
  'pwChange.passwordChanged': 'Parole mainīta',
  // user details
  'userDetails.authError':
    'Autentifikācija neizdevās. Mēģiniet pierakstīties vēlreiz!',
  'userDetails.unexpError': 'Noteikti jāaizpilda',
  'userDetails.required': 'Noteikti jāaizpilda',
  'userDetails.invalidEmail': 'Nederīga e-pasta adrese',
  'userDetails.email': 'E-pasts',
  'userDetails.emailAddress': 'E-pasta adrese',
  'userDetails.firstName': 'Vārds',
  'userDetails.lastName': 'Uzvārds',
  'userDetails.language': 'Valoda',
  'userDetails.updatingProfile': 'Atjaunina profilu...',
  'userDetails.updateProfile': 'Atjaunināt profilu',
  'userDetails.profileUpdated': 'Profils atjaunināts',
  // languages
  'languages.en.long': 'angļu',
  'languages.fi.long': 'somu',
  'languages.se.long': 'zviedru',
  'languages.de.long': 'vācu (Vācija)',
  'languages.ee.long': 'igauņu',
  'languages.it.long': 'itāļu',
  'languages.nl.long': 'holandiešu (Nīderlande)',
  'languages.sk.long': 'slovāku',
  'languages.cs.long': 'čehu',
  'languages.no.long': 'norvēģu',
  'languages.lv.long': 'latviešu',
  'languages.da.long': 'dāņu',
  'languages.ro.long': 'rumāņu',
  'languages.pl.long': 'poļu',
  'languages.en-ie.long': 'angļu (Īrija)',
  'languages.mt.long': 'maltiešu',
  'languages.fr-be.long': 'franču (Beļģija)',
  'languages.nl-be.long': 'holandiešu (Beļģija)',
  'languages.ga.long': 'īru',
  'languages.lt.long': 'lietuviešu',
  'languages.fr.long': 'franču (Francija)',
  'languages.hr.long': 'horvātu',
  'languages.is.long': 'islandiešu',
  'languages.de-at.long': 'vācietiski (Austrija)',
  'languages.en-lu.long': 'angliski (Luksemburga)',
  'languages.bg.long': 'bulgāru',
  'languages.sl.long': 'slovēņu',
  'languages.pt.long': 'portugāļu',
  'languages.el.long': 'grieķu',
  'languages.es.long': 'spāņu',
  // Email verification
  'emailVerification.completedMessage': 'Jūsu e-pasta adrese ir pārbaudīta.',
  'emailVerification.verified': 'Pārbaudīts.',
  // Exercises
  'exercise.loading': 'Uzdevums tiek ielādēts...',
  'exercise.signUp': 'Piesakieties uzdevumu risināšanā',
  'exercise.score': 'Jūsu rezultāts:',
  'exercise.wrongLocale': 'Nepareizi noteikta vieta.',
  // Part tempplate
  'partTemplate.intro1': 'Nodaļā ',
  'partTemplate.intro2':
    ' tiks aplūkotas nākamās sadaļas. Lai sāktu, noklikšķiniet zemāk:',
  'partTemplate.spectrumPart1':
    'Piebiedrojieties ',
  'partTemplate.spectrum': '"Elements of AI" kopienai',
  'partTemplate.spectrumPart2': ' – diskutējiet un jautājiet par šo nodaļu!',
  // missing info
  'missingInfo.title': 'Trūkst informācijas',
  'missingInfo.body':
    'Šos jautājumus parasti uzdodam, kad lietotāji reģistrējas, taču jūs ienācāt ar pastāvošu mooc.fi kontu. Lai turpinātu, aizpildiet veidlapu!',
  'missingInfo.privacyPolicy': 'Izlasiet par mūsu privātuma politiku!',
  'missingInfo.error': 'Jūsu veidlapā ir kļūdas. Pārbaudiet veidlapu!',
  'missingInfo.privacyPolicyUpdateBody': "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
  'missingInfo.privacyPolicyUpdateLink': 'Iepazīstieties ar mūsu privātuma politiku šeit',
  // routes
  'routes.LANDING': '/lv/',
  'routes.SIGN_UP': '/lv/signup',
  'routes.SIGN_IN': '/lv/signin',
  'routes.MISSING_INFO': '/lv/missinginfo',
  'routes.PASSWORD_FORGET': '/lv/pw-forget',
  'routes.HOME': '/lv/home',
  'routes.ACCOUNT': '/lv/account',
  'routes.PRIVACY': 'https://www.elementsofai.lv/faq/privatuma-politika-angliski',
  'routes.FAQ': 'https://www.elementsofai.lv/faq',
  'routes.ABOUT': 'https://www.elementsofai.lv',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/lv-latviesu-kopiena',
  'routes.ects': 'https://mooc.fi/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/faq/how-can-i-get-2-ects-credits',
  'routes.ACCEPT_POLICY': '/lv/accept-policy',

  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Krūzes kafijas dienā',
  'markdowncomponents.linearRegressionStatic.yLabel':
    'Uzrakstītās programmas rindas',
  // meta
  'meta.title': 'Bezmaksas kurss tiešsaistē',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description':
    'Uzziniet ko vairāk par Helsinku Universitātes un “Reaktor” MI kursu studentiem un komersantiem – netiek prasītas programmēšanas un matemātikas prasmes.',
  'meta.title.og': 'A free online introduction to artificial intelligence for non-experts',

  // ECTS
  'ects.text':
    'Pēc kursa pabeigšanas te saņemsit norādījumus par pieteikšanos uz ECTS kredītpunktiem.',
  'ects.textCompleted':
    'Ja jums ir Somijas sociālās nodrošināšanas numurs, uz kredītpunktiem varat pieteikties, noklikšķinot',
  'ects.link': 'šeit.',
  'ects.info': 'Would you like to apply for ECTS credits?',
  'ects.readMore': 'Read more',
  // Countries
  'countryselect.select': 'Lūdzu izvēlieties',
  'Sweden': 'Zviedrija',
  'Estonia': 'Igaunija',
  'Finland': 'Somija',
  'Germany': 'Vācija',
  'Norway': 'Norvēģija',
  'Latvia': 'Latvija',
  'Netherlands': 'Nīderlande',
  'Countries': {
    'Afganistan': 'Afganistāna',
    'Åland Islands': 'Ālandu salas',
    'Albania': 'Albānija',
    'Algeria': 'Alžīrija',
    'American Samoa': 'Amerikāņu Samoa',
    'Andorra': 'Andora',
    'Angola': 'Angola',
    'Anguilla': 'Angilja',
    'Antarctica': 'Antarktīda',
    'Antigua and Barbuda': 'Antigva un Barbuda',
    'Argentina': 'Argentīna',
    'Armenia': 'Armēnija',
    'Aruba': 'Aruba',
    'Australia': 'Austrālija',
    'Austria': 'Austrijā',
    'Azerbaijan': 'Azerbaidžāna',
    'Bahamas': 'Bahamu salas',
    'Bahrain': 'Bahreina',
    'Bangladesh': 'Bangladeša',
    'Barbados': 'Barbadosa',
    'Belarus': 'Baltkrievija',
    'Belgium': 'Beļģija',
    'Belize': 'Beliza',
    'Benin': 'Benina',
    'Bermuda': 'Bermudu salas',
    'Bhutan': 'Butāna',
    'Bolivia': 'Bolīvija',
    'Bosnia and Herzegovina': 'Bosnija un Hercegovina',
    'Botswana': 'Botsvāna',
    'Bouvet Island': 'Bouvet sala',
    'Brazil': 'Brazīlija',
    'British Indian Ocean Territory': 'Indijas okeāna Britu teritorija',
    'Brunei Darussalam': 'Bruneja Darusalama',
    'Bulgaria': 'Bulgārija',
    'Burkina Faso': 'Burkinafaso',
    'Burundi': 'Burundi',
    'Cambodia': 'Kambodža',
    'Cameroon': 'Kamerūna',
    'Canada': 'Kanāda',
    'Cape Verde': 'Kaboverde',
    'Cayman Islands': 'Kaimanu salas',
    'Central African Republic': 'Centrālāfrikas Republika',
    'Chad': 'Čada',
    'Chile': 'Čīle',
    'China': 'Ķīna',
    'Christmas Island': 'Ziemassvētku sala',
    'Cocos (Keeling) Islands': 'Kokosu (Kīlinga) salas',
    'Colombia': 'Kolumbija',
    'Comoros': 'Komoru salas',
    'Congo': 'Kongo',
    'Congo, The Democratic Republic of The': 'Kongo, Demokrātiskā Republika',
    'Cook Islands': 'Kuka salas',
    'Costa Rica': 'Kostarika',
    "Cote D'ivoire": 'Ziloņkaula krasts',
    'Croatia': 'Horvātija',
    'Cuba': 'Kuba',
    'Cyprus': 'Kipra',
    'Czech Republic': 'Čehu Republika',
    'Denmark': 'Dānija',
    'Djibouti': 'Džibutija',
    'Dominica': 'Dominika',
    'Dominican Republic': 'Dominikānas republika',
    'Ecuador': 'Ekvadora',
    'Egypt': 'Ēģipte',
    'El Salvador': 'Salvadora',
    'Equatorial Guinea': 'Ekvatoriālā Gvineja',
    'Eritrea': 'Eritreja',
    'Estonia': 'Igaunija',
    'Ethiopia': 'Etiopija',
    'Falkland Islands (Malvinas)': 'Folklenda salas (Malvinas)',
    'Faroe Islands': 'Farēru salas',
    'Fiji': 'Fidži',
    'Finland': 'Somija',
    'France': 'Francija',
    'French Guiana': 'Franču Gviāna',
    'French Polynesia': 'Francijas Polinēzija',
    'French Southern Territories': 'Francijas dienvidu teritorijas',
    'Gabon': 'Gabona',
    'Gambia': 'Gambija',
    'Georgia': 'Gruzijā',
    'Germany': 'Vācija',
    'Ghana': 'Gana',
    'Gibraltar': 'Gibraltārs',
    'Greece': 'Grieķija',
    'Greenland': 'Grenlande',
    'Grenada': 'Grenāda',
    'Guadeloupe': 'Gvadelupa',
    'Guam': 'Guam',
    'Guatemala': 'Gvatemala',
    'Guernsey': 'Gērnsija',
    'Guinea': 'Gvineja',
    'Guinea-Bissau': 'Gvineja-Bisava',
    'Guyana': 'Gajāna',
    'Haiti': 'Haiti',
    'Heard Island and Mcdonald Islands': 'Dzirdēto sala un Makdonalda salas',
    'Holy See (Vatican City State)': 'Svētais Krēsls (Vatikāna Pilsētvalsts)',
    'Honduras': 'Hondurasa',
    'Hong Kong': 'Honkonga',
    'Hungary': 'Ungārija',
    'Iceland': 'Islande',
    'India': 'Indija',
    'Indonesia': 'Indonēzija',
    'Iran, Islamic Republic of': 'Irāna, Islāma Republika',
    'Iraq': 'Irāka',
    'Ireland': 'Īrija',
    'Isle of Man': 'Menas Sala',
    'Israel': 'Izraēla',
    'Italy': 'Itālijā',
    'Jamaica': 'Jamaika',
    'Japan': 'Japāna',
    'Jersey': 'Džērsija',
    'Jordan': 'Jordānija',
    'Kazakhstan': 'Kazahstāna',
    'Kenya': 'Kenija',
    'Kiribati': 'Kiribati',
    "Korea, Democratic People's Republic of":
      'Korejas Tautas Demokrātiskā Republika',
    'Korea, Republic of': 'Korejas Republika',
    'Kosovo': 'Kosova',
    'Kuwait': 'Kuveita',
    'Kyrgyzstan': 'Kirgizstāna',
    "Lao People's Democratic Republic": 'Laosas Tautas Demokrātiskā Republika',
    'Latvia': 'Latvija',
    'Lebanon': 'Libāna',
    'Lesotho': 'Lesoto',
    'Liberia': 'Libērija',
    'Libyan Arab Jamahiriya': 'Lībijas arābu Jamahiriya',
    'Liechtenstein': 'Lihtenšteina',
    'Lithuania': 'Lietuva',
    'Luxembourg': 'Luksemburga',
    'Macao': 'Makao',
    'Macedonia, The Former Yugoslav Republic of':
      'Maķedonija, Bijusī Dienvidslāvijas Republika',
    'Madagascar': 'Madagaskara',
    'Malawi': 'Malāvija',
    'Malaysia': 'Malaizija',
    'Maldives': 'Maldivu salas',
    'Mali': 'Mali',
    'Malta': 'Maltā',
    'Marshall Islands': 'Māršala salas',
    'Martinique': 'Martinika',
    'Mauritania': 'Mauritānija',
    'Mauritius': 'Maurīcija',
    'Mayotte': 'Majota',
    'Mexico': 'Meksika',
    'Micronesia, Federated States of': 'Mikronēzijas Federatīvās Valstis',
    'Moldova, Republic of': 'Moldovas Republika',
    'Monaco': 'Monako',
    'Mongolia': 'Mongolija',
    'Montenegro': 'Melnkalne',
    'Montserrat': 'Montserrata',
    'Marocco': 'Maroka',
    'Mozambique': 'Mozambika',
    'Myanmar': 'Mjanma',
    'Namibia': 'Namībija',
    'Nauru': 'Nauru',
    'Nepal': 'Nepāla',
    'Netherlands': 'Nīderlande',
    'Netherlands Antilles': 'Nīderlandes Antiļas',
    'New Caledonia': 'Jaunkaledonija',
    'New Zealand': 'Jaunzēlande',
    'Nicaragua': 'Nikaragva',
    'Niger': 'Nigēra',
    'Nigeria': 'Nigērija',
    'Niue': 'Niue',
    'Norfolk Island': 'Norfolkas sala',
    'Northern Mariana Islands': 'Marianas ziemeļu salas',
    'Norway': 'Norvēģija',
    'Oman': 'Omāna',
    'Pakistan': 'Pakistāna',
    'Palau': 'Palau',
    'Palestinian Territory, Occupied': 'Okupēta Palestīnas teritorija',
    'Panama': 'Panama',
    'Papua New Guinea': 'Papua Jaungvineja',
    'Paraguay': 'Paragvaja',
    'Peru': 'Peru',
    'Philippines': 'Filipīnas',
    'Pitcairn': 'Pitkērna',
    'Poland': 'Polija',
    'Portugal': 'Portugāle',
    'Puerto Rico': 'Puertoriko',
    'Qatar': 'Katara',
    'Reunion': 'Reinjona',
    'Romania': 'Rumānija',
    'Russian Federation': 'Krievijas Federācija',
    'Rwanda': 'Ruanda',
    'Saint Helena': 'Svētā Helēna',
    'Saint Kitts and Nevis': 'Sentkita un Nevisa',
    'Saint Lucia': 'Sentlūsija',
    'Saint Pierre and Miquelon': 'Sentpjēra un Mikelona',
    'Saint Vincent and The Grenadines': 'Sentvinsenta un Grenadīnas',
    'Samoa': 'Samoa',
    'San Marino': 'Sanmarīno',
    'Sao Tome and Principe': 'Santome un Prinsipi',
    'Saudi Arabia': 'Saūda Arābija',
    'Senegal': 'Senegāla',
    'Serbia': 'Serbija',
    'Seychelles': 'Seišelu salas',
    'Sierra Leone': 'Sjerraleone',
    'Singapore': 'Singapūra',
    'Slovakia': 'Slovākija',
    'Slovenia': 'Slovēnija',
    'Solomon Islands': 'Zālamana salas',
    'Somalia': 'Somālija',
    'South Africa': 'Dienvidāfrika',
    'South Georgia and The South Sandwich Islands':
      'Dienviddžordžija un Dienvidsendviču salas',
    'Spain': 'Spānija',
    'Sri Lanka': 'Šrilanka',
    'Sudan': 'Sudāna',
    'Suriname': 'Surinama',
    'Svalbard and Jan Mayen': 'Svalbards un Jans Majens',
    'Swaziland': 'Svazilenda',
    'Sweden': 'Zviedrija',
    'Swizerland': 'Swizerland',
    'Syrian Arab Republic': 'Sīrijas Arābu Republika',
    'Taiwan': 'Taivāna',
    'Tajikistan': 'Tadžikistāna',
    'Tanzania, United Republic of': 'Tanzānija,',
    'Thailand': 'Taizeme',
    'Timor-leste': 'Austrumtimora',
    'Togo': 'Togo',
    'Tokelau': 'Tokelau',
    'Tonga': 'Tonga',
    'Trinidad and Tobago': 'Trinidada un Tobāgo',
    'Tunisia': 'Tunisija',
    'Turkey': 'Turcija',
    'Turkmenistan': 'Turkmenistāna',
    'Turks and Caicos Islands': 'Tērksas un Kaikosas salas',
    'Tuvalu': 'Tuvalu',
    'Uganda': 'Uganda',
    'Ukraine': 'Ukraina',
    'United Arab Emirates': 'Apvienotie Arābu Emirāti',
    'United Kingdom': 'Apvienotā Karaliste',
    'United States': 'Savienotās Valstis',
    'United States Minor Outlying Islands':
      'ASV Mazās aizjūras salas',
    'Uruguay': 'Urugvaja',
    'Uzbekistan': 'Uzbekistāna',
    'Vanuatu': 'Vanuatu',
    'Venezuela': 'Venecuēla',
    'Viet Nam': 'Vjetnama',
    'Virgin Islands, British': 'Virdžīnu salas, briti',
    'Virgin Islands, U.S.': 'Virdžīnu salas, ASV',
    'Wallis and Futuna': 'Volisa un Futuna',
    'Western Sahara': 'Rietumsahāra',
    'Yemen': 'Jemena',
    'Zambia': 'Zambija',
    'Zimbabwe': 'Zimbabve',
  },
  'alt.example-1': 'divi pašbraucēji automobiļi',
  'alt.example-2': 'mobilais tālrunis rokā',
  'alt.example-3': 'sievietes seja',
  'alt.defining-ai': 'ar MI saistīto vārdu biezoknis',
  'alt.turing-test': 'vīrs datorā tērzē ar robotu un sievieti',
  'alt.chinese-room': 'eksperiments ar ķīniešu istabu',
  'alt.chicken-crossing':
    'robots laivā skatās uz vistu, lapsu un vistu barības maisiņu',
  'alt.a-to-b': 'roka ar telefonu, kurā darbojas navigācijas lietotne',
  'alt.tic-tac-toe': 'divi bērni spēlē krustiņus un nullītes',
  'alt.self-driving-car': 'sieviete iet pa priekšu pašbraucējam automobilim',
  'alt.spam-or-ham': 'parasta e-vēstule un mēstule',
  'alt.mnist': 'MNIST datu kopa',
  'alt.nearest-neighbor': 'divi iepērkas',
  'alt.nearest-neighbor-graph': 'tuvāko kaimiņu klasifikators',
  'alt.recommendation': 'vīrietis un sieviete klausās mūziku telefonā',
  'alt.price-real-estate': 'trīs dažādas mājas',
  'alt.supervised-learning': 'cilvēks māca robotu',
  'alt.dice': 'dice',
  'alt.chess': 'šaha galdiņš',
  'alt.poker': 'rokā spēļu kārtis',
  'alt.eyes': 'trīs cilvēki ar brūnām acīm, viens ar zilām',
  'alt.search-1': 'vienīgais ceļš uz mērķi',
  'alt.search-2': 'četri dažādi ceļi uz vienu mērķi',
  'alt.chicken-crossing-1': 'vistas pārcelšanas sākumstāvoklis',
  'alt.chicken-crossing-2': 'vistas pārcelšanas pirmā pāreja',
  'alt.chicken-crossing-3': 'visas vistas pārcelšanas pārejas',
  'alt.chicken-crossing-4': 'vistas pārcelšanas labākais ceļš',
  'alt.chicken-crossing-5': 'vistas pārcelšanas sākumstāvoklis',
  'alt.implication-1': 'vīrietis un kāda sieviete',
  'alt.implication-2': 'acis',
  'alt.implication-3': 'novērošanas kamera ',
  'alt.implication-4': 'robots strādā ar klēpjdatoru ',
  'alt.oddchange': 'saule un mākoņi ',
  'alt.brain': 'smadzeņu zīmējums ',
  'alt.carstop': 'pašbraucējs automobilis pie apstāšanās zīmes',
  'alt.gan': 'robots tur kaķa attēlu un apstāšanās zīmes attēlu ',
  'alt.hammock': 'robots vēdina vīrieti šūpuļtīklā',
  'alt.game-tree-1': '1. spēles koks',
  'alt.game-tree-2': '2. spēles koks',
  'alt.game-tree-3': '3. spēles koks',
  'alt.game-tree-4': '4. spēles koks',
  'alt.bayes-rule-1':
    'attēls ar kļūdaini pozitīviem un negatīviem vēža izmeklēšanas rezultātiem  ',
  'alt.bayes-rule-2':
    'attēls ar kļūdaini pozitīviem un negatīviem vēža izmeklēšanas rezultātiem  ',
  'alt.defineai': 'ar MI saistīto vārdu biezoknis',
  'alt.winter': 'robots guļ sniegā',
  'alt.terminator': 'baismīgs robots',
}
