// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'fi',
  'globals.signIn': 'Kirjaudu sisään',
  'globals.logIn': 'Kirjaudu sisään',
  'globals.signUp': 'Rekisteröidy',
  'globals.signOut': 'Kirjaudu ulos',
  'globals.logOut': 'Kirjaudu ulos',
  'globals.submit': 'Lähetä',
  'globals.submitting': 'Lähetetään...',
  'globals.yourEmail': 'Sähköpostiosoitteesi',
  'globals.yourPassword': 'Salasanasi',
  'globals.courseOverview': 'Kurssin yleiskuvaus',
  'globals.forgotPassword': 'Unohditko salasanasi?',
  'globals.invalidCredentials': 'Virhe kirjautumistiedoissa',
  'globals.section': 'Kappale',
  'globals.exercises': 'Tehtäviä',
  'globals.chapter': 'Luku',
  'globals.introToAI': 'Tekoälyn perusteet',
  'globals.buildingAI': 'Tekoälyä käytännössä',
  'globals.acceptPolicy': 'Accept policy',
  // section
  'section.footerEndOfTheChapter': 'Olet päässyt luvun {chapter} loppuun!',
  'section.footerEndOfTheCourseTitle': 'Olet päässyt kurssin loppuun!',
  'section.footerEndOfTheCourseText': 'Kun olet tehnyt kaikki tehtävät ja vertaisarviot, vastauksesi arvostellaan.',
  'section.nextSection': 'Seuraava kappale',
  'section.nextChapter': 'Seuraava luku',
  'section.start': 'Aloita',
  'section.logisticRegressionExample.slope': 'Slope',
  'section.logisticRegressionExample.intercept': 'Intercept',
  'section.footerEndOfTheCourseBAI': 'Jatka matkaa ja toteuta ensimmäinen, oma AI-ideasi',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Odottaa vertaisarviotasi',
  'quiz.rejectedPeerReview': 'Hylätty vertaisarviossa',
  'quiz.unsubmittedAnswer': 'Lähettämätön vastaus',
  'quiz.pendingPeerReview': 'Odottaa vertaisarviota',
  // quiz
  'quiz.status.answered': 'Vastattu',
  'quiz.status.rejected': 'Vastaus hylätty, yritä uudelleen',
  'quiz.status.unanswered': 'Vastaamaton',
  'quiz.status.exercisesCompleted': 'Tehtyjä tehtäviä',
  'quiz.status.correctAnswers': 'Oikeita vastauksia',
  'quiz.message.rejectedInReview': 'Vastauksesi on hylätty vertaisarvioinnissa. Ole hyvä ja yritä uudelleen.',
  'quiz.youAnswered': 'Vastasit',
  'quiz.yourAnswer': 'Vastauksesi:',
  'quiz.yourScore': 'Pisteesi:',
  'quiz.quizNotLoaded': 'Tehtävää ei voitu ladata',
  'quiz.words': 'Sanoja:',
  'quiz.exampleAnswer': 'Esimerkkivastaus:',
  // peer review
  'peerreview.question1': 'Vastaus on asiallinen',
  'peerreview.question2': 'Vastaus on kattava',
  'peerreview.question3': 'Vastaus on hyvin perusteltu',
  'peerreview.question4': 'Vastaus on helppo ymmärtää',
  'peerreview.thankYou': 'Kiitoksia, että arvioit muiden vastauksia!',
  'peerreview.giveMoreReviews': 'Jos sinulla on aikaa,',
  'peerreview.giveMoreReviewsButton': 'anna lisää arvioita',
  'peerreview.notEnoughAnswers': 'Tässä kohtaa pitäisi olla vertaisarviointilomake, mutta tehtävään ei ole vielä vastattu tarpeeksi. Ole hyvä ja palaa myöhemmin antamaan vertaisarviosi.',
  'peerreview.selectAnswerMessage': 'On vertaisarvioinnin aika! Alla on muiden opiskelijoiden vastauksia. Valitse jompi kumpi niistä, ja arvioi se. Toista tämä vähintää kolme kertaa.',
  'peerreview.minimumPeerReviewWarning': 'Tehtävä voidaan hyväksyä vasta kun olet antanut vähintään kolme vertaisarviota.',
  'peerreview.select': 'Valitse',
  'peerreview.reportSpam': 'Ilmoita asiaton vastaus',
  'peerreview.feedback': 'Vastauksesi on arvosteltu ja hyväksytty. Näin se arvioitiin:',
  // exercise17
  'quiz.exercise17.xLabel': 'koulutus (vuosina)',
  'quiz.exercise17.yLabel': 'elinajanodote (vuosina)',
  'quiz.exercise17.textPart1': 'Tarkastellaanpa koulussa (esikoulusta yliopistoon ja kaikki siltä väliltä) vietetyn ajan ja elinajanodotteen välistä suhdetta. Dataa on kolmesta eri maasta esitettynä kolmena pisteenä.',
  'quiz.exercise17.textPart2': 'Dataan kuuluu yksi maa, jossa keskimääräinen koulutukseen käytetty aika on 10 vuotta ja elinajanodote on 57 vuotta, toinen maa, jossa koulutukseen käytetty aika on 13 ja elinajanodote on 53 vuotta, ja kolmas maa, jossa koulutukseen käytetty aika on 20 vuotta ja elinajanodote on 80 vuotta.',
  'quiz.exercise17.textPart3': 'Napsauttamalla ja vetämällä kaavioon piirretyn suoran viivan päätepisteitä voit säätää kaaviota kuvaamaan erilaisia ennusteita. Yritä löytää suora, joka kuvaa dataa parhaalla mahdollisella tavalla. Et voi saada suoraa viivaa osumaan täydellisesti kaikkien kolmen pisteen päälle, mutta se ei haittaa: osa pisteistä voi jäädä viivan yläpuolelle ja osa sen alapuolelle. Pääasia on, että viiva kuvaa dataa pääpiirteissään.',
  'quiz.exercise17.textPart4': 'Kun olet saanut sovitettua viivan mielestäsi sopivalla tavalla, voit käyttää sitä elinajanodotteen ennustamiseen.',
  'quiz.exercise17.textPart5': 'Käytettävissä olevan datan huomioon ottaen, mitä voit sanoa sellaisten ihmisten elinajanodotteesta, jotka ovat käyneet 15 vuotta kouluja? Huom.: Vaikka viivaa säätämällä voitkin saada erittäin tarkan ennusteen, et välttämättä voi luottaa ennusteen tarkkuuteen rajattomasti. Huomioi datan määrä, kun valitset sopivaa vastausta.',
  // exercise18
  'quiz.exercise18.xLabel': 'koulutus (vuosina)',
  'quiz.exercise18.yLabel': 'elinajanodote (vuosina)',
  'quiz.exercise18.textPart1': 'Edellisessä tehtävässä dataa oli vain kolmesta maasta. Isompi datajoukko, joka on kuvattu seuraavassa kaaviossa, sisältää dataa 14 maasta.',
  'quiz.exercise18.textPart2': 'Muuttuuko arviosi 15 vuotta kouluja käyneiden elinajanodotteesta, kun dataa onkin enemmän, ja miksi?',
  'quiz.exercise18.textPart3': 'Mikä seuraavista vaihtoehdoista kuvaa parhaiten arvioitasi 15 vuotta kouluja käyneiden elinajanodotteesta? Valitse kaikkein täsmällisin vaihtoehto, jonka voit perustella sovittamalla suoran viivan yllä olevaan dataan. Ota huomioon, että dataa on nyt enemmän kuin aiemmin, mutta sen määrä on edelleen rajallinen.',
  // exercise19
  'quiz.exercise19.xLabel': 'valmistautumiseen käytetty aika (tunteja)',
  'quiz.exercise19.yLabel': 'hyväksymistodennäköisyys',
  'quiz.exercise19.textPart1': 'Jokainen piste kuvassa kuvaa yhtä oppilasta. Kaavion alareunassa on asteikko, johon on merkitty oppilaiden opiskeluun käyttämät tuntimäärät. Kaavion alareunassa olevat pisteet ovat opiskelijoita, jotka eivät läpäisseet koetta ja kaavion yläreunassa olevat pisteet puolestaan niitä opiskelijoita, jotka läpäisivät kokeen. Kaavion vasemman reunan asteikon avulla voimme ilmaista todennäköisyyden, jolla oppilas läpäisee kokeen sillä perusteella, kuinka monta tuntia hän käytti valmistautumiseen. Tämän todennäköisyyden saamme logistisesta regressiomallista, kuten pian selitämme.',
  'quiz.exercise19.textPart2': 'Kaaviota tarkastelemalla huomaat varmaankin, että pidempään valmistautuneilla oppilailla oli paremmat mahdollisuudet läpäistä koe kuin oppilailla, jotka valmistautuivat vain muutamia tunteja. Ääritapaukset on helppo ymmärtää: alle tunnin valmistautumisella koe on hyvin vaikea läpäistä, mutta ahkerasti opiskelemalla onnistuminen on luultavaa. Mutta mitä voimme sanoa heistä, jotka ovat ääripäiden välissä? Mikä on onnistumistodennäköisyys kuuden tunnin valmistautumisella?',
  'quiz.exercise19.textPart3': 'Logistinen regressio voi tuottaa todennäköisyysarvion kurssin läpäisemiselle. Kuvassa oleva hiukan S-kirjainta muistuttava käyrä kuvaa tätä arviota: esimerkiksi viiden tunnin opiskelulla onnistumistodennäköisyys on hiukan yli 20 %. Emme käsittele tarkemmin sitä, miten tämä käyrä saadaan aikaan, mutta se perustuu samankaltaisiin kertoimiin kuin ne, joita käytetään lineaariregressiossa.',
  'quiz.exercise19.textPart4': 'Jos haluaisit läpäistä ruoanlaittokurssin 80 prosentin todennäköisyydellä, mikä määrä valmistautumista riittäisi yllä olevan kaavion perusteella?',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'syöte',
  'quiz.exercise22.sigmoidYLabel': 'sigmoidifunktion tuloste',
  'quiz.exercise22.stepXLabel': 'syöte',
  'quiz.exercise22.stepYLabel': 'porrasfunktion tuloste',
  'quiz.exercise22.identityXLabel': 'syöte',
  'quiz.exercise22.identityYLabel': 'identiteettifunktion tuloste',
  'quiz.exercise22.textPart1': 'Alla olevat kolme kaaviota esittävät kolmea erilaista aktivaatiofunktiota. Ensimmäinen on sigmoidifunktio, toinen on porrasfunktio ja kolmas identiteettifunktio.',
  // exercise23
  'quiz.exercise23.happyFaces': 'iloisista hymiöistä luokiteltu oikein',
  'quiz.exercise23.sadFaces': 'surullisista hymiöistä luokiteltu oikein',
  // certificate availability
  'certificate.failedToCheck': 'Virhe sertifikaatin tarkistuksessa',
  'certificate.failedToGenerate': 'Virhe sertifikaatin luomisessa',
  'certificate.notYetAvailable': 'Sertifikaattisi tulee saataville tähän kun pääset kurssista läpi. Voit tarkistaa edistymisesi kurssin etusivulta.',
  'certificate.updateName': 'Sertifikaattisi on saatavilla! Ole hyvä ja päivitä etu- ja sukunimesi, jotta ne näkyvät todistuksessasi, käyttämällä profiilisivultasi löytyvää lomaketta.',
  'certificate.generate': 'Luo sertifikaattisi tässä.',
  'certificate.generateTextPart1': 'Sertifikaatti tehdään nimelle',
  'certificate.generateTextPart2': 'Varmista, että tiedot ovat oikein ennen sertifikaatin luomista. Nimeä ei voi muuttaa jälkeenpäin. Jos haluat muuttaa nimeäsi, tee se allaolevalla lomakkeella.',
  'certificate.generateButton': 'Luo sertifikaatti',
  'certificate.downloadButton': 'Lataa sertifikaatti',
  'certificate.checkingAvailability': 'Tarkistetaan sertifikaatin saatavuutta...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Classified correctly',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Not classified correctly',
  // section blocks
  'section.block.note': 'Huom.',
  'section.block.example': 'Esimerkki',
  'section.block.keyTerminology': 'Avaintermejä',
  'section.partSummary.spectrumTextPart1': 'Keskustele ja esitä kysymyksiä tästä luvusta ',
  'section.partSummary.spectrum': 'Elements of AI -yhteisössä',
  'section.partSummary.spectrumTextPart2': '.',
  'section.partSummary.feedbackTitle': 'Anna meille palautetta tästä luvusta',
  'section.partSummary.feedbackPlaceholder': 'Kirjoita palautteesi tähän.',
  'section.partSummary.feedbackSuccess': 'Kiitos palautteestasi!',
  // 404
  'globals.404.title': '404 - Sivua ei löydy',
  'globals.404.intro': 'Hups! Löysit olemattoman reitin.',
  'globals.loading': 'Ladataan...',
  // footer / menu
  'globals.menu': 'Valikko',
  'globals.menu.about': 'Tietoa',
  'globals.menu.faq': 'UKK',
  'globals.menu.privacyPolicy': 'Tietosuojaseloste',
  'globals.menu.myProfile': 'Profiilini',
  'globals.menu.termsofService': 'Käyttöehdot',
  // Dashboard/Index
  'dashboard.title': 'Tervetuloa kurssille!',
  'dashboard.congratulations.title': 'Onnittelut!',
  'dashboard.congratulations.message': 'Olet läpäissyt Elements of AI -kurssin',
  'dashboard.congratulations.downloadCertificate': 'Lataa sertifikaatti',
  'dashboard.notLoggedIn.title': 'Tervetuloa!',
  'dashboard.notLoggedIn.message': 'Rekisteröidy tai kirjaudu sisään saadaksesi kurssimateriaalin kokonaan näkyviin. Kurssi on maksuton!',
  // Account
  'account.certificate': 'Sertifikaatti',
  'account.credits': 'Opintopisteet',
  'account.myProfile': 'Oma profiili',
  'account.changePassword': 'Vaihda salasana',
  'account.changeLanguage': 'Vaihda kieltä',
  'account.profileInformation': 'Profiilin tiedot',
  'account.goToCourse': 'Siirry kurssille',
  'account.buildingAINew': 'Uusi englanninkielinen kurssi',
  'account.accept': 'Hyväksy',
  'account.decline': 'Hylkää',
  'account.courses': 'Kurssit',
  // Sign Up page
  'signUp.description': 'Luo uusi mooc.fi-tili täyttämällä tiedot alle',
  'signUp.email': 'Sähköpostiosoitteesi',
  'signUp.firstName': 'Etunimesi',
  'signUp.lastName': 'Sukunimesi',
  'signUp.postalCode': 'Postinumero',
  'signUp.country': 'Maa',
  'signUp.Password': 'Salasana',
  'signUp.confirmPassword': 'Salasana uudelleen',
  'signUp.howToStudyTitle': 'Kuinka haluat opiskella?',
  'signUp.6weeksTitle': '6 viikon kurssi',
  'signUp.6weeksSubTitle': 'Suositeltu (noin 5 h viikossa)',
  'signUp.6weeksText': 'Määräajat ovat todistetusti hyödyksi, kun haluat saada kurssin valmiiksi.',
  'signUp.selfPacedTitle': 'Aikarajaton',
  'signUp.selfPacedText': 'Voit tehdä kurssin omaan tahtiin.',
  'signUp.ectsTitleSwe': 'Would you like to apply for ECTS credits? ',
  'signUp.ectsTitle': 'Aiotko hakea opintopisteitä?',
  'signUp.ectsStudent': 'Olen opiskelija Helsingin yliopistossa. Jos valitset tämän vaihtoehdon ja annat opiskelijanumerosi, saat opintopisteet automaattisesti.',
  'signUp.ectsStudentSwe': 'Suoritettuasi kurssin voit hakea opintopisteitä Linköpingin yliopistosta. Löydät ohjeet ',
  'signup.ectsStudentSweLink': 'täältä',
  'signUp.ectsOpenUni': 'Valitsemalla tämän vaihtoehdon saat sähköpostiisi ohjeet opintopisteiden hakemiseen',
  'signUp.studentNumber': 'Helsingin yliopiston opiskelijanumero (jos olet opiskelija)',
  'signUp.privacyOptIn': 'Kurssisuorituksestani saa kerätä tietoa tutkimustarkoituksiin. Tietoa kerätään materiaalin käytöstä ja kurssien tehtävistä. Tutkimustuloksista ei pystytä tunnistamaan yksittäisiä opiskelijoita.',
  'signUp.marketingOptIn': 'Minulle saa lähettää sähköpostiin tietoa uusista kursseista. Suostun jakamaan yhteystietoni saadakseni räätälöityjä viestejä kolmansien osapuolten alustoille. Lue lisää tietosuojaselosteestamme.',
  'signUp.privacyLink': 'Tietosuojaseloste',
  'signUp.error': 'Lomakkeessa on virheitä. Ole hyvä ja korjaa tiedot.',
  'signUp.acceptPolicyFirst': 'Hyväksyn tietosuojaselosteen ja käyttöehdot.',
  // Sign in page
  'signIn.title': 'Kirjaudu sisään',
  'signIn.intro': 'Kirjaudu tästä sisään mooc.fi-tunnuksillasi',
  'signIn.email': 'Sähköpostiosoitteesi',
  'signIn.password': 'Salasanasi',
  'signIn.noAccount': 'Eikö sinulla ole tiliä?',
  'signIn.signUpNow': 'Rekisteröidy nyt',
  // Forgot password
  'pwForget.title': 'Unohtunut salasana',
  'pwForget.description': 'Anna mooc.fi-tiliisi liitetty sähköpostiosoite, ja lähetämme sinulle salasanan nollauslinkin.',
  'pwForget.sendResetLink': 'Lähetä nollauslinkki',
  'pwForget.completedDescription': 'Saat pian mooc.fi:ltä sähköpostin, jossa on salasanasi nollauslinkki. Jos postia ei kuulu, tarkista roskapostikansiosi.',
  'pwForget.problemDescription': 'Ongelmatapauksissa ota yhteyttä mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent': 'Nollauslinkki lähetetty',
  'pwForget.emailPlaceholder': 'Sähköpostiosoite',
  // password change
  'pwChange.authError': 'Tunnistus epäonnistui. Yritä kirjautua sisään uudestaan',
  'pwChange.unexpError': 'Salasanan päivitys epäonnistui tuntemattomasta syystä',
  'pwChange.required': 'Vaaditaan',
  'pwChange.pwMustMatch': 'Salasanojen on oltava samoja',
  'pwChange.currentPassword': 'Nykyinen salasana',
  'pwChange.newPassword': 'Uusi salasana',
  'pwChange.confirmPassword': 'Vahvista uusi salasana',
  'pwChange.changingPassword': 'Vaihdetaan salasanaa...',
  'pwChange.changePassword': 'Vaihda salasana',
  'pwChange.passwordChanged': 'Salasana vaihdettu',
  // user details
  'userDetails.authError': 'Tunnistus epäonnistui. Yritä kirjautua sisään uudestaan',
  'userDetails.unexpError': 'Käyttäjätietojen päivitys epäonnistui tuntemattomasta syystä',
  'userDetails.required': 'Vaaditaan',
  'userDetails.invalidEmail': 'Virheellinen sähköpostiosoite',
  'userDetails.email': 'Sähköposti',
  'userDetails.emailAddress': 'Sähköpostiosoite',
  'userDetails.firstName': 'Etunimi',
  'userDetails.lastName': 'Sukunimi',
  'userDetails.language': 'Kieli',
  'userDetails.updatingProfile': 'Päivitetään profiilia...',
  'userDetails.updateProfile': 'Päivitä profiili',
  'userDetails.profileUpdated': 'Profiili päivitetty',
  // languages
  'languages.en.long': 'englanti',
  'languages.fi.long': 'suomi',
  'languages.se.long': 'ruotsi',
  'languages.de.long': 'saksa (Saksa)',
  'languages.ee.long': 'viro',
  'languages.it.long': 'italia',
  'languages.fr.long': 'ranska (Ranska)',
  'languages.no.long': 'norja',
  'languages.el.long': 'kreikka',
  'languages.pt.long': 'portugali',
  'languages.nl.long': 'hollanti (Alankomaat)',
  'languages.sk.long': 'slovakia',
  'languages.cs.long': 'tšekki',
  'languages.lv.long': 'latvia',
  'languages.da.long': 'tanska',
  'languages.ro.long': 'romania',
  'languages.pl.long': 'puola',
  'languages.en-ie.long': 'englanti (Irlanti)',
  'languages.mt.long': 'malta',
  'languages.fr-be.long': 'ranska (Belgia)',
  'languages.lt.long': 'liettua',
  'languages.nl-be.long': 'hollanti (Belgia)',
  'languages.hr.long': 'kroatia',
  'languages.de-at.long': 'saksa (Itävalta)',
  'languages.en-lu.long': 'englanti (Luxemburg)',
  'languages.bg.long': 'bulgaria',
  'languages.es.long': 'espanja',
  'languages.ga.long': 'iiri',
  'languages.sl.long': 'slovenia',
  'languages.is.long': 'islanti',
  // Email verification
  'emailVerification.completedMessage': 'Sähköpostiosoitteesi on varmistettu.',
  'emailVerification.verified': 'Varmistettu',
  // Exercises
  'exercise.loading': 'Ladataan tehtävää...',
  'exercise.signUp': 'Rekisteröidy vastataksesi tehtäviin',
  'exercise.score': 'Pisteesi:',
  'exercise.wrongLocale': 'Väärä kielialue',
  // Part tempplate
  'partTemplate.intro1': 'Luku ',
  'partTemplate.intro2': ' käsittää seuraavat kappaleet. Valitse kappale alta:',
  'partTemplate.spectrumPart1': 'Keskustele ja esitä kysymyksiä tästä luvusta ',
  'partTemplate.spectrum': 'Elements of AI -yhteisössä',
  'partTemplate.spectrumPart2': '.',
  // missing info
  'missingInfo.title': 'Puuttuvat tiedot',
  'missingInfo.body': 'Yleensä kysymme näitä tietoja rekisteröinnin yhteydessä, mutta kirjauduit sisään olemassaolevalla mooc.fi -tilillä. Ole hyvä ja täytä tiedot jatkaaksesi.',
  'missingInfo.privacyPolicy': 'Tietosuojaseloste',
  'missingInfo.error': 'Lomakkeessa on virheitä. Ole hyvä ja tarkista tiedot.',
  'missingInfo.privacyPolicyUpdateBody': "Olemme päivittäneet tietosuojaselosteemme. Ohessa on yhteenveto muutoksista. Mainostamme kursseja potentiaalisille uusille opiskelijoille muodostamalla yleisöjä markkinointialustoille. Tähän voi sisältyä sähköpostiosoitteiden jakaminen näiden alustojen kanssa. Samaa tarkoitusta varten jaamme markkinointiverkostoille anonyymia tietoa käyttäjien toiminnasta sivustolla. Voit estää sähköpostitietojen jakamisen poistamalla rastin profiilisivulla olevasta markkinointiluvasta. Toimintatietojen jakamisen voit estää muuttamalla evästeasetuksia kurssisivustolla. Palvelun käytön jatkaminen edellyttää näiden ehtojen hyväksymistä.",
  'missingInfo.privacyPolicyUpdateLink': 'Voit lukea tietosuojaselosteen täältä.',

  // routes
  'routes.LANDING': '/fi/',
  'routes.SIGN_UP': '/fi/signup',
  'routes.SIGN_IN': '/fi/signin',
  'routes.MISSING_INFO': '/fi/missinginfo',
  'routes.PASSWORD_FORGET': '/fi/pw-forget',
  'routes.HOME': '/fi/home',
  'routes.ACCOUNT': '/fi/account',
  'routes.PRIVACY': 'https://www.elementsofai.com/fi/faq/tietosuojak%C3%A4yt%C3%A4nt%C3%B6',
  'routes.FAQ': 'https://www.elementsofai.com/fi/faq',
  'routes.ABOUT': 'https://www.elementsofai.com/fi',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/fi-suomenkielinen-yhteiso',
  'routes.ects': 'https://mooc.fi/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/fi/faq/miten-saan-opintopisteet',
  'routes.ACCEPT_POLICY': '/fi/accept-policy',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'kahvikupillisia päivässä',
  'markdowncomponents.linearRegressionStatic.yLabel': 'koodirivejä päivässä',
  // meta
  'meta.title': 'Ilmainen verkkokurssi',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Lue lisää Helsingin yliopiston ja Reaktorin tekoälykurssista, joka soveltuu sekä opiskelijoille että ammattilaisille - ei vaadi ohjelmointitaitoa tai monimutkaista matematiikkaa',
  'meta.sitename': 'Elements of AI',
  'meta.title.og': 'A free online introduction to artificial intelligence for non-experts',
  // ECTS
  'ects.text': 'Saat tähän ohjeet opintopisteiden hakemiseen suoritettuasi kurssin.',
  'ects.textCompleted': 'Voit hakea opintopisteitä klikkaamalla ',
  'ects.link': 'tästä.',
  'ects.info': 'Voit hakea Avoimen yliopiston opintopisteitä!',
  'ects.readMore': 'Lue lisää',
  // Countries
  'countryselect.select': "Please select",
  'Sweden': "Ruotsi",
  'Estonia': "Viro",
  'Finland': "Suomi",
  'Germany': "Saksa",
  'Norway': "Norja",
  "Latvia": "Latvia",
  "Netherlands": "Alankomaat",
  "Italy": "Italia",
  'France': 'Ranska',
  "Countries": {
    'Afganistan': "Afganistan",
    'Åland Islands': "Ahvenanmaa",
    'Albania': "Albania",
    'Algeria': "Algeria",
    'American Samoa': "Amerikan Samoa",
    'Andorra': "Andorra",
    'Angola': "Angola",
    'Anguilla': "Anguilla",
    'Antarctica': "Antarktis",
    'Antigua and Barbuda': "Antigua ja Barbuda",
    'Argentina': "Argentiina",
    'Armenia': "Armenia",
    'Aruba': "Aruba",
    'Australia': "Australia",
    'Austria': "Itävalta",
    'Azerbaijan': "azerbaijan",
    'Bahamas': "Bahama",
    'Bahrain': "Bahrain",
    'Bangladesh': "Bangladesh",
    'Barbados': "Barbados",
    'Belarus': "Valko-Venäjä",
    'Belgium': "Belgia",
    'Belize': "Belize",
    'Benin': "Benin",
    'Bermuda': "bermuda",
    'Bhutan': "Bhutan",
    'Bolivia': "Bolivia",
    'Bosnia and Herzegovina': "Bosnia ja Hertsegovina",
    'Botswana': "Botswana",
    'Bouvet Island': "Bouvet Island",
    'Brazil': "Brasilia",
    'British Indian Ocean Territory': "Britannian Intian valtameren alue",
    'Brunei Darussalam': "Brunei Darussalam",
    'Bulgaria': "Bulgaria",
    'Burkina Faso': "Burkina Faso",
    'Burundi': "Burundi",
    'Cambodia': "Kambodza",
    'Cameroon': "Kamerun",
    'Canada': "Kanada",
    'Cape Verde': "Kap Verde",
    'Cayman Islands': "Caymansaaret",
    'Central African Republic': "Keski-Afrikan tasavalta",
    'Chad': "Chad",
    'Chile': "Chile",
    'China': "Kiina",
    'Christmas Island': "Joulusaari",
    'Cocos (Keeling) Islands': "Kookossaaret (Keelingsaaret)",
    'Colombia': "Kolumbia",
    'Comoros': "Komorit",
    'Congo': "Kongo",
    'Congo, The Democratic Republic of The': "Kongon demokraattinen tasavalta",
    'Cook Islands': "Cookinsaaret",
    'Costa Rica': "Costa Rica",
    "Cote D'ivoire": "Norsunluurannikko",
    'Croatia': "Kroatia",
    'Cuba': "Kuuba",
    'Cyprus': "Kypros",
    'Czech Republic': "Tšekin tasavalta",
    'Denmark': "Tanska",
    'Djibouti': "Djibouti",
    'Dominica': "dominica",
    'Dominican Republic': "Dominikaaninen tasavalta",
    'Ecuador': "Ecuador",
    'Egypt': "Egypti",
    'El Salvador': "El Salvador",
    'Equatorial Guinea': "Päiväntasaajan Guinea",
    'Eritrea': "Eritrea",
    'Estonia': "Viro",
    'Ethiopia': "Etiopia",
    'Falkland Islands (Malvinas)': "Falklandinsaaret (Malvinas)",
    'Faroe Islands': "Färsaaret",
    'Fiji': "Fidži",
    'Finland': "Suomi",
    'France': "Ranska",
    'French Guiana': "Ranskan Guayana",
    'French Polynesia': "Ranskan Polynesia",
    'French Southern Territories': "Ranskan eteläiset alueet",
    'Gabon': "Gabon",
    'Gambia': "Gambia",
    'Georgia': "Georgia",
    'Germany': "Saksa",
    'Ghana': "Ghana",
    'Gibraltar': "Gibraltar",
    'Greece': "Kreikka",
    'Greenland': "Grönlanti",
    'Grenada': "Grenada",
    'Guadeloupe': "Guadeloupe",
    'Guam': "Guam",
    'Guatemala': "Guatemala",
    'Guernsey': "Guernsey",
    'Guinea': "Guinea",
    'Guinea-Bissau': "Guinea-Bissau",
    'Guyana': "Guyana",
    'Haiti': "Haiti",
    'Heard Island and Mcdonald Islands': "Heard Island ja Mcdonald Islands",
    'Holy See (Vatican City State)': "Pyhä istuin (Vatikaanivaltio)",
    'Honduras': "Honduras",
    'Hong Kong': "Hongkong",
    'Hungary': "Unkari",
    'Iceland': "Islanti",
    'India': "Intia",
    'Indonesia': "Indonesia",
    'Iran, Islamic Republic of': "Iran, Islamin tasavalta",
    'Iraq': "Irak",
    'Ireland': "Irlanti",
    'Isle of Man': "Mansaari",
    'Israel': "Israel",
    'Italy': "Italia",
    'Jamaica': "Jamaika",
    'Japan': "Japani",
    'Jersey': "Jersey",
    'Jordan': "Jordan",
    'Kazakhstan': "Kazakstan",
    'Kenya': "Kenia",
    'Kiribati': "Kiribati",
    "Korea, Democratic People's Republic of": "Korea, Korean demokraattinen kansantasavalta",
    'Korea, Republic of': "Korean tasavalta",
    'Kosovo': 'Kosovo',
    'Kuwait': "Kuwait",
    'Kyrgyzstan': "Kirgisia",
    "Lao People's Democratic Republic": "Laosin demokraattinen kansantasavalta",
    'Latvia': "Latvia",
    'Lebanon': "Libanon",
    'Lesotho': "lesotho",
    'Liberia': "Liberia",
    'Libyan Arab Jamahiriya': "Libya",
    'Liechtenstein': "Liechtenstein",
    'Lithuania': "Liettua",
    'Luxembourg': "Luxemburg",
    'Macao': "Macao",
    'Macedonia, The Former Yugoslav Republic of': "Pohjois-Makedonia",
    'Madagascar': "Madagaskar",
    'Malawi': "Malawi",
    'Malaysia': "Malesia",
    'Maldives': "Malediivit",
    'Mali': "Mali",
    'Malta': "Malta",
    'Marshall Islands': "Marshallsaaret",
    'Martinique': "Martinique",
    'Mauritania': "Mauritania",
    'Mauritius': "Mauritius",
    'Mayotte': "Mayotte",
    'Mexico': "Meksiko",
    'Micronesia, Federated States of': "Mikronesian liittotasavallat",
    'Moldova, Republic of': "Moldovan tasavalta",
    'Monaco': "Monaco",
    'Mongolia': "Mongolia",
    'Montenegro': "Montenegro",
    'Montserrat': "Montserrat",
    'Marocco': "Marokko",
    'Mozambique': "Mosambik",
    'Myanmar': "Myanmar",
    'Namibia': "Namibia",
    'Nauru': "Nauru",
    'Nepal': "Nepal",
    'Netherlands': "Alankomaat",
    'Netherlands Antilles': "Alankomaiden Antillit",
    'New Caledonia': "Uusi-Kaledonia",
    'New Zealand': "Uusi-Seelanti",
    'Nicaragua': "Nicaragua",
    'Niger': "Niger",
    'Nigeria': "Nigeria",
    'Niue': "Niue",
    'Norfolk Island': "Norfolkin saari",
    'Northern Mariana Islands': "Pohjois-Mariaanit",
    'Norway': "Norja",
    'Oman': "Oman",
    'Pakistan': "Pakistan",
    'Palau': "Palau",
    'Palestinian Territory, Occupied': "Palestiinan alue, miehitetty",
    'Panama': "Panama",
    'Papua New Guinea': "Papua-Uusi-Guinea",
    'Paraguay': "Paraguay",
    'Peru': "Peru",
    'Philippines': "Filippiinit",
    'Pitcairn': "Pitcairn",
    'Poland': "Puola",
    'Portugal': "Portugali",
    'Puerto Rico': "Puerto Rico",
    'Qatar': "Qatar",
    'Reunion': "Réunion",
    'Romania': "Romania",
    'Russian Federation': "Venäjä",
    'Rwanda': "Ruanda",
    'Saint Helena': "Saint Helena",
    'Saint Kitts and Nevis': "Saint Kitts ja Nevis",
    'Saint Lucia': "Saint Lucia",
    'Saint Pierre and Miquelon': "Saint Pierre ja Miquelon",
    'Saint Vincent and The Grenadines': "Saint Vincent ja Grenadiinit",
    'Samoa': "Samoa",
    'San Marino': "San Marino",
    'Sao Tome and Principe': "São Tomé ja Príncipe",
    'Saudi Arabia': "Saudi-Arabia",
    'Senegal': "Senegal",
    'Serbia': "Serbia",
    'Seychelles': "Seychellit",
    'Sierra Leone': "Sierra Leone",
    'Singapore': "Singapore",
    'Slovakia': "Slovakia",
    'Slovenia': "Slovenia",
    'Solomon Islands': "Salomoninsaaret",
    'Somalia': "Somalia",
    'South Africa': "Etelä-Afrikka",
    'South Georgia and The South Sandwich Islands': "Etelä-Georgia ja Eteläiset Sandwichsaaret",
    'Spain': "Espanja",
    'Sri Lanka': "Sri Lanka",
    'Sudan': "Sudan",
    'Suriname': "Suriname",
    'Svalbard and Jan Mayen': "Svalbard ja Jan Mayen",
    'Swaziland': "Swazimaa",
    'Sweden': "Ruotsi",
    'Swizerland': "Sveitsi",
    'Syrian Arab Republic': "Syyria",
    'Taiwan': "Taiwan",
    'Tajikistan': "Tadžikistan",
    'Tanzania, United Republic of': "Tansanian yhdistynyt tasavalta",
    'Thailand': "Thaimaa",
    'Timor-leste': "Itä-Timor",
    'Togo': "Togo",
    'Tokelau': "Tokelau",
    'Tonga': "Tonga",
    'Trinidad and Tobago': "Trinidad ja Tobago",
    'Tunisia': "Tunisia",
    'Turkey': "Turkki",
    'Turkmenistan': "Turkmenistan",
    'Turks and Caicos Islands': "Turks- ja Caicossaaret",
    'Tuvalu': "Tuvalu",
    'Uganda': "Uganda",
    'Ukraine': "Ukraina",
    'United Arab Emirates': "Yhdistyneet arabiemiirikunnat",
    'United Kingdom': "Yhdistynyt kuningaskunta",
    'United States': "Yhdysvallat",
    'United States Minor Outlying Islands': "Yhdysvaltain pienet erillissaaret",
    'Uruguay': "Uruguay",
    'Uzbekistan': "Uzbekistan",
    'Vanuatu': "Vanuatu",
    'Venezuela': "Venezuela",
    'Viet Nam': "Vietnam",
    'Virgin Islands, British': "Brittiläiset Neitsytsaaret",
    'Virgin Islands, U.S.': "Yhdysvaltain Neitsytsaaret",
    'Wallis and Futuna': "Wallis ja Futuna",
    'Western Sahara': "Länsi-Sahara",
    'Yemen': "Jemen",
    'Zambia': "Sambia",
    'Zimbabwe': "Zimbabwe"
  },
  //Alt texts
  'alt.example-1': 'kaksi itseohjautuvaa autoa',
  'alt.example-2': 'käsi jossa on matkapuhelin',
  'alt.example-3': 'naisen kasvot',
  'alt.defining-ai': 'joukko tekoälyyn liittyviä sanoja',
  'alt.turing-test': 'mies chattaa tietokoneella naisen ja robotin kanssa',
  'alt.chinese-room': 'kiinalaisen huoneen asetelma',
  'alt.chicken-crossing': 'robotti veneessä katsoo kettua, kanaa ja jyväpussia',
  'alt.a-to-b': 'kädessä puhelin, jossa on auki navigaatio-ohjelma',
  'alt.tic-tac-toe': 'kaksi lasta pelaa ristinollaa',
  'alt.self-driving-car': 'nainen kävelee itseohjautuvan auton edestä',
  'alt.spam-or-ham': 'tavallinen sähköposti ja roskaposti',
  'alt.mnist': 'MNIST-datajoukko',
  'alt.nearest-neighbor': 'kaksi ihmistä ostoksilla',
  'alt.nearest-neighbor-graph': 'lähimmän naapurin luokitin',
  'alt.recommendation': 'mies ja nainen kuuntelevat musiikkia puhelimiltaan',
  'alt.price-real-estate': 'kolme erilaista taloa',
  'alt.supervised-learning': 'ihminen opettaa robottia',
  'alt.dice': 'noppia',
  'alt.chess': 'shakkilauta',
  'alt.poker': 'pelikortteja kädessä',
  'alt.eyes': 'kolme ruskeasilmäistä ja yksi sinisilmäinen ihminen',
  'alt.search-1': 'yksi maaliin vievä polku',
  'alt.search-2': 'neljä eri polkua vie samaan maaliin',
  'alt.chicken-crossing-1': 'joenylitysongelman lähtötila',
  'alt.chicken-crossing-2': 'joenylitysongelman ensimmäinen siirtymä',
  'alt.chicken-crossing-3': 'joenylitysongelman kaikki siirtymät',
  'alt.chicken-crossing-4': 'joenylistysongelman paras reitti',
  'alt.chicken-crossing-5': 'joenylitysongelman lähtötila',
  'alt.implication-1': 'mies ja nainen',
  'alt.implication-2': 'silmät',
  'alt.implication-3': 'valvontakamera',
  'alt.implication-4': 'robotti työskentelee laptopilla',
  'alt.oddchange': 'aurinko ja pilviä',
  'alt.brain': 'piirroskuva aivoista',
  'alt.carstop': 'itseohjautuva auto stop-merkin luona',
  'alt.gan': 'robotti pitelee kuvaa kissasta ja stop-merkistä',
  'alt.hammock': 'robotti leyhyttää viuhkaa, kun mies makaa riippukeinussa',
  'alt.game-tree-1': 'pelipuu 1',
  'alt.game-tree-2': 'pelipuu 2',
  'alt.game-tree-3': 'pelipuu 3',
  'alt.game-tree-4': 'pelipuu 4',
  'alt.bayes-rule-1': 'vääriä positiivisia ja negatiivisia tuloksia syöpäseulonnassa',
  'alt.bayes-rule-2': 'vääriä positiivisia ja negatiivisia tuloksia syöpäseulonnassa',
  'alt.defineai': 'joukko tekoälyyn liittyviä sanoja',
  'alt.winter': 'robotti nukkuu lumessa',
  'alt.terminator': 'pelottava robotti'
}
