import * as React from "react";
import styled from "styled-components";

export default props => {
  const StyledSubmitButton = styled.button`
    font-size: 1rem;
    outline: none !important;
    font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
    color: ${props =>
      props.disabled ? "rgba(255,255,255,0.5)" : "rgba(255,255,255,1)"};
    line-height: 2rem;
    border-radius: 2rem;
    background: #d36bd3;
    border: none;
    width: 200px;
    opacity: ${props => (props.disabled ? "0.5" : "1")};
    transition: all 0.2s ease;
    margin: 0;
    display: block;
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.04);
    max-width: 150px !important;
    padding: 0.75rem 1rem;
    @media screen and (min-width: 72em) {
      max-width: 200px !important;
    }
    :hover {
      cursor: pointer;
    }
  `;

  return <StyledSubmitButton {...props} />;
};
