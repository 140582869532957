// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'pl',
  'globals.signIn': 'Zaloguj się',
  'globals.logIn': 'Zaloguj się',
  'globals.signUp': 'Zarejestruj się',
  'globals.signOut': 'Wylogowanie',
  'globals.logOut': 'Wylogowanie',
  'globals.submit': 'Prześlij',
  'globals.submitting': 'Wysyłanie...',
  'globals.yourEmail': 'E-mail',
  'globals.yourPassword': 'Hasło',
  'globals.courseOverview': 'Przegląd treści kursu',
  'globals.forgotPassword': 'Nie pamiętasz hasła?',
  'globals.invalidCredentials': 'Nieprawidłowe dane uwierzytelniające',
  'globals.section': 'Sekcja',
  'globals.exercises': 'Ćwiczenia',
  'globals.chapter': 'Rozdział',
  'globals.introToAI': 'Wprowadzenie do sztucznej inteligencji',
  'globals.buildingAI': 'Tworzenie sztucznej inteligencji',
  'globals.acceptPolicy': 'Accept policy',
  // section
  'section.footerEndOfTheChapter': 'Koniec rozdziału',
  'section.footerEndOfTheCourseTitle': 'Koniec kursu!',
  'section.footerEndOfTheCourseText': 'Po zakończeniu wszystkich ćwiczeń i ocen Twoje odpowiedzi zostaną poddane ocenie przez nasz personel.',
  'section.footerEndOfTheCourseBAI': 'Kontynuuj swoją podróż do realizacji swojego pierwszego pomysłu na sztuczną inteligencję.',
  'section.nextSection': 'Następna sekcja',
  'section.nextChapter': 'Następny rozdział',
  'section.start': 'Początek',
  'section.logisticRegressionExample.slope': 'Nachylenie',
  'section.logisticRegressionExample.intercept': 'Punkt przecięcia',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Oczekuje na Twoją ocenę',
  'quiz.rejectedPeerReview': 'Odrzucono podczas oceny',
  'quiz.unsubmittedAnswer': 'Odpowiedź nie została przesłana',
  'quiz.pendingPeerReview': 'Oczekiwanie na ocenę',
  // quiz
  'quiz.status.answered': 'Odpowiedź przesłana',
  'quiz.status.rejected': 'Odpowiedź odrzucona, spróbuj ponownie',
  'quiz.status.unanswered': 'Brak odpowiedzi',
  'quiz.status.exercisesCompleted': 'Wykonane ćwiczenia',
  'quiz.status.correctAnswers': 'Prawidłowe odpowiedzi',
  'quiz.message.rejectedInReview': 'Poprzednia odpowiedź została odrzucona podczas oceny. Spróbuj ponownie.',
  'quiz.youAnswered': 'Twoja odpowiedź to',
  'quiz.yourAnswer': 'Twoja odpowiedź...',
  'quiz.yourScore': 'Twój wynik:',
  'quiz.quizNotLoaded': 'Nie udało się załadować quizu',
  'quiz.words': 'Liczba słów:',
  'quiz.exampleAnswer': 'Przykładowa odpowiedź:',
  // peer review
  'peerreview.question1': 'Odpowiedź jest na temat',
  'peerreview.question2': 'Odpowiedź jest wyczerpująca',
  'peerreview.question3': 'Odpowiedź jest dobrze uzasadniona',
  'peerreview.question4': 'Odpowiedź jest zrozumiała',
  'peerreview.thankYou': 'Dziękujemy za dokonanie oceny!',
  'peerreview.giveMoreReviews': 'Jeśli masz czas,',
  'peerreview.giveMoreReviewsButton': 'przeprowadź większą liczbę ocen',
  'peerreview.notEnoughAnswers': 'W zasadzie w tym miejscu powinna być odpowiedź innego użytkownika do oceny, ale niewystarczająca liczba innych osób udzieliła odpowiedzi na to pytanie.',
  'peerreview.selectAnswerMessage': 'Czas na ocenę! Poniżej znajdziesz kilka odpowiedzi innych uczestników. Wybierz jedną z nich i dokonaj oceny. Powtórz co najmniej trzy razy.',
  'peerreview.minimumPeerReviewWarning': 'Rozwiązania ćwiczeń zostaną przyjęte dopiero wówczas, gdy ocenisz trzy odpowiedzi innych użytkowników.',
  'peerreview.select': 'Wybierz',
  'peerreview.reportSpam': 'Zgłoś spam',
  'peerreview.feedback': 'Odpowiedź została oceniona i przyjęta. Oto, co sądzą o niej inni uczestnicy:',
  // exercise17
  'quiz.exercise17.xLabel': 'Lata edukacji',
  'quiz.exercise17.yLabel': 'Oczekiwana długość życia w latach',
  'quiz.exercise17.textPart1': 'Przyjrzyjmy się związkowi między łączną liczbą dni spędzonych w placówkach oświatowych (od przedszkola po uniwersytet) a oczekiwaną długością życia. Poniżej przedstawiamy dane pochodzące z trzech różnych państw, które naniesiono na wykres w postaci punktów:',
  'quiz.exercise17.textPart2': 'W pierwszym państwie średnia liczba lat spędzanych w szkole wynosi 10, a oczekiwana długość życia to 57 lat, w drugim średnia liczba lat spędzanych w szkole wynosi 13, a oczekiwana długość życia to 53 lata, natomiast w trzecim średnia liczba lat spędzanych w szkole wynosi 20, a oczekiwana długość życia to 80 lat.',
  'quiz.exercise17.textPart3': 'Możesz przeciągnąć punkty końcowe linii ciągłej, aby zmienić jej położenie w taki sposób, by odpowiadała tendencji wyznaczonej przez punkty danych. Pamiętaj, że nie uda Ci się idealnie dopasować przebiegu linii do punktów danych – nie przejmuj się tym: niektóre punkty danych będą znajdowały się powyżej, a inne poniżej linii. Najważniejsze, aby linia opisywała ogólną tendencję.',
  'quiz.exercise17.textPart4': 'Umieszczenie linii w odpowiednim miejscu na wykresie pozwoli przewidzieć oczekiwaną długość życia.',
  'quiz.exercise17.textPart5': 'Jakie wnioski dotyczące oczekiwanej długości życia osób, które kształciły się przez 15 lat, można wyciągnąć na podstawie udostępnionych danych? Ważne: należy pamiętać, że nawet w przypadku uzyskania prognozy z dokładnością do ułamka roku za sprawą dostosowania przebiegu linii, taka prognoza niekoniecznie musi być w pełni wiarygodna. Przy udzielaniu odpowiedzi weź pod uwagę ograniczoną ilość dostępnych danych.',
  // exercise18
  'quiz.exercise18.xLabel': 'Lata edukacji',
  'quiz.exercise18.yLabel': 'Oczekiwana długość życia w latach',
  'quiz.exercise18.textPart1': 'W poprzednim ćwiczeniu dysponowaliśmy wyłącznie danymi dotyczącymi trzech państw. Pełny zbiór danych obejmuje dane z 14 różnych państw przedstawione na poniższym wykresie:',
  'quiz.exercise18.textPart2': 'Czy udostępnienie tych dodatkowych danych wpłynęłoby na zmianę Twojej prognozy dotyczącej oczekiwanej długości życia po 15 latach kształcenia? Jeśli tak, dlaczego?',
  'quiz.exercise18.textPart3': 'Która z poniższych odpowiedzi najlepiej odpowiadałaby Twoim szacunkom dotyczącym oczekiwanej długości życia osób, które kształciły się przez 15 lat? Wybierz odpowiedź, którą uważasz za najbardziej uzasadnioną po dopasowaniu linii prostej do powyższych danych.',
  // exercise19
  'quiz.exercise19.xLabel': 'Liczba godzin nauki',
  'quiz.exercise19.yLabel': 'Prawdopodobieństwo zdania egzaminu',
  'quiz.exercise19.textPart1': 'Każdy punkt na wykresie odpowiada jednemu studentowi. Na osi u dołu wykresu zaznaczono liczbę godzin, jaką dany student poświęcił na naukę do egzaminu, a studenci, którzy zdali egzamin, zostali zaznaczeni w formie kropek w górnej części wykresu; studenci, którzy nie zdali egzaminu, znajdują się w dolnej części wykresu. W celu określenia przewidywanego prawdopodobieństwa zdania egzaminu skorzystamy ze skali po lewej stronie, którą uzyskamy z omówionego poniżej modelu regresji logistycznej. Na podstawie tego wykresu można wstępnie stwierdzić, że studentom, którzy poświęcili więcej czasu na naukę, częściej udawało się zaliczyć kurs. Szczególnie zgodne z tym, co podpowiada intuicja są w tej sytuacji przypadki skrajne: poświęcając mniej niż godzinę na naukę bardzo trudno jest zaliczyć kurs, ale osoby, które włożą w przygotowania dużo wysiłku, zazwyczaj odniosą sukces. Co jednak z osobami, które poświęciły na przygotowania ilość czasu mieszczącą się pomiędzy tymi skrajnymi wartościami? Jakie szanse na zdanie egzaminu ma osoba, która uczyła się przez 6 godzin?',
  'quiz.exercise19.textPart2': 'Prawdopodobieństwo zaliczenia możemy wyrazić w ujęciu ilościowym, korzystając z regresji logistycznej. Krzywą na wykresie można interpretować jako prawdopodobieństwo zdania egzaminu: na przykład w przypadku poświęcenia pięciu godzin na naukę prawdopodobieństwo zdania wynosi niewiele ponad 20%. Nie będziemy w tym miejscu szczegółowo omawiali, w jaki sposób uzyskać tę krzywą, ale proces ten jest zbliżony do procesu obliczania wag w regresji liniowej.',
  'quiz.exercise19.textPart3': 'Bazując na informacjach przedstawionych na powyższym wykresie, ile godzin musiałby poświęcić na naukę student, aby mieć 80% szans na zdanie egzaminu?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Dane wejściowe',
  'quiz.exercise22.sigmoidYLabel': 'Sigmoidalne dane wyjściowe',
  'quiz.exercise22.stepXLabel': 'Dane wejściowe',
  'quiz.exercise22.stepYLabel': 'Dane wyjściowe funkcji schodkowej',
  'quiz.exercise22.identityXLabel': 'Dane wejściowe',
  'quiz.exercise22.identityYLabel': 'Tożsamościowe dane wyjściowe',
  'quiz.exercise22.textPart1': 'Poniżej zamieszczono wykresy przedstawiające trzy różne funkcje aktywacji o różnych właściwościach. Pierwszą z nich jest funkcja sigmoidalna, drugą funkcja schodkowa, a trzecią – funkcja tożsamościowa.',
  // exercise23
  'quiz.exercise23.happyFaces': 'emotikony z uśmiechniętą twarzą sklasyfikowano prawidłowo',
  'quiz.exercise23.sadFaces': 'emotikony ze smutną twarzą sklasyfikowano prawidłowo',
  // certificate availability
  'certificate.failedToCheck': 'Nie udało się sprawdzić dostępności certyfikatu',
  'certificate.failedToGenerate': 'Nie udało się wygenerować certyfikatu',
  'certificate.notYetAvailable': 'Twój certyfikat będzie dostępny w tym miejscu, gdy go oficjalnie zatwierdzimy. Może to zająć chwilę. Dziękujemy za cierpliwość!',
  'certificate.updateName': 'Twój certyfikat jest dostępny! Prosimy o aktualizację imienia i nazwiska, które mają być widoczne na certyfikacie, za pomocą formularza dostępnego na stronie Twojego profilu.',
  'certificate.generate': 'Wygeneruj swój certyfikat poniżej.',
  'certificate.generateTextPart1': 'Certyfikat zostanie wygenerowany dla',
  'certificate.generateTextPart2': 'Przed wygenerowaniem certyfikatu upewnij się, że podano prawidłowe dane, gdyż później nie będzie można zmienić nazwiska. Możesz zmienić swoje nazwisko korzystając z formularza poniżej.',
  'certificate.generateButton': 'Wygeneruj certyfikat',
  'certificate.downloadButton': 'Pobierz certyfikat',
  'certificate.checkingAvailability': 'Sprawdzanie dostępności certyfikatu...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Prawidłowo sklasyfikowane',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Nieprawidłowo sklasyfikowane',
  // section blocks
  'section.block.note': 'Uwaga',
  'section.block.example': 'Przykład',
  'section.block.keyTerminology': 'Kluczowe terminy',
  'section.partSummary.spectrumTextPart1': 'Dołącz do ',
  'section.partSummary.spectrum': 'społeczności kursu Elements of AI',
  'section.partSummary.spectrumTextPart2': ' aby omawiać ten rozdział i zadawać pytania na jego temat.',
  'section.partSummary.feedbackTitle': 'Podziel się z nami swoją opinią na temat tej części',
  'section.partSummary.feedbackPlaceholder': 'Wpisz swoje uwagi...',
  'section.partSummary.feedbackSuccess': 'Dziękujemy za uwagi!',
  // 404
  'globals.404.title': '404 – Nie znaleziono strony',
  'globals.404.intro': 'Ta ścieżka nie istnieje... przykro nam.',
  'globals.loading': 'Ładowanie...',
  // footer / menu
  'globals.menu': 'Menu',
  'globals.menu.about': 'O kursie',
  'globals.menu.faq': 'Najczęściej zadawane pytania',
  'globals.menu.privacyPolicy': 'Polityka prywatności',
  'globals.menu.myProfile': 'Mój profil',
  'globals.menu.termsofService': 'Warunki usługi',
  // Dashboard/Index
  'dashboard.title': 'Witamy na kursie!',
  'dashboard.congratulations.title': 'Gratulujemy!',
  'dashboard.congratulations.message': 'Zakończono kurs Elements of AI',
  'dashboard.congratulations.downloadCertificate': 'Pobierz certyfikat',
  'dashboard.notLoggedIn.title': 'Witamy!',
  'dashboard.notLoggedIn.message': 'Zarejestruj się lub zaloguj, aby uzyskać pełny dostęp do kursu. Kurs jest darmowy!',
  // Account
  'account.certificate': 'Certyfikat',
  'account.credits': 'Punkty ECTS',
  'account.myProfile': 'Mój profil',
  'account.profileInformation': 'Informacje o profilu',
  'account.changePassword': 'Zmień hasło',
  'account.changeLanguage': 'Zmień język',
  'account.goToCourse': 'Przejdź do kursu',
  'account.buildingAINew': 'Nowy kurs w języku angielskim',
  'account.accept': 'Akceptuję',
  'account.decline': 'Nie akceptuję',
  'account.courses': 'Kursy',
  // Sign Up page
  'signUp.description': 'Wypełnij poniższy formularz, aby utworzyć nowe konto mooc.fi',
  'signUp.email': 'E-mail',
  'signUp.firstName': 'Imię',
  'signUp.lastName': 'Nazwisko',
  'signUp.postalCode': 'Kod pocztowy',
  'signUp.country': 'Gdzie mieszkasz?',
  'signUp.Password': 'Hasło',
  'signUp.confirmPassword': 'Potwierdź hasło',
  'signUp.howToStudyTitle': 'Jak chcesz się uczyć?',
  'signUp.6weeksTitle': 'Kurs sześciotygodniowy',
  'signUp.6weeksSubTitle': 'Zalecane (ok. 5 godz. tygodniowo)',
  'signUp.6weeksText': 'Wyznaczenie sobie terminu to sprawdzony sposób na skuteczne ukończenie kursu.',
  'signUp.selfPacedTitle': 'Własne tempo',
  'signUp.selfPacedText': 'Możesz realizować kurs we własnym tempie.',
  'signUp.ectsTitleSwe': ' ',
  'signUp.ectsTitle': 'Czy wnioskujesz o przyznanie punktów ECTS?',
  'signUp.ectsStudent': 'Studiuję na Uniwersytecie Helsińskim. Jeśli zaznaczysz to pole i podasz swój numer studenta, automatycznie otrzymasz punkty ECTS.',
  'signUp.ectsStudentSwe': ' ',
  'signup.ectsStudentSweLink': ' ',
  'signUp.ectsOpenUni': 'Zaznacz to pole, aby otrzymać e-mail z instrukcjami, w jaki sposób ubiegać się o punkty ECTS',
  'signUp.studentNumber': 'Numer studenta Uniwersytetu w Helsinkach (jeśli jesteś studentem/studentką)',
  'signUp.privacyOptIn': 'Wyrażam zgodę na wykorzystanie w celach naukowych zgromadzonych danych na temat przebiegu mojej nauki w trakcie kursu. Dane zawierają informacje pochodzące z wykorzystywanych materiałów oraz ćwiczeń będących elementem kursu. Publikacje nie pozwalają na identyfikację konkretnych osób.',
  'signUp.acceptPolicyFirst': 'Akceptuję Politykę prywatności i Warunki korzystania z usługi.',
  'signUp.marketingOptIn': 'Chcę otrzymywać informacje o nowych wersjach językowych i o nowych kursach. Wyrażam zgodę na udostępnianie moich danych kontaktowych w celu otrzymywania dostosowanych wiadomości na platformach stron trzecich. Przeczytaj więcej w naszej polityką ochrony prywatności.',
  'signUp.privacyLink': 'Zapoznaj się z naszą polityką ochrony prywatności',
  'signUp.error': 'Formularz zawiera błędy. Sprawdź formularz.',
  // Sign in page
  'signIn.title': 'Zaloguj się',
  'signIn.intro': 'Skorzystaj z poniższego formularza, aby zalogować się za pomocą swojego konta mooc.fi',
  'signIn.email': 'E-mail',
  'signIn.password': 'Hasło',
  'signIn.noAccount': 'Nie masz konta?',
  'signIn.signUpNow': 'Zarejestruj się teraz',
  // Forgot password
  'pwForget.title': 'Nie pamiętasz hasła?',
  'pwForget.description': 'Podaj swój adres e-mail na koncie mooc.fi, a prześlemy Ci link do zresetowania hasła.',
  'pwForget.sendResetLink': 'Wyślij link do zresetowania hasła',
  'pwForget.completedDescription': 'Wkrótce otrzymasz e-mail od mooc.fi zawierający link do zresetowania hasła. Jeśli go nie widzisz, sprawdź folder spam.',
  'pwForget.problemDescription': 'W razie jakichkolwiek problemów prosimy o kontakt pod adresem: mooc@cs.helsinki.fi',
  'pwForget.resetEmailSent': 'Wysłano e-mail z linkiem do zresetowania hasła',
  'pwForget.emailPlaceholder': 'Adres e-mail',
  // password change
  'pwChange.authError': 'Uwierzytelnienie nie powiodło się. Spróbuj zalogować się ponownie',
  'pwChange.unexpError': 'Nie udało się zaktualizować hasła z powodu nieoczekiwanego błędu',
  'pwChange.required': 'Wymagane',
  'pwChange.pwMustMatch': 'Hasła muszą być jednakowe',
  'pwChange.currentPassword': 'Obecne hasło',
  'pwChange.newPassword': 'Nowe hasło',
  'pwChange.confirmPassword': 'Potwierdź nowe hasło',
  'pwChange.changingPassword': 'Zmiana hasła...',
  'pwChange.changePassword': 'Zmień hasło',
  'pwChange.passwordChanged': 'Hasło zostało zmienione',
  // user details
  'userDetails.authError': 'Uwierzytelnienie nie powiodło się. Spróbuj zalogować się ponownie',
  'userDetails.unexpError': 'Nie udało się zaktualizować profilu z powodu nieoczekiwanego błędu',
  'userDetails.required': 'Wymagane',
  'userDetails.invalidEmail': 'Nieprawidłowy adres e-mail',
  'userDetails.email': 'E-mail',
  'userDetails.emailAddress': 'Adres e-mail',
  'userDetails.firstName': 'Imię',
  'userDetails.lastName': 'Nazwisko',
  'userDetails.language': 'Język',
  'userDetails.updatingProfile': 'Aktualizacja profilu...',
  'userDetails.updateProfile': 'Zaktualizuj profil',
  'userDetails.profileUpdated': 'Zaktualizowano profil',
  // languages
  'languages.en.long': 'angielski',
  'languages.fi.long': 'fiński',
  'languages.se.long': 'szwedzki',
  'languages.de.long': 'niemiecki (Niemcy)',
  'languages.ee.long': 'estoński',
  'languages.pl.long': 'polski',
  'languages.lv.long': 'łotewski',
  'languages.no.long': 'norweski',
  'languages.lt.long': 'litewski',
  'languages.ga.long': 'irlandzki',
  'languages.nl.long': 'niderlandzki (Holandia)',
  'languages.fr.long': 'francuski (Francja)',
  'languages.fr-be.long': 'francuski (Belgia)',
  'languages.nl-be.long': 'niderlandzki (Belgia)',
  'languages.it.long': 'włoski',
  'languages.mt.long': 'maltański',
  'languages.en-ie.long': 'angielski (Irlandia)',
  'languages.sk.long': 'słowacki',
  'languages.da.long': 'duński',
  'languages.ro.long': 'rumuński',
  'languages.hr.long': 'chorwacki',
  'languages.is.long': 'islandzki',
  'languages.de-at.long': 'niemiecki (Austria)',
  'languages.en-lu.long': 'angielski (Luksemburg)',
  'languages.bg.long': 'bułgarski',
  'languages.cs.long': 'czeski',
  'languages.sl.long': 'słoweński',
  'languages.pt.long': 'portugalski',
  'languages.es.long': 'hiszpański',
  'languages.el.long': 'grecki',
  // Email verification
  'emailVerification.completedMessage': 'Twój adres e-mail został zweryfikowany',
  'emailVerification.verified': 'Zweryfikowano',
  // Exercises
  'exercise.loading': 'Ładowanie ćwiczenia...',
  'exercise.signUp': 'Zaloguj się, aby rozwiązywać ćwiczenia',
  'exercise.score': 'Twój wynik:',
  'exercise.wrongLocale': 'Błędna lokalizacja',
  // Part tempplate
  'partTemplate.intro1': 'Rozdział ',
  'partTemplate.intro2': ' składa się z następujących sekcji. Kliknij poniżej, aby rozpocząć:',
  'partTemplate.spectrumPart1': 'Dołącz do ',
  'partTemplate.spectrum': 'społeczności kursu Elements of AI',
  'partTemplate.spectrumPart2': ', aby omawiać ten rozdział i zadawać pytania na jego temat.',
  // missing info
  'missingInfo.title': 'Brakujące informacje',
  'missingInfo.body': 'Zazwyczaj zadajemy te pytania podczas rejestracji użytkownika, ale logowanie nastąpiło za pomocą istniejącego konta mooc.fi. Wypełnij poniższy formularz, aby kontynuować.',
  'missingInfo.privacyPolicy': 'Zapoznaj się z naszą polityką ochrony prywatności',
  'missingInfo.error': 'Formularz zawiera błędy. Sprawdź formularz.',
  'missingInfo.privacyPolicyUpdateBody': "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
  'missingInfo.privacyPolicyUpdateLink': 'Możesz zapoznać się z polityką prywatności tutaj.',
  // routes
  'routes.LANDING': '/pl/',
  'routes.SIGN_UP': '/pl/signup',
  'routes.SIGN_IN': '/pl/signin',
  'routes.MISSING_INFO': '/pl/missinginfo',
  'routes.PASSWORD_FORGET': '/pl/pw-forget',
  'routes.HOME': '/pl/home',
  'routes.ACCOUNT': '/pl/account',
  'routes.ACCEPT_POLICY': '/pl/accept-policy',
  'routes.PRIVACY': 'https://www.elementsofai.pl/faq/polityka-prywatno%C5%9Bci',
  'routes.TERMS': 'https://www.elementsofai.com/faq/terms-and-conditions',
  'routes.FAQ': 'https://www.elementsofai.pl/faq',
  'routes.ABOUT': 'https://www.elementsofai.pl',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/pl-polska-spolecznosc',
  'routes.ects': 'https://mooc.fi/register-completion/elements-of-ai',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/faq/how-can-i-get-2-ects-credits',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Liczba filiżanek kawy dziennie',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Liczba napisanych linijek kodu',
  // meta
  'meta.title': 'Bezpłatny kurs online',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Dowiedz się więcej na temat kursu dotyczącego sztucznej inteligencji dla studentów i przedsiębiorców przygotowanego przez Uniwersytet Helsiński i Reaktor – nie jest wymagana umiejętność programowania ani znajomość matematyki na wyższym poziomie.',
  'meta.sitename': 'Elements of AI',
  // ECTS
  'ects.text': 'Po zakończeniu kursu otrzymasz instrukcje, jak złożyć wniosek o punkty ECTS.',
  'ects.textCompleted': 'Jeśli posiadasz fiński numer ubezpieczenia, możesz wnioskować o punkty, klikając na ',
  'ects.link': 'tutaj.',
  'ects.info': 'Would you like to apply for ECTS credits?',
  'ects.readMore': 'Read more',
  // Countries
  'countryselect.select': "Proszę wybrać",
  'Sweden': "Szwecja",
  'Estonia': "Estonia",
  'Finland': "Finlandia",
  'Germany': "Niemcy",
  "Countries": {
    "Afganistan": "Afganistan",
    "Åland Islands": "Wyspy Alandzkie",
    "Albania": "Albania",
    "Algeria": "Algieria",
    "American Samoa": "Samoa Amerykańskie",
    "Andorra": "Andora",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antarctica": "Antarktyda",
    "Antigua and Barbuda": "Antigua i Barbuda",
    "Argentina": "Argentyna",
    "Armenia": "Armenia",
    "Aruba": "Aruba",
    "Australia": "Australia",
    "Austria": "Austria",
    "Azerbaijan": "Azerbejdżan",
    "Bahamas": "Bahamy",
    "Bahrain": "Bahrajn",
    "Bangladesh": "Bangladesz",
    "Barbados": "Barbados",
    "Belarus": "Białoruś",
    "Belgium": "Belgia",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermudy",
    "Bhutan": "Bhutan",
    "Bolivia": "Boliwia",
    "Bosnia and Herzegovina": "Bośnia i Hercegowina",
    "Botswana": "Botswana",
    "Bouvet Island": "Wyspa Bouvet",
    "Brazil": "Brazylia",
    "British Indian Ocean Territory": "Brytyjskie Terytorium Oceanu Indyjskiego",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bułgaria",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambodia": "Kambodża",
    "Cameroon": "Kamerun",
    "Canada": "Kanada",
    "Cape Verde": "Wyspy Zielonego Przylądka",
    "Cayman Islands": "Kajmany",
    "Central African Republic": "Republika Środkowoafrykańska",
    "Chad": "Czad",
    "Chile": "Chile",
    "China": "Chiny",
    "Christmas Island": "Wyspa Bożego Narodzenia",
    "Cocos (Keeling) Islands": "Wyspy Kokosowe (Keelinga)",
    "Colombia": "Kolumbia",
    "Comoros": "Komory",
    "Congo": "Kongo",
    "Congo, The Democratic Republic of The": "Kongo, Demokratyczna Republika",
    "Cook Islands": "Wyspy Cooka",
    "Costa Rica": "Kostaryka",
    "Cote D'ivoire": "Wybrzeże Kości Słoniowej",
    "Croatia": "Chorwacja",
    "Cuba": "Kuba",
    "Cyprus": "Cypr",
    "Czech Republic": "Republika Czeska",
    "Denmark": "Dania",
    "Djibouti": "Dżibuti",
    "Dominica": "Dominika",
    "Dominican Republic": "Republika Dominikany",
    "Ecuador": "Ekwador",
    "Egypt": "Egipt",
    "El Salvador": "Salwador",
    "Equatorial Guinea": "Gwinea Równikowa",
    "Eritrea": "Erytrea",
    "Estonia": "Estonia",
    "Ethiopia": "Etiopia",
    "Falkland Islands (Malvinas)": "Falklandy (Malwiny)",
    "Faroe Islands": "Wyspy Owcze",
    "Fiji": "Fidżi",
    "Finland": "Finlandia",
    "France": "Francja",
    "French Guiana": "Gujana Francuska",
    "French Polynesia": "Polinezja Francuska",
    "French Southern Territories": "Francuskie Terytoria Południowe",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Gruzja",
    "Germany": "Niemcy",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Grecja",
    "Greenland": "Grenlandia",
    "Grenada": "Grenada",
    "Guadeloupe": "Gwadelupa",
    "Guam": "Guam",
    "Guatemala": "Gwatemala",
    "Guernsey": "Guernsey",
    "Guinea": "Gwinea",
    "Guinea-Bissau": "Gwinea Bissau",
    "Guyana": "Gujana",
    "Haiti": "Haiti",
    "Heard Island and Mcdonald Islands": "Wyspy Heard i Mcdonald Islands",
    "Holy See (Vatican City State)": "Stolica Apostolska (Państwo Watykańskie)",
    "Honduras": "Honduras",
    "Hong Kong": "Hongkong",
    "Hungary": "Węgry",
    "Iceland": "Islandia",
    "India": "Indie",
    "Indonesia": "Indonezja",
    "Iran, Islamic Republic of": "Iran (Islamska Republika)",
    "Iraq": "Irak",
    "Ireland": "Irlandia",
    "Isle of Man": "Wyspa Man",
    "Israel": "Izrael",
    "Italy": "Włochy",
    "Jamaica": "Jamajka",
    "Japan": "Japonia",
    "Jersey": "Jersey",
    "Jordan": "Jordania",
    "Kazakhstan": "Kazachstan",
    "Kenya": "Kenia",
    "Kiribati": "Kiribati",
    "Korea, Democratic People's Republic of": "Koreańska Republika Ludowo-Demokratyczna",
    "Korea, Republic of": "Republika Korei",
    "Kosovo": "Kosowo",
    "Kuwait": "Kuwejt",
    "Kyrgyzstan": "Kirgistan",
    "Lao People's Democratic Republic": "Laotańska Republika Ludowo-Demokratyczna",
    "Latvia": "Łotwa",
    "Lebanon": "Liban",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libyan Arab Jamahiriya": "Arabia Libijska Dżamahirija",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Litwa",
    "Luxembourg": "Luksemburg",
    "Macao": "Makao",
    "Macedonia, The Former Yugoslav Republic of": "Macedonia, Była Jugosłowiańska Republika",
    "Madagascar": "Madagaskar",
    "Malawi": "Malawi",
    "Malaysia": "Malezja",
    "Maldives": "Malediwy",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Wyspy Marshalla",
    "Martinique": "Martynika",
    "Mauritania": "Mauretania",
    "Mauritius": "Mauritius",
    "Mayotte": "Majotta",
    "Mexico": "Meksyk",
    "Micronesia, Federated States of": "Mikronezja, Sfederowane Stany USA",
    "Moldova, Republic of": "Mołdawia, Republika",
    "Monaco": "Monako",
    "Mongolia": "Mongolia",
    "Montenegro": "Czarnogóra",
    "Montserrat": "Montserrat",
    "Marocco": "Maroko",
    "Mozambique": "Mozambik",
    "Myanmar": "Myanmar",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Holandia",
    "Netherlands Antilles": "Antyle Holenderskie",
    "New Caledonia": "Nowa Kaledonia",
    "New Zealand": "Nowa Zelandia",
    "Nicaragua": "Nikaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "Norfolk Island": "Wyspa Norfolk",
    "Northern Mariana Islands": "Mariany Północne",
    "Norway": "Norwegia",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestinian Territory, Occupied": "Teretorium Paleństynskie, Okupowane",
    "Panama": "Panama",
    "Papua New Guinea": "Papua Nowa Gwinea",
    "Paraguay": "Paragwaj",
    "Peru": "Peru",
    "Philippines": "Filipiny",
    "Pitcairn": "Pitcairn",
    "Poland": "Polska",
    "Portugal": "Portugalia",
    "Puerto Rico": "Portoryko",
    "Qatar": "Katar",
    "Reunion": "Reunion",
    "Romania": "Rumunia",
    "Russian Federation": "Federacja Rosyjska",
    "Rwanda": "Rwanda",
    "Saint Helena": "Święta Helena",
    "Saint Kitts and Nevis": "Saint Kitts i Nevis",
    "Saint Lucia": "święta Lucia",
    "Saint Pierre and Miquelon": "Saint Pierre i Miquelon",
    "Saint Vincent and The Grenadines": "Saint Vincent i Grenadyny",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "Wyspy Świętego Tomasza i Książęca",
    "Saudi Arabia": "Arabia Saudyjska",
    "Senegal": "Senegal",
    "Serbia": "Serbia",
    "Seychelles": "Seszele",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapur",
    "Slovakia": "Słowacja",
    "Slovenia": "Słowenia",
    "Solomon Islands": "Wyspy Salomona",
    "Somalia": "Somali",
    "South Africa": "Afryka Południowa",
    "South Georgia and The South Sandwich Islands": "Georgia Południowa i Sandwich Południowy",
    "Spain": "Hiszpania",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Surinam",
    "Svalbard and Jan Mayen": "Svalbard i Jan Mayen",
    "Swaziland": "Suazi",
    "Sweden": "Szwecja",
    "Swizerland": "Swizerland",
    "Syrian Arab Republic": "Republika Syryjsko-Arabska",
    "Taiwan": "Tajwan",
    "Tajikistan": "Tadżykistan",
    "Tanzania, United Republic of": "Tanzania, Zjednoczona Republika",
    "Thailand": "Tajlandia",
    "Timor-leste": "Timor Wschodni",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trynidad i Tobago",
    "Tunisia": "Tunezja",
    "Turkey": "Turcja",
    "Turkmenistan": "Turkmenia",
    "Turks and Caicos Islands": "Wyspy Turks i Caicos",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukraina",
    "United Arab Emirates": "Zjednoczone Emiraty Arabskie",
    "United Kingdom": "Zjednoczone Królestwo",
    "United States": "Stany Zjednoczone",
    "United States Minor Outlying Islands": "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
    "Uruguay": "Urugwaj",
    "Uzbekistan": "Uzbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela": "Wenezuela",
    "Viet Nam": "Wietnam",
    "Virgin Islands, British": "Wyspy Dziewicze, Brytyjskie",
    "Virgin Islands, U.S.": "Wyspy Dziewicze Stanów Zjednoczonych",
    "Wallis and Futuna": "Wallis i Futuna",
    "Western Sahara": "Sahara Zachodnia",
    "Yemen": "Jemen",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe"

  },
  //Alt texts
  'alt.example-1': 'dwa samochody autonomiczne',
  'alt.example-2': 'dłoń z telefonem komórkowym',
  'alt.example-3': "twarz kobiety",
  'alt.defining-ai': 'rozsypane słowa związane ze sztuczną inteligencją',
  'alt.turing-test': 'człowiek rozmawiający przez komputer z robotem i kobietą',
  'alt.chinese-room': 'eksperyment myślowy „chiński pokój”',
  'alt.chicken-crossing': 'robot w łódce patrzący na kurę, lisa i worek z ziarnem dla kury',
  'alt.a-to-b': 'dłoń z telefonem z aplikacją nawigacyjną',
  'alt.tic-tac-toe': 'kółko i krzyżyk',
  'alt.self-driving-car': 'samochód autonomiczny przed znakiem stop',
  'alt.spam-or-ham': 'zwykły e-mail i spam',
  'alt.mnist': 'zbiór danych MNIST',
  'alt.nearest-neighbor': 'dwie osoby na zakupach',
  'alt.nearest-neighbor-graph': 'klasyfikator najbliższych sąsiadów',
  'alt.recommendation': 'mężczyzna i kobieta słuchający muzyki na telefonie',
  'alt.price-real-estate': 'trzy różne domy',
  'alt.supervised-learning': 'człowiek uczący robota',
  'alt.dice': 'kostka do gry',
  'alt.chess': 'szachownica',
  'alt.poker': 'dłoń z kartami do gry',
  'alt.eyes': 'trzy osoby o brązowych oczach, jedna osoba o niebieskich oczach',
  'alt.search-1': 'pojedyncza ścieżka prowadząca do celu',
  'alt.search-2': 'cztery różne ścieżki prowadzące do tego samego celu',
  'alt.chicken-crossing-1': 'przeprawa kury – stan początkowy',
  'alt.chicken-crossing-2': 'przeprawa kury – pierwsze przejście',
  'alt.chicken-crossing-3': 'przeprawa kury – wszystkie przejścia',
  'alt.chicken-crossing-4': 'przeprawa kury – najlepsza ścieżka',
  'alt.chicken-crossing-5': 'przeprawa kury – stan początkowy',
  'alt.implication-1': 'mężczyzna i kobieta',
  'alt.implication-2': 'oczy',
  'alt.implication-3': 'kamera monitoringu',
  'alt.implication-4': 'robot pracujący na laptopie',
  'alt.oddchange': 'słońce i chmury',
  'alt.brain': 'rysunek mózgu',
  'alt.carstop': 'samochód autonomiczny przed znakiem stop',
  'alt.gan': 'robot trzymający obrazek kota i obrazek znaku stop',
  'alt.hammock': 'robot wachlujący człowieka w hamaku',
  'alt.game-tree-1': 'drzewo gry 1',
  'alt.game-tree-2': 'drzewo gry 2',
  'alt.game-tree-3': 'drzewo gry 3',
  'alt.game-tree-4': 'drzewo gry 4',
  'alt.bayes-rule-1': 'obraz ilustrujący wyniki fałszywie dodatnie i ujemne w odniesieniu do badań przesiewowych w kierunku raka',
  'alt.bayes-rule-2': 'obraz ilustrujący wyniki fałszywie dodatnie i ujemne w odniesieniu do badań przesiewowych w kierunku raka',
  'alt.defineai': 'rozsypane słowa związane ze sztuczną inteligencją',
  'alt.winter': 'robot śpiący na śniegu',
  'alt.terminator': 'straszny robot',

}
