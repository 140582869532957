import { createGlobalStyle } from "styled-components";

// Resist! Use this for @font-face declarations only.
const Globals = createGlobalStyle`
 @font-face {
    font-family: "walsheim-bold";
    src: url(/fonts/GT-Walsheim-Bold.eot);
    src: url(/fonts/GT-Walsheim-Bold.eot?#iefix)
        format("embedded-opentype"),
      url(/fonts/GT-Walsheim-Bold.woff) format("woff"),
      url(/fonts/GT-Walsheim-Bold.woff2) format("woff2"),
      url(/fonts/GT-Walsheim-Bold.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "walsheim-light";
    src: url(/fonts/GT-Walsheim-Light.eot);
    src: url(/fonts/GT-Walsheim-Light.eot?#iefix)
        format("embedded-opentype"),
      url(/fonts/GT-Walsheim-Light.woff) format("woff"),
      url(/fonts/GT-Walsheim-Light.woff2) format("woff2"),
      url(/fonts/GT-Walsheim-Light.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "walsheim-regular";
    src: url(/fonts/GT-Walsheim-Regular.eot);
    src: url(/fonts/GT-Walsheim-Regular.eot?#iefix)
        format("embedded-opentype"),
      url(/fonts/GT-Walsheim-Regular.woff) format("woff"),
      url(/fonts/GT-Walsheim-Regular.woff2) format("woff2"),
      url(/fonts/GT-Walsheim-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "tiempos-regular";
    src: url(/fonts/TiemposTextWeb-Regular.eot);
    src: url(/fonts/TiemposTextWeb-Regular.eot?#iefix)
        format("embedded-opentype"),
      url(/fonts/TiemposTextWeb-Regular.woff2) format("woff2"),
      url(/fonts/TiemposTextWeb-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "tiempos-semibold";
    src: url(/fonts/TiemposTextWeb-Semibold.eot);
    src: url(/fonts/TiemposTextWeb-Semibold.eot?#iefix)
        format("embedded-opentype"),
      url(/fonts/TiemposTextWeb-Semibold.woff2) format("woff2"),
      url(/fonts/TiemposTextWeb-Semibold.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
 
  }

  @font-face {
    font-family: "tt-norms-pro-light";
    src: url(/int-fonts/TT-Norms-Pro-Light.ttf) format("truetype");
    src: url(/int-fonts/TT-Norms-Pro-Light.woff2) format("woff2"),
      url(/int-fonts/TT-Norms-Pro-Light.woff) format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    unicode-range:U+03??;
  }

  @font-face {
    font-family:  "tt-norms-pro-regular";
    src: url(/int-fonts/TT-Norms-Pro-Regular.ttf) format("truetype");
    src: url(/int-fonts/TT-Norms-Pro-Regular.woff2) format("woff2"),
      url(/int-fonts/TT-Norms-Pro-Regular.woff) format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    unicode-range:U+03??;
  }
  @font-face {
    font-family:  "alkes-regular";
    src: url(/int-fonts/Alkes-Regular.otf) format("opentype");
    src: url(/int-fonts/Alkes-Regular.woff2) format("woff2"),
      url(/int-fonts/Alkes-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    font-feature-settings: "ss01", "ss02";
    unicode-range:U+03??;
  }

  @font-face {
    font-family:  "alkes-semibold";
    src: url(/int-fonts/Alkes-Semibold.otf) format("opentype");
    src: url(/int-fonts/Alkes-Semibold.woff2) format("woff2"),
      url(/int-fonts/Alkes-Semibold.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    font-feature-settings: "ss01", "ss02";
    unicode-range:U+03??;
  }

  body {
    font-family: "tiempos-regular", "alkes-regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export default Globals;