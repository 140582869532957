export const space = [
  0,
  ".25rem",
  "0.5rem",
  "1rem",
  "2rem",
  "4rem",
  "8rem",
  "16rem"
];
