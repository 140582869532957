import * as actionTypes from "./actions";
import { RESET_STATE } from "../actions";
import _ from "lodash";

const initialState = {
  /*
    [quizId]: {
      given: <given peer reviews>,
      received: <received peer reviews>
    }
  */
  submitting: false
};

export default function peerReviews(state = initialState, action) {
  let newGiven, newReceived, quizIds;

  switch (action.type) {
    case actionTypes.SET_PEER_REVIEWS_INIT_STATE:
    case RESET_STATE:
      return initialState;
    case actionTypes.RECEIVE_GIVEN_PEER_REVIEW:
      newGiven = _.concat(
        _.get(state, `[${action.quizId}].given`, []).filter(
          pr => pr._id !== action.data._id
        ),
        action.data
      );

      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          given: newGiven
        }
      });
    case actionTypes.RECEIVE_GIVEN_PEER_REVIEWS:
      quizIds = _.uniq(
        _.concat(
          action.data.map(pr => pr.sourceQuizId),
          _.filter(Object.keys(state), k => k !== "submitting")
        )
      );
      newGiven = _.groupBy(action.data, pr => pr.sourceQuizId);

      return _.reduce(
        quizIds,
        (obj, id) => ({
          ...obj,
          [id]: {
            given: _.get(newGiven, id, []),
            received: _.get(state, `[${id}].received`, [])
          }
        }),
        {}
      );
    case actionTypes.RECEIVE_RECEIVED_PEER_REVIEW:
      newReceived = _.concat(
        _.get(state, `[${action.quizId}].received`, []).filter(
          pr => pr._id !== action.data._id
        ),
        action.data
      );

      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          received: newReceived
        }
      });
    case actionTypes.RECEIVE_RECEIVED_PEER_REVIEWS:
      quizIds = _.uniq(
        _.concat(
          action.data.map(pr => pr.sourceQuizId),
          _.filter(Object.keys(state), k => k !== "submitting")
        )
      );
      newReceived = _.groupBy(action.data, pr => pr.sourceQuizId);

      return _.reduce(
        quizIds,
        (obj, id) => ({
          ...obj,
          [id]: {
            given: _.get(state, `[${id}].given`, []),
            received: _.get(newReceived, id, [])
          }
        }),
        {}
      );
    case actionTypes.RECEIVE_PEER_REVIEWS:
      newGiven = _.get(action.payload, "given", []);
      newReceived = _.get(action.payload, "received", []);

      quizIds = _.uniq(
        _.concat(
          newGiven.map(pr => pr.sourceQuizId),
          newReceived.map(pr => pr.sourceQuizId)
        )
      );
      return {
        ..._.reduce(
          quizIds,
          (obj, id) => ({
            ...obj,
            [id]: {
              given: newGiven.filter(pr => pr.sourceQuizId === id),
              received: newReceived.filter(pr => pr.sourceQuizId === id)
            }
          }),
          {}
        ),
        submitting: _.get(state, "submitting", false)
      };
    case actionTypes.CLEAR_RECEIVED_PEER_REVIEWS_FOR_ID:
      const { quizId } = action.payload;

      return {
        ...state,
        [quizId]: {
          ...state[quizId],
          received: []
        }
      };
    case actionTypes.SUBMIT_PEER_REVIEW:
      return Object.assign({}, state, {
        ...state,
        submitting: true // or set submitting on the actual quiz?
      });
    case actionTypes.SUBMIT_PEER_REVIEW_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        [action.quizId]: {
          ...state[action.quizId],
          given: _.concat(
            _.get(state, `[${action.quizId}].given`, []),
            action.data
          )
        },
        submitting: false
      });
    case actionTypes.SUBMIT_PEER_REVIEW_FAILURE:
      return Object.assign({}, state, {
        ...state,
        submitting: false // same here
      });
    default:
      return state;
  }
}
