import * as actionTypes from "./actions";
import { RESET_STATE } from "../actions";

const initialState = {
  error: ""
};

export default function tags(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE:
      return { ...initialState };
    case actionTypes.SET_QUIZIDS_FOR_TAGS:
      if (!action.data || (!!action.data && Object.keys(action.data) === 0)) {
        return { ...initialState };
      }
      let tags = action.data;

      return {
        ...tags,
        error: ""
      };
    /*       return Object.assign({}, state, {
        ...tags,
        error: ''
      }) */
    case actionTypes.SET_QUIZIDS_FOR_TAGS_ERROR:
      return Object.assign(
        {},
        {},
        {
          error: action.error
        }
      );
    default:
      return state;
  }
}
