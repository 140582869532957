import { createStore as createReduxStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import { loggedIn } from "../services/moocfi";
import { fetchInitialData, resetState } from "./actions";
import reducer from "./reducer";
import * as accountState from "./account";
import _ from "lodash";
import { getExpiredQuizIds } from "./quiz/selectors";
import { getAnswererId, getCourseId } from "./courseState/selectors";
import {
  localizeCourseId,
  localizeCourseIdTo,
  currentLocale
} from "../services/locale";
import { config } from "./course-config";

const { COURSE_ID } = config;
const DEVELOPMENT = process.env.NODE_ENV === "development";
let store,
  persistor = undefined;

const middlewares = [thunkMiddleware, DEVELOPMENT && createLogger({})].filter(
  Boolean
);

export const createStore = () => {
  if (!store) {
    store = createReduxStore(
      reducer,
      {},
      composeWithDevTools(applyMiddleware(...middlewares))
    );

    persistor = persistStore(store);
  }

  return { persistor, store };
};

export const syncStore = (options = {}) => {
  const state = store ? store.getState() : {};
  const { forceSync, locale = currentLocale() } = options;
  const user = _.get(accountState.getUser(), "username");
  const answererId = getAnswererId(state);

  let next = Promise.resolve();

  // TODO - can I do this without zapping everything?
  if ((user !== answererId || forceSync) && store) {
    next = store.dispatch(resetState());
    if (!typeof window === "undefined" && window.localStorage) {
      window.localStorage.removeItem(`persist:${localizeCourseId("root")}`);
      window.localStorage.removeItem("persist:answers");
    }
  }

  return next.then(() =>
    store.dispatch(
      fetchInitialData({
        loggedIn: loggedIn(),
        courseId: localizeCourseIdTo(COURSE_ID, locale)
      })
    )
  );
};
