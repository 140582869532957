// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'de-at',
  'globals.signIn': 'Anmelden',
  'globals.logIn': 'Einloggen',
  'globals.signUp': 'Registrieren',
  'globals.signOut': 'Abmelden',
  'globals.logOut': 'Ausloggen',
  'globals.submit': 'Senden',
  'globals.submitting': 'Wird gesendet...',
  'globals.yourEmail': 'Deine E-Mail-Adresse',
  'globals.yourPassword': 'Dein Passwort',
  'globals.courseOverview': 'Kursübersicht',
  'globals.forgotPassword': 'Passwort vergessen?',
  'globals.invalidCredentials': 'Zugangsdaten ungültig',
  'globals.section': 'Abschnitt',
  'globals.exercises': 'Übungen',
  'globals.chapter': 'Kapitel',
  'globals.introToAI': 'Einführung in die KI',
  'globals.buildingAI': 'Building AI',
  'globals.acceptPolicy': 'Accept policy',
  // section
  'section.footerEndOfTheChapter': 'Du bist am Ende von Kapitel {chapter} angekommen',
  'section.footerEndOfTheCourseTitle': 'Du bist am Ende des Kurses angekommen!',
  'section.footerEndOfTheCourseText': 'Wenn du die Übungen gemacht und deine Peer-Evaluationen abgegeben hast, werden deine Antworten von unserem Personal geprüft.',
  'section.nextSection': 'Nächster Abschnitt',
  'section.nextChapter': 'Nächstes Kapitel',
  'section.start': 'Start',
  'section.logisticRegressionExample.slope': 'Slope',
  'section.logisticRegressionExample.intercept': 'Intercept',
  'section.footerEndOfTheCourseBAI': 'Setze deine Reise fort, um deine erste KI-Idee umzusetzen.',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Wartet auf deine Evaluation',
  'quiz.rejectedPeerReview': 'Bei der Peer-Evaluation abgelehnt',
  'quiz.unsubmittedAnswer': 'Antwort nicht gesendet',
  'quiz.pendingPeerReview': 'Peer-Evaluation steht noch aus',
  // quiz
  'quiz.status.answered': 'Beantwortet',
  'quiz.status.rejected': 'Antwort abgelehnt, bitte erneut versuchen',
  'quiz.status.unanswered': 'Nicht beantwortet',
  'quiz.status.exercisesCompleted': 'Abgeschlossene Übungen',
  'quiz.status.correctAnswers': 'Richtige Antworten',
  'quiz.message.rejectedInReview': 'Deine vorherige Antwort wurde bei der Peer-Evaluation abgelehnt. Bitte versuch es noch einmal.',
  'quiz.youAnswered': 'Deine Antwort:',
  'quiz.yourAnswer': 'Deine Antwort...',
  'quiz.yourScore': 'Dein Ergebnis:',
  'quiz.quizNotLoaded': 'Das Quiz konnte nicht geladen werden',
  'quiz.words': 'Wörter:',
  'quiz.exampleAnswer': 'Musterantwort:',
  // peer review
  'peerreview.question1': 'Die Antwort ist themenbezogen',
  'peerreview.question2': 'Die Antwort ist umfassend',
  'peerreview.question3': 'Die Antwort ist gut begründet',
  'peerreview.question4': 'Die Antwort ist leicht verständlich',
  'peerreview.thankYou': 'Vielen Dank für deine Peer-Evaluationen!',
  'peerreview.giveMoreReviews': 'Wenn du Zeit hast,',
  'peerreview.giveMoreReviewsButton': 'kannst du gerne weitere Evaluationen abgeben',
  'peerreview.notEnoughAnswers': 'Normalerweise würdest du hier eine Peer-Evaluation sehen, aber wir warten noch auf weitere Antworten zu dieser Übung.',
  'peerreview.selectAnswerMessage': 'Zeit für Peer-Evaluationen! Unten siehst du einige Antworten von anderen Kursteilnehmern. Bitte wähle eine davon aus und bewerte sie. Bitte evaluiere mindestens drei Antworten.',
  'peerreview.minimumPeerReviewWarning': 'Die Übung kann erst akzeptiert werden, wenn du mindestens drei Peer-Evaluationen abgegeben hast.',
  'peerreview.select': 'Auswählen',
  'peerreview.reportSpam': 'Spam melden',
  'peerreview.feedback': 'Deine Antwort wurde evaluiert und akzeptiert. Deine Peers haben die Antwort wie folgt bewertet:',
  // exercise17
  'quiz.exercise17.xLabel': 'Schuljahre',
  'quiz.exercise17.yLabel': 'Lebenserwartung in Jahren',
  'quiz.exercise17.textPart1': 'Untersuchen wir einmal den Zusammenhang zwischen der Gesamtzahl an Schuljahren (von der Vorschule bis zur Universität) und der Lebenserwartung. In dieser Grafik sind Daten aus drei verschiedenen Ländern durch Punkte dargestellt:',
  'quiz.exercise17.textPart2': 'Wir haben ein Land, in dem die durchschnittliche Anzahl an Schuljahren 10 beträgt und die Lebenserwartung bei 57 Jahren liegt, ein weiteres Land, in dem die durchschnittliche Anzahl an Schuljahren 13 beträgt und die Lebenserwartung bei 53 Jahren liegt und ein drittes Land, in dem die durchschnittliche Anzahl an Schuljahren 20 beträgt und die Lebenserwartung bei 80 Jahren liegt.',
  'quiz.exercise17.textPart3': 'Du kannst die Endpunkte der durchgezogenen Linie verschieben, um die Linie so anzupassen, dass sie dem Trend folgt, der sich aus den Datenpunkten ergibt. Bitte beachte, dass du die Linie nicht komplett an die Datenpunkte angleichen kannst, aber das ist in Ordnung: Manche Datenpunkte liegen über der Linie und manche darunter. Das Wichtigste ist, dass die Linie den allgemeinen Trend widerspiegelt.',
  'quiz.exercise17.textPart4': 'Wenn du die Linie ausgerichtet hast, kannst du sie zur Vorhersage der Lebenserwartung verwenden.',
  'quiz.exercise17.textPart5': 'Was kannst du anhand der Daten über die Lebenserwartung der Menschen sagen, die eine 15-jährige Schulbildung erhalten? Wichtig: Selbst wenn sich durch Anpassung der Linie eine genaue Vorhersage treffen lässt (sogar bis zu einem Bruchteil eines Jahres), bedeutet dies nicht automatisch, dass du auch eine zuverlässige Vorhersage treffen kannst. Bedenke bei der Wahl deiner Antwort die geringe Datenmenge.',
  // exercise18
  'quiz.exercise18.xLabel': 'Schuljahre',
  'quiz.exercise18.yLabel': 'Lebenserwartung in Jahren',
  'quiz.exercise18.textPart1': 'In der vorigen Übung lagen uns lediglich Daten aus drei Ländern vor. Der vollständige Datensatz besteht aus Daten aus 14 verschiedenen Ländern (siehe Diagramm):',
  'quiz.exercise18.textPart2': 'Würdest du deine Vorhersage der Lebenserwartung von Menschen mit 15 Jahren Schulbildung auf Grundlage dieser Daten ändern? Falls ja, weshalb?',
  'quiz.exercise18.textPart3': 'Welche der folgenden Möglichkeiten würde deiner Schätzung der Lebenserwartung von Menschen mit 15 Jahren Schulbildung am Ehesten entsprechen? Wähle die genaueste Möglichkeit, die du für gerechtfertigt hältst, indem du das gerade Linienmodell an die obigen Daten anpasst.',
  // exercise19
  'quiz.exercise19.xLabel': 'Gelernte Stunden',
  'quiz.exercise19.yLabel': 'Wahrscheinlichkeit zu bestehen',
  'quiz.exercise19.textPart1': 'Jeder Punkt im Bild entspricht einer*einem Studierenden. Unten im Bild befindet sich die Skala, der wir entnehmen können, wie viele Stunden die Person für die Prüfung gelernt hat. Die Studierenden, die die Prüfung bestanden haben, werden als Punkte im oberen Bereich des Diagramms dargestellt und diejenigen, die nicht bestanden haben, im unteren Bereich. Die voraussichtliche Wahrscheinlichkeit, die Prüfung zu bestehen, die sich – wie nachfolgend erklärt – aus dem logistischen Regressionsmodell ergibt, wird entlang der Skala auf der linken Seite angegeben. Aus dieser Grafik kann man grob entnehmen, dass Studierenden, die länger gelernt haben, bessere Chancen hatten, den Kurs zu bestehen. Insbesondere die extremen Fälle leuchten intuitiv ein: Mit weniger als einer Stunde Arbeit ist es sehr schwer, den Kurs zu bestehen, aber mit viel Arbeit sind die meisten erfolgreich. Doch was ist mit den Studierenden, die sich irgendwo zwischen den Extremen bewegen? Wenn ein*e Studierende*r z. B. sechs Stunden lernt, wie hoch ist dann seine*ihre Wahrscheinlichkeit zu bestehen?',
  'quiz.exercise19.textPart2': 'Wir können die Wahrscheinlichkeit zu bestehen mithilfe der logistischen Regression quantifizieren. Die Kurve im Bild lässt sich als Wahrscheinlichkeit zu bestehen deuten. Nach fünf Stunden Lernen beträgt die Wahrscheinlichkeit zu bestehen zum Beispiel etwas mehr als 20 %. Wir wollen nicht im Detail erläutern, wie man diese Kurve erhält, aber das Prinzip ähnelt der Ermittlung der Gewichte bei der linearen Regression.',
  'quiz.exercise19.textPart3': 'Wenn du eine 80 %ige Wahrscheinlichkeit erreichen möchtest, eine Prüfung an der Universität zu bestehen, wie viele Stunden solltest du dann auf Basis der oben abgebildeten Grafik ungefähr dafür lernen?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Eingabesignal',
  'quiz.exercise22.sigmoidYLabel': 'Ausgabesignal der Sigmoidfunktion',
  'quiz.exercise22.stepXLabel': 'Eingabesignal',
  'quiz.exercise22.stepYLabel': 'Ausgabesignal der Schwellenwertfunktion',
  'quiz.exercise22.identityXLabel': 'Eingabesignal',
  'quiz.exercise22.identityYLabel': 'Ausgabesignal der Identitätsfunktion',
  'quiz.exercise22.textPart1': 'Nachstehend siehst du Diagramme zu drei verschiedenen Aktivierungsfunktionen mit unterschiedlichen Eigenschaften: die Sigmoidfunktion, die Schwellenwertfunktion und die Identitätsfunktion.',
  // exercise23
  'quiz.exercise23.happyFaces': 'glückliche Gesichter richtig klassifiziert',
  'quiz.exercise23.sadFaces': 'traurige Gesichter richtig klassifiziert',
  // certificate availability
  'certificate.failedToCheck': 'Überprüfung der Verfügbarkeit der Teilnahmebescheinigung fehlgeschlagen',
  'certificate.failedToGenerate': 'Erstellung der Teilnahmebescheinigung fehlgeschlagen',
  'certificate.notYetAvailable': 'Nachdem du unsere offizielle Bestätigung erhalten hast, wird die Teilnahmebescheinigung hier für dich zur Verfügung gestellt. Dies kann ein Weilchen dauern. Wir bitten um Verständnis.',
  'certificate.updateName': 'Deine Teilnahmebescheinigung ist verfügbar! Bitte aktualisiere deinen Vor- und Nachnamen mit dem nachstehenden Formular im Profil, damit wir diese Informationen auf der Teilnahmebescheinigung benutzen können.',
  'certificate.generate': 'Nachstehend kannst du deine Teilnahmebescheinigung erstellen.',
  'certificate.generateTextPart1': 'Eine Teilnahmebescheinigung wird erstellt für',
  'certificate.generateTextPart2': 'Bitte vor der Erstellung der Teilnahmebescheinigung überprüfen, ob die Angaben korrekt sind. Der Name kann nachträglich nicht geändert werden! Wenn du deinen Namen ändern möchtest, kannst du dies im unten befindlichen Formular tun.',
  'certificate.generateButton': 'Teilnahmebescheinigung erstellen',
  'certificate.downloadButton': 'Teilnahmebescheinigung herunterladen',
  'certificate.checkingAvailability': 'Verfügbarkeit der Teilnahmebescheinigung wird überprüft...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Richtig klassifiziert',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Falsch klassifiziert',
  // section blocks
  'section.block.note': 'Hinweis',
  'section.block.example': 'Beispiel',
  'section.block.keyTerminology': 'Grundbegriffe',
  'section.partSummary.spectrumTextPart1': 'Bitte melde dich als Mitglied der ',
  'section.partSummary.spectrum': '„Elements of AI“-Community',
  'section.partSummary.spectrumTextPart2': ' an, um dieses Kapitel zu besprechen und Fragen dazu zu stellen.',
  'section.partSummary.feedbackTitle': 'Feedback zu diesem Abschnitt abgeben',
  'section.partSummary.feedbackPlaceholder': 'Bitte gib hier dein Feedback ab...',
  'section.partSummary.feedbackSuccess': 'Vielen Dank für dein Feedback!',
  // 404
  'globals.404.title': '404 – Seite nicht gefunden',
  'globals.404.intro': "Leider existiert dieser Pfad nicht... schade!",
  'globals.loading': 'Wird geladen...',
  // footer / menu
  'globals.menu': 'Menü',
  'globals.menu.about': 'Startseite',
  'globals.menu.faq': 'Häufig gestellte Fragen',
  'globals.menu.privacyPolicy': 'Datenschutzerklärung',
  'globals.menu.myProfile': 'Mein Profil',
  'globals.menu.termsofService': 'Nutzungsbedingungen',
  // Dashboard/Index
  'dashboard.title': 'Willkommen im Kurs!',
  'dashboard.congratulations.title': 'Herzlichen Glückwunsch!',
  'dashboard.congratulations.message': 'Du hast den Kurs „Elements of AI“ absolviert',
  'dashboard.congratulations.downloadCertificate': 'Teilnahmebescheinigung herunterladen',
  'dashboard.notLoggedIn.title': 'Herzlich willkommen!',
  'dashboard.notLoggedIn.message': 'Bitte registriere dich oder melde dich an, um unbeschränkten Zugang zum Kurs zu erhalten. Der Kurs ist kostenlos!',
  // Account
  'account.certificate': 'Teilnahmebescheinigung',
  'account.myProfile': 'Mein Profil',
  'account.profileInformation': 'Profil Information',
  'account.credits': 'ECTS-Credits',
  'account.changePassword': 'Passwort ändern',
  'account.changeLanguage': 'Sprache ändern',
  'account.goToCourse': 'Weiter zum Kurs',
  'account.buildingAINew': 'Neuer Kurs in Englisch',
  'account.accept': 'Akzeptieren',
  'account.decline': 'Ablehnen',
  'account.courses': 'Kurse',
  // Sign Up page
  'signUp.description': 'Bitte vervollständige das untenstehende Formular, um ein neues mooc.fi-Benutzerkonto zu erstellen',
  'signUp.email': 'Deine E-Mail-Adresse',
  'signUp.firstName': 'Dein Vorname',
  'signUp.lastName': 'Dein Nachname',
  'signUp.postalCode': 'Postleitzahl',
  'signUp.country': 'Wo wohnst du?',
  'signUp.Password': 'Passwort',
  'signUp.confirmPassword': 'Passwort bestätigen',
  'signUp.howToStudyTitle': 'Wie möchtest du den Kurs absolvieren?',
  'signUp.6weeksTitle': '6-Wochen-Kurs',
  'signUp.6weeksSubTitle': 'Empfohlen (ca. 5 Std./Woche)',
  'signUp.6weeksText': 'Das Festlegen einer Deadline hilft erfahrungsgemäß dabei, den Kurs erfolgreich zu absolvieren.',
  'signUp.selfPacedTitle': 'In meinem eigenen Tempo',
  'signUp.selfPacedText': 'Du kannst selbst entscheiden, in welchem Tempo du den Kurs absolvieren möchtest.',
  'signUp.ectsTitle': 'Möchtest du ECTS-Credits beantragen?',
  'signUp.ectsTitleSwe': 'Would you like to apply for ECTS credits? ',
  'signUp.ectsStudent': 'Ich studiere an der Universität von Helsinki. Wenn du dieses Kästchen markierst und deine Matrikelnummer einfügst, werden dir die ECTS-Credits automatisch angerechnet.',
  'signUp.ectsStudentSwe': 'After completing the course, you can apply for ECTS credits from Linköping University. You can find the instructions ',
  'signup.ectsStudentSweLink': 'here.',
  'signUp.ectsOpenUni': 'Markiere dieses Kästchen, um per E-Mail Informationen zur Beantragung von ECTS-Credits zu erhalten',
  'signUp.studentNumber': 'Matrikelnummer der Universität von Helsinki (sofern eingeschrieben)',
  'signUp.privacyOptIn': 'Ich bin damit einverstanden, dass über meine Studienaktivitäten erhobene Daten für wissenschaftliche Zwecke verwendet werden können. Zu diesen Daten zählen Informationen zur Verwendung des Materials und zu den Übungen im Rahmen des Kurses. Aus entsprechenden Veröffentlichungen können keine Rückschlüsse auf einzelne Personen gezogen werden.',
  'signUp.marketingOptIn': 'Ja, ich möchte über neue Sprachvarianten und künftige Kurse informiert werden. Ich bin mit der Weitergabe meiner Kontaktinformationen einverstanden, um auf Plattformen von Drittanbietern maßgeschneiderte Informationen zu erhalten. Weitere Informationen findest du in unserer Datenschutzerklärung.',
  'signUp.acceptPolicyFirst': 'Ich akzeptiere die Datenschutzerklärung und die Nutzungsbedingungen.',
  'signUp.privacyLink': 'Datenschutzerklärung lesen',
  'signUp.error': 'Das Formular enthält fehlerhafte Angaben. Bitte überprüfe deine Angaben.',
  // Sign in page
  'signIn.title': 'Anmelden',
  'signIn.intro': 'Bitte benutze das untenstehende Formular, um dich über dein mooc.fi-Konto anzumelden',
  'signIn.email': 'Deine E-Mail-Adresse',
  'signIn.password': 'Dein Passwort',
  'signIn.noAccount': "Kein Benutzerkonto?",
  'signIn.signUpNow': 'Jetzt für den Kurs registrieren',
  // Forgot password
  'pwForget.title': 'Passwort vergessen',
  'pwForget.description': 'Bitte gib die mit deinem mooc.fi-Konto verknüpfte E-Mail-Adresse ein. Wir senden dir dann einen Link zu, mit dem du dein Passwort zurücksetzen kannst.',
  'pwForget.sendResetLink': 'Link zum Zurücksetzen zusenden',
  'pwForget.completedDescription': 'Du solltest in Kürze von mooc.fi eine E-Mail mit einem Link erhalten, über den du dein Passwort zurücksetzen kannst. Wenn diese Nachricht nicht bei dir ankommt, sieh bitte in deinem Spam-Ordner nach.',
  'pwForget.problemDescription': 'Bei Problemen kannst du dich an mooc@cs.helsinki.fi wenden.',
  'pwForget.resetEmailSent': 'E-Mail zum Zurücksetzen des Passworts gesendet',
  'pwForget.emailPlaceholder': 'E-Mail-Adresse',
  // password change
  'pwChange.authError': 'Authentifizierung fehlgeschlagen. Versuche bitte noch einmal, dich anzumelden.',
  'pwChange.unexpError': 'Passwortänderung aufgrund eines unerwarteten Fehlers fehlgeschlagen',
  'pwChange.required': 'Pflichtangabe',
  'pwChange.pwMustMatch': 'Die Passwörter müssen übereinstimmen',
  'pwChange.currentPassword': 'Aktuelles Passwort',
  'pwChange.newPassword': 'Neues Passwort',
  'pwChange.confirmPassword': 'Neues Passwort bestätigen',
  'pwChange.changingPassword': 'Passwort wird geändert...',
  'pwChange.changePassword': 'Passwort ändern',
  'pwChange.passwordChanged': 'Passwort geändert',
  // user details
  'userDetails.authError': 'Authentifizierung fehlgeschlagen. Versuch bitte noch einmal, dich anzumelden.',
  'userDetails.unexpError': 'Aktualisierung des Profils aufgrund eines unerwarteten Fehlers fehlgeschlagen',
  'userDetails.required': 'Pflichtangabe',
  'userDetails.invalidEmail': 'Ungültige E-Mail-Adresse',
  'userDetails.email': 'E-Mail',
  'userDetails.emailAddress': 'E-Mail-Adresse',
  'userDetails.firstName': 'Vorname',
  'userDetails.lastName': 'Nachname',
  'userDetails.language': 'Sprache',
  'userDetails.updatingProfile': 'Profil wird aktualisiert...',
  'userDetails.updateProfile': 'Profil aktualisieren',
  'userDetails.profileUpdated': 'Profil aktualisiert',
  // languages
  'languages.en.long': 'Englisch',
  'languages.fi.long': 'Finnisch',
  'languages.se.long': 'Schwedisch',
  'languages.de.long': 'Deutsch (Deutschland)',
  'languages.ee.long': 'Estnisch',
  'languages.it.long': 'Italienisch',
  'languages.fr.long': 'Französisch (Frankreich)',
  'languages.no.long': 'Norwegisch',
  'languages.lv.long': 'Lettisch',
  'languages.da.long': 'Dänisch',
  'languages.pl.long': 'Polnisch',
  'languages.ro.long': 'Rumänisch',
  'languages.en-ie.long': 'Englisch (Irland)',
  'languages.mt.long': 'Maltesisch',
  'languages.fr-be.long': 'Französisch (Belgien)',
  'languages.lt.long': 'Litauisch',
  'languages.nl-be.long': 'Niederländisch (Belgien)',
  'languages.nl.long': 'Niederländisch (Niederlande)',
  'languages.hr.long': 'Kroatisch',
  'languages.de-at.long': 'Deutsch (Österreich)',
  'languages.en-lu.long': 'Englisch (Luxemburg)',
  'languages.cs.long': 'Tschechisch',
  'languages.pt.long': 'Portugiesisch',
  'languages.bg.long': 'Bulgarisch',
  'languages.sk.long': 'Slowakisch',
  'languages.el.long': 'Griechisch',
  'languages.sl.long': 'Slowenisch',
  'languages.is.long': 'Isländisch',
  'languages.es.long': 'Spanisch',
  'languages.ga.long': 'Irisch',
  // Email verfification
  'emailVerification.completedMessage': 'Deine E-Mail-Adresse wurde bestätigt',
  'emailVerification.verified': 'Bestätigt',
  // Exercises
  'exercise.loading': 'Übung wird geladen...',
  'exercise.signUp': 'Bitte registriere dich für den Kurs, um Übungen zu machen',
  'exercise.score': 'Dein Ergebnis:',
  'exercise.wrongLocale': 'Falsches Gebietsschema',
  // Part tempplate
  'partTemplate.intro1': 'Das Kapitel ',
  'partTemplate.intro2': ' besteht aus den folgenden Abschnitten. Per Klick unten geht\'s los:',
  'partTemplate.spectrumPart1': 'Bitte melde dich als Mitglied der ',
  'partTemplate.spectrum': '„Elements of AI“-Community',
  'partTemplate.spectrumPart2': ' an, um dieses Kapitel zu besprechen und Fragen dazu zu stellen.',
  // missing info
  'missingInfo.title': 'Fehlende Angaben',
  'missingInfo.body': 'Normalerweise stellen wir diese Fragen, wenn Benutzer sich registrieren, aber du hast dich über ein bestehendes mooc.fi-Konto angemldet. Bitte vervollständige das nachstehende Formular, um fortzufahren.',
  'missingInfo.privacyPolicy': 'Bitte unsere Datenschutzerklärung lesen',
  'missingInfo.error': 'Das Formular enthält fehlerhafte Angaben. Bitte überprüfe deine Angaben.',
  'missingInfo.privacyPolicyUpdateBody': "Wir haben unsere Datenschutzerklärung aktualisiert. Nachstehend finden Sie eine Zusammenfassung der Änderungen. Wir bewerben unsere Kurse für potenzielle neue Studierende, indem wir auf Marketingplattformen Zielgruppen bilden. Dies kann das Teilen von E-Mail-Adressen mit diesen Plattformen umfassen. Zu demselben Zweck werden anonymisierte Nutzerdaten an Marketingnetzwerke weitergegeben. Sie können die Weitergabe von E-Mail-Informationen verhindern, indem Sie das Kontrollkästchen für die Genehmigung der Vermarktung in Ihrem Kursprofil deaktivieren. Um die Freigabe von Nutzerdaten zu verhindern, können Sie die Cookie-Einstellungen auf der Kursseite ändern. Die weitere Nutzung des Dienstes setzt die Annahme dieser Bedingungen voraus.",
  'missingInfo.privacyPolicyUpdateLink': 'Lesen Sie hier unsere Datenschutzerklärung.',
  // routes
  'routes.LANDING': '/de-at/',
  'routes.SIGN_UP': '/de-at/signup',
  'routes.SIGN_IN': '/de-at/signin',
  'routes.MISSING_INFO': '/de-at/missinginfo',
  'routes.PASSWORD_FORGET': '/de-at/pw-forget',
  'routes.HOME': '/de-at/home',
  'routes.ACCOUNT': '/de-at/account',
  'routes.PRIVACY': 'https://www.elementsofai.at/faq/datenschutzerkl%C3%A4rung-1',
  'routes.FAQ': 'https://www.elementsofai.at/faq',
  'routes.TERMS': 'https://www.elementsofai.com/faq/terms-and-conditions',
  'routes.ABOUT': 'https://www.elementsofai.at',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/de-besprechen-auf-deutsch',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/faq/how-can-i-get-2-ects-credits',
  'routes.ACCEPT_POLICY': '/de-at/accept-policy',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Tassen Kaffee pro Tag',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Geschriebene Codezeilen',
  // meta
  'meta.title': 'Kostenloser Onlinekurs',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Finde mehr über diesen KI-Kurs heraus, der von der Universität von Helsinki und von Reaktor für Studierende und Berufstätige angeboten wird – Vorkenntnisse in Mathematik oder Programmieren sind nicht erforderlich!',
  'meta.sitename': 'Elements of AI',
  'meta.title.og': 'A free online introduction to artificial intelligence for non-experts',
  // ECTS
  'ects.text': 'Wir werden dich darüber informieren, wie du nach dem Absolvieren des Kurses ECTS-Credits beantragen kannst.',
  'ects.textCompleted': 'Wenn du eine finnische Sozialversicherungsnummer hast und Credits beantragen möchtest, klicke bitte auf ',
  'ects.link': 'hier.',
  'ects.info': 'Would you like to apply for ECTS credits?',
  'ects.readMore': 'Read more',
  // Countries
  'countryselect.select': "Please select",
  'Finland': "Finnland",
  'Estonia': "Estland",
  'Germany': "Deutschland",
  'Sweden': "Schweden",
  'Norway': "Norwegen",
  "Latvia": "Lettland",
  "Countries": {
    'Afganistan': "Afghanistan",
    'Åland Islands': "Åland-Inseln",
    'Albania': "Albanien",
    'Algeria': "Algerien",
    'American Samoa': "Amerikanischen Samoa-Inseln",
    'Andorra': "Andorra",
    'Angola': "Angola",
    'Anguilla': "Anguilla",
    'Antarctica': "Antarktis",
    'Antigua and Barbuda': "Antigua und Barbuda",
    'Argentina': "Argentinien",
    'Armenia': "Armenien",
    'Aruba': "Aruba",
    'Australia': "Australien",
    'Austria': "Österreich",
    'Azerbaijan': "Aserbaidschan",
    'Bahamas': "Bahamas",
    'Bahrain': "Bahrain",
    'Bangladesh': "Bangladesch",
    'Barbados': "Barbados",
    'Belarus': "Weißrussland",
    'Belgium': "Belgien",
    'Belize': "Belize",
    'Benin': "Benin",
    'Bermuda': "Bermuda",
    'Bhutan': "Bhutan",
    'Bolivia': "Bolivien",
    'Bosnia and Herzegovina': "Bosnien und Herzegowina",
    'Botswana': "Botswana",
    'Bouvet Island': "Bouvetinsel",
    'Brazil': "Brasilien",
    'British Indian Ocean Territory': "Britisches Territorium des Indischen Ozeans",
    'Brunei Darussalam': "Brunei Darussalam",
    'Bulgaria': "Bulgarien",
    'Burkina Faso': "Burkina Faso",
    'Burundi': "Burundi",
    'Cambodia': "Kambodscha",
    'Cameroon': "Kamerun",
    'Canada': "Kanada",
    'Cape Verde': "Kap Verde",
    'Cayman Islands': "Cayman Inseln",
    'Central African Republic': "Zentralafrikanische Republik",
    'Chad': "Tschad",
    'Chile': "Chile",
    'China': "China",
    'Christmas Island': "Weihnachtsinsel",
    'Cocos (Keeling) Islands': "Kokosinseln (Keelinginseln)",
    'Colombia': "Kolumbien",
    'Comoros': "Komoren",
    'Congo': "Kongo",
    'Congo, The Democratic Republic of The': "Kongo, Demokratische Republik",
    'Cook Islands': "Cookinseln",
    'Costa Rica': "Costa Rica",
    "Cote D'ivoire": "Elfenbeinküste",
    'Croatia': "Kroatien",
    'Cuba': "Kuba",
    'Cyprus': "Zypern",
    'Czech Republic': "Tschechische Republik",
    'Denmark': "Dänemark",
    'Djibouti': "Dschibuti",
    'Dominica': "Dominica",
    'Dominican Republic': "Dominikanische Republik",
    'Ecuador': "Ecuador",
    'Egypt': "Ägypten",
    'El Salvador': "El Salvador",
    'Equatorial Guinea': "Äquatorialguinea",
    'Eritrea': "Eritrea",
    'Estonia': "Estland",
    'Ethiopia': "Äthiopien",
    'Falkland Islands (Malvinas)': "Falklandinseln (Malvinas)",
    'Faroe Islands': "Färöer Inseln",
    'Fiji': "Fidschi",
    'Finland': "Finnland",
    'France': "Frankreich",
    'French Guiana': "Französisch-Guayana",
    'French Polynesia': "Französisch Polynesien",
    'French Southern Territories': "Südfranzösische Territorien",
    'Gabon': "Gabun",
    'Gambia': "Gambia",
    'Georgia': "Georgia",
    'Germany': "Deutschland",
    'Ghana': "Ghana",
    'Gibraltar': "Gibraltar",
    'Greece': "Griechenland",
    'Greenland': "Grönland",
    'Grenada': "Grenada",
    'Guadeloupe': "Guadeloupe",
    'Guam': "Guam",
    'Guatemala': "Guatemala",
    'Guernsey': "Guernsey",
    'Guinea': "Guinea",
    'Guinea-Bissau': "Guinea-Bissau",
    'Guyana': "Guyana",
    'Haiti': "Haiti",
    'Heard Island and Mcdonald Islands': "Heard Island und Mcdonald Islands",
    'Holy See (Vatican City State)': "Heiliger Stuhl",
    'Honduras': "Honduras",
    'Hong Kong': "Hongkong",
    'Hungary': "Ungarn",
    'Iceland': "Island",
    'India': "Indien",
    'Indonesia': "Indonesien",
    'Iran, Islamic Republic of': "Iran, Islamische Republik",
    'Iraq': "Irak",
    'Ireland': "Irland",
    'Isle of Man': "Isle of Man",
    'Israel': "Israel",
    'Italy': "Italien",
    'Jamaica': "Jamaika",
    'Japan': "Japan",
    'Jersey': "Jersey",
    'Jordan': "Jordan",
    'Kazakhstan': "Kasachstan",
    'Kenya': "Kenia",
    'Kiribati': "Kiribati",
    "Korea, Democratic People's Republic of": "Korea, Demokratische Volksrepublik",
    'Korea, Republic of': "Korea, Republik von",
    'Kuwait': "Kuwait",
    'Kosovo': 'Kosovo',
    'Kyrgyzstan': "Kirgisistan",
    "Lao People's Democratic Republic": "Demokratische Volksrepublik Laos",
    'Latvia': "Lettland",
    'Lebanon': "Libanon",
    'Lesotho': "Lesotho",
    'Liberia': "Liberia",
    'Libyan Arab Jamahiriya': "Libyscher Araber Jamahiriya",
    'Liechtenstein': "Liechtenstein",
    'Lithuania': "Litauen",
    'Luxembourg': "Luxemburg",
    'Macao': "Macao",
    'Macedonia, The Former Yugoslav Republic of': "Mazedonien, die ehemalige jugoslawische Republik",
    'Madagascar': "Madagaskar",
    'Malawi': "Malawi",
    'Malaysia': "Malaysia",
    'Maldives': "Malediven",
    'Mali': "Mali",
    'Malta': "Malta",
    'Marshall Islands': "Marshallinseln",
    'Martinique': "Martinique",
    'Mauritania': "Mauretanien",
    'Mauritius': "Mauritius",
    'Mayotte': "Mayotte",
    'Mexico': "Mexiko",
    'Micronesia, Federated States of': "Mikronesien, Föderierte Staaten von",
    'Moldova, Republic of': "Moldawien, Republik",
    'Monaco': "Monaco",
    'Mongolia': "Mongolei",
    'Montenegro': "Montenegro",
    'Montserrat': "Montserrat",
    'Marocco': "Marokko",
    'Mozambique': "Mosambik",
    'Myanmar': "Myanmar",
    'Namibia': "Namibia",
    'Nauru': "Nauru",
    'Nepal': "Nepal",
    'Netherlands': "Niederlande",
    'Netherlands Antilles': "Niederländische Antillen",
    'New Caledonia': "Neu-Kaledonien",
    'New Zealand': "Neuseeland",
    'Nicaragua': "Nicaragua",
    'Niger': "Niger",
    'Nigeria': "Nigeria",
    'Niue': "Niue",
    'Norfolk Island': "Norfolkinsel",
    'Northern Mariana Islands': "Nördliche Marianneninseln",
    'Norway': "Norwegen",
    'Oman': "Oman",
    'Pakistan': "Pakistan",
    'Palau': "Palau",
    'Palestinian Territory, Occupied': "Besetzte palästinensische Gebiete",
    'Panama': "Panama",
    'Papua New Guinea': "Papua Neu-Guinea",
    'Paraguay': "Paraguay",
    'Peru': "Peru",
    'Philippines': "Philippinen",
    'Pitcairn': "Pitcairn",
    'Poland': "Polen",
    'Portugal': "Portugal",
    'Puerto Rico': "Puerto Rico",
    'Qatar': "Katar",
    'Reunion': "Réunion",
    'Romania': "Rumänien",
    'Russian Federation': "Russische Föderation",
    'Rwanda': "Ruanda",
    'Saint Helena': "St. Helena",
    'Saint Kitts and Nevis': "St. Kitts und Nevis",
    'Saint Lucia': "St. Lucia",
    'Saint Pierre and Miquelon': "Saint Pierre und Miquelon",
    'Saint Vincent and The Grenadines': "St. Vincent und die Grenadinen",
    'Samoa': "Samoa",
    'San Marino': "San Marino",
    'Sao Tome and Principe': "Sao Tome und Principe",
    'Saudi Arabia': "Saudi Arabien",
    'Senegal': "Senegal",
    'Serbia': "Serbien",
    'Seychelles': "Seychellen",
    'Sierra Leone': "Sierra Leone",
    'Singapore': "Singapur",
    'Slovakia': "Slowakei",
    'Slovenia': "Slowenien",
    'Solomon Islands': "Salomon-Inseln",
    'Somalia': "Somalia",
    'South Africa': "Südafrika",
    'South Georgia and The South Sandwich Islands': "Süd-Georgien und die südlichen Sandwich-Inseln",
    'Spain': "Spanien",
    'Sri Lanka': "Sri Lanka",
    'Sudan': "Sudan",
    'Suriname': "Suriname",
    'Svalbard and Jan Mayen': "Spitzbergen und Jan Mayen",
    'Swaziland': "Swasiland",
    'Sweden': "Schweden",
    'Swizerland': "Schweiz",
    'Syrian Arab Republic': "Syrische Arabische Republik",
    'Taiwan': "Taiwan",
    'Tajikistan': "Tadschikistan",
    'Tanzania, United Republic of': "Tansania, Vereinigte Republik",
    'Thailand': "Thailand",
    'Timor-leste': "Timor-leste",
    'Togo': "Togo",
    'Tokelau': "Tokelau",
    'Tonga': "Tonga",
    'Trinidad and Tobago': "Trinidad und Tobago",
    'Tunisia': "Tunesien",
    'Turkey': "Türkei",
    'Turkmenistan': "Turkmenistan",
    'Turks and Caicos Islands': "Turks- und Caicosinseln",
    'Tuvalu': "Tuvalu",
    'Uganda': "Uganda",
    'Ukraine': "Ukraine",
    'United Arab Emirates': "Vereinigte Arabische Emirate",
    'United Kingdom': "Großbritannien",
    'United States': "Vereinigte Staaten",
    'United States Minor Outlying Islands': "United States Minor Outlying Islands",
    'Uruguay': "Uruguay",
    'Uzbekistan': "Usbekistan",
    'Vanuatu': "Vanuatu",
    'Venezuela': "Venezuela",
    'Viet Nam': "Vietnam",
    'Virgin Islands, British': "Virgin Inseln, Britisch",
    'Virgin Islands, U.S.': "Amerikanische Jungferninseln",
    'Wallis and Futuna': "Wallis und Futuna",
    'Western Sahara': "Westsahara",
    'Yemen': "Jemen",
    'Zambia': "Sambia",
    'Zimbabwe': "Zimbabwe"
  },
  //Alt texts
  'alt.example-1': 'zwei selbstfahrende Autos',
  'alt.example-2': 'Hand mit einem Mobiltelefon',
  'alt.example-3': "Gesicht einer Frau",
  'alt.defining-ai': 'ein Haufen Wörter mit Bezug auf KI',
  'alt.turing-test': 'Mann chattet am Computer mit einem Roboter und einer Frau',
  'alt.chinese-room': 'das Experiment des chinesischen Zimmers',
  'alt.chicken-crossing': 'Roboter im Boot schaut auf das Huhn, den Fuchs und das Hühnerfutter',
  'alt.a-to-b': 'Hand mit einem Telefon, auf dem eine Navigations-App verwendet wird',
  'alt.tic-tac-toe': 'zwei Kinder spielen Tic Tac Toe',
  'alt.self-driving-car': 'selbstfahrendes Auto hält am Stopp-Schild',
  'alt.spam-or-ham': 'eine normale E-Mail und eine Spam-Nachricht',
  'alt.mnist': 'MNIST-Datensatz',
  'alt.nearest-neighbor': 'zwei Menschen beim Shoppen',
  'alt.nearest-neighbor-graph': 'der Nächste-Nachbarn-Klassifikator',
  'alt.recommendation': 'Mann und Frau hören Musik über das Telefon',
  'alt.price-real-estate': 'drei verschiedene Häuser',
  'alt.supervised-learning': 'Mensch unterricht einen Roboter',
  'alt.dice': 'Würfel',
  'alt.chess': 'Schachbrett',
  'alt.poker': 'Hand mit Spielkarten',
  'alt.eyes': 'drei Menschen mit braunen Augen, eine Person mit blauen Augen',
  'alt.search-1': 'ein einziger Weg führt zum Ziel',
  'alt.search-2': 'vier verschiedene Wege führen zum selben Ziel',
  'alt.chicken-crossing-1': 'Huhn überquert den Anfangszustand',
  'alt.chicken-crossing-2': 'Huhn überquert den ersten Übergang',
  'alt.chicken-crossing-3': 'Huhn überquert alle Übergänge',
  'alt.chicken-crossing-4': 'Huhn überquert mit bestem Weg',
  'alt.chicken-crossing-5': 'Huhn überquert den Anfangszustand',
  'alt.implication-1': 'Mann und Frau',
  'alt.implication-2': 'Augen',
  'alt.implication-3': 'Überwachungskamera',
  'alt.implication-4': 'Roboter arbeitet an einem Laptop',
  'alt.oddchange': 'Sonne und Wolken',
  'alt.brain': 'Zeichnung eines Gehirns',
  'alt.carstop': 'selbstfahrendes Auto hält am Stopp-Schild',
  'alt.gan': 'Roboter hält ein Bild einer Katze und ein Bild eines Stopp-Schilds in der Hand',
  'alt.hammock': 'Roboter fächert für einen Mann in einer Hängematte',
  'alt.game-tree-1': 'Spielbaum 1',
  'alt.game-tree-2': 'Spielbaum 2',
  'alt.game-tree-3': 'Spielbaum 3',
  'alt.game-tree-4': 'Spielbaum 4',
  'alt.bayes-rule-1': 'Bild zeigt falsche positive und negative Ergebnisse bei Krebsvorsorgeuntersuchung',
  'alt.bayes-rule-2': 'Bild zeigt falsche positive und negative Ergebnisse bei Krebsvorsorgeuntersuchung',
  'alt.defineai': 'ein Haufen Wörter mit Bezug auf KI',
  'alt.winter': 'Roboter schläft im Schnee',
  'alt.terminator': 'gruseliger Roboter'

}
