// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'cs',
  'globals.signIn': 'Přihlásit se',
  'globals.logIn': 'Přihlásit se',
  'globals.signUp': 'Registrace',
  'globals.signOut': 'Odhlásit se',
  'globals.logOut': 'Odhlásit se',
  'globals.submit': 'Odeslat',
  'globals.submitting': 'Odesílá se...',
  'globals.yourEmail': 'Váš e-mail',
  'globals.yourPassword': 'Vaše heslo',
  'globals.courseOverview': 'Přehled kurzu',
  'globals.forgotPassword': 'Zapomněli jste heslo?',
  'globals.invalidCredentials': 'Neplatné údaje',
  'globals.section': 'Oddíl',
  'globals.exercises': 'Úkoly',
  'globals.chapter': 'Kapitola',
  'globals.introToAI': 'Úvod do AI',
  'globals.buildingAI': 'Budování AI',
  'globals.acceptPolicy': 'Přijměte politiku',
  // section
  'section.footerEndOfTheChapter': 'Jste na konci kapitoly {chapter}!',
  'section.footerEndOfTheCourseTitle': 'Jste na konci kurzu!',
  'section.footerEndOfTheCourseText': 'Po dokončení všech úkolů a vzájemných hodnocení ohodnotí vaše odpovědi naši lektoři.',
  'section.footerEndOfTheCourseBAI': 'Pokračujte ve své cestě k realizaci svého prvního nápadu UI.',
  'section.nextSection': 'Další oddíl',
  'section.nextChapter': 'Další kapitola',
  'section.start': 'Začít',
  'section.logisticRegressionExample.slope': 'Sklon',
  'section.logisticRegressionExample.intercept': 'Průsečík',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Čeká na vaše hodnocení',
  'quiz.rejectedPeerReview': 'Odmítnuto v rámci hodnocení',
  'quiz.unsubmittedAnswer': 'Neodeslaná odpověď',
  'quiz.pendingPeerReview': 'Čeká se na hodnocení',
  // quiz
  'quiz.status.answered': 'Zodpovězeno',
  'quiz.status.rejected': 'Rejected answer, try again',
  'quiz.status.unanswered': 'Nezodpovězeno',
  'quiz.status.exercisesCompleted': 'Dokončeno úkolů',
  'quiz.status.correctAnswers': 'Správné odpovědi',
  'quiz.message.rejectedInReview': 'Vaše předchozí odpověď byla v rámci hodnocení odmítnuta. Zkuste to prosím znovu.',
  'quiz.youAnswered': 'Vaše odpověď',
  'quiz.yourAnswer': 'Vaše odpověď...',
  'quiz.yourScore': 'Vaše skóre:',
  'quiz.quizNotLoaded': 'Úkol se nepodařilo načíst',
  'quiz.words': 'Počet slov:',
  'quiz.exampleAnswer': 'Příklad odpovědi:',
  // peer review
  'peerreview.question1': 'Odpověď se vztahuje k tématu',
  'peerreview.question2': 'Odpověď je vyčerpávající',
  'peerreview.question3': 'Odpověď je dobře zdůvodněná',
  'peerreview.question4': 'Odpověď je snadno srozumitelná',
  'peerreview.thankYou': 'Děkujeme vám za ohodnocení dalších účastníků kurzu!',
  'peerreview.giveMoreReviews': 'Pokud máte chvilku,',
  'peerreview.giveMoreReviewsButton': 'ohodnoťte další odpovědi',
  'peerreview.notEnoughAnswers': 'Zde by mělo být vzájemné hodnocení, ale úkol zatím nedokončil dostatečný počet osob.',
  'peerreview.selectAnswerMessage': 'Je čas ohodnotit odpovědi ostatních! Níže naleznete několik odpovědí ostatních studentů. Vyberte jednu z nich a ohodnoťte ji. Tento postup zopakujte alespoň třikrát.',
  'peerreview.minimumPeerReviewWarning': 'Úkol může být přijat až poté, co ohodnotíte alespoň tři odpovědi dalších účastníků.',
  'peerreview.select': 'Vybrat',
  'peerreview.reportSpam': 'Nahlásit spam',
  'peerreview.feedback': 'Vaše odpověď byla ohodnocena a přijata. Takto ji ohodnotili další účastníci kurzu:',
  // exercise17
  'quiz.exercise17.xLabel': 'Počet let vzdělání',
  'quiz.exercise17.yLabel': 'Předpokládaná délka života v letech',
  'quiz.exercise17.textPart1': 'Podívejme se na souvislost mezi celkovým počtem let strávených ve škole (od předškolního až po vysokoškolské vzdělání) a předpokládanou délkou života. Zde jsou data ze tří různých zemí, znázorněná v grafu pomocí teček:',
  'quiz.exercise17.textPart2': 'Máme jednu zemi, kde je průměrný počet let ve škole 10 a předpokládaná délka života 57 let, další zemi, kde je průměrný počet let ve škole 13 a předpokládaná délka života 53 let, a třetí zemi, kde je průměrný počet let ve škole 20 a předpokládaná délka života 80 let.',
  'quiz.exercise17.textPart3': 'Pomocí myši můžete přetáhnout koncové body plné čáry tak, aby čára odpovídala trendu zakreslených bodů. Upozorňujeme, že čára nebude bodům odpovídat dokonale, ale to nevadí: některé body budou ležet nad čarou a některé pod ní. Nejdůležitější je, aby čára popisovala celkový trend.',
  'quiz.exercise17.textPart4': 'Po umístění této čáry ji můžete použít k předpovězení délky života.',
  'quiz.exercise17.textPart5': 'Co lze s ohledem na data říci o předpokládané délce života lidí, kteří chodili do školy 15 let? Důležité: i když upravením umístění čáry získáte konkrétní předpověď na zlomek roku přesně, tato předpověď nutně nemusí být spolehlivá. Ve své odpovědi zohledněte omezené množství dat.',
  // exercise18
  'quiz.exercise18.xLabel': 'Počet let vzdělání',
  'quiz.exercise18.yLabel': 'Předpokládaná délka života v letech',
  'quiz.exercise18.textPart1': 'V předchozím úkolu jsme měli data jen ze tří zemí. Celý datový soubor se skládá z dat ze 14 různých zemí, jak uvádí tento graf:',
  'quiz.exercise18.textPart2': 'Změnila by se vaše předpověď předpokládané délky života lidí, kteří chodili 15 let do školy, na základě těchto dat? Pokud ano, proč?',
  'quiz.exercise18.textPart3': 'Která z následujících možností by nejlépe odpovídala vašemu odhadu předpokládané délky života lidí, kteří chodili 15 let do školy? Vyberte nejkonkrétnější možnost, která je podle vás odůvodněná, uplatněním modelu přímky na výše uvedená data.',
  // exercise19
  'quiz.exercise19.xLabel': 'Počet hodin studia',
  'quiz.exercise19.yLabel': 'Pravděpodobnost úspěchu',
  'quiz.exercise19.textPart1': 'Každá tečka v diagramu odpovídá jednomu studentovi. Ve spodní části diagramu máme stupnici počtu hodin, která vyjadřuje, jak dlouho se student na zkoušku připravoval, přičemž úspěšní studenti jsou zobrazeni jako tečky v horní části grafu a neúspěšní jsou zobrazeni dole. Stupnici vlevo použijeme k určení předvídané pravděpodobnosti úspěchu, kterou získáme na základě modelu logistické regrese, jak vysvětlujeme níže. Na základě tohoto diagramu zhruba vidíte, že studenti, kteří se déle připravovali, měli vyšší šanci na úspěch v kurzu. Obzvláště extrémní případy jsou intuitivní: pokud student práci věnuje ani ne hodinu, kurzem projde jen stěží, ale po poctivé přípravě většina studentů uspěje. Co ale s těmi, kteří spadají někam mezi tyto dva extrémy? Jaká je vaše šance na úspěch, budete-li studovat 6 hodin?',
  'quiz.exercise19.textPart2': 'Pravděpodobnost úspěchu můžeme vyčíslit s použitím logistické regrese. Křivku v diagramu lze interpretovat jako pravděpodobnost úspěchu: například v případě přibližně pětihodinové přípravy je pravděpodobnost úspěchu něco málo přes 20 %. Nebudeme zde zacházet do detailů, jak křivku získat, ale je to podobné zjišťování vah v lineární regresi.',
  'quiz.exercise19.textPart3': 'Pokud chcete mít 80% šanci na úspěch při zkoušce na vysoké škole, kolik hodin byste se podle výše uvedeného diagramu měli učit?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Vstup',
  'quiz.exercise22.sigmoidYLabel': 'Výstup sigmoidy',
  'quiz.exercise22.stepXLabel': 'Vstup',
  'quiz.exercise22.stepYLabel': 'Výstup skokové funkce',
  'quiz.exercise22.identityXLabel': 'Vstup',
  'quiz.exercise22.identityYLabel': 'Výstup identity',
  'quiz.exercise22.textPart1': 'Níže jsou uvedeny grafy pro tři různé aktivační funkce s různými vlastnostmi. Jako první je zobrazena sigmoidální funkce, následuje skoková funkce a poslední graf zobrazuje funkci identity.',
  // exercise23
  'quiz.exercise23.happyFaces': 'správně klasifikované šťastné obličeje',
  'quiz.exercise23.sadFaces': 'správně klasifikované smutné obličeje',
  // certificate availability
  'certificate.failedToCheck': 'Nepodařilo se ověřit dostupnost certifikátu',
  'certificate.failedToGenerate': 'Nepodařilo se vygenerovat certifikát',
  'certificate.notYetAvailable': 'Váš certifikát bude k dispozici zde, jakmile jej oficiálně potvrdíme. Může to chvíli trvat. Děkujeme vám za trpělivost!',
  'certificate.updateName': 'Váš certifikát je připraven! Aktualizujte si prosím křestní jméno a příjmení pomoci formuláře ve svém profilu, abychom je mohli použít pro vytvoření certifikátu.',
  'certificate.generate': 'Níže si můžete vygenerovat svůj certifikát.',
  'certificate.generateTextPart1': 'Certifikát bude vystaven pro',
  'certificate.generateTextPart2': 'Před tím, než si vygenerujete certifikát, se ujistěte, že je vaše jméno uvedeno správně – následně už jej nebude možné změnit. Chcete-li své jméno změnit, použijte prosím níže uvedený formulář.',
  'certificate.generateButton': 'Vygenerovat certifikát',
  'certificate.downloadButton': 'Stáhnout certifikát',
  'certificate.checkingAvailability': 'Ověřování dostupnosti certifikátu...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Klasifikováno správně',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Klasifikováno špatně',
  // section blocks
  'section.block.note': 'Poznámka',
  'section.block.example': 'Příklad',
  'section.block.keyTerminology': 'Klíčové pojmy',
  'section.block.additionalResources': 'Další zdroje',
  'section.partSummary.spectrumTextPart1': 'Připojte se ke komunitě kurzu Elements of AI ',
  'section.partSummary.spectrum': 'zde',
  'section.partSummary.spectrumTextPart2': ' – budete se tak moci zapojit do diskuse a klást otázky ohledně této kapitoly.',
  'section.partSummary.feedbackTitle': 'Poskytněte nám zpětnou vazbu ohledně této části',
  'section.partSummary.feedbackPlaceholder': 'Sem uveďte svou zpětnou vazbu...',
  'section.partSummary.feedbackSuccess': 'Děkujeme vám za zpětnou vazbu!',
  // 404
  'globals.404.title': '404 – Stránka nenalezena',
  'globals.404.intro': "Požadovanou stránku se nepodařilo nalézt.",
  'globals.loading': 'Načítá se...',
  // footer / menu
  'globals.menu': 'Nabídka',
  'globals.menu.about': 'O kurzu',
  'globals.menu.faq': 'Často kladené otázky',
  'globals.menu.privacyPolicy': 'Zásady ochrany osobních údajů',
  'globals.menu.termsofService': 'Podmínky služby',
  'globals.menu.myProfile': 'Můj profil',
  // Dashboard/Index
  'dashboard.title': 'Vítejte v kurzu!',
  'dashboard.congratulations.title': 'Blahopřejeme!',
  'dashboard.congratulations.message': 'Dokončili jste kurz Elements of AI',
  'dashboard.congratulations.downloadCertificate': 'Download certificate',
  'dashboard.notLoggedIn.title': 'Vítejte!',
  'dashboard.notLoggedIn.message': 'Pro získání přístupu k celému kurzu se zaregistrujte nebo přihlaste. Je to zdarma!',
  // Account
  'account.certificate': 'Certifikát',
  'account.myProfile': 'Můj profil',
  'account.profileInformation': 'Profilové informace',
  'account.credits': 'Kredity ECTS',
  'account.changePassword': 'Změnit heslo',
  'account.changeLanguage': 'Změnit jazyk',
  'account.goToCourse': 'Přejít na kurz',
  'account.buildingAINew': 'Nový kurz v angličtině',
  'account.accept': 'Přijměte',
  'account.decline': 'Odmítněte',
  'account.courses': 'Kurzy',
  // Sign Up page
  'signUp.description': 'Pro založení nového účtu mooc.fi vyplňte níže uvedený formulář',
  'signUp.email': 'Váš e-mail',
  'signUp.firstName': 'Vaše křestní jméno',
  'signUp.lastName': 'Vaše příjmení',
  'signUp.postalCode': 'Poštovní směrovací číslo',
  'signUp.country': 'Kde máte bydliště?',
  'signUp.Password': 'Heslo',
  'signUp.confirmPassword': 'Potvrďte heslo',
  'signUp.howToStudyTitle': 'Jakým způsobem chcete studovat?',
  'signUp.6weeksTitle': 'Kurz v délce 6 týdnů',
  'signUp.6weeksSubTitle': 'Doporučeno (přibližně 5 hodin týdně)',
  'signUp.6weeksText': 'Je prokázané, že kurz snáze dokončíte, pokud si pro dokončení stanovíte termín.',
  'signUp.selfPacedTitle': 'Vlastní tempo',
  'signUp.selfPacedText': 'Kurz můžete absolvovat vlastním tempem.',
  'signUp.ectsTitle': 'Žádáte o kredity ECTS?',
  'signUp.ectsTitleSwe': ' ',
  'signUp.ectsStudent': 'Jsem studentem/studentkou Helsinské univerzity. Pokud zaškrtnete toto políčko a vyplníte své číslo studenta, budou vám kredity ECTS uděleny automaticky.',
  'signUp.ectsStudentSwe': ' ',
  'signup.ectsStudentSweLink': ' ',
  'signUp.ectsOpenUni': 'Zaškrtněte toto políčko, pokud chcete obdržet e-mail s instrukcemi, jak zažádat o kredity ECTS',
  'signUp.studentNumber': 'Číslo studenta Helsinské univerzity (jste-li student/ka)',
  'signUp.privacyOptIn': 'Uděluji souhlas s použitím shromážděných údajů o mém studijním chování pro výzkumné účely. Údaje obsahují informace vycházející z použití materiálů a z úkolů v rámci kurzu. Na základě publikací není možné identifikovat žádné osoby.',
  'signUp.marketingOptIn': 'Souhlasím s tím, že budu dostávat aktuální informace o nadcházejících jazykových verzích a informace ohledně nových kurzů. Souhlasím se sdílením svých kontaktních údajů za účelem přijímání zpráv na míru na platformách třetích stran. Přečtěte si, jak chráníme vaše soukromí.',
  'signUp.acceptPolicyFirst': 'Přijímám zásady ochrany osobních údajů a podmínky služby.',
  'signUp.privacyLink': 'Ochrana soukromí',
  'signUp.error': 'Vámi vyplněný formulář obsahuje chyby. Zkontrolujte prosím údaje ve formuláři.',
  // Sign in page
  'signIn.title': 'Přihlásit se',
  'signIn.intro': 'Pro přihlášení prostřednictvím vašeho účtu mooc.fi použijte níže uvedený formulář',
  'signIn.email': 'Váš e-mail',
  'signIn.password': 'Vaše heslo',
  'signIn.noAccount': "Nemáte účet?",
  'signIn.signUpNow': 'Zaregistrujte se nyní',
  // Forgot password
  'pwForget.title': 'Zapomenuté heslo',
  'pwForget.description': 'Zadejte na svém účtu mooc.fi e-mailovou adresu a my vám pošleme odkaz pro obnovení hesla',
  'pwForget.sendResetLink': 'Poslat odkaz pro obnovení hesla',
  'pwForget.completedDescription': 'Měl/a byste brzy obdržet e-mail z domény mooc.fi s odkazem pro obnovení hesla. Pokud e-mail ve své schránce nevidíte, zkontrolujte složku se spamem.',
  'pwForget.problemDescription': 'V případě jakýchkoli problémů se obraťte na mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent': 'Byl odeslán e-mail s odkazem pro obnovení hesla',
  'pwForget.emailPlaceholder': 'E-mailová adresa',
  // password change
  'pwChange.authError': 'Ověření bylo neúspěšné. Zkuste se prosím přihlásit znovu',
  'pwChange.unexpError': 'Heslo se nepodařilo aktualizovat z důvodu neočekávané chyby',
  'pwChange.required': 'Povinné údaje',
  'pwChange.pwMustMatch': 'Hesla se musí shodovat',
  'pwChange.currentPassword': 'Stávající heslo',
  'pwChange.newPassword': 'Nové heslo',
  'pwChange.confirmPassword': 'Potvrdit nové heslo',
  'pwChange.changingPassword': 'Probíhá změna hesla...',
  'pwChange.changePassword': 'Změnit heslo',
  'pwChange.passwordChanged': 'Heslo bylo změněno',
  // user details
  'userDetails.authError': 'Ověření bylo neúspěšné. Zkuste se prosím přihlásit znovu',
  'userDetails.unexpError': 'Profil se nepodařilo aktualizovat z důvodu neočekávané chyby',
  'userDetails.required': 'Povinné údaje',
  'userDetails.invalidEmail': 'Neplatná e-mailová adresa',
  'userDetails.email': 'E-mail',
  'userDetails.emailAddress': 'E-mailová adresa',
  'userDetails.firstName': 'Křestní jméno',
  'userDetails.lastName': 'Příjmení',
  'userDetails.language': 'Jazyk',
  'userDetails.updatingProfile': 'Probíhá aktualizace profilu...',
  'userDetails.updateProfile': 'Aktualizovat profil',
  'userDetails.profileUpdated': 'Profil byl aktualizován',
  // languages
  'languages.en.long': 'angličtina',
  'languages.fi.long': 'finština',
  'languages.se.long': 'švédština',
  'languages.de.long': 'němčina (Německo)',
  'languages.ee.long': 'estonština',
  'languages.no.long': 'norština',
  'languages.lv.long': 'lotyština',
  'languages.cs.long': 'čeština',
  'languages.da.long': 'dánština',
  'languages.pl.long': 'polština',
  'languages.lt.long': 'litevština',
  'languages.fr.long': 'francouzština (Francie)',
  'languages.fr-be.long': 'francouzština (Belgie)',
  'languages.nl-be.long': 'nizozemština (Belgie)',
  'languages.it.long': 'italština',
  'languages.mt.long': 'maltština',
  'languages.en-ie.long': 'angličtina (Irsko)',
  'languages.hr.long': 'chorvatština',
  'languages.ro.long': 'rumunština',
  'languages.en-lu.long': 'angličtina (Lucembursko)',
  'languages.bg.long': 'bulharština',
  'languages.sl.long': 'slovinština',
  'languages.de-at.long': 'němčina (Rakousko)',
  'languages.nl.long': 'nizozemština (Nizozemsko)',
  'languages.sk.long': 'slovenština',
  'languages.pt.long': 'portugalština',
  'languages.es.long': 'španělština',
  'languages.el.long': 'řečtina',
  'languages.ga.long': 'irština',
  'languages.is.long': 'islandština',
  // Email verfification
  'emailVerification.completedMessage': 'Vaše e-mailová adresa byla ověřena',
  'emailVerification.verified': 'Ověřeno',
  // Exercises
  'exercise.loading': 'Úkol se načítá...',
  'exercise.signUp': 'Pro získání přístupu k úkolům se zaregistrujte',
  'exercise.score': 'Vaše skóre:',
  'exercise.wrongLocale': 'Chybné národní prostředí',
  // Part tempplate
  'partTemplate.intro1': 'V kapitole ',
  'partTemplate.intro2': ' se budeme zabývat následujícími tématy. Pro zahájení klikněte níže:',
  'partTemplate.spectrumPart1': 'Připojte se ke komunitě kurzu Elements of AI ',
  'partTemplate.spectrum': 'zde',
  'partTemplate.spectrumPart2': ' – budete se tak moci zapojit do diskuse a klást otázky ohledně této kapitoly.',
  // missing info
  'missingInfo.title': 'Chybějící informace',
  'missingInfo.body': 'Na tyto otázky se obvykle ptáme při registraci nových uživatelů, vy jste se však přihlásil/a prostřednictvím existujícího účtu mooc.fi. Chcete-li pokračovat, vyplňte prosím níže uvedený formulář.',
  'missingInfo.privacyPolicy': 'Zásady ochrany osobních údajů',
  'missingInfo.error': 'Vámi vyplněný formulář obsahuje chyby. Zkontrolujte prosím údaje ve formuláři.',
  'missingInfo.privacyPolicyUpdateBody': "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
  'missingInfo.privacyPolicyUpdateLink': 'Zásady ochrany osobních údajů si můžete přečíst zde.',
  // routes
  'routes.LANDING': '/cs',
  'routes.SIGN_UP': '/cs/signup',
  'routes.SIGN_IN': '/cs/signin',
  'routes.MISSING_INFO': '/cs/missinginfo',
  'routes.PASSWORD_FORGET': '/cs/pw-forget',
  'routes.HOME': '/cs/home',
  'routes.ACCOUNT': '/cs/account',
  'routes.ACCEPT_POLICY': '/cs/accept-policy',
  'routes.PRIVACY': 'https://www.elementsofai.cz/faq/z%C3%A1sady-ochrany-osobn%C3%ADch-%C3%BAdaj%C5%AF',
  'routes.FAQ': 'https://www.elementsofai.cz/faq',
  'routes.ABOUT': 'https://www.elementsofai.cz',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/vitejte/',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/cs/faq/jak-mohu-z%C3%ADskat-2-kredity-ects',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Počet šálků kávy denně',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Řádky napsaného kódu',
  // meta
  'meta.title': 'Bezplatný online kurz',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Přečtěte si více o kurzu umělé inteligence pro studenty a profesionály, který společně připravily Helsinská univerzita a společnost Reaktor – nepotřebujete ovládat programování ani matematiku.',
  'meta.sitename': 'Elements of AI',
  // ECTS
  'ects.text': 'Zde po dokončení kurzu obdržíte instrukce, jak zažádat o kredity ECTS.',
  'ects.textCompleted': 'Pokud máte finské číslo sociálního zabezpečení, můžete o kredity zažádat kliknutím',
  'ects.link': 'zde.',
  'ects.info': 'Chcete požádat o kredity ECTS?',
  'ects.readMore': 'Přečtěte si více',
  // Countries
  'countryselect.select': "Prosím vyberte",
  'Finland': 'Finsko',
  'Estonia': 'Estonsko',
  'Germany': 'Německo',
  'Sweden': 'Švédsko',
  'Norway': 'Norsko',
  'Latvia': 'Lotyšsko',
  "Countries": {
    "Afganistan": "Afghánistán",
    "Åland Islands": "Ålandské ostrovy",
    "Albania": "Albánie",
    "Algeria": "Alžírsko",
    "American Samoa": "Americká Samoa",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antarctica": "Antarktida",
    "Antigua and Barbuda": "Antigua a Barbuda",
    "Argentina": "Argentina",
    "Armenia": "Arménie",
    "Aruba": "Aruba",
    "Australia": "Austrálie",
    "Austria": "Rakousko",
    "Azerbaijan": "Ázerbajdžán",
    "Bahamas": "Bahamy",
    "Bahrain": "Bahrajn",
    "Bangladesh": "Bangladéš",
    "Barbados": "Barbados",
    "Belarus": "Bělorusko",
    "Belgium": "Belgie",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermudy",
    "Bhutan": "Bhútán",
    "Bolivia": "Bolívie",
    "Bosnia and Herzegovina": "Bosna a Hercegovina",
    "Botswana": "Botswana",
    "Bouvet Island": "Bouvetův ostrov",
    "Brazil": "Brazílie",
    "British Indian Ocean Territory": "Britské území v Indickém oceánu",
    "Brunei Darussalam": "Brunej Darussalam",
    "Bulgaria": "Bulharsko",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambodia": "Kambodža",
    "Cameroon": "Kamerun",
    "Canada": "Kanada",
    "Cape Verde": "Kapverdy",
    "Cayman Islands": "Kajmanské ostrovy",
    "Central African Republic": "Středoafrická republika",
    "Chad": "Čadu",
    "Chile": "Chile",
    "China": "Čína",
    "Christmas Island": "Vánoční ostrov",
    "Cocos (Keeling) Islands": "Kokosové (Keelingovy) ostrovy",
    "Colombia": "Kolumbie",
    "Comoros": "Komory",
    "Congo": "Kongo",
    "Congo, The Democratic Republic of The": "Konžská demokratická republika",
    "Cook Islands": "Cookovy ostrovy",
    "Costa Rica": "Kostarika",
    "Cote D'ivoire": "Pobřeží slonoviny",
    "Croatia": "Chorvatsko",
    "Cuba": "Kuba",
    "Cyprus": "Kypr",
    "Czech Republic": "Česká republika",
    "Denmark": "Dánsko",
    "Djibouti": "Džibutsko",
    "Dominica": "Dominika",
    "Dominican Republic": "Dominikánská republika",
    "Ecuador": "Ekvádor",
    "Egypt": "Egypt",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Rovníková Guinea",
    "Eritrea": "Eritrea",
    "Estonia": "Estonsko",
    "Ethiopia": "Etiopie",
    "Falkland Islands (Malvinas)": "Falklandy (Malvíny)",
    "Faroe Islands": "Faerské ostrovy",
    "Fiji": "Fidži",
    "Finland": "Finsko",
    "France": "Francie",
    "French Guiana": "Francouzská Guyana",
    "French Polynesia": "Francouzská Polynésie",
    "French Southern Territories": "Francouzská jižní území",
    "Gabon": "Gabon",
    "Gambia": "Gambie",
    "Georgia": "Gruzie",
    "Germany": "Německo",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Řecko",
    "Greenland": "Grónsko",
    "Grenada": "Grenada",
    "Guadeloupe": "Guadeloupe",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea": "Guinea",
    "Guinea-Bissau": "Guinea-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "Heard Island and Mcdonald Islands": "Slyšel ostrov a Mcdonaldovy ostrovy",
    "Holy See (Vatican City State)": "Svatý stolec (Městský stát Vatikán)",
    "Honduras": "Honduras",
    "Hong Kong": "Hongkong",
    "Hungary": "Maďarsko",
    "Iceland": "Island",
    "India": "Indie",
    "Indonesia": "Indonésie",
    "Iran, Islamic Republic of": "Írán",
    "Iraq": "Irák",
    "Ireland": "Irsko",
    "Isle of Man": "Ostrov Man",
    "Israel": "Izrael",
    "Italy": "Itálie",
    "Jamaica": "Jamaica",
    "Japan": "Japonsko",
    "Jersey": "Jersey",
    "Jordan": "Jordán",
    "Kazakhstan": "Kazachstán",
    "Kenya": "Keňa",
    "Kiribati": "Kiribati",
    "Korea, Democratic People's Republic of": "Korejská lidově demokratická republika",
    "Korea, Republic of": "Korejská republika",
    "Kosovo": "Kosovo",
    "Kuwait": "Kuvajt",
    "Kyrgyzstan": "Kyrgyzstán",
    "Lao People's Democratic Republic": "Laoská lidově demokratická republika",
    "Latvia": "Lotyšsko",
    "Lebanon": "Libanon",
    "Lesotho": "Lesotho",
    "Liberia": "Libérie",
    "Libyan Arab Jamahiriya": "Libyjská arabská Jamahiriya",
    "Liechtenstein": "Lichtenštejnsko",
    "Lithuania": "Litva",
    "Luxembourg": "Lucembursko",
    "Macao": "Macao",
    "Macedonia, The Former Yugoslav Republic of": "Makedonie, Bývalá jugoslávská republika",
    "Madagascar": "Madagaskar",
    "Malawi": "Malawi",
    "Malaysia": "Malajsie",
    "Maldives": "Maledivy",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Marshallovy ostrovy",
    "Martinique": "Martinik",
    "Mauritania": "Mauritánie",
    "Mauritius": "Mauricius",
    "Mayotte": "Mayotte",
    "Mexico": "Mexiko",
    "Micronesia, Federated States of": "Mikronésie, Federativní státy",
    "Moldova, Republic of": "Moldavská republika",
    "Monaco": "Monako",
    "Mongolia": "Mongolsko",
    "Montenegro": "Černá Hora",
    "Montserrat": "Montserrat",
    "Marocco": "Maroko",
    "Mozambique": "Mozambik",
    "Myanmar": "Myanmar",
    "Namibia": "Namibie",
    "Nauru": "Nauru",
    "Nepal": "Nepál",
    "Netherlands": "Holandsko",
    "Netherlands Antilles": "Nizozemské Antily",
    "New Caledonia": "Nová Kaledonie",
    "New Zealand": "Nový Zéland",
    "Nicaragua": "Nikaragua",
    "Niger": "Niger",
    "Nigeria": "Nigérie",
    "Niue": "Niue",
    "Norfolk Island": "Norfolk Island",
    "Northern Mariana Islands": "Severní Mariany",
    "Norway": "Norsko",
    "Oman": "Omán",
    "Pakistan": "Pákistán",
    "Palau": "Palau",
    "Palestinian Territory, Occupied": "Palestinské území, obsazeno",
    "Panama": "Panama",
    "Papua New Guinea": "Papua-Nová Guinea",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Filipíny",
    "Pitcairn": "Pitcairn",
    "Poland": "Polsko",
    "Portugal": "Portugalsko",
    "Puerto Rico": "Portoriko",
    "Qatar": "Katar",
    "Reunion": "Réunion",
    "Romania": "Rumunsko",
    "Russian Federation": "Ruská Federace",
    "Rwanda": "Rwandě",
    "Saint Helena": "Svatá Helena",
    "Saint Kitts and Nevis": "Svatý Kryštof a Nevis",
    "Saint Lucia": "Svatá Lucia",
    "Saint Pierre and Miquelon": "Saint Pierre a Miquelon",
    "Saint Vincent and The Grenadines": "Svatý Vincenc a Grenadiny",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "Svatý Tomáš a Princův ostrov",
    "Saudi Arabia": "Saudská arábie",
    "Senegal": "Senegal",
    "Serbia": "Srbsko",
    "Seychelles": "Seychely",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapur",
    "Slovakia": "Slovensko",
    "Slovenia": "Slovinsko",
    "Solomon Islands": "Solomonovy ostrovy",
    "Somalia": "Somálsko",
    "South Africa": "Jižní Afrika",
    "South Georgia and The South Sandwich Islands": "Jižní Georgie a Jižní Sandwichovy ostrovy",
    "Spain": "Španělsko",
    "Sri Lanka": "Srí Lanka",
    "Sudan": "Súdán",
    "Suriname": "Surinam",
    "Svalbard and Jan Mayen": "Svalbard a Jan Mayen",
    "Swaziland": "Svazijsko",
    "Sweden": "Švédsko",
    "Swizerland": "Swizerland",
    "Syrian Arab Republic": "Syrská Arabská republika",
    "Taiwan": "Tchaj-wan",
    "Tajikistan": "Tádžikistán",
    "Tanzania, United Republic of": "Tanzanie, Spojené království",
    "Thailand": "Thajsko",
    "Timor-leste": "Timor-Leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad a Tobago",
    "Tunisia": "Tunisko",
    "Turkey": "Turecko",
    "Turkmenistan": "Turkmenistán",
    "Turks and Caicos Islands": "Turks a Cacois",
    "Tuvalu": "Tuvalu",
    "Uganda": "Ugandě",
    "Ukraine": "Ukrajina",
    "United Arab Emirates": "Spojené arabské emiráty",
    "United Kingdom": "Spojené království",
    "United States": "Spojené státy",
    "United States Minor Outlying Islands": "Menší odlehlé ostrovy Spojených států amerických",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Uzbekistán",
    "Vanuatu": "Vanuatu",
    "Venezuela": "Venezuela",
    "Viet Nam": "Vietnam",
    "Virgin Islands, British": "Panenské ostrovy, Britové",
    "Virgin Islands, U.S.": "Panenské ostrovy, USA",
    "Wallis and Futuna": "Wallis a Futuna",
    "Western Sahara": "Západní Sahara",
    "Yemen": "Jemen",
    "Zambia": "Zambie",
    "Zimbabwe": "Zimbabwe"
  },
  //Alt texts
  'alt.example-1': 'dva samořídící automobily',
  'alt.example-2': 'ruka držící mobilní telefon',
  'alt.example-3': "ženská tvář",
  'alt.defining-ai': 'změť pojmů souvisejících s umělou inteligencí',
  'alt.turing-test': 'muž chatující na počítači s robotem a se ženou',
  'alt.chinese-room': 'experiment „čínský pokoj“',
  'alt.chicken-crossing': 'robot ve člunu se dívá na slepici, lišku a pytel zrní',
  'alt.a-to-b': 'ruka držící telefon se spuštěnou navigační aplikací',
  'alt.tic-tac-toe': 'dvě děti hrající piškvorky',
  'alt.self-driving-car': 'samořídící automobil před značkou „Stop“',
  'alt.spam-or-ham': 'běžný e-mail a spam ',
  'alt.mnist': 'sada dat MNIST',
  'alt.nearest-neighbor': 'dvě nakupující osoby',
  'alt.nearest-neighbor-graph': 'klasifikátor nejbližšího souseda',
  'alt.recommendation': 'muž a žena poslouchající hudbu na telefonu',
  'alt.price-real-estate': 'tři různé domy',
  'alt.supervised-learning': 'člověk učící robota',
  'alt.dice': 'kostky',
  'alt.chess': 'šachovnice',
  'alt.poker': 'držící hrací karty',
  'alt.eyes': 'tři osoby s hnědýma očima, jedna osoba s modrýma očima',
  'alt.search-1': 'jediná cesta vedoucí do cíle',
  'alt.search-2': 'čtyři různé cesty vedoucí do stejného cíle',
  'alt.chicken-crossing-1': 'přesun slepice – výchozí stav',
  'alt.chicken-crossing-2': 'přesun slepice – první přechod',
  'alt.chicken-crossing-3': 'přesun slepice – všechny přechody',
  'alt.chicken-crossing-4': 'přesun slepice – nejlepší cesta',
  'alt.chicken-crossing-5': 'přesun slepice – výchozí stav',
  'alt.implication-1': 'muž a žena ',
  'alt.implication-2': 'oči',
  'alt.implication-3': 'bezpečnostní kamera ',
  'alt.implication-4': 'robot pracující na laptopu  ',
  'alt.oddchange': 'slunce a mraky  ',
  'alt.brain': 'obrázek mozku ',
  'alt.carstop': 'dva samořídící automobily',
  'alt.gan': 'robot holding a picture of cat and a picture of a stop sign ',
  'alt.hammock': 'dva samořídící automobily',
  'alt.game-tree-1': 'herní strom 1',
  'alt.game-tree-2': 'herní strom 2',
  'alt.game-tree-3': 'herní strom 3',
  'alt.game-tree-4': 'herní strom 4',
  'alt.bayes-rule-1': 'obrázek ilustrující falešně pozitivní a negativní výsledky screeningu rakoviny ',
  'alt.bayes-rule-2': 'obrázek ilustrující falešně pozitivní a negativní výsledky screeningu rakoviny ',
  'alt.defineai': 'změť pojmů souvisejících s umělou inteligencí',
  'alt.winter': 'robot spící ve sněhu',
  'alt.terminator': 'strašidelný robot',
}
