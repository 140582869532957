import _ from "lodash";

import { getQuizIdsByTags } from "../../services/quiznator";
import { config } from "../course-config";

export const SET_QUIZIDS_FOR_TAGS = "SET_QUIZIDS_FOR_TAGS";
export const SET_QUIZIDS_FOR_TAGS_ERROR = "SET_QUIZIDS_FOR_TAGS_ERROR";

const { LOCALIZED_COURSE_ID } = config;

export function initQuizIds(tags) {
  return (dispatch, getState) => {
    const { stats, tags: stateTags } = getState();

    let tags = [LOCALIZED_COURSE_ID];

    const currentTags = stateTags ? Object.keys(stateTags) : [];

    // TODO: hmm, does this ensure anything?
    const preloaded =
      currentTags.length > 0 &&
      currentTags.map(tag => _.includes(tags, tag)).every(v => !!v);

    if (!preloaded) {
      return getQuizIdsByTags(tags)
        .then((tagsData = []) => {
          let tags = {};

          // a hard coded way to ensure we're only counting quizzes from this course
          tagsData.forEach(tagSet => {
            if (_.includes(tagSet.tags, LOCALIZED_COURSE_ID)) {
              tagSet.tags
                .filter(t => t !== LOCALIZED_COURSE_ID)
                .forEach(
                  tag =>
                    (tags = {
                      ...tags,
                      [tag]: _.union(tags[tag], tagSet.quizIds)
                    })
                );
            }
          });

          return tags;
        })
        .then(tags => {
          dispatch({ type: SET_QUIZIDS_FOR_TAGS, data: tags });
          return tags;
        })
        .catch(err => {
          dispatch({ type: SET_QUIZIDS_FOR_TAGS_ERROR, error: err });
          return Promise.reject(err);
        });
    }

    return Promise.resolve(stateTags);
  };
}

export function initQuizIdsWithProgress(progress) {
  return dispatch => {
    const quizzes = _.flatten([
      progress.answered,
      progress.notAnswered,
      progress.rejected
    ]).map(entry => entry.quiz);

    const tags = _.reduce(
      quizzes,
      (obj, quiz) => {
        const tagsForQuiz = quiz.tags.filter(t => t !== LOCALIZED_COURSE_ID);

        tagsForQuiz.forEach(tag => {
          obj[tag] = [...(obj[tag] || []), quiz._id.toString()];
        });

        return obj;
      },
      {}
    );

    dispatch({ type: SET_QUIZIDS_FOR_TAGS, data: tags });

    return Promise.resolve(tags);
  };
}
