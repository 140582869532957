import {
  submitPeerReview as submitPeerReviewRequest,
  fetchGivenPeerReviews
} from "../../services/quiznator";
import { updateEssaysWaitingPeerReviews } from "../stats/actions";

import _ from "lodash";

export const SET_PEER_REVIEWS_INIT_STATE = "SET_PEER_REVIEWS_INIT_STATE";

export const RECEIVE_GIVEN_PEER_REVIEW = "RECEIVE_GIVEN_PEER_REVIEW";
export const RECEIVE_RECEIVED_PEER_REVIEW = "RECEIVE_RECEIVED_PEER_REVIEW";

export const RECEIVE_GIVEN_PEER_REVIEWS = "RECEIVE_GIVEN_PEER_REVIEWS";
export const RECEIVE_RECEIVED_PEER_REVIEWS = "RECEIVE_RECEIVED_PEER_REVIEWS";

export const RECEIVE_PEER_REVIEWS = "RECEIVE_PEER_REVIEWS";

export const CLEAR_RECEIVED_PEER_REVIEWS_FOR_ID =
  "CLEAR_RECEIVED_PEER_REVIEWS_FOR_ID";

export const SUBMIT_PEER_REVIEW = "SUBMIT_PEER_REVIEW";
export const SUBMIT_PEER_REVIEW_SUCCESS = "SUBMIT_PEER_REVIEW_SUCCESS";
export const SUBMIT_PEER_REVIEW_FAILURE = "SUBMIT_PEER_REVIEW_FAILURE";

export function setPeerReviewsInitState() {
  return {
    type: SET_PEER_REVIEWS_INIT_STATE
  };
}

export function receiveGivenPeerReview(quizId, data) {
  return {
    type: RECEIVE_GIVEN_PEER_REVIEW,
    quizId,
    data,
    receivedAt: Date.now()
  };
}

export function receiveReceivedPeerReview(quizId, data) {
  return {
    type: RECEIVE_RECEIVED_PEER_REVIEW,
    quizId,
    data,
    receivedAt: Date.now()
  };
}

export function receiveGivenPeerReviews(data) {
  return {
    type: RECEIVE_GIVEN_PEER_REVIEWS,
    data,
    receivedAt: Date.now()
  };
}

export function receiveReceivedPeerReviews(data) {
  return {
    type: RECEIVE_RECEIVED_PEER_REVIEWS,
    data,
    receivedAt: Date.now()
  };
}

export function receivePeerReviews(data) {
  return {
    type: RECEIVE_PEER_REVIEWS,
    payload: data,
    receivedAt: Date.now()
  };
}
export function clearReceivedPeerReviewsForId(quizId) {
  return {
    type: CLEAR_RECEIVED_PEER_REVIEWS_FOR_ID,
    payload: { quizId }
  };
}

export function initPeerReviews(data) {
  return (dispatch, getState) => {
    if (!data) {
      return;
    }

    const allReviews = _.concat(
      _.get(data, "answered", []),
      _.get(data, "notAnswered", []),
      _.get(data, "rejected")
    )
      .map(entry => entry.peerReviews)
      .filter(v => !!v);

    // NB: sourceQuizId and quizId flipped here (As well)
    let given = _.flatten(
      allReviews.map(reviews => reviews.given).filter(v => !!v)
    );

    let received = _.flatten(
      allReviews.map(reviews => reviews.received).filter(v => !!v)
    );

    dispatch(receivePeerReviews({ given, received }));
    /*     dispatch(receiveGivenPeerReviews(given))
    dispatch(receiveReceivedPeerReviews(received)) */

    return;
  };
}

export function updatePeerReviews(data) {
  return (dispatch, getState) => {};
}

export function submitPeerReview(data) {
  return (dispatch, getState) => {
    dispatch({ type: SUBMIT_PEER_REVIEW, quizId: data.quizId });

    return submitPeerReviewRequest(data)
      .then(review => {
        dispatch({
          type: SUBMIT_PEER_REVIEW_SUCCESS,
          quizId: data.quizId,
          data: review
        });
        dispatch(updateEssaysWaitingPeerReviews(review));
        //dispatch(receiveGivenPeerReview(data.quizId, review))
      })
      .catch(err => {
        dispatch({ type: SUBMIT_PEER_REVIEW_FAILURE, error: err });
      });
  };
}
