import _ from "lodash";

export const FETCH_COURSE_STATE = "FETCH_COURSE_STATE";
export const FETCH_COURSE_STATE_SUCCESS = "FETCH_COURSE_STATE_SUCCESS";
export const FETCH_COURSE_STATE_FAILURE = "FETCH_COURSE_STATE_FAILURE";

export const UPDATE_COURSE_STATE = "UPDATE_COURSE_STATE";
export const UPDATE_COURSE_STATE_SUCCESS = "UPDATE_COURSE_STATE_SUCCESS";
export const UPDATE_COURSE_STATE_FAILURE = "UPDATE_COURSE_STATE_FAILURE";

export function updateCourseState(progress) {
  return dispatch => {
    dispatch({
      type: UPDATE_COURSE_STATE,
      payload: {
        ..._.omit(_.get(progress, "courseState.completion", {}), ["data"]),
        answererId: _.get(progress, "answererId"),
        courseId: _.get(progress, "courseState.courseId")
      }
    });

    return Promise.resolve(progress);
  };
}
