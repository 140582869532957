import axios from "axios";
import _ from "lodash";
import fetchPonyfill from "fetch-ponyfill";
import * as accountState from "../state/account";
import { config } from "../state/course-config";

const { fetch } = fetchPonyfill();

const DEVELOPMENT = process.env.NODE_ENV === "development";
/*const BASE_URL = DEVELOPMENT
  ? 'http://127.0.0.1:3000/api/v1' // <-- change THIS
  : 'https://quiznator.mooc.fi/api/v1'*/

/*const BASE_URL = DEVELOPMENT
   ? 'https://quiznator.mooc.fi/api/v1' // <-- change THIS
   : 'https://quiznator.mooc.fi/api/v1'*/

const BASE_URL = "";

const { COURSE_ID, LOCALIZED_COURSE_ID } = config;

/*
  endpoint
  body
  validate: function (resolve, reject) 
              - don't return anything if ok
  options: 
    method: HTTP method (default: POST)
    json: add content type json to header (default: true)
    auth: add auth with accesstoken (default: true)
    headers: override headers (default: built with the above)

    returns either json or error
*/

function apiRequest({ endpoint, body, validate, options = {} }) {
  return new Promise((resolve, reject) => {
    if (validate && typeof validate === "function") {
      validate(resolve, reject);
    }

    const json = options.json !== undefined ? options.json : true;
    const auth = options.auth !== undefined ? options.auth : true;
    const method = options.method || "POST";
    const headers = options.headers || {
      "Content-Type": json ? "application/json" : undefined,
      Authorization: auth ? `Bearer ${accessToken()}` : undefined
    };

    return fetch(`${BASE_URL}${endpoint}`, {
      method,
      headers,
      body
    })
      .then(res => {
        if (!res.ok) {
          //throw new Error(res.statusText); // .statusText
        }

        return res.json();
      })
      .then(json => resolve(json))
      .catch(err => reject(err));
  });
}

export function fetchQuiz(quizId) {
  return apiRequest({
    endpoint: `/quizzes/${quizId}`,
    validate: reject => {
      if (!quizId) {
        reject(new Error("quizid undefined"));
      }
    },
    options: {
      method: "GET"
    }
  });
}

export function fetchStrippedQuizzes(quizIds) {
  return apiRequest({
    endpoint: `/quizzes/stripped`,
    validate: (resolve, reject) => {
      if (!quizIds || (!!quizIds && quizIds.length === 0)) {
        reject(new Error("quizids undefined or empty"));
      }
    },
    body: JSON.stringify({ quizIds }),
    options: {
      auth: false
    }
  });
}

export function fetchPeerReviewOptions(quizId) {
  return apiRequest({
    endpoint: `/quizzes/${quizId}/peer-reviews/${username()}/v3?courseId=${LOCALIZED_COURSE_ID}`,
    options: {
      method: "GET"
    }
  });
}

export function reportSpam(answerId) {
  return apiRequest({
    endpoint: `/quiz-answers/${answerId}/spam-flags`,
    body: JSON.stringify({ flag: 1 })
  });
}

export function fetchGivenPeerReviews(id) {
  return apiRequest({
    endpoint: `/quizzes/${id}/peer-reviews/${username()}/given-reviews-by-actual-quiz`,
    options: {
      method: "GET"
    }
  });
}

export function submitPeerReview(requestData) {
  const {
    selectedOptionId,
    reviewAnswers,
    options,
    peerreviewid,
    quizId
  } = requestData;

  return apiRequest({
    endpoint: `/quizzes/${peerreviewid}/peer-reviews`,
    validate: (resolve, reject) => {
      if (!selectedOptionId) {
        reject(new Error("No quiz selected"));
      }
    },
    body: JSON.stringify({
      quizId: peerreviewid,
      sourceQuizId: quizId,
      review: "n/a",
      chosenQuizAnswerId: selectedOptionId,
      rejectedQuizAnswerId: options.find(
        option => option._id !== selectedOptionId
      )._id,
      grading: reviewAnswers
    })
  });
}

export function submitQuiz(quizId, data) {
  return apiRequest({
    endpoint: `/quizzes/${quizId}/answers`,
    body: JSON.stringify(data)
  });
}

export function submitQuizWithValidation(quizId, data) {
  return apiRequest({
    endpoint: `/quizzes/${quizId}/answers/validate`,
    body: JSON.stringify(data)
  });
}

export function submitScore(quizId, data) {
  const scoreObject = {
    quizId,
    score: {
      // in case something ever uses this
      points: data.points,
      maxPoints: data.maxPoints
    },
    meta: {
      answererId: `${username()}`,
      answerId: data.answerId,
      courseId: LOCALIZED_COURSE_ID
    }
  };

  return apiRequest({
    endpoint: `/quiz-scores/`,
    body: JSON.stringify(scoreObject)
  });
}

function accessToken() {
  return _.get(accountState.getUser(), "accessToken", null);
}

function username() {
  return _.get(accountState.getUser(), "username", null);
}

export function fetchAnswer(quizId) {
  apiRequest({
    endpoint: `/quizzes/${quizId}/answers/${username()}?limit=1`,
    validate: (resolve, reject) => {
      if (!username()) {
        reject(new Error("cannot fetch without login"));
      }
    },
    options: {
      method: "GET"
    }
  });
}

export function fetchAnswers(quizIds) {
  return apiRequest({
    endpoint: `/quiz-answers/batch`,
    validate: (resolve, reject) => {
      if (!username()) {
        reject(new Error("cannot fetch without login"));
      }
    },
    body: JSON.stringify({ quizIds })
  });
}

export function getQuizIdsByTags(tags = []) {
  // another hard coded extra quiz prevention
  // tags = tags.filter(tag => tag !== COURSE_ID)

  return apiRequest({
    endpoint: `/tags/quizids`,
    validate: (resolve, reject) => {
      if (tags.length === 0) {
        return reject(new Error("no tags given"));
      }
    },
    body: JSON.stringify({ tags })
  });
}

export function getProgressWithValidation(quizIds = [], options = {}) {
  return apiRequest({
    // This seems to create many Sentry errors. Is the path correct?
    endpoint: `/answerers/progress-with-validation`,
    // if quizIds are not given, it now returns by courseId
    /*     validate: (resolve, reject) => {
      if (quizIds.length === 0) {
        return resolve({})
      }
    }, */
    body: JSON.stringify({
      quizIds,
      quizzes: _.get(options, "quizzes", true),
      answers: _.get(options, "answers", true),
      peerReviews: _.get(options, "peerReviews", true),
      validation: _.get(options, "validation", true),
      onlyUpdate: _.get(options, "onlyUpdate", false),
      stripAnswers: _.get(options, "stripAnswers", false),
      peerReviewsRequiredGiven: _.get(
        options,
        "peerReviewsRequiredGiven",
        config.MINIMUM_PEER_REVIEWS_GIVEN
      ),
      courseId: _.get(options, "courseId") || LOCALIZED_COURSE_ID
    })
  });
}

export function getCourseState() {
  return apiRequest({
    endpoint: `/course-state/state/${LOCALIZED_COURSE_ID}`
  });
}

/* ------------- deprecated? ----------------- */

export function getScores(quizIds = []) {
  return new Promise((resolve, reject) => {
    if (quizIds.length === 0) {
      return resolve({});
    }
    const queryString = quizIds.join(",");
    fetch(`${BASE_URL}/quiz-scores/?${queryString}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken()}`
      }
    })
      .then(res => {
        res.json().then(json => {
          resolve(json);
        });
      })
      .catch(err => reject(err));
  });
}

export function getStatsByUserByTag(tags = [], onlyConfirmed = false) {
  return new Promise((resolve, reject) => {
    if (tags.length === 0) {
      return reject("don't leave this empty, it hogs the server");
    }

    const queryString =
      "?" +
      (tags.length > 0 ? "tags=" + tags.join(",") + "&" : "") +
      "onlyConfirmed=" +
      (onlyConfirmed ? "true" : "false") +
      "&matchAll=false";

    fetch(`${BASE_URL}/quizzes/stats/user${queryString}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken()}`
      }
    }).then(res => {
      if (!res.ok) {
        return reject({});
      }
      res.json().then(json => {
        resolve(json);
      });
    });
  });
}
