// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'es',
  'globals.signIn': 'Iniciar sesión',
  'globals.logIn': 'Iniciar sesión',
  'globals.signUp': 'Registrarte',
  'globals.signOut': 'Cerrar sesión',
  'globals.logOut': 'Cerrar sesión',
  'globals.submit': 'Enviar',
  'globals.submitting': 'Enviando...',
  'globals.yourEmail': 'Tu correo electrónico',
  'globals.yourPassword': 'Tu contraseña',
  'globals.courseOverview': 'Síntesis del curso',
  'globals.forgotPassword': '¿Has olvidado tu contraseña?',
  'globals.invalidCredentials': 'Credenciales no válidas',
  'globals.section': 'Sección',
  'globals.exercises': 'Ejercicios',
  'globals.chapter': 'Capítulo',
  'globals.introToAI': 'Introducción a la IA',
  'globals.buildingAI': 'Construyendo IA',
  'globals.acceptPolicy': 'Aceptar la política',
  // section
  'section.footerEndOfTheChapter': 'Has llegado al final del capítulo',
  'section.footerEndOfTheCourseTitle': '¡Has llegado al final del curso!',
  'section.footerEndOfTheCourseText': 'Una vez completes todos los ejercicios y las revisiones entre compañeros, nuestro equipo revisará tus respuestas.',
  'section.footerEndOfTheCourseBAI': 'Continúa con tu viaje para poder implementar tu primera ídea de IA.',
  'section.nextSection': 'Siguiente sección',
  'section.nextChapter': 'Siguiente capítulo',
  'section.start': 'Empezar',
  'section.logisticRegressionExample.slope': 'Pendiente',
  'section.logisticRegressionExample.intercept': 'Ordenada en el origen',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Esperando a tu revisión',
  'quiz.rejectedPeerReview': 'Rechazado en la revisión entre compañeros',
  'quiz.unsubmittedAnswer': 'Respuesta no enviada',
  'quiz.pendingPeerReview': 'Pendiente de revisión entre compañeros',
  // quiz
  'quiz.status.answered': 'Respondido',
  'quiz.status.rejected': 'Respuesta rechazada, inténtalo de nuevo',
  'quiz.status.unanswered': 'Sin responder',
  'quiz.status.exercisesCompleted': 'Ejercicios completados',
  'quiz.status.correctAnswers': 'Respuestas correctas',
  'quiz.message.rejectedInReview': 'Se ha rechazado tu respuesta anterior en la revisión. Inténtalo de nuevo.',
  'quiz.youAnswered': 'Tu respuesta',
  'quiz.yourAnswer': 'Tu respuesta...',
  'quiz.yourScore': 'Tu puntuación:',
  'quiz.quizNotLoaded': 'No se ha podido cargar el ejercicio',
  'quiz.words': 'Palabras:',
  'quiz.exampleAnswer': 'Ejemplo de respuesta:',
  // peer review
  'peerreview.question1': 'La respuesta es pertinente',
  'peerreview.question2': 'La respuesta es detallada',
  'peerreview.question3': 'La respuesta está bien razonada',
  'peerreview.question4': 'Es fácil seguir el hilo de la respuesta',
  'peerreview.thankYou': '¡Gracias por revisar a tus compañeros!',
  'peerreview.giveMoreReviews': 'Si tienes algo de tiempo,',
  'peerreview.giveMoreReviewsButton': 'haz más revisiones',
  'peerreview.notEnoughAnswers': 'En principio, aquí habría una revisión entre compañeros, pero no se han recibido suficientes respuestas.',
  'peerreview.selectAnswerMessage': '¡Hora de revisión entre compañeros! Abajo verás un par de respuestas de otros alumnos. Elige una y revísala. Haz al menos tres revisiones.',
  'peerreview.minimumPeerReviewWarning': 'Para que aceptemos el ejercicio, tienes que hacer al menos tres revisiones a compañeros.',
  'peerreview.select': 'Seleccionar',
  'peerreview.reportSpam': 'Marcar como no deseado',
  'peerreview.feedback': 'Tu respuesta ha sido revisada y aceptada. Esto es lo que opinan tus compañeros:',
  // exercise17
  'quiz.exercise17.xLabel': 'Años de formación',
  'quiz.exercise17.yLabel': 'Esperanza de vida en años',
  'quiz.exercise17.textPart1': 'Vamos a analizar la relación entre el número total de años de formación (desde preescolar hasta la universidad) y la esperanza de vida. A continuación, presentamos mediante puntos en un gráfico los datos de tres países distintos:',
  'quiz.exercise17.textPart2': 'Tenemos un país con una media de 10 años de formación y una esperanza de vida de 57 años, otro país con una media de 13 años de formación y una esperanza de vida de 53 años, y un tercer país con una media de 20 años de formación y una esperanza de vida de 80 años.',
  'quiz.exercise17.textPart3': 'Puedes arrastrar los extremos de la línea sólida para colocarla de tal forma que siga la tendencia de los puntos de datos. Ten en cuenta que no vas a conseguir que la línea se ajuste perfectamente a los puntos de datos, pero no pasa nada; algunos de los puntos de datos quedarán por encima de la línea y otros, por debajo. Lo importante es que la línea describa la tendencia general.',
  'quiz.exercise17.textPart4': 'Una vez colocada la línea, puedes utilizarla para predecir la esperanza de vida.',
  'quiz.exercise17.textPart5': 'A partir de los datos de que disponemos, ¿qué puedes decir de la esperanza de vida de las personas con 15 años de formación? Importante: Si bien ajustando la línea puedes obtener una predicción específica de hasta una fracción de un año, eso no significa necesariamente que puedas formular una predicción segura. Al indicar tu respuesta, ten en cuenta que los datos disponibles son limitados.',
  // exercise18
  'quiz.exercise18.xLabel': 'Años de formación',
  'quiz.exercise18.yLabel': 'Esperanza de vida en años',
  'quiz.exercise18.textPart1': 'En el ejercicio anterior, solo contábamos con los datos de tres países. El conjunto de datos íntegro consiste en datos de catorce países distintos, presentados a continuación en un gráfico:',
  'quiz.exercise18.textPart2': 'A partir de estos datos, ¿cambiaría tu predicción sobre la esperanza de vida de las personas con 15 años de formación? En caso afirmativo, ¿por qué?',
  'quiz.exercise18.textPart3': '¿Cuál de las siguientes opciones se ajustaría mejor a tu estimación de la esperanza de vida de las personas con 15 años de formación? Escoge la opción más específica que consideres justificada ajustando el modelo de línea recta a los datos anteriores.',
  // exercise19
  'quiz.exercise19.xLabel': 'Horas de estudio',
  'quiz.exercise19.yLabel': 'Probabilidad de aprobar',
  'quiz.exercise19.textPart1': 'Cada punto del gráfico se corresponde con un alumno. En la parte inferior del gráfico tenemos la escala del número de horas que el alumno ha estudiado para el examen. Los estudiantes que han aprobado se representan con puntos en la parte superior del gráfico, y los que han suspendido, en la parte inferior. Utilizaremos la escala de la izquierda para indicar la predicción acerca de la probabilidad de aprobar, que obtendremos gracias al modelo de regresión logística que explicamos a continuación. A partir de este gráfico, puedes ver de forma aproximada que los alumnos que dedicaron más horas al estudio tuvieron más probabilidades de aprobar. Los casos extremos son especialmente intuitivos: con menos de una hora de trabajo, es muy difícil aprobar el curso, pero con mucho esfuerzo, la mayoría aprueba. Sin embargo, ¿qué pasa con aquellos cuyo tiempo de estudio se sitúa en un punto entre ambos extremos? Si dedicas 6 horas a estudiar, ¿qué probabilidades tienes de aprobar?',
  'quiz.exercise19.textPart2': 'Podemos cuantificar la probabilidad de aprobar mediante la regresión logística. La curva del gráfico puede interpretarse como la probabilidad de aprobar: por ejemplo, después de estudiar 5 horas, la probabilidad de aprobar es de algo más del 20 %. No entraremos en los detalles de cómo obtener la curva, pero es similar a la forma de obtener los coeficientes en la regresión lineal.',
  'quiz.exercise19.textPart3': 'Si quisieras tener una probabilidad del 80 % de aprobar un examen en la universidad, partiendo de la base del gráfico anterior, ¿cuántas horas deberías estudiar aproximadamente?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Entrada',
  'quiz.exercise22.sigmoidYLabel': 'Salida sigmoidea',
  'quiz.exercise22.stepXLabel': 'Entrada',
  'quiz.exercise22.stepYLabel': 'Salida de la función escalón',
  'quiz.exercise22.identityXLabel': 'Entrada',
  'quiz.exercise22.identityYLabel': 'Salida de identidad',
  'quiz.exercise22.textPart1': 'A continuación, encontrarás los gráficos correspondientes a tres funciones de activación diferentes con propiedades diferentes. En primer lugar, tenemos la función sigmoidea; luego, la función escalón, y, por último, la función identidad.',
  // exercise23
  'quiz.exercise23.happyFaces': 'caras felices clasificadas correctamente',
  'quiz.exercise23.sadFaces': 'caras tristes clasificadas correctamente',
  // certificate availability
  'certificate.failedToCheck': 'Error al comprobar la disponibilidad del certificado',
  'certificate.failedToGenerate': 'Error al generar el certificado',
  'certificate.notYetAvailable': 'Aquí encontrarás tu certificado una vez que recibas nuestra confirmación oficial. El proceso puede tardar un poco. ¡Gracias por tu paciencia!',
  'certificate.updateName': '¡Ya está disponible tu certificado! Por favor, actualice su nombre y apellidos para que aparezcan en su certificado utilizando un formulario disponible en su página de perfil.',
  'certificate.generate': 'Más abajo podrás generar tu certificado.',
  'certificate.generateTextPart1': 'Se generará el certificado para',
  'certificate.generateTextPart2': 'Asegúrate de que los datos sean correctos antes de generar el certificado, ya que después no podrás cambiar el nombre. Si quieres cambiar el nombre, usa el formulario a continuación.',
  'certificate.generateButton': 'Generar certificado',
  'certificate.downloadButton': 'Descargar certificado',
  'certificate.checkingAvailability': 'Comprobando si el certificado está disponible...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Clasificación correcta',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Clasificación incorrecta',
  // section blocks
  'section.block.note': 'Nota',
  'section.block.example': 'Ejemplo',
  'section.block.keyTerminology': 'Terminología clave',
  'section.partSummary.spectrumTextPart1': 'Únete a ',
  'section.partSummary.spectrum': 'la comunidad de Elements of AI',
  'section.partSummary.spectrumTextPart2': ' para discutir y plantear preguntas sobre este capítulo.',
  'section.partSummary.feedbackTitle': 'Cuéntanos qué te ha parecido esta parte',
  'section.partSummary.feedbackPlaceholder': 'Escribe aquí tus comentarios.',
  'section.partSummary.feedbackSuccess': '¡Gracias por tus comentarios!',
  // 404
  'globals.404.title': '404: página no encontrada',
  'globals.404.intro': "Vaya, el camino está cortado...",
  'globals.loading': 'Cargando...',
  // footer / menu
  'globals.menu': 'Menú',
  'globals.menu.about': 'Acerca de nosotros',
  'globals.menu.faq': 'Preguntas frecuentes',
  'globals.menu.privacyPolicy': 'Política de privacidad',
  'globals.menu.termsofService': 'Términos de servicio',
  'globals.menu.myProfile': 'Mi perfil',
  // Dashboard/Index
  'dashboard.title': '¡Te damos la bienvenida al curso!',
  'dashboard.congratulations.title': '¡Enhorabuena!',
  'dashboard.congratulations.message': 'Has terminado el curso Elements of AI',
  'dashboard.congratulations.downloadCertificate': 'Descargar certificado',
  'dashboard.notLoggedIn.title': '¡Te damos la bienvenida!',
  'dashboard.notLoggedIn.message': 'Regístrate o inicia sesión para acceder a la totalidad del curso. ¡Es gratis!',
  // Account
  'account.certificate': 'Certificado',
  'account.myProfile': 'Mi perfil',
  'account.credits': 'Créditos ECTS',
  'account.profileInformation': 'Información del perfil',
  'account.changePassword': 'Cambiar contraseña',
  'account.changeLanguage': 'Cambiar lengua',
  'account.goToCourse': 'Ir al curso',
  'account.buildingAINew': 'Nuevo curso en Inglés',
  'account.accept': 'Aceptar',
  'account.decline': 'Rechazar',
  'account.courses': 'Cursos',
  // Sign Up page
  'signUp.description': 'Rellena el formulario a continuación para crear una nueva cuenta de mooc.fi',
  'signUp.email': 'Tu correo electrónico',
  'signUp.firstName': 'Tu nombre',
  'signUp.lastName': 'Tus apellidos',
  'signUp.postalCode': 'Código postal',
  'signUp.country': '¿Dónde vives?',
  'signUp.Password': 'Contraseña',
  'signUp.confirmPassword': 'Confirmar contraseña',
  'signUp.howToStudyTitle': '¿Cómo quieres estudiar?',
  'signUp.6weeksTitle': 'Curso de 6 semanas',
  'signUp.6weeksSubTitle': 'Recomendado (aprox. 5 horas / semana)',
  'signUp.6weeksText': 'Fijarse un plazo es una forma eficaz de motivarse para terminar el curso.',
  'signUp.selfPacedTitle': 'A mi ritmo',
  'signUp.selfPacedText': 'Puedes hacer el curso a tu ritmo.',
  'signUp.ectsTitle': '¿Vas a solicitar créditos ECTS?',
  'signUp.ectsTitleSwe': ' ',
  'signUp.ectsStudent': 'Soy alumno/a de la Universidad de Helsinki. Si marcas esta casilla e introduces tu número de alumno, obtendrás los créditos ECTS automáticamente.',
  'signUp.ectsStudentSwe': ' ',
  'signup.ectsStudentSweLink': ' ',
  'signUp.ectsOpenUni': 'Marca esta casilla para recibir un correo electrónico con instrucciones sobre cómo solicitar créditos ECTS',
  'signUp.studentNumber': 'Número de alumno de la Universidad de Helsinki (en su caso)',
  'signUp.privacyOptIn': 'Doy mi consentimiento para que se usen los datos recopilados sobre mis hábitos de estudio con fines de investigación. Los datos contienen información extraída del uso de los materiales y de los ejercicios del curso. La información que se publique no permitirá identificar a ninguna persona concreta.',
  'signUp.marketingOptIn': 'Acepto recibir actualizaciones sobre futuras versiones lingüísticas e información sobre nuevos cursos. Acepto compartir mi información de contacto para recibir mensajes personalizados en plataformas de terceros. Lea más en nuestra política de privacidad.',
  'signUp.acceptPolicyFirst': 'Acepto la Política de privacidad y los Términos de servicio.',
  'signUp.privacyLink': 'Leer la política de privacidad',
  'signUp.error': 'Hay errores en el formulario. Por favor, revísalo.',
  // Sign in page
  'signIn.title': 'Iniciar sesión',
  'signIn.intro': 'Usa el formulario a continuación para iniciar sesión con tu cuenta de mooc.fi',
  'signIn.email': 'Tu correo electrónico',
  'signIn.password': 'Tu contraseña',
  'signIn.noAccount': "¿No tienes una cuenta?",
  'signIn.signUpNow': 'Regístrate ahora',
  // Forgot password
  'pwForget.title': 'He olvidado la contraseña',
  'pwForget.description': 'Introduce el correo electrónico de tu cuenta de mooc.fi y te enviaremos un enlace para restablecer la contraseña',
  'pwForget.sendResetLink': 'Enviar enlace para restablecer contraseña',
  'pwForget.completedDescription': 'En breve recibirás un correo electrónico de mooc.fi con un enlace para restablecer tu contraseña. Si no lo ves, comprueba la carpeta de correo no deseado.',
  'pwForget.problemDescription': 'Si te surge algún problema, escribe a mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent': 'Se ha enviado el correo electrónico para restablecer la contraseña',
  'pwForget.emailPlaceholder': 'Dirección de correo electrónico',
  // password change
  'pwChange.authError': 'Error en la autenticación. Intenta iniciar sesión de nuevo.',
  'pwChange.unexpError': 'Error inesperado: no se ha podido actualizar la contraseña',
  'pwChange.required': 'Obligatorio',
  'pwChange.pwMustMatch': 'Las contraseñas deben coincidir',
  'pwChange.currentPassword': 'Contraseña actual',
  'pwChange.newPassword': 'Nueva contraseña',
  'pwChange.confirmPassword': 'Confirmar nueva contraseña',
  'pwChange.changingPassword': 'Cambiando contraseña...',
  'pwChange.changePassword': 'Cambiar contraseña',
  'pwChange.passwordChanged': 'Se ha cambiado la contraseña',
  // user details
  'userDetails.authError': 'Error en la autenticación. Intenta iniciar sesión de nuevo.',
  'userDetails.unexpError': 'Error inesperado: no se ha podido actualizar el perfil',
  'userDetails.required': 'Obligatorio',
  'userDetails.invalidEmail': 'Dirección de correo electrónico no válida',
  'userDetails.email': 'Correo electrónico',
  'userDetails.emailAddress': 'Dirección de correo electrónico',
  'userDetails.firstName': 'Nombre',
  'userDetails.lastName': 'Apellidos',
  'userDetails.language': 'Lengua',
  'userDetails.updatingProfile': 'Actualizando el perfil...',
  'userDetails.updateProfile': 'Actualizar perfil',
  'userDetails.profileUpdated': 'Perfil actualizado',
  // languages
  'languages.en.long': 'Inglés',
  'languages.fi.long': 'Finlandes',
  'languages.se.long': 'Sueco',
  'languages.de.long': 'Aleman (Alemania)',
  'languages.ee.long': 'Estonio',
  'languages.sk.long': 'Esloveno',
  'languages.it.long': 'Italiano',
  'languages.fr.long': 'Francés (Francia)',
  'languages.no.long': 'Noruego',
  'languages.lv.long': 'Letón',
  'languages.pl.long': 'Polaco',
  'languages.en-ie.long': 'Inglés (Irlanda)',
  'languages.mt.long': 'Maltés',
  'languages.fr-be.long': 'Francés (Bélgica)',
  'languages.lt.long': 'Lituano',
  'languages.nl-be.long': 'Neerlandés (Bélgica)',
  'languages.hr.long': 'Croato',
  'languages.da.long': 'Danés',
  'languages.ro.long': 'Rumano',
  'languages.en-lu.long': 'Inglés (Luxemburgo)',
  'languages.bg.long': 'Búlgaro',
  'languages.cs.long': 'Checo',
  'languages.pt.long': 'Portugués',
  'languages.nl.long': 'Neerlandés (Países Bajos)',
  'languages.es.long': 'Español',
  'languages.el.long': 'Griego',
  'languages.de-at.long': 'Aleman (Austria)',
  'languages.ga.long': 'Irlandés',
  'languages.sl.long': 'Esloveno',
  'languages.is.long': 'Islandés',
  // Email verfification
  'emailVerification.completedMessage': 'Hemos verificado tu dirección de correo electrónico',
  'emailVerification.verified': 'Verificado',
  // Exercises
  'exercise.loading': 'Cargando ejercicio...',
  'exercise.signUp': 'Regístrate para resolver ejercicios',
  'exercise.score': 'Tu puntuación:',
  'exercise.wrongLocale': 'Configuración regional errónea',
  // Part tempplate
  'partTemplate.intro1': 'En el capítulo ',
  'partTemplate.intro2': ', abarcaremos las siguientes secciones. Haz clic a continuación para empezar:',
  'partTemplate.spectrumPart1': 'Únete a ',
  'partTemplate.spectrum': 'la comunidad de Elements of AI',
  'partTemplate.spectrumPart2': ' para discutir y plantear preguntas sobre este capítulo.',
  // missing info
  'missingInfo.title': 'Falta información',
  'missingInfo.body': 'Solemos hacer estas preguntas cuando los usuarios se registran, pero has iniciado sesión con una cuenta de mooc.fi ya existente. Por favor, rellena el formulario más abajo para continuar.',
  'missingInfo.privacyPolicy': 'Leer la política de privacidad',
  'missingInfo.error': 'Hay errores en el formulario. Por favor, revísalo.',
  'missingInfo.privacyPolicyUpdateBody': "Hemos actualizado nuestra política de privacidad. A continuación se muestra un resumen de los cambios. Promocionamos nuestros cursos a nuevos estudiantes potenciales formando audiencias en plataformas de marketing. Esto puede implicar compartir direcciones de correo electrónico con estas plataformas. Con el mismo propósito, los datos de actividad anonimizados se compartirán con las redes de marketing. Puede evitar que se comparta información de correo electrónico desmarcando la casilla de verificación de consentimiento de marketing en su perfil del curso. Para evitar que se compartan los datos de la actividad, puede cambiar la configuración de las cookies en el sitio del curso. Es necesario aceptar estos términos para seguir utilizando el servicio.",
  'missingInfo.privacyPolicyUpdateLink': 'Puedes revisar la política de privacidad aquí.',
  // routes
  'routes.LANDING': '/es/',
  'routes.SIGN_UP': '/es/signup',
  'routes.SIGN_IN': '/es/signin',
  'routes.MISSING_INFO': '/es/missinginfo',
  'routes.PASSWORD_FORGET': '/es/pw-forget',
  'routes.HOME': '/es/home',
  'routes.ACCOUNT': '/es/account',
  'routes.ACCEPT_POLICY': '/es/accept-policy',
  'routes.PRIVACY': 'https://www.elementsofai.com/es/faq/pol%C3%ADtica-de-privacidad',
  'routes.FAQ': 'https://www.elementsofai.com/es/faq',
  'routes.ABOUT': 'https://www.elementsofai.com/es',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/es-discutir-en-espanol',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/es/faq/how-can-i-get-2-ects-credits',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Tazas de café diarias',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Líneas de código escritas',
  // meta
  'meta.title': 'Un curso en línea gratuito',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Descubre más sobre el curso de IA de la Universidad de Helsinki y Reaktor para estudiantes y profesionales (no se requieren conocimientos específicos de programación ni de matemáticas).',
  'meta.sitename': 'Elements of AI',
  // ECTS
  'ects.text': 'Aquí encontrarás las instrucciones para solicitar créditos ECTS una vez completado el curso.',
  'ects.textCompleted': 'Si tienes un número de seguridad social finlandés, puedes solicitar los créditos haciendo clic ',
  'ects.link': 'aquí.',
  'ects.info': '¿Te gustaría solicitar créditos ECTS?',
  'ects.readMore': 'Leer más',
  // Countries
  'countryselect.select': "Por favor seleccione",
  'Estonia': "Estonia",
  'Finland': "Finlandia",
  'Germany': "Alemania",
  'Sweden': "Suecia",
  "Countries": {
    "Afganistan": "Afganistán",
    "Åland Islands": "Islas Aland",
    "Albania": "Albania",
    "Algeria": "Argelia",
    "American Samoa": "Samoa Americana",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguila",
    "Antarctica": "Antártida",
    "Antigua and Barbuda": "Antigua y Barbuda",
    "Argentina": "Argentina",
    "Armenia": "Armenia",
    "Aruba": "Aruba",
    "Australia": "Australia",
    "Austria": "Austria",
    "Azerbaijan": "Azerbaiyán",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahrein",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbados",
    "Belarus": "Bielorrusia",
    "Belgium": "Bélgica",
    "Belize": "Belice",
    "Benin": "Benin",
    "Bermuda": "islas Bermudas",
    "Bhutan": "Bután",
    "Bolivia": "Bolivia",
    "Bosnia and Herzegovina": "Bosnia y Herzegovina",
    "Botswana": "Botsuana",
    "Bouvet Island": "Isla Bouvet",
    "Brazil": "Brasil",
    "British Indian Ocean Territory": "Territorio Británico del Océano Índico",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgaria",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambodia": "Camboya",
    "Cameroon": "Camerún",
    "Canada": "Canadá",
    "Cape Verde": "Cabo Verde",
    "Cayman Islands": "Islas Caimán",
    "Central African Republic": "República Centroafricana",
    "Chad": "Chad",
    "Chile": "Chile",
    "China": "China",
    "Christmas Island": "Isla de Navidad",
    "Cocos (Keeling) Islands": "Islas Cocos (Keeling)",
    "Colombia": "Colombia",
    "Comoros": "Comoras",
    "Congo": "Congo",
    "Congo, The Democratic Republic of The": "Congo, República Democrática del",
    "Cook Islands": "Islas Cook",
    "Costa Rica": "Costa Rica",
    "Cote D'ivoire": "Costa de Marfil",
    "Croatia": "Croacia",
    "Cuba": "Cuba",
    "Cyprus": "Chipre",
    "Czech Republic": "Republica checa",
    "Denmark": "Dinamarca",
    "Djibouti": "Djibouti",
    "Dominica": "Dominica",
    "Dominican Republic": "República Dominicana",
    "Ecuador": "Ecuador",
    "Egypt": "Egipto",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Guinea Ecuatorial",
    "Eritrea": "Eritrea",
    "Estonia": "Estonia",
    "Ethiopia": "Etiopía",
    "Falkland Islands (Malvinas)": "Islas Malvinas (Falkland Islands)",
    "Faroe Islands": "Islas Faroe",
    "Fiji": "Fiyi",
    "Finland": "Finlandia",
    "France": "Francia",
    "French Guiana": "Guayana Francesa",
    "French Polynesia": "Polinesia francés",
    "French Southern Territories": "Territorios Franceses del Sur",
    "Gabon": "Gabón",
    "Gambia": "Gambia",
    "Georgia": "Georgia",
    "Germany": "Alemania",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Grecia",
    "Greenland": "Groenlandia",
    "Grenada": "Granada",
    "Guadeloupe": "Guadalupe",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea": "Guinea",
    "Guinea-Bissau": "Guinea-Bissau",
    "Guyana": "Guayana",
    "Haiti": "Haití",
    "Heard Island and Mcdonald Islands": "Islas Heard y Mcdonald",
    "Holy See (Vatican City State)": "Santa Sede (Estado de la Ciudad del Vaticano)",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Hungría",
    "Iceland": "Islandia",
    "India": "India",
    "Indonesia": "Indonesia",
    "Iran, Islamic Republic of": "Irán (República Islámica de",
    "Iraq": "Irak",
    "Ireland": "Irlanda",
    "Isle of Man": "Isla de Man",
    "Israel": "Israel",
    "Italy": "Italia",
    "Jamaica": "Jamaica",
    "Japan": "Japón",
    "Jersey": "Jersey",
    "Jordan": "Jordán",
    "Kazakhstan": "Kazajstán",
    "Kenya": "Kenia",
    "Kiribati": "Kiribati",
    "Korea, Democratic People's Republic of": "República de Corea, Popular Democrática de",
    "Korea, Republic of": "Corea, república de",
    "Kosovo": "Kosovo",
    "Kuwait": "Kuwait",
    "Kyrgyzstan": "Kirguistán",
    "Lao People's Democratic Republic": "República Democrática Popular Lao",
    "Latvia": "Letonia",
    "Lebanon": "Líbano",
    "Lesotho": "Lesoto",
    "Liberia": "Liberia",
    "Libyan Arab Jamahiriya": "Jamahiriya Árabe Libia",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Lituania",
    "Luxembourg": "Luxemburgo",
    "Macao": "Macao",
    "Macedonia, The Former Yugoslav Republic of": "Macedonia, la ex República Yugoslava de",
    "Madagascar": "Madagascar",
    "Malawi": "Malawi",
    "Malaysia": "Malasia",
    "Maldives": "Maldivas",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Islas Marshall",
    "Martinique": "Martinica",
    "Mauritania": "Mauritania",
    "Mauritius": "Mauricio",
    "Mayotte": "Mayotte",
    "Mexico": "Mexico",
    "Micronesia, Federated States of": "Micronesia, Estados Federados de",
    "Moldova, Republic of": "Moldavia, República de",
    "Monaco": "Mónaco",
    "Mongolia": "Mongolia",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Marocco": "Marruecos",
    "Mozambique": "Mozambique",
    "Myanmar": "Myanmar",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Países Bajos",
    "Netherlands Antilles": "Antillas Holandesas",
    "New Caledonia": "Nueva Caledonia",
    "New Zealand": "Nueva Zelanda",
    "Nicaragua": "Nicaragua",
    "Niger": "Níger",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "Norfolk Island": "Isla Norfolk",
    "Northern Mariana Islands": "Islas Marianas del Norte",
    "Norway": "Noruega",
    "Oman": "Omán",
    "Pakistan": "Pakistán",
    "Palau": "Palau",
    "Palestinian Territory, Occupied": "Territorio Palestino, Ocupado",
    "Panama": "Panamá",
    "Papua New Guinea": "Papúa Nueva Guinea",
    "Paraguay": "Paraguay",
    "Peru": "Perú",
    "Philippines": "Filipinas",
    "Pitcairn": "Pitcairn",
    "Poland": "Polonia",
    "Portugal": "Portugal",
    "Puerto Rico": "Puerto Rico",
    "Qatar": "Katar",
    "Reunion": "Reunión",
    "Romania": "Rumania",
    "Russian Federation": "Federación Rusa",
    "Rwanda": "Ruanda",
    "Saint Helena": "Santa Helena",
    "Saint Kitts and Nevis": "San Cristóbal y Nieves",
    "Saint Lucia": "Santa Lucía",
    "Saint Pierre and Miquelon": "San Pedro y Miquelón",
    "Saint Vincent and The Grenadines": "San Vicente y las Granadinas",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "Santo Tomé y Príncipe",
    "Saudi Arabia": "Arabia Saudita",
    "Senegal": "Senegal",
    "Serbia": "Serbia",
    "Seychelles": "Seychelles",
    "Sierra Leone": "Sierra Leona",
    "Singapore": "Singapur",
    "Slovakia": "Eslovaquia",
    "Slovenia": "Eslovenia",
    "Solomon Islands": "Islas Salomón",
    "Somalia": "Somalia",
    "South Africa": "Sudáfrica",
    "South Georgia and The South Sandwich Islands": "Georgia del sur y las islas Sandwich del sur",
    "Spain": "España",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudán",
    "Suriname": "Surinam",
    "Svalbard and Jan Mayen": "Svalbard y Jan Mayen",
    "Swaziland": "Swazilandia",
    "Sweden": "Suecia",
    "Swizerland": "Swizerland",
    "Syrian Arab Republic": "República Árabe Siria",
    "Taiwan": "Taiwán",
    "Tajikistan": "Tayikistán",
    "Tanzania, United Republic of": "Tanzania, República Unida de",
    "Thailand": "Tailandia",
    "Timor-leste": "Timor-leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad y Tobago",
    "Tunisia": "Túnez",
    "Turkey": "Turquía",
    "Turkmenistan": "Turkmenistán",
    "Turks and Caicos Islands": "Islas Turcas y Caicos",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ucrania",
    "United Arab Emirates": "Emiratos Árabes Unidos",
    "United Kingdom": "Reino Unido",
    "United States": "Estados Unidos",
    "United States Minor Outlying Islands": "Islas Ultramarinas Menores de los Estados Unidos",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Uzbekistán",
    "Vanuatu": "Vanuatu",
    "Venezuela": "Venezuela",
    "Viet Nam": "Vietnam",
    "Virgin Islands, British": "Islas Vírgenes Británicas",
    "Virgin Islands, U.S.": "Islas Vírgenes, EE. UU.",
    "Wallis and Futuna": "Wallis y Futuna",
    "Western Sahara": "Sahara Occidental",
    "Yemen": "Yemen",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabue"
  },
  //Alt texts
  'alt.example-1': 'dos coches autónomos',
  'alt.example-2': 'mano sujetando un móvil',
  'alt.example-3': "cara de mujer",
  'alt.defining-ai': 'popurrí de palabras relacionadas con la IA',
  'alt.turing-test': 'hombre chateando por ordenador con un robot y una mujer',
  'alt.chinese-room': 'el experimento de la habitación china',
  'alt.chicken-crossing': 'robot en una barca mirando a un pollo, un zorro y una bolsa de pienso',
  'alt.a-to-b': 'hombre con móvil usando una aplicación de navegación',
  'alt.tic-tac-toe': 'dos niños jugando al tres en raya',
  'alt.self-driving-car': 'coche autónomo frente a señal de stop',
  'alt.spam-or-ham': 'correo electrónico normal y no deseado',
  'alt.mnist': 'conjunto de datos MNIST',
  'alt.nearest-neighbor': 'dos personas comprando',
  'alt.nearest-neighbor-graph': 'clasificador del vecino más cercano',
  'alt.recommendation': 'hombre y mujer escuchando música con el móvil',
  'alt.price-real-estate': 'tres casas diferentes',
  'alt.supervised-learning': 'humano enseñando a un robot',
  'alt.dice': 'dado',
  'alt.chess': 'tablero de ajedrez',
  'alt.poker': 'mano sujetando cartas',
  'alt.eyes': 'tres personas de ojos castaños, una persona de ojos azules',
  'alt.search-1': 'itinerario único que lleva a una meta',
  'alt.search-2': 'cuatro itinerarios distintos que llevan a la misma meta',
  'alt.chicken-crossing-1': 'estado inicial del cruce del pollo',
  'alt.chicken-crossing-2': 'primera transición del cruce del pollo',
  'alt.chicken-crossing-3': 'todas las transiciones del cruce del pollo',
  'alt.chicken-crossing-4': 'mejor itinerario del cruce del pollo',
  'alt.chicken-crossing-5': 'estado inicial del cruce del pollos',
  'alt.implication-1': 'hombre y mujer',
  'alt.implication-2': 'ojos',
  'alt.implication-3': 'cámara de vigilancia',
  'alt.implication-4': 'robot trabajando con un portátil',
  'alt.oddchange': 'sol y nubes',
  'alt.brain': 'dibujo de un cerebro',
  'alt.carstop': 'coche autónomo frente a señal de stop',
  'alt.gan': 'robot sujetando una imagen de un gato y una imagen de una señal de stop',
  'alt.hammock': 'robot abanicando a un hombre en una hamaca',
  'alt.game-tree-1': 'árbol de juego 1',
  'alt.game-tree-2': 'árbol de juego 2',
  'alt.game-tree-3': 'árbol de juego 3',
  'alt.game-tree-4': 'árbol de juego 4',
  'alt.bayes-rule-1': 'imagen que ilustra falsos positivos y negativos en detección del cáncer',
  'alt.bayes-rule-2': 'imagen que ilustra falsos positivos y negativos en detección del cáncer',
  'alt.defineai': 'popurrí de palabras relacionadas con la IA',
  'alt.winter': 'robot durmiendo sobre la nieve',
  'alt.terminator': 'robot siniestro',
}

