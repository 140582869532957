// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'ga',
  'globals.signIn': 'Sínigh isteach',
  'globals.logIn': 'Logáil isteach',
  'globals.signUp': 'Cláraigh',
  'globals.signOut': 'Sínigh amach',
  'globals.logOut': 'Logáil amach',
  'globals.submit': 'Seol',
  'globals.submitting': 'Ag seoladh...',
  'globals.yourEmail': 'Do ríomhphost',
  'globals.yourPassword': 'Do phasfhocal',
  'globals.courseOverview': 'Forbhreathnú ar an gcúrsa',
  'globals.forgotPassword': 'Pasfhocal dearmadta?',
  'globals.invalidCredentials': 'Faisnéis aitheantais neamhbhailí',
  'globals.section': 'Rannán',
  'globals.exercises': 'Cleachtaí',
  'globals.chapter': 'Caibidil',
  'globals.introToAI': 'Treoir eolais ar an IS',
  'globals.buildingAI': 'An IS a Chur le Chéile',
  'globals.acceptPolicy': 'Glac le beartas',
  // section
  'section.footerEndOfTheChapter': 'Tá deireadh Chaibidil {chapter} n bainte amach agat!',
  'section.footerEndOfTheCourseTitle': 'Tá deireadh an chúrsa bainte amach agat!',
  'section.footerEndOfTheCourseText': 'Tar éis duit na freagraí agus athbhreithnithe piaraí ar fad a chríochnú, déanfaidh ár mbaill foirne athbhreithniú ar na freagraí uait.',
  'section.footerEndOfTheCourseBAI': 'Féach ar an seicheamh don chúrsa seo, Building AI (i mBéarla).',
  'section.nextSection': 'An chéad rannán eile',
  'section.nextChapter': 'An chéad chaibidil eile',
  'section.start': 'Tosaigh',
  'section.logisticRegressionExample.slope': 'Fána',
  'section.logisticRegressionExample.intercept': 'Idirlíne',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Ag fanacht ort athbhreithniú a dhéanamh',
  'quiz.rejectedPeerReview': 'Diúltaithe san athbhreithniú piaraí',
  'quiz.unsubmittedAnswer': 'Freagra nár seoladh isteach',
  'quiz.pendingPeerReview': 'Ag fanacht ar athbhreithniú piaraí',
  // quiz
  'quiz.status.answered': 'Freagartha',
  'quiz.status.rejected': 'Diúltaíodh don fhreagra, bain triail as arís',
  'quiz.status.unanswered': 'Gan freagra',
  'quiz.status.exercisesCompleted': 'Cleachtaí atá críochnaithe',
  'quiz.status.correctAnswers': 'Freagraí cearta',
  'quiz.message.rejectedInReview': 'Diúltaíodh don fhreagra a thug tú roimhe seo san athbhreithniú. Bain triail as arís.',
  'quiz.youAnswered': 'An freagra a thug tú',
  'quiz.yourAnswer': 'An freagra uait...',
  'quiz.yourScore': 'Do scór:',
  'quiz.quizNotLoaded': 'Níorbh fhéidir an cleachtadh a lódáil',
  'quiz.words': 'Focail:',
  'quiz.exampleAnswer': 'Freagra samplach:',
  // peer review
  'peerreview.question1': 'Baineann an freagra le hábhar',
  'peerreview.question2': 'Freagra cuimsitheach atá ann',
  'peerreview.question3': 'Freagra dea-réasúnaithe atá ann',
  'peerreview.question4': 'Freagra éasca le leanúint atá ann',
  'peerreview.thankYou': 'Go raibh maith agat as athbhreithniú a dhéanamh ar do phiaraí!',
  'peerreview.giveMoreReviews': 'Má tá am le spáráil agat,',
  'peerreview.giveMoreReviewsButton': 'déan tuilleadh athbhreithnithe',
  'peerreview.notEnoughAnswers': 'Go hiondúil, bheadh athbhreithniú piaraí le feiceáil anseo ach níor fhreagair a ndóthain daoine eile an cleachtadh seo.',
  'peerreview.selectAnswerMessage': 'Tá sé in am don athbhreithniú piaraí! Tá cúpla freagra ó mhic léinn eile thíos. Roghnaigh ceann acu agus déan athbhreithniú air. Déan sin arís trí huaire ar a laghad.',
  'peerreview.minimumPeerReviewWarning': 'Ní féidir glacadh leis an gcleachtadh go dtí go mbeidh trí athbhreithniú piaraí ar a laghad déanta agat.',
  'peerreview.select': 'Roghnaigh',
  'peerreview.reportSpam': 'Tuairiscigh turscar',
  'peerreview.feedback': 'Rinneadh athbhreithniú ar an bhfreagra uait agus glacadh leis. Seo an meas a bhí ag do phiaraí air:',
  // exercise17
  'quiz.exercise17.xLabel': 'Líon blianta oideachais',
  'quiz.exercise17.yLabel': 'An t-ionchas saoil mar líon blianta',
  'quiz.exercise17.textPart1': 'Scrúdaímis an nasc idir an líon blianta a chaitheann duine ar scoil (idir an t-am a chaitheann sé sa naíonra agus san ollscoil araon) agus an t-ionchas saoil atá aige. Seo thíos fíor ina léirítear na sonraí ó thrí thír dhifriúla mar phoncanna:',
  'quiz.exercise17.textPart2': 'Feicimid tír inarb é an meánlíon blianta a chaitheann duine ar scoil ná 10 agus inarb é 57 mbliana an t-ionchas saoil. Feicimid tír eile inarb é an meánlíon blianta a chaitheann duine ar scoil ná 13 agus inarb é 53 bhliain an t-ionchas saoil. Feicimid tír eile fós inarb é an meánlíon blianta a chaitheann duine ar scoil ná 20 agus inarb é 80 an t-ionchas saoil.',
  'quiz.exercise17.textPart3': 'Is féidir pointí deiridh na líne troime a bhogadh as a suíomh i dtreo is go leanann sé treocht na bpointí sonraí. Tabhair do d’aire nach mbeidh tú in ann an líne a rianú go díreach ar lorg na bpointí, agus ná cuireadh sé sin isteach ort: beidh cuid de na pointí sonraí os cionn na líne agus beidh cuid eile acu faoina bun. Is é an rud is tábhachtaí ná go léiríonn an líne an treocht fhoriomlán.',
  'quiz.exercise17.textPart4': 'Tar éis duit an líne a chur ina suíomh sa chaoi sin, is féidir leat í a úsáid chun an t-ionchas saoil a thuar.',
  'quiz.exercise17.textPart5': 'I bhfianaise na sonraí, cad is féidir leat a rá faoi ionchas saoil na ndaoine a bhfuil 15 bliana oideachais orthu? Rud tábhachtach: Fiú amháin má éiríonn leat tuar sonrach a bhaint amach go dtí an céatadán bliana is gaire, agus sin tríd an líne a choigeartú, ní hionann sin agus a rá gur féidir leat a bheith muiníneach as an tuar céanna. Agus do fhreagra á chur in iúl agat, bí cinnte go gcuireann tú san áireamh nach bhfuil agat ach sonraí atá srianta go maith.',
  // exercise18
  'quiz.exercise18.xLabel': 'Líon blianta oideachais',
  'quiz.exercise18.yLabel': 'An t-ionchas saoil mar líon blianta',
  'quiz.exercise18.textPart1': 'Sa chleachtadh roimhe seo, ní raibh sonraí againn ach ó thrí thír. Is éard atá sa tacar sonraí iomlán sonraí ó 14 thír dhifriúla, agus iad léirithe anseo i ngraf:',
  'quiz.exercise18.textPart2': 'I bhfianaise na sonraí seo, an n-athrófá an tuar a rinne tú faoi ionchas saoil na ndaoine a bhfuil 15 bliana oideachais orthu? Cén fáth sin, más ea?',
  'quiz.exercise18.textPart3': 'Cé acu ceann de na roghanna seo a leanas is mó a thagann le do réamh-mheastachán ar ionchas saoil na ndaoine a bhfuil 15 bliana oideachais orthu? Cuir samhail na líne dírí i bhfeidhm ar na sonraí thuas agus ansin roghnaigh an rogha sonrach a mheasann tú is mó a bhfuil údar leis dá réir.',
  // exercise19
  'quiz.exercise19.xLabel': 'Uaireanta an chloig i mbun staidéir',
  'quiz.exercise19.yLabel': 'An dóchúlacht go bhfaighidh duine pasmharc i scrúdú',
  'quiz.exercise19.textPart1': 'Seasann gach ponc san fhíor do mhac léinn amháin. Ag bun na fíorach, tá an scála againn i dtaca le méid na n-uaireanta a chaith an mac léinn ag staidéar don scrúdú, agus léirítear na mic léinn a fuair pas sa scrúdú mar phoncanna ag barr na cairte. Léirítear na mic léinn sin ar theip orthu ag an mbun. Bainfimid úsáid as an scála ar thaobh na láimhe clé chun an dóchúlacht tuartha go bhfaigheadh duine pasmharc a léiriú, rud a bhainfimid as samhail an chúlchéimnithe loighisticiúil mar a mhínímid thíos. Bunaithe ar an bhfíor seo, is féidir leat a dhéanamh amach gurb iad na mic léinn sin a chaith níos mó ama ag staidéar is mó a raibh seans ann go bhfaighidís pasmharc. Ní dhéanfadh duine ar bith lá iontais de na cásanna is faide amach ar dhá thaobh an scála: an té nach bhfuil oiread agus uair an chloig oibre curtha isteach aige, tá caolseans ann go bhfaigheadh sé pasmharc sa chúrsa, ach iad siúd a bhfuil a lán oibre déanta acu, is é is dóichí go n-éireodh leo. Ach cad faoi na mic léinn a dtagann an méid ama a chaith siad ag staidéar idir an dá phointe is faide amach? Cad é an seans go bhfaighidh tú pasmharc má chaitheann tú 6 huaire an chloig i mbun staidéir?',
  'quiz.exercise19.textPart2': 'Is féidir linn an dóchúlacht sin a mheas ach úsáid a bhaint as an gcúlchéimniú loighisticiúil. Is féidir an cuar san fhíor a léamh mar an dóchúlacht go bhfaighidh duine pasmharc: mar shampla, tar éis do mhac léinn cúig huaire an chloig a chaitheamh ag staidéar, is é an dóchúlacht go bhfaighidh sé pasmharc beagán os cionn 20%. Ní chuirfimid síos ar na mionsonraí ar an mbealach a bhfaightear an cuar ach tá sé cosúil leis an mbealach a bhfoghlaimímid conas na hualaí a dhéanamh sa chúlchéimniú líneach.',
  'quiz.exercise19.textPart3': 'Ag baint úsáid as an bhfíor thuas, dá mba mhian leat seans 80% a bheith agat go bhfaighfeá pasmharc i scrúdú ollscoile, cé mhéad uaireanta an chloig ba cheart duit a chaitheamh i mbun staidéir?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'an t-ionchur',
  'quiz.exercise22.sigmoidYLabel': 'an t-aschur siogmóideach',
  'quiz.exercise22.stepXLabel': 'an t-ionchur',
  'quiz.exercise22.stepYLabel': 'an t-aschur céimfheidhme',
  'quiz.exercise22.identityXLabel': 'an t-ionchur',
  'quiz.exercise22.identityYLabel': 'an t-aschur céannachta',
  'quiz.exercise22.textPart1': 'Seo thíos graif ina léirítear trí chinn d’fheidhmeanna gníomhachtúcháin a bhfuil airíonna difriúla ag baint leo. I dtús báire feicimid an fheidhm shiogmóideach, ansin an chéimfheidhm, agus ar deireadh an fheidhm chéannachta.',
  // exercise23
  'quiz.exercise23.happyFaces': 'aghaidheanna sásta aicmithe i gceart',
  'quiz.exercise23.sadFaces': 'aghaidheanna brónacha aicmithe i gceart',
  // certificate availability
  'certificate.failedToCheck': 'Níorbh fhéidir an fháil ar an teastas a sheiceáil',
  'certificate.failedToGenerate': 'Níorbh fhéidir an teastas a chruthú',
  'certificate.notYetAvailable': 'Beidh do theastas ar fáil anseo tar éis duit dearbhú oifigiúil a fháil uainn. D’fhéadfadh fanacht a bheith i gceist. Gabhaimid buíochas as ucht d’fhoighne!',
  'certificate.updateName': 'Tá do theastas ar fáil! Úsáid an fhoirm thíos chun do chéadainm agus do shloinne a chur le do phróifíl sa chaoi gur féidir linn iad a úsáid ar an teastas.',
  'certificate.generate': 'Cruthaigh do theastas thíos.',
  'certificate.generateTextPart1': 'Cruthófar an teastas le haghaidh',
  'certificate.generateTextPart2': 'Déan cinnte go bhfuil sé sin ceart sula gcruthóidh tú an teastas toisc nach féidir an t-ainm a athrú ina dhiaidh sin. Más mian leat d’ainm a athrú, is féidir leat an fhoirm thíos a úsáid chuige sin.',
  'certificate.generateButton': 'Cruthaigh an teastas',
  'certificate.downloadButton': 'Íoslódáil an teastas',
  'certificate.checkingAvailability': 'An fháil ar an teastas á seiceáil...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Aicmithe i gceart',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Gan a bheith aicmithe i gceart',
  // section blocks
  'section.block.note': 'Nóta',
  'section.block.example': 'Sampla',
  'section.block.keyTerminology': 'Na téarmaí is tábhachtaí',
  'section.partSummary.spectrumTextPart1': 'Téigh isteach i ',
  'section.partSummary.spectrum': 'bpobal Elements of AI',
  'section.partSummary.spectrumTextPart2': ' chun dul i mbun plé agus ceisteanna a chur faoin gcaibidil seo.',
  'section.partSummary.feedbackTitle': 'Tabhair do chuid aiseolais faoin gcuid seo',
  'section.partSummary.feedbackPlaceholder': 'Scríobh do chuid aiseolais anseo...',
  'section.partSummary.feedbackSuccess': 'Go raibh maith agat as an aiseolas a thabhairt!',
  // 404
  'globals.404.title': '404 - Leathanach gan aimsiú',
  'globals.404.intro': 'Tá tú díreach tar éis dul ar bhealach nach ann dó... ochón.',
  'globals.loading': 'Ag lódáil...',
  // footer / menu
  'globals.menu': 'Roghchlár',
  'globals.menu.about': 'Eolas',
  'globals.menu.faq': 'Ceisteanna coitianta',
  'globals.menu.privacyPolicy': 'An beartas príobháideachta',
  'globals.menu.termsofService': 'Tearmaí Seirbhís',
  'globals.menu.myProfile': 'Mo phróifíl',
  // Dashboard/Index
  'dashboard.title': 'Fáilte go dtí an cúrsa!',
  'dashboard.congratulations.title': 'Comhghairdeas!',
  'dashboard.congratulations.message': 'Chríochnaigh tú an cúrsa Elements of AI',
  'dashboard.congratulations.downloadCertificate': 'Íoslódáil an teastas',
  'dashboard.notLoggedIn.title': 'Fáilte!',
  'dashboard.notLoggedIn.message': 'Cláraigh nó logáil isteach chun teacht a bheith agat ar an gcúrsa gan srian. Tá sé saor in aisce!',
  // Account
  'account.certificate': 'Teastas',
  'account.myProfile': 'Mo phróifíl',
  'account.profileInformation': 'Faisnéis phróifíle',
  'account.credits': 'Creidiúintí ECTS',
  'account.changePassword': 'Athraigh an pasfhocal',
  'account.changeLanguage': 'Athraigh an teanga',
  'account.goToCourse': 'Téigh ar chúrsa',
  'account.buildingAINew': 'Cúrsa nua i mBéarla',
  'account.accept': 'Glac',
  'account.decline': 'Diúltaigh',
  'account.courses': 'Cúrsaí',
  // Sign Up page
  'signUp.description': 'Líon isteach an fhoirm thíos chun cuntas nua mooc.fi a chruthú',
  'signUp.email': 'Do ríomhphost',
  'signUp.firstName': 'Do chéadainm',
  'signUp.lastName': 'Do shloinne',
  'signUp.postalCode': 'Cód Poist',
  'signUp.country': 'Cá bhfuil cónaí ort?',
  'signUp.Password': 'Pasfhocal',
  'signUp.confirmPassword': 'Deimhnigh an pasfhocal',
  'signUp.howToStudyTitle': 'Conas is mian leat a bheith ag staidéar?',
  'signUp.6weeksTitle': 'Cúrsa 6 seachtaine',
  'signUp.6weeksSubTitle': 'Molta (thart ar 5 huaire in aghaidh na seachtaine)',
  'signUp.6weeksText': 'Is bealach cruthaithe le cabhrú leat an cúrsa a chríochnú é spriocdháta a leagan síos.',
  'signUp.selfPacedTitle': 'Ar do luas féin',
  'signUp.selfPacedText': 'Is féidir leat an cúrsa a dhéanamh ar do luas féin.',
  'signUp.ectsTitle': 'An bhfuil iarratas ar chreidiúintí ECTS á dhéanamh agat?',
  'signUp.ectsTitleSwe': 'Would you like to apply for ECTS credits? ',
  'signUp.ectsStudent': 'Is mac léinn in Ollscoil Heilsincí mé. Má chuireann tú tic sa bhosca seo agus má líonann tú isteach d’uimhir mac léinn, gheobhaidh tú na creidiúintí ECTS go huathoibríoch.',
  'signUp.ectsStudentSwe': 'After completing the course, you can apply for ECTS credits from Linköping University. You can find the instructions ',
  'signup.ectsStudentSweLink': 'here.',
  'signUp.ectsOpenUni': 'Cuir tic sa bhosca seo chun ríomhphost a fháil ina mbeidh treoracha faoi conas iarratas a dhéanamh ar chreidiúintí ECTS',
  'signUp.studentNumber': 'Uimhir mac léinn Ollscoil Heilsincí (más mac léinn thú)',
  'signUp.privacyOptIn': 'Toilím gur féidir na sonraí a bhailítear faoi mo nósanna staidéir a úsáid chun críoch taighde. Cuimsítear sna sonraí faisnéis maidir le húsáid ábhair agus cleachtaí cúrsa. Ní féidir daoine aonair a aithint ó na foilseacháin.',
  'signUp.marketingOptIn': 'Táim sásta nuashonruithe a fháil maidir le leaganacha teanga atá ar na bacáin agus maidir le faisnéis a bhainfidh le cúrsaí nua. Aontaím mo chuid faisnéise teagmhála a roinnt d’fhonn teachtaireachtaí oiriúnaithe a fháil ar ardáin tríú páirtí. Léigh níos mó inár mBeartas Príobháideachta.',
  'signUp.acceptPolicyFirst': 'Glacaim leis an mBeartas Príobháideachta agus na Téarmaí Seirbhíse.',
  'signUp.privacyLink': 'Léigh an beartas maidir leis an bpríobháideachas',
  'signUp.error': 'Tá earráidí san fhoirm a líon tú. Seiceáil an fhoirm le do thoil.',
  // Sign in page
  'signIn.title': 'Sínigh isteach',
  'signIn.intro': 'Úsáid an fhoirm thíos le síniú isteach le do chuntas mooc.fi',
  'signIn.email': 'Do ríomhphost',
  'signIn.password': 'Do phasfhocal',
  'signIn.noAccount': 'Níl cuntas agat?',
  'signIn.signUpNow': 'Cláraigh anois',
  // Forgot password
  'pwForget.title': 'Pasfhocal dearmadta',
  'pwForget.description': 'Líon isteach seoladh ríomhphoist do chuntais mooc.fi agus seolfaimid nasc chun an pasfhocal a athshocrú chugat',
  'pwForget.sendResetLink': 'Seol nasc chun athshocrú a dhéanamh',
  'pwForget.completedDescription': 'Ba cheart go bhfaighfeá ríomhphost go luath ó mooc.fi ina mbeidh nasc chun an pasfhocal a athshocrú. Mura bhfeicfidh tú é, seiceáil san fhillteán turscair.',
  'pwForget.problemDescription': 'Má bhíonn fadhb ar bith agat, déan teagmháil le mooc@cs.helsinki.fi.',
  'pwForget.emailPlaceholder': 'Seoladh ríomhphoist',
  // password change
  'pwChange.authError': 'Theip ar an bhfíordheimhniú. Déan iarracht síniú isteach arís',
  'pwChange.unexpError': 'Níor nuashonraíodh an pasfhocal toisc gur tharla earráid gan choinne',
  'pwChange.required': 'Riachtanach',
  'pwChange.pwMustMatch': 'Ní mór do na pasfhocail a bheith mar an gcéanna',
  'pwChange.currentPassword': 'Pasfhocal reatha',
  'pwChange.newPassword': 'Pasfhocal nua',
  'pwChange.confirmPassword': 'Deimhnigh an pasfhocal nua',
  'pwChange.changingPassword': 'Pasfhocal á athrú...',
  'pwChange.changePassword': 'Athraigh an pasfhocal',
  'pwChange.passwordChanged': 'Pasfhocal athraithe',
  // user details
  'userDetails.authError': 'Theip ar an bhfíordheimhniú. Déan iarracht síniú isteach arís',
  'userDetails.unexpError': 'Níor nuashonraíodh an phróifíl toisc gur tharla earráid gan choinne',
  'userDetails.required': 'Riachtanach',
  'userDetails.invalidEmail': 'Seoladh ríomhphoist neamhbhailí',
  'userDetails.email': 'Ríomhphost',
  'userDetails.emailAddress': 'Seoladh ríomhphoist',
  'userDetails.firstName': 'Céadainm',
  'userDetails.lastName': 'Sloinne',
  'userDetails.language': 'Teanga',
  'userDetails.updatingProfile': 'Próifíl á nuashonrú...',
  'userDetails.updateProfile': 'Nuashonraigh an phróifíl',
  'userDetails.profileUpdated': 'Nuashonraíodh an phróifíl',
  // languages
  'languages.en.long': 'Béarla',
  'languages.fi.long': 'Fionlainnis',
  'languages.se.long': 'Sualainnis',
  'languages.de.long': 'Gearmáinis (Ghearmáin)',
  'languages.ee.long': 'Eastóinis',
  'languages.it.long': 'Iodáilis',
  'languages.fr.long': 'Fraincis (Fhrainc)',
  'languages.no.long': 'Ioruais',
  'languages.lv.long': 'Laitvis',
  'languages.mt.long': 'Máltais',
  'languages.fr-be.long': "Fraincis (Bheilg)",
  'languages.lt.long': 'Liotuáinis',
  'languages.nl-be.long': "Ollainnis (Bheilg)",
  'languages.en-ie.long': "Béarla (Éire)",
  'languages.pl.long': 'Polainnis',
  'languages.hr.long': 'Cróitis',
  'languages.ga.long': 'Gaeilge',
  'languages.da.long': 'Danmhairgis',
  'languages.ro.long': 'Rómáinis',
  'languages.pt.long': 'Portaingéilis',
  'languages.bg.long': 'Bulgáiris',
  'languages.cs.long': 'Seicis',
  'languages.de-at.long': 'Gearmáinis (Ostair)',
  'languages.el.long': 'Gréigis',
  'languages.nl.long': 'Ollainnis (Ísiltír)',
  'languages.sk.long': 'Slóvaicis',
  'languages.es.long': 'Spáinnis',
  'languages.en-lu.long': 'Béarla (Lucsamburg)',
  'languages.sl.long': 'Slóivéinis',
  'languages.is.long': 'Íoslainnis',
  // Email verfification
  'emailVerification.completedMessage': 'Deimhníodh do sheoladh ríomhphoist',
  'emailVerification.verified': 'Deimhnithe',
  // Exercises
  'exercise.loading': 'Cleachtadh á lódáil...',
  'exercise.signUp': 'Cláraigh chun cleachtaí a réiteach:',
  'exercise.score': 'Do scór:',
  'exercise.wrongLocale': 'Logchaighdeán mícheart',
  // Part tempplate
  'partTemplate.intro1': 'I gCaibidil ',
  'partTemplate.intro2': ', clúdóimid na rannáin a leanas. Cliceáil thíos chun tosú:',
  'partTemplate.spectrumPart1': 'Téigh isteach i ',
  'partTemplate.spectrum': 'bpobal Elements of AI',
  'partTemplate.spectrumPart2': ' chun dul i mbun plé agus ceisteanna a chur faoin gcaibidil seo.',
  // missing info
  'missingInfo.title': 'Eolas in easnamh',
  'missingInfo.body': 'Go hiondúil cuirimid na ceisteanna seo nuair a chláraíonn úsáideoirí, ach logáil tú isteach le cuntas mooc.fi atá ann cheana. Líon isteach an fhoirm thíos le leanúint ar aghaidh.',
  'missingInfo.privacyPolicy': 'Léigh an beartas maidir leis an bpríobháideachas',
  'missingInfo.error': 'Tá earráidí san fhoirm a líon tú. Seiceáil an fhoirm le do thoil.',
  'missingInfo.privacyPolicyUpdateBody': "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
  'missingInfo.privacyPolicyUpdateLink': 'You can review the privacy policy here.',
  // routes
  'routes.LANDING': '/ga',
  'routes.SIGN_UP': '/ga/signup',
  'routes.SIGN_IN': '/ga/signin',
  'routes.MISSING_INFO': '/ga/missinginfo',
  'routes.PASSWORD_FORGET': '/ga/pw-forget',
  'routes.HOME': '/ga/home',
  'routes.ACCOUNT': '/ga/account',
  'routes.ACCEPT_POLICY': '/ga/accept-policy',
  'routes.PRIVACY': 'https://www.elementsofai.com/ga/faq/an-beartas-pr%C3%ADobh%C3%A1ideachta',
  'routes.FAQ': 'https://www.elementsofai.com/ga/faq',
  'routes.ABOUT': 'https://www.elementsofai.com/ga',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/intro-course-help',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/ga/faq/how-can-i-get-2-ects-credits',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Líon na gcupán caife sa lá',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Línte cóid a scríobhtar',
  // meta
  'meta.title': 'Cúrsa saor in aisce ar líne',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Faigh tuilleadh eolais faoi chúrsa Ollscoil Heilsincí agus Reaktor le haghaidh mic léinn agus gairmithe gnó – níl scileanna ríomhchláraithe ná matamaitice ag teastáil.',
  'meta.sitename': 'Elements of AI',
  // ECTS
  'ects.text': 'Gheobhaidh tú treoracha faoi conas iarratas a dhéanamh ar chreidiúintí ECTS anseo tar éis duit an cúrsa a chríochnú.',
  'ects.textCompleted': 'Má tá uimhir slándála sóisialta Fhionlannach agat, is féidir leat iarratas a dhéanamh ar chreidiúintí ach cliceáil ',
  'ects.link': 'anseo.',
  'ects.info': 'Would you like to apply for ECTS credits?',
  'ects.readMore': 'Leigh nios mo',
  // Countries
  'countryselect.select': 'Please select',
  'Finland': 'An Fhionlainn',
  'Estonia': 'An Eastóin',
  'Germany': 'An Ghearmáin',
  'Sweden': 'An tSualainn',
  'Norway': 'An Iorua',
  'Latvia': 'An Laitvia',
  "Countries": {
    'Afganistan': 'Afganistan',
    'Åland Islands': 'Oileáin Åland',
    'Albania': 'Albáin',
    'Algeria': 'An Ailgéir',
    'American Samoa': 'Samó Mheiriceá',
    'Andorra': 'Andóra',
    'Angola': 'Angóla',
    'Anguilla': 'Anguilla',
    'Antarctica': 'Antartaice',
    'Antigua and Barbuda': 'Antigua agus Barbúda',
    'Argentina': 'An Airgintín',
    'Armenia': 'Airméin',
    'Aruba': 'Aruba',
    'Australia': 'An Astráil',
    'Austria': 'An Ostair',
    'Azerbaijan': 'An Asarbaiseáin',
    'Bahamas': 'Na Bahámaí',
    'Bahrain': 'Bairéin',
    'Bangladesh': 'An Bhanglaidéis',
    'Barbados': 'Barbadós',
    'Belarus': 'An Bhealarúis',
    'Belgium': 'An Bheilg',
    'Belize': 'An Bheilís',
    'Benin': 'Beinin',
    'Bermuda': 'Beirmiúda',
    'Bhutan': 'An Bhútáin',
    'Bolivia': 'An Bholaiv',
    'Bosnia and Herzegovina': 'An Bhoisnia agus Heirseagaivéin',
    'Botswana': 'An Bhotsuáin',
    'Bouvet Island': 'Oileán Bouvet',
    'Brazil': 'An Bhrasaíl',
    'British Indian Ocean Territory': 'Críoch Aigéan Indiach na Breataine',
    'Brunei Darussalam': 'Brúiné Darussalam',
    'Bulgaria': 'An Bhulgáir',
    'Burkina Faso': 'Buircíne Fasó',
    'Burundi': 'An Bhurúin',
    'Cambodia': 'An Chambóid',
    'Cameroon': 'Camarún',
    'Canada': 'Ceanada',
    'Cape Verde': 'Rinn Verde',
    'Cayman Islands': 'Oileáin Cayman',
    'Central African Republic': 'Poblacht na hAfraice Láir',
    'Chad': 'Sead',
    'Chile': 'An tSile',
    'China': 'An tSín',
    'Christmas Island': 'Oileán na Nollag',
    'Cocos (Keeling) Islands': 'Oileáin Cocos (Keeling)',
    'Colombia': 'An Cholóim',
    'Comoros': 'Comoros',
    'Congo': 'Congó',
    'Congo, The Democratic Republic of The': 'Congó, Poblacht Dhaonlathach na',
    'Cook Islands': 'Oileáin Cook',
    'Costa Rica': 'Costa Rica',
    "Cote D'ivoire": "Cote D'ivoire",
    'Croatia': 'An Chróit',
    'Cuba': 'Cúba',
    'Cyprus': 'An Chipir',
    'Czech Republic': 'Poblacht na Seice',
    'Denmark': 'An Danmhairg',
    'Djibouti': 'Djibouti',
    'Dominica': 'Doiminice',
    'Dominican Republic': 'An Phoblacht Dhoiminiceach',
    'Ecuador': 'Eacuadór',
    'Egypt': 'An Éigipt',
    'El Salvador': 'El Salvador',
    'Equatorial Guinea': 'An Ghuine Mheánchriosach',
    'Eritrea': 'Eiritré',
    'Estonia': 'An Eastóin',
    'Ethiopia': 'An Aetóip',
    'Falkland Islands (Malvinas)': 'Oileáin Fháclainne (Malvinas)',
    'Faroe Islands': 'Oileáin Fharó',
    'Fiji': 'Fidsí',
    'Finland': 'An Fhionlainn',
    'France': 'An Fhrainc',
    'French Guiana': 'Guáin na Fraince',
    'French Polynesia': 'Polainéis na Fraince',
    'French Southern Territories': 'Críocha Deiscirt na Fraince',
    'Gabon': 'An Ghabúin',
    'Gambia': 'An Ghaimbia',
    'Georgia': 'Georgia',
    'Germany': 'An Ghearmáin',
    'Ghana': 'Gána',
    'Gibraltar': 'Giobráltar',
    'Greece': 'An Ghréig',
    'Greenland': 'An Ghraonlainn',
    'Grenada': 'Grenada',
    'Guadeloupe': 'Guadalúip',
    'Guam': 'Guam',
    'Guatemala': 'Guatamala',
    'Guernsey': 'Geansaí',
    'Guinea': 'Guinea',
    'Guinea-Bissau': 'Guine-Bissau',
    'Guyana': 'An Ghuáin',
    'Haiti': 'Háití',
    'Heard Island and Mcdonald Islands': 'Oileáin Heard agus Oileáin Mcdonald',
    'Holy See (Vatican City State)': 'Féach Naofa (Stát Chathair na Vatacáine)',
    'Honduras': 'Hondúras',
    'Hong Kong': 'Hong Cong',
    'Hungary': 'An Ungáir',
    'Iceland': 'An Íoslainn',
    'India': 'An India',
    'Indonesia': 'An Indinéis',
    'Iran, Islamic Republic of': 'An Iaráin, Poblacht Ioslamach na',
    'Iraq': 'An Iaráic',
    'Ireland': 'Éireann',
    'Isle of Man': 'Oileán Mhanann',
    'Israel': 'Iosrael',
    'Italy': 'An Iodáil',
    'Jamaica': 'Iamáice',
    'Japan': 'An tSeapáin',
    'Jersey': 'Jersey',
    'Jordan': 'An Iordáin',
    'Kazakhstan': 'An Chasacstáin',
    'Kenya': 'An Chéinia',
    'Kiribati': 'Kiribati',
    "Korea, Democratic People's Republic of": 'An Chóiré, Daon-Phoblacht Dhaonlathach na',
    'Korea, Republic of': 'An Chóiré, Poblacht na',
    'Kosovo': 'An Chosaiv',
    'Kuwait': 'Cuáit',
    'Kyrgyzstan': 'An Chirgeastáin',
    "Lao People's Democratic Republic": 'Poblacht Dhaonlathach an Phobail Lao',
    'Latvia': 'An Laitvia',
    'Lebanon': 'Liobáin',
    'Lesotho': 'Leosóta',
    'Liberia': 'An Libéir',
    'Libyan Arab Jamahiriya': 'Jamahiriya Arabach na Libia',
    'Liechtenstein': 'Lichtinstéin',
    'Lithuania': 'An Liotuáin',
    'Luxembourg': 'Lucsamburg',
    'Macao': 'Macao',
    'Macedonia, The Former Yugoslav Republic of': 'Macadóine, Iar-Phoblacht Iúgslavach na',
    'Madagascar': 'Madagascar',
    'Malawi': 'An Mhaláiv',
    'Malaysia': 'An Mhalaeisia',
    'Maldives': 'Oileáin Mhaildíve',
    'Mali': 'Mailí',
    'Malta': 'Málta',
    'Marshall Islands': 'Oileáin Marshall',
    'Martinique': 'Martinique',
    'Mauritania': 'An Mháratáin',
    'Mauritius': 'Oileán Mhuirís',
    'Mayotte': 'Mayotte',
    'Mexico': 'Meicsiceo',
    'Micronesia, Federated States of': 'Micrinéise, Stáit Chónaidhme na',
    'Moldova, Republic of': 'An Mholdóiv, Poblacht na',
    'Monaco': 'Monacó',
    'Mongolia': 'Mhongóil',
    'Montenegro': 'Montainéagró',
    'Montserrat': 'Montserrat',
    'Marocco': 'Marocco',
    'Mozambique': 'Mósaimbíc',
    'Myanmar': 'Maenmar',
    'Namibia': 'Namaib',
    'Nauru': 'Nárú',
    'Nepal': 'Neipeal',
    'Netherlands': 'An Ísiltír',
    'Netherlands Antilles': 'Aintillí na hÍsiltíre',
    'New Caledonia': 'An Nua-Chaladóin',
    'New Zealand': 'An Nua-Shéalainn',
    'Nicaragua': 'Nicearagua',
    'Niger': 'An Nígir',
    'Nigeria': 'An Nigéir',
    'Niue': 'Niue',
    'Norfolk Island': 'Oileán Norfolk',
    'Northern Mariana Islands': 'Oileáin Mariana Thuaisceart',
    'Norway': 'An Iorua',
    'Oman': 'Oman',
    'Pakistan': 'An Phacastáin',
    'Palau': 'Palau',
    'Palestinian Territory, Occupied': 'Críoch na Palaistíne, Áitithe',
    'Panama': 'Panama',
    'Papua New Guinea': 'Nua-Ghuine Phapua',
    'Paraguay': 'Paragua',
    'Peru': 'Peiriú',
    'Philippines': 'Na hOileáin Fhilipíneacha',
    'Pitcairn': 'Pitcairn',
    'Poland': 'An Pholainn',
    'Portugal': 'An Phortaingéil',
    'Puerto Rico': 'Pórtó Ríce',
    'Qatar': 'Catar',
    'Reunion': 'Reunion',
    'Romania': 'An Rómáin',
    'Russian Federation': 'Cónaidhm na Rúise',
    'Rwanda': 'Ruanda',
    'Saint Helena': 'Naomh Helena',
    'Saint Kitts and Nevis': 'Saint Kitts agus Nevis',
    'Saint Lucia': 'Saint Lucia',
    'Saint Pierre and Miquelon': 'Saint Pierre agus Miquelon',
    'Saint Vincent and The Grenadines': 'Naomh Uinseann agus Na Greanáidíní',
    'Samoa': 'Samó',
    'San Marino': 'San Mairíne',
    'Sao Tome and Principe': 'Sao Tome agus Principe',
    'Saudi Arabia': 'An Araib Shádach',
    'Senegal': 'An tSeineagáil',
    'Serbia': 'An tSeirbia',
    'Seychelles': 'Na Séiséil',
    'Sierra Leone': 'Siarra Leon',
    'Singapore': 'Singeapór',
    'Slovakia': 'An tSlóvaic',
    'Slovenia': 'An tSlóivéin',
    'Solomon Islands': 'Oileáin Sholamón',
    'Somalia': 'An tSomáil',
    'South Africa': 'an Afraic Theas',
    'South Georgia and The South Sandwich Islands': 'An tSeoirsia Theas agus Oileáin Sandwich Theas',
    'Spain': 'an Spáinn',
    'Sri Lanka': 'Srí Lanca',
    'Sudan': 'An tSúdáin',
    'Suriname': 'Suranam',
    'Svalbard and Jan Mayen': 'Svalbard agus Jan Mayen',
    'Swaziland': 'An tSuasalainn',
    'Sweden': 'An tSualainn',
    'Swizerland': 'Swizerland',
    'Syrian Arab Republic': 'Poblacht Arabach na Siria',
    'Taiwan': 'Taiwan',
    'Tajikistan': 'Táidsíceastáin',
    'Tanzania, United Republic of': 'An Tansáin, Poblacht Aontaithe na',
    'Thailand': 'An Téalainn',
    'Timor-leste': 'Timor-leste',
    'Togo': 'Tóga',
    'Tokelau': 'Tokelau',
    'Tonga': 'Tonga',
    'Trinidad and Tobago': 'Oileán na Tríonóide agus Tobága',
    'Tunisia': 'An Túinéis',
    'Turkey': 'Turcaí',
    'Turkmenistan': 'An Tuircméanastáin',
    'Turks and Caicos Islands': 'Oileáin na dTurcach agus Caicos',
    'Tuvalu': 'Tuvalu',
    'Uganda': 'Uganda',
    'Ukraine': 'An Úcráin',
    'United Arab Emirates': 'Aontas na nÉimíríochtaí Arabacha',
    'United Kingdom': 'an Ríocht Aontaithe',
    'United States': 'Stáit Aontaithe',
    'United States Minor Outlying Islands': 'Mion-Oileáin Forimeallacha na Stát Aontaithe',
    'Uruguay': 'Uragua',
    'Uzbekistan': 'Úisbéiceastáin',
    'Vanuatu': 'Vanuatú',
    'Venezuela': 'Veiniséala',
    'Viet Nam': 'Viet Nam',
    'Virgin Islands, British': 'Oileáin Mhaighdean, na Breataine',
    'Virgin Islands, U.S.': 'Oileáin Mhaighdean, U.S.',
    'Wallis and Futuna': 'Wallis agus Futuna',
    'Western Sahara': 'An Sahára Thiar',
    'Yemen': 'Éimin',
    'Zambia': 'An tSaimbia',
    'Zimbabwe': 'An tSiombáib'
  },
  //Alt texts
  'alt.example-1': 'two self driving cars',
  'alt.example-2': 'hand holding a mobile phone',
  'alt.example-3': "woman’s face",
  'alt.defining-ai': 'jumble of words related to AI',
  'alt.turing-test': 'man chatting on computer with a robot and a woman',
  'alt.chinese-room': 'the chinese room experiment',
  'alt.chicken-crossing': 'robot in a boat looking at a chicken, fox, and chicken feed bag',
  'alt.a-to-b': 'hand holding a phone using a navigation app',
  'alt.tic-tac-toe': 'two kids playing tic tac toe',
  'alt.self-driving-car': 'a woman walks past a self-driwing car',
  'alt.spam-or-ham': 'a normal email and a spam email ',
  'alt.mnist': 'MNIST data set',
  'alt.nearest-neighbor': 'two people shopping',
  'alt.nearest-neighbor-graph': 'nearest neighbor classifier',
  'alt.recommendation': 'man and woman listening to music on the phone',
  'alt.price-real-estate': 'three different houses',
  'alt.supervised-learning': 'human teaching a robot',
  'alt.dice': 'dice',
  'alt.chess': 'chessboard',
  'alt.poker': 'hand holding playing cards',
  'alt.eyes': 'three people with brown eyes, one person with blue eyes',
  'alt.search-1': 'single path leading to a goal',
  'alt.search-2': 'four different paths leading to the same goal',
  'alt.chicken-crossing-1': 'chicken crossing initial state',
  'alt.chicken-crossing-2': 'chicken crossing first transition',
  'alt.chicken-crossing-3': 'chicken crossing all transitions',
  'alt.chicken-crossing-4': 'chicken crossing with best path',
  'alt.chicken-crossing-5': 'chicken crossing initial state',
  'alt.implication-1': 'man and a woman',
  'alt.implication-2': 'eyes',
  'alt.implication-3': 'surveillance camera ',
  'alt.implication-4': 'robot working on a laptop ',
  'alt.oddchange': 'sun and clouds ',
  'alt.brain': 'drawing of a brain ',
  'alt.carstop': 'self driving car at stop sign',
  'alt.gan': 'robot holding a picture of cat and a picture of a stop sign ',
  'alt.hammock': 'robot fanning a man in a hammock',
  'alt.game-tree-1': 'game tree 1',
  'alt.game-tree-2': 'game tree 2',
  'alt.game-tree-3': 'game tree 3',
  'alt.game-tree-4': 'game tree 4',
  'alt.bayes-rule-1': 'image illustrating false positives and negatives for cancer screening  ',
  'alt.bayes-rule-2': 'image illustrating false positives and negatives for cancer screening  ',
  'alt.defineai': 'jumble of words related to AI',
  'alt.winter': 'robot sleeping in the snow',
  'alt.terminator': 'scary robot',
}
