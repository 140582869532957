export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const UNSET_NOTIFICATION = "UNSET_NOTIFICATION";

import {
  MULTIPLE_CHOICE,
  CHECKBOX,
  PRIVACY_AGREEMENT,
  ESSAY,
  PEER_REVIEW,
  PEER_REVIEWS_RECEIVED,
  OPEN,
  SCALE,
  RADIO_MATRIX,
  MULTIPLE_OPEN
} from "../../constants/quiztypes";

let uniqueId = 0;

export function setNotification(quizId, itemId, data, error = false) {
  const notificationId = uniqueId++;

  return {
    type: SET_NOTIFICATION,
    quizId,
    itemId,
    notificationId,
    error,
    data
  };
}

export function unsetNotification(notificationId) {
  return {
    type: UNSET_NOTIFICATION,
    notificationId
  };
}
