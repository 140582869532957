import styled from "styled-components";
import {
  color,
  space,
  width,
  maxWidth,
  flex,
  flexWrap,
  flexDirection,
  alignItems,
  alignContent,
  justifyContent,
  backgroundColor,
  borders,
  display,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  height,
  position,
  zIndex
} from "styled-system";

const Box = styled.div`
  ${zIndex}
  ${color}
  ${space}
  ${width}
  ${maxWidth}
  ${flex}
  ${flexWrap}
  ${flexDirection}
  ${alignItems}
  ${justifyContent}
  ${backgroundColor}
  ${borders}
  ${display}
  ${backgroundImage}
  ${backgroundSize}
  ${backgroundPosition}
  ${backgroundRepeat}
  ${height}
  ${alignContent}
  ${position}
`;

export default Box;
