import * as _ from "lodash";

import {
  setQuizzesInitState,
  initQuizzesStripped,
  initQuizzesWithProgress
} from "./quiz/actions";
import {
  setAnswersInitState,
  initAnswers,
  updateAnswerStatuses
} from "./answer/actions";
import { setStatsInitState, initStats, updateProgress } from "./stats/actions";
import {
  setPeerReviewsInitState,
  initPeerReviews,
  updatePeerReviews
} from "./peerReview/actions";
import { initQuizIds, initQuizIdsWithProgress } from "../state/tags/actions";
import { updateCourseState } from "../state/courseState/actions";
import { currentLocalizedCourseId } from "../services/locale";

import {
  INIT_QUIZZES,
  INIT_QUIZZES_DONE,
  INIT_QUIZZES_ERROR
} from "./quiz/actions";

export const RESET_STATE = "RESET_STATE";

export const FETCH_INITIAL_DATA = "FETCH_INITIAL_DATA";
export const RECEIVE_INITIAL_DATA = "RECEIVE_INITIAL_DATA";

let updateInterval;

export function fetchInitial() {
  return {
    type: FETCH_INITIAL_DATA
  };
}

export function receiveInitial(data) {
  return {
    type: RECEIVE_INITIAL_DATA,
    data
  };
}

export function resetState(data) {
  return dispatch => {
    return Promise.resolve(
      dispatch({
        type: RESET_STATE,
        data
      })
    );
  };
}

export function fetchInitialData(options) {
  return (dispatch, getState) => {
    dispatch({ type: INIT_QUIZZES });

    const { loggedIn, expiredQuizIds, answeredIds, courseId } = options;

    if (!loggedIn) {
      dispatch(initQuizIds())
        .then(tagsData => {
          const quizIds = _.uniq(
            _.flattenDeep(Object.keys(tagsData || {}).map(tag => tagsData[tag]))
          );

          return dispatch(initQuizzesStripped(quizIds));
        })
        .then(() => dispatch({ type: INIT_QUIZZES_DONE }))
        .catch(err => dispatch({ type: INIT_QUIZZES_ERROR, error: err }));
    } else {
      dispatch(
        initStats({
          // had initQuizIds before
          firstTime: true,
          expiredQuizIds,
          answeredIds,
          courseId
        })
      ).then(progress => {
        dispatch(initQuizIdsWithProgress(progress));
        dispatch(updateCourseState(progress));
        dispatch(initQuizzesWithProgress(progress));
        dispatch(initAnswers(progress));
        dispatch(initPeerReviews(progress));

        updateInterval = setInterval(() => {
          dispatch(updateProgress(loggedIn));
        }, 300000); // 5 mins

        return dispatch({ type: INIT_QUIZZES_DONE });
      });
      //.catch(err => dispatch({ type: INIT_QUIZZES_ERROR, error: err }))
    }

    return Promise.resolve();
  };
}

export function clearUpdateInterval() {
  return dispatch => {
    if (updateInterval) {
      clearInterval(updateInterval);

      updateInterval = undefined;
    }
  };
}
