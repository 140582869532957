import styled from "styled-components";

export default styled.div`
  background-color: ${({ rowNumber }) => rowNumber % 2 === 0 && "#ebe9ef"};
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    p:first-of-type {
      padding-left: 1rem;
    }

    > div > div > div:nth-of-type(1) {
      display: flex;
      flex-direction: column;

      div:first-of-type {
        padding-bottom: 0.75em;
      }
    }
  }
`;
