export const fontSizes = [
  null,
  "3rem",
  "2.25rem",
  "1.5rem",
  "1.25rem",
  "1rem",
  ".875rem",
  ".75rem"
];

let fonts = {}
const url = typeof window !== "undefined" ? window.location.href : '';
let splitUrl = url.split("/")
let splitDomain = url.split(".")

if (splitUrl[3] === "el" || splitDomain[2] === "gr") {
  fonts = {
    TTNormsProBold: '"tt-norms-pro-bold", "sans-serif";',
    TTNormsProLight: '"tt-norms-pro-light", "sans-serif";',
    TTNormsProRegular: '"tt-norms-pro-regular", "sans-serif";',
    alkesRegular: '"alkes-regular", "sans-serif";',
    alkesSemibold: '"alkes-semibold", "sans-serif";'
  }
} else {
  fonts = {
    TTNormsProBold: '"walsheim-bold", "tt-norms-pro-bold", "sans-serif";',
    TTNormsProLight: '"walsheim-light", "tt-norms-pro-light", "sans-serif";',
    TTNormsProRegular: '"walsheim-regular", "tt-norms-pro-regular", "sans-serif";',
    alkesRegular: '"tiempos-regular", "alkes-regular", "sans-serif";',
    alkesSemibold: '"tiempos-semibold", "alkes-semibold", "sans-serif";'
  }
}

export { fonts }

export const lineHeights = [null, 1, 1.25, 1.5, 1.8];

export const textStyles = {
  paragraph: {
    fontFamily: fonts.alkesRegular
    // fontWeight: 200,
  },
  styledF1: {
    fontFamily: fonts.TTNormsProBold,
    color: "#29264C",
    fontSize: "3.5rem"
  },
  styledF2: {
    fontFamily: fonts.TTNormsProBold,
    color: "#343434",
    fontSize: "3rem"
  },
  styledF3: {
    fontFamily: fonts.TTNormsProBold,
    color: "#343434",
    fontSize: "1.75rem"
  },
  styledF4: {
    fontFamily: fonts.TTNormsProRegular,
    color: "#343434",
    fontSize: "1.75rem"
  },
  styledF5: {
    fontFamily: fonts.TTNormsProBold,
    color: "#343434",
    fontSize: "1.25rem"
  },
  styledF6: {
    fontFamily: fonts.alkesRegular,
    color: "#343434",
    fontSize: "1.125"
  }
};