export const maxWidths = [
  "none",
  "1rem",
  "2rem",
  "4rem",
  "8rem",
  "16rem",
  "44rem",
  "60rem",
  "82rem",
  "96rem"
];
