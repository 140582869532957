// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'pt',
  'globals.signIn': 'Iniciar sessão',
  'globals.logIn': 'Iniciar sessão',
  'globals.signUp': 'Inscrição',
  'globals.signOut': 'Terminar sessão',
  'globals.logOut': 'Terminar sessão',
  'globals.submit': 'Enviar',
  'globals.submitting': 'A enviar...',
  'globals.yourEmail': 'O seu endereço de correio eletrónico',
  'globals.yourPassword': 'A sua palavra-passe',
  'globals.courseOverview': 'Visão geral do curso',
  'globals.forgotPassword': 'Esqueceu-se da palavra-passe?',
  'globals.invalidCredentials': 'Credenciais inválidas',
  'globals.section': 'Secção',
  'globals.exercises': 'Exercícios',
  'globals.chapter': 'Capítulo',
  'globals.introToAI': 'Introdução à IA',
  'globals.buildingAI': 'Construindo IA',
  'globals.acceptPolicy': 'Aceitar política',
  // section
  'section.footerEndOfTheChapter': 'Chegou ao fim do capítulo {chapter}',
  'section.footerEndOfTheCourseTitle': 'Chegou ao fim do curso!',
  'section.footerEndOfTheCourseText': 'Depois de ter completado todos os exercícios e avaliações de pares, as suas respostas serão avaliadas pela nossa equipa.',
  'section.footerEndOfTheCourseBAI': 'Continue a sua jornada para ser capaz de implementar sua primeira ideia de IA.',
  'section.nextSection': 'Secção seguinte',
  'section.nextChapter': 'Capítulo seguinte',
  'section.start': 'Início',
  'section.logisticRegressionExample.slope': 'Declive',
  'section.logisticRegressionExample.intercept': 'Interceto',
  // quiz peer review
  'quiz.awaitingPeerReview': 'A aguardar a sua avaliação',
  'quiz.rejectedPeerReview': 'Rejeitada na avaliação por pares',
  'quiz.unsubmittedAnswer': 'Resposta não enviada',
  'quiz.pendingPeerReview': 'Avaliação por pares pendente',
  // quiz
  'quiz.status.answered': 'Respondido',
  'quiz.status.rejected': 'Resposta rejeitada, volte a tentar',
  'quiz.status.unanswered': 'Não respondido',
  'quiz.status.exercisesCompleted': 'Exercícios concluídos',
  'quiz.status.correctAnswers': 'Respostas corretas',
  'quiz.message.rejectedInReview': 'A sua resposta anterior foi rejeitada durante a avaliação. Volte a tentar.',
  'quiz.youAnswered': 'Respondeu',
  'quiz.yourAnswer': 'A sua resposta...',
  'quiz.yourScore': 'A sua pontuação:',
  'quiz.quizNotLoaded': 'Não foi possível carregar o questionário',
  'quiz.words': 'N.º de palavras:',
  'quiz.exampleAnswer': 'Exemplo de resposta:',
  // peer review
  'peerreview.question1': 'A resposta é pertinente',
  'peerreview.question2': 'A resposta é exaustiva',
  'peerreview.question3': 'A resposta está bem fundamentada',
  'peerreview.question4': 'A resposta está bem estruturada',
  'peerreview.thankYou': 'Obrigado por avaliar os seus pares!',
  'peerreview.giveMoreReviews': 'Se tiver tempo livre,',
  'peerreview.giveMoreReviewsButton': 'realize mais avaliações',
  'peerreview.notEnoughAnswers': 'Normalmente, haveria aqui uma avaliação por pares, mas ainda não houve suficientes respostas a este exercício.',
  'peerreview.selectAnswerMessage': 'Está na hora de proceder à avaliação de pares! Abaixo, poderá encontrar algumas respostas de outros estudantes. Selecione uma e proceda à sua avaliação. Realize, pelo menos, três avaliações.',
  'peerreview.minimumPeerReviewWarning': 'O exercício só pode ser aceite depois de ter realizado, pelo menos, três avaliações de pares.',
  'peerreview.select': 'Selecionar',
  'peerreview.reportSpam': 'Denunciar spam',
  'peerreview.feedback': 'A sua resposta foi avaliada e aceite. Eis o que pensam os seus pares:',
  // exercise17
  'quiz.exercise17.xLabel': 'Anos de escolaridade',
  'quiz.exercise17.yLabel': 'Esperança de vida em anos',
  'quiz.exercise17.textPart1': 'Vamos estudar a ligação entre o total de anos de escolaridade (incluindo todos os níveis entre o pré-escolar e a universidade) e a esperança de vida. Estão aqui dados de três países diferentes, apresentados numa figura representada por pontos:',
  'quiz.exercise17.textPart2': 'Temos um país em que o número médio de anos de escolaridade é 10 e a esperança de vida são 57 anos, outro país em que o número médio de anos de escolaridade é 13 e a esperança de vida são 53 anos, e um terceiro país em que o número médio de anos de escolaridade é 20 e a esperança de vida são 80 anos.',
  'quiz.exercise17.textPart3': 'Pode arrastar os pontos terminais da linha contínua para a posicionar de maneira a seguir a tendência dos pontos de dados. Tenha em conta que não conseguirá fazer com que a linha se ajuste perfeitamente aos pontos de dados, o que não constitui um problema: alguns dos pontos de dados estarão acima da linha e outros abaixo da mesma. O mais importante é que a linha descreve a tendência global.',
  'quiz.exercise17.textPart4': 'Depois de posicionar a linha, pode usá-la para prever a esperança de vida.',
  'quiz.exercise17.textPart5': 'Tendo em conta os dados, o que pode dizer sobre a esperança de vida das pessoas que têm 15 anos de escolaridade? Importante: tenha em atenção que, mesmo que consiga obter uma previsão específica, até à fração de um ano, ao ajustar a linha, isso não significa necessariamente que consiga apontar uma previsão confiante. Tenha em conta a quantidade limitada de dados ao dar a sua resposta.',
  // exercise18
  'quiz.exercise18.xLabel': 'Anos de escolaridade',
  'quiz.exercise18.yLabel': 'Esperança de vida em anos',
  'quiz.exercise18.textPart1': 'No exercício anterior, apenas tínhamos dados de três países. O conjunto de dados completo é composto por dados de 14 países diferentes, que são apresentados aqui num gráfico:',
  'quiz.exercise18.textPart2': 'Com base nestes dados, a sua previsão sobre a esperança de vida das pessoas com 15 anos de escolaridade mudaria? Em caso afirmativo, porquê?',
  'quiz.exercise18.textPart3': 'Qual das seguintes opções corresponderia melhor à sua estimativa de esperança de vida para pessoas com 15 anos de escolaridade? Escolha a opção mais específica, que ache justificar-se depois de ajustar o modelo de linha reta aos dados acima.',
  // exercise19
  'quiz.exercise19.xLabel': 'Horas de estudo',
  'quiz.exercise19.yLabel': 'Probabilidade de obter aprovação',
  'quiz.exercise19.textPart1': 'Cada ponto na figura corresponde a um estudante. Na parte inferior da figura, temos a escala de quantas horas o estudante estudou para o exame, sendo que os estudantes que obtiveram aprovação são mostrados como pontos na parte superior do gráfico e os que reprovaram são apresentados ao fundo. Utilizaremos a escala à esquerda para indicar a probabilidade prevista de obter aprovação, que calcularemos a partir do modelo de regressão logística, como explicamos em seguida. Com base nesta figura, pode ver, por alto, que os estudantes que passaram mais tempo a estudar tiveram melhores hipóteses de obter aprovação no curso. Os casos extremos são especialmente intuitivos: com menos de uma hora de trabalho, é muito difícil obter aprovação no curso, mas com muito trabalho, a maioria dos estudantes terá êxito. Mas e aqueles que estudam uma quantidade de tempo algures entre os extremos? Se estudar durante 6 horas, quais são as suas hipóteses de obter aprovação?',
  'quiz.exercise19.textPart2': 'Podemos quantificar a probabilidade de obter aprovação utilizando a regressão logística. A curva na figura pode ser interpretada como a probabilidade de obter aprovação: por exemplo, se estudar cinco horas, a probabilidade de obter aprovação é ligeiramente superior a 20 %. Não vamos analisar em detalhe como obter a curva, mas o processo será semelhante à forma como aprendemos os pesos na regressão linear.',
  'quiz.exercise19.textPart3': 'Se quer ter 80 % de hipóteses de obter aprovação num exame universitário, com base na figura acima, durante aproximadamente quantas horas é que deve estudar?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Entrada',
  'quiz.exercise22.sigmoidYLabel': 'Saída de sigmoide',
  'quiz.exercise22.stepXLabel': 'Entrada',
  'quiz.exercise22.stepYLabel': 'Saída da função de passo',
  'quiz.exercise22.identityXLabel': 'Entrada',
  'quiz.exercise22.identityYLabel': 'Saída de identidade',
  'quiz.exercise22.textPart1': 'Abaixo, são apresentados gráficos para três funções de ativação diferentes, com propriedades diferentes. Em primeiro lugar, temos a função sigmoide, depois a função de passo e, por fim, a função de identidade.',
  // exercise23
  'quiz.exercise23.happyFaces': 'caras felizes classificadas corretamente',
  'quiz.exercise23.sadFaces': 'caras tristes classificadas corretamente',
  // certificate availability
  'certificate.failedToCheck': 'Erro ao verificar a disponibilidade do certificado',
  'certificate.failedToGenerate': 'Erro ao gerar certificado',
  'certificate.notYetAvailable': 'O seu certificado estará disponível aqui, após a nossa confirmação oficial. Isso poderá demorar algum tempo. Agradecemos a sua compreensão!',
  'certificate.updateName': 'O seu certificado está disponível! Por favor actualize o seu nome e apelido para mostrar no seu certificado, utilizando um formulário disponível na sua página de perfil.',
  'certificate.generate': 'Gerar o seu certificado abaixo.',
  'certificate.generateTextPart1': 'Será gerado o certificado para',
  'certificate.generateTextPart2': 'Certifique-se de que o nome está correto antes de gerar o certificado, uma vez que não pode ser alterado posteriormente. Se pretender alterar o seu nome, pode fazê-lo no formulário abaixo.',
  'certificate.generateButton': 'Gerar certificado',
  'certificate.downloadButton': 'Transferir certificado',
  'certificate.checkingAvailability': 'A verificar a disponibilidade do certificado...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Classificado corretamente',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Não está classificado corretamente',
  // section blocks
  'section.block.note': 'Nota',
  'section.block.example': 'Exemplo',
  'section.block.keyTerminology': 'Terminologia importante',
  'section.partSummary.spectrumTextPart1': 'Junte-se à ',
  'section.partSummary.spectrum': 'comunidade Elements of AI',
  'section.partSummary.spectrumTextPart2': ' para debater e fazer perguntas sobre este capítulo.',
  'section.partSummary.feedbackTitle': 'Dê-nos a sua opinião sobre esta parte',
  'section.partSummary.feedbackPlaceholder': 'Escreva a sua opinião aqui...',
  'section.partSummary.feedbackSuccess': 'Agradecemos os seus comentários!',
  // 404
  'globals.404.title': '404 – Página não encontrada',
  'globals.404.intro': "Escolheu seguir por um caminho que não existe... maldição!",
  'globals.loading': 'A carregar...',
  // footer / menu
  'globals.menu': 'Menu',
  'globals.menu.about': 'Sobre',
  'globals.menu.faq': 'FAQ',
  'globals.menu.privacyPolicy': 'Política de privacidade',
  'globals.menu.termsofService': 'Termos de serviço',
  'globals.menu.myProfile': 'O meu perfil',
  // Dashboard/Index
  'dashboard.title': 'Bem-vindo/a ao curso!',
  'dashboard.congratulations.title': 'Parabéns!',
  'dashboard.congratulations.message': 'Concluiu o curso Elements of AI',
  'dashboard.congratulations.downloadCertificate': 'Transferir certificado',
  'dashboard.notLoggedIn.title': 'Bem-vindo/a!',
  'dashboard.notLoggedIn.message': 'Inscreva-se ou inicie sessão para ter pleno acesso ao curso. É gratuito!',
  // Account
  'account.certificate': 'Certificado',
  'account.myProfile': 'O meu perfil',
  'account.credits': 'Créditos ECTS',
  'account.profileInformation': 'Informação do perfil',
  'account.changePassword': 'Alterar a palavra-passe',
  'account.changeLanguage': 'Alterar a língua',
  'account.goToCourse': 'Ir para o curso',
  'account.buildingAINew': 'Novo curso em Inglês',
  'account.accept': 'Aceitar',
  'account.decline': 'Recusar',
  'account.courses': 'Cursos',
  // Sign Up page
  'signUp.description': 'Preencha o formulário abaixo para criar uma conta mooc.fi',
  'signUp.email': 'O seu endereço de correio eletrónico',
  'signUp.firstName': 'Nome próprio',
  'signUp.lastName': 'Apelido',
  'signUp.postalCode': 'Código postal',
  'signUp.country': 'Onde vive?',
  'signUp.Password': 'Palavra-passe',
  'signUp.confirmPassword': 'Confirmar palavra-passe',
  'signUp.howToStudyTitle': 'Como pretende estudar?',
  'signUp.6weeksTitle': 'Curso de 6 semanas',
  'signUp.6weeksSubTitle': 'Recomendado (aprox. 5h/semana)',
  'signUp.6weeksText': 'A fixação de um prazo é uma forma comprovada de o ajudar a terminar o curso.',
  'signUp.selfPacedTitle': 'Ritmo personalizado',
  'signUp.selfPacedText': 'Pode seguir o curso ao seu próprio ritmo.',
  'signUp.ectsTitle': 'Está a requerer a atribuição de créditos ECTS?',
  'signUp.ectsTitleSwe': ' ',
  'signUp.ectsStudent': 'Sou estudante na Universidade de Helsínquia. Se assinalar esta casa e indicar o seu número de estudante, receberá automaticamente os créditos ECTS.',
  'signUp.ectsStudentSwe': ' ',
  'signup.ectsStudentSweLink': ' ',
  'signUp.ectsOpenUni': 'Assinale esta casa para receber uma mensagem de correio eletrónico com instruções sobre como requerer a atribuição de créditos ECTS',
  'signUp.studentNumber': 'Número de estudante da Universidade de Helsínquia (se for estudante)',
  'signUp.privacyOptIn': 'Autorizo a utilização dos dados recolhidos sobre o meu comportamento de estudo para fins de investigação. Os dados contêm informações sobre a utilização dos materiais e os exercícios do curso. Não é possível identificar quaisquer pessoas a partir das publicações.',
  'signUp.marketingOptIn': 'Aceito receber atualizações sobre versões futuras noutros idiomas e informações relativas a novos cursos. Concordo em compartilhar as minhas informações de contato, para receber mensagens personalizadas em plataformas de terceiros. Consulte aqui a nossa política de privacidade.',
  'signUp.acceptPolicyFirst': 'Aceito a Política de Privacidade e os Termos de Serviço.',
  'signUp.privacyLink': 'Leia a nossa política de privacidade',
  'signUp.error': 'O seu formulário tem erros. Confira o seu formulário.',
  // Sign in page
  'signIn.title': 'Iniciar sessão',
  'signIn.intro': 'Utilize o formulário abaixo para iniciar sessão com a sua conta mooc.fi',
  'signIn.email': 'O seu endereço de correio eletrónico',
  'signIn.password': 'A sua palavra-passe',
  'signIn.noAccount': "Ainda não tem conta?",
  'signIn.signUpNow': 'Inscreva-se já',
  // Forgot password
  'pwForget.title': 'Esqueceu-se da palavra-passe?',
  'pwForget.description': 'Indique o seu endereço de correio eletrónico mooc.fi e enviar-lhe-emos a ligação para reposição da palavra-passe',
  'pwForget.sendResetLink': 'Enviar a ligação para reposição',
  'pwForget.completedDescription': 'Deverá receber, em breve, uma mensagem de correio eletrónico de mooc.fi com uma ligação para repor a palavra-passe. Se não estiver visível na caixa de entrada, consulte a sua pasta de spam.',
  'pwForget.problemDescription': 'Em caso de problemas, contacte mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent': 'Foi enviada uma mensagem de correio eletrónico para repor a palavra-passe',
  'pwForget.emailPlaceholder': 'Endereço de correio eletrónico',
  // password change
  'pwChange.authError': 'Erro na autenticação. Tente novamente iniciar a sessão',
  'pwChange.unexpError': 'Não foi possível atualizar a palavra-passe devido a um erro inesperado',
  'pwChange.required': 'Obrigatório',
  'pwChange.pwMustMatch': 'As palavras-passe têm de ser iguais',
  'pwChange.currentPassword': 'Palavra-passe atual',
  'pwChange.newPassword': 'Nova palavra-passe',
  'pwChange.confirmPassword': 'Confirmar a nova palavra-passe',
  'pwChange.changingPassword': 'A alterar a palavra-passe...',
  'pwChange.changePassword': 'Alterar a palavra-passe',
  'pwChange.passwordChanged': 'A palavra-passe foi alterada',
  // user details
  'userDetails.authError': 'Erro na autenticação. Tente novamente iniciar a sessão',
  'userDetails.unexpError': 'Não foi possível atualizar a palavra-passe devido a um erro inesperado',
  'userDetails.required': 'Obrigatório',
  'userDetails.invalidEmail': 'Endereço de correio eletrónico inválido',
  'userDetails.email': 'Correio eletrónico',
  'userDetails.emailAddress': 'Endereço de correio eletrónico',
  'userDetails.firstName': 'Nome próprio',
  'userDetails.lastName': 'Apelido',
  'userDetails.language': 'Língua',
  'userDetails.updatingProfile': 'A atualizar o perfil...',
  'userDetails.updateProfile': 'Atualizar o perfil',
  'userDetails.profileUpdated': 'Perfil atualizado',
  // languages
  'languages.en.long': 'Inglés',
  'languages.fi.long': 'Finlandes',
  'languages.se.long': 'Sueco',
  'languages.de.long': 'Alemão (Alemanha)',
  'languages.ee.long': 'Estoniano',
  'languages.pt.long': 'Português',
  'languages.it.long': 'Italiano',
  'languages.no.long': 'Norueguês',
  'languages.lv.long': 'Letão',
  'languages.fr.long': 'Francês (França)',
  'languages.pl.long': 'Polaco',
  'languages.en-ie.long': 'Inglés (Irlanda)',
  'languages.mt.long': 'Maltês',
  'languages.fr-be.long': 'Francês (Bélgica)',
  'languages.lt.long': 'Lituano',
  'languages.nl-be.long': 'Holandês (Bélgica)',
  'languages.ro.long': 'Romeno',
  'languages.hr.long': 'Croato',
  'languages.da.long': 'Dinamarquês',
  'languages.sk.long': 'Eslovaco',
  'languages.en-lu.long': 'Inglés (Luxemburgo)',
  'languages.bg.long': 'Búlgaro',
  'languages.cs.long': 'Tcheco',
  'languages.nl.long': 'Holandês (Países Baixos)',
  'languages.es.long': 'Castelhano',
  'languages.de-at.long': 'Alemão (Áustria)',
  'languages.el.long': 'Grego',
  'languages.ga.long': 'Irlandês',
  'languages.sl.long': 'Esloveno',
  'languages.is.long': 'Islandês',
  // Email verfification
  'emailVerification.completedMessage': 'O seu endereço de correio eletrónico foi verificado',
  'emailVerification.verified': 'Verificado',
  // Exercises
  'exercise.loading': 'A carregar exercício...',
  'exercise.signUp': 'Inscreva-se para resolver exercícios',
  'exercise.score': 'A sua pontuação:',
  'exercise.wrongLocale': 'Local errado',
  // Part tempplate
  'partTemplate.intro1': 'No capítulo ',
  'partTemplate.intro2': ', vamos analisar as secções seguintes. Clique abaixo para começar:',
  'partTemplate.spectrumPart1': 'Junte-se à ',
  'partTemplate.spectrum': 'comunidade Elements of AI',
  'partTemplate.spectrumPart2': ' para debater e fazer perguntas sobre este capítulo.',
  // missing info
  'missingInfo.title': 'Informações em falta',
  'missingInfo.body': 'Colocamos normalmente estas questões quando os utilizadores se inscrevem, mas iniciou sessão com uma conta mooc.fi existente. Preencha o formulário abaixo para continuar.',
  'missingInfo.privacyPolicy': 'Leia a nossa política de privacidade',
  'missingInfo.error': 'O seu formulário tem erros. Confira o seu formulário.',
  'missingInfo.privacyPolicyUpdateBody': "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
  'missingInfo.privacyPolicyUpdateLink': 'Pode revisar a política de privacidade aqui.',
  // routes
  'routes.LANDING': '/pt/',
  'routes.SIGN_UP': '/pt/signup',
  'routes.SIGN_IN': '/pt/signin',
  'routes.MISSING_INFO': '/pt/missinginfo',
  'routes.PASSWORD_FORGET': '/pt/pw-forget',
  'routes.HOME': '/pt/home',
  'routes.ACCOUNT': '/pt/account',
  'routes.ACCEPT_POLICY': '/pt/accept-policy',
  'routes.PRIVACY': 'https://www.elementsofai.pt/faq/política-de-privacidade',
  'routes.FAQ': 'https://www.elementsofai.pt/faq',
  'routes.ABOUT': 'https://www.elementsofai.pt',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/pt-discuta-em-portugues',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.ects.faq': 'https://www.elementsofai.pt/faq/como-posso-obter-2-cr%C3%A9ditos-ects',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Chávenas de café por dia',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Linhas de código escritas',
  // meta
  'meta.title': 'Um curso em linha gratuito',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Saiba mais sobre o curso de IA da Universidade de Helsínquia e da Reaktor para estudantes e empresários – não precisa de ter conhecimentos de programação ou de matemática.',
  'meta.sitename': 'Elements of AI',
  // ECTS
  'ects.text': 'Depois de concluir o curso, receberá instruções sobre como requerer a atribuição de créditos ECTS.',
  'ects.textCompleted': 'Se tiver um número finlandês de segurança social, pode requerer a atribuição de créditos clicando ',
  'ects.link': 'aqui.',
  'ects.info': 'Gostaria de solicitar créditos ECTS?',
  'ects.readMore': 'Consulte mais informação',
  // Countries
  'countryselect.select': "Por favor seleccione",
  'Finland': 'Finlandia',
  'Estonia': 'Estonia',
  'Germany': 'Alemania',
  'Sweden': 'Suecia',
  "Countries": {
    "Afganistan": "Afganistan",
    "Åland Islands": "Ilhas Aland",
    "Albania": "Albânia",
    "Algeria": "Argélia",
    "American Samoa": "Samoa Americana",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antarctica": "Antártica",
    "Antigua and Barbuda": "Antígua e Barbuda",
    "Argentina": "Argentina",
    "Armenia": "Armênia",
    "Aruba": "Aruba",
    "Australia": "Austrália",
    "Austria": "Áustria",
    "Azerbaijan": "Azerbaijão",
    "Bahamas": "Bahamas",
    "Bahrain": "Barém",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbados",
    "Belarus": "Bielorrússia",
    "Belgium": "Bélgica",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermudas",
    "Bhutan": "Butão",
    "Bolivia": "Bolívia",
    "Bosnia and Herzegovina": "Bósnia e Herzegovina",
    "Botswana": "Botsuana",
    "Bouvet Island": "Ilha Bouvet",
    "Brazil": "Brasil",
    "British Indian Ocean Territory": "Território Britânico do Oceano Índico",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgária",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambodia": "Camboja",
    "Cameroon": "Camarões",
    "Canada": "Canadá",
    "Cape Verde": "cabo Verde",
    "Cayman Islands": "Ilhas Cayman",
    "Central African Republic": "República Centro-Africana",
    "Chad": "Chade",
    "Chile": "Chile",
    "China": "China",
    "Christmas Island": "Ilha do Natal",
    "Cocos (Keeling) Islands": "Ilhas Cocos (Keeling)",
    "Colombia": "Colômbia",
    "Comoros": "Comores",
    "Congo": "Congo",
    "Congo, The Democratic Republic of The": "Congo, República Democrática do",
    "Cook Islands": "Ilhas Cook",
    "Costa Rica": "Costa Rica",
    "Cote D'ivoire": "Costa do Marfim",
    "Croatia": "Croácia",
    "Cuba": "Cuba",
    "Cyprus": "Chipre",
    "Czech Republic": "República Checa",
    "Denmark": "Dinamarca",
    "Djibouti": "Djibuti",
    "Dominica": "Dominica",
    "Dominican Republic": "República Dominicana",
    "Ecuador": "Equador",
    "Egypt": "Egito",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Guiné Equatorial",
    "Eritrea": "Eritreia",
    "Estonia": "Estônia",
    "Ethiopia": "Etiópia",
    "Falkland Islands (Malvinas)": "Ilhas Falkland (Malvinas)",
    "Faroe Islands": "Ilhas Faroe",
    "Fiji": "Fiji",
    "Finland": "Finlândia",
    "France": "França",
    "French Guiana": "Guiana Francesa",
    "French Polynesia": "Polinésia Francesa",
    "French Southern Territories": "Territórios Franceses do Sul",
    "Gabon": "Gabão",
    "Gambia": "Gâmbia",
    "Georgia": "Geórgia",
    "Germany": "Alemanha",
    "Ghana": "Gana",
    "Gibraltar": "Gibraltar",
    "Greece": "Grécia",
    "Greenland": "Gronelândia",
    "Grenada": "Granada",
    "Guadeloupe": "Guadalupe",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea": "Guiné",
    "Guinea-Bissau": "Guiné-Bissau",
    "Guyana": "Guiana",
    "Haiti": "Haiti",
    "Heard Island and Mcdonald Islands": "Ilha Heard e Ilhas Mcdonald",
    "Holy See (Vatican City State)": "Santa Sé (Estado da Cidade do Vaticano)",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Hungria",
    "Iceland": "Islândia",
    "India": "Índia",
    "Indonesia": "Indonésia",
    "Iran, Islamic Republic of": "Irã (Republic Islâmica do Irã",
    "Iraq": "Iraque",
    "Ireland": "Irlanda",
    "Isle of Man": "Ilha de Man",
    "Israel": "Israel",
    "Italy": "Itália",
    "Jamaica": "Jamaica",
    "Japan": "Japão",
    "Jersey": "Jersey",
    "Jordan": "Jordânia",
    "Kazakhstan": "Cazaquistão",
    "Kenya": "Quênia",
    "Kiribati": "Kiribati",
    "Korea, Democratic People's Republic of": "Coréia, República Popular Democrática da",
    "Korea, Republic of": "Republica da Coréia",
    "Kosovo": "Kosovo",
    "Kuwait": "Kuwait",
    "Kyrgyzstan": "Quirguistão",
    "Lao People's Democratic Republic": "República Democrática Popular do Laos",
    "Latvia": "Letônia",
    "Lebanon": "Líbano",
    "Lesotho": "Lesoto",
    "Liberia": "Libéria",
    "Libyan Arab Jamahiriya": "Jamahiriya árabe da Líbia",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Lituânia",
    "Luxembourg": "Luxemburgo",
    "Macao": "Macau",
    "Macedonia, The Former Yugoslav Republic of": "Macedônia, Antiga República Jugoslava da",
    "Madagascar": "Madagáscar",
    "Malawi": "Malawi",
    "Malaysia": "Malásia",
    "Maldives": "Maldivas",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Ilhas Marshall",
    "Martinique": "Martinica",
    "Mauritania": "Mauritânia",
    "Mauritius": "Maurícia",
    "Mayotte": "Mayotte",
    "Mexico": "México",
    "Micronesia, Federated States of": "Micronésia, Estados Federados da",
    "Moldova, Republic of": "Moldávia, República da",
    "Monaco": "Mônaco",
    "Mongolia": "Mongólia",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Marocco": "Marocco",
    "Mozambique": "Moçambique",
    "Myanmar": "Myanmar",
    "Namibia": "Namíbia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Países Baixos",
    "Netherlands Antilles": "Antilhas Holandesas",
    "New Caledonia": "Nova Caledônia",
    "New Zealand": "Nova Zelândia",
    "Nicaragua": "Nicarágua",
    "Niger": "Níger",
    "Nigeria": "Nigéria",
    "Niue": "Niue",
    "Norfolk Island": "Ilha Norfolk",
    "Northern Mariana Islands": "Ilhas Marianas do Norte",
    "Norway": "Noruega",
    "Oman": "Omã",
    "Pakistan": "Paquistão",
    "Palau": "Palau",
    "Palestinian Territory, Occupied": "Território Palestino, Ocupado",
    "Panama": "Panamá",
    "Papua New Guinea": "Papua Nova Guiné",
    "Paraguay": "Paraguai",
    "Peru": "Peru",
    "Philippines": "Filipinas",
    "Pitcairn": "Pitcairn",
    "Poland": "Polônia",
    "Portugal": "Portugal",
    "Puerto Rico": "Porto Rico",
    "Qatar": "Catar",
    "Reunion": "Reunião",
    "Romania": "Romênia",
    "Russian Federation": "Federação Russa",
    "Rwanda": "Ruanda",
    "Saint Helena": "Santa Helena",
    "Saint Kitts and Nevis": "São Cristóvão e Nevis",
    "Saint Lucia": "Santa Lúcia",
    "Saint Pierre and Miquelon": "São Pedro e Miquelon",
    "Saint Vincent and The Grenadines": "São Vicente e Granadinas",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "São Tomé e Príncipe",
    "Saudi Arabia": "Arábia Saudita",
    "Senegal": "Senegal",
    "Serbia": "Sérvia",
    "Seychelles": "Seychelles",
    "Sierra Leone": "Serra Leoa",
    "Singapore": "Cingapura",
    "Slovakia": "Eslováquia",
    "Slovenia": "Eslovênia",
    "Solomon Islands": "Ilhas Salomão",
    "Somalia": "Somália",
    "South Africa": "África do Sul",
    "South Georgia and The South Sandwich Islands": "Ilhas Geórgia do Sul e Sandwich do Sul",
    "Spain": "Espanha",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudão",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard e Jan Mayen",
    "Swaziland": "Suazilândia",
    "Sweden": "Suécia",
    "Swizerland": "Suíça",
    "Syrian Arab Republic": "República Árabe da Síria",
    "Taiwan": "Taiwan",
    "Tajikistan": "Tajiquistão",
    "Tanzania, United Republic of": "Tanzânia, República Unida da",
    "Thailand": "Tailândia",
    "Timor-leste": "Timor-leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trindade e Tobago",
    "Tunisia": "Tunísia",
    "Turkey": "Turquia",
    "Turkmenistan": "Turquemenistão",
    "Turks and Caicos Islands": "Ilhas Turcas e Caicos",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ucrânia",
    "United Arab Emirates": "Emirados Árabes Unidos",
    "United Kingdom": "Reino Unido",
    "United States": "Estados Unidos",
    "United States Minor Outlying Islands": "Ilhas Menores Distantes dos Estados Unidos",
    "Uruguay": "Uruguai",
    "Uzbekistan": "Usbequistão",
    "Vanuatu": "Vanuatu",
    "Venezuela": "Venezuela",
    "Viet Nam": "Vietnã",
    "Virgin Islands, British": "Ilhas Virgens Britânicas",
    "Virgin Islands, U.S.": "Ilhas Virgens, EUA",
    "Wallis and Futuna": "Wallis e Futuna",
    "Western Sahara": "Saara Ocidental",
    "Yemen": "Iémen",
    "Zambia": "Zâmbia",
    "Zimbabwe": "Zimbábue"
  },
  //Alt texts
  'alt.example-1': 'dois carros autónomos',
  'alt.example-2': 'mão segura um telemóvel',
  'alt.example-3': "rosto de mulher",
  'alt.defining-ai': 'aglomerado de palavras relacionadas com a IA',
  'alt.turing-test': 'homem conversa por computador com um robô e uma mulher',
  'alt.chinese-room': 'experiência do Quarto Chinês',
  'alt.chicken-crossing': 'robô num barco observa uma galinha, uma raposa e um saco de ração para galinha',
  'alt.a-to-b': 'mão segura um telemóvel com uma aplicação de navegação',
  'alt.tic-tac-toe': 'crianças brincam ao jogo do galo',
  'alt.self-driving-car': 'carro autónomo junto a um sinal de STOP',
  'alt.spam-or-ham': 'uma mensagem de correio eletrónico normal e uma mensagem spam',
  'alt.mnist': 'conjunto de dados MNIST',
  'alt.nearest-neighbor': 'duas pessoas fazem compras',
  'alt.nearest-neighbor-graph': 'classificador vizinho mais próximo',
  'alt.recommendation': 'homem e mulher ouvem música no telefone',
  'alt.price-real-estate': 'três casas diferentes',
  'alt.supervised-learning': 'ser humano ensina um robô',
  'alt.dice': 'dadas',
  'alt.chess': 'tabuleiro de xadrez',
  'alt.poker': 'mão segura cartas de baralho',
  'alt.eyes': 'três pessoas com olhos castanhos, uma pessoa com olhos azuis',
  'alt.search-1': 'caminho mais curto até ao objetivo',
  'alt.search-2': 'quatro caminhos diferentes conducentes ao mesmo objetivo',
  'alt.chicken-crossing-1': 'travessia da galinha: estado inicial',
  'alt.chicken-crossing-2': 'travessia da galinha: primeira transição',
  'alt.chicken-crossing-3': 'travessia da galinha: todas as transições',
  'alt.chicken-crossing-4': 'travessia da galinha: melhor caminho',
  'alt.chicken-crossing-5': 'travessia da galinha: estado inicial',
  'alt.implication-1': 'homem e mulher',
  'alt.implication-2': 'olhos',
  'alt.implication-3': 'câmara de vigilância',
  'alt.implication-4': 'robô trabalha com um computador portátil',
  'alt.oddchange': 'sol e nuvens',
  'alt.brain': 'desenho de um cérebro',
  'alt.carstop': 'carro autónomo junto a um sinal de STOP',
  'alt.gan': 'robô segura uma imagem de um gato e uma imagem de um sinal de STOP',
  'alt.hammock': 'robô abana folha de palmeira junto a um homem deitado numa rede',
  'alt.game-tree-1': 'árvore de jogo 1',
  'alt.game-tree-2': 'árvore de jogo 2',
  'alt.game-tree-3': 'árvore de jogo 3',
  'alt.game-tree-4': 'árvore de jogo 4',
  'alt.bayes-rule-1': 'imagem que ilustra falsos positivos e negativos de um rastreio de cancro',
  'alt.bayes-rule-2': 'imagem que ilustra falsos positivos e negativos de um rastreio de cancro',
  'alt.defineai': 'aglomerado de palavras relacionadas com a IA',
  'alt.winter': 'robô a dormir na neve',
  'alt.terminator': 'robô assustador',
}
