export const MULTIPLE_CHOICE = "MULTIPLE_CHOICE";
export const ESSAY = "ESSAY";
export const OPEN = "OPEN";
export const PEER_REVIEW = "PEER_REVIEW";
export const PEER_REVIEWS_RECEIVED = "PEER_REVIEWS_RECEIVED";
export const CHECKBOX = "CHECKBOX";
export const PRIVACY_AGREEMENT = "PRIVACY_AGREEMENT";
export const SCALE = "SCALE";
export const RADIO_MATRIX = "RADIO_MATRIX";
export const MULTIPLE_OPEN = "MULTIPLE_OPEN";
