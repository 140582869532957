// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translation is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'it',
  'globals.signIn': 'Accedi',
  'globals.logIn': 'Accedi',
  'globals.signUp': 'Registrati adesso',
  'globals.signOut': 'Esci',
  'globals.logOut': 'Disconnettiti',
  'globals.submit': 'Invia',
  'globals.submitting': 'Invio...',
  'globals.yourEmail': 'La tua e-mail',
  'globals.yourPassword': 'La tua password',
  'globals.courseOverview': 'Panoramica del corso',
  'globals.forgotPassword': 'Hai dimenticato la password?',
  'globals.invalidCredentials': 'Credenziali non valide',
  'globals.section': 'Sezione',
  'globals.exercises': 'Esercizi',
  'globals.chapter': 'Capitolo',
  'globals.introToAI': "Introduzione all'IA",
  'globals.buildingAI': "Costruire l'IA",
  'globals.acceptPolicy': 'Accept policy',
  // section
  'section.footerEndOfTheChapter': 'Hai finito il capitolo!',
  'section.footerEndOfTheCourseTitle': 'Hai finito il corso!',
  'section.footerEndOfTheCourseText': 'Dopo aver completato tutti gli esercizi e le valutazioni tra pari, le tue risposte saranno esaminate dal nostro staff.',
  'section.nextSection': 'Prossimo sezione',
  'section.nextChapter': 'Prossimo capitolo',
  'section.start': 'Inizia',
  'section.logisticRegressionExample.slope': 'Pendenza',
  'section.logisticRegressionExample.intercept': 'Intercetta',
  'section.footerEndOfTheCourseBAI': 'Continua il tuo viaggio verso la possibilità di implementare la tua prima idea di intelligenza artificiale.',
  // quiz peer review
  'quiz.awaitingPeerReview': 'In attesa della tua valutazione',
  'quiz.rejectedPeerReview': 'Respinta dalla valutazione tra pari',
  'quiz.unsubmittedAnswer': 'Risposta non inviata',
  'quiz.pendingPeerReview': 'In attesa della valutazione tra pari',
  // quiz
  'quiz.status.answered': 'Hai risposto',
  'quiz.status.rejected': 'Risposta respinta, riprova',
  'quiz.status.unanswered': 'Non hai risposto',
  'quiz.status.exercisesCompleted': 'Esercizi completati',
  'quiz.status.correctAnswers': 'Risposte esatte',
  'quiz.message.rejectedInReview': 'La tua risposta precedente è stata respinta durante la valutazione. Riprova.',
  'quiz.youAnswered': 'Hai risposto',
  'quiz.yourAnswer': 'La tua risposta...:',
  'quiz.yourScore': 'Il tuo punteggio::',
  'quiz.quizNotLoaded': 'Impossibile caricare il quiz',
  'quiz.words': 'Parole:',
  'quiz.exampleAnswer': 'Esempio di risposta:',
  // peer review
  'peerreview.question1': 'La risposta è pertinente',
  'peerreview.question2': 'La risposta è esauriente',
  'peerreview.question3': 'La risposta è ben motivata',
  'peerreview.question4': 'La risposta è facile da seguire',
  'peerreview.thankYou': 'Grazie per aver valutato i tuoi compagni!',
  'peerreview.giveMoreReviews': 'Se hai un po\' di tempo,',
  'peerreview.giveMoreReviewsButton': 'effettua altre valutazioni.',
  'peerreview.notEnoughAnswers': 'Di norma qui ci sarebbe una valutazione tra pari, ma questo esercizio non ha ancora ottenuto abbastanza risposte.',
  'peerreview.selectAnswerMessage': 'È il momento di una valutazione tra pari! Qui sotto ci sono un paio di risposte di altri studenti. Selezionane una e valutala. Fallo almeno altre tre volte.',
  'peerreview.minimumPeerReviewWarning': 'L\'esercizio può essere accettato solo dopo che hai effettuato almeno tre valutazioni tra pari.',
  'peerreview.select': 'Seleziona',
  'peerreview.reportSpam': 'Segnala spam',
  'peerreview.feedback': 'La tua risposta è stata valutata e accettata. Ecco cosa ne hanno pensato i tuoi compagni:',
  // exercise17
  'quiz.exercise17.xLabel': 'Anni di istruzione',
  'quiz.exercise17.yLabel': 'Aspettativa di vita in anni',
  'quiz.exercise17.textPart1': 'Studiamo ora il legame tra il numero totale di anni trascorsi a scuola (che comprende tutto ciò che accade tra fase prescolare e università) e l\'aspettativa di vita. Ecco i dati di tre diversi paesi mostrati in una figura sotto forma di punti:',
  'quiz.exercise17.textPart2': 'Sulla base di tali dati, la tua previsione riguardo all\'aspettativa di vita delle persone con 15 anni di istruzione cambierebbe? E in tal caso, per quale motivo?',
  'quiz.exercise17.textPart3': 'Quale delle seguenti opzioni corrisponde meglio alla tua stima dell\'aspettativa di vita delle persone con 15 anni di istruzione? Scegli l\'opzione più specifica che ritieni giustificata adattando il modello della linea retta ai dati che precedono.',
  'quiz.exercise17.textPart4': 'Dopo aver posizionato la linea, puoi usarla per prevedere l\'aspettativa di vita.',
  'quiz.exercise17.textPart5': 'Analizzando i dati, che cosa puoi dire dell\'aspettativa di vita delle persone che hanno alle spalle 15 anni di istruzione? Importante: nota che anche riuscendo a ottenere una previsione specifica fino a una frazione di anno, non necessariamente sarai in grado di fornire una previsione sicura. Tieni conto della quantità limitata di dati quando fornisci la tua risposta.',
  // exercise18
  'quiz.exercise18.xLabel': 'Anni di istruzione',
  'quiz.exercise18.yLabel': 'Aspettativa di vita in anni',
  'quiz.exercise18.textPart1': 'Nell\'esercizio precedente avevamo soltanto i dati di tre paesi. Il set di dati completo è invece composto dai dati di 14 paesi diversi, qui presentati sotto forma di grafico:',
  'quiz.exercise18.textPart2': 'Sulla base di tali dati, la tua previsione riguardo all\'aspettativa di vita delle persone con 15 anni di istruzione cambierebbe? E in tal caso, per quale motivo?',
  'quiz.exercise18.textPart3': 'Quale delle seguenti opzioni corrisponde meglio alla tua stima dell\'aspettativa di vita delle persone con 15 anni di istruzione? Scegli l\'opzione più specifica che ritieni giustificata adattando il modello della linea retta ai dati che precedono.',
  // exercise19
  'quiz.exercise19.xLabel': 'Ore di studio',
  'quiz.exercise19.yLabel': 'Probabilità di superare l\'esame',
  'quiz.exercise19.textPart1': 'Ogni punto nella figura corrisponde a uno studente. In fondo alla figura abbiamo la scala di quante ore ha studiato lo studente per l\'esame, mentre gli studenti che hanno superato l\'esame sono mostrati sotto forma di punti in cima al grafico e quelli che non l\'hanno passato sono mostrati in basso. Utilizzeremo la scala a sinistra per indicare la probabilità prevista di superare l\'esame, che otterremo dal modello di regressione logistica spiegato qui sotto.',
  'quiz.exercise19.textPart2': 'Sulla base di questa figura, si può vedere indicativamente che gli studenti che hanno trascorso più tempo studiando avevano migliori probabilità di superare l\'esame. Sono intuitivi soprattutto i casi estremi: con meno di un\'ora di lavoro è molto difficile superare il corso, ma con molto lavoro la maggior parte avrà successo. Ma che dire di quelli che si collocano da qualche parte in mezzo ai due estremi? Quali sono le probabilità di passare studiando sei ore?',
  'quiz.exercise19.textPart3': 'Possiamo quantificare la probabilità di superare l\'esame usando la regressione logistica. La curva nella figura può essere interpretata come la probabilità di superare l\'esame: per esempio, dopo aver studiato per cinque ore, la probabilità di passare è appena superiore al 20 %. Non entreremo nei dettagli di come ottenere la curva, ma diciamo soltanto che la procedura è simile all\'apprendimento dei pesi nella regressione lineare.',
  'quiz.exercise19.textPart4': 'Se vuoi passare un esame universitario con una probabilità dell\' 80 %, sulla base della figura che precede quante ore dovrai studiare approssimativamente?',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Input',
  'quiz.exercise22.sigmoidYLabel': 'Output sigmoide',
  'quiz.exercise22.stepXLabel': 'Input',
  'quiz.exercise22.stepYLabel': 'Output funzione gradino',
  'quiz.exercise22.identityXLabel': 'Input',
  'quiz.exercise22.identityYLabel': 'Output identità',
  'quiz.exercise22.textPart1': 'Di seguito sono riportati i grafici di tre diverse funzioni di attivazione con proprietà differenti. Innanzitutto abbiamo la funzione sigmoide, poi la funzione gradino e infine la funzione identità.',
  // exercise23
  'quiz.exercise23.happyFaces': 'faccine felici classificate correttamente',
  'quiz.exercise23.sadFaces': 'faccine tristi classificate correttamente',
  // certificate availability
  'certificate.failedToCheck': 'Impossibile verificare la disponibilità del certificato',
  'certificate.failedToGenerate': 'Impossibile generare certificato',
  'certificate.notYetAvailable': 'Il tuo certificato sarà disponibile qui dopo la conferma ufficiale da parte nostra. Potrebbe volerci un po\'. Grazie per la pazienza!',
  'certificate.updateName': 'Il tuo certificato è disponibile! Si prega di aggiornare il nome e il cognome da riportare sul certificato utilizzando il modulo disponibile nella pagina del profilo.',
  'certificate.generate': 'Genera il tuo certificato qui sotto',
  'certificate.generateTextPart1': 'Il certificato sarà generato per',
  'certificate.generateTextPart2': 'Prima di generare il certificato assicurati che il nome sia corretto, perché in seguito non sarà più possibile modificarlo. Se desideri modificare il nome, puoi farlo compilando il modulo qui sotto.',
  'certificate.generateButton': 'Genera certificato',
  'certificate.downloadButton': 'Scarica il certificato',
  'certificate.checkingAvailability': 'Verifica della disponibilità del certificato...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Classificazione esatta',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Classificazione inesatta',
  // section blocks
  'section.block.note': 'Nota',
  'section.block.example': 'Esempio',
  'section.block.keyTerminology': 'Termini chiave',
  'section.partSummary.spectrumTextPart1': 'Unisciti alla ',
  'section.partSummary.spectrum': 'comunità Elements of AI',
  'section.partSummary.spectrumTextPart2': ' per discutere di questo capitolo e porre domande.',
  'section.partSummary.feedbackTitle': 'Dacci il tuo feedback su questa parte',
  'section.partSummary.feedbackPlaceholder': 'Scrivi il tuo feedback qui.',
  'section.partSummary.feedbackSuccess': 'Grazie per il feedback!',
  // 404
  'globals.404.title': '404 - Pagina non trovata',
  'globals.404.intro': 'Hai seguito un percorso che non esiste... che tristezza.',
  'globals.loading': 'Caricamento...',
  // footer / menu
  'globals.menu': 'Menu',
  'globals.menu.about': 'Chi siamo',
  'globals.menu.faq': 'FAQ',
  'globals.menu.privacyPolicy': 'Informativa sulla privacy',
  'globals.menu.myProfile': 'Il mio profilo',
  'globals.menu.termsofService': 'Termini di servizio',
  // Dashboard/Index
  'dashboard.title': 'Benvenuti al corso',
  'dashboard.congratulations.title': 'Complimenti!',
  'dashboard.congratulations.message': 'Hai completato il corso "Elements of AI"',
  'dashboard.congratulations.downloadCertificate': 'Scarica il certificato',
  'dashboard.notLoggedIn.title': 'Benvenuti!',
  'dashboard.notLoggedIn.message': 'Registrati o effettua l\'accesso per avere pieno accesso al corso. È gratuito!',
  // Account
  'account.certificate': 'Certificato',
  'account.credits': 'Crediti ECTS',
  'account.myProfile': 'Il mio profilo',
  'account.changePassword': 'Modifica password',
  'account.changeLanguage': 'Cambia lingua',
  'account.profileInformation': 'Informazioni del profilo',
  'account.goToCourse': 'Passare al corso',
  'account.buildingAINew': 'Nuovo corso in inglese',
  'account.accept': 'Accetto',
  'account.decline': 'Rifiuto',
  'account.courses': 'Corsi',
  // Sign Up page
  'signUp.description': 'Compila il modulo qui sotto per creare un nuovo account sulla piattaforma mooc.fi.',
  'signUp.email': 'La tua e-mail',
  'signUp.firstName': 'Nome',
  'signUp.lastName': 'Cognome',
  'signUp.postalCode': 'Codice postale',
  'signUp.country': 'Dove vivi?',
  'signUp.Password': 'Password',
  'signUp.confirmPassword': 'Conferma password',
  'signUp.howToStudyTitle': 'Come vuoi studiare?',
  'signUp.6weeksTitle': 'Corso di 6 settimane',
  'signUp.6weeksSubTitle': 'Raccomandato (circa 5 ore settimanali)',
  'signUp.6weeksText': 'Fissare una scadenza è un metodo comprovato per aiutarti a completare il corso.',
  'signUp.selfPacedTitle': 'Autogestito',
  'signUp.selfPacedText': 'Puoi seguire il corso secondo i tuoi tempi.',
  'signUp.ectsTitleSwe': 'Would you like to apply for ECTS credits? ',
  'signUp.ectsTitle': 'Stai richiedendo crediti CFU?',
  'signUp.ectsStudent': 'Studio all\'Università di Helsinki Se spunti questa casella e inserisci il tuo numero di matricola otterrai automaticamente i crediti ECTS.',
  'signUp.ectsStudentSwe': 'After completing the course, you can apply for ECTS credits from Linköping University. You can find the instructions ',
  'signup.ectsStudentSweLink': 'here.',
  'signUp.ectsOpenUni': 'Autorizzo la condivisione dei dati del mio corso su Elementi di AI con l\'Università Roma Tre allo scopo del riconoscimento istituzionale del corso necessario al conseguimento dei CFU.',
  'signUp.studentNumber': 'Numero di matricola dell\'Università di Helsinki (se studi lì)',
  'signUp.privacyOptIn': 'Autorizzo l\'uso dei dati raccolti sul mio comportamento di studio per fini di ricerca. I dati contengono informazioni relative all\'utilizzo del materiale e agli esercizi del corso. Dalle pubblicazioni non è possibile risalire all\'identità dei singoli.',
  'signUp.marketingOptIn': 'Accetto di ricevere aggiornamenti sulle prossime versioni e informazioni riguardo a nuovi corsi. Accetto di condividere le mie informazioni di contatto per ricevere messaggi personalizzati su piattaforme di terze parti. Maggiori informazioni nella nostra informativa sulla privacy.',
  'signUp.privacyLink': 'Leggi la nostra informativa sulla privacy',
  'signUp.error': 'Il tuo modulo contiene errori. Ricontrolla il modulo.',
  'signUp.acceptPolicyFirst': "Accetto l'Informativa sulla privacy e i Termini di servizio.",
  // Sign in page
  'signIn.title': 'Accedi',
  'signIn.intro': 'Usa il modulo qui sotto per accedere con il tuo account mooc.fi',
  'signIn.email': 'La tua e-mail',
  'signIn.password': 'La tua password',
  'signIn.noAccount': 'Non hai un account?',
  'signIn.signUpNow': 'Registrati adesso',
  // Forgot password
  'pwForget.title': 'Password dimenticata',
  'pwForget.description': 'Inserisci l\'e-mail del tuo account mooc.fi e ti invieremo un link per reimpostare la password.',
  'pwForget.sendResetLink': 'Invia link per la reimpostazione',
  'pwForget.completedDescription': 'A breve riceverai un\'e-mail da mooc.fi contenente un link per reimpostare la password. Se non la dovessi trovare, controlla la cartella antispam.',
  'pwForget.problemDescription': 'In caso di problemi, contatta mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent': 'E-mail per reimpostare la password inviata',
  'pwForget.emailPlaceholder': 'Indirizzo e-mail',
  // password change
  'pwChange.authError': 'Autenticazione non riuscita Prova ad accedere di nuovo',
  'pwChange.unexpError': 'Impossibile aggiornare la password a causa di un errore imprevisto',
  'pwChange.required': 'Obbligatorio',
  'pwChange.pwMustMatch': 'Le password devono corrispondere',
  'pwChange.currentPassword': 'Password attuale',
  'pwChange.newPassword': 'Nuova password',
  'pwChange.confirmPassword': 'Conferma nuova password',
  'pwChange.changingPassword': 'Modifica password...',
  'pwChange.changePassword': 'Modifica password',
  'pwChange.passwordChanged': 'Password modificata',
  // user details
  'userDetails.authError': 'Autenticazione non riuscita Prova ad accedere di nuovo',
  'userDetails.unexpError': 'Impossibile aggiornare il profilo a causa di un errore imprevisto',
  'userDetails.required': 'Obbligatorio',
  'userDetails.invalidEmail': 'Indirizzo e-mail non valido',
  'userDetails.email': 'E-mail',
  'userDetails.emailAddress': 'Indirizzo e-mail',
  'userDetails.firstName': 'Nome',
  'userDetails.lastName': 'Cognome',
  'userDetails.language': 'Lingua',
  'userDetails.updatingProfile': 'Aggiornamento profilo...',
  'userDetails.updateProfile': 'Aggiorna profilo',
  'userDetails.profileUpdated': 'Profilo aggiornato',
  // languages
  'languages.en.long': 'Inglese',
  'languages.fi.long': 'Finlandese',
  'languages.se.long': 'Svedese',
  'languages.de.long': 'Tedesco (Germania)',
  'languages.ee.long': 'Estone',
  'languages.fr.long': 'Francese (Francia)',
  'languages.it.long': 'Italiano',
  'languages.nl-be.long': 'Olandese (Belgio)',
  'languages.nl.long': 'Olandese (Paesi Bassi)',
  'languages.fr-be.long': 'Francese (Belgio)',
  'languages.no.long': 'Norvegese',
  'languages.mt.long': 'Maltese',
  'languages.lt.long': 'Lituano',
  'languages.lv.long': 'Lettone',
  'languages.pl.long': 'Polacco',
  'languages.ga.long': 'Irlandese',
  'languages.hr.long': 'Croato',
  'languages.da.long': 'Danese',
  'languages.sk.long': 'Slovacco',
  'languages.en-ie.long': 'Inglese (Irlanda)',
  'languages.ro.long': 'Rumeno',
  'languages.is.long': 'Islandese',
  'languages.de-at.long': 'Tedesco (Austria)',
  'languages.en-lu.long': 'Inglese (Lussemburgo)',
  'languages.bg.long': 'Bulgaro',
  'languages.cs.long': 'Ceca',
  'languages.sl.long': 'Sloveno',
  'languages.pt.long': 'Portoghese',
  'languages.es.long': 'Spagnolo',
  'languages.el.long': 'Greco',
  // Email verification
  'emailVerification.completedMessage': 'Il tuo indirizzo e-mail è stato verificato',
  'emailVerification.verified': 'Verificato',
  // Exercises
  'exercise.loading': 'Caricamento esercizio...',
  'exercise.signUp': 'Registrati per risolvere gli esercizi',
  'exercise.score': 'Il tuo punteggio:',
  'exercise.wrongLocale': 'Locale errato',
  // Part tempplate
  'partTemplate.intro1': 'Nel capitolo ',
  'partTemplate.intro2': ', tratteremo le seguenti sezioni. Clicca qui sotto per iniziare:',
  'partTemplate.spectrumPart1': 'Unisciti alla ',
  'partTemplate.spectrum': 'comunità Elements of AI',
  'partTemplate.spectrumPart2': ' per discutere di questo capitolo e porre domande.',
  // missing info
  'missingInfo.title': 'Informazioni mancanti',
  'missingInfo.body': 'Di solito facciamo queste domande quando gli utenti si registrano, ma tu hai eseguito l\'accesso usando un account mooc.fi esistente. Compila il modulo qui sotto per continuare.',
  'missingInfo.privacyPolicy': 'Leggi la nostra informativa sulla privacy',
  'missingInfo.error': 'Il tuo modulo contiene errori. Ricontrolla il modulo.',
  'missingInfo.privacyPolicyUpdateBody': "Abbiamo aggiornato la nostra politica sulla privacy. Ecco un riepilogo delle modifiche. Pubblicizziamo i nostri corsi a potenziali nuovi studenti formando un pubblico nelle piattaforme di marketing. Ciò potrebbe comportare la condivisione di indirizzi e-mail con queste piattaforme. Allo stesso scopo, i dati delle attività anonimizzati saranno condivisi con le reti di marketing. Puoi impedire la condivisione delle informazioni e-mail deselezionando la casella di controllo del consenso al marketing nel profilo del corso. Per impedire la condivisione dei dati dell'attività, è possibile modificare le impostazioni dei cookie sul sito del corso. L'accettazione di questi termini è necessaria per continuare a utilizzare il servizio.",
  'missingInfo.privacyPolicyUpdateLink': 'Leggi la nostra privacy policy qui.',
  // routes
  'routes.LANDING': '/it/',
  'routes.SIGN_UP': '/it/signup',
  'routes.SIGN_IN': '/it/signin',
  'routes.MISSING_INFO': '/it/missinginfo',
  'routes.PASSWORD_FORGET': '/it/pw-forget',
  'routes.HOME': '/it/home',
  'routes.ACCOUNT': '/it/account',
  'routes.PRIVACY': 'https://www.elementsofai.it/faq/informativa-sulla-privacy',
  'routes.FAQ': 'https://www.elementsofai.it/faq',
  'routes.ABOUT': 'https://www.elementsofai.it',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/it-comunita-italiana',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.it/faq/come-posso-ottenere-i-crediti-cfu',
  'routes.ACCEPT_POLICY': '/it/accept-policy',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Tazze di caffè al giorno',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Linee di codice scritte',
  // meta
  'meta.title': 'Un corso online gratuito',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Scopri di più sul corso sull\'IA dell\'Università di Helsinki e di Reaktor per studenti e professionisti. Non sono necessarie conoscenze di programmazione o matematica.',
  'meta.sitename': 'Elements of AI',
  'meta.title.og': 'A free online introduction to artificial intelligence for non-experts ',
  // ECTS
  'ects.text': 'Per ottenere i crediti CFU è necessario registrarsi con una mail dell\'Università degli Studi Roma Tre. Per sapere come fare, leggi le informazioni sotto il modulo. Si comunica che, ai fini dell\'ottenimento dei 3 CFU, è necessario completare il corso Elements of AI entro e non oltre il 31/12/2021',
  'ects.textCompleted': 'Se hai un numero finlandese di previdenza sociale, puoi richiedere i crediti cliccando',
  'ects.link': 'Come posso ottenere i crediti CFU?',
  'ects.info': 'Stai richiedendo crediti CFU?',
  'ects.readMore': 'Leggi di più.',
  // Countries
  'countryselect.select': "Si prega di selezionare",
  'Sweden': "Svezia",
  'Estonia': "Estonia",
  'Finland': "Finlandia",
  'Germany': "Germania",
  'Italy': "Italia",
  "Countries": {
    'Afganistan': "Afghanistan",
    'Åland Islands': "Isole Aland",
    'Albania': "Albania",
    'Algeria': "Algeria",
    'American Samoa': "Samoa Americane",
    'Andorra': "Andorra",
    'Angola': "Angola",
    'Anguilla': "Anguilla",
    'Antarctica': "Antartide",
    'Antigua and Barbuda': "Antigua e Barbuda",
    'Argentina': "L’Argentina",
    'Armenia': "Armenia",
    'Aruba': "Aruba",
    'Australia': "Australia",
    'Austria': "Austria",
    'Azerbaijan': "Azerbaijan",
    'Bahamas': "Bahamas",
    'Bahrain': "Bahrain",
    'Bangladesh': "Bangladesh",
    'Barbados': "Barbados",
    'Belarus': "Bielorussia",
    'Belgium': "Belgio",
    'Belize': "Belize",
    'Benin': "Benin",
    'Bermuda': "Bermuda",
    'Bhutan': "Bhutan",
    'Bolivia': "Bolivia",
    'Bosnia and Herzegovina': "Bosnia ed Erzegovina",
    'Botswana': "Botswana",
    'Bouvet Island': "Isola Bouvet",
    'Brazil': "Brasile",
    'British Indian Ocean Territory': "Territori Britannici dell'Oceano Indiano",
    'Brunei Darussalam': "Brunei",
    'Bulgaria': "Bulgaria",
    'Burkina Faso': "Burkina Faso",
    'Burundi': "Burundi",
    'Cambodia': "Cambogia",
    'Cameroon': "Camerun",
    'Canada': "Canada",
    'Cape Verde': "Capo Verde",
    'Cayman Islands': "Isole Cayman",
    'Central African Republic': "Repubblica Centrafricana",
    'Chad': "Ciad",
    'Chile': "Cile",
    'China': "Cina",
    'Christmas Island': "Isola di Natale",
    'Cocos (Keeling) Islands': "Isole Cocos",
    'Colombia': "Colombia",
    'Comoros': "Comore",
    'Congo': "Congo",
    'Congo, The Democratic Republic of The': "Repubblica Democratica del Congo",
    'Cook Islands': "	Isole Cook	",
    'Costa Rica': "Costa Rica",
    "Cote D'ivoire": "Costa d'Avorio",
    'Croatia': "Croazia",
    'Cuba': "Cuba",
    'Cyprus': "Cipro",
    'Czech Republic': "	Cechia, Repubblica Ceca",
    'Denmark': "Danimarca",
    'Djibouti': "Gibuti",
    'Dominica': "Dominica",
    'Dominican Republic': "Repubblica Dominicana",
    'Ecuador': "Ecuador",
    'Egypt': "Egitto",
    'El Salvador': "El Salvador",
    'Equatorial Guinea': "Guinea Equatoriale",
    'Eritrea': "Eritrea",
    'Estonia': "Estonia",
    'Ethiopia': "Etiopia",
    'Falkland Islands (Malvinas)': "Isole Falkland",
    'Faroe Islands': "Isole Faroe",
    'Fiji': "Isole Fiji",
    'Finland': "Finlandia",
    'France': "Francia",
    'French Guiana': "Guyana Francese",
    'French Polynesia': "Polinesia Francese",
    'French Southern Territories': "Terre Australi e Antartiche Francesi",
    'Gabon': "Gabon",
    'Gambia': "Gambia",
    'Georgia': "Georgia",
    'Germany': "Germania",
    'Ghana': "Ghana",
    'Gibraltar': "Gibilterra",
    'Greece': "Grecia",
    'Greenland': "Groenlandia",
    'Grenada': "Grenada",
    'Guadeloupe': "Guadalupa",
    'Guam': "Guam",
    'Guatemala': "Guatemala",
    'Guernsey': "Guernsey",
    'Guinea': "Guinea",
    'Guinea-Bissau': "Guinea Bissau",
    'Guyana': "Guyana",
    'Haiti': "Haiti",
    'Heard Island and Mcdonald Islands': "Isole Heard e McDonald",
    'Holy See (Vatican City State)': "Santa Sede, Vaticano (Citta' del)",
    'Honduras': "Honduras",
    'Hong Kong': "Hongkong",
    'Hungary': "Ungheria",
    'Iceland': "Islanda",
    'India': "India",
    'Indonesia': "Indonesia",
    'Iran, Islamic Republic of': "Iran (Persia)",
    'Iraq': "Iraq",
    'Ireland': "Irlanda",
    'Isle of Man': "Isola di Man",
    'Israel': "Israele",
    'Italy': "Italia",
    'Jamaica': "Giamaica",
    'Japan': "Giappone",
    'Jersey': "Jersey",
    'Jordan': "Giordania",
    'Kazakhstan': "Kazakhstan",
    'Kenya': "Kenya",
    'Kiribati': "Kiribati",
    "Korea, Democratic People's Republic of": "Repubblica Popolare Democratica di Corea (Corea del Nord)",
    'Korea, Republic of': "Repubblica di Corea (Corea del Sud)",
    'Kosovo': 'Kosovo',
    'Kuwait': "Kuwait",
    'Kyrgyzstan': "Kirghizistan",
    "Lao People's Democratic Republic": "Laos (Repubblica Popolare Democratica del Laos)",
    'Latvia': "Latvia",
    'Lebanon': "Libano",
    'Lesotho': "Lesotho",
    'Liberia': "Liberia",
    'Libyan Arab Jamahiriya': "Libia",
    'Liechtenstein': "Liechtenstein",
    'Lithuania': "Lituania",
    'Luxembourg': "Lussemburgo",
    'Macao': "Macao",
    'Macedonia, The Former Yugoslav Republic of': "Repubblica di Macedonia",
    'Madagascar': "Madagascar",
    'Malawi': "Malawi",
    'Malaysia': "Malesia",
    'Maldives': "Maldive",
    'Mali': "Mali",
    'Malta': "Malta",
    'Marshall Islands': "Isole Marshallt",
    'Martinique': "Martinica",
    'Mauritania': "Mauritania",
    'Mauritius': "Mauritius",
    'Mayotte': "Mayotte",
    'Mexico': "Messico",
    'Micronesia, Federated States of': "Micronesia (Isole)",
    'Monaco': "Monaco",
    'Mongolia': "Mongolia",
    'Montenegro': "Montenegro",
    'Montserrat': "Montserrat",
    'Marocco': "Marokko",
    'Mozambique': "Mozambico",
    'Myanmar': "Birmania (Myanmar)",
    'Namibia': "Namibia",
    'Nauru': "Nauru",
    'Nepal': "Nepal",
    'Netherlands': "Paesi Bassi (Olanda)",
    'Netherlands Antilles': "Antille Olandesi",
    'New Caledonia': "	Nuova Caledonia",
    'New Zealand': "	Nuova Zelanda",
    'Nicaragua': "Nicaragua",
    'Niger': "Niger",
    'Nigeria': "Nigeria",
    'Niue': "Niue",
    'Norfolk Island': "Isola Norfolk",
    'Northern Mariana Islands': "Isole Marianne Settentrionali",
    'Norway': "Norvegia",
    'Oman': "Oman",
    'Pakistan': "Pakistan",
    'Palau': "Palau",
    'Palestinian Territory, Occupied': "Territori palestinesi",
    'Panama': "Panama",
    'Papua New Guinea': "Papua Nuova Guinea",
    'Paraguay': "Paraguay",
    'Peru': "Peru",
    'Philippines': "Filippine",
    'Pitcairn': "Isole Pitcairn",
    'Poland': "Polonia",
    'Portugal': "Portogallo",
    'Puerto Rico': "Porto Rico",
    'Qatar': "Qatar",
    'Reunion': "Riunione (isola)",
    'Romania': "Romania",
    'Russian Federation': "Russia",
    'Rwanda': "Ruanda",
    'Saint Helena': "Sant'Elena (isola)",
    'Saint Kitts and Nevis': "Saint Kitts e Nevis",
    'Saint Lucia': "Saint Lucia",
    'Saint Pierre and Miquelon': "Saint-Pierre e Miquelon",
    'Saint Vincent and The Grenadines': "Saint Vincent e Grenadine",
    'Samoa': "Samoa (Samoa Occidentali)",
    'San Marino': "San Marino",
    'Sao Tome and Principe': "Sao Tome' e Principe",
    'Saudi Arabia': "	Arabia Saudita",
    'Senegal': "Senegal",
    'Serbia': "Serbia",
    'Seychelles': "Seychelles",
    'Sierra Leone': "Sierra Leone",
    'Singapore': "Singapore",
    'Slovakia': "Slovacchia (Repubblica Slovacca)",
    'Slovenia': "Slovenia",
    'Solomon Islands': "Isole Salomone",
    'Somalia': "Somalia",
    'South Africa': "Sudafrica",
    'South Georgia and The South Sandwich Islands': "Georgia del Sud e Isole Sandwich Australi",
    'Spain': "Spagna",
    'Sri Lanka': "Sri Lanka",
    'Sudan': "Sudan",
    'Suriname': "Suriname",
    'Svalbard and Jan Mayen': "Svalbard e Jan Mayen",
    'Swaziland': "Swaziland",
    'Sweden': "Svezia",
    'Swizerland': "Svizzera",
    'Syrian Arab Republic': "Siria, Repubblica Araba di Siria",
    'Taiwan': "Taiwan, Repubblica di Cina",
    'Tajikistan': "Tagikistan",
    'Tanzania, United Republic of': "Tanzania,",
    'Thailand': "Thailandia",
    'Timor-leste': "Timor Est",
    'Togo': "Togo",
    'Tokelau': "Tokelau",
    'Tonga': "Tonga",
    'Trinidad and Tobago': "Trinidad e Tobago",
    'Tunisia': "Tunisia",
    'Turkey': "Turchia",
    'Turkmenistan': "Turkmenistan",
    'Turks and Caicos Islands': "Isole Turks and Caicos",
    'Tuvalu': "Tuvalu",
    'Uganda': "Uganda",
    'Ukraine': "Ucraina",
    'United Arab Emirates': "Emirati Arabi Uniti",
    'United Kingdom': "Regno Unito",
    'United States': "Stati Uniti d'America",
    'United States Minor Outlying Islands': "Isole minori esterne degli Stati Uniti d'America",
    'Uruguay': "Uruguay",
    'Uzbekistan': "Uzbekistan",
    'Vanuatu': "Vanuatu",
    'Venezuela': "Venezuela",
    'Viet Nam': "Vietnam",
    'Virgin Islands, British': "Isole Vergini Britanniche",
    'Virgin Islands, U.S.': "Isole Vergini Statunitensi",
    'Wallis and Futuna': "Wallis e Futuna",
    'Western Sahara': "Sahara Occidentale",
    'Yemen': "Yemen",
    'Zambia': "Zambia",
    'Zimbabwe': "Zimbabwe",
  },
  'alt.example-1': 'due auto a guida autonoma',
  'alt.example-2': 'mano che tiene un cellulare',
  'alt.example-3': "volto di una donna",
  'alt.defining-ai': "mucchio di parole relative all'IA",
  'alt.turing-test': 'uomo che chatta al computer con un robot e una donna',
  'alt.chinese-room': 'esperimento della stanza cinese',
  'alt.chicken-crossing': 'robot su una barca che osserva un pollo, una volpe e un sacco di mangime per polli',
  'alt.a-to-b': 'mano che tiene un cellulare che sta utilizzando una app di navigazione',
  'alt.tic-tac-toe': 'due bambini giocano a tris',
  'alt.self-driving-car': "donna che cammina di fronte a un'auto a guida autonoma",
  'alt.spam-or-ham': "un'e-mail normale e un messaggio spam",
  'alt.mnist': 'set di dati MNIST',
  'alt.nearest-neighbor': 'due persone fanno shopping',
  'alt.nearest-neighbor-graph': 'il classificatore Nearest Neighbor',
  'alt.recommendation': 'un uomo e una donna ascoltano musica sul cellulare',
  'alt.price-real-estate': 'tre abitazioni diverse',
  'alt.supervised-learning': 'umano che insegna a un robot',
  'alt.dice': 'dice',
  'alt.chess': 'scacchiera',
  'alt.poker': 'mano che tiene carte da gioco',
  'alt.eyes': 'tre persone con occhi castani, una persona con occhi azzurri',
  'alt.search-1': 'percorso unico che porta a un traguardo',
  'alt.search-2': 'quattro percorsi diversi che portano allo stesso traguardo',
  'alt.chicken-crossing-1': 'attraversamento del fiume: stato iniziale',
  'alt.chicken-crossing-2': 'attraversamento del fiume: prima transizione',
  'alt.chicken-crossing-3': 'attraversamento del fiume: tutte le transizioni',
  'alt.chicken-crossing-4': 'attraversamento del fiume: percorso migliore',
  'alt.chicken-crossing-5': 'attraversamento del fiume: stato iniziale',
  'alt.implication-1': 'un uomo e una donna',
  'alt.implication-2': 'occhi ',
  'alt.implication-3': 'telecamera di sorveglianza ',
  'alt.implication-4': 'robot che lavora con un computer portatile ',
  'alt.oddchange': 'sole e nuvole ',
  'alt.brain': 'disegno di un cervello ',
  'alt.carstop': 'auto a guida autonoma ferma a uno stop',
  'alt.gan': 'robot che tiene la foto di un gatto e la foto di un segnale di stop ',
  'alt.hammock': "robot che sventaglia un uomo su un'amaca",
  'alt.game-tree-1': 'albero di gioco 1',
  'alt.game-tree-2': 'albero di gioco 2',
  'alt.game-tree-3': 'albero di gioco 3',
  'alt.game-tree-4': 'albero di gioco 4',
  'alt.bayes-rule-1': 'immagine che illustra falsi positivi e negativi di uno screening del cancro  ',
  'alt.bayes-rule-2': 'immagine che illustra falsi positivi e negativi di uno screening del cancro  ',
  'alt.defineai': "mucchio di parole relative all'IA",
  'alt.winter': 'robot che dorme nella neve',
  'alt.terminator': 'robot spaventoso'
}
