import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { signOut } from "../../services/moocfi";
import { themeGet } from "styled-system";
import { createStore, syncStore } from "../../state/appStore";
import {  clearUpdateInterval } from "../../state/actions";
import _ from "lodash";

const StyledButton = styled.button`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  display: inline-block;
  padding: ${props => (props.inFooter ? "1rem" : "1rem 0")};
  font-size: 0.875rem;
  background: none;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.2s ease;
  margin-right: ${props => (props.inFooter ? "0" : "1rem")};
  text-align: left;
  :hover {
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
`;

const SignOutButton = ({ history, inFooter }) => (
  <StyledButton
    type="button"
    inFooter={inFooter}
    onClick={() => {
      onClick(history);
    }}
  >
    <FormattedMessage id="globals.signOut" />
  </StyledButton>
);

function onClick(history) {

  signOut();

  const { store } = createStore();

  syncStore()
    .then(() => store.dispatch(clearUpdateInterval()))
    .catch(err => {});
}

export default SignOutButton;
