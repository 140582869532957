import styled from "styled-components";
import ExerciseDefaultIcon from "../../images/exercise-icon.svg";
import ExerciseAnsweredIcon from "../../images/exercise-answered2.svg";
import ExerciseRejectedIcon from "../../images/exercise-rejected2.svg";

export default styled.div`
  color: ${({ status }) =>
    status === "answered"
      ? "#4EDEBE"
      : status === "rejected"
      ? "#FFB5C8"
      : "rgba(41, 38, 76, 0.5)"};
  :before {
    content: "";
    display: block;
    background: ${({ status }) =>
      status === "answered"
        ? `url(${ExerciseAnsweredIcon})`
        : status === "rejected"
        ? `url(${ExerciseRejectedIcon})`
        : `url(${ExerciseDefaultIcon})`};
    width: 44px;
    height: 44px;
  }
`;
