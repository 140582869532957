import { receiveQuiz } from "../quiz/actions";
import {
  fetchAnswer as fetchAnswerRequest,
  fetchAnswers as fetchAnswersRequest,
  submitQuiz,
  submitQuizWithValidation,
  submitScore
} from "../../services/quiznator";
import {
  getQuizIdsByTags,
  getStatsByUserByTag,
  getProgress
} from "../../services/quiznator";
import {
  setQuizIdsForTags,
  setCompleted,
  recalculateValidation,
  initStats
} from "../stats/actions";
import {
  initPeerReviews,
  clearReceivedPeerReviewsForId
} from "../peerReview/actions";
import { precise_round } from "../../utilities/mathutil";

import {
  MULTIPLE_CHOICE,
  CHECKBOX,
  PRIVACY_AGREEMENT,
  ESSAY,
  PEER_REVIEW,
  PEER_REVIEWS_RECEIVED,
  OPEN,
  SCALE,
  RADIO_MATRIX,
  MULTIPLE_OPEN
} from "../../constants/quiztypes";
import _ from "lodash";

export const SET_ANSWERS_INIT_STATE = "SET_ANSWERS_INIT_STATE";

export const CHANGE_QUIZ_ANSWER_STATE = "CHANGE_QUIZ_ANSWER_STATE";

export const FETCH_ANSWER = "FETCH_ANSWER";
export const FETCH_ANSWER_REQUEST = "FETCH_ANSWER_REQUEST";
export const FETCH_ANSWER_SUCCESS = "FETCH_ANSWER_SUCCESS";
export const FETCH_ANSWER_FAILURE = "FETCH_ANSWER_FAILURE";

export const FETCH_ANSWERS = "FETCH_ANSWERS";
export const FETCH_ANSWERS_REQUEST = "FETCH_ANSWERS_REQUEST";
export const FETCH_ANSWERS_SUCCESS = "FETCH_ANSWERS_SUCCESS";
export const FETCH_ANSWERS_FAILURE = "FETCH_ANSWERS_FAILURE";

export const FETCH_ANSWERS_WITH_VALIDATION_SUCCESS =
  "FETCH_ANSWERS_WITH_VALIDATION_SUCCESS";
export const FETCH_ANSWERS_WITH_VALIDATION_FAILURE =
  "FETCH_ANSWERS_WITH_VALIDATION_FAILURE";

export const SUBMIT_ANSWER = "SUBMIT_ANSWER";

export const SUBMITTED_ANSWER = "SUBMITTED_ANSWER";

export const SUBMIT_ANSWER_VALIDATE = "SUBMIT_ANSWER_VALIDATE";
export const SUBMIT_ANSWER_VALIDATE_SUCCESS = "SUBMIT_ANSWER_VALIDATE_SUCCESS";
export const SUBMIT_ANSWER_VALIDATE_FAILURE = "SUBMIT_ANSWER_VALIDATE_FAILURE";

export const SUBMITTED_ANSWER_VALIDATE = "SUBMITTED_ANSWER_VALIDATE";

export const SUBMITTING_SCORE = "SUBMITTING_SCORE";
export const SUBMITTED_SCORE = "SUBMITTED_SCORE";

export const SET_ANSWER = "SET_ANSWER";
export const SET_ITEM_ANSWER = "SET_ITEM_ANSWER";
export const SET_TOGGLE_ITEM_ANSWER = "SET_TOGGLE_ITEM_ANSWER";

export const SET_EMPTY_ANSWERS = "SET_EMPTY_ANSWERS";

export const VALIDATE_ANSWER = "VALIDATE_ANSWER";
export const VALIDATED_ANSWER = "VALIDATED_ANSWER";

export const INIT_ANSWERS = "INIT_ANSWERS";

export const UPDATE_ANSWER_STATES = "UPDATE_ANSWER_STATES";

export const UPDATE_ANSWER_STATUSES = "UPDATE_ANSWER_STATUSES";

const RIGHT_ANSWER = "Correct!";
const WRONG_ANSWER = "Wrong.";

export function setAnswersInitState() {
  return {
    type: SET_ANSWERS_INIT_STATE
  };
}

export function changeAnswerState(quizId, data) {
  return {
    type: CHANGE_QUIZ_ANSWER_STATE,
    quizId,
    data
  };
}

export function fetchAnswer(quizId) {
  return {
    type: FETCH_ANSWER,
    quizId
  };
}

export function fetchAnswerSuccess(quizId, data) {
  return {
    type: FETCH_ANSWER_SUCCESS,
    quizId,
    payload: data,
    receivedAt: Date.now()
  };
}

export function fetchAnswers(quizIds) {
  return {
    type: FETCH_ANSWERS,
    quizIds
  };
}

export function fetchAnswersSuccess(quizIds, data) {
  return {
    type: FETCH_ANSWERS_SUCCESS,
    quizIds,
    payload: data,
    receivedAt: Date.now()
  };
}

export function fetchAnswersWithValidationSuccess(data) {
  return {
    type: FETCH_ANSWERS_WITH_VALIDATION_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
}

export function submitAnswer(quizId, data) {
  return {
    type: SUBMIT_ANSWER,
    quizId,
    data
  };
}

export function submittedAnswer(quizId, data) {
  return {
    type: SUBMITTED_ANSWER,
    quizId,
    data
  };
}

export function submitAnswerValidate(quizId, data) {
  return {
    type: SUBMIT_ANSWER_VALIDATE,
    quizId,
    data
  };
}

export function submittedAnswerValidate(quizId, data) {
  return {
    type: SUBMITTED_ANSWER_VALIDATE,
    quizId,
    data
  };
}

export function submittingScore(quizId, data) {
  return {
    type: SUBMITTING_SCORE,
    quizId,
    data
  };
}

export function submittedScore(quizId, data) {
  return {
    type: SUBMITTED_SCORE,
    quizId,
    data
  };
}

export function setAnswer(quizId, data) {
  return {
    type: SET_ANSWER,
    quizId,
    data
  };
}

export function setItemAnswer(quizId, itemId, data) {
  return {
    type: SET_ITEM_ANSWER,
    quizId,
    itemId,
    data
  };
}

export function setToggleItemAnswer(quizId, itemId, data) {
  return {
    type: SET_TOGGLE_ITEM_ANSWER,
    quizId,
    itemId,
    data
  };
}

export function setEmptyAnswers(quizIds) {
  return {
    type: SET_EMPTY_ANSWERS,
    quizIds
  };
}

export function validateAnswer(quizId, data) {
  return {
    type: VALIDATE_ANSWER,
    quizId,
    data
  };
}

export function validatedAnswer(quizId, data) {
  return {
    type: VALIDATED_ANSWER,
    quizId,
    data
  };
}

// this updates from coursestate...
export function updateAnswerStates(data) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_ANSWER_STATES,
      payload: data
    });
  };
}

// ...this from progress
export function updateAnswerStatuses(data) {
  return (dispatch, getState) => {
    const oldAnswers = _.get(getState(), "answers", {});

    let updatedAnswers = [];

    if (data.answered) {
      let answers = data.answered.map(entry =>
        Object.assign({}, entry.answer[0], { validation: entry.validation })
      );

      answers.forEach(answer => {
        const oldAnswer = _.get(oldAnswers, answer.quizId, {});

        if (
          (oldAnswer.answerId === answer._id &&
            !oldAnswer.changed &&
            (oldAnswer.confirmed !== answer.confirmed ||
              oldAnswer.rejected !== answer.rejected)) ||
          !oldAnswer ||
          !_.get(oldAnswer, "data")
        ) {
          updatedAnswers.push(answer);
        }
      });
    }

    if (data.rejected) {
      const quizIds = data.rejected.map(entry => entry.quiz._id);
      let answers = quizIds.map(quizId => {
        return { quizId }; // empty answer - was: array
      });

      answers.forEach(answer => {
        const oldAnswer = _.get(oldAnswers, answer.quizId, {});

        if (!oldAnswer.changed && !!oldAnswer.answerId) {
          updatedAnswers.push(answer);
        }
      });
    }

    // TODO: check if this actually responds to answered status change
    if (updatedAnswers.length > 0) {
      dispatch({
        type: UPDATE_ANSWER_STATUSES,
        payload: updatedAnswers
      });
    }

    // TODO: stuff
    return new Promise(resolve => resolve(data));
  };
}

export function fetchAnswersData(quizIds) {
  return (dispatch, getState) => {
    dispatch(fetchAnswers(quizIds));

    return fetchAnswersRequest(quizIds)
      .then(data => {
        dispatch(fetchAnswersSuccess(quizIds, data));
      })
      .catch(err => {
        dispatch({ type: FETCH_ANSWERS_FAILURE, error: err });
      });
  };
}

export function getStatsByUser(tags) {
  return dispatch => {
    return getStatsByUserByTag(tags).then(data => {
      return data.answered || {};
    });
  };
}

export function initAnswers(data, options = {}) {
  return (dispatch, getState) => {
    if (data.error) {
      dispatch({
        type: FETCH_ANSWERS_WITH_VALIDATION_FAILURE,
        error: data.error
      });
      return new Promise((_, reject) => reject(data.error));
    }

    const oldAnswers = _.get(getState(), "answers", {});

    const { stats } = getState();

    // TODO: how to handle if status is changed
    // from rejected to not rejected, but the new
    // answer is already changed?!

    const filterChanged = answers => {
      const filtered = answers.filter(answer => {
        const oldAnswer = _.get(oldAnswers, answer.quizId);
        if (
          oldAnswer &&
          (!oldAnswer.answerId || oldAnswer.answerId === answer._id) &&
          oldAnswer.changed
        ) {
          return false;
        }

        return true;
      });

      return filtered;
    };

    if (data.answered) {
      let answers = data.answered.map(entry =>
        Object.assign({}, entry.answer[0], {
          validation: {
            ...entry.validation,
            rightAnswer: _.get(entry, "quiz.data.meta.rightAnswer")
          }
        })
      );
      answers = filterChanged(answers);

      const quizIds = answers.map(answer => answer.quizId);
      dispatch(fetchAnswersWithValidationSuccess(answers));
    }

    const emptyAnswers = _.concat(data.notAnswered, data.rejected);

    if (emptyAnswers) {
      const quizIds = emptyAnswers.map(entry => entry.quiz._id);
      let answers = emptyAnswers.map(entry => {
        return {
          quizId: entry.quiz._id,
          validation: entry.validation
        }; // was: in array
      });
      answers = filterChanged(answers);

      if (answers.length > 0) {
        dispatch(fetchAnswersWithValidationSuccess(answers));
      }
    }

    return new Promise((resolve, reject) => resolve(data));
  };
}

export function submitAnswerDataValidation(quizId) {
  return (dispatch, getState) => {
    const { quizzes, answers } = getState();
    const quiz = quizzes[quizId].data;
    const data = answers[quizId].data;

    const confirmed = quiz.type !== ESSAY;

    dispatch({ type: SUBMIT_ANSWER_VALIDATE, quizId });

    return submitQuizWithValidation(quizId, {
      data,
      confirmed,
      rejected: false // let's set rejected as well
    })
      .then(answer => {
        dispatch({
          type: SUBMIT_ANSWER_VALIDATE_SUCCESS,
          quizId,
          data: answer
        });
        //dispatch(validateAnswerData(quizId, answer))
        //dispatch(recalculateValidation(quizId, answer)) // testing updating progress
        //dispatch(setCompleted({ quizId }))
        dispatch(
          submitScoreData(quizId, {
            ..._.omit(answer.validation, ["messages", "rightAnswer"]),
            answerId: answer._id
          })
        );
        dispatch(clearReceivedPeerReviewsForId(quizId));

        return dispatch(initStats({ onlyUpdate: true }));
      })
      .then(progress => {
        return dispatch(updateAnswerStatuses(progress));
      })
      .then(progress => dispatch(initPeerReviews(progress)))
      .catch(err => {
        dispatch({
          type: SUBMIT_ANSWER_VALIDATE_FAILURE,
          quizId,
          error: err.message
        });
        // TODO: notifications or stuff
      });
  };
}

export function submitScoreData(quizId, data) {
  return dispatch => {
    dispatch(submittingScore(quizId, data));

    return submitScore(quizId, data).then(score => {
      dispatch(submittedScore(quizId, data));
    });
  };
}

export function validateAnswerData(quizId, data) {
  return (dispatch, getState) => {
    const { quizzes, answers } = getState();
    const quiz = _.get(quizzes, `[${quizId}].data`) || {};
    //const { quizzes, answers, quizid } = this.props
    //const quiz = _get(quizzes, `[${quizid}].data`)
    //const answerData = _get(answers, `[${quizid}].data`)
    const answerData = _.get(data, "data") || undefined;
    const rightAnswer = _.get(quiz, `data.meta.rightAnswer`);

    if (!quiz || !answerData) {
      return;
    }

    dispatch(validateAnswer(quizId, answerData));

    let messages = {};
    let points = 0;
    let maxPoints = 1;
    let normalizedPoints = 0;

    if (!!rightAnswer) {
      switch (quiz.type) {
        case MULTIPLE_CHOICE:
          messages = validateMultipleChoiceData(quiz, answerData);
          points = 1 * !messages.error;
          normalizedPoints = points;
          break;
        case RADIO_MATRIX:
          messages = validateRadioMatrixData(quiz, answerData);
          points = Object.keys(messages).filter(key => !messages[key].error)
            .length;
          maxPoints = quiz.data.items.length;
          normalizedPoints = points / maxPoints;
          break;
        case OPEN:
          messages = validateOpenData(quiz, answerData);
          points = 1 * !messages.error;
          normalizedPoints = points;
          break;
        case MULTIPLE_OPEN:
          messages = validateMultipleOpenData(quiz, answerData);
          points = Object.keys(messages).filter(key => !messages[key].error)
            .length;
          maxPoints = quiz.data.items.length;
          normalizedPoints = points / maxPoints;
          break;
        case ESSAY:
          messages = {};
          points = data.confirmed ? 1 : 0;
          break;
        default:
      }
    }

    const validationObject = {
      messages: messages,
      points,
      normalizedPoints: precise_round(normalizedPoints, 2),
      maxPoints
    };

    dispatch(validatedAnswer(quizId, validationObject));

    return validationObject;
  };
}

function validateRadioMatrixData(quiz, data) {
  const { items, meta } = quiz.data;
  const rightAnswer = meta.rightAnswer;

  if (!data) {
    return {};
  }

  let messages = {};

  items.forEach(item => {
    let message = "";
    let error = false;
    let correct = false;

    // TODO (?) only accepts 100% correct in multi
    if (meta.multi && !!data[item.id]) {
      const answer =
        typeof data[item.id] === "string" ? [data[item.id]] : data[item.id];

      correct =
        (answer || [])
          .map(k => rightAnswer[item.id].indexOf(k) >= 0)
          .every(v => !!v) &&
        rightAnswer[item.id]
          .map(k => (answer || []).indexOf(k) >= 0)
          .every(v => !!v);
    } else {
      correct = _.includes(rightAnswer[item.id], data[item.id]);
    }

    if (correct) {
      message = _.get(meta, `successes[${item.id}]`) || RIGHT_ANSWER;
    } else {
      message = _.get(meta, `errors[${item.id}]`) || WRONG_ANSWER;
      error = true;
    }
    if (!!message) {
      messages = {
        ...messages,
        [item.id]: {
          message,
          error
        }
      };
    }
  });

  return messages;
}

function validateOpenData(quiz, data) {
  const meta = quiz.data.meta;
  const rightAnswer = meta.rightAnswer;

  if (!data) {
    return {};
  }

  let correct = false;

  if (meta.regex) {
    try {
      let re = new RegExp(rightAnswer);
      correct = !!re.exec(data.trim().toLowerCase());
    } catch (err) {
      // errored, already false
    }
  } else {
    correct = data.trim().toLowerCase() === rightAnswer.trim().toLowerCase();
  }

  if (correct) {
    return {
      message: _.get(meta, `success`) || RIGHT_ANSWER,
      error: false
    };
  } else {
    return {
      message: _.get(meta, `error`) || WRONG_ANSWER,
      error: true
    };
  }
}

function validateMultipleChoiceData(quiz, data) {
  const meta = quiz.data.meta;
  const rightAnswers = meta.rightAnswer;
  const optionId = data;
  const correct = rightAnswers.some(o => o === optionId);

  if (correct) {
    return {
      message: _.get(meta, `successes[${optionId}]`) || RIGHT_ANSWER,
      error: false
    };
  } else {
    return {
      message: _.get(meta, `errors[${optionId}]`) || WRONG_ANSWER,
      error: true
    };
  }
}

function validateMultipleOpenData(quiz, data) {
  const { meta, items } = quiz.data;
  const rightAnswers = meta.rightAnswer;

  let messages = {};

  items.map(item => {
    let message = "";
    let error = false;

    let correct = false;

    if (meta.regex) {
      try {
        let re = new RegExp(rightAnswers[item.id] || "");
        correct = !!re.exec((data[item.id] || "").trim().toLowerCase());
      } catch (err) {
        // errored, already false
      }
    } else {
      correct =
        (rightAnswers[item.id] || "").toLowerCase().trim() ===
        (data[item.id] || "").toLowerCase().trim();
    }

    if (correct) {
      message = _.get(meta, `successes[${item.id}]`) || RIGHT_ANSWER;
    } else {
      message = _.get(meta, `errors[${item.id}]`) || WRONG_ANSWER;
      error = true;
    }

    messages[item.id] = {
      message,
      error
    };
  });

  return messages;
}
