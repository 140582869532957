// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translation is already done before adding a new one.
// *****

export default {
    // Globals
    'globals.locale': 'ro',
    'globals.signIn': 'Intrați în cont',
    'globals.logIn': 'Conectați-vă',
    'globals.signUp': 'Înscrieți-vă',
    'globals.signOut': 'Ieșiți din cont',
    'globals.logOut': 'Deconectați-vă',
    'globals.submit': 'Trimiteți',
    'globals.submitting': 'Se trimite...',
    'globals.yourEmail': 'Adresa dumneavoastră de e-mail',
    'globals.yourPassword': 'Parola dumneavoastră',
    'globals.courseOverview': 'Sinteza cursului',
    'globals.forgotPassword': 'Ați uitat parola?',
    'globals.invalidCredentials': 'Acreditări incorecte',
    'globals.section': 'Secțiune',
    'globals.exercises': 'Exerciții',
    'globals.chapter': 'Capitol',
    'globals.introToAI': 'Introducere în inteligența artificială',
    'globals.buildingAI': 'Construirea inteligenței artificiale',
    'globals.acceptPolicy': 'Acceptați politica',
    // section
    'section.footerEndOfTheChapter': 'Ați ajuns la sfârșitul capitolului ',
    'section.footerEndOfTheCourseTitle': 'Ați ajuns la sfârșitul cursului!',
    'section.footerEndOfTheCourseText': 'După ce ați finalizat toate exercițiile, personalul nostru va evalua răspunsurile dumneavoastră.',
    'section.nextSection': 'Secțiunea următoare',
    'section.nextChapter': 'Capitolul următor',
    'section.start': 'Începeți',
    'section.logisticRegressionExample.slope': 'Panta',
    'section.logisticRegressionExample.intercept': 'Punctul de intercepție',
    'section.footerEndOfTheCourseBAI': 'Continuați-vă călătoria spre a vă putea pune în aplicare prima idee de AI.',
    // quiz peer review
    'quiz.awaitingPeerReview': 'Este așteptată evaluarea dumneavoastră',
    'quiz.rejectedPeerReview': 'Respins în urma evaluării colegiale',
    'quiz.unsubmittedAnswer': 'Răspuns netransmis',
    'quiz.pendingPeerReview': 'Evaluare colegială în curs',
    // quiz
    'quiz.status.answered': 'Răspuns trimis',
    'quiz.status.rejected': 'Răspuns respins, încercați din nou',
    'quiz.status.unanswered': 'Nu ați răspuns',
    'quiz.status.exercisesCompleted': 'Exerciții efectuate',
    'quiz.status.correctAnswers': 'Răspunsuri corecte',
    'quiz.message.rejectedInReview': 'Răspunsul dumneavoastră anterior a fost respins în urma evaluării. Vă rugăm să încercați din nou.',
    'quiz.youAnswered': 'Ați răspuns',
    'quiz.yourAnswer': 'Răspunsul dumneavoastră...',
    'quiz.yourScore': 'Scorul dumneavoastră:',
    'quiz.quizNotLoaded': 'Chestionarul nu a putut fi încărcat',
    'quiz.words': 'Cuvinte:',
    'quiz.exampleAnswer': 'Exemplu de răspuns:',
    // peer review
    'peerreview.question1': 'Răspunsul este la subiect',
    'peerreview.question2': 'Răspunsul este cuprinzător',
    'peerreview.question3': 'Răspunsul este bine motivat',
    'peerreview.question4': 'Răspunsul este inteligibil',
    'peerreview.thankYou': 'Vă mulțumim că i-ați evaluat pe colegii dumneavoastră!',
    'peerreview.giveMoreReviews': 'Dacă aveți timp,',
    'peerreview.giveMoreReviewsButton': 'vă rugăm să faceți mai multe evaluări',
    'peerreview.notEnoughAnswers': 'În mod normal, aici ar trebui să fie o evaluare colegială, dar nu au fost îndeajuns de multe persoane care au făcut acest exercițiu.',
    'peerreview.selectAnswerMessage': 'Este timpul pentru o evaluare colegială! Mai jos aveți câteva răspunsuri ale altor cursanți. Selectați unul dintre acestea și evaluați-l. Repetați operațiunea de cel puțin trei ori.',
    'peerreview.minimumPeerReviewWarning': 'Exercițiile sunt acceptate numai după ce ați făcut cel puțin trei evaluări colegiale.',
    'peerreview.select': 'Selectați',
    'peerreview.reportSpam': 'Raportați mesajele spam',
    'peerreview.feedback': 'Răspunsul dumneavoastră a fost evaluat și acceptat. Iată părerile colegilor dumneavoastră pe această temă:',
    // exercise17
    'quiz.exercise17.xLabel': 'Ani de școală',
    'quiz.exercise17.yLabel': 'Speranța de viață în ani',
    'quiz.exercise17.textPart1': 'Să analizăm legătura dintre numărul total de ani de școală (de la clasele pregătitoare la facultate) și speranța de viață. Iată datele din trei țări diferite prezentate într-un grafic reprezentat prin puncte:',
    'quiz.exercise17.textPart2': 'Avem o țară în care numărul mediu de ani de școală este de 10 ani, iar speranța de viață este de 57 de ani, o altă țară în care numărul mediu de ani de școală este de 13 ani, iar speranța de viață este de 53 de ani, și o a treia țară în care numărul mediu de ani de școală este de 20 de ani, iar speranța de viață este de 80 de ani.',
    'quiz.exercise17.textPart3': 'Puteți trage de capetele segmentului de dreaptă pentru a poziționa dreapta astfel încât să reflecte tendința indicată de punctele de date. Rețineți că nu veți putea să potriviți perfect dreapta peste punctele de date; nu-i nimic, unele puncte de date vor fi deasupra dreptei, altele dedesubt. Cel mai important este ca dreapta să descrie tendința generală.',
    'quiz.exercise17.textPart4': 'După ce ați poziționat dreapta, o puteți folosi pentru a prezice speranța de viață.',
    'quiz.exercise17.textPart5': 'Din datele existente, ce puteți spune despre speranța de viață a persoanelor care au 15 ani de școală? Important: Observați că, deși puteți obține o anumită predicție, ajustând poziția liniei până la o fracțiune de un an, este posibil ca predicția pe care o faceți să nu fie neapărat fiabilă. Țineți cont de cantitatea limitată de date atunci când răspundeți.',
    // exercise18
    'quiz.exercise18.xLabel': 'Ani de școală',
    'quiz.exercise18.yLabel': 'Speranța de viață în ani',
    'quiz.exercise18.textPart1': 'În exercițiul precedent, aveam date din doar trei țări. Setul complet de date include date din 14 țări, prezentate în graficul următor:',
    'quiz.exercise18.textPart2': 'Pe baza acestor date, s-ar schimba predicția dumneavoastră privind speranța de viață a persoanelor cu 15 ani de școală? Dacă da, de ce?',
    'quiz.exercise18.textPart3': 'Care dintre următoarele răspunsuri ar corespunde cel mai bine estimării dumneavoastră privind speranța de viață pentru persoanele cu 15 ani de școală? Alegeți răspunsul cel mai precis pe care îl considerați justificat aplicând modelul de regresie liniară la datele de mai sus.',
    // exercise19
    'quiz.exercise19.xLabel': 'Ore de studiu',
    'quiz.exercise19.yLabel': 'Probabilitatea de a trece examenul',
    'quiz.exercise19.textPart1': 'Fiecare punct din grafic corespunde unui student. Pe axa orizontală a graficului avem numărul de ore de studiu pe care studenții le-au dedicat examenului. Studenții care au trecut examenul sunt reprezentați sub formă de puncte în partea de sus a graficului, iar cei care l-au picat sunt reprezentați în partea de jos. Vom utiliza axa verticală pentru a indica probabilitatea de a trece examenul, pe care o vom afla aplicând modelul de regresie logistică explicat în continuare.',
    'quiz.exercise19.textPart2': 'Pe baza acestui grafic, puteți constata la prima vedere că studenții care au dedicat mai multe ore studiului au avut șanse mai mari de a trece examenul. În special cazurile de la extreme sunt intuitive: studenților care studiază mai puțin de o oră le este foarte greu să treacă examenul, în timp ce, dacă muncesc mult, cei mai mulți studenți îl vor trece cu ușurință. Dar ce se întâmplă cu cei care se încadrează în intervalul dintre aceste extreme? Dacă studiați 6 ore, ce șanse aveți să treceți examenul?',
    'quiz.exercise19.textPart3': 'Putem cuantifica probabilitatea de a trece examenul folosind regresia logistică. Curba din grafic poate fi interpretată ca probabilitatea de a trece examenul: de exemplu, după ce ați studiat cinci ore, probabilitatea de a trece examenul este puțin mai mare de 20 %. Nu vom intra în detalii cu privire la cum se obține curba, dar procesul este similar modului în care învățăm ponderile în regresia liniară.',
    'quiz.exercise19.textPart4': 'Dacă doriți să aveți o șansă de 80 % de a trece un examen la facultate, pe baza datelor din graficul de mai sus, cam câte ore ar trebui să studiați?',
    // exercise22
    'quiz.exercise22.sigmoidXLabel': 'Intrare',
    'quiz.exercise22.sigmoidYLabel': 'Ieșire funcția sigmoidă',
    'quiz.exercise22.stepXLabel': 'Intrare',
    'quiz.exercise22.stepYLabel': 'Ieșire funcția prag',
    'quiz.exercise22.identityXLabel': 'Intrare',
    'quiz.exercise22.identityYLabel': 'Ieșire funcția identitate',
    'quiz.exercise22.textPart1': 'Mai jos sunt grafice pentru trei funcții diferite de activare cu proprietăți diferite. Avem mai întâi funcția sigmoidă, apoi funcția prag și, în cele din urmă, funcția identitate.',
    // exercise23
    'quiz.exercise23.happyFaces': 'fețe vesele clasificate corect',
    'quiz.exercise23.sadFaces': 'fețe triste clasificate corect',
    // certificate availability
    'certificate.failedToCheck': 'Nu s-a reușit verificarea disponibilității certificatului',
    'certificate.failedToGenerate': 'Nu s-a putut genera certificatul',
    'certificate.notYetAvailable': 'Certificatul dumneavoastră va fi disponibil aici după ce veți primi o confirmare oficială din partea noastră. Procesul poate dura un timp. Vă mulțumim pentru răbdare!',
    'certificate.updateName': 'Certificatul dumneavoastră este disponibil! Vă rugăm să vă actualizați prenumele și numele în profilul dumneavoastră, utilizând formularul de mai jos, astfel încât să îl putem utiliza la generarea certificatului.',
    'certificate.generate': 'Generați-vă certificatul mai jos.',
    'certificate.generateTextPart1': 'Certificatul va fi generat pentru',
    'certificate.generateTextPart2': 'Asigurați-vă că numele este corect înainte de a genera certificatul, deoarece acesta nu poate fi modificat ulterior. Dacă doriți să modificați numele, puteți face acest lucru în formularul de mai jos.',
    'certificate.generateButton': 'Generați-vă certificatul',
    'certificate.downloadButton': 'Descărcați certificatul',
    'certificate.checkingAvailability': 'Verificarea disponibilității certificatului...',
    // Visualizations
    'visualizations.logisticRegressionChart.classifiedCorrectly': 'Clasificat corect',
    'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Clasificat incorect',
    // section blocks
    'section.block.note': 'Notă',
    'section.block.example': 'Exemplu',
    'section.block.keyTerminology': 'Termeni de reținut',
    'section.partSummary.spectrumTextPart1': 'Vă rugăm să aderați ',
    'section.partSummary.spectrum': 'la comunitatea „Elements of AI”',
    'section.partSummary.spectrumTextPart2': ' pentru a discuta și a pune întrebări referitoare la acest capitol.',
    'section.partSummary.feedbackTitle': 'Trimiteți-ne comentariile dumneavoastră cu privire la această parte',
    'section.partSummary.feedbackPlaceholder': 'Consemnați aici comentariile dumneavoastră...',
    'section.partSummary.feedbackSuccess': 'Vă mulțumim pentru comentarii!',
    // 404
    'globals.404.title': '404 — Pagina nu a fost găsită',
    'globals.404.intro': "Acest parcurs nu există... ne pare rău!",
    'globals.loading': 'Se încarcă...',
    // footer / menu
    'globals.menu': 'Meniu',
    'globals.menu.about': 'Despre',
    'globals.menu.faq': 'Întrebări frecvente',
    'globals.menu.privacyPolicy': 'Politica de confidențialitate',
    'globals.menu.myProfile': 'Profilul meu',
    'globals.menu.termsofService':'Termenii serviciului',
    // Dashboard/Index
    'dashboard.title': 'Bun venit la curs!',
    'dashboard.congratulations.title': 'Felicitări!',
    'dashboard.congratulations.message': 'Ați terminat cursul „Elements of AI”',
    'dashboard.congratulations.downloadCertificate': 'Descărcați certificatul',
    'dashboard.notLoggedIn.title': 'Bun venit!',
    'dashboard.notLoggedIn.message': 'Înscrieți-vă sau conectați-vă pentru a avea un acces complet la curs. Este gratis!',
    // Account
    'account.certificate': 'Certificat',
    'account.myProfile': 'Profilul meu',
    'account.credits': 'Credite ECTS',
    'account.changePassword': 'Modificați parola',
    'account.changeLanguage': 'Modificați limba',
    'account.profileInformation': 'Informații despre profil',
    'account.goToCourse': 'Mergi la curs',
    'account.buildingAINew':'Uusi englanninkielinen kurssi',
    'account.accept': 'Acceptă',
    'account.decline': 'Declin',
    'account.courses': 'Cursuri',
      // Sign Up page
    'signUp.description': 'Completați formularul de mai jos pentru a crea un nou cont pe mooc.fi',
    'signUp.email': 'Adresa dumneavoastră de e-mail',
    'signUp.firstName': 'Prenumele dumneavoastră',
    'signUp.lastName': 'Numele dumneavoastră de familie',
    'signUp.postalCode': 'Cod postal',
    'signUp.country': 'În ce țară locuiți?',
    'signUp.Password': 'Parola',
    'signUp.confirmPassword': 'Confirmați parola',
    'signUp.howToStudyTitle': 'Cum doriți să studiați?',
    'signUp.6weeksTitle': 'Curs de 6 săptămâni',
    'signUp.6weeksSubTitle': 'Recomandat (aproximativ 5 ore pe săptămână)',
    'signUp.6weeksText': 'A avea un termen-limită este o practică ce s-a dovedit eficace în a vă ajuta să finalizați cursul.',
    'signUp.selfPacedTitle': 'În ritmul propriu',
    'signUp.selfPacedText': 'Puteți urma cursul în ritmul propriu.',
    'signUp.ectsTitle': 'Doriți să obțineți credite ECTS?',
    'signUp.ectsTitleSwe': ' ',
    'signUp.ectsStudent': 'Sunt student la Universitatea din Helsinki. Dacă bifați această căsuță și completați numărul dumneavoastră matricol, veți primi automat creditele ECTS.',
    'signUp.ectsStudentSwe': ' ',
    'signup.ectsStudentSweLink': ' ',
    'signUp.ectsOpenUni': 'Bifați această căsuță pentru a primi un e-mail cu instrucțiuni privind modul în care puteți solicita credite ECTS',
    'signUp.studentNumber': 'Numărul matricol atribuit de Universitatea din Helsinki (dacă sunteți student)',
    'signUp.privacyOptIn': 'Sunt de acord ca datele colectate cu privire la comportamentul meu de studiu să fie utilizate în scopuri de cercetare. Datele conțin informații legate de utilizarea materialelor de curs și de efectuarea exercițiilor propuse. Nu se poate deduce identitatea unei persoane pe baza materialelor publicate.',
    'signUp.marketingOptIn': 'Sunt de acord să primesc notificări privind noile versiuni lingvistice disponibile și privind organizarea de noi cursuri. Sunt de acord să împărtășesc informațiile mele de contact pentru a primi mesaje personalizate pe platforme terțe. Citiți mai multe în politica noastră de confidențialitate.',
    'signUp.privacyLink': 'Citiți politica noastră de confidențialitate',
    'signUp.error': 'Există erori în formularul completat de dumneavoastră. Vă rugăm să vă verificați formularul.',
    'signUp.acceptPolicyFirst':'Accept Politica de confidențialitate și Termenii și condițiile.',
    // Sign in page
    'signIn.title': 'Intrați în cont',
    'signIn.intro': 'Utilizați formularul de mai jos pentru a intra în contul dumneavoastră mooc.fi',
    'signIn.email': 'Adresa dumneavoastră de e-mail',
    'signIn.password': 'Parola dumneavoastră',
    'signIn.noAccount': "Nu aveți cont?",
    'signIn.signUpNow': 'Înscrieți-vă acum',
    // Forgot password
    'pwForget.title': 'Ați uitat parola',
    'pwForget.description': 'Completați e-mailul contului mooc.fi și vă vom trimite un link pentru reinițializarea parolei',
    'pwForget.sendResetLink': 'Link zum Zurücksetzen zusenden',
    'pwForget.completedDescription': 'Du solltest in Kürze von mooc.fi eine E-Mail mit einem Link erhalten, über den du dein Passwort zurücksetzen kannst. Wenn diese Nachricht nicht bei dir ankommt, sieh bitte in deinem Spam-Ordner nach.',
    'pwForget.problemDescription': 'Bei Problemen kannst du dich an mooc@cs.helsinki.fi wenden.',
    'pwForget.resetEmailSent': 'E-Mail zum Zurücksetzen des Passworts gesendet',
    'pwForget.emailPlaceholder': 'E-Mail-Adresse',
    // password change
    'pwChange.authError': 'Authentifizierung fehlgeschlagen. Versuche bitte noch einmal, dich anzumelden.',
    'pwChange.unexpError': 'Passwortänderung aufgrund eines unerwarteten Fehlers fehlgeschlagen',
    'pwChange.required': 'Obligatoriu',
    'pwChange.pwMustMatch': 'Parolele trebuie să fie identice',
    'pwChange.currentPassword': 'Parola actuală',
    'pwChange.newPassword': 'Parola nouă',
    'pwChange.confirmPassword': 'Confirmați noua parolă',
    'pwChange.changingPassword': 'Parola se modifică...',
    'pwChange.changePassword': 'Modificați parola',
    'pwChange.passwordChanged': 'Parola a fost modificată',
    // user details
    'userDetails.authError': 'Autentificarea a eșuat. Vă rugăm să încercați să intrați din nou în cont',
    'userDetails.unexpError': 'Profilul nu a putut fi actualizat din cauza unei erori neprevăzute',
    'userDetails.required': 'Obligatoriu',
    'userDetails.invalidEmail': 'Adresă de e-mail nevalabilă',
    'userDetails.email': 'E-mail',
    'userDetails.emailAddress': 'Adresa de e-mail',
    'userDetails.firstName': 'Prenume',
    'userDetails.lastName': 'Numele de familie',
    'userDetails.language': 'Limba',
    'userDetails.updatingProfile': 'Profilul se actualizează...',
    'userDetails.updateProfile': 'Actualizați-vă profilul',
    'userDetails.profileUpdated': 'Profil actualizat',
    // languages
    'languages.en.long': 'Engleză',
    'languages.fi.long': 'Finlandeză',
    'languages.se.long': 'Suedeză',
    'languages.de.long': 'Germană (Germania)',
    'languages.ee.long': 'Estonă',
    'languages.it.long': 'Italiană',
    'languages.no.long': 'Norvegiană',
    'languages.lv.long': 'Letonă',
    'languages.fr.long': 'Franceză (Franța)',
    'languages.pl.long': 'Poloneză',
    'languages.en-ie.long': 'Engleză (Irlanda)',
    'languages.mt.long': 'Malteză',
    'languages.fr-be.long': 'Franceză (Belgia)',
    'languages.lt.long': 'Lituaniană',
    'languages.nl-be.long': 'Neerlandeză (Belgia)',
    'languages.ro.long': 'Română',
    'languages.hr.long': 'Croată',
    'languages.da.long': 'Daneză',
    'languages.ga.long': 'Irlandeză',
    'languages.is.long': 'Islandeză',
    'languages.de-at.long': 'Germană (Austria)',
    'languages.sk.long': 'Slovacă',
    'languages.en-lu.long': 'Engleză (Luxemburg)',
    'languages.bg.long': 'Bulgară',
    'languages.cs.long': 'Cehă',
    'languages.sl.long': 'Slovenă',
    'languages.pt.long': 'Portugheză',
    'languages.nl.long': 'Neerlandeză (Țările de Jos)',
    'languages.es.long': 'Spaniolă',
    'languages.el.long': 'Greacă',
    // Email verfification
    'emailVerification.completedMessage': 'Adresa dumneavoastră de e-mail a fost verificată',
    'emailVerification.verified': 'Verificată',
    // Exercises
    'exercise.loading': 'Exercițiul se încarcă...',
    'exercise.signUp': 'Înscrieți-vă pentru a rezolva exercițiile',
    'exercise.score': 'Scorul dumneavoastră:',
    'exercise.wrongLocale': 'Cod greșit',
    // Part tempplate
    'partTemplate.intro1': 'În capitolul ',
    'partTemplate.intro2': ', vom acoperi următoarele secțiuni. Faceți clic mai jos pentru a începe:',
    'partTemplate.spectrumPart1': 'Vă rugăm să aderați ',
    'partTemplate.spectrum': 'la comunitatea „Elements of AI”',
    'partTemplate.spectrumPart2': ' pentru a discuta și a pune întrebări referitoare la acest capitol.',
    // missing info
    'missingInfo.title': 'Informații lipsă',
    'missingInfo.body': 'De regulă, punem aceste întrebări în momentul în care utilizatorii se înscriu, dar dumneavoastră v-ați conectat cu un cont mooc.fi existent. Vă rugăm să completați formularul de mai jos pentru a continua.',
    'missingInfo.privacyPolicy': 'Citiți politica noastră de confidențialitate',
    'missingInfo.error': 'Există erori în formularul completat de dumneavoastră. Vă rugăm să vă verificați formularul.',
    'missingInfo.privacyPolicyUpdateBody':"Ne-am actualizat politica de confidențialitate. Iată un rezumat al modificărilor. Recomandăm cursurile noastre potențialilor noi studenți, formând audiențe pe platformele de marketing. Aceasta poate implica schimbul de adrese de e-mail cu aceste platforme. În același scop, datele de activitate anonimizate vor fi partajate cu rețelele de marketing. Puteți împiedica partajarea informațiilor de e-mail prin debifarea casetei de consimțământ de marketing din profilul cursului. Pentru a împiedica partajarea datelor de activitate, puteți modifica setările cookie-urilor de pe site-ul cursului. Acceptarea acestor termeni este necesară pentru continuarea utilizării serviciului.",
    'missingInfo.privacyPolicyUpdateLink':'Puteți consulta politica de confidențialitate aici.',
      // routes
    'routes.LANDING': '/ro/',
    'routes.SIGN_UP': '/ro/signup',
    'routes.SIGN_IN': '/ro/signin',
    'routes.MISSING_INFO': '/ro/missinginfo',
    'routes.PASSWORD_FORGET': '/ro/pw-forget',
    'routes.HOME': '/ro/home',
    'routes.ACCOUNT': '/ro/account',
    'routes.PRIVACY': 'https://www.elementsofai.ro/faq/politica-de-confiden%C8%9Bialitate',
    'routes.FAQ': 'https://www.elementsofai.ro/faq',
    'routes.ABOUT': 'https://www.elementsofai.ro',
    'routes.SPECTRUM': 'https://community.elementsofai.com/c/ro-comunitate-romaneasca',
    'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
    'routes.TERMS':'https://www.elementsofai.com/terms-of-service',
    'routes.BUILDING_AI':'https://buildingai.elementsofai.com',
    'routes.ects.faq':'https://www.elementsofai.com/faq/how-can-i-get-ects-credits',
    'routes.ACCEPT_POLICY': '/ro/accept-policy',
    // MarkdownComponents
    'markdowncomponents.linearRegressionStatic.xLabel': 'Cești de cafea pe zi',
    'markdowncomponents.linearRegressionStatic.yLabel': 'linii de cod scrise',
    // meta
    'meta.title': 'Un curs online gratuit',
    'meta.titleTemplate': '%s - Elements of AI',
    'meta.description': 'Aflați mai multe despre cursul în domeniul IA organizat de Universitatea din Helsinki și Reaktor pentru studenți și profesioniști — nu sunt necesare cunoștințe de programare sau de matematică.',
    'meta.sitename': 'Elements of AI',
    // ECTS
    'ects.text': 'După finalizarea cursului, veți primi instrucțiuni cu privire la modul în care puteți solicita aici credite ECTS.',
    'ects.textCompleted': 'Dacă aveți un număr de securitate socială finlandez, puteți solicita credite făcând clic ',
    'ects.link': 'aici.',
    'ects.info': 'Doriți să solicitați credite ECTS?',
    'ects.readMore': 'Citeste mai mult',
      // Countries
    'countryselect.select': "Te rog selecteaza",
    'Finland': "Finlanda",
    'Estonia': "Estonia",
    'Germany': "Germania",
    'Sweden': "Suedia",
    "Countries": {
        "Afganistan": "Afganistan",
        "Åland Islands": "Insulele Aland",
        "Albania": "Albania",
        "Algeria": "Algeria",
        "American Samoa": "Samoa Americană",
        "Andorra": "Andorra",
        "Angola": "Angola",
        "Anguilla": "Anguilla",
        "Antarctica": "Antarctica",
        "Antigua and Barbuda": "Antigua si Barbuda",
        "Argentina": "Argentina",
        "Armenia": "Armenia",
        "Aruba": "Aruba",
        "Australia": "Australia",
        "Austria": "Austria",
        "Azerbaijan": "Azerbaidjan",
        "Bahamas": "Bahamas",
        "Bahrain": "Bahrain",
        "Bangladesh": "Bangladesh",
        "Barbados": "Barbados",
        "Belarus": "Bielorusia",
        "Belgium": "Belgia",
        "Belize": "Belize",
        "Benin": "Benin",
        "Bermuda": "Bermuda",
        "Bhutan": "Bhutan",
        "Bolivia": "Bolivia",
        "Bosnia and Herzegovina": "Bosnia si Hertegovina",
        "Botswana": "Botswana",
        "Bouvet Island": "Insula Bouvet",
        "Brazil": "Brazilia",
        "British Indian Ocean Territory": "Teritoriul Britanic al Oceanului Indian",
        "Brunei Darussalam": "Brunei Darussalam",
        "Bulgaria": "Bulgaria",
        "Burkina Faso": "Burkina Faso",
        "Burundi": "Burundi",
        "Cambodia": "Cambogia",
        "Cameroon": "Camerun",
        "Canada": "Canada",
        "Cape Verde": "capul Verde",
        "Cayman Islands": "Insulele Cayman",
        "Central African Republic": "Republica Centrafricană",
        "Chad": "Ciad",
        "Chile": "Chile",
        "China": "China",
        "Christmas Island": "Insula Craciunului",
        "Cocos (Keeling) Islands": "Insulele Cocos (Keeling)",
        "Colombia": "Columbia",
        "Comoros": "Comore",
        "Congo": "Congo",
        "Congo, The Democratic Republic of The": "Congo, Republica Democrată din",
        "Cook Islands": "Insulele Cook",
        "Costa Rica": "Costa Rica",
        "Cote D'ivoire": "Cote D'ivoire",
        "Croatia": "Croaţia",
        "Cuba": "Cuba",
        "Cyprus": "Cipru",
        "Czech Republic": "Republica Cehă",
        "Denmark": "Danemarca",
        "Djibouti": "Djibouti",
        "Dominica": "Dominica",
        "Dominican Republic": "Republica Dominicana",
        "Ecuador": "Ecuador",
        "Egypt": "Egipt",
        "El Salvador": "El Salvador",
        "Equatorial Guinea": "Guineea Ecuatorială",
        "Eritrea": "Eritreea",
        "Estonia": "Estonia",
        "Ethiopia": "Etiopia",
        "Falkland Islands (Malvinas)": "Insulele Falkland (Malvinas)",
        "Faroe Islands": "Insulele Feroe",
        "Fiji": "Fiji",
        "Finland": "Finlanda",
        "France": "Franţa",
        "French Guiana": "Guyana Franceză",
        "French Polynesia": "Polinezia Franceză",
        "French Southern Territories": "teritoriile din sudul Frantei",
        "Gabon": "Gabon",
        "Gambia": "Gambia",
        "Georgia": "Georgia",
        "Germany": "Germania",
        "Ghana": "Ghana",
        "Gibraltar": "Gibraltar",
        "Greece": "Grecia",
        "Greenland": "Groenlanda",
        "Grenada": "Grenada",
        "Guadeloupe": "Guadelupa",
        "Guam": "Guam",
        "Guatemala": "Guatemala",
        "Guernsey": "jachetă",
        "Guinea": "Guineea",
        "Guinea-Bissau": "Guineea-Bissau",
        "Guyana": "Guyana",
        "Haiti": "Haiti",
        "Heard Island and Mcdonald Islands": "Insulele Heard și Insulele Mcdonald",
        "Holy See (Vatican City State)": "Sfântul Scaun (statul Vatican)",
        "Honduras": "Honduras",
        "Hong Kong": "Hong Kong",
        "Hungary": "Ungaria",
        "Iceland": "Islanda",
        "India": "India",
        "Indonesia": "Indonezia",
        "Iran, Islamic Republic of": "Iran (Republica Islamica a",
        "Iraq": "Irak",
        "Ireland": "Irlanda",
        "Isle of Man": "Insula Man",
        "Israel": "Israel",
        "Italy": "Italia",
        "Jamaica": "Jamaica",
        "Japan": "Japonia",
        "Jersey": "Jersey",
        "Jordan": "Iordania",
        "Kazakhstan": "Kazahstan",
        "Kenya": "Kenia",
        "Kiribati": "Kiribati",
        "Korea, Democratic People's Republic of": "Coreea, Republica Populară Democrată din Coreea",
        "Korea, Republic of": "Republica Coreea",
        "Kosovo": "Kosovo",
        "Kuwait": "Kuweit",
        "Kyrgyzstan": "Kârgâzstan",
        "Lao People's Democratic Republic": "Republica Populară Democrată Laos",
        "Latvia": "Letonia",
        "Lebanon": "Liban",
        "Lesotho": "Lesotho",
        "Liberia": "Liberia",
        "Libyan Arab Jamahiriya": "Jamahiriya arabă libiană",
        "Liechtenstein": "Liechtenstein",
        "Lithuania": "Lituania",
        "Luxembourg": "Luxemburg",
        "Macao": "Macao",
        "Macedonia, The Former Yugoslav Republic of": "Macedonia, Fosta Republică Iugoslavă a Moldovei",
        "Madagascar": "Madagascar",
        "Malawi": "Malawi",
        "Malaysia": "Malaezia",
        "Maldives": "Maldive",
        "Mali": "Mali",
        "Malta": "Malta",
        "Marshall Islands": "Insulele Marshall",
        "Martinique": "Martinica",
        "Mauritania": "Mauritania",
        "Mauritius": "Mauritius",
        "Mayotte": "Mayotte",
        "Mexico": "Mexic",
        "Micronesia, Federated States of": "Micronezia, state federate din",
        "Moldova, Republic of": "Republica Moldova",
        "Monaco": "Monaco",
        "Mongolia": "Mongolia",
        "Montenegro": "Muntenegru",
        "Montserrat": "Montserrat",
        "Marocco": "Marocco",
        "Mozambique": "Mozambic",
        "Myanmar": "Myanmar",
        "Namibia": "Namibia",
        "Nauru": "Nauru",
        "Nepal": "Nepal",
        "Netherlands": "Olanda",
        "Netherlands Antilles": "Antilele Olandeze",
        "New Caledonia": "Noua Caledonie",
        "New Zealand": "Noua Zeelanda",
        "Nicaragua": "Nicaragua",
        "Niger": "Niger",
        "Nigeria": "Nigeria",
        "Niue": "Niue",
        "Norfolk Island": "Insula Norfolk",
        "Northern Mariana Islands": "Insulele Mariane de Nord",
        "Norway": "Norvegia",
        "Oman": "Oman",
        "Pakistan": "Pakistan",
        "Palau": "Palau",
        "Palestinian Territory, Occupied": "Teritoriul palestinian, ocupat",
        "Panama": "Panama",
        "Papua New Guinea": "Papua Noua Guinee",
        "Paraguay": "Paraguay",
        "Peru": "Peru",
        "Philippines": "Filipine",
        "Pitcairn": "Pitcairn",
        "Poland": "Polonia",
        "Portugal": "Portugalia",
        "Puerto Rico": "Puerto Rico",
        "Qatar": "Qatar",
        "Reunion": "Réunion",
        "Romania": "România",
        "Russian Federation": "Federația Rusă",
        "Rwanda": "Rwanda",
        "Saint Helena": "Sfânta Elena",
        "Saint Kitts and Nevis": "Saint Kitts și Nevis",
        "Saint Lucia": "Sfânta Lucia",
        "Saint Pierre and Miquelon": "Sfântul Pierre și Miquelon",
        "Saint Vincent and The Grenadines": "Saint Vincent și Grenadine",
        "Samoa": "Samoa",
        "San Marino": "San Marino",
        "Sao Tome and Principe": "Sao Tome și Principe",
        "Saudi Arabia": "Arabia Saudită",
        "Senegal": "Senegal",
        "Serbia": "Serbia",
        "Seychelles": "Seychelles",
        "Sierra Leone": "Sierra Leone",
        "Singapore": "Singapur",
        "Slovakia": "Slovacia",
        "Slovenia": "Slovenia",
        "Solomon Islands": "Insulele Solomon",
        "Somalia": "Somalia",
        "South Africa": "Africa de Sud",
        "South Georgia and The South Sandwich Islands": "Georgia de Sud și Insulele Sandwich de Sud",
        "Spain": "Spania",
        "Sri Lanka": "Sri Lanka",
        "Sudan": "Sudan",
        "Suriname": "Surinam",
        "Svalbard and Jan Mayen": "Svalbard și Jan Mayen",
        "Swaziland": "Swaziland",
        "Sweden": "Suedia",
        "Swizerland": "Elvetia",
        "Syrian Arab Republic": "Republica Araba Siriana",
        "Taiwan": "Taiwan",
        "Tajikistan": "Tadjikistan",
        "Tanzania, United Republic of": "Tanzania, Republica Unită din",
        "Thailand": "Tailanda",
        "Timor-leste": "Timorul de Est",
        "Togo": "Togo",
        "Tokelau": "Tokelau",
        "Tonga": "Tonga",
        "Trinidad and Tobago": "Trinidad și Tobago",
        "Tunisia": "Tunisia",
        "Turkey": "Turcia",
        "Turkmenistan": "Turkmenistan",
        "Turks and Caicos Islands": "Insulele Turks și Caicos",
        "Tuvalu": "Tuvalu",
        "Uganda": "Uganda",
        "Ukraine": "Ucraina",
        "United Arab Emirates": "Emiratele Arabe Unite",
        "United Kingdom": "Regatul Unit",
        "United States": "Statele Unite",
        "United States Minor Outlying Islands": "Insulele Minore Îndepărtate ale Statelor Unite",
        "Uruguay": "Uruguay",
        "Uzbekistan": "Uzbekistan",
        "Vanuatu": "Vanuatu",
        "Venezuela": "Venezuela",
        "Viet Nam": "Viet Nam",
        "Virgin Islands, British": "Insulele Virgine, britanice",
        "Virgin Islands, U.S.": "Insulele Virgine, S.U.A.",
        "Wallis and Futuna": "Wallis și Futuna",
        "Western Sahara": "Sahara de Vest",
        "Yemen": "Yemen",
        "Zambia": "Zambia",
        "Zimbabwe": "Zimbabwe"
      },
      
      //Alt texts
      'alt.example-1':'două automobile autonome',
      'alt.example-2':'mână care ține un telefon mobil',
      'alt.example-3': 'chip de femeie',
      'alt.defining-ai':'amalgam de cuvinte legate de IA',
      'alt.turing-test':'conversație pe calculator între un bărbat, un robot și o femeie',
      'alt.chinese-room':'experimentul camerei chinezești',
      'alt.chicken-crossing':'robot într-o barcă uitându-se la un pui de găină, o vulpe și un săculeț cu grăunțe',
      'alt.a-to-b':'hand holding a phone using a navigation app',
      'alt.tic-tac-toe':'doi copii care se joacă X și 0',
      'alt.self-driving-car':'automobil autonom la stop',
      'alt.spam-or-ham':'e-mail obișnuit și e-mail spam ',
      'alt.mnist':'set de date MNIST',
      'alt.nearest-neighbor':'două persoane făcând cumpărături',
      'alt.nearest-neighbor-graph':'clasificatorul „cel mai apropiat vecin”',
      'alt.recommendation':'bărbat și femeie ascultând muzică pe telefon',
      'alt.price-real-estate':'trei case diferite',
      'alt.supervised-learning':'om care învață un robot',
      'alt.dice':'zaruri',
      'alt.chess':'tablă de șah',
      'alt.poker':'mână care ține cărți de joc',
      'alt.eyes':'trei persoane cu ochi căprui, o persoană cu ochi albaștri',
      'alt.search-1':'cale unică ducând la un obiectiv',
      'alt.search-2':'patru căi diferite ducând la același obiectiv',
      'alt.chicken-crossing-1':'puiul de găină care traversează râul - starea inițială',
      'alt.chicken-crossing-2':'puiul de găină care traversează râul - prima tranziție',
      'alt.chicken-crossing-3':'puiul de găină care traversează râul - prima tranziție',
      'alt.chicken-crossing-4':'puiul de găină care traversează râul - prima tranziție',
      'alt.chicken-crossing-5':'puiul de găină care traversează râul - prima tranziție',
      'alt.implication-1':'bărbat și femeie',
      'alt.implication-2':'ochi ',
      'alt.implication-3':'cameră de supraveghere',
      'alt.implication-4':'robot lucrând pe un laptop ',
      'alt.oddchange':'soare și nori',
      'alt.brain':'desen reprezentând un creier',
      'alt.carstop':'automobil autonom la stop',
      'alt.gan':'robot ținând imaginea unei pisici și a unui indicator stop ',
      'alt.hammock':'robot făcând vânt cu evantaiul unui bărbat într-un hamac',
      'alt.game-tree-1':'arbore de joc 1',
      'alt.game-tree-2':'arbore de joc 2',
      'alt.game-tree-3':'arbore de joc 3',
      'alt.game-tree-4':'arbore de joc 4',
      'alt.bayes-rule-1':'imagine ilustrând rezultatele fals pozitive și fals negative la testele pentru depistarea cancerului',
      'alt.bayes-rule-2':'imagine ilustrând rezultatele fals pozitive și fals negative la testele pentru depistarea cancerului',
      'alt.defineai':'amalgam de cuvinte legate de IA',
      'alt.winter':'robot dormind în zăpadă',
      'alt.terminator':'robot fioros',
  }
  
