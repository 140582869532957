// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'da',
  'globals.signIn': 'Log ind',
  'globals.logIn': 'Log ind',
  'globals.signUp': 'Tilmeld dig',
  'globals.signOut': 'Log ud',
  'globals.logOut': 'Log ud',
  'globals.submit': 'Indsend',
  'globals.submitting': 'Indsender...',
  'globals.yourEmail': 'Din e-mailadresse',
  'globals.yourPassword': 'Din adgangskode',
  'globals.courseOverview': 'Kursusoversigt',
  'globals.forgotPassword': 'Glemt adgangskode?',
  'globals.invalidCredentials': 'Ugyldigt ID',
  'globals.section': 'Del',
  'globals.exercises': 'Øvelser',
  'globals.chapter': 'Kapitel',
  'globals.introToAI': 'Introduktion til kunstig intelligens',
  'globals.buildingAI': 'Opbygning af kunstig intelligens',
  'globals.acceptPolicy': 'Accepter politik',
  // section
  'section.footerEndOfTheChapter': 'Du er nået til slutningen af kapitlet',
  'section.footerEndOfTheCourseTitle': 'Du er nået til slutningen af kurset!',
  'section.footerEndOfTheCourseText': 'Når du har gennemført alle øvelser og peer reviews, gennemgås dine svar af vores medarbejdere.',
  'section.footerEndOfTheCourseBAI': 'Fortsæt din rejse mod at kunne implementere din første AI-idé.',
  'section.nextSection': 'Næste del',
  'section.nextChapter': 'Næste kapitel',
  'section.start': 'Start',
  'section.logisticRegressionExample.slope': 'Hældning',
  'section.logisticRegressionExample.intercept': 'Skæring',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Venter på dit review',
  'quiz.rejectedPeerReview': 'Afvist under peer review',
  'quiz.unsubmittedAnswer': 'Svar ikke indsendt',
  'quiz.pendingPeerReview': 'Afventer peer review',
  // quiz
  'quiz.status.answered': 'Besvaret',
  'quiz.status.rejected': 'Svar afvist, prøv igen',
  'quiz.status.unanswered': 'Ikke besvaret',
  'quiz.status.exercisesCompleted': 'Gennemførte øvelser',
  'quiz.status.correctAnswers': 'Korrekte svar',
  'quiz.message.rejectedInReview': 'Dit tidligere svar blev afvist under review. Prøv igen!',
  'quiz.youAnswered': 'Du svarede',
  'quiz.yourAnswer': 'Dit svar...',
  'quiz.yourScore': 'Din score:',
  'quiz.quizNotLoaded': 'Quiz kunne ikke indlæses',
  'quiz.words': 'Ord:',
  'quiz.exampleAnswer': 'Eksempel på svar:',
  // peer review
  'peerreview.question1': 'Svaret er relevant',
  'peerreview.question2': 'Svaret er fyldestgørende',
  'peerreview.question3': 'Svaret er velbegrundet',
  'peerreview.question4': 'Svaret er let at følge',
  'peerreview.thankYou': 'Tak for dit review af dine peers!',
  'peerreview.giveMoreReviews': 'Hvis du har tid til overs,',
  'peerreview.giveMoreReviewsButton': 'kan du give flere reviews',
  'peerreview.notEnoughAnswers': 'Normalt vises et peer review her, men der er ikke nok deltagere, der har besvaret denne øvelse.',
  'peerreview.selectAnswerMessage': 'Nu er det tid til et peer review! Nedenfor kan du se nogle svar fra andre kursusdeltagere. Vælg ét af dem, og giv dit review. Gentag mindst tre gange.',
  'peerreview.minimumPeerReviewWarning': 'Øvelsen kan først accepteres, når du har givet mindst tre peer reviews.',
  'peerreview.select': 'Vælg',
  'peerreview.reportSpam': 'Rapporter som spam',
  'peerreview.feedback': 'Dit svar er gennemgået og accepteret. Her kan du se, hvad dine peers mener om det:',
  // exercise17
  'quiz.exercise17.xLabel': 'Års skolegang',
  'quiz.exercise17.yLabel': 'Forventet levetid i år',
  'quiz.exercise17.textPart1': 'Nu vil vi undersøge sammenhængen mellem det samlede antal års uddannelse (fra førskole til universitet) og den forventede levetid. I denne figur vises data fra tre forskellige lande repræsenteret ved prikker:',
  'quiz.exercise17.textPart2': 'Vi har et land, hvor man i gennemsnit går i skole i 10 år, og den forventede levetid er 57 år, et andet land, hvor man i gennemsnit går i skole i 13 år, og den forventede levetid er 53 år, og et tredje land, hvor man i gennemsnit går i skole i 20 år, og den forventede levetid er 80 år.',
  'quiz.exercise17.textPart3': 'Du kan trække slutpunkterne for den ubrudte linje for at placere linjen på en sådan måde, at den følger trenden for datapunkterne. Du kan ikke få linjen til at passe perfekt med datapunkterne. Det er helt i orden: Nogle af datapunkterne ligger over linjen, og andre under. Det vigtigste er, at linjen beskriver den generelle trend.',
  'quiz.exercise17.textPart4': 'Når du har placeret linjen, kan du bruge den til at forudsige den forventede levetid.',
  'quiz.exercise17.textPart5': 'Hvad kan du ud fra disse data sige om den forventede levetid for personer med 15 års skolegang? Vigtigt: Selv om du kan nå frem til en meget specifik forudsigelse ned til en brøkdel af et år ved at justere linjen, får du ikke nødvendigvis en troværdig forudsigelse. Du skal tage den begrænsede datamængde i betragtning, når du giver dit svar.',
  // exercise18
  'quiz.exercise18.xLabel': 'Års skolegang',
  'quiz.exercise18.yLabel': 'Forventet levetid i år',
  'quiz.exercise18.textPart1': 'I den foregående øvelse havde vi kun data fra tre lande. Det fulde datasæt består af data fra 14 forskellige lande, som her er gengivet i en graf:',
  'quiz.exercise18.textPart2': 'Vil din forudsigelse af den forventede levetid for personer med 15 års skolegang blive ændret med disse data? I bekræftende fald, hvorfor?',
  'quiz.exercise18.textPart3': 'Hvilken af følgende muligheder matcher bedst dit estimat af den forventede levetid for personer med 15 års skolegang? Vælg den mulighed, der efter din mening kan begrundes ved at tilpasse modellen med den lige linje til ovennævnte data.',
  // exercise19
  'quiz.exercise19.xLabel': 'Forberedelsestimer',
  'quiz.exercise19.yLabel': 'Sandsynlighed for at bestå',
  'quiz.exercise19.textPart1': 'Hver prik på figuren svarer til en studerende. Nederst i figuren har vi skalaen for, hvor mange timer den studerende har brugt på at forberede sig til eksamen. De studerende, der har bestået eksamen, vises som prikker øverst i diagrammet, og de studerende, der er dumpet, vises nederst. Vi bruger skalaen til venstre til at angive den forudsagte sandsynlighed for at bestå, som vi får fra den logistiske regressionsmodel (se nedenfor).',
  'quiz.exercise19.textPart2': 'Ud fra denne figur kan du generelt se, at studerende, der har brugt flere timer på at forberede sig, havde bedre chancer for at bestå eksamen. Især de ekstreme tilfælde er indlysende: Med under én times forberedelse er det meget vanskeligt at bestå eksamen, mens de fleste med mange forberedelsestimer består. Men hvad med de studerende, der har forberedt sig et sted mellem ekstremerne? Hvad er dine chancer for at bestå, hvis du forbereder dig i seks timer?',
  'quiz.exercise19.textPart3': 'Vi kan kvantificere sandsynligheden for at bestå ved hjælp af logistisk regression. Kurven i figuren kan fortolkes som sandsynligheden for at bestå: Når en studerende har forberedt sig i fem timer, er sandsynligheden for at bestå lidt over 20 %. Vi vil ikke gå i detaljer med, hvordan kurven fås, men den svarer til den måde, hvorpå vi fastlægger vægtene ved lineær regression.',
  'quiz.exercise19.textPart4': 'Hvis du ønsker at have en chance på 80 % for at bestå en universitetseksamen, hvor mange timer skal du da omtrentligt bruge på at forberede dig?',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Input',
  'quiz.exercise22.sigmoidYLabel': 'Sigmoid-output',
  'quiz.exercise22.stepXLabel': 'Input',
  'quiz.exercise22.stepYLabel': 'Step-output',
  'quiz.exercise22.identityXLabel': 'Input',
  'quiz.exercise22.identityYLabel': 'Identitetsoutput',
  'quiz.exercise22.textPart1': 'Nedenfor vises grafer for tre forskellige aktiveringsfunktioner med forskellige egenskaber. Vi har først sigmoidfunktionen, derefter trinfunktionen og til sidst identitetsfunktionen.',
  // exercise23
  'quiz.exercise23.happyFaces': 'glade smileyer klassificeret korrekt',
  'quiz.exercise23.sadFaces': 'sure smileyer klassificeret korrekt',
  // certificate availability
  'certificate.failedToCheck': 'Det kunne ikke kontrolleres, om kursusbeviset er tilgængeligt',
  'certificate.failedToGenerate': 'Kursusbevis kunne ikke genereres',
  'certificate.notYetAvailable': 'Dit kursusbevis kan hentes her, når du har modtaget en officiel bekræftelse fra os. Dette kan tage noget tid. Tak for din tålmodighed!',
  'certificate.updateName': 'Dit kursusbevis er tilgængeligt! Du bedes opdatere dit for- og efternavn, så det fremgår af dit certifikat, ved hjælp af en formular, der findes på din profilside',
  'certificate.generate': 'Generer dit kursusbevis nedenfor.',
  'certificate.generateTextPart1': 'Kursusbevis genereres til',
  'certificate.generateTextPart2': 'Sørg for, at dette er korrekt, inden kursusbeviset genereres, da navnet ikke kan ændres efterfølgende. Hvis du vil ændre dit navn, kan du gøre det i formularen nedenfor.',
  'certificate.generateButton': 'Generer kursusbevis',
  'certificate.downloadButton': 'Download kursusbevis',
  'certificate.checkingAvailability': 'Kontrollerer, at kursusbevis er tilgængeligt...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Klassificeret korrekt',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Ikke klassificeret korrekt',
  // section blocks
  'section.block.note': 'Bemærk',
  'section.block.example': 'Eksempel',
  'section.block.keyTerminology': 'Nøgletermer',
  'section.partSummary.spectrumTextPart1': 'Tilmeld dig ',
  'section.partSummary.spectrum': '"Elements of AI"-gruppen',
  'section.partSummary.spectrumTextPart2': ' for at diskutere og stille spørgsmål om dette kapitel.',
  'section.partSummary.feedbackTitle': 'Indsend feedback om denne del',
  'section.partSummary.feedbackPlaceholder': 'Skriv din feedback her.',
  'section.partSummary.feedbackSuccess': 'Tak for din feedback!',
  // 404
  'globals.404.title': '404 - Side ikke fundet',
  'globals.404.intro': 'Du har lige ramt en rute, der ikke findes ... trist.',
  'globals.loading': 'Indlæser...',
  // footer / menu
  'globals.menu': 'Menu',
  'globals.menu.about': 'Om',
  'globals.menu.faq': 'Ofte stillede spørgsmål',
  'globals.menu.privacyPolicy': 'Databeskyttelsespolitik',
  'globals.menu.myProfile': 'Min profil',
  'globals.menu.termsofService': 'Servicevilkår',
  // Dashboard/Index
  'dashboard.title': 'Velkommen til kurset!',
  'dashboard.congratulations.title': 'Tillykke!',
  'dashboard.congratulations.message': 'Du har afsluttet kurset Elements of AI',
  'dashboard.congratulations.downloadCertificate': 'Download kursusbevis',
  'dashboard.notLoggedIn.title': 'Velkommen!',
  'dashboard.notLoggedIn.message': 'Tilmeld dig, eller log ind for at få fuld adgang til kurset. Det er gratis!',
  // Account
  'account.certificate': 'Kursusbevis',
  'account.credits': 'ECTS-point',
  'account.myProfile': 'Min profil',
  'account.profileInformation': 'Profiloplysninger',
  'account.changePassword': 'Skift adgangskode',
  'account.changeLanguage': 'Skift sprog',
  'account.goToCourse': 'Gå på kursus',
  'account.buildingAINew': 'Nyt kursus på engelsk',
  'account.accept': 'Accepter',
  'account.decline': 'Afvis',
  'account.courses': 'Kurser',
  // Sign Up page
  'signUp.description': 'Udfyld formularen nedenfor for at oprette en ny mooc.fi-konto',
  'signUp.email': 'Din e-mailadresse',
  'signUp.firstName': 'Fornavn',
  'signUp.lastName': 'Efternavn',
  'signUp.postalCode': 'Postnummer',
  'signUp.country': 'Hvor bor du?',
  'signUp.Password': 'Adgangskode',
  'signUp.confirmPassword': 'Bekræft adgangskode',
  'signUp.howToStudyTitle': 'Hvordan ønsker du at studere?',
  'signUp.6weeksTitle': 'Kursus på seks uger',
  'signUp.6weeksSubTitle': 'Anbefalet (ca. 5 timer om ugen)',
  'signUp.6weeksText': 'Det er dokumenteret, at en fast frist hjælper kursister med at afslutte kurset.',
  'signUp.selfPacedTitle': 'Selvstudie',
  'signUp.selfPacedText': 'Du kan gennemføre kurset i dit eget tempo.',
  'signUp.ectsTitleSwe': '',
  'signUp.ectsTitle': 'Ansøger du om ECTS-point?',
  'signUp.ectsStudent': 'Jeg er studerende ved universitetet i Helsinki. Hvis du markerer denne boks og angiver dit studie-ID, modtager du automatisk ECTS-point.',
  'signUp.ectsStudentSwe': '',
  'signup.ectsStudentSweLink': '',
  'signUp.ectsOpenUni': 'Marker denne boks for at modtage en e-mail med vejledning om, hvordan du ansøger om ECTS-point',
  'signUp.studentNumber': 'ID ved universitetet i Helsinki (hvis du er studerende)',
  'signUp.privacyOptIn': 'Jeg tillader, at indsamlede data om min studieadfærd anvendes til forskningsformål. Dataene indeholder information fra anvendelse af materiale og kursusøvelser. Ingen personer kan identificeres ud fra publikationer.',
  'signUp.acceptPolicyFirst': 'Jeg accepterer databeskyttelsespolitikken og servicevilkårene.',
  'signUp.marketingOptIn': 'Jeg ønsker at modtage opdateringer om nye sprogversioner og oplysninger om nye kurser. Jeg accepterer at dele mine kontaktoplysninger for at modtage skræddersyede meddelelser på tredjepartsplatforme. Læs mere i vores databeskyttelsespolitik.',
  'signUp.privacyLink': 'Læs vores databeskyttelsespolitik',
  'signUp.error': 'Der er fejl i formularen. Tjek din formular.',
  // Sign in page
  'signIn.title': 'Log ind',
  'signIn.intro': 'Brug formularen nedenfor for at logge ind med din mooc.fi-konto',
  'signIn.email': 'Din e-mailadresse',
  'signIn.password': 'Din adgangskode',
  'signIn.noAccount': 'Har du ikke en konto?',
  'signIn.signUpNow': 'Tilmeld dig nu',
  // Forgot password
  'pwForget.title': 'Glemt adgangskode',
  'pwForget.description': 'Indsæt e-mailadressen for din mooc.fi-konto, så sender vi dig et link til nulstilling af din adgangskode',
  'pwForget.sendResetLink': 'Send link til nulstilling',
  'pwForget.completedDescription': 'Du bør snart modtage en e-mail fra mooc.fi med et link til nulstilling af din adgangskode. Hvis du ikke kan se den, skal du tjekke din spammappe.',
  'pwForget.problemDescription': 'Kontakt mooc@cs.helsinki.fi, hvis der opstår problemer.',
  'pwForget.resetEmailSent': 'Link til nulstilling af adgangskode sendt',
  'pwForget.emailPlaceholder': 'E-mailadresse',
  // password change
  'pwChange.authError': 'Autentificering mislykkedes. Prøv at logge ind igen',
  'pwChange.unexpError': 'Adgangskoden kunne ikke opdateres på grund af en uventet fejl',
  'pwChange.required': 'Påkrævet',
  'pwChange.pwMustMatch': 'Adgangskoder skal stemme overens',
  'pwChange.currentPassword': 'Nuværende adgangskode',
  'pwChange.newPassword': 'Ny adgangskode',
  'pwChange.confirmPassword': 'Bekræft ny adgangskode',
  'pwChange.changingPassword': 'Adgangskode ændres...',
  'pwChange.changePassword': 'Skift adgangskode',
  'pwChange.passwordChanged': 'Adgangskode ændret',
  // user details
  'userDetails.authError': 'Autentificering mislykkedes. Prøv at logge ind igen',
  'userDetails.unexpError': 'Profilen kunne ikke opdateres på grund af en uventet fejl',
  'userDetails.required': 'Påkrævet',
  'userDetails.invalidEmail': 'Ugyldig e-mailadresse',
  'userDetails.email': 'E-mail',
  'userDetails.emailAddress': 'E-mailadresse',
  'userDetails.firstName': 'Fornavn',
  'userDetails.lastName': 'Efternavn',
  'userDetails.language': 'Sprog',
  'userDetails.updatingProfile': 'Profil opdateres...',
  'userDetails.updateProfile': 'Opdater profil',
  'userDetails.profileUpdated': 'Profil opdateret',
  // languages
  'languages.en.long': 'Engelsk',
  'languages.fi.long': 'Finsk',
  'languages.se.long': 'Svensk',
  'languages.de.long': 'Tysk (Tyskland)',
  'languages.ee.long': 'Estisk',
  'languages.no.long': 'Norsk',
  'languages.lv.long': 'Lettisk',
  'languages.da.long': 'Dansk',
  'languages.pl.long': 'Polsk',
  'languages.lt.long': 'Litauisk',
  'languages.fr.long': 'Fransk (Frankrig)',
  'languages.fr-be.long': 'Fransk (Belgien)',
  'languages.nl-be.long': 'Nederlandsk (Belgien)',
  'languages.it.long': 'Italiensk',
  'languages.mt.long': 'Maltesisk',
  'languages.en-ie.long': 'Engelsk (Irland)',
  'languages.hr.long': 'Kroatisk',
  'languages.ro.long': 'Rumænsk',
  'languages.ga.long': 'Irsk',
  'languages.is.long': 'Islandsk',
  'languages.de-at.long': 'Tysk (Østrig)',
  'languages.sk.long': 'Slovakisk',
  'languages.en-lu.long': 'Engelsk (Luxembourg)',
  'languages.bg.long': 'Bulgarsk',
  'languages.cs.long': 'Tjekkisk',
  'languages.sl.long': 'Slovensk',
  'languages.pt.long': 'Portugisisk',
  'languages.nl.long': 'Nederlandsk (Holland)',
  'languages.es.long': 'Spansk',
  'languages.el.long': 'Græsk',
  // Email verification
  'emailVerification.completedMessage': 'Din e-mailadresse er blevet bekræftet',
  'emailVerification.verified': 'Bekræftet',
  // Exercises
  'exercise.loading': 'Indlæser øvelse...',
  'exercise.signUp': 'Tilmeld dig for at gennemføre øvelser',
  'exercise.score': 'Din score:',
  'exercise.wrongLocale': 'Forkert adresse',
  // Part tempplate
  'partTemplate.intro1': 'I kapitel ',
  'partTemplate.intro2': ' gennemgår vi følgende dele. Klik nedenfor for at komme i gang:',
  'partTemplate.spectrumPart1': 'Tilmeld dig ',
  'partTemplate.spectrum': '"Elements of AI"-gruppen',
  'partTemplate.spectrumPart2': ' for at diskutere og stille spørgsmål om dette kapitel.',
  // missing info
  'missingInfo.title': 'Oplysninger mangler',
  'missingInfo.body': 'Vi stiller normalt disse spørgsmål, når brugere tilmelder sig, men du har logget ind med en eksisterende mooc.fi-konto. Udfyld formularen nedenfor for at fortsætte.',
  'missingInfo.privacyPolicy': 'Læs vores databeskyttelsespolitik',
  'missingInfo.error': 'Der er fejl i formularen. Tjek din formular.',
  'missingInfo.privacyPolicyUpdateBody': 'Vi har opdateret vores databeskyttelsespolitik. Her er et resumé af ændringerne. Vi annoncerer vores kurser for potentielle nye studerende ved at danne publikum i marketingplatforme. Dette kan involvere deling af e-mail-adresser med disse platforme. Til det samme formål deles anonymiserede aktivitetsdata med marketingnetværk. Du kan forhindre deling af e-mail-oplysninger ved at fjerne markeringen i afkrydsningsfeltet for markedsføringstilladelse i din kursusprofil. For at forhindre deling af aktivitetsdata kan du ændre cookieindstillingerne på kursuswebstedet. Det er nødvendigt at acceptere disse vilkår for at fortsætte med at bruge tjenesten.',
  'missingInfo.privacyPolicyUpdateLink': 'Du kan se databeskyttelsespolitikken her.',
  // routes
  'routes.LANDING': '/da/',
  'routes.SIGN_UP': '/da/signup',
  'routes.SIGN_IN': '/da/signin',
  'routes.MISSING_INFO': '/da/missinginfo',
  'routes.PASSWORD_FORGET': '/da/pw-forget',
  'routes.HOME': '/da/home',
  'routes.ACCOUNT': '/da/account',
  'routes.ACCEPT_POLICY': '/da/accept-policy',
  'routes.PRIVACY': 'https://www.elementsofai.dk/faq/databeskyttelsespolitik',
  'routes.TERMS': 'https://www.elementsofai.com/faq/terms-and-conditions',
  'routes.FAQ': 'https://www.elementsofai.dk/faq',
  'routes.ABOUT': 'https://www.elementsofai.dk/',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/da-diskuter-pa-dansk',
  'routes.ects': 'https://mooc.fi/register-completion/elements-of-ai',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/faq/how-can-i-get-2-ects-credits',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Kopper kaffe om dagen',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Skrevne kodelinjer',
  // meta
  'meta.title': 'Et gratis onlinekursus',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Læs mere om det AI-kursus for studerende og erhvervsfolk, der er udviklet af universitetet i Helsinki og Reaktor – der kræves intet kendskab til programmering eller matematik.',
  'meta.sitename': 'Elements of AI',
  // ECTS
  'ects.text': 'Her modtager du instruktioner i, hvordan du ansøger om ECTS-point, når du har gennemført kurset.',
  'ects.textCompleted': 'Hvis du har et finsk personnummer, kan du ansøge om point ved at klikke på ',
  'ects.link': 'her.',
  'ects.info': 'Would you like to apply for ECTS credits?',
  'ects.readMore': 'Read more',
  // Countries
  'countryselect.select': "Vælg venligst",
  'Sweden': "Sverige",
  'Estonia': "Estland",
  'Finland': "Finland",
  'Germany': "Tyskland",
  "Countries": {
    "Afganistan": "Afganistan",
    "Åland Islands": "Ålandsøerne",
    "Albania": "Albanien",
    "Algeria": "Algeriet",
    "American Samoa": "Amerikansk Samoa",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antarctica": "Antarktis",
    "Antigua and Barbuda": "Antigua og Barbuda",
    "Argentina": "Argentina",
    "Armenia": "Armenien",
    "Aruba": "Aruba",
    "Australia": "Australien",
    "Austria": "Østrig",
    "Azerbaijan": "Aserbajdsjan",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahrain",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbados",
    "Belarus": "Hviderusland",
    "Belgium": "Belgien",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermuda",
    "Bhutan": "Bhutan",
    "Bolivia": "Bolivia",
    "Bosnia and Herzegovina": "Bosnien-Hercegovina",
    "Botswana": "Botswana",
    "Bouvet Island": "Bouvet Island",
    "Brazil": "Brasilien",
    "British Indian Ocean Territory": "British Indian Ocean Territory",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgarien",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambodia": "Cambodja",
    "Cameroon": "Cameroun",
    "Canada": "Canada",
    "Cape Verde": "Kap Verde",
    "Cayman Islands": "Caymanøerne",
    "Central African Republic": "Den Centralafrikanske Republik",
    "Chad": "Tchad",
    "Chile": "Chile",
    "China": "Kina",
    "Christmas Island": "Juleøen",
    "Cocos (Keeling) Islands": "Cocosøerne (Keeling)",
    "Colombia": "Colombia",
    "Comoros": "Comorerne",
    "Congo": "Congo",
    "Congo, The Democratic Republic of The": "Congo, Den demokratiske republik The",
    "Cook Islands": "Cookøerne",
    "Costa Rica": "Costa Rica",
    "Cote D'ivoire": "Elfenbenskysten",
    "Croatia": "Kroatien",
    "Cuba": "Cuba",
    "Cyprus": "Cypern",
    "Czech Republic": "Tjekkiet",
    "Denmark": "Danmark",
    "Djibouti": "Djibouti",
    "Dominica": "Dominica",
    "Dominican Republic": "Dominikanske republik",
    "Ecuador": "Ecuador",
    "Egypt": "Egypten",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Ækvatorial Guinea",
    "Eritrea": "Eritrea",
    "Estonia": "Estland",
    "Ethiopia": "Etiopien",
    "Falkland Islands (Malvinas)": "Falklandsøerne (Malvinas)",
    "Faroe Islands": "Færøerne",
    "Fiji": "Fiji",
    "Finland": "Finland",
    "France": "Frankrig",
    "French Guiana": "Fransk Guyana",
    "French Polynesia": "Fransk Polynesien",
    "French Southern Territories": "Franske sydlige territorier",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Georgien",
    "Germany": "Tyskland",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Grækenland",
    "Greenland": "Grønland",
    "Grenada": "Grenada",
    "Guadeloupe": "Guadeloupe",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea": "Guinea",
    "Guinea-Bissau": "Guinea-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "Heard Island and Mcdonald Islands": "Heard Island og Mcdonald Islands",
    "Holy See (Vatican City State)": "Holy Holy State (Vatikanstaten)",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Ungarn",
    "Iceland": "Island",
    "India": "Indien",
    "Indonesia": "Indonesien",
    "Iran, Islamic Republic of": "Iran, Islamiske Republik",
    "Iraq": "Irak",
    "Ireland": "Irland",
    "Isle of Man": "Isle of Man",
    "Israel": "Israel",
    "Italy": "Italien",
    "Jamaica": "Jamaica",
    "Japan": "Japan",
    "Jersey": "Jersey",
    "Jordan": "Jordan",
    "Kazakhstan": "Kasakhstan",
    "Kenya": "Kenya",
    "Kiribati": "Kiribati",
    "Korea, Democratic People's Republic of": "Korea, Den Demokratiske Folkerepublik",
    "Korea, Republic of": "Korea, Republikken",
    "Kosovo": "Kosovo",
    "Kuwait": "Kuwait",
    "Kyrgyzstan": "Kirgisistan",
    "Lao People's Democratic Republic": "Laos folks demokratiske republik",
    "Latvia": "Letland",
    "Lebanon": "Libanon",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libyan Arab Jamahiriya": "Libyan Arab Jamahiriya",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Litauen",
    "Luxembourg": "Luxembourg",
    "Macao": "Macao",
    "Macedonia, The Former Yugoslav Republic of": "Makedonien, Den Tidligere Jugoslaviske Republik",
    "Madagascar": "Madagaskar",
    "Malawi": "Malawi",
    "Malaysia": "Malaysia",
    "Maldives": "Maldiverne",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Marshalløerne",
    "Martinique": "Martinique",
    "Mauritania": "Mauretanien",
    "Mauritius": "Mauritius",
    "Mayotte": "Mayotte",
    "Mexico": "Mexico",
    "Micronesia, Federated States of": "Mikronesien",
    "Moldova, Republic of": "Moldova, Republikken",
    "Monaco": "Monaco",
    "Mongolia": "Mongoliet",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Marocco": "Marocco",
    "Mozambique": "Mozambique",
    "Myanmar": "Myanmar",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Holland",
    "Netherlands Antilles": "De Nederlandske Antiller",
    "New Caledonia": "Ny Kaledonien",
    "New Zealand": "New Zealand",
    "Nicaragua": "Nicaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "Norfolk Island": "Norfolk Island",
    "Northern Mariana Islands": "Nordmarianerne",
    "Norway": "Norge",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestinian Territory, Occupied": "Palestinsk territorium, besat",
    "Panama": "Panama",
    "Papua New Guinea": "Papua Ny Guinea",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Filippinerne",
    "Pitcairn": "Pitcairn",
    "Poland": "Polen",
    "Portugal": "Portugal",
    "Puerto Rico": "Puerto Rico",
    "Qatar": "Qatar",
    "Reunion": "Réunion",
    "Romania": "Rumænien",
    "Russian Federation": "Den Russiske Føderation",
    "Rwanda": "Rwanda",
    "Saint Helena": "Sankt Helena",
    "Saint Kitts and Nevis": "Saint Kitts og Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Pierre and Miquelon": "Saint Pierre og Miquelon",
    "Saint Vincent and The Grenadines": "Saint Vincent og Grenadinerne",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "Sao Tomé og Principe",
    "Saudi Arabia": "Saudi Arabien",
    "Senegal": "Senegal",
    "Serbia": "Serbien",
    "Seychelles": "Seychellerne",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapore",
    "Slovakia": "Slovakiet",
    "Slovenia": "Slovenien",
    "Solomon Islands": "Salomonøerne",
    "Somalia": "Somalia",
    "South Africa": "Sydafrika",
    "South Georgia and The South Sandwich Islands": "Syd Georgia og Sydsandwichøerne",
    "Spain": "Spanien",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Surinam",
    "Svalbard and Jan Mayen": "Svalbard og Jan Mayen",
    "Swaziland": "Swaziland",
    "Sweden": "Sverige",
    "Swizerland": "Swizerland",
    "Syrian Arab Republic": "Den syriske arabiske republik",
    "Taiwan": "Taiwan",
    "Tajikistan": "Tadsjikistan",
    "Tanzania, United Republic of": "Tanzania, De Forenede Republik",
    "Thailand": "Thailand",
    "Timor-leste": "Østtimor",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad og Tobago",
    "Tunisia": "Tunesien",
    "Turkey": "Tyrkiet",
    "Turkmenistan": "Turkmenistan",
    "Turks and Caicos Islands": "Turks- og Caicosøerne",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukraine",
    "United Arab Emirates": "Forenede Arabiske Emirater",
    "United Kingdom": "Det Forenede Kongerige",
    "United States": "Forenede Stater",
    "United States Minor Outlying Islands": "USA's ydre småøer",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Usbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela": "Venezuela",
    "Viet Nam": "Vietnam",
    "Virgin Islands, British": "Jomfruøerne, Britiske",
    "Virgin Islands, U.S.": "Jomfruøerne, U.S.",
    "Wallis and Futuna": "Wallis og Futuna",
    "Western Sahara": "Vestsahara",
    "Yemen": "Yemen",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe"
  }
}
