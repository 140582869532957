module.exports = {
  langs: [
    "en",
    "fi",
    "se",
    "de",
    "ee",
    "fr",
    "it",
    "fr-be",
    "no",
    "lt",
    "lv",
    "nl-be",
    "mt",
    "hr",
    "pl",
    "en-ie",
    "ga",
    "nl",
    "sk",
    "da",
    "ro",
    "sl",
    "is",
    "de-at",
    "en-lu",
    "bg",
    "cs",
    "pt",
    "es",
    "el"
  ],
  defaultLangKey: "en"
};
