// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'nl',
  'globals.signIn': 'Aanmelden',
  'globals.logIn': 'Inloggen',
  'globals.signUp': 'Registreren',
  'globals.signOut': 'Afmelden',
  'globals.logOut': 'Uitloggen',
  'globals.submit': 'Verzenden',
  'globals.submitting': 'Verzenden...',
  'globals.yourEmail': 'Uw e-mailadres',
  'globals.yourPassword': 'Uw wachtwoord',
  'globals.courseOverview': 'Cursusoverzicht',
  'globals.forgotPassword': 'Wachtwoord vergeten?',
  'globals.invalidCredentials': 'Ongeldige gegevens',
  'globals.section': 'Rubriek',
  'globals.exercises': 'Oefeningen',
  'globals.chapter': 'Hoofdstuk',
  'globals.introToAI': 'Inleiding in AI',
  'globals.buildingAI': 'AI opbouwen',
  'globals.acceptPolicy': 'Accepteren beleid',
  // section
  'section.footerEndOfTheChapter': 'Dit is het einde van hoofdstuk {chapter}!',
  'section.footerEndOfTheCourseTitle': 'Dit is het einde van de cursus!',
  'section.footerEndOfTheCourseText': 'Als je de oefeningen gemaakt hebt, en de peerevaluatie werd afgerond, worden de antwoorden ook nog persoonlijk door ons nagekeken.',
  'section.nextSection': 'Volgend deel',
  'section.nextChapter': 'Volgend hoofdstuk',
  'section.start': 'Begin',
  'section.logisticRegressionExample.slope': 'Helling',
  'section.logisticRegressionExample.intercept': 'Snijpunt',
  'section.footerEndOfTheCourseBAI': 'Vervolg uw reis om uw eerste AI-idee te kunnen implementeren.',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Wachtend op uw beoordeling',
  'quiz.rejectedPeerReview': 'Afgekeurd bij beoordeling',
  'quiz.unsubmittedAnswer': 'Niet ingediend antwoord',
  'quiz.pendingPeerReview': 'In afwachting van beoordeling',
  // quiz
  'quiz.status.answered': 'Beantwoord',
  'quiz.status.rejected': 'Antwoord afgekeurd, probeer het opnieuw.',
  'quiz.status.unanswered': 'Niet beantwoord',
  'quiz.status.exercisesCompleted': 'Voltooide oefeningen',
  'quiz.status.correctAnswers': 'Correcte antwoorden',
  'quiz.message.rejectedInReview': 'Uw vorige antwoord werd afgekeurd bij de beoordeling. Probeer het opnieuw.',
  'quiz.youAnswered': 'U hebt geantwoord',
  'quiz.yourAnswer': 'Uw antwoord...',
  'quiz.yourScore': 'Uw score:',
  'quiz.quizNotLoaded': 'Kan quiz niet laden',
  'quiz.words': 'Woorden:',
  'quiz.exampleAnswer': 'Voorbeeldantwoord:',
  // peer review
  'peerreview.question1': 'Antwoord is relevant',
  'peerreview.question2': 'Antwoord is uitgebreid',
  'peerreview.question3': 'Antwoord is goed onderbouwd',
  'peerreview.question4': 'Antwoord is gemakkelijk te volgen',
  'peerreview.thankYou': 'Bedankt voor het beoordelen!',
  'peerreview.giveMoreReviews': 'Heeft u nog wat tijd,',
  'peerreview.giveMoreReviewsButton': 'beoordeel dan meer antwoorden.',
  'peerreview.notEnoughAnswers': 'Normaal zou hier een beoordeling staan, maar deze oefening is niet door voldoende mensen beantwoord.',
  'peerreview.selectAnswerMessage': 'Tijd om te beoordelen! Hieronder staan een paar antwoorden van andere gebruikers. Kies en beoordeel er een van. Doe dit ten minste driemaal.',
  'peerreview.minimumPeerReviewWarning': 'De oefening kan alleen worden aanvaard nadat u ten minste drie antwoorden heeft beoordeeld.',
  'peerreview.select': 'Selecteren',
  'peerreview.reportSpam': 'Spam rapporteren',
  'peerreview.feedback': 'Uw antwoord is beoordeeld en aanvaard. Dit denken andere gebruikers ervan:',
  // exercise17
  'quiz.exercise17.xLabel': 'Aantal jaren onderwijs',
  'quiz.exercise17.yLabel': 'Levensverwachting in jaren',
  'quiz.exercise17.textPart1': 'Laten we kijken naar het verband tussen het totale aantal jaren onderwijs (van de kleuterschool tot en met de universiteit) en de levensverwachting. Aan de hand van punten worden hier gegevens uit drie verschillende landen weergegeven:',
  'quiz.exercise17.textPart2': 'In één land tellen we gemiddeld 10 jaren onderwijs en een levensverwachting van 57 jaar, in een ander land gemiddeld 13 jaren onderwijs en een levensverwachting van 53 jaar, en in een derde land gemiddeld 20 jaren onderwijs en een levensverwachting van 80 jaar.',
  'quiz.exercise17.textPart3': 'U kunt de eindpunten van de volle lijn verslepen zodat de lijn de tendens van de gegevenspunten volgt. U kunt de lijn niet volledig doen overeenstemmen met de gegevenspunten, maar dat is geen probleem: sommige gegevenspunten liggen boven de lijn, andere eronder. Het voornaamste is dat de lijn de algemene tendens weergeeft.',
  'quiz.exercise17.textPart4': 'Als u de lijn heeft geplaatst, kunt u hiermee de levensverwachting voorspellen.',
  'quiz.exercise17.textPart5': 'Wat kunt u, op basis van de gegevens, zeggen over de levensverwachting van mensen met een onderwijsloopbaan van 15 jaar? Belangrijk: Denk eraan dat zelfs als u door de lijn aan te passen een specifieke voorspelling kunt doen die tot op een jaar nauwkeurig is, dit niet per se een betrouwbare voorspelling is. Hou bij het geven van uw antwoord rekening met de beperkte hoeveelheid gegevens.',
  // exercise18
  'quiz.exercise18.xLabel': 'Aantal studiejaren',
  'quiz.exercise18.yLabel': 'Levensverwachting in jaren',
  'quiz.exercise18.textPart1': 'In de vorige oefening beschikten we alleen over de gegevens van drie landen. De volledige gegevensreeks bestaat uit gegevens van 14 verschillende landen, hier weergegeven in een grafiek:',
  'quiz.exercise18.textPart2': 'Zou uw eerdere voorspelling over mensen met een onderwijsloopbaan van 15 jaar veranderen op grond van deze gegevens? Zo ja, waarom?',
  'quiz.exercise18.textPart3': 'Welke van de volgende opties benadert het best uw schatting van de levensverwachting van mensen met een onderwijsloopbaan van 15 jaar? Kies de optie die volgens u het meest correct is wanneer u het model van de rechte lijn toepast op de bovenstaande gegevens.',
  // exercise19
  'quiz.exercise19.xLabel': 'Uren gestudeerd',
  'quiz.exercise19.yLabel': 'Slaagkans',
  'quiz.exercise19.textPart1': 'Ieder punt op de grafiek komt overeen met één student. Onderaan de grafiek staat het aantal uren dat de student studeerde voor het examen. De studenten die geslaagd zijn voor het examen, worden met punten weergegeven bovenaan op de grafiek; de studenten die zijn gezakt staan onderaan. Met de indeling aan de linkerkant geven we de voorspelde slaagkans aan, die we afleiden uit het logistische regressiemodel, zoals hieronder wordt uitgelegd. Uit deze grafiek is ruwweg af te leiden dat studenten die meer hebben gestudeerd, een grotere slaagkans hebben. Vooral de extreme gevallen voelen we intuïtief aan: wie minder dan een uur studeert, zal het erg moeilijk hebben om te slagen, maar wie er hard voor werkt zal in de meeste gevallen slagen. Maar hoe zit het met de mensen die ergens tussen de uitersten vallen? Wat voor slaagkans heeft iemand die zes uur studeert?',
  'quiz.exercise19.textPart2': 'We kunnen de slaagkans berekenen dankzij logistische regressie. De curve op de grafiek kan worden geïnterpreteerd als de slaagkans: vijf uur studeren biedt bijvoorbeeld een slaagkans van iets meer dan 20%. We leggen niet verder uit hoe de curve kan worden verkregen, maar dat is vergelijkbaar met de manier waarop we de gewichten in lineaire regressie vinden.',
  'quiz.exercise19.textPart3': 'Kijk naar de bovenstaande grafiek. Hoeveel uur zou u ongeveer moeten studeren om voor een universiteitsexamen een slaagkans van 80% te hebben?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Input',
  'quiz.exercise22.sigmoidYLabel': 'Output sigmoïdefunctie',
  'quiz.exercise22.stepXLabel': 'Input',
  'quiz.exercise22.stepYLabel': 'Output sprongfunctie',
  'quiz.exercise22.identityXLabel': 'Input',
  'quiz.exercise22.identityYLabel': 'Output identiteitsfunctie',
  'quiz.exercise22.textPart1': 'Hieronder staan grafieken voor drie verschillende activeringsfuncties met verschillende eigenschappen. Eerst is er de sigmoïdefunctie, dan de sprongfunctie en tot slot de identiteitsfunctie.',
  // exercise23
  'quiz.exercise23.happyFaces': 'blije gezichten juist ingedeeld',
  'quiz.exercise23.sadFaces': 'trieste gezichten juist ingedeeld',
  // certificate availability
  'certificate.failedToCheck': 'Kan beschikbaarheid certificaat niet controleren',
  'certificate.failedToGenerate': 'Certificaat kan niet worden aangemaakt',
  'certificate.notYetAvailable': 'Na de officiële bevestiging staat uw certificaat hier. Dit kan even duren. Alvast bedankt voor uw geduld.',
  'certificate.updateName': 'Uw certificaat is beschikbaar. Gelieve uw voor- en achternaam bij te werken zodat ze op uw certificaat staan, met behulp van een formulier op uw profielpagina.',
  'certificate.generate': 'Maak uw certificaat hieronder aan.',
  'certificate.generateTextPart1': 'Het certificaat wordt aangemaakt voor',
  'certificate.generateTextPart2': 'Controleer de naam voordat het certificaat wordt aangemaakt, want deze kan achteraf niet worden gewijzigd. U kunt de naam wijzigen met onderstaand formulier.',
  'certificate.generateButton': 'Certificaat aanmaken',
  'certificate.downloadButton': 'Certificaat downloaden',
  'certificate.checkingAvailability': 'Beschikbaarheid certificaat wordt gecontroleerd...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Correct ingedeeld',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Niet correct ingedeeld',
  // section blocks
  'section.block.note': 'Opmerking',
  'section.block.example': 'Voorbeeld',
  'section.block.keyTerminology': 'Belangrijke termen',
  'section.partSummary.spectrumTextPart1': 'Word lid van de ',
  'section.partSummary.spectrum': 'Elements of AI-community',
  'section.partSummary.spectrumTextPart2': ' om dit hoofdstuk te bespreken en vragen te stellen.',
  'section.partSummary.feedbackTitle': 'Geef feedback over dit deel',
  'section.partSummary.feedbackPlaceholder': 'Geef feedback...',
  'section.partSummary.feedbackSuccess': 'Bedankt voor uw feedback!',
  // 404
  'globals.404.title': '404 – Pagina niet gevonden',
  'globals.404.intro': "Deze route bestaat niet... Sorry!",
  'globals.loading': 'Bezig met laden...',
  // footer / menu
  'globals.menu': 'Menu',
  'globals.menu.about': 'Over',
  'globals.menu.faq': 'Veelgestelde vragen',
  'globals.menu.privacyPolicy': 'Privacybeleid',
  'globals.menu.myProfile': 'Mijn profiel',
  'globals.menu.termsofService': 'Servicevoorwaarden',
  // Dashboard/Index
  'dashboard.title': 'Welkom bij de cursus!',
  'dashboard.congratulations.title': 'Gefeliciteerd!',
  'dashboard.congratulations.message': 'U heeft de cursus Elements of AI afgerond',
  'dashboard.congratulations.downloadCertificate': 'Certificaat downloaden',
  'dashboard.notLoggedIn.title': 'Welkom!',
  'dashboard.notLoggedIn.message': 'Registreer of log in voor volledige toegang tot de cursus. Dit is gratis!',
  // Account
  'account.certificate': 'Certificaat',
  'account.myProfile': 'Mijn profiel',
  'account.credits': 'ECTS-studiepunten',
  'account.changePassword': 'Wachtwoord wijzigen',
  'account.changeLanguage': 'Taal wijzigen',
  'account.profileInformation': 'Profiel informatie',
  'account.goToCourse': 'Ga naar de cursus',
  'account.buildingAINew': 'Nieuwe cursus in het Engels',
  'account.accept': 'Accepteren',
  'account.decline': 'Afwijzen',
  'account.courses': 'Cursussen',
  // Sign Up page
  'signUp.description': 'Vul onderstaand formulier in om een nieuw mooc.fi-account te creëren',
  'signUp.email': 'Uw e-mailadres',
  'signUp.firstName': 'Voornaam',
  'signUp.lastName': 'Achternaam',
  'signUp.postalCode': 'Postcode',
  'signUp.country': 'Waar woont u?',
  'signUp.Password': 'Wachtwoord',
  'signUp.confirmPassword': 'Wachtwoord bevestigen',
  'signUp.howToStudyTitle': 'Hoe wilt u studeren?',
  'signUp.6weeksTitle': 'Cursus van 6 weken',
  'signUp.6weeksSubTitle': 'Aanbevolen (ongeveer 5 uur per week)',
  'signUp.6weeksText': 'Een deadline is een beproefde manier om u te helpen de cursus te voltooien.',
  'signUp.selfPacedTitle': 'Eigen tempo',
  'signUp.selfPacedText': 'U kunt de cursus in uw eigen tempo volgen.',
  'signUp.ectsTitle': 'Vraagt u ECTS-studiepunten aan?',
  'signUp.ectsTitleSwe': '',
  'signUp.ectsStudent': 'Ik ben student aan de universiteit van Helsinki. Indien u dit vakje aanvinkt en uw studentnummer invult, krijgt u de ECTS-studiepunten automatisch.',
  'signUp.ectsStudentSwe': '',
  'signup.ectsStudentSweLink': '',
  'signUp.ectsOpenUni': 'Vink dit vakje aan om per e-mail instructies te ontvangen over het aanvragen van ECTS-studiepunten',
  'signUp.studentNumber': 'Studentnummer van de universiteit van Helsinki (als u student bent)',
  'signUp.privacyOptIn': 'Ik geef toestemming om verzamelde gegevens over mijn studiegedrag te gebruiken voor onderzoeksdoeleinden. Deze gegevens bevatten informatie over het gebruik van materialen en de oefeningen van de cursus. Uit publicaties kan de identiteit van personen niet worden afgeleid.',
  'signUp.marketingOptIn': 'Ik ga akkoord met het ontvangen van updates over nieuwe taalversies en informatie over nieuwe cursussen. Ik ga ermee akkoord mijn contactgegevens te delen om op maat gemaakte berichten op platforms van derden te ontvangen. Lees meer in ons privacybeleid.',
  'signUp.privacyLink': 'Lees ons privacybeleid.',
  'signUp.error': 'Er staan fouten in uw formulier. Controleer uw formulier.',
  'signUp.acceptPolicyFirst': 'Ik accepteer het privacybeleid en de servicevoorwaarden.',
  // Sign in page
  'signIn.title': 'Aanmelden',
  'signIn.intro': 'Gebruik onderstaand formulier om u met uw mooc.fi-account aan te melden.',
  'signIn.email': 'Uw e-mailadres',
  'signIn.password': 'Uw wachtwoord',
  'signIn.noAccount': "Hebt u geen account?",
  'signIn.signUpNow': 'Nu registreren',
  // Forgot password
  'pwForget.title': 'Wachtwoord vergeten',
  'pwForget.description': 'Vul het e-mailadres van uw mooc.fi-account in en we sturen u een link waarmee u uw wachtwoord opnieuw kunt instellen.',
  'pwForget.sendResetLink': 'Link verzenden voor het opnieuw instellen van het wachtwoord',
  'pwForget.completedDescription': 'U ontvangt binnenkort een e-mail van mooc.fi met een link waarmee u uw wachtwoord opnieuw kunt instellen. Als u het bericht niet ziet, controleer dan uw spamfilter.',
  'pwForget.problemDescription': 'Als u problemen tegenkomt, neem dan contact op met mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent': 'Er is een e-mailbericht over het opnieuw instellen van het wachtwoord verzonden',
  'pwForget.emailPlaceholder': 'E-mailadres',
  // password change
  'pwChange.authError': 'Authenticatie mislukt. Probeer het opnieuw',
  'pwChange.unexpError': 'Wachtwoord niet bijgewerkt door onverwachte fouten',
  'pwChange.required': 'Verplicht',
  'pwChange.pwMustMatch': 'De wachtwoorden moeten overeenkomen',
  'pwChange.currentPassword': 'Huidig wachtwoord',
  'pwChange.newPassword': 'Nieuw wachtwoord',
  'pwChange.confirmPassword': 'Bevestig nieuw wachtwoord',
  'pwChange.changingPassword': 'Wachtwoord wijzigen...',
  'pwChange.changePassword': 'Wachtwoord wijzigen',
  'pwChange.passwordChanged': 'Wachtwoord gewijzigd',
  // user details
  'userDetails.authError': 'Authenticatie mislukt. Probeer het opnieuw',
  'userDetails.unexpError': 'Profiel niet bijgewerkt door onverwachte fouten',
  'userDetails.required': 'Verplicht',
  'userDetails.invalidEmail': 'Ongeldig e-mailadres',
  'userDetails.email': 'E-mail',
  'userDetails.emailAddress': 'E-mailadres',
  'userDetails.firstName': 'Voornaam',
  'userDetails.lastName': 'Achternaam',
  'userDetails.language': 'Taal',
  'userDetails.updatingProfile': 'Profiel bijwerken...',
  'userDetails.updateProfile': 'Profiel bijwerken',
  'userDetails.profileUpdated': 'Profiel bijgewerkt',
  // languages
  'languages.en.long': 'Engels',
  'languages.fi.long': 'Fins',
  'languages.se.long': 'Zweeds',
  'languages.de.long': 'Duits (Duitsland)',
  'languages.ee.long': 'Ests',
  'languages.nl.long': 'Nederlands (Nederland)',
  'languages.no.long': 'Noors',
  'languages.lv.long': 'Lets',
  'languages.lt.long': 'Litouws',
  'languages.nl-be.long': 'Nederlands (België)',
  'languages.fr.long': 'Frans (Frankrijk)',
  'languages.fr-be.long': 'Frans (België)',
  'languages.it.long': 'Italiaans',
  'languages.mt.long': 'Maltees',
  'languages.pl.long': 'Pools',
  'languages.en-ie.long': 'Engels (Ierland)',
  'languages.bg.long': 'Bulgaars',
  'languages.cs.long': 'Tsjechisch',
  'languages.da.long': 'Deens',
  'languages.en-lu.long': 'Engels (Luxemburg)',
  'languages.hr.long': 'Kroatisch',
  'languages.ro.long': 'Roemeens',
  'languages.sk.long': 'Slowaaks',  
  'languages.pt.long': 'Portugees',
  'languages.es.long': 'Spaans',
  'languages.de-at.long': 'Duits (Oostenrijk)',
  'languages.el.long': 'Grieks',
  'languages.ga.long': 'Iers',
  'languages.sl.long': 'Sloveens',
  'languages.is.long': 'IJslands',
  // Email verfification
  'emailVerification.completedMessage': 'Uw e-mailadres is geverifieerd',
  'emailVerification.verified': 'Geverifieerd',
  // Exercises
  'exercise.loading': 'Oefening laden...',
  'exercise.signUp': 'Registreer om oefeningen te maken:',
  'exercise.score': 'Uw score:',
  'exercise.wrongLocale': 'Verkeerde landinstellingen',
  // Part tempplate
  'partTemplate.intro1': 'In hoofdstuk ',
  'partTemplate.intro2': ' behandelen we de volgende delen. Klik hieronder om te beginnen:',
  'partTemplate.spectrumPart1': 'Neem deel aan de ',
  'partTemplate.spectrum': 'Elements of AI-community',
  'partTemplate.spectrumPart2': ' om dit hoofdstuk te bespreken en vragen te stellen.',
  // missing info
  'missingInfo.title': 'Ontbrekende informatie',
  'missingInfo.body': 'Normaal worden deze vragen gesteld bij registratie, maar u bent ingelogd met een bestaand mooc.fi-account. Vul onderstaand formulier in om door te gaan.',
  'missingInfo.privacyPolicy': 'Lees ons privacybeleid.',
  'missingInfo.error': 'Er staan fouten in uw formulier. Controleer uw formulier.',
  'missingInfo.privacyPolicyUpdateBody': "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
  'missingInfo.privacyPolicyUpdateLink': 'Lees ons privacybeleid hier.',
  // routes
  'routes.LANDING': '/nl/',
  'routes.SIGN_UP': '/nl/signup',
  'routes.SIGN_IN': '/nl/signin',
  'routes.MISSING_INFO': '/nl/missinginfo',
  'routes.PASSWORD_FORGET': '/nl/pw-forget',
  'routes.HOME': '/nl/home',
  'routes.ACCOUNT': '/nl/account',
  'routes.PRIVACY': 'https://www.elementsofai.nl/faq/privacybeleid-1',
  'routes.FAQ': 'https://www.elementsofai.nl/faq',
  'routes.ABOUT': 'https://www.elementsofai.nl',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/nl-nederlands',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/faq/how-can-i-get-2-ects-credits',
  'routes.ACCEPT_POLICY': '/nl/accept-policy',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Kopjes koffie per dag',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Geschreven regels code',
  // meta
  'meta.title': 'Een gratis onlinecursus',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Leer meer over de Universiteit van Helsinki en de AI-cursus van Reaktor voor studenten en werkenden - geen kennis van programmeren of wiskunde vereist',
  'meta.sitename': 'Elements of AI',
  'meta.title.og': 'A free online introduction to artificial intelligence for non-experts',
  // ECTS
  'ects.text': 'Na afloop van de cursus krijgt u instructies voor het aanvragen van ECTS-studiepunten.',
  'ects.textCompleted': 'Als u een Fins socialezekerheidsnummer hebt en punten wilt aanvragen, klik dan',
  'ects.link': 'hier.',
  'ects.info': 'Wilt u ECTS-credits aanvragen?',
  'ects.readMore': 'Lees verder',
  // Countries
  'countryselect.select': "Selecteer alstublieft",
  'Finland': "Finland",
  'Estonia': "Estland",
  'Germany': "Duitsland",
  'Sweden': "Zweden",
  "Norway": "Noorwegen",
  "Latvia": "Letland",
  "Netherlands": "Nederland",
  "Countries": {
    "Afganistan": "Afganistan",
    "Åland Islands": "Aland-eilanden",
    "Albania": "Albanië",
    "Algeria": "Algerije",
    "American Samoa": "Amerikaans Samoa",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antarctica": "Antarctica",
    "Antigua and Barbuda": "Antigua en Barbuda",
    "Argentina": "Argentinië",
    "Armenia": "Armenië",
    "Aruba": "Aruba",
    "Australia": "Australië",
    "Austria": "Oostenrijk",
    "Azerbaijan": "Azerbeidzjan",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahrein",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbados",
    "Belarus": "Wit-Rusland",
    "Belgium": "België",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermuda",
    "Bhutan": "Bhutan",
    "Bolivia": "Bolivia",
    "Bosnia and Herzegovina": "Bosnië-Herzegovina",
    "Botswana": "Botswana",
    "Bouvet Island": "Bouvet Island",
    "Brazil": "Brazilië",
    "British Indian Ocean Territory": "Brits-Indisch oceaan gebied",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgarije",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambodia": "Cambodja",
    "Cameroon": "Kameroen",
    "Canada": "Canada",
    "Cape Verde": "Kaapverdië",
    "Cayman Islands": "Kaaiman Eilanden",
    "Central African Republic": "Centraal Afrikaanse Republiek",
    "Chad": "Tsjaad",
    "Chile": "Chili",
    "China": "China",
    "Christmas Island": "Kersteiland",
    "Cocos (Keeling) Islands": "Cocos (Keeling) eilanden",
    "Colombia": "Colombia",
    "Comoros": "Comoren",
    "Congo": "Congo",
    "Congo, The Democratic Republic of The": "Congo, de Democratische Republiek",
    "Cook Islands": "Cookeilanden",
    "Costa Rica": "Costa Rica",
    "Cote D'ivoire": "Ivoorkust",
    "Croatia": "Kroatië",
    "Cuba": "Cuba",
    "Cyprus": "Cyprus",
    "Czech Republic": "Tsjechische Republiek",
    "Denmark": "Denemarken",
    "Djibouti": "Djibouti",
    "Dominica": "Dominica",
    "Dominican Republic": "Dominicaanse Republiek",
    "Ecuador": "Ecuador",
    "Egypt": "Egypte",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Equatoriaal-Guinea",
    "Eritrea": "Eritrea",
    "Estonia": "Estland",
    "Ethiopia": "Ethiopië",
    "Falkland Islands (Malvinas)": "Falklandeilanden (Malvinas)",
    "Faroe Islands": "Faeröer",
    "Fiji": "Fiji",
    "Finland": "Finland",
    "France": "Frankrijk",
    "French Guiana": "Frans-Guyana",
    "French Polynesia": "Frans-Polynesië",
    "French Southern Territories": "Franse zuidelijke gebieden",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Georgië",
    "Germany": "Duitsland",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Griekenland",
    "Greenland": "Groenland",
    "Grenada": "Grenada",
    "Guadeloupe": "Guadeloupe",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea": "Guinea",
    "Guinea-Bissau": "Guinee-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haïti",
    "Heard Island and Mcdonald Islands": "Heard Island en Mcdonald Islands",
    "Holy See (Vatican City State)": "Heilige Stoel (Vaticaanstad)",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Hongarije",
    "Iceland": "IJsland",
    "India": "India",
    "Indonesia": "Indonesië",
    "Iran, Islamic Republic of": "Iran, Islamitische Republiek",
    "Iraq": "Irak",
    "Ireland": "Ierland",
    "Isle of Man": "Isle of Man",
    "Israel": "Israël",
    "Italy": "Italië",
    "Jamaica": "Jamaica",
    "Japan": "Japan",
    "Jersey": "Jersey",
    "Jordan": "Jordanië",
    "Kazakhstan": "Kazachstan",
    "Kenya": "Kenia",
    "Kiribati": "Kiribati",
    "Korea, Democratic People's Republic of": "Korea, Democratische Volksrepubliek",
    "Korea, Republic of": "Korea, republiek van",
    "Kosovo": "Kosovo",
    "Kuwait": "Koeweit",
    "Kyrgyzstan": "Kirgizië",
    "Lao People's Democratic Republic": "Lao Democratische Volksrepubliek",
    "Latvia": "Letland",
    "Lebanon": "Libanon",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libyan Arab Jamahiriya": "Libië",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Litouwen",
    "Luxembourg": "Luxemburg",
    "Macao": "Macao",
    "Macedonia, The Former Yugoslav Republic of": "Macedonië, de Voormalige Joegoslavische Republiek",
    "Madagascar": "Madagascar",
    "Malawi": "Malawi",
    "Malaysia": "Maleisië",
    "Maldives": "Maldiven",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Marshall eilanden",
    "Martinique": "Martinique",
    "Mauritania": "Mauritanië",
    "Mauritius": "Mauritius",
    "Mayotte": "Mayotte",
    "Mexico": "Mexico",
    "Micronesia, Federated States of": "Micronesië, Federale Staten van",
    "Moldova, Republic of": "Moldavië, Republiek",
    "Monaco": "Monaco",
    "Mongolia": "Mongolië",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Marocco": "Marokko",
    "Mozambique": "Mozambique",
    "Myanmar": "Myanmar",
    "Namibia": "Namibië",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Nederland",
    "Netherlands Antilles": "Nederlandse Antillen",
    "New Caledonia": "Nieuw-Caledonië",
    "New Zealand": "Nieuw-Zeeland",
    "Nicaragua": "Nicaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "Norfolk Island": "Norfolkeiland",
    "Northern Mariana Islands": "noordelijke Mariana eilanden",
    "Norway": "Noorwegen",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestinian Territory, Occupied": "Bezet Palestijns gebied",
    "Panama": "Panama",
    "Papua New Guinea": "Papoea-Nieuw-Guinea",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Filippijnen",
    "Pitcairn": "Pitcairn",
    "Poland": "Polen",
    "Portugal": "Portugal",
    "Puerto Rico": "Puerto Rico",
    "Qatar": "Qatar",
    "Reunion": "Réunion",
    "Romania": "Roemenië",
    "Russian Federation": "Russische Federatie",
    "Rwanda": "Rwanda",
    "Saint Helena": "Sint-Helena",
    "Saint Kitts and Nevis": "Saint Kitts en Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Pierre and Miquelon": "Saint Pierre en Miquelon",
    "Saint Vincent and The Grenadines": "Saint Vincent en de Grenadines",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "Sao Tome en Principe",
    "Saudi Arabia": "Saoedi-Arabië",
    "Senegal": "Senegal",
    "Serbia": "Servië",
    "Seychelles": "Seychellen",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapore",
    "Slovakia": "Slowakije",
    "Slovenia": "Slovenië",
    "Solomon Islands": "Solomon eilanden",
    "Somalia": "Somalië",
    "South Africa": "Zuid-Afrika",
    "South Georgia and The South Sandwich Islands": "Zuid-Georgië en de Zuidelijke Sandwicheilanden",
    "Spain": "Spanje",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Soedan",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard en Jan Mayen",
    "Swaziland": "Swaziland",
    "Sweden": "Zweden",
    "Swizerland": "Zwitserland",
    "Syrian Arab Republic": "Syrische Arabische Republiek",
    "Taiwan": "Taiwan",
    "Tajikistan": "Tadzjikistan",
    "Tanzania, United Republic of": "Tanzania, Verenigde Republiek",
    "Thailand": "Thailand",
    "Timor-leste": "Oost-Timor",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad en Tobago",
    "Tunisia": "Tunesië",
    "Turkey": "Turkije",
    "Turkmenistan": "Turkmenistan",
    "Turks and Caicos Islands": "Turks- en Caicoseilanden",
    "Tuvalu": "Tuvalu",
    "Uganda": "Oeganda",
    "Ukraine": "Oekraïne",
    "United Arab Emirates": "Verenigde Arabische Emiraten",
    "United Kingdom": "Verenigd Koningkrijk",
    "United States": "Verenigde Staten",
    "United States Minor Outlying Islands": "Kleine afgelegen eilanden van de Verenigde Staten",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Oezbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela": "Venezuela",
    "Viet Nam": "Vietnam",
    "Virgin Islands, British": "Britse Maagdeneilanden",
    "Virgin Islands, U.S.": "Amerikaanse Maagdeneilanden",
    "Wallis and Futuna": "Wallis en Futuna",
    "Western Sahara": "Westelijke Sahara",
    "Yemen": "Jemen",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe"
  },

  //Alt texts
  'alt.example-1': 'twee zelfrijdende auto’s',
  'alt.example-2': 'hand met een mobieltje',
  'alt.example-3': 'gezicht van een vrouw',
  'alt.defining-ai': 'woorden die met AI te maken hebben',
  'alt.turing-test': 'man die via de computer met een robot en een vrouw chat',
  'alt.chinese-room': 'het Chinese kamerexperiment',
  'alt.chicken-crossing': 'robot in een boot die naar een kip, een vos en een zak kippenvoer kijkt',
  'alt.a-to-b': 'hand met een mobieltje met een navigatieapp',
  'alt.tic-tac-toe': 'twee kinderen die boter-kaas-en-eieren spelen',
  'alt.self-driving-car': 'zelfrijdende auto',
  'alt.spam-or-ham': 'normale e-mail en spam',
  'alt.mnist': 'MNIST-dataset',
  'alt.nearest-neighbor': 'twee winkelende mensen',
  'alt.nearest-neighbor-graph': 'de naaste-buurclassificatie',
  'alt.recommendation': 'een man en vrouw die via hun telefoon naar muziek luisteren',
  'alt.price-real-estate': 'drie verschillende huizen',
  'alt.supervised-learning': 'mens die een robot lesgeeft',
  'alt.dice': 'dobbelsteen',
  'alt.chess': 'schaakbord',
  'alt.poker': 'hand met speelkaarten',
  'alt.eyes': 'drie personen met bruine ogen en één met blauwe ogen',
  'alt.search-1': 'enkele weg die naar een doel leidt',
  'alt.search-2': 'vier verschillende wegen die naar hetzelfde doel leiden',
  'alt.chicken-crossing-1': 'roeibootpuzzel begintoestand',
  'alt.chicken-crossing-2': 'roeibootpuzzel eerste overgang',
  'alt.chicken-crossing-3': 'roeibootpuzzel alle overgangen',
  'alt.chicken-crossing-4': 'roeibootpuzzel met de beste manier',
  'alt.chicken-crossing-5': 'roeibootpuzzel begintoestand	',
  'alt.implication-1': 'een man en een vrouw',
  'alt.implication-2': 'ogen',
  'alt.implication-3': 'toezichtcamera',
  'alt.implication-4': 'robot die op een laptop werkt',
  'alt.oddchange': 'zon en wolken',
  'alt.brain': 'tekening van hersenen',
  'alt.carstop': 'zelfrijdende auto',
  'alt.gan': 'robot met een plaatje van een kat en van een stopbord in de hand',
  'alt.hammock': 'robot die man in hangmat koelte toewaait',
  'alt.game-tree-1': 'spelverloop 1',
  'alt.game-tree-2': 'spelverloop 2',
  'alt.game-tree-3': 'spelverloop 3',
  'alt.game-tree-4': 'spelverloop 4',
  'alt.bayes-rule-1': 'beeld van valse positieven en negatieven van een kankeronderzoek',
  'alt.bayes-rule-2': 'beeld van valse positieven en negatieven van een kankeronderzoek',
  'alt.defineai': 'woorden die met AI te maken hebben',
  'alt.winter': 'robot die in de sneeuw slaapt',
  'alt.terminator': 'enge robot',
}

