// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'is',
  'globals.signIn': 'Innskráning',
  'globals.logIn': 'Innskráning',
  'globals.signUp': 'Skráning í námskeið',
  'globals.signOut': 'Útskráning',
  'globals.logOut': 'Útskráning',
  'globals.submit': 'Senda',
  'globals.submitting': 'Sendi...',
  'globals.yourEmail': 'Netfangið þitt',
  'globals.yourPassword': 'Lykilorðið þitt',
  'globals.courseOverview': 'Námskeiðsyfirlit',
  'globals.forgotPassword': 'Manstu ekki lykilorðið?',
  'globals.invalidCredentials': 'Ógilt notandanafn og/eða lykilorð',
  'globals.section': 'Kafli',
  'globals.exercises': 'Æfingar',
  'globals.chapter': 'Hluti',
  'globals.introToAI': 'Inngangur að gervigreind',
  'globals.buildingAI': 'Að smíða gervigreind',
  'globals.acceptPolicy': 'Samþykkja persónuverndarstefnu',
  // section
  'section.footerEndOfTheChapter': 'Þú ert komin(n) að lokum {chapter}. hluta!',
  'section.footerEndOfTheCourseTitle': 'Þú ert komin(n) að lokum námskeiðsins!',
  'section.footerEndOfTheCourseText': 'Þegar þú hefur lokið öllum æfingunum og gefið umsagnir um svör annarra förum við yfir svörin þín.',
  'section.footerEndOfTheCourseBAI': 'Haltu ferðinni áfram og lærðu hvaða aðferðir þú getur notað til að gera fyrstu gervigreindarhugmyndina þína að veruleika.',
  'section.nextSection': 'Næsti kafli',
  'section.nextChapter': 'Næsti hluti',
  'section.start': 'Byrja',
  'section.logisticRegressionExample.slope': 'Halli',
  'section.logisticRegressionExample.intercept': 'Ássnið',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Bíður umsagnar frá þér',
  'quiz.rejectedPeerReview': 'Neikvæð umsögn annarra nemenda',
  'quiz.unsubmittedAnswer': 'Svarið hefur ekki verið sent',
  'quiz.pendingPeerReview': 'Bíður umsagnar frá öðrum nemendum',
  // quiz
  'quiz.status.answered': 'Svarað',
  'quiz.status.rejected': 'Svarið fékk neikvæða umsögn — reyndu aftur',
  'quiz.status.unanswered': 'Ósvarað',
  'quiz.status.exercisesCompleted': 'Æfingum lokið',
  'quiz.status.correctAnswers': 'Rétt svör',
  'quiz.message.rejectedInReview': 'Svarið sem þú gafst fékk neikvæða umsögn. Vinsamlega reyndu aftur.',
  'quiz.youAnswered': 'Svar þitt var',
  'quiz.yourAnswer': 'Svar þitt...',
  'quiz.yourScore': 'Einkunn:',
  'quiz.quizNotLoaded': 'Spurningarnar hlóðust ekki',
  'quiz.words': 'Orðafjöldi:',
  'quiz.exampleAnswer': 'Dæmi um svar:',
  // peer review
  'peerreview.question1': 'Svarið er efnislega í samræmi við spurninguna',
  'peerreview.question2': 'Svarið er ítarlegt',
  'peerreview.question3': 'Svarið er vel rökstutt',
  'peerreview.question4': 'Svarið er skýrt fram sett',
  'peerreview.thankYou': 'Kærar þakkir fyrir umsagnirnar!',
  'peerreview.giveMoreReviews': 'Ef þú getur komið því við',
  'peerreview.giveMoreReviewsButton': 'skaltu gefa fleiri umsagnir.',
  'peerreview.notEnoughAnswers': 'Hér á að birtast umsögn annarra þátttakenda í námskeiðinu en fáir hafa leyst þessa æfingu enn sem komið er.',
  'peerreview.selectAnswerMessage': 'Nú þarftu að gefa umsögn um svör annarra! Hér fyrir neðan sérðu nokkrar úrlausnir frá öðrum nemendum. Veldu eina þeirra og gefðu umsögn um hana. Gefðu umsögn um að minnsta kosti þrjár úrlausnir.',
  'peerreview.minimumPeerReviewWarning': 'Þú færð úrlausnirnar þínar ekki samþykktar fyrr en þú hefur gefið að minnsta kosti þrjár umsagnir um úrlausnir annarra.',
  'peerreview.select': 'Velja',
  'peerreview.reportSpam': 'Senda tilkynningu um ógilt svar',
  'peerreview.feedback': 'Svarið þitt hefur verið yfirfarið og samþykkt. Hér fyrir neðan sést hvað aðrir nemendur í námskeiðinu höfðu um það að segja:',
  // exercise17
  'quiz.exercise17.xLabel': 'Lengd skólagöngu (ár)',
  'quiz.exercise17.yLabel': 'Lífslíkur (ár)',
  'quiz.exercise17.textPart1': 'Skoðum nú tengslin milli skólagöngu eins og hún er talin í árum (allt frá leikskóla til háskóla) og lífslíkna. Punktarnir þrír á grafinu sýna tölur frá þremur mismunandi löndum:',
  'quiz.exercise17.textPart2': 'Í einu landinu er skólagangan að meðaltali 10 ár og lífslíkurnar 57 ár, í öðru er skólagangan að meðaltali 13 ár og lífslíkurnar 53 ár, og í þriðja landinu er skólagangan að meðaltali 20 ár og lífslíkurnar 80 ár.',
  'quiz.exercise17.textPart3': 'Þú getur látið línuna á myndinni falla betur að legu punktanna með því að draga endana á henni til. Gættu að því að línan getur aldrei fallið fullkomlega að gögnunum; óhjákvæmilegt er að einn eða fleiri punktar lendi fyrir ofan eða neðan línuna. Mestu skiptir að línan sýni í stórum dráttum sambandið sem lesa má út úr gögnunum.',
  'quiz.exercise17.textPart4': 'Þegar þú hefur dregið línuna á sinn stað getur þú notað hana til að spá fyrir um lífslíkur.',
  'quiz.exercise17.textPart5': 'Ef við byggjum niðurstöðuna eingöngu á þessum gögnum, hverjar eru þá lífslíkur fólks sem á að jafnaði 15 ára skólagöngu að baki? Taktu vel eftir því að þótt þú getir með þessum hætti búið til spá sem tilgreinir lífslíkurnar allt niður í brot úr ári er alls óvíst að sú spá verði áreiðanleg. Hafðu í huga hversu lítið af gögnum við höfum hér í höndunum.',
  // exercise18
  'quiz.exercise18.xLabel': 'Lengd skólagöngu (ár)',
  'quiz.exercise18.yLabel': 'Lífslíkur (ár)',
  'quiz.exercise18.textPart1': 'IÍ æfingunni hér á undan notuðum við aðeins tölur frá þremur löndum. Á næsta grafi eru sýnd gögn frá 14 mismunandi löndum:',
  'quiz.exercise18.textPart2': 'Breytir það einhverju um fyrri spána að við höfum nú meira af gögnum til að moða úr? Og ef svo er, hver er þá ástæðan?',
  'quiz.exercise18.textPart3': 'Hvert af eftirtöldum svörum kemst næst því að meta rétt lífslíkur fólks með 15 ára skólagöngu að baki? Veldu nákvæmasta svarið sem þú telur hægt að réttlæta með vísan til þess hversu vel beina línan fellur að gögnunum.',
  // exercise19
  'quiz.exercise19.xLabel': 'Undirbúningstími (klst.)',
  'quiz.exercise19.yLabel': 'Líkindi þess að standast prófið',
  'quiz.exercise19.textPart1': 'Hver punktur á myndinni samsvarar einum nemanda. Kvarðinn neðst á myndinni sýnir hversu mörgum klukkustundum nemandinn varði í undirbúning fyrir prófið. Nemendur sem stóðust prófið eru sýndir sem punktar efst á myndinni, en þeir sem féllu í prófinu eru sýndir neðst. Kvarðann vinstra megin notum við til að sýna líkindin á að standast prófið, en þau fáum við með tvíkosta aðhvarfsgreining eins og útskýrt er hér fyrir neðan. Myndin sýnir að nemendur sem eyddu miklum tíma í undirbúning stóðu almennt betur að vígi en hinir. Punktarnir yst á hvorum enda sýna þetta skýrast. Ef undirbúningurinn er minni en ein klukkustund er afar erfitt að standast prófið, en með með mikilli vinnu ná flestir fullnægjandi einkunn. Hvað getum við sagt um nemendur sem eru einhvers staðar þarna á milli? Hversu líklegt er að nemandi standist prófið ef hann hefur eytt 6 tímum í undirbúninginn?',
  'quiz.exercise19.textPart2': 'Við getum áætlað þær líkur með tvíkosta aðhvarfsgreiningu. Ferilinn á myndinni má túlka sem líkindin á að standast prófið. Þannig sjáum við til dæmis að með fimm klukkustunda undirbúningi eru líkindin rúmlega 20%. Við skýrum það ekki hér hvernig ferillinn er reiknaður út, en það gerist á svipaðan hátt og þegar vægin í línulegri aðhvarfsgreiningu eru fundin.',
  'quiz.exercise19.textPart3': 'Hversu löngum tíma þyrftum við að eyða í undirbúning, samkvæmt því sem ferillinn á myndinni sýnir, til þess að tryggja okkur 80% líkur á að standast prófið?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Inntak',
  'quiz.exercise22.sigmoidYLabel': 'Úttak S-falls',
  'quiz.exercise22.stepXLabel': 'Inntak',
  'quiz.exercise22.stepYLabel': 'Úttak þrepafalls',
  'quiz.exercise22.identityXLabel': 'Inntak',
  'quiz.exercise22.identityYLabel': 'Úttak samsemdarfalls',
  'quiz.exercise22.textPart1': 'Myndirnar hér fyrir neðan sýna þrjú mismunandi virkjunarföll með ólíkum eiginleikum: S-fall, þrepafall og samsemdarfall.',
  // exercise23
  'quiz.exercise23.happyFaces': 'rétt flokkaðir broskallar',
  'quiz.exercise23.sadFaces': 'rétt flokkaðir fýlukallar',
  // certificate availability
  'certificate.failedToCheck': 'Ekki tókst að athuga hvort skírteini er til',
  'certificate.failedToGenerate': 'Ekki tókst að búa til skírteini',
  'certificate.notYetAvailable': 'Skírteinið þitt birtist hér þegar við höfum staðfest úrlausnir þínar á æfingunum. Það getur tekið nokkurn tíma. Kærar þakkir fyrir þolinmæðina!',
  'certificate.updateName': 'Skírteinið þitt er tilbúið! Gerðu viðeigandi breytingar á notandaupplýsingunum þínum svo að nafnið verði rétt á skírteininu.',
  'certificate.generate': 'Smelltu hér fyrir neðan til að búa til skírteinið.',
  'certificate.generateTextPart1': 'Skírteini verður búið til fyrir notandann',
  'certificate.generateTextPart2': 'Staðfestu að nafnið sé rétt áður en skírteinið er búið til. Þú getur ekki breytt nafninu eftirá. Ef þú vilt breyta rithætti nafnsins getur þú gert það hér fyrir neðan.',
  'certificate.generateButton': 'Búa til skírteini',
  'certificate.downloadButton': 'Sækja skírteini',
  'certificate.checkingAvailability': 'Athuga hvort skírteini er til…',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Rétt flokkað',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Rangt flokkað',
  // section blocks
  'section.block.note': 'Til athugunar',
  'section.block.example': 'Dæmi',
  'section.block.keyTerminology': 'Grunnhugtök',
  'section.partSummary.spectrumTextPart1': 'Skráðu þig í ',
  'section.partSummary.spectrum': 'notendahópinn fyrir Elements of AI',
  'section.partSummary.spectrumTextPart2': ' til að ræða við aðra notendur um efnið og spyrja spurninga.',
  'section.partSummary.feedbackTitle': 'Láttu okkur vita hvað þér fannst um þennan hluta námskeiðsins',
  'section.partSummary.feedbackPlaceholder': 'Skrifaðu athugasemdir þínar hér...',
  'section.partSummary.feedbackSuccess': 'Kærar þakkir fyrir framlagið!',
  // 404
  'globals.404.title': '404 – Síðan er ekki til',
  'globals.404.intro': "Þessi leið er því miður ófær...",
  'globals.loading': 'Hleð...',
  // footer / menu
  'globals.menu': 'Valmynd',
  'globals.menu.about': 'Um námskeiðið',
  'globals.menu.faq': 'Spurt og svarað',
  'globals.menu.privacyPolicy': 'Persónuverndarstefna',
  'globals.menu.termsofService': 'Skilmálar',
  'globals.menu.myProfile': 'Notandasíða',
  // Dashboard/Index
  'dashboard.title': 'Velkomin(n) á námskeiðið!',
  'dashboard.congratulations.title': 'Til hamingju!',
  'dashboard.congratulations.message': 'Þú hefur lokið námskeiðinu Elements of AI',
  'dashboard.congratulations.downloadCertificate': 'Sækja skírteini',
  'dashboard.notLoggedIn.title': 'Velkomin(n)!',
  'dashboard.notLoggedIn.message': 'Skráðu þig sem nýjan notanda eða skráðu þig inn á síðuna til að byrja á námskeiðinu. Það er ókeypis!',
  // Account
  'account.certificate': 'Skírteini',
  'account.myProfile': 'Notandasíða',
  'account.profileInformation': 'Notandaupplýsingar',
  'account.credits': 'ECTS-einingar',
  'account.changePassword': 'Breyta lykilorði',
  'account.changeLanguage': 'Velja tungumál',
  'account.goToCourse': 'Byrja námskeið',
  'account.buildingAINew': 'Nýtt námskeið á ensku',
  'account.accept': 'Samþykkja',
  'account.decline': 'Afþakka',
  'account.courses': 'Námskeið',
  // Sign Up page
  'signUp.description': 'Þú getur stofnað nýjan notandareikning á mooc.fi hér fyrir neðan.',
  'signUp.email': 'Netfangið þitt',
  'signUp.firstName': 'Eiginnafn',
  'signUp.lastName': 'Eftirnafn',
  'signUp.postalCode': 'Postal code',
  'signUp.country': 'Hvar áttu heima?',
  'signUp.Password': 'Lykilorð',
  'signUp.confirmPassword': 'Staðfesta lykilorð',
  'signUp.howToStudyTitle': 'Hvernig viltu stunda námið?',
  'signUp.6weeksTitle': '6 vikna námskeið',
  'signUp.6weeksSubTitle': 'Heppilegasta leiðin fyrir flesta (áætlað vinnuframlag um 5 klst. á viku)',
  'signUp.6weeksText': 'Reynslan hefur sýnt að nemendur sem fylgja ákveðinni áætlun eiga auðveldara með að ljúka námskeiðinu.',
  'signUp.selfPacedTitle': 'Nám á þínum hraða',
  'signUp.selfPacedText': 'Þú getur stundað námið á þínum eigin hraða.',
  'signUp.ectsTitle': 'Viltu fá námskeiðið viðurkennt til ECTS-eininga?',
  'signUp.ectsTitleSwe': 'Viltu fá námskeiðið viðurkennt til ECTS-eininga?',
  'signUp.ectsStudent': 'I am a student at University of Helsinki. If you check this box and fill in your student number you will automatically get the ECTS credits.',
  'signUp.ectsStudentSwe': 'After completing the course, you can apply for ECTS credits from Linköping University. You can find the instructions ',
  'signup.ectsStudentSweLink': 'here.',
  'signUp.ectsOpenUni': 'Merktu við hér ef þú vilt fá sendar upplýsingar um það sem gera þarf til að fá námskeiðið metið til ECTS-eininga.',
  'signUp.studentNumber': 'Númer námsmannaskírteinis við Háskólann í Helsinki (ef þú ert nemandi þar)',
  'signUp.privacyOptIn': 'Ég heimila að gögnum um hvernig stundaði námið verði safnað í rannsóknaskyni. Um er að ræða upplýsingar um lestur námsefnisins og úrlausn æfinga. Ef til birtingar kemur verða gögnin með öllu ópersónugreinanleg.',
  'signUp.marketingOptIn': 'Ég samþykki að mér verði sendar upplýsingar þegar námskeiðið kemur út á nýjum tungumálum og um önnur námskeið sem bætast við. Ég samþykki að deila tengiliðaupplýsingunum mínum til að fá sérsniðin skilaboð á vettvangi þriðja aðila. Lestu meira í persónuverndarstefnu okkar.',
  'signUp.acceptPolicyFirst': 'Ég samþykki persónuverndarstefnuna og skilmálana.',
  'signUp.privacyLink': 'Upplýsingar um persónuverndarstefnu okkar eru hér',
  'signUp.error': 'Það eru villur í upplýsingunum. Farðu aftur yfir upplýsingarnar sem þú skráðir.',
  // Sign in page
  'signIn.title': 'Innskráning',
  'signIn.intro': 'Þú getur skráð þig inn hér fyrir neðan með notandanafninu þínu á mooc.fi',
  'signIn.email': 'Netfangið þitt',
  'signIn.password': 'Lykilorðið þitt',
  'signIn.noAccount': "Vantar þig notandareikning?",
  'signIn.signUpNow': 'Skráðu þig hér',
  // Forgot password
  'pwForget.title': 'Manstu ekki lykilorðið',
  'pwForget.description': 'Skrifaðu inn netfangið sem er tengt notandareikningnum þínum á mooc.fi og við sendum þér slóð til að breyta lykilorðinu.',
  'pwForget.sendResetLink': 'Senda slóð til að breyta lykilorði',
  'pwForget.completedDescription': 'Þú færð fljótlega tölvupóst frá mooc.fi með slóð til að breyta lykilorðinu. Skoðaðu ruslpóstinn ef skeytið birtist ekki í innhólfinu.',
  'pwForget.problemDescription': 'Sendu okkur fyrirspurn á mooc@cs.helsinki.fi ef þú lendir í vandræðum með eitthvað.',
  'pwForget.resetEmailSent': 'Tölvupóstur með slóð til að breyta lykilorði hefur verið sendur.',
  'pwForget.emailPlaceholder': 'Netfang',
  // password change
  'pwChange.authError': 'Innskráning mistókst. Vinsamlega reyndu aftur.',
  'pwChange.unexpError': 'Óvænt villa – lykilorði ekki breytt',
  'pwChange.required': 'Má ekki vanta',
  'pwChange.pwMustMatch': 'Þú verður að slá lykilorðið inn eins í bæði skiptin',
  'pwChange.currentPassword': 'Núverandi lykilorð',
  'pwChange.newPassword': 'Nýtt lykilorð',
  'pwChange.confirmPassword': 'Staðfesta nýtt lykilorð',
  'pwChange.changingPassword': 'Breyti lykilorði...',
  'pwChange.changePassword': 'Breyta lykilorði',
  'pwChange.passwordChanged': 'Lykilorði breytt',
  // user details
  'userDetails.authError': 'Innskráning mistókst. Vinsamlega reyndu aftur.',
  'userDetails.unexpError': 'Óvænt villa – notandaupplýsingar ekki uppfærðar',
  'userDetails.required': 'Má ekki vanta',
  'userDetails.invalidEmail': 'Ógilt netfang',
  'userDetails.email': 'Netfang',
  'userDetails.emailAddress': 'Netfang',
  'userDetails.firstName': 'Eiginnafn',
  'userDetails.lastName': 'Kenninafn',
  'userDetails.language': 'Tungumál',
  'userDetails.updatingProfile': 'Uppfæri notandaupplýsingar...',
  'userDetails.updateProfile': 'Uppfæra notandaupplýsingar',
  'userDetails.profileUpdated': 'Notandaupplýsingar uppfærðar',
  // languages
  'languages.en.long': 'enska',
  'languages.fi.long': 'finnska',
  'languages.se.long': 'sænska',
  'languages.de.long': 'þýska',
  'languages.ee.long': 'eistneska',
  'languages.it.long': 'ítölska',
  'languages.fr.long': 'frönska',
  'languages.no.long': 'norska',
  'languages.lv.long': 'lettneska',
  'languages.mt.long': 'maltneska',
  'languages.fr-be.long': 'frönska (Belgía)',
  'languages.lt.long': 'litháíska',
  'languages.nl-be.long': 'hollenska (Belgía)',
  'languages.en-ie.long': 'enska (Írland)',
  'languages.pl.long': 'pólska',
  'languages.da.long': 'danska',
  'languages.ro.long': 'rúmenska',
  'languages.hr.long': 'króatíska',
  'languages.sk.long': 'slóvakíska',
  'languages.en-lu.long': 'enska (Lúxemborg)',
  'languages.bg.long': 'búlgarska',
  'languages.cs.long': 'tékkneska',
  'languages.nl.long': 'hollenska',
  'languages.pt.long': 'portúgalska',
  'languages.el.long': 'gríska',
  'languages.de-at.long': 'þýska (Austurríki)',
  'languages.es.long': 'spænska',
  'languages.is.long': 'íslenska',
  'languages.sl.long': 'slóvenska',
  'languages.ga.long': 'írska',
  // Email verfification
  'emailVerification.completedMessage': 'Netfangið þitt hefur verið staðfest',
  'emailVerification.verified': 'Staðfest',
  // Exercises
  'exercise.loading': 'Sæki æfingu...',
  'exercise.signUp': 'Skráðu þig sem notanda til að geta leyst æfingarnar',
  'exercise.score': 'Einkunn:',
  'exercise.wrongLocale': 'Rangt tungumál',
  // Part tempplate
  'partTemplate.intro1': 'Í hluta ',
  'partTemplate.intro2': ' eru eftirtaldir þrír kaflar. Smelltu hér fyrir neðan til að byrja lesturinn:',
  'partTemplate.spectrumPart1': 'Skráðu þig í ',
  'partTemplate.spectrum': 'notendahópinn fyrir Elements of AI',
  'partTemplate.spectrumPart2': ' til að ræða við aðra notendur um efnið og spyrja spurninga.',
  // missing info
  'missingInfo.title': 'Upplýsingar vantar',
  'missingInfo.body': 'Við biðjum venjulega um þessar upplýsingar þegar fólk býr til notandareikning fyrir námskeiðið, en þú skráðir þig inn með eldri reikningi á mooc.fi. Ljúktu skráningunni með því að skrá upplýsingarnar sem beðið er um hér fyrir neðan.',
  'missingInfo.privacyPolicy': 'Upplýsingar um persónuverndarstefnu okkar eru hér',
  'missingInfo.error': 'Það eru villur í upplýsingunum. Farðu aftur yfir upplýsingarnar sem þú skráðir.',
  'missingInfo.privacyPolicyUpdateBody': 'Við höfum uppfært persónuverndarstefnu okkar. Hér er yfirlit yfir breytingarnar. Við auglýsum námskeiðin okkar fyrir hugsanlegum nýnemum með því að mynda áhorfendur á markaðsvettvangi. Þetta getur falið í sér að deila netföngum með þessum kerfum. Í sama tilgangi verður nafnlausum gögnum um starfsemi deilt með markaðsnetum. Þú getur komið í veg fyrir deilingu upplýsinga með tölvupósti með því að taka hakið úr gátreitnum fyrir markaðsleyfi í námskeiðssniðinu. Til að koma í veg fyrir samnýtingu virknigagna geturðu breytt vafrakökustillingunum á námskeiðssíðunni. Samþykki þessara skilmála er nauðsynlegt til að halda áfram að nota þjónustuna.',
  'missingInfo.privacyPolicyUpdateLink': 'Þú getur lesið persónuverndarstefnuna hér.',
  // routes
  'routes.LANDING': '/is',
  'routes.SIGN_UP': '/is/signup',
  'routes.SIGN_IN': '/is/signin',
  'routes.MISSING_INFO': '/is/missinginfo',
  'routes.PASSWORD_FORGET': '/is/pw-forget',
  'routes.HOME': '/is/home',
  'routes.ACCOUNT': '/is/account',
  'routes.ACCEPT_POLICY': '/is/accept-policy',
  'routes.PRIVACY': 'https://www.elementsofai.is/faq/pers%C3%B3nuverndarstefna',
  'routes.FAQ': 'https://www.elementsofai.is/faq',
  'routes.ABOUT': 'https://www.elementsofai.is/',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/is-island',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/faq/how-can-i-get-ects-credits',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Kaffibollar á dag',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Skrifaðar línur af kóða',
  // meta
  'meta.title': 'Ókeypis vefnámskeið',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Kynntu þér betur námskeið háskólans í Helsinki og fyrirtækisins Reaktor fyrir námsmenn og fólk í atvinnulífinu. Þú þarft aðeins að kunna skil á einföldum reikniaðgerðum og námskeiðið krefst engrar forritunarkunnáttu.',
  'meta.sitename': 'Elements of AI',
  // ECTS
  'ects.text': 'Leiðbeiningar um hvernig sótt er um ECTS-einingar birtast hér þegar þú hefur lokið námskeiðinu.',
  'ects.textCompleted': 'Ef þú hefur finnska kennitölu getur þú sótt um að fá námskeiðið viðurkennt til eininga með því að smella ',
  'ects.link': 'hér.',
  'ects.info': 'Viltu fá námskeiðið viðurkennt til ECTS-eininga?',
  'ects.readMore': 'Nánari upplýsingar',
  // Countries
  'countryselect.select': "Vinsamlega velja",
  'Finland': 'Finnland',
  'Estonia': 'Eistland',
  'Germany': 'Þýskalandi',
  'Sweden': 'Svíþjóð',
  'Norway': 'Noregur',
  'Latvia': 'Lettland',
  "Countries":   {
    'Afganistan': 'Afganistan',
    'Åland Islands': 'Álandseyjar',
    'Albania': 'Albanía',
    'Algeria': 'Alsír',
    'American Samoa': 'Ameríska Samóa',
    'Andorra': 'Andorra',
    'Angola': 'Angóla',
    'Anguilla': 'Anguilla',
    'Antarctica': 'Suðurskautslandið',
    'Antigua and Barbuda': 'Antigua og Barbúda',
    'Argentina': 'Argentína',
    'Armenia': 'Armenía',
    'Aruba': 'Arúba',
    'Australia': 'Ástralía',
    'Austria': 'Austurríki',
    'Azerbaijan': 'Aserbaídsjan',
    'Bahamas': 'Bahamaeyjar',
    'Bahrain': 'Barein',
    'Bangladesh': 'Bangladess',
    'Barbados': 'Barbados',
    'Belarus': 'Hvíta-Rússland',
    'Belgium': 'Belgía',
    'Belize': 'Belís',
    'Benin': 'Benín',
    'Bermuda': 'Bermúda',
    'Bhutan': 'Bútan',
    'Bolivia': 'Bólivía',
    'Bosnia and Herzegovina': 'Bosnía og Hersegóvína',
    'Botswana': 'Botsvana',
    'Bouvet Island': 'Bouveteyja',
    'Brazil': 'Brasilía',
    'British Indian Ocean Territory': 'Breska svæðið við Indlandshaf',
    'Brunei Darussalam': 'Brúnei',
    'Bulgaria': 'Búlgaría',
    'Burkina Faso': 'Búrkína Fasó',
    'Burundi': 'Búrúndí',
    'Cambodia': 'Kambódía',
    'Cameroon': 'Kamerún',
    'Canada': 'Kanada',
    'Cape Verde': 'Grænhöfðaeyjar',
    'Cayman Islands': 'Cayman Islands',
    'Central African Republic': 'Mið-Afríkulýðveldið',
    'Chad': 'Chad',
    'Chile': 'Chile',
    'China': 'Kína',
    'Christmas Island': 'Jólaeyja',
    'Cocos (Keeling) Islands': 'Cocos (Keeling) eyjar',
    'Colombia': 'Kólumbíu',
    'Comoros': 'Kómoreyjar',
    'Congo': 'Kongó',
    'Congo, The Democratic Republic of The': 'Kongó, Lýðræðislega lýðveldið',
    'Cook Islands': 'Cook Islands',
    'Costa Rica': 'Kosta Ríka',
    'Cote D\'ivoire': 'Cote D\'ivoire',
    'Croatia': 'Króatía',
    'Cuba': 'Kúbu',
    'Cyprus': 'Kýpur',
    'Czech Republic': 'Tékkland',
    'Denmark': 'Danmörk',
    'Djibouti': 'Djíbútí',
    'Dominica': 'Dóminíka',
    'Dominican Republic': 'Dóminíska lýðveldið',
    'Ecuador': 'Ekvador',
    'Egypt': 'Egyptaland',
    'El Salvador': 'El Salvador',
    'Equatorial Guinea': 'Miðbaugs-Gíneu',
    'Eritrea': 'Erítreu',
    'Estonia': 'Eistland',
    'Ethiopia': 'Eþíópía',
    'Falkland Islands (Malvinas)': 'Falklandseyjar (Malvinas)',
    'Faroe Islands': 'Færeyjar',
    'Fiji': 'Fídjieyjar',
    'Finland': 'Finnland',
    'France': 'Frakkland',
    'French Guiana': 'Franska Gvæjana',
    'French Polynesia': 'Frönsku Pólýnesíu',
    'French Southern Territories': 'Frönsku suðursvæðin',
    'Gabon': 'Gabon',
    'Gambia': 'Gambía',
    'Georgia': 'Georgíu',
    'Germany': 'Þýskalandi',
    'Ghana': 'Gana',
    'Gibraltar': 'Gíbraltar',
    'Greece': 'Grikkland',
    'Greenland': 'Grænland',
    'Grenada': 'Grenada',
    'Guadeloupe': 'Gvadelúpeyjar',
    'Guam': 'Gvam',
    'Guatemala': 'Gvatemala',
    'Guernsey': 'Guernsey',
    'Guinea': 'Gíneu',
    'Guinea-Bissau': 'Gíneu-Bissá',
    'Guyana': 'Gvæjana',
    'Haiti': 'Haítí',
    'Heard Island and Mcdonald Islands': 'Heard Island og Mcdonald Islands',
    'Holy See (Vatican City State)': 'Páfagarður (Vatíkanríkið)',
    'Honduras': 'Hondúras',
    'Hong Kong': 'Hong Kong',
    'Hungary': 'Ungverjalandi',
    'Iceland': 'Ísland',
    'India': 'Indland',
    'Indonesia': 'Indónesía',
    'Iran, Islamic Republic of': 'Íran, Íslamska lýðveldið',
    'Iraq': 'Írak',
    'Ireland': 'Írland',
    'Isle of Man': 'Mön',
    'Israel': 'Ísrael',
    'Italy': 'Ítalía',
    'Jamaica': 'Jamaíka',
    'Japan': 'Japan',
    'Jersey': 'Jersey',
    'Jordan': 'Jórdaníu',
    'Kazakhstan': 'Kasakstan',
    'Kenya': 'Kenýa',
    'Kiribati': 'Kiribati',
    'Korea, Democratic People\'s Republic of': 'Kóreu, lýðræðislega alþýðulýðveldið',
    'Korea, Republic of': 'Kórea, Lýðveldið',
    'Kosovo': 'Kosovo',
    'Kuwait': 'Kúveit',
    'Kyrgyzstan': 'Kirgistan',
    'Lao People\'s Democratic Republic': 'Lýðræðislega lýðveldið Laó',
    'Latvia': 'Lettland',
    'Lebanon': 'Líbanon',
    'Lesotho': 'Lesótó',
    'Liberia': 'Líberíu',
    'Libyan Arab Jamahiriya': 'Líbýu Arabinn Jamahiriya',
    'Liechtenstein': 'Liechtenstein',
    'Lithuania': 'Litháen',
    'Luxembourg': 'Lúxemborg',
    'Macao': 'Macao',
    'Macedonia, The Former Yugoslav Republic of': 'Makedónía Fyrrum júgóslavneska lýðveldið',
    'Madagascar': 'Madagaskar',
    'Malawi': 'Malaví',
    'Malaysia': 'Malasía',
    'Maldives': 'Maldíveyjar',
    'Mali': 'Malí',
    'Malta': 'Malta',
    'Marshall Islands': 'Marshall-eyjar',
    'Martinique': 'Martinique',
    'Mauritania': 'Máritanía',
    'Mauritius': 'Máritíus',
    'Mayotte': 'Mayotte',
    'Mexico': 'Mexíkó',
    'Micronesia, Federated States of': 'Sambandsríki Míkrónesíu',
    'Moldova, Republic of': 'Lýðveldið Moldavía',
    'Monaco': 'Mónakó',
    'Mongolia': 'Mongólía',
    'Montenegro': 'Svartfjallaland',
    'Montserrat': 'Montserrat',
    'Marocco': 'Marokkó',
    'Mozambique': 'Mósambík',
    'Myanmar': 'Mjanmar',
    'Namibia': 'Namibía',
    'Nauru': 'Nauru',
    'Nepal': 'Nepal',
    'Netherlands': 'Holland',
    'Netherlands Antilles': 'Hollensku Antilles-eyjar',
    'New Caledonia': 'Nýja Kaledónía',
    'New Zealand': 'Nýja Sjáland',
    'Nicaragua': 'Níkaragva',
    'Niger': 'Níger',
    'Nigeria': 'Nígeríu',
    'Niue': 'Niue',
    'Norfolk Island': 'Norfolk eyja',
    'Northern Mariana Islands': 'Norður-Marianeyjar',
    'Norway': 'Noregur',
    'Oman': 'Óman',
    'Pakistan': 'Pakistan',
    'Palau': 'Palau',
    'Palestinian Territory, Occupied': 'Palestínskt landsvæði hertekið',
    'Panama': 'Panama',
    'Papua New Guinea': 'Papúa Nýja-Gínea',
    'Paraguay': 'Paragvæ',
    'Peru': 'Perú',
    'Philippines': 'Filippseyjar',
    'Pitcairn': 'Pitcairn',
    'Poland': 'Pólland',
    'Portugal': 'Portúgal',
    'Puerto Rico': 'Púertó Ríkó',
    'Qatar': 'Katar',
    'Reunion': 'Reunion',
    'Romania': 'Rúmenía',
    'Russian Federation': 'Rússland',
    'Rwanda': 'Rúanda',
    'Saint Helena': 'Sankti Helena',
    'Saint Kitts and Nevis': 'Saint Kitts og Nevis',
    'Saint Lucia': 'Sankti Lúsía',
    'Saint Pierre and Miquelon': 'Saint Pierre og Miquelon',
    'Saint Vincent and The Grenadines': 'Saint Vincent og Grenadíneyjar',
    'Samoa': 'Samóa',
    'San Marino': 'San Marínó',
    'Sao Tome and Principe': 'Sao Tome og Prinsípe',
    'Saudi Arabia': 'Sádí-Arabía',
    'Senegal': 'Senegal',
    'Serbia': 'Serbía',
    'Seychelles': 'Seychelles',
    'Sierra Leone': 'Síerra Leóne',
    'Singapore': 'Singapore',
    'Slovakia': 'Slóvakía',
    'Slovenia': 'Slóvenía',
    'Solomon Islands': 'Salómonseyjar',
    'Somalia': 'Sómalíu',
    'South Africa': 'Suður-Afríka',
    'South Georgia and The South Sandwich Islands': 'Suður-Georgía og Suður-Sandwicheyjar',
    'Spain': 'Spánn',
    'Sri Lanka': 'Sri Lanka',
    'Sudan': 'Súdan',
    'Suriname': 'Súrínam',
    'Svalbard and Jan Mayen': 'Svalbarða og Jan Mayen',
    'Swaziland': 'Svasíland',
    'Sweden': 'Svíþjóð',
    'Swizerland': 'Swizerland',
    'Syrian Arab Republic': 'Sýrlenska Arabalýðveldið',
    'Taiwan': 'Taívan',
    'Tajikistan': 'Tadsjikistan',
    'Tanzania, United Republic of': 'Tansanía Sameinuðu lýðveldið',
    'Thailand': 'Tæland',
    'Timor-leste': 'Tímor',
    'Togo': 'Tógó',
    'Tokelau': 'Tokelau',
    'Tonga': 'Tonga',
    'Trinidad and Tobago': 'Trínidad og Tóbagó',
    'Tunisia': 'Túnis',
    'Turkey': 'Tyrkland',
    'Turkmenistan': 'Túrkmenistan',
    'Turks and Caicos Islands': 'Turks og Caicos eyjar',
    'Tuvalu': 'Túvalú',
    'Uganda': 'Úganda',
    'Ukraine': 'Úkraína',
    'United Arab Emirates': 'Sameinuðu arabísku furstadæmin',
    'United Kingdom': 'Bretland',
    'United States': 'Bandaríkin',
    'United States Minor Outlying Islands': 'Smáeyjar Bandaríkjanna',
    'Uruguay': 'Úrúgvæ',
    'Uzbekistan': 'Úsbekistan',
    'Vanuatu': 'Vanúatú',
    'Venezuela': 'Venesúela',
    'Viet Nam': 'Víetnam',
    'Virgin Islands, British': 'Jómfrúreyjar Breska',
    'Virgin Islands, U.S.': 'Jómfrúareyjar U.S.',
    'Wallis and Futuna': 'Wallis og Futuna',
    'Western Sahara': 'Vestur-Sahara',
    'Yemen': 'Jemen',
    'Zambia': 'Sambía',
    'Zimbabwe': 'Simbabve'
  },
  //Alt texts
  'alt.example-1': 'tveir sjálfkeyrandi bílar',
  'alt.example-2': 'hönd sem heldur á farsíma',
  'alt.example-3': 'konuandlit',
  'alt.defining-ai': 'hrúga af orðum sem tengjast gervigreind',
  'alt.turing-test': 'karl sem spjallar við vélmenni og konu gegnum tölvu',
  'alt.chinese-room': 'hugsunartilraunin kínverska herbergið',
  'alt.chicken-crossing': 'vélmenni í báti; horfir á hænu, ref og kornpoka',
  'alt.a-to-b': 'hönd sem heldur á síma með leiðsöguforriti á skjánum',
  'alt.tic-tac-toe': 'tvö börn spila krossa og hringi (myllu)',
  'alt.self-driving-car': 'kona á gangi fyrir framan sjálfkeyrandi bíl',
  'alt.spam-or-ham': 'venjulegur tölvupóstur og ruslpóstur',
  'alt.mnist': 'sýnishorn úr MNIST-gagnagrunninum',
  'alt.nearest-neighbor': 'tvennt í innkaupahugleiðingum',
  'alt.nearest-neighbor-graph': 'grannaflokkun',
  'alt.recommendation': 'karl og kona hlusta á tónlist hvort á sínum farsíma',
  'alt.price-real-estate': 'þrjú mismunandi hús',
  'alt.supervised-learning': 'maður að kenna vélmenni',
  'alt.dice': 'teningum',
  'alt.chess': 'skákborð',
  'alt.poker': 'hönd sem heldur á spilum',
  'alt.eyes': 'þrenn brún augu; ein blá',
  'alt.search-1': 'ein leið að marki',
  'alt.search-2': 'fjórar ólíkar leiðir að sama marki',
  'alt.chicken-crossing-1': 'róðrarbátsþraut, upphafsstaða',
  'alt.chicken-crossing-2': 'róðrarbátsþraut, fyrsta færsla',
  'alt.chicken-crossing-3': 'róðrarbátsþraut, allar færslur',
  'alt.chicken-crossing-4': 'róðrarbátsþraut, besta leið',
  'alt.chicken-crossing-5': 'róðrarbátsþraut, upphafsstaða',
  'alt.implication-1': 'karl og kona',
  'alt.implication-2': 'mannsaugu',
  'alt.implication-3': 'eftirlitsmyndavél',
  'alt.implication-4': 'vélmenni að skrifa á tölvu',
  'alt.oddchange': 'skin og skúrir',
  'alt.brain': 'stílfærð teikning af heila',
  'alt.carstop': 'sjálfkeyrandi bíll við stöðvunarmerki',
  'alt.gan': 'vélhendur; önnur heldur á mynd af ketti, hin heldur á mynd af stöðvunarmerki',
  'alt.hammock': 'vélmenni sem blakar stóru laufblaði til að kæla mann sem liggur í hengirúmi',
  'alt.game-tree-1': 'leiktré 1',
  'alt.game-tree-2': 'leiktré 2',
  'alt.game-tree-3': 'leiktré 3',
  'alt.game-tree-4': 'leiktré 4',
  'alt.bayes-rule-1': 'mynd sem sýnir falskar jákvæðar og neikvæðar niðurstöður úr krabbameinsskimun',
  'alt.bayes-rule-2': 'mynd sem sýnir falskar jákvæðar og neikvæðar niðurstöður úr krabbameinsskimun',
  'alt.defineai': 'hrúga af orðum sem tengjast gervigreind',
  'alt.winter': 'vélmenni sofandi í snjónum',
  'alt.terminator': 'óttalegt vélmenni',
}
