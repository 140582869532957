import * as actionTypes from "./actions";
import { RESET_STATE } from "../actions";

const initialState = {
  completed: false,
  completionDate: null,
  confirmationSent: false,
  confirmationSentDate: null,
  answererId: null,
  courseId: null
};

export default function courseState(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE:
      return { ...initialState };
    case actionTypes.UPDATE_COURSE_STATE:
      return { ...action.payload };
    default:
      return state;
  }
}
