import { combineReducers } from "redux";
import { persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createCompressEncryptor from "redux-persist-transform-compress-encrypt";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import traverse from "traverse";

import quizzes from "./quiz";
import answers from "./answer";
import stats from "./stats";
import notifications from "./notification";
import peerReviews from "./peerReview";
import tags from "./tags";
import courseState from "./courseState";
import * as accountState from "./account";
import _get from "lodash/get";

import dateToUnix from "../utilities/datetounix";

import {
  expiryDate as answerExpiryDate,
  initialAnswerState
} from "./answer/reducer";
import {
  expiryDate as quizExpiryDate,
  initialState as initialQuizState
} from "./quiz/reducer";
import { localizeCourseId } from "../services/locale"; // this is temporary

const username = _get(accountState.getUser(), "username");

const encryptor = createCompressEncryptor({
  // TODO: change this / dotenv this?
  secretKey: "a-totally-secret-key-which-has-a-wombat",
  onError: error => new Error(`encryptor error ${error}`)
});

const expiry = createTransform(
  (inboundState, key) => {
    return inboundState;
  },
  (outboundState, key) => {
    if (!outboundState) {
      return outboundState;
    }

    const newState = traverse(outboundState).forEach(function(val) {
      if (!val || typeof val !== "object") {
        return;
      }

      const expireDate = _get(val, "expireAt");
      if (!expireDate) {
        return;
      }

      switch (key) {
        case "quizzes":
          if (!expireDate) {
            return;
          }

          if (expireDate < dateToUnix(new Date())) {
            this.update({ ...initialQuizState, ...quizExpiryDate });
          }

          break;
        case "answers":
          const submitted = _get(val, "submitted");
          const confirmed = _get(val, "confirmed");
          const rejected = _get(val, "rejected");

          if (!expireDate || (submitted && !confirmed && !rejected)) {
            return;
          }

          if (expireDate < dateToUnix(new Date())) {
            this.update({ ...initialAnswerState, ...answerExpiryDate });
          }

          break;
        default:
          break;
      }

      return;
    });

    return newState;
  } /* ,
  { 
    whitelist: ['answers'],
  } */
);

const answerPersistConfig = {
  key: "answers",
  storage: storage,
  transforms: [expiry, encryptor],
  stateReconciler: autoMergeLevel2
};

const statPersistConfig = {
  key: `stats`, // -username
  storage: storage,
  stateReconciler: autoMergeLevel2
};

// now everything is under root - answers wasn't expiring properly

const combinedReducer = combineReducers({
  quizzes,
  answers, //: persistReducer(answerPersistConfig, answers),
  peerReviews,
  notifications,
  stats, //: persistReducer(statPersistConfig, stats)
  tags,
  courseState
});

export default persistReducer(
  {
    key: localizeCourseId("root"),
    storage: storage,
    //blacklist: ['answers'],
    transforms: [expiry, encryptor],
    stateReconciler: autoMergeLevel2
  },
  combinedReducer
);
