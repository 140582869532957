// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
    // Globals
    'globals.locale': 'mt',
    'globals.signIn': 'Idħol',
    'globals.logIn': 'Illoggja',
    'globals.signUp': 'Irreġistra',
    'globals.signOut': 'Oħroġ',
    'globals.logOut': 'Oħroġ',
    'globals.submit': 'Issottometti',
    'globals.submitting': 'Is-sottomissjoni għaddejja...',
    'globals.yourEmail': 'L-email tiegħek',
    'globals.yourPassword': 'Il-password tiegħek',
    'globals.courseOverview': 'Deskrizzjoni ġenerali tal-kors',
    'globals.forgotPassword': 'Insejt il-password?',
    'globals.invalidCredentials': 'Kredenzjali invalidi',
    'globals.section': 'Taqsima',
    'globals.exercises': 'Eżerċizzji',
    'globals.chapter': 'Kapitlu',
    'globals.introToAI': 'Introduzzjoni għall-IA',
    'globals.buildingAI': 'Il-bini tal-IA',
    'globals.acceptPolicy': 'Accept policy',
    // section
    'section.footerEndOfTheChapter': 'Wasalt fi tmiem il-Kapitlu!',
    'section.footerEndOfTheCourseTitle': 'Wasalt fi tmiem il-kors!',
    'section.footerEndOfTheCourseText': 'Meta tlesti l-eżerċizzji u l-valutazzjonijiet bejn il-pari kollha, it-tweġibiet tiegħek janalizzawhom il-persunal tagħna.',
    'section.nextSection': 'It-taqsima li jmiss',
    'section.nextChapter': 'Il-Kapitlu li jmiss',
    'section.start': 'Ibda',
    'section.logisticRegressionExample.slope': 'Inklinazzjoni',
    'section.logisticRegressionExample.intercept': 'Interċetta',
    'section.footerEndOfTheCourseBAI': "Kompli l-vjaġġ tiegħek biex tkun tista 'timplimenta l-ewwel idea AI tiegħek.",
    // quiz peer review
    'quiz.awaitingPeerReview': 'Qed nistennew il-valutazzjoni tiegħek',
    'quiz.rejectedPeerReview': 'It-tweġiba preċedenti tiegħek ġiet miċħuda fil-valutazzjoni. Jekk jogħġbok erġa’ pprova.',
    'quiz.unsubmittedAnswer': 'Tweġiba mhux sottomessa',
    'quiz.pendingPeerReview': 'Valutazzjoni bejn il-pari pendenti',
    // quiz
    'quiz.status.answered': 'Imwieġba',
    'quiz.status.rejected': 'It-tweġiba ġiet miċħuda, erġa’ pprova',
    'quiz.status.unanswered': 'Mhux imwieġba',
    'quiz.status.exercisesCompleted': 'Eżerċizzji kompluti',
    'quiz.status.correctAnswers': 'Tweġibiet tajbin',
    'quiz.message.rejectedInReview': 'It-tweġiba preċedenti tiegħek ġiet miċħuda fil-valutazzjoni. Jekk jogħġbok erġa’ pprova.',
    'quiz.youAnswered': 'Int weġibt',
    'quiz.yourAnswer': 'It-tweġiba tiegħek...',
    'quiz.yourScore': 'Il-marka tiegħek:',
    'quiz.quizNotLoaded': 'Il-kwiżż ma setax jillowdja',
    'quiz.words': 'Kliem:',
    'quiz.exampleAnswer': 'Eżempji ta’ tweġibiet:',
    // peer review
    'peerreview.question1': 'It-tweġiba tirrigwarda s-suġġett',
    'peerreview.question2': 'It-tweġiba hija komprensiva',
    'peerreview.question3': 'It-tweġiba hija raġunata sew',
    'peerreview.question4': 'It-tweġiba hija faċli biex tiġi segwita',
    'peerreview.thankYou': 'Grazzi talli vvalutajt il-pari tiegħek!',
    'peerreview.giveMoreReviews': 'Jekk ikollok ftit ħin żejjed,',
    'peerreview.giveMoreReviewsButton': 'agħti aktar valutazzjonijiet',
    'peerreview.notEnoughAnswers': 'Normalment hawnhekk ikun hawn valutazzjoni bejn il-pari, iżda ma waslux biżżejjed tweġibiet għal dan l-eżerċizzju.',
    'peerreview.selectAnswerMessage': 'Wasal il-ħin għal valutazzjoni bejn il-pari! Hawn taħt se ssib għadd ta’ tweġibiet ta’ studenti oħra. Agħżel waħda minnhom u vvalutaha. Irrepeti għal mill-inqas tliet darbiet.',
    'peerreview.minimumPeerReviewWarning': 'L-eżerċizzju jista’ jiġi aċċettat biss meta tkun tajt mill-inqas tliet valutazzjonijiet bejn il-pari.',
    'peerreview.select': 'Agħżel',
    'peerreview.reportSpam': 'Irrapporta spam',
    'peerreview.feedback': 'It-tweġiba tiegħek ġiet analizzata u aċċettata. Ejja naraw kif jaħsbuha l-pari tiegħek dwar din:',
    // exercise17
    'quiz.exercise17.xLabel': 'Snin ta’ edukazzjoni',
    'quiz.exercise17.yLabel': 'Stennija tal-għomor fi snin',
    'quiz.exercise17.textPart1': 'Ejja nistħarrġu r-rabta bejn is-snin totali fil-ħajja skolastika (mill-kindergarten sal-università) u l-istennija tal-għomor. Din hija data minn tliet pajjiżi differenti, murija f’figura rrappreżentata bit-tikek:',
    'quiz.exercise17.textPart2': 'Il-medja tas-snin skolastiċi ta’ pajjiż partikolari hi 10 snin u l-istennija tal-għomor tiegħu hi 57 sena; il-medja tas-snin skolastiċi ta’ pajjiż ieħor hi 13-il sena u l-istennija tal-għomor tiegħu hi 53 sena; filwaqt li l-medja tas-snin skolastiċi ta’ pajjiż ieħor hi 20 sena u l-istennija tal-għomor tiegħu hi 80 sena.',
    'quiz.exercise17.textPart3': 'Tista’ ċċaqlaq il-punti tat-truf tal-linja fissa biex tkun tista’ tippożizzjonaha b’mod li turi x-xejra tal-punti tad-data. Innota li mhux se tkun tista’ tippożizzjona l-linja b’mod li taqbel eżatt mal-punti tad-data, iżda din mhix problema: uħud minn dawn il-punti tad-data se jkunu ’l fuq mil-linja, filwaqt li oħrajn se jkunu taħtha. L-importanti hu li l-linja tiddeskrivi x-xejra ġenerali.',
    'quiz.exercise17.textPart4': 'Meta tippożizzjona l-linja, tista’ tużaha biex tbassar l-istennija tal-għomor.',
    'quiz.exercise17.textPart5': 'Minn din id-data, x’tista’ tgħid dwar l-istennija tal-għomor tal-persuni li kellhom 15-il sena edukazzjoni? Importanti: Innota li anki jekk tista’ tikseb tbassir speċifiku, saħansitra għal frazzjoni ta’ sena, billi taġġusta l-linja, mhux bilfors tista’ tikseb tbassir kunfidenti. Meta twieġeb, qis l-ammont limitat tad-data.',
    // exercise18
    'quiz.exercise18.xLabel': 'Snin ta’ edukazzjoni',
    'quiz.exercise18.yLabel': 'Stennija tal-għomor fi snin',
    'quiz.exercise18.textPart1': 'Fl-eżerċizzju preċedenti, kellna biss data minn tliet pajjiżi. Is-sett sħiħ tad-data fih data minn 14-il pajjiż differenti, li qed tiġi ppreżentata fi graff hawn taħt:',
    'quiz.exercise18.textPart2': 'Minn din id-data, taħseb li jista’ jinbidel it-tbassir tiegħek dwar l-istennija tal-għomor tal-persuni bi 15-il sena edukazzjoni? Jekk iva, għaliex?',
    'quiz.exercise18.textPart3': 'Liema minn dawn l-opzjonijiet li ġejjin taqbel l-aktar mal-istima tiegħek tal-istennija tal-għomor tal-persuni bi 15-il sena edukazzjoni? Agħżel l-aktar opzjoni speċifika li taħseb li hija ġustifikata billi ġġib il-mudell tal-linja dritta jaqbel mad-data ta’ hawn fuq.',
    // exercise19
    'quiz.exercise19.xLabel': 'Sigħat ta’ studju',
    'quiz.exercise19.yLabel': 'Probabbiltà li wieħed jgħaddi',
    'quiz.exercise19.textPart1': 'Kull tikka fil-figura tikkorrispondi għal student wieħed. Fil-qiegħ tal-figura hemm l-iskala għal kemm-il siegħa l-istudent ikun studja għall-eżami, u l-istudenti li għaddew mill-eżami jintwerew bħala tikek fin-naħa ta’ fuq tal-figura, filwaqt li dawk li ma għaddewx jintwerew fil-qiegħ. Se nużaw l-iskala fuq ix-xellug biex nindikaw il-probabbiltà mbassra li l-istudenti jgħaddu, u din se niksbuha mill-mudell ta’ rigressjoni loġistika kif inhu spjegat hawn taħt. Minn din il-figura, tista’ tara li bejn wieħed u ieħor l-istudenti li qattgħu aktar ħin jistudjaw kellhom probabbiltà aħjar li jgħaddu mill-kors. B’mod speċjali, il-każijiet estremi huma intwittivi: hu diffiċli ħafna li dawk li jaħdmu inqas minn siegħa jirnexxilhom jgħaddu mill-kors, filwaqt li ħafna minn dawk li jeħduh bis-serjetà, jirnexxilhom jgħaddu. Iżda xi ngħidu dwar dawk li jinsabu xi mkien bejn dawn iż-żewġ estremi? Jekk tqatta’ sitt sigħat studju, x’inhi l-probabbiltà tiegħek li tgħaddi?',
    'quiz.exercise19.textPart2': 'Bir-rigressjoni loġistika nistgħu nikkwantifikaw il-probabbiltà li wieħed jgħaddi. Il-kurva fil-figura tista’ tiġi interpretata bħala l-probabbiltà li wieħed jgħaddi: pereżempju, wara li jkun qatta’ ħames sigħat jistudja, il-probabbiltà li wieħed jgħaddi hija ftit aktar minn 20 %. Mhux se nidħlu fid-dettalji ta’ kif niksbu l-kurva, iżda dan hu simili għal kif niksbu l-ponderazzjonijiet fir-rigressjoni lineari.',
    'quiz.exercise19.textPart3': 'Mill-figura ta’ hawn fuq, jekk tixtieq ikollok probabbiltà ta’ 80 % li tgħaddi minn eżami tal-università, bejn wieħed u ieħor kemm-il siegħa għandek tistudja?',
    'quiz.exercise19.textPart4': ' ',
    // exercise22
    'quiz.exercise22.sigmoidXLabel': 'Input',
    'quiz.exercise22.sigmoidYLabel': 'Riżultat tal-funzjoni sigmoid',
    'quiz.exercise22.stepXLabel': 'Input',
    'quiz.exercise22.stepYLabel': 'Riżultat tal-funzjoni tal-passi',
    'quiz.exercise22.identityXLabel': 'Input',
    'quiz.exercise22.identityYLabel': 'Riżultat tal-funzjoni tal-identità',
    'quiz.exercise22.textPart1': 'Hawn taħt hawn graffs għal tliet funzjonijiet ta’ attivazzjoni bi proprjetajiet differenti. L-ewwel, għandna l-funzjoni sigmoid, imbagħad il-funzjoni tal-passi, u fl-aħħar nett il-funzjoni tal-identità.',
    // exercise23
    'quiz.exercise23.happyFaces': 'l-uċuħ ferħanin ġew klassifikati b’mod korrett',
    'quiz.exercise23.sadFaces': 'l-uċuħ imdejqin ġew klassifikati b’mod korrett',
    // certificate availability
    'certificate.failedToCheck': 'Il-verifika tad-disponibbiltà taċ-ċertifikat ma rnexxietx',
    'certificate.failedToGenerate': 'Iċ-ċertifikat ma setax jiġi ġġenerat',
    'certificate.notYetAvailable': 'Iċ-ċertifikat tiegħek se ssibu hawn wara l-konferma uffiċjali tagħna. Dan jaf jieħu xi ftit tal-ħin. Grazzi tal-paċenzja.',
    'certificate.updateName': 'Iċ-ċertifikat tiegħek huwa disponibbli! Jekk jogħġbok aġġorna ismek u kunjomok fil-profil tiegħek permezz tal-formola ta’ hawn taħt, sabiex inkunu nistgħu nużawh għaċ-ċertifikat.',
    'certificate.generate': 'Iġġenera ċ-ċertifikat tiegħek hawn taħt.',
    'certificate.generateTextPart1': 'Iċ-ċertifikat se jiġi ġġenerat għal',
    'certificate.generateTextPart2': 'Qabel tiġġenera ċ-ċertifikat, jekk jogħġbok ikkonferma li dan huwa korrett billi l-isem ma jistax jiġi mibdul wara. Jekk tixtieq tibdel ismek, tista’ tagħmel dan fil-formola hawn taħt.',
    'certificate.generateButton': 'Iġġenera ċ-ċertifikat',
    'certificate.downloadButton': 'Niżżel iċ-ċertifikat',
    'certificate.checkingAvailability': 'Qed issir il-verifika tad-disponibbiltà taċ-ċertifikat...',
    // Visualizations
    'visualizations.logisticRegressionChart.classifiedCorrectly': 'Klassifikata b’mod korrett',
    'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Klassifikata b’mod mhux korrett',
    // section blocks
    'section.block.note': 'Nota',
    'section.block.example': 'Eżempju',
    'section.block.keyTerminology': 'Terminoloġija ewlenija',
    'section.partSummary.spectrumTextPart1': 'Please join the ',
    'section.partSummary.spectrum': 'Elements of AI community',
    'section.partSummary.spectrumTextPart2': ' to discuss and ask questions about this chapter.',
    'section.partSummary.feedbackTitle': 'Agħtina r-rispons tiegħek dwar din il-parti',
    'section.partSummary.feedbackPlaceholder': 'Ikteb ir-rispons tiegħek hawnhekk.',
    'section.partSummary.feedbackSuccess': 'Grazzi tar-rispons!',
    // 404
    'globals.404.title': '404 - Il-paġna ma nstabitx',
    'globals.404.intro': 'Għażilt rotta li ma teżistix... paċenzja.',
    'globals.loading': 'Qed jillowdja...',
    // footer / menu
    'globals.menu': 'Menu',
    'globals.menu.about': 'Tagħrif',
    'globals.menu.faq': 'Mistoqsijiet Frekwenti',
    'globals.menu.privacyPolicy': 'Politika dwar il-Privatezza',
    'globals.menu.myProfile': 'Il-profil tiegħi',
    'globals.menu.termsofService': 'Termini tas-Servizz',
    // Dashboard/Index
    'dashboard.title': 'Nilqgħuk fil-kors!',
    'dashboard.congratulations.title': 'Proset!',
    'dashboard.congratulations.message': 'Inti lestejt il-kors Elements of AI',
    'dashboard.congratulations.downloadCertificate': 'Niżżel iċ-ċertifikat',
    'dashboard.notLoggedIn.title': 'Merħba!',
    'dashboard.notLoggedIn.message': 'Irreġistra jew illoggja biex tikseb aċċess sħiħ għall-kors. Mingħajr ħlas!',
    // Account
    'account.certificate': 'Ċertifikat',
    'account.credits': 'Krediti tal-ECTS',
    'account.myProfile': 'Il-profil tiegħi',
    'account.changePassword': 'Ibdel il-password',
    'account.changeLanguage': 'Ibdel il-lingwa',
    'account.profileInformation': 'Informazzjoni dwar il-profil',
    'account.goToCourse': 'Mur fil-kors',
    'account.buildingAINew': 'Kors ġdid bl-Ingliż',
    'account.accept': 'Naċċetta',
    'account.decline': 'Nirrifjuta',
    'account.courses': 'Korsijiet',
    // Sign Up page
    'signUp.description': 'Imla l-formola ta’ hawn taħt biex toħloq kont mooc.fi ġdid',
    'signUp.email': 'L-email tiegħek',
    'signUp.firstName': 'Isem',
    'signUp.lastName': 'Kunjom',
    'signUp.postalCode': 'Kodiċi postali',
    'signUp.country': 'F’liema pajjiż tgħix?',
    'signUp.Password': 'Password',
    'signUp.confirmPassword': 'Ikkonferma l-password',
    'signUp.howToStudyTitle': 'Kif tixtieq tistudja?',
    'signUp.6weeksTitle': 'Kors ta’ 6 ġimgħat',
    'signUp.6weeksSubTitle': 'Rakkomandat (madwar ħames sigħat fil-ġimgħa)',
    'signUp.6weeksText': 'Intwera li skadenza tgħin biex dak li jkun ilesti l-kors.',
    'signUp.selfPacedTitle': 'Bir-ritmu tiegħek',
    'signUp.selfPacedText': 'Tista’ tagħmel il-kors bir-ritmu tiegħek.',
    'signUp.ectsTitleSwe': ' ',
    'signUp.ectsTitle': 'Qed tapplika għall-krediti tal-ECTS?',
    'signUp.ectsStudent': 'Jien student tal-Università ta’ Helsinki. Jekk timmarka din il-kaxxa u ddaħħal in-numru tiegħek tal-istudent, il-krediti tal-ECTS jingħatawlek awtomatikament.',
    'signUp.ectsStudentSwe': ' ',
    'signup.ectsStudentSweLink': ' ',
    'signUp.ectsOpenUni': 'Immarka din il-kaxxa biex tirċievi email bi struzzjonijiet dwar kif tapplika għall-krediti tal-ECTS',
    'signUp.studentNumber': 'In-numru tiegħek tal-istudent tal-Università ta’ Helsinki (jekk inti student/a)',
    'signUp.privacyOptIn': 'Nagħti l-kunsens tiegħi biex id-data li nġabret b’rabta mal-imġiba tiegħi fl-istudju tintuża għal għanijiet ta’ riċerka. Id-data fiha informazzjoni dwar l-użu tal-materjal u l-eżerċizzji tal-kors. Il-pubblikazzjonijiet ma jistgħu jwasslu għall-identifikazzjoni tal-ebda individwu.',
    'signUp.marketingOptIn': 'Nixtieq nirċievi aġġornamenti dwar verżjonijiet lingwistiċi li se joħorġu fil-ġejjieni u informazzjoni dwar korsijiet ġodda. Naqbel li naqsam l-informazzjoni ta ’kuntatt tiegħi sabiex nirċievi messaġġi mfassla apposta fuq pjattaformi ta’ partijiet terzi. Aqra l-politika tagħna dwar il-privatezza.',
    'signUp.privacyLink': 'Aqra it-termini tagħna dwar il-privatezza',
    'signUp.error': 'Il-formola tiegħek fiha xi żbalji. Jekk jogħġbok iċċekkja l-formola tiegħek.',
    'signUp.acceptPolicyFirst': 'Naċċetta l-Politika tal-Privatezza u t-Termini tas-Servizz.',
    // Sign in page
    'signIn.title': 'Idħol',
    'signIn.intro': 'Uża l-formola ta’ hawn taħt biex tidħol bil-kont mooc.fi tiegħek',
    'signIn.email': 'L-email tiegħek',
    'signIn.password': 'Il-password tiegħek',
    'signIn.noAccount': 'Ma għandekx kont?',
    'signIn.signUpNow': 'Irreġistra issa',
    // Forgot password
    'pwForget.title': 'Insejt il-password',
    'pwForget.description': 'Daħħal l-email tal-kont mooc.fi tiegħek, u tirċievi link biex tirrisettja l-password',
    'pwForget.sendResetLink': 'Ibgħat link għar-risettjar',
    'pwForget.completedDescription': 'Dalwaqt għandek tirċievi email mingħand mooc.fi b’link għar-risettjar tal-password. F’każ li ma tarahiex, iċċekkja l-folder tal-ispam tiegħek.',
    'pwForget.problemDescription': 'Jekk issib xi problemi, ikkuntattja lil mooc@cs.helsinki.fi.',
    'pwForget.resetEmailSent': 'L-email għar-risettjar tal-password intbagħtet',
    'pwForget.emailPlaceholder': 'Indirizz tal-email',
    // password change
    'pwChange.authError': 'L-awtentikazzjoni ma rnexxietx. Jekk jogħġbok erġa’ pprova idħol',
    'pwChange.unexpError': 'Minħabba żball mhux mistenni, il-password ma ġietx aġġornata',
    'pwChange.required': 'Meħtieġa',
    'pwChange.pwMustMatch': 'Il-passwords iridu jaqblu',
    'pwChange.currentPassword': 'Password attwali',
    'pwChange.newPassword': 'Password ġdida',
    'pwChange.confirmPassword': 'Ikkonferma l-password il-ġdida',
    'pwChange.changingPassword': 'Il-password qed tinbidel...',
    'pwChange.changePassword': 'Ibdel il-password',
    'pwChange.passwordChanged': 'Il-password ġiet mibdula',
    // user details
    'userDetails.authError': 'L-awtentikazzjoni ma rnexxietx. Jekk jogħġbok erġa’ pprova idħol',
    'userDetails.unexpError': 'Minħabba żball mhux mistenni, il-profil ma ġiex aġġornat',
    'userDetails.required': 'Meħtieġa',
    'userDetails.invalidEmail': 'Email invalidu',
    'userDetails.email': 'Email',
    'userDetails.emailAddress': 'Indirizz tal-email',
    'userDetails.firstName': 'Isem',
    'userDetails.lastName': 'Kunjom',
    'userDetails.language': 'Lingwa',
    'userDetails.updatingProfile': 'Il-profil qed jiġi aġġornat...',
    'userDetails.updateProfile': 'Aġġorna l-profil',
    'userDetails.profileUpdated': 'Il-profil ġie aġġornat',
    // languages
    'languages.en.long': 'l-Ingliż',
    'languages.fi.long': 'Finlandiż',
    'languages.se.long': 'Żvediż',
    'languages.de.long': 'Ġermaniż (Ġermanja)',
    'languages.ee.long': 'Estonjan',
    'languages.no.long': 'Norveġiż',
    'languages.lv.long': 'Latvjan',
    'languages.lt.long': 'Litwan',
    'languages.mt.long': 'Il-Malti',
    'languages.fr-be.long': 'Franċiż (Belġju)',
    'languages.nl-be.long': 'Olandiż (Belġju)',
    'languages.nl.long': 'Olandiż (Pajjiżi l-Baxxi)',
    'languages.fr.long': 'Franċiż (Franza)',
    'languages.it.long': 'Taljan',
    'languages.ga.long': 'Irlandiż',
    'languages.sk.long': 'Slovakk',
    'languages.da.long': 'Daniż',
    'languages.pl.long': 'Pollakk',
    'languages.hr.long': 'Kroat',
    'languages.en-ie.long': 'Ingliż (Irlanda)',
    'languages.ro.long': 'Rumen',
    'languages.is.long': 'Iżlandiż',
    'languages.de-at.long': 'Ġermaniż (Awstrija)',
    'languages.en-lu.long': 'l-Ingliż (Lussemburgu)',
    'languages.bg.long': 'Bulgaru',
    'languages.cs.long': 'Ċek',
    'languages.sl.long': 'Sloven',
    'languages.pt.long': 'Portugiż',
    'languages.es.long': 'Spanjol',
    'languages.el.long': 'Grieg',
    // Email verification
    'emailVerification.completedMessage': 'L-indirizz tal-email tiegħek ġie vverifikat',
    'emailVerification.verified': 'Verifikat',
    // Exercises
    'exercise.loading': 'L-eżerċizzju qed jillowdja...',
    'exercise.signUp': 'Irreġistra biex issolvi l-eżerċizzji',
    'exercise.score': 'Il-marka tiegħek:',
    'exercise.wrongLocale': 'Locale ħażin',
    // Part tempplate
    'partTemplate.intro1': 'Fil-Kapitlu ',
    'partTemplate.intro2': ', se nkopru t-taqsimiet li ġejjin. Ikklikkja hawn taħt biex tibda:',
    'partTemplate.spectrumPart1': 'Please join the ',
    'partTemplate.spectrum': 'Elements of AI community',
    'partTemplate.spectrumPart2': ' to discuss and ask questions about this chapter.',
    // missing info
    'missingInfo.title': 'Informazzjoni nieqsa',
    'missingInfo.body': 'Is-soltu nistaqsu dawn il-mistoqsijiet meta l-utenti jirreġistraw, iżda int illoggjajt permezz ta’ kont mooc.fi eżistenti. Jekk jogħġbok imla l-formola ta’ hawn taħt biex tkompli.',
    'missingInfo.privacyPolicy': 'Aqra it-termini tagħna dwar il-privatezza',
    'missingInfo.error': 'Il-formola tiegħek fiha xi żbalji. Jekk jogħġbok iċċekkja l-formola tiegħek.',
    'missingInfo.privacyPolicyUpdateBody': "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
    'missingInfo.privacyPolicyUpdateLink': 'Aqra l-politika tagħna dwar il-privatezza.',
    // routes
    'routes.LANDING': '/mt/',
    'routes.SIGN_UP': '/mt/signup',
    'routes.SIGN_IN': '/mt/signin',
    'routes.MISSING_INFO': '/mt/missinginfo',
    'routes.PASSWORD_FORGET': '/mt/pw-forget',
    'routes.HOME': '/mt/home',
    'routes.ACCOUNT': '/mt/account',
    'routes.PRIVACY': 'https://www.elementsofai.mt/faq/politika-dwar-il-privatezza',
    'routes.FAQ': 'https://www.elementsofai.mt/faq',
    'routes.ABOUT': 'https://www.elementsofai.mt',
    'routes.SPECTRUM': 'https://community.elementsofai.com/c/intro-course-help',
    'routes.ects': 'https://mooc.fi/register-completion/elements-of-ai',
    'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
    'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
    'routes.ects.faq': 'https://www.elementsofai.com/faq/how-can-i-get-2-ects-credits',
    'routes.ACCEPT_POLICY': '/mt/accept-policy',

    // MarkdownComponents
    'markdowncomponents.linearRegressionStatic.xLabel': 'Kikkri kafè kuljum',
    'markdowncomponents.linearRegressionStatic.yLabel': 'Linji ta’ kodiċi miktubin',
    // meta
    'meta.title': 'Kors online mingħajr ħlas',
    'meta.titleTemplate': '%s - Elements of AI',
    'meta.description': 'Sir af aktar dwar il-kors tal-IA organizzat mill-Università ta’ Helsinki u minn Reaktor għall-istudenti u għall-professjonisti tan-negozju - ma huma meħtieġa l-ebda ħiliet fl-ipprogrammar jew fil-matematika.',
    'meta.sitename': 'Elements of AI',
    'meta.title.og': 'A free online introduction to artificial intelligence for non-experts',
    // ECTS
    'ects.text': 'Meta tlesti l-kors, hawn se tirċievi struzzjonijiet fuq kif għandek tapplika għall-krediti tal-ECTS.',
    'ects.textCompleted': 'Jekk għandek numru tas-sigurtà soċjali Finlandiża, tista’ tapplika għall-krediti billi tikklikkja ',
    'ects.link': 'hawn.',
    'ects.info': 'Would you like to apply for ECTS credits?',
    'ects.readMore': 'Read more',
    // Countries
    'countryselect.select': "Jekk jogħġbok agħżel",
    'Sweden': "L-Iżvezja",
    'Estonia': "Estonja",
    'Finland': "Il-Finlandja",
    'Germany': "Il-Ġermanja",
    "Countries": {
        "Afganistan": "Afganistan",
        "Åland Islands": "Gżejjer Åland",
        "Albania": "L-Albanija",
        "Algeria": "Alġerija",
        "American Samoa": "Samoa Amerikana",
        "Andorra": "Andorra",
        "Angola": "Angola",
        "Anguilla": "Anguilla",
        "Antarctica": "L-Antartika",
        "Antigua and Barbuda": "Antigwa u Barbuda",
        "Argentina": "L-Arġentina",
        "Armenia": "L-Armenja",
        "Aruba": "Aruba",
        "Australia": "Awstralja",
        "Austria": "L-Awstrija",
        "Azerbaijan": "Azerbaijan",
        "Bahamas": "Bahamas",
        "Bahrain": "Fil-Baħrejn",
        "Bangladesh": "Bangladexx",
        "Barbados": "Barbados",
        "Belarus": "Il-Belarussja",
        "Belgium": "Il-Belġju",
        "Belize": "Beliże",
        "Benin": "Benin",
        "Bermuda": "Bermuda",
        "Bhutan": "Butan",
        "Bolivia": "Bolivja",
        "Bosnia and Herzegovina": "Bożnja u Ħerzegovina",
        "Botswana": "Il-Botswana",
        "Bouvet Island": "Gżira Bouvet",
        "Brazil": "Brażil",
        "British Indian Ocean Territory": "Territorju Britanniku tal-Oċean Indjan",
        "Brunei Darussalam": "Brunej Darussalam",
        "Bulgaria": "Il-Bulgarija",
        "Burkina Faso": "Burkina Faso",
        "Burundi": "Burundi",
        "Cambodia": "Kambodja",
        "Cameroon": "Kamerun",
        "Canada": "Kanada",
        "Cape Verde": "Kap Verde",
        "Cayman Islands": "Gżejjer Kajman",
        "Central African Republic": "Ir-Repubblika Ċentru-Afrikana",
        "Chad": "Iċ-Ċad",
        "Chile": "Ċili",
        "China": "Iċ-Ċina",
        "Christmas Island": "Gżira tal-Milied",
        "Cocos (Keeling) Islands": "Gżejjer Cocos (Keeling)",
        "Colombia": "Kolombja",
        "Comoros": "Komoros",
        "Congo": "Kongo",
        "Congo, The Democratic Republic of The": "Il - Kongo, Ir - Repubblika Demokratika ta '",
        "Cook Islands": "Gżejjer Cook",
        "Costa Rica": "Kosta Rika",
        "Cote D'ivoire": "Côte D'ivoire",
        "Croatia": "Kroazja",
        "Cuba": "Kuba",
        "Cyprus": "Ċipru",
        "Czech Republic": "Repubblika Ċeka",
        "Denmark": "Id-Danimarka",
        "Djibouti": "Ġibuti",
        "Dominica": "Dominika",
        "Dominican Republic": "Repubblika Dominikana",
        "Ecuador": "Ekwador",
        "Egypt": "L-Eġittu",
        "El Salvador": "El Salvador",
        "Equatorial Guinea": "Ginea Ekwatorjali",
        "Eritrea": "L-Eritrea",
        "Estonia": "Estonja",
        "Ethiopia": "L-Etjopja",
        "Falkland Islands (Malvinas)": "Gżejjer Falkland (Malvinas)",
        "Faroe Islands": "Gżejjer Faroe",
        "Fiji": "Fiġi",
        "Finland": "Il-Finlandja",
        "France": "Franza",
        "French Guiana": "Guyana Franċiża",
        "French Polynesia": "Polineżja Franċiża",
        "French Southern Territories": "Territorji tan-Nofsinhar Franċiżi",
        "Gabon": "Gabon",
        "Gambia": "Il-Gambja",
        "Georgia": "Ġorġja",
        "Germany": "Il-Ġermanja",
        "Ghana": "Ghana",
        "Gibraltar": "Ġibiltà",
        "Greece": "Il-Greċja",
        "Greenland": "Groenlandja",
        "Grenada": "Grenada",
        "Guadeloupe": "Guadeloupe",
        "Guam": "Gwam",
        "Guatemala": "Gwatemala",
        "Guernsey": "Guernsey",
        "Guinea": "Guinea",
        "Guinea-Bissau": "Ginea-Bissaw",
        "Guyana": "Guyana",
        "Haiti": "Ħaiti",
        "Heard Island and Mcdonald Islands": "Gżejjer Heard u Gżejjer Mcdonald",
        "Holy See (Vatican City State)": "Santa Sede (Stat tal-Belt tal-Vatikan)",
        "Honduras": "Honduras",
        "Hong Kong": "Ħong Kong",
        "Hungary": "L-Ungerija",
        "Iceland": "Islanda",
        "India": "Indja",
        "Indonesia": "Indoneżja",
        "Iran, Islamic Republic of": "Iran, Repubblika Iżlamika",
        "Iraq": "Iraq",
        "Ireland": "L-Irlanda",
        "Isle of Man": "Isle of Man",
        "Israel": "Iżrael",
        "Italy": "L-Italja",
        "Jamaica": "Il-Ġamajka",
        "Japan": "Il-Ġappun",
        "Jersey": "Jersey",
        "Jordan": "Ġordan",
        "Kazakhstan": "Każakstan",
        "Kenya": "Kenja",
        "Kiribati": "Kiribati",
        "Korea, Democratic People's Republic of": "Il - Korea, ir - Repubblika Demokratika tal - Poplu",
        "Korea, Republic of": "Il - Korea, ir - Repubblika tal - Korea",
        "Kosovo": "Il-Kosovo",
        "Kuwait": "Kuwajt",
        "Kyrgyzstan": "Kirgiżistan",
        "Lao People's Democratic Republic": "Ir-Repubblika Demokratika tal-Poplu tal-Lao",
        "Latvia": "Il-Latvja",
        "Lebanon": "Libanu",
        "Lesotho": "Lesoto",
        "Liberia": "Liberja",
        "Libyan Arab Jamahiriya": "Jamahiriya Għarbija Libjana",
        "Liechtenstein": "Liechtenstein",
        "Lithuania": "Il-Litwanja",
        "Luxembourg": "Il-Lussemburgu",
        "Macao": "Makaw",
        "Macedonia, The Former Yugoslav Republic of": "Il-Maċedonja, l-Eks Repubblika Jugoslava ta '",
        "Madagascar": "Madagaskar",
        "Malawi": "Malawi",
        "Malaysia": "Malasja",
        "Maldives": "Maldivi",
        "Mali": "Mali",
        "Malta": "Malta",
        "Marshall Islands": "Gżejjer Marshall",
        "Martinique": "Martinika",
        "Mauritania": "Mawritanja",
        "Mauritius": "Mawrizju",
        "Mayotte": "Mayotte",
        "Mexico": "Messiku",
        "Micronesia, Federated States of": "Mikroneżja, Stati Federati",
        "Moldova, Republic of": "Repubblika tal - Moldova",
        "Monaco": "Monako",
        "Mongolia": "Mongolja",
        "Montenegro": "Montenegro",
        "Montserrat": "Montserrat",
        "Marocco": "Marokkju",
        "Mozambique": "Il-Możambik",
        "Myanmar": "Il-Mjanmar",
        "Namibia": "In-Namibja",
        "Nauru": "Nauru",
        "Nepal": "In-Nepal",
        "Netherlands": "Olanda",
        "Netherlands Antilles": "Antilles Olandiżi",
        "New Caledonia": "Kaledonja Ġdida",
        "New Zealand": "New Zealand",
        "Nicaragua": "Nikaragwa",
        "Niger": "Niġer",
        "Nigeria": "Niġerja",
        "Niue": "Niue",
        "Norfolk Island": "Gżira Norfolk",
        "Northern Mariana Islands": "Gżejjer Mariana tat-Tramuntana",
        "Norway": "In-Norveġja",
        "Oman": "Oman",
        "Pakistan": "Pakistan",
        "Palau": "Palau",
        "Palestinian Territory, Occupied": "Territorju Palestinjan, okkupat",
        "Panama": "Il-Panama",
        "Papua New Guinea": "Papua New Guinea",
        "Paraguay": "Paragwaj",
        "Peru": "Il-Perù",
        "Philippines": "Filippini",
        "Pitcairn": "Pitcairn",
        "Poland": "Polonja",
        "Portugal": "Il-Portugall",
        "Puerto Rico": "Puerto Rico",
        "Qatar": "Il-Qatar",
        "Reunion": "Reunion",
        "Romania": "Ir-Rumanija",
        "Russian Federation": "Federazzjoni Russa",
        "Rwanda": "Rwanda",
        "Saint Helena": "Santa Elena",
        "Saint Kitts and Nevis": "Saint Kitts u Nevis",
        "Saint Lucia": "Santa Luċija",
        "Saint Pierre and Miquelon": "Saint Pierre u Miquelon",
        "Saint Vincent and The Grenadines": "San Vinċenz u Il-Grenadini",
        "Samoa": "Samoa",
        "San Marino": "San Marino",
        "Sao Tome and Principe": "Sao Tome u Principe",
        "Saudi Arabia": "L-Arabja Sawdija",
        "Senegal": "Senegal",
        "Serbia": "Serbja",
        "Seychelles": "Seychelles",
        "Sierra Leone": "Sierra Leone",
        "Singapore": "Singapor",
        "Slovakia": "Is-Slovakkja",
        "Slovenia": "Slovenja",
        "Solomon Islands": "Gżejjer Solomon",
        "Somalia": "Somalja",
        "South Africa": "Afrika t'Isfel",
        "South Georgia and The South Sandwich Islands": "Ġorġja t'Isfel u Il-Gżejjer Sandwich tan-Nofsinhar",
        "Spain": "Spanja",
        "Sri Lanka": "Sri Lanka",
        "Sudan": "Sudan",
        "Suriname": "Is-Surinam",
        "Svalbard and Jan Mayen": "Svalbard u Jan Mayen",
        "Swaziland": "Swaziland",
        "Sweden": "L-Iżvezja",
        "Swizerland": "Is-Swizerland",
        "Syrian Arab Republic": "Ir-Repubblika Għarbija tas-Sirja",
        "Taiwan": "Tajwan",
        "Tajikistan": "Taġikistan",
        "Tanzania, United Republic of": "Tanzanija, ir-Repubblika Unita tal-Korea",
        "Thailand": "Tajlandja",
        "Timor-leste": "Timor-Leste",
        "Togo": "Togo",
        "Tokelau": "Tokelau",
        "Tonga": "Tonga",
        "Trinidad and Tobago": "Trinidad u Tobago",
        "Tunisia": "It-Tuneżija",
        "Turkey": "It-Turkija",
        "Turkmenistan": "Turkmenistan",
        "Turks and Caicos Islands": "Gżejjer Turks u Caicos",
        "Tuvalu": "Tuvalu",
        "Uganda": "Uganda",
        "Ukraine": "L-Ukrajna",
        "United Arab Emirates": "Emirati Għarab Magħquda",
        "United Kingdom": "Ir-Renju Unit",
        "United States": "Stati Uniti",
        "United States Minor Outlying Islands": "Gżejjer Minuri tal-Istati Uniti",
        "Uruguay": "L-Urugwaj",
        "Uzbekistan": "Użbekistan",
        "Vanuatu": "Vanuatu",
        "Venezuela": "Venezwela",
        "Viet Nam": "Vjetnam",
        "Virgin Islands, British": "Gżejjer Verġni, Ingliżi",
        "Virgin Islands, U.S.": "Gżejjer Verġni, U.S.",
        "Wallis and Futuna": "Wallis u Futuna",
        "Western Sahara": "Saħara tal-Punent",
        "Yemen": "Jemen",
        "Zambia": "Iż-Żambja",
        "Zimbabwe": "Żimbabwe",
    },
    //Alt texts
    'alt.example-1': 'żewġ karozzi mingħajr sewwieqa',
    'alt.example-2': 'id iżżomm mowbajl',
    'alt.example-3': "wiċċ ta’ mara",
    'alt.defining-ai': 'taħlita ta’ kliem relatat mal-IA',
    'alt.turing-test': 'raġel jiċċettja bil-kompjuter ma’ robot u mara',
    'alt.chinese-room': 'l-esperiment tal-kamra Ċiniża',
    'alt.chicken-crossing': 'robot f’dgħajsa jħares lejn tiġieġa, volpi, u xkora għalf tat-tiġieġ',
    'alt.a-to-b': 'id qed iżżomm mowbajl waqt li qed jintuża app tan-navigazzjoni',
    'alt.tic-tac-toe': 'żewġt itfal jilagħbu tic tac toe',
    'alt.self-driving-car': 'karozza mingħajr sewwieq quddiem tabella b’sinjal ta’ waqfien',
    'alt.spam-or-ham': 'email normali u email spam',
    'alt.mnist': 'sett tad-data MNIST',
    'alt.nearest-neighbor': 'żewġ persuni qed jixtru',
    'alt.nearest-neighbor-graph': 'klassifikatur tal-eqreb ġar',
    'alt.recommendation': 'raġel u mara jisimgħu l-mużika fuq il-mowbajl',
    'alt.price-real-estate': 'tlett idjar differenti',
    'alt.supervised-learning': 'bniedem jgħallem robot',
    'alt.dice': 'dadi',
    'alt.chess': 'bord taċ-ċess',
    'alt.poker': 'id iżżomm karti tal-logħob',
    'alt.eyes': 'tliet persuni b’għajnejhom kannella, persuna waħda b’għajnejha blu',
    'alt.search-1': 'mogħdija waħda li twassal għal għan',
    'alt.search-2': 'erba’ mogħdijiet differenti li jwasslu għall-istess għan',
    'alt.chicken-crossing-1': 'qsim tax-xmara: stat inizjali',
    'alt.chicken-crossing-2': 'qsim tax-xmara: l-ewwel tranżizzjoni',
    'alt.chicken-crossing-3': 'qsim tax-xmara: it-tranżizzjonijiet kollha',
    'alt.chicken-crossing-4': 'qsim tax-xmara, bl-aħjar mogħdija',
    'alt.chicken-crossing-5': 'qsim tax-xmara: stat inizjali',
    'alt.implication-1': 'raġel u mara',
    'alt.implication-2': 'għajnejn',
    'alt.implication-3': 'kamera tas-sorveljanza',
    'alt.implication-4': 'robot jaħdem fuq laptop',
    'alt.oddchange': 'xemx u sħab',
    'alt.brain': 'tpinġija ta’ moħħ',
    'alt.carstop': 'karozza mingħajr sewwieq quddiem tabella b’sinjal ta’ waqfien',
    'alt.gan': 'robot iżomm ritratt ta’ qattus u stampa ta’ sinjal ta’ waqfien',
    'alt.hammock': 'robot irewwaħ lil raġel mimdud fi branda',
    'alt.game-tree-1': 'siġra tal-logħba 1',
    'alt.game-tree-2': 'siġra tal-logħba 2',
    'alt.game-tree-3': 'siġra tal-logħba 3',
    'alt.game-tree-4': 'siġra tal-logħba 4',
    'alt.bayes-rule-1': 'immaġni li turi pożittivi u negattivi foloz għall-iskrinjar tal-kanċer',
    'alt.bayes-rule-2': 'immaġni li turi pożittivi u negattivi foloz għall-iskrinjar tal-kanċer',
    'alt.defineai': 'taħlita ta’ kliem relatat mal-IA',
    'alt.winter': 'robot rieqed fil-borra',
    'alt.terminator': 'robot tal-biża’',
}
