// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'bg',
  'globals.signIn': 'Вход',
  'globals.logIn': 'Вход',
  'globals.signUp': 'Регистрация',
  'globals.signOut': 'Изход',
  'globals.logOut': 'Изход',
  'globals.submit': 'Подаване',
  'globals.submitting': 'Подаване...',
  'globals.yourEmail': 'Вашият имейл',
  'globals.yourPassword': 'Вашата парола',
  'globals.courseOverview': 'Общ преглед на курса',
  'globals.forgotPassword': 'Забравена парола?',
  'globals.invalidCredentials': 'Невалидни идентификационни данни',
  'globals.section': 'Раздел',
  'globals.exercises': 'Упражнения',
  'globals.chapter': 'Глава',
  'globals.introToAI': 'Въведение в ИИ',
  'globals.buildingAI': 'Изграждане на ИИ',
  'globals.acceptPolicy': 'Приемете политиката',
  // section
  'section.footerEndOfTheChapter': 'Достигнахте до края на глава',
  'section.footerEndOfTheCourseTitle': 'Достигнахте до края на курса!',
  'section.footerEndOfTheCourseText': 'Като свършите всички упражнения и партньорски проверки, нашите инструктори ще проверят отговорите Ви.',
  'section.footerEndOfTheCourseBAI': 'Продължете пътуването си към възможността да реализирате първата си идея за ИИ.',
  'section.nextSection': 'Следващ раздел',
  'section.nextChapter': 'Следваща глава',
  'section.start': 'Начало',
  'section.logisticRegressionExample.slope': 'Наклон',
  'section.logisticRegressionExample.intercept': 'Отрез',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Направете партньорска проверка',
  'quiz.rejectedPeerReview': 'Отрицателна партньорска проверка',
  'quiz.unsubmittedAnswer': 'Неподаден отговор',
  'quiz.pendingPeerReview': 'Очаква партньорска проверка',
  // quiz
  'quiz.status.answered': 'Вече сте отговорили',
  'quiz.status.rejected': 'Отхвърлен отговор, опитайте отново',
  'quiz.status.unanswered': 'Без отговор',
  'quiz.status.exercisesCompleted': 'Завършени упражнения',
  'quiz.status.correctAnswers': 'Верни отговори',
  'quiz.message.rejectedInReview': 'Предишният Ви отговор беше отхвърлен при партньорска проверка. Моля, опитайте отново.',
  'quiz.youAnswered': 'Вие отговорихте',
  'quiz.yourAnswer': 'Вашият отговор...',
  'quiz.yourScore': 'Вашият резултат:',
  'quiz.quizNotLoaded': 'Неуспешно зареждане на теста',
  'quiz.words': 'Думи:',
  'quiz.exampleAnswer': 'Примерен отговор:',
  // peer review
  'peerreview.question1': 'Отговорът е по темата',
  'peerreview.question2': 'Отговорът е изчерпателен',
  'peerreview.question3': 'Отговорът е добре обоснован',
  'peerreview.question4': 'Отговорът е лесен за разбиране',
  'peerreview.thankYou': 'Благодарим ви, че проверихте отговорите на свои съкурсници!',
  'peerreview.giveMoreReviews': 'Ако разполагате с време,',
  'peerreview.giveMoreReviewsButton': 'направете още партньорски проверки',
  'peerreview.notEnoughAnswers': 'Обикновено тук се провежда партньорска проверка, но няма достатъчно отговорили на това упражнение.',
  'peerreview.selectAnswerMessage': 'Време е за партньорска проверка! Ето няколко отговора от други съкурсници. Изберете един от тях и го проверете. Направете поне три проверки.',
  'peerreview.minimumPeerReviewWarning': 'Упражнението ще бъде прието, ако направите поне три партньорски проверки.',
  'peerreview.select': 'Изберете',
  'peerreview.reportSpam': 'Докладвайте спам',
  'peerreview.feedback': 'Вашият отговор беше проверен и приет. Ето какво мислят за него Вашите съкурсници:',
  // exercise17
  'quiz.exercise17.xLabel': 'Години образование',
  'quiz.exercise17.yLabel': 'Продължителност на живота в години',
  'quiz.exercise17.textPart1': 'Нека изследваме връзката между общия брой години в училище (между предучилищна подготовка и университет) и продължителността на живота. Разполагаме с данни от три различни държави, онагледени чрез точки на фигура:',
  'quiz.exercise17.textPart2': 'В първата средният брой години в училище е 10 и продължителността на живота е 57 години; във втората — средният брой години в училище е 13 и продължителността на живота е 53 години; в третата — средният брой години в училище е 20 и продължителността на живота — 80 години.',
  'quiz.exercise17.textPart3': 'Можете да провлачите крайните точки на плътната линия, за да я разположите така, че да следва посоката на точките от данните. Имайте предвид, че линията няма да съвпадне напълно с точките от данните и това е нормално: някои от точките на данните ще останат над линията, а други — под нея. Важното е линията да показва общата тенденция.',
  'quiz.exercise17.textPart4': 'Като нагласите линията, можете да я използвате, за да предвидите продължителността на живота.',
  'quiz.exercise17.textPart5': 'Предвид данните, какво можете да заключите за продължителността на живота на хората, които прекарват 15 години в образователната система? Важно: дори да стигнете до конкретна прогноза (до част от годината) с нагласяне на линията, това не означава обезателно, че тя е категорична. Преди да отговорите, имайте предвид ограничения обем данни.',
  // exercise18
  'quiz.exercise18.xLabel': 'Години образование',
  'quiz.exercise18.yLabel': 'Продължителност на живота в години',
  'quiz.exercise18.textPart1': 'В предишното упражнение разполагахме с данни само от три държави. Пълният набор съдържа данни от 14 различни държави, представени в диаграма тук:',
  'quiz.exercise18.textPart2': 'Въз основа на данните бихте ли променили прогнозата си за продължителността на живота сред хората с 15 години в образователната система? Ако отговорът е „Да“, защо?',
  'quiz.exercise18.textPart3': 'Кои от следните варианти отговарят най-добре на прогнозата ви за продължителността на живота при хората с 15 години в образователната система? Изберете най-конкретния вариант, за който според вас има основание, като нагодите правата линия към данните по-горе.',
  // exercise19
  'quiz.exercise19.xLabel': 'Часове подготовка',
  'quiz.exercise19.yLabel': 'Вероятност за успешно полагане на изпит',
  'quiz.exercise19.textPart1': 'Всяка точка на фигурата съответства на един студент. В долната част на фигурата имаме скалата с броя часове, в които студентът е учил за изпита, като студентите, които са издържали изпита, са показани с точки в горната част на диаграмата, а тези, които не са — в долната. Ще използваме скалата в ляво, за да покажем прогнозната вероятност за успешно полагане на изпит, която ще получим чрез модела на логистичната регресия, както ще обясним малко по-долу. От тази фигура виждате, че в общи линии студентите, които са учили повече часове, имат по-голям шанс да издържат изпита. Крайните случаи позволяват интуитивно заключение: с по-малко от един час работа е много трудно да се издържи изпита, но повечето студенти с дългочасова подготовка ще издържат изпита. Какво обаче е положението при онези, които не отделят нито много, нито малко време за учене? Ако учите 6 часа, какви са шансовете ви да издържите изпита?',
  'quiz.exercise19.textPart2': 'Можем да определим количествено вероятността за успешно полагане на изпита чрез логистична регресия. Кривата на фигурата може да се разглежда като вероятността за успех: например при петчасова подготовка вероятността за успех е малко над 20 %. Няма да разглеждаме подробно как е получена кривата, но това става подобно на получаването на теглата в линейната регресия.',
  'quiz.exercise19.textPart3': 'Чрез данните по-горе, ако искате да имате 80 % шанс да издържите изпит в университета, приблизително колко часа трябва да учите?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Входни данни',
  'quiz.exercise22.sigmoidYLabel': 'Сигмоидни изходни данни',
  'quiz.exercise22.stepXLabel': 'Входни данни',
  'quiz.exercise22.stepYLabel': 'Изходни данни от стъпаловидната функция',
  'quiz.exercise22.identityXLabel': 'Входни данни',
  'quiz.exercise22.identityYLabel': 'Изходни данни от тъждествена функция',
  'quiz.exercise22.textPart1': 'По-долу са начертани диаграми за три различни активационни функции с различни свойства. Първо е представена сигмоидната функция, след това стъпаловидната функция и накрая тъждествената функция.',
  // exercise23
  'quiz.exercise23.happyFaces': 'весели лица, класифицирани правилно',
  'quiz.exercise23.sadFaces': 'тъжни лица, класифицирани правилно',
  // certificate availability
  'certificate.failedToCheck': 'Неуспешна проверка за наличието на удостоверение',
  'certificate.failedToGenerate': 'Неуспешно генериране на удостоверение',
  'certificate.notYetAvailable': 'Ще намерите тук своето удостоверение след като Ви потвърдим официално. Това може да отнеме известно време. Благодарим за търпението!',
  'certificate.updateName': 'Вашето удостоверение е на разположение! Моля, актуализирайте своето собствено и фамилно име във Вашия профил чрез формуляра по-долу, за да ги използваме в сертификата.',
  'certificate.generate': 'Генерирайте своето удостоверение по-долу.',
  'certificate.generateTextPart1': 'Ще бъде генерирано удостоверение за',
  'certificate.generateTextPart2': 'Проверете внимателно дали е попълнено правилно, преди да генерирате удостоверението, тъй като името не може да бъде променяно впоследствие. Можете да промените името си във формуляра по-долу.',
  'certificate.generateButton': 'Генериране на удостоверение',
  'certificate.downloadButton': 'Изтеглете удостоверение',
  'certificate.checkingAvailability': 'Проверява се наличието на удостоверение...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Правилно класифицирано',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Неправилно класифицирано',
  // section blocks
  'section.block.note': 'Бележка',
  'section.block.example': 'Пример',
  'section.block.keyTerminology': 'Основна терминология',
  'section.partSummary.spectrumTextPart1': 'Присъединете се към ',
  'section.partSummary.spectrum': 'общността на курса Elements of AI',
  'section.partSummary.spectrumTextPart2': ', за да обсъдите и зададете въпросите си по тази глава.',
  'section.partSummary.feedbackTitle': 'Споделете с нас коментарите си по тази част.',
  'section.partSummary.feedbackPlaceholder': 'Изпратете своя коментар тук...',
  'section.partSummary.feedbackSuccess': 'Благодарим за информацията!',
  // 404
  'globals.404.title': '404 - Страницата не е намерена',
  'globals.404.intro': "Избрахте маршрут, който не съществува... случва се!",
  'globals.loading': 'Зареждане...',
  // footer / menu
  'globals.menu': 'Меню',
  'globals.menu.about': 'Относно',
  'globals.menu.faq': 'ЧЗВ',
  'globals.menu.privacyPolicy': 'Политика за личните данни',
  'globals.menu.termsofService': 'Условия за ползване',
  'globals.menu.myProfile': 'Моят профил',
  // Dashboard/Index
  'dashboard.title': 'Добре дошли в този курс!',
  'dashboard.congratulations.title': 'Поздравления!',
  'dashboard.congratulations.message': 'Вие приключихте курса „Elements of AI“',
  'dashboard.congratulations.downloadCertificate': 'Изтеглете удостоверение',
  'dashboard.notLoggedIn.title': 'Добре дошли!',
  'dashboard.notLoggedIn.message': 'Регистрирайте се или влезте в системата, за да получите пълен достъп до курса. Безплатно е!',
  // Account
  'account.certificate': 'Удостоверение',
  'account.myProfile': 'Моят профил',
  'account.profileInformation': 'Информация за профила',
  'account.credits': 'ECTS кредити',
  'account.changePassword': 'Промяна на паролата',
  'account.changeLanguage': 'Смяна на езика',
  'account.goToCourse': 'Отидете на курса',
  'account.buildingAINew': 'Нов курс по английски език',
  'account.accept': 'Приемете',
  'account.decline': 'Откажете',
  'account.courses': 'Курсове',
  // Sign Up page
  'signUp.description': 'Попълнете формуляра по-долу, за да създадете нов потребителски профил в mooc.fi',
  'signUp.email': 'Вашият имейл',
  'signUp.firstName': 'Вашето собствено име',
  'signUp.lastName': 'Вашето фамилно име',
  'signUp.postalCode': 'Пощенски код',
  'signUp.country': 'Къде живеете?',
  'signUp.Password': 'Парола',
  'signUp.confirmPassword': 'Потвърдете паролата',
  'signUp.howToStudyTitle': 'Как искате да учите?',
  'signUp.6weeksTitle': 'Курс от 6 седмици',
  'signUp.6weeksSubTitle': 'Препоръчително (ок. 5 часа седмично)',
  'signUp.6weeksText': 'Наличието на краен срок доказано стимулира участниците да приключат курса.',
  'signUp.selfPacedTitle': 'Самостоятелно темпо',
  'signUp.selfPacedText': 'Можете да напредвате със свое темпо.',
  'signUp.ectsTitle': 'Искате ли ECTS кредити?',
  'signUp.ectsTitleSwe': ' ',
  'signUp.ectsStudent': 'Аз съм студент в университета в Хелзинки. Ако поставите отметка тук и въведете своя студентски номер, ще получите автоматично ECTS кредити.',
  'signUp.ectsStudentSwe': ' ',
  'signup.ectsStudentSweLink': ' ',
  'signUp.ectsOpenUni': 'Поставете отметка в квадратчето, за да получите имейл с инструкции как да поискате ECTS кредити',
  'signUp.studentNumber': 'Студентски номер в Хелзинкския университет (ако сте студент)',
  'signUp.privacyOptIn': 'Разрешавам събраните данни за работата ми по време на обучението да се използват за научноизследователски цели. Данните съдържат информация за използването на материалите и упражненията от курса. Публикациите не разкриват ничия самоличност.',
  'signUp.marketingOptIn': 'Желая да получавам новини за версии на други езици и информация за нови курсове. Съгласен съм да предоставя данните си за контакт, за да получавам съобщения съобразени с мен на платформи на трети страни. Прочетете повече за нашата политика за защита на личните данни.',
  'signUp.acceptPolicyFirst': 'Приемам Политиката за поверителност и Общите условия.',
  'signUp.privacyLink': 'Прочетете нашата политика за защита на личните данни',
  'signUp.error': 'Формулярът съдържа грешки. Моля, проверете формуляра.',
  // Sign in page
  'signIn.title': 'Вход',
  'signIn.intro': 'Използвайте формуляра по-долу, за да влезете в системата със своя профил в mooc.fi',
  'signIn.email': 'Вашият имейл',
  'signIn.password': 'Вашата парола',
  'signIn.noAccount': "Нямате потребителски профил?",
  'signIn.signUpNow': 'Регистрирайте се сега',
  // Forgot password
  'pwForget.title': 'Забравена парола?',
  'pwForget.description': 'Попълнете имейл адреса от профила си в mooc.fi и ще Ви изпратим връзка за подновяване на паролата',
  'pwForget.sendResetLink': 'Изпрати връзка за подновяване',
  'pwForget.completedDescription': 'Скоро ще получите имейл от mooc.fi с връзка за подновяване на паролата. Ако не го виждате, проверете папката за спам.',
  'pwForget.problemDescription': 'Ако срещнете проблеми, пишете на mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent': 'Изпратен имейл за подновяване на парола',
  'pwForget.emailPlaceholder': 'Електронна поща',
  // password change
  'pwChange.authError': 'Неуспешно влизане. Моля, опитайте отново.',
  'pwChange.unexpError': 'Неуспешно актуализиране на паролата поради неочаквана грешка',
  'pwChange.required': 'Задължително поле',
  'pwChange.pwMustMatch': 'Паролите трябва да съвпадат',
  'pwChange.currentPassword': 'Текуща парола',
  'pwChange.newPassword': 'Нова парола',
  'pwChange.confirmPassword': 'Потвърждаване на новата парола',
  'pwChange.changingPassword': 'Промяна на паролата...',
  'pwChange.changePassword': 'Промяна на паролата',
  'pwChange.passwordChanged': 'Паролата е променена',
  // user details
  'userDetails.authError': 'Неуспешно влизане. Моля, опитайте отново.',
  'userDetails.unexpError': 'Неуспешно актуализиране на профила поради неочаквана грешка',
  'userDetails.required': 'Задължително поле',
  'userDetails.invalidEmail': 'Невалиден адрес на електронна поща',
  'userDetails.email': 'Електронен адрес',
  'userDetails.emailAddress': 'Email address',
  'userDetails.firstName': 'First name',
  'userDetails.lastName': 'Last name',
  'userDetails.language': 'Language',
  'userDetails.updateProfile': 'Актуализиране на профила',
  'userDetails.updatingProfile': 'Актуализиране на профила...',
  'userDetails.profileUpdated': 'Профилът е актуализиран',
  // languages
  'languages.en.long': 'английски',
  'languages.fi.long': 'фински',
  'languages.se.long': 'шведски',
  'languages.de.long': 'немски (Нидерландия)',
  'languages.ee.long': 'естонски',
  'languages.it.long': 'италиански',
  'languages.fr.long': 'френски (Франция)',
  'languages.no.long': 'норвежки',
  'languages.lv.long': 'латвийски',
  'languages.mt.long': 'малтийски',
  'languages.fr-be.long': 'френски (Белгия)',
  'languages.lt.long': 'литовски',
  'languages.nl-be.long': 'нидерландски (Белгия)',
  'languages.en-ie.long': 'английски (Ирландия)',
  'languages.pl.long': 'полски',
  'languages.da.long': 'датски',
  'languages.ro.long': 'румънски',
  'languages.hr.long': 'хърватски',
  'languages.bg.long': 'български',
  'languages.cs.long': 'чешки',
  'languages.en-lu.long': 'английски (Люксембург)',
  'languages.sl.long': 'словенски',
  'languages.de-at.long': 'немски (Австрия)',
  'languages.nl.long': 'нидерландски (Нидерландия)',
  'languages.sk.long': 'словашки',
  'languages.pt.long': 'португалски',
  'languages.es.long': 'испански',
  'languages.el.long': 'гръцки',
  'languages.ga.long': 'ирландски',
  'languages.is.long': 'исландски',
  // Email verfification
  'emailVerification.completedMessage': 'Вашият адрес на електронна поща беше валидиран',
  'emailVerification.verified': 'Валидиран',
  // Exercises
  'exercise.loading': 'Упражнението се зарежда...',
  'exercise.signUp': 'Регистрирайте се, за да решавате упражнения',
  'exercise.score': 'Вашият резултат:',
  'exercise.wrongLocale': 'Погрешна регионална настройка',
  // Part tempplate
  'partTemplate.intro1': 'В глава ',
  'partTemplate.intro2': ' ще разгледаме изброените по-долу раздели. Щракнете върху заглавието на първия, за да започнете: ',
  'partTemplate.spectrumPart1': 'Присъединете се към ',
  'partTemplate.spectrum': 'общността на курса Elements of AI',
  'partTemplate.spectrumPart2': ', за да обсъдите и зададете въпросите си по тази глава.',
  // missing info
  'missingInfo.title': 'Липсва информация',
  'missingInfo.body': 'Обикновено задаваме тези въпроси, когато потребителите се регистрират, но Вие сте влезли в съществуващ mooc.fi профил. Моля, попълнете формуляра по-долу, за да продължите.',
  'missingInfo.privacyPolicy': 'Прочетете нашата политика за защита на личните данни',
  'missingInfo.error': 'Формулярът съдържа грешки. Моля, проверете формуляра.',
  'missingInfo.privacyPolicyUpdateBody': "Актуализирахме нашата политика за поверителност. Ето резюме на промените. Ние рекламираме нашите курсове пред потенциални нови студенти, като формираме аудитория в маркетингови платформи. Това може да включва споделяне на имейл адреси с тези платформи. За същата цел анонимизирани данни за активността ще бъдат споделяни с маркетинговите мрежи. Можете да предотвратите споделянето на информация за имейли, като премахнете отметката от квадратчето за маркетингово съгласие в профила си на курса. За да предотвратите споделянето на данни за активността, можете да промените настройките на бисквитките на сайта на курса. Приемането на тези условия е необходимо, за да продължите да използвате услугата.",
  'missingInfo.privacyPolicyUpdateLink': 'Можете да прегледате политиката за поверителност тук.',
  // routes
  'routes.LANDING': '/bg/',
  'routes.SIGN_UP': '/bg/signup',
  'routes.SIGN_IN': '/bg/signin',
  'routes.MISSING_INFO': '/bg/missinginfo',
  'routes.PASSWORD_FORGET': '/bg/pw-forget',
  'routes.HOME': '/bg/home',
  'routes.ACCOUNT': '/bg/account',
  'routes.ACCEPT_POLICY': '/bg/accept-policy',
  'routes.PRIVACY': 'https://www.elementsofai.com/bg/faq/%D0%BF%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0-%D0%B7%D0%B0-%D0%BB%D0%B8%D1%87%D0%BD%D0%B8%D1%82%D0%B5-%D0%B4%D0%B0%D0%BD%D0%BD%D0%B8',
  'routes.FAQ': 'https://www.elementsofai.com/bg/faq',
  'routes.ABOUT': 'https://www.elementsofai.com/bg',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/bg-bulgarska-obshnost',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/ie/faq/how-can-i-get-2-ects-credits',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Чаши кафе на ден',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Брой програмирани редове',
  // meta
  'meta.title': 'Безплатен онлайн курс',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Научете повече за университета в Хелзинки и за курса на Reaktor по ИИ за студенти и професионалисти — не се изискват специални умения по програмиране или математика.',
  'meta.sitename': 'Elements of AI',
  // ECTS
  'ects.text': 'Тук ще получите инструкции как да си поискате кредити по Европейската система за трансфер и натрупване на кредити (ECTS) след приключване на курса.',
  'ects.textCompleted': 'Ако разполагате с финландски социалноосигурителен номер, можете да поискате ECTS кредити, като щракнете върху ',
  'ects.link': 'тук.',
  'ects.info': 'Искате ли да кандидатствате за ECTS кредити?',
  'ects.readMore': 'Прочетете още',
  // Countries
  'countryselect.select': "Моля изберете",
  'Finland': "Финландия",
  'Estonia': "Естония",
  'Germany': "Германия",
  'Sweden': "Швеция",
  "Countries": {
    "Afganistan": "Афганистан",
    "Åland Islands": "Оландски острови",
    "Albania": "Албания",
    "Algeria": "Алжир",
    "American Samoa": "Американска Самоа",
    "Andorra": "Андора",
    "Angola": "Ангола",
    "Anguilla": "Ангуила",
    "Antarctica": "Антарктида",
    "Antigua and Barbuda": "Антигуа и Барбуда",
    "Argentina": "Аржентина",
    "Armenia": "Армения",
    "Aruba": "Аруба",
    "Australia": "Австралия",
    "Austria": "Австрия",
    "Azerbaijan": "Азербайджан",
    "Bahamas": "Бахамски острови",
    "Bahrain": "Бахрейн",
    "Bangladesh": "Бангладеш",
    "Barbados": "Барбадос",
    "Belarus": "Беларус",
    "Belgium": "Белгия",
    "Belize": "Белиз",
    "Benin": "Бенин",
    "Bermuda": "Бермуда",
    "Bhutan": "Бутан",
    "Bolivia": "Боливия",
    "Bosnia and Herzegovina": "Босна и Херцеговина",
    "Botswana": "Ботсуана",
    "Bouvet Island": "Остров Буве",
    "Brazil": "Бразилия",
    "British Indian Ocean Territory": "Британска територия в Индийския океан",
    "Brunei Darussalam": "Бруней Дарусалам",
    "Bulgaria": "България",
    "Burkina Faso": "Буркина Фасо",
    "Burundi": "Бурунди",
    "Cambodia": "Камбоджа",
    "Cameroon": "Камерун",
    "Canada": "Канада",
    "Cape Verde": "Кабо Верде",
    "Cayman Islands": "Кайманови острови",
    "Central African Republic": "Централноафриканска република",
    "Chad": "Чад",
    "Chile": "Чили",
    "China": "Китай",
    "Christmas Island": "Коледен остров",
    "Cocos (Keeling) Islands": "Кокосови (Килинг) острови",
    "Colombia": "Колумбия",
    "Comoros": "Коморски острови",
    "Congo": "Конго",
    "Congo, The Democratic Republic of The": "Конго, Демократична република",
    "Cook Islands": "Острови Кук",
    "Costa Rica": "Коста Рика",
    "Cote D'ivoire": "Кот д'Ивоар",
    "Croatia": "Хърватия",
    "Cuba": "Куба",
    "Cyprus": "Кипър",
    "Czech Republic": "Чехия",
    "Denmark": "Дания",
    "Djibouti": "Джибути",
    "Dominica": "Доминика",
    "Dominican Republic": "Доминиканска република",
    "Ecuador": "Еквадор",
    "Egypt": "Египет",
    "El Salvador": "Ел Салвадор",
    "Equatorial Guinea": "Екваториална Гвинея",
    "Eritrea": "Еритрея",
    "Estonia": "Естония",
    "Ethiopia": "Етиопия",
    "Falkland Islands (Malvinas)": "Фолклендските острови (Малвини)",
    "Faroe Islands": "Фарьорските острови",
    "Fiji": "Фиджи",
    "Finland": "Финландия",
    "France": "Франция",
    "French Guiana": "Френска Гвиана",
    "French Polynesia": "Френска полинезия",
    "French Southern Territories": "Френски южни територии",
    "Gabon": "Габон",
    "Gambia": "Гамбия",
    "Georgia": "Грузия",
    "Germany": "Германия",
    "Ghana": "Гана",
    "Gibraltar": "Гибралтар",
    "Greece": "Гърция",
    "Greenland": "Гренландия",
    "Grenada": "Гренада",
    "Guadeloupe": "Гваделупа",
    "Guam": "Гуам",
    "Guatemala": "Гватемала",
    "Guernsey": "вълнена фланела",
    "Guinea": "Гвинея",
    "Guinea-Bissau": "Гвинея-Бисау",
    "Guyana": "Гвиана",
    "Haiti": "Хаити",
    "Heard Island and Mcdonald Islands": "Острови Хърд и Макдоналд",
    "Holy See (Vatican City State)": "Свети престол (град Ватикан)",
    "Honduras": "Хондурас",
    "Hong Kong": "Хонг Конг",
    "Hungary": "Унгария",
    "Iceland": "Исландия",
    "India": "Индия",
    "Indonesia": "Индонезия",
    "Iran, Islamic Republic of": "Иран, Ислямска република",
    "Iraq": "Ирак",
    "Ireland": "Ирландия",
    "Isle of Man": "Остров Ман",
    "Israel": "Израел",
    "Italy": "Италия",
    "Jamaica": "Ямайка",
    "Japan": "Япония",
    "Jersey": "жарсе",
    "Jordan": "Йордания",
    "Kazakhstan": "Казахстан",
    "Kenya": "Кения",
    "Kiribati": "Кирибати",
    "Korea, Democratic People's Republic of": "Корея, Демократична народна република на",
    "Korea, Republic of": "Република Корея",
    "Kosovo": "Косово",
    "Kuwait": "Кувейт",
    "Kyrgyzstan": "Киргизстан",
    "Lao People's Democratic Republic": "Лаоска Народна демократична република",
    "Latvia": "Латвия",
    "Lebanon": "Ливан",
    "Lesotho": "Лесото",
    "Liberia": "Либерия",
    "Libyan Arab Jamahiriya": "Либийска арабска Джамахирия",
    "Liechtenstein": "Лихтенщайн",
    "Lithuania": "Литва",
    "Luxembourg": "Люксембург",
    "Macao": "Макао",
    "Macedonia, The Former Yugoslav Republic of": "Македония, Бивша югославска република",
    "Madagascar": "Мадагаскар",
    "Malawi": "Малави",
    "Malaysia": "Малайзия",
    "Maldives": "Малдиви",
    "Mali": "Мали",
    "Malta": "Малта",
    "Marshall Islands": "Маршаловите острови",
    "Martinique": "Мартиника",
    "Mauritania": "Мавритания",
    "Mauritius": "Мавриций",
    "Mayotte": "Майот",
    "Mexico": "Мексико",
    "Micronesia, Federated States of": "Микронезия, федерални държави на",
    "Moldova, Republic of": "Молдова, Република",
    "Monaco": "Монако",
    "Mongolia": "Монголия",
    "Montenegro": "Черна гора",
    "Montserrat": "Монсерат",
    "Marocco": "Мароко",
    "Mozambique": "Мозамбик",
    "Myanmar": "Мианмар",
    "Namibia": "Намибия",
    "Nauru": "Науру",
    "Nepal": "Непал",
    "Netherlands": "Холандия",
    "Netherlands Antilles": "Холандски Антили",
    "New Caledonia": "Нова Каледония",
    "New Zealand": "Нова Зеландия",
    "Nicaragua": "Никарагуа",
    "Niger": "Нигер",
    "Nigeria": "Нигерия",
    "Niue": "Ниуе",
    "Norfolk Island": "Остров Норфолк",
    "Northern Mariana Islands": "Северни Мариански острови",
    "Norway": "Норвегия",
    "Oman": "Оман",
    "Pakistan": "Пакистан",
    "Palau": "Palau",
    "Palestinian Territory, Occupied": "Палестинска територия, окупирана",
    "Panama": "Панама",
    "Papua New Guinea": "Папуа-Нова Гвинея",
    "Paraguay": "Парагвай",
    "Peru": "Перу",
    "Philippines": "Филипини",
    "Pitcairn": "Питкерн",
    "Poland": "Полша",
    "Portugal": "Португалия",
    "Puerto Rico": "Пуерто Рико",
    "Qatar": "Катар",
    "Reunion": "Реюнион",
    "Romania": "Румъния",
    "Russian Federation": "Руска федерация",
    "Rwanda": "Руанда",
    "Saint Helena": "Света Елена",
    "Saint Kitts and Nevis": "Сейнт Китс и Невис",
    "Saint Lucia": "Света Лусия",
    "Saint Pierre and Miquelon": "Сен Пиер и Микелон",
    "Saint Vincent and The Grenadines": "Свети Винсент и Гренадини",
    "Samoa": "Самоа",
    "San Marino": "Сан Марино",
    "Sao Tome and Principe": "Сао Томе и Принсипи",
    "Saudi Arabia": "Саудитска Арабия",
    "Senegal": "Сенегал",
    "Serbia": "Сърбия",
    "Seychelles": "Сейшелските острови",
    "Sierra Leone": "Сиера Леоне",
    "Singapore": "Сингапур",
    "Slovakia": "Словакия",
    "Slovenia": "Словения",
    "Solomon Islands": "Соломоновите острови",
    "Somalia": "Сомалия",
    "South Africa": "Южна Африка",
    "South Georgia and The South Sandwich Islands": "Южна Джорджия и Южните Сандвичеви острови",
    "Spain": "Испания",
    "Sri Lanka": "Шри Ланка",
    "Sudan": "Судан",
    "Suriname": "Суринам",
    "Svalbard and Jan Mayen": "Свалбард и Ян Майен",
    "Swaziland": "Свазиленд",
    "Sweden": "Швеция",
    "Swizerland": "Швейцария",
    "Syrian Arab Republic": "Сирийска Арабска Република",
    "Taiwan": "Тайван",
    "Tajikistan": "Таджикистан",
    "Tanzania, United Republic of": "Танзания, Обединена република",
    "Thailand": "Тайланд",
    "Timor-leste": "Източен Тимор",
    "Togo": "Того",
    "Tokelau": "Токелау",
    "Tonga": "лека индийска двуколка",
    "Trinidad and Tobago": "Тринидад и Тобаго",
    "Tunisia": "Тунис",
    "Turkey": "Турция",
    "Turkmenistan": "Туркменистан",
    "Turks and Caicos Islands": "Острови Търкс и Кайкос",
    "Tuvalu": "Тувалу",
    "Uganda": "Уганда",
    "Ukraine": "Украйна",
    "United Arab Emirates": "Обединени арабски емирства",
    "United Kingdom": "Великобритания",
    "United States": "Съединени щати",
    "United States Minor Outlying Islands": "Малки отдалечени острови на САЩ",
    "Uruguay": "Уругвай",
    "Uzbekistan": "Узбекистан",
    "Vanuatu": "Вануату",
    "Venezuela": "Венецуела",
    "Viet Nam": "Виетнам",
    "Virgin Islands, British": "Вирджински острови, британски",
    "Virgin Islands, U.S.": "Вирджински острови, САЩ",
    "Wallis and Futuna": "Уолис и Футуна",
    "Western Sahara": "Западна Сахара",
    "Yemen": "Йемен",
    "Zambia": "Замбия",
    "Zimbabwe": "Зимбабве"
  },
  //Alt texts
  'alt.example-1': 'два автомобила без водач',
  'alt.example-2': 'ръка с мобилен телефон',
  'alt.example-3': "лице на жена",
  'alt.defining-ai': 'безразборни термини от областта на ИИ',
  'alt.turing-test': 'мъж събеседва с компютър и жена',
  'alt.chinese-room': 'експеримент на китайската стая',
  'alt.chicken-crossing': 'робот в лодка с кокошка, лисица и храна за кокошки',
  'alt.a-to-b': 'ръка с телефон, който използва приложение за навигация',
  'alt.tic-tac-toe': 'две деца играят морски шах',
  'alt.self-driving-car': 'автомобили без водач при знак „Стоп“',
  'alt.spam-or-ham': 'редовен имейл и спам ',
  'alt.mnist': 'набор от данни MNIST',
  'alt.nearest-neighbor': 'двама души пазаруват',
  'alt.nearest-neighbor-graph': 'класифициране по метода на най-близкия съсед',
  'alt.recommendation': 'мъж и жена слушат музика през телефон',
  'alt.price-real-estate': 'три различни къщи',
  'alt.supervised-learning': 'човек обучава робот',
  'alt.dice': 'зарове',
  'alt.chess': 'шахматна дъска',
  'alt.poker': 'ръка с карти за игра',
  'alt.eyes': 'ръка с карти за игра',
  'alt.search-1': 'единствен път до целта',
  'alt.search-2': 'единствен път до целта',
  'alt.chicken-crossing-1': 'кокошка прекосява първоначално състояние',
  'alt.chicken-crossing-2': 'кокошка прекосява първи преход',
  'alt.chicken-crossing-3': 'кокошка прекосява първоначално състояние',
  'alt.chicken-crossing-4': 'кокошка прекосява най-добър маршрут',
  'alt.chicken-crossing-5': 'кокошка прекосява първоначално състояние',
  'alt.implication-1': 'мъж и жена',
  'alt.implication-2': 'очи ',
  'alt.implication-3': 'камера за наблюдение',
  'alt.implication-4': 'робот работи на лаптоп ',
  'alt.oddchange': 'слънце и облаци ',
  'alt.brain': 'рисунка на мозък ',
  'alt.carstop': 'автомобили без водач при знак „Стоп“',
  'alt.gan': 'робот държи карта с котка и карта със знак „Стоп“ ',
  'alt.hammock': 'робот вее с ветрило на човек в хамак',
  'alt.game-tree-1': 'игрово дърво 1',
  'alt.game-tree-2': 'игрово дърво 2',
  'alt.game-tree-3': 'игрово дърво 3',
  'alt.game-tree-4': 'игрово дърво 4',
  'alt.bayes-rule-1': 'изображение на погрешно положителни и погрешно отрицателни резултати на онкологичен скрининг ',
  'alt.bayes-rule-2': 'изображение на погрешно положителни и погрешно отрицателни резултати на онкологичен скрининг  ',
  'alt.defineai': 'безразборни термини от областта на ИИ',
  'alt.winter': 'робот спи в снега',
  'alt.terminator': 'страшен робот',
}
