import { localizeCourseId } from "../services/locale";

const COURSE_ID = "elements-of-ai";
const COURSE_SHORT_ID = "ai";

export const config = {
  MINIMUM_PEER_REVIEWS_GIVEN: 3,
  MINIMUM_PEER_REVIEWS_RECEIVED: 2,
  COURSE_ID: COURSE_ID,
  LOCALIZED_COURSE_ID: localizeCourseId(COURSE_ID),
  COURSE_SHORT_ID: COURSE_SHORT_ID
};
