import React from 'react'
import { Router } from '@reach/router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { createStore, syncStore } from './src/state/appStore'
import { FadeLoader } from 'react-spinners'
import { currentLocale } from './src/services/locale'
import Layout from './src/components/Layout'


let prevLocation = typeof window !== 'undefined' && window ? window.location : null
const { persistor, store } = createStore()

const historyListener = (location) => {
  if (prevLocation && currentLocale(prevLocation) !== currentLocale(location)) {
    syncStore()
  }

  prevLocation = location
}
//wraps components into PresistGate and replaces the history of the router 
//history replacing not possible with the new @react/router, so must be replaced 

export const wrapRootElement = ({ element }) => {
    // TODO: this - and ssr - should have a check which data to load
    // and which to retrieve from cache
    const ConnectedRootElement= (
        <Provider store={store}>
        <PersistGate 
                loading={<FadeLoader />} 
                onBeforeLift={() => syncStore()}
                persistor={persistor}>
            <div>{ element }</div>
          </PersistGate>
        </Provider>
    )

    return ConnectedRootElement
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout location={props.location}> { element } </Layout>
  
}