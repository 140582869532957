import _ from "lodash";

const USER_KEY = "tmc.user";
const USER_DETAILS_KEY = "tmc.user.details";

const getUser = () => getLocalStorageValue(USER_KEY);
const getUserDetails = () => getLocalStorageValue(USER_DETAILS_KEY);
const setUser = user => setLocalStorageValue(USER_KEY, user);
const setUserDetails = userDetails =>
  setLocalStorageValue(USER_DETAILS_KEY, userDetails);

const removeUser = () => {
  if (localStorageNotDefined()) return undefined;

  window.localStorage.removeItem(USER_KEY);
  window.localStorage.removeItem(USER_DETAILS_KEY);
};

const localStorageNotDefined = () =>
  typeof window === `undefined` || !window.localStorage;

const getLocalStorageValue = key => {
  if (localStorageNotDefined()) return undefined;

  try {
    const valueStr = window.localStorage[key];
    return _.isString(valueStr) ? JSON.parse(valueStr) : undefined;
  } catch (error) {
    console.log(`Failed to parse local storage value of key ${key}: ${error}`);
    return undefined;
  }
};

const setLocalStorageValue = (key, value) => {
  if (localStorageNotDefined()) return undefined;

  window.localStorage[key] = JSON.stringify(value);
};

export { getUser, getUserDetails, setUser, setUserDetails, removeUser };
