import _ from "lodash";
import languages from "../../data/languages";
import { getUserDetails } from "../state/account";

export const currentLocale = (
  location = typeof window !== "undefined" && window && window.location
) => {
  if (location) {
    const langcode = location.pathname.split("/")[1];

    if (~languages.langs.indexOf(langcode)) {
      return langcode;
    }
  }

  return languages.defaultLangKey;
};

export const currentUserLocale = () => {
  const userDetails = getUserDetails();
  // returns default even if language exists but is ""
  const userLanguage = _.get(userDetails, "extra_fields.language");
  if (!languages.langs.includes(userLanguage)) {
    return languages.defaultLangKey;
  }
  return userLanguage || languages.defaultLangKey;
};

export const localizeCourseIdTo = (id, locale) => {
  return id + (locale !== languages.defaultLangKey ? `-${locale}` : "");
};

export const localizeCourseId = id => {
  const locale = currentLocale();

  return localizeCourseIdTo(id, locale);
};

export const currentLocalizedCourseId = () => localizeCourseId(currentLocale());

export const isWrongLocale = () => currentLocale() !== currentUserLocale();
