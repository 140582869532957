import _ from "lodash";
import * as actionTypes from "./actions";
import { RESET_STATE } from "../actions";
import dateToUnix from "../../utilities/datetounix";
//import { handleActions } from 'redux-actions'

// expire in 15 minutes
export const expiryDate = {
  expireAt: dateToUnix(new Date()) + 900
};

const initialState = {
  error: ""
  /* [quizId]: {
    data: <a target='_blank' rel='noopener noreferrer'nswer data>,
    peerReviews: <peer review data>,
    validation: <validation data>,
    expireAt: <expiry as datetounix>
    loading, <...booleans>
    changed,
    submitted,
    confirmed,
    rejected,
    validating,
  }

  */
};

export const initialAnswerState = {
  loading: false,
  answerId: null,
  data: null,
  submitted: false,
  peerReviews: {},
  confirmed: false,
  rejected: false,
  validation: {},
  validating: false,
  changed: false
};

export default function answers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ANSWERS_INIT_STATE:
    case RESET_STATE:
      return { ...initialState };
    case actionTypes.CHANGE_QUIZ_ANSWER_STATE:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          data: action.data
        }
      });
    case actionTypes.FETCH_ANSWER:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          data: null,
          answerId: null,
          loading: true,
          changed: false,
          error: ""
        }
      });
    case actionTypes.FETCH_ANSWERS:
      let newRequestedAnswerStates = {};

      action.quizIds.forEach(quizId => {
        if (!state[quizId] || (!!state[quizId] && !state[quizId].changed)) {
          newRequestedAnswerStates = {
            ...newRequestedAnswerStates,
            [quizId]: {
              ...state[quizId],
              data: null,
              answerId: null,
              loading: true,
              changed: false,
              error: ""
            }
          };
        }
      });

      return Object.assign({}, state, {
        ...newRequestedAnswerStates
      });
    case actionTypes.FETCH_ANSWER_SUCCESS:
      const answer = _.get(action, "payload[0]") || null;
      const answerData = _.get(answer, "data") || null;
      const answerId = _.get(answer, "_id", null);

      if (state[action.quizId] && state[action.quizId].changed) {
        return state;
      }

      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          ...expiryDate,
          loading: false,
          answerId,
          data: answerData,
          submitted: !!answerData,
          peerReviews: action.data.peerReviews || undefined,
          confirmed: _.get(answer, "confirmed") || false,
          rejected: _.get(answer, "rejected") || false,
          changed: false,
          validating: false,
          error: ""
        }
      });
    case actionTypes.FETCH_ANSWERS_SUCCESS:
    case actionTypes.FETCH_ANSWERS_WITH_VALIDATION_SUCCESS:
      let newReceivedAnswerStates = {};

      newReceivedAnswerStates = _.reduce(
        action.payload,
        (obj, data) => {
          const answer = data; // [0]
          const answerData = _.get(answer, "data") || null;
          const answerId = _.get(answer, "_id", null);

          let validation;
          let peerReviews;

          if (
            action.type === actionTypes.FETCH_ANSWERS_WITH_VALIDATION_SUCCESS &&
            data
          ) {
            ({ validation, peerReviews } = data);
          }

          obj[answer.quizId] = {
            ...state[answer.quizId],
            ...expiryDate,
            loading: false,
            answerId,
            data: answerData,
            submitted: !!answerData,
            peerReviews, //: data[0].peerReviews || undefined,
            confirmed: _.get(answer, "confirmed") || false,
            rejected: _.get(answer, "rejected") || false,
            validation,
            validating: false,
            changed: false,
            error: ""
          };

          return obj;
        },
        {}
      );

      return Object.assign({}, state, {
        ...newReceivedAnswerStates,
        error: ""
      });
    case actionTypes.FETCH_ANSWERS_FAILURE:
    case actionTypes.FETCH_ANSWERS_WITH_VALIDATION_FAILURE:
      let newErrorAnswerStates = {};

      _.get(action.payload, "quizIds", []).forEach(quizId => {
        newErrorAnswerStates = {
          ...newErrorAnswerStates,
          [quizId]: {
            ...state[quizId],
            loading: false,
            error: action.error
          }
        };
      });

      return Object.assign({}, state, {
        ...newErrorAnswerStates,
        error: action.error
      });
    case actionTypes.SUBMIT_ANSWER:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          submitting: true
        }
      });
    case actionTypes.SUBMIT_ANSWER_VALIDATE:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          submitting: true,
          validating: true,
          error: {}
        }
      });
    // SUBMITTED_ANSWER_VALIDATE
    case actionTypes.SUBMIT_ANSWER_VALIDATE_SUCCESS:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          ..._.pick(action.data, [
            "confirmed",
            "rejected",
            "validation",
            "peerReviews"
          ]),
          answerId: _.get(action.data, "_id"),
          /*          validation: action.data.validation,
          peerReviews: action.data.peerReviews || undefined, */
          // this should may be have a never expire
          submitting: false,
          submitted: true,
          changed: false,
          validating: false
        }
      });
    case actionTypes.SUBMIT_ANSWER_VALIDATE_FAILURE:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          submitted: false,
          submitting: false,
          validating: false,
          error: action.error
        }
      });
    case actionTypes.SUBMITTED_ANSWER:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          submitting: false,
          submitted: true,
          changed: false
        }
      });
    case actionTypes.SET_ANSWER:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          ...expiryDate,
          data: action.data,
          changed: true
        }
      });
    case actionTypes.SET_ITEM_ANSWER:
      const newState = Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          ...expiryDate,
          data: {
            ...(_.get(state[action.quizId], `data`) || {}),
            [action.itemId]: action.data
          },
          changed: true
        }
      });
      return newState;
    case actionTypes.SET_TOGGLE_ITEM_ANSWER:
      let items = _.get(state[action.quizId], `data[${action.itemId}]`) || [];

      if (_.includes(items, action.data)) {
        items = items.filter(id => id !== action.data);
      } else {
        items = [...items, action.data];
      }
      const newToggleState = Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          ...expiryDate,
          data: {
            ..._.get(state, `[${action.quizId}].data`, {}),
            [action.itemId]: items
          },
          changed: true
        }
      });
      return newToggleState;
    case actionTypes.SET_EMPTY_ANSWERS:
      return _.reduce(
        action.quizIds,
        (nextState, quizId) => {
          nextState[quizId] = { ...initialAnswerState };

          return nextState;
        },
        Object.assign({}, state)
      );
    case actionTypes.VALIDATE_ANSWER:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          validating: true
        }
      });
    case actionTypes.VALIDATED_ANSWER:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          validating: false,
          validation: action.data
        }
      });
    case actionTypes.SUBMITTING_SCORE:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          scoreSubmitting: true
        }
      });
    case actionTypes.SUBMITTED_SCORE:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          scoreSubmitting: false,
          score: action.data.score
        }
      });
    case actionTypes.UPDATE_ANSWER_STATUSES:
      let nextState = Object.assign({}, state);

      action.payload.map(data => {
        const answer = data; // [0]
        const answerData = _.get(answer, "data") || null;
        const answerId = _.get(answer, "_id", null);

        const currentAnswer = _.get(state, answer.quizId);

        let validation;
        let peerReviews;
        ({ validation, peerReviews } = data);

        nextState = {
          ...nextState,
          [answer.quizId]: {
            ...state[answer.quizId],
            ...expiryDate,
            loading: false,
            answerId,
            data: answerData,
            submitted: !!answerData,
            peerReviews, //: data[0].peerReviews || undefined,
            confirmed: _.get(answer, "confirmed") || false,
            rejected: _.get(answer, "rejected") || false,
            validation,
            validating: false,
            changed: false
          }
        };
      });

      return nextState;
    // not used
    case actionTypes.UPDATE_ANSWER_STATES:
      const { oldAnswer, newAnswer } = action.payload;

      let updatedState = Object.assign({}, state);

      if (newAnswer.rejected) {
        updatedState = {
          ...updatedState,
          [newAnswer.quizId]: {
            ...initialAnswerState,
            validation: {
              points: 0,
              maxPoints: newAnswer.maxPoints,
              normalizedPoints: 0
            }
          }
        };
      } else {
        updatedState = {
          ...updatedState,
          [newAnswer.quizId]: {
            ...updatedState[newAnswer.quizId],
            confirmed: newAnswer.confirmed || false,
            rejected: newAnswer.rejected || false
          }
        };
      }

      return updatedState;
    default:
      return state;
  }
}
