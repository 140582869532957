import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { themeGet } from "styled-system";
import styled from "styled-components";
import StyledLink from "./StyledLink";
import Box from "./Box";
import { LargeContainer, Row, Col } from "./Grid";
import SignOutButton from "./SignOut";
import { injectIntl, FormattedMessage } from "react-intl";
import { loggedIn } from "../services/moocfi";

import minnaLearnLogo from "../images/MinnaLearn.jpg";
import uniLogo from "../images/Uni.jpg";

// Germany
import dihkLogo from "../images/DIHK2.png";
import ihkLogo from "../images/IHK2.png";
import appliedLogo from "../images/AppliedAI.jpg";
import ahkLogo from "../images/AHK2.png";
import bmwLogo from "../images/BMW1.jpg";

// Estonia
import taltech from "../images/Taltech_logo_grayscale.svg";

// Sweden
import aiSweden from "../images/aisweden_grey.png";
import aiCompetence from "../images/ai_competence_gray.png";
import linkoping from "../images/linkoping-university.png";

//Norway
import feedLogoNB from "../images/feed_logo_NB.svg";
import ntnuLogoNB from "../images/NTNU_logo_NB.svg";
import openaiLogoNB from "../images/openai_logo_black_NB.svg";

// Latvia
import startitLogo from "../images/Startit.png"

import withAuthentication from "./Session/withAuthentication";

const NavLink = styled(StyledLink)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  display: block;
  font-size: 0.875rem;
  transition: color 0.2s ease;
  color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1rem 1.5rem;
  @media screen and (min-width: 38em) {
    padding: 1rem;
    border-bottom: none;
  }
  :hover {
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
`;

const ExternalNavLink = styled.a`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  display: block;

  font-size: 0.875rem;
  transition: color 0.2s ease;
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1rem 1.5rem;
  @media screen and (min-width: 38em) {
    padding: 1rem;
    border-bottom: none;
  }
  :hover {
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
`;

const FooterNavLink = styled(StyledLink)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  display: block;
  padding: 1rem;
  font-size: 0.875rem;
  transition: color 0.2s ease;
  color: rgba(255, 255, 255, 0.5);
  :hover {
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
`;

const StyledFooter = styled.div`
  background: #4844a3;
  padding: 2.65rem 0 1.15rem;
`;

const StyledImg = styled.img`
  padding: 0.2rem 0 0.2rem 0;
  width: 100%;
`;

const StyledSvgLink = styled.a`
  display: flex;
  height: 100%;
  align-items: center;
`;

const StyledNav = styled.nav`
  display: flex;
`;

class Footer extends React.Component {
  render() {
    const { intl } = this.props;
    return (
      <footer>
        <StyledFooter>
          <LargeContainer>
            <Row flexDirection={["column-reverse", "column-reverse", "row"]}>
              <Col w={[12 / 12, 12 / 12, 12 / 12]}>
                <Box
                  display="flex"
                  justifyContent={["flex-start", "flex-start", "flex-start"]}
                  flexDirection={["column", "row", "row"]}
                  flexWrap={["wrap"]}
                  borderTop={[
                    "1px solid rgba(255, 255, 255, 0.2);",
                    "none",
                    "none"
                  ]}
                  pt={[1]}
                  ml={["-1.5rem", "-1rem", "-1rem"]}
                  mr={["-1.5rem", "-1rem", "-1rem"]}
                >
                  <NavLink to={intl.formatMessage({ id: "routes.LANDING" })}>
                    <FormattedMessage id="globals.introToAI" />
                  </NavLink>
                  <ExternalNavLink
                    href={intl.formatMessage({ id: "routes.BUILDING_AI" })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="globals.buildingAI" />
                  </ExternalNavLink>
                  <ExternalNavLink
                    href={intl.formatMessage({ id: "routes.ABOUT" })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="globals.menu.about" />
                  </ExternalNavLink>
                  <ExternalNavLink
                    href={intl.formatMessage({ id: "routes.FAQ" })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="globals.menu.faq" />
                  </ExternalNavLink>
                  <ExternalNavLink
                    href={intl.formatMessage({ id: "routes.PRIVACY" })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="globals.menu.privacyPolicy" />
                  </ExternalNavLink>
                  <ExternalNavLink
                    href={intl.formatMessage({ id: "routes.TERMS" })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="globals.menu.termsofService" />
                  </ExternalNavLink>
                  {this.context.loggedIn ? (
                    <Box
                      display="flex"
                      mb={["2rem", "0", "0"]}
                      pl={["0.5rem", "0", "0"]}
                      borderBottom={[
                        "1px solid rgba(255, 255, 255, 0.2);",
                        "none",
                        "none"
                      ]}
                    >
                      <NavLink
                        to={intl.formatMessage({ id: "routes.ACCOUNT" })}
                      >
                        <FormattedMessage id="globals.menu.myProfile" />
                      </NavLink>
                      <SignOutButton inFooter />
                    </Box>
                  ) : (
                      <Box
                        display="flex"
                        mb={["2rem", "0", "0"]}
                        pl={["0.5rem", "0", "0"]}
                        borderBottom={[
                          "1px solid rgba(255, 255, 255, 0.2);",
                          "none",
                          "none"
                        ]}
                      >
                        <FooterNavLink
                          to={intl.formatMessage({ id: "routes.SIGN_IN" })}
                        >
                          <FormattedMessage id="globals.signIn" />
                        </FooterNavLink>
                        <FooterNavLink
                          to={intl.formatMessage({ id: "routes.SIGN_UP" })}
                        >
                          <FormattedMessage id="globals.signUp" />
                        </FooterNavLink>
                      </Box>
                    )}
                </Box>
              </Col>
            </Row>
          </LargeContainer>
        </StyledFooter>
        <LargeContainer>
          <Row my={[3]}>
            <Col w={[4 / 12, 3 / 12, 1.7 / 12, 1.7 / 12]}>
              <StyledSvgLink
                href="https://www.helsinki.fi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledImg src={uniLogo} alt="University of Helsinki" />
              </StyledSvgLink>
            </Col>

            <Col w={[4 / 12, 3 / 12, 1.7 / 12, 1.7 / 12]}>
              <StyledSvgLink
                href="https://www.minnalearn.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledImg src={minnaLearnLogo} alt="MinnaLearn" />
              </StyledSvgLink>
            </Col>
            {intl.locale === "se" && (
              <Fragment>
                <Col w={[4 / 12, 3 / 12, 1.7 / 12, 1.7 / 12]}>
                  <StyledSvgLink
                    href="https://www.ai.se/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledImg src={aiSweden} alt="AI Innovation of Sweden" />
                  </StyledSvgLink>
                </Col>
                <Col w={[4 / 12, 3 / 12, 1.7 / 12, 1.7 / 12]}>
                  <StyledSvgLink
                    href="https://ai-competence.se/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledImg src={aiCompetence} alt="AI Competence Sweden" />
                  </StyledSvgLink>
                </Col>
                <Col w={[4 / 12, 3 / 12, 1.7 / 12, 1.7 / 12]}>
                  <StyledSvgLink
                    href="https://liu.se/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledImg src={linkoping} alt="Linköping University" />
                  </StyledSvgLink>
                </Col>
              </Fragment>
            )}
            {intl.locale === "ee" && (
              <Col w={[4 / 12, 3 / 12, 1.7 / 12, 1.7 / 12]}>
                <StyledSvgLink
                  href="https://www.ttu.ee/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledImg height={70} src={taltech} alt="Tal Tech" />
                </StyledSvgLink>
              </Col>
            )}
            {intl.locale === "de" && (
              <Col w={[4 / 12, 3 / 12, 1.7 / 12, 1.7 / 12]}>
                <StyledSvgLink
                  href="https://appliedai.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledImg
                    src={appliedLogo}
                    alt="Initiative For Applied Artificial Intelligence"
                  />
                </StyledSvgLink>
              </Col>
            )}
            {intl.locale === "no" && (
              <Fragment>
                <Col w={[4 / 12, 3 / 12, 1.7 / 12, 1.7 / 12]}>
                  <StyledSvgLink
                    href="https://feed.no/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledImg height={50} src={feedLogoNB} alt="Feed" />
                  </StyledSvgLink>
                </Col>
                <Col w={[4 / 12, 3 / 12, 1.7 / 12, 1.7 / 12]}>
                  <StyledSvgLink
                    href="https://www.ntnu.edu/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledImg
                      height={40}
                      src={ntnuLogoNB}
                      alt="Norges teknisk-naturvitenskaplige universitet"
                    />
                  </StyledSvgLink>
                </Col>
                <Col w={[4 / 12, 3 / 12, 1.7 / 12, 1.7 / 12]}>
                  <StyledSvgLink
                    href="https://www.ntnu.edu/ailab"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledImg
                      height={60}
                      src={openaiLogoNB}
                      alt="NTNU - ailab"
                    />
                  </StyledSvgLink>
                </Col>
              </Fragment>
            )}
            {intl.locale === "lv" && (
              <Fragment>
                <Col w={[4 / 12, 3 / 12, 1.7 / 12, 1.7 / 12]}>
                  <StyledSvgLink
                    href="https://www.startit.lv"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledImg src={startitLogo} alt="Start it" />
                  </StyledSvgLink>
                </Col>
              </Fragment>
            )}
          </Row>
        </LargeContainer>
      </footer>
    );
  }

  static contextTypes = {
    loggedIn: PropTypes.bool
  };
}

export default withAuthentication(injectIntl(Footer));
