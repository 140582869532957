import React from "react";
import styled from "styled-components";
import Link from "gatsby-link";
import { textStyle, fontSize, color, lineHeight, space } from "styled-system";

const StyledLink = styled(Link)`
  ${textStyle};
  ${fontSize};
  ${color};
  ${lineHeight};
  ${space};
  text-decoration: none;
`;

export default StyledLink;
