import styled from "styled-components";

export default styled.div`
  position: relative;
  /* bottom: 20px; */
  padding: ${({ nItems }) => (nItems === 1 ? "2.5rem" : "5rem")};
  background-color: ${({ nItems }) =>
    nItems === 1 || nItems % 2 === 0 ? "#ebe9ef" : "inherit"};
  > div {
    width: ${({ nItems }) => (nItems === 1 ? "700px" : "1100px")};
    ${({ nItems }) => nItems === 1 && `justify-content: center;`}
    margin-left: auto;
    margin-right: auto;
    padding-left: ${({ nItems }) => (nItems === 1 ? "0" : "2rem")};
    > p:last-of-type {
      ${({ nItems }) => (nItems === 1 ? "display: none;" : "")}
      font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
      font-weight: normal;
    }
  }

  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    div:first-child {
      max-width: 17.5em;
      padding-right: 0.5rem;
    }

    /* widen the peer review box */
    .sc-bZQynM {
      padding: 1rem;
      margin-right: 0;
      margin-left: 0;
    }
  }
`;
