// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'en-lu',
  'globals.signIn': 'Sign in',
  'globals.logIn': 'Log in',
  'globals.signUp': 'Sign up',
  'globals.signOut': 'Sign out',
  'globals.logOut': 'Log out',
  'globals.submit': 'Submit',
  'globals.submitting': 'Submitting...',
  'globals.yourEmail': 'Your email',
  'globals.yourPassword': 'Your password',
  'globals.courseOverview': 'Course overview',
  'globals.forgotPassword': 'Forgot password?',
  'globals.invalidCredentials': 'Invalid credentials',
  'globals.section': 'Section',
  'globals.exercises': 'Exercises',
  'globals.chapter': 'Chapter',
  'globals.introToAI': 'Introduction to AI',
  'globals.buildingAI': 'Building AI',
  'globals.acceptPolicy': 'Accept policy',
  // section
  'section.footerEndOfTheChapter': 'You reached the end of Chapter {chapter}!',
  'section.footerEndOfTheCourseTitle': 'You reached the end of the course!',
  'section.footerEndOfTheCourseText': 'After you have completed all the exercises and peer reviews, your answers will be reviewed by our staff.',
  'section.footerEndOfTheCourseBAI': 'Continue your journey towards being able to implement your first AI idea.',
  'section.nextSection': 'Next section',
  'section.nextChapter': 'Next Chapter',
  'section.start': 'Start',
  'section.logisticRegressionExample.slope': 'Slope',
  'section.logisticRegressionExample.intercept': 'Intercept',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Waiting for you to review',
  'quiz.rejectedPeerReview': 'Rejected in peer review',
  'quiz.unsubmittedAnswer': 'Unsubmitted answer',
  'quiz.pendingPeerReview': 'Pending peer review',
  // quiz
  'quiz.status.answered': 'Answered',
  'quiz.status.rejected': 'Rejected answer, try again',
  'quiz.status.unanswered': 'Unanswered',
  'quiz.status.exercisesCompleted': 'Exercises completed',
  'quiz.status.correctAnswers': 'Correct answers',
  'quiz.message.rejectedInReview': 'Your previous answer was rejected in review. Please try again.',
  'quiz.youAnswered': 'You answered',
  'quiz.yourAnswer': 'Your answer...',
  'quiz.yourScore': 'Your score:',
  'quiz.quizNotLoaded': 'Could not load quiz',
  'quiz.words': 'Words:',
  'quiz.exampleAnswer': 'Example answer:',
  // peer review
  'peerreview.question1': 'Answer is on-topic',
  'peerreview.question2': 'Answer is comprehensive',
  'peerreview.question3': 'Answer is well reasoned',
  'peerreview.question4': 'Answer is easy to follow',
  'peerreview.thankYou': 'Thank you for reviewing your peers!',
  'peerreview.giveMoreReviews': 'If you have time to spare,',
  'peerreview.giveMoreReviewsButton': 'give more reviews',
  'peerreview.notEnoughAnswers': 'Normally there would be a peer review here but not enough other people have answered this exercise.',
  'peerreview.selectAnswerMessage': 'It’s time for peer review! Below you have a couple of answers from other students. Select one of them and review it. Repeat at least three times.',
  'peerreview.minimumPeerReviewWarning': 'Exercise can be accepted only after you’ve given at least three peer reviews.',
  'peerreview.select': 'Select',
  'peerreview.reportSpam': 'Report spam',
  'peerreview.feedback': 'Your answer has been reviewed and accepted. Here’s what your peers thought of it:',
  // exercise17
  'quiz.exercise17.xLabel': 'Years of education',
  'quiz.exercise17.yLabel': 'Life expectancy in years',
  'quiz.exercise17.textPart1': 'Let’s study the link between the total number of years spent in school (including everything between preschool and university) and life expectancy. Here is data from three different countries displayed in a figure represented by dots:',
  'quiz.exercise17.textPart2': 'We have one country where the average number of years in school is 10 and life expectancy is 57 years, another country where the average number of years in school is 13 and life expectancy is 53 years, and a third country where the average number of years in school is 20 and life expectancy is 80 years.',
  'quiz.exercise17.textPart3': 'You can drag the end points of the solid line to position the line in such a way that it follows the trend of the data points. Note that you will not be able to get the line fit perfectly with the data  points, and this is fine: some of the data points will lie above the line,  and some below it. The most important part is that the line describes the overall trend.',
  'quiz.exercise17.textPart4': 'After you have positioned the line you can use it to predict the life expectancy.',
  'quiz.exercise17.textPart5': 'Given the data, what can you tell about the life expectancy of people who have 15 years of education? Important: Notice that even if you can obtain a specific prediction, down to a fraction of a year, by adjusting the line, you may not necessarily be able to give a confident prediction. Take the limited amount of data into account when giving your answer.',
  // exercise18
  'quiz.exercise18.xLabel': 'Years of education',
  'quiz.exercise18.yLabel': 'Life expectancy in years',
  'quiz.exercise18.textPart1': 'In the previous exercise, we only had data from three countries. The full data set consists of data from 14 different countries, presented here in a graph:',
  'quiz.exercise18.textPart2': 'Based on this data, would your prediction about the life expectancy of people with 15 years of education change? If so, why?',
  'quiz.exercise18.textPart3': 'Which of the following options would best match your estimate of the life expectancy for people with 15 years of education? Choose the most specific option that you think is justified by fitting the straight line model to the above data.',
  // exercise19
  'quiz.exercise19.xLabel': 'Hours studied',
  'quiz.exercise19.yLabel': 'Probability of passing',
  'quiz.exercise19.textPart1': 'Each dot on the figure corresponds to one student. On the bottom of the figure we have the scale for how many hours the student studied for the exam, and the students who passed the exam are shown as dots at the top of the chart, and the ones who failed are shown at the bottom. We’ll use the scale on the left to indicate the predicted probability of passing, which we’ll get from the logistic regression model as we explain just below. Based on this figure, you can see roughly that students who spent longer studying had better chances of passing the course. Especially the extreme cases are intuitive: with less than an hour’s work, it is very hard to pass the course, but with a lot of work, most will be successful. But what about those that spend time studying somewhere inbetween the extremes? If you study for 6 hours, what are your chances of passing?',
  'quiz.exercise19.textPart2': 'We can quantify the probability of passing using logistic regression. The curve in the figure can be interpreted as the probability of passing: for example, after studying for five hours, the probability of passing is a little over 20%. We will not go into the details on how to obtain the curve, but it will be similar to how we learn the weights in linear regression.',
  'quiz.exercise19.textPart3': 'If you wanted to have an 80% chance of passing a university exam, based on the above figure, how many hours should you approximately study for?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Input',
  'quiz.exercise22.sigmoidYLabel': 'Sigmoid output',
  'quiz.exercise22.stepXLabel': 'Input',
  'quiz.exercise22.stepYLabel': 'Step function output',
  'quiz.exercise22.identityXLabel': 'Input',
  'quiz.exercise22.identityYLabel': 'Identity output',
  'quiz.exercise22.textPart1': 'Below are graphs for three different activation functions with different properties. First we have the sigmoid function, then the step function, and finally the identity function. IMPORTANT: Note the different y-axis (vertical) scale in the identity function chart.',
  // exercise23
  'quiz.exercise23.happyFaces': 'happy faces classified correctly',
  'quiz.exercise23.sadFaces': 'sad faces classified correctly',
  // certificate availability
  'certificate.failedToCheck': 'Failed to check certificate availability',
  'certificate.failedToGenerate': 'Failed to generate certificate',
  'certificate.notYetAvailable': 'Your certificate will be available here once you pass the course. See the course dashboard for your progress.',
  'certificate.updateName': 'Your certificate is available! Please update your first and last name to show on your certificate by using a form available in your profile page.',
  'certificate.generate': 'Generate your certificate below.',
  'certificate.generateTextPart1': 'The certificate will be generated for',
  'certificate.generateTextPart2': 'Please make sure this is correct before generating the certificate as the name cannot be changed afterwards. If you like to change your name, you can do so in the form below.',
  'certificate.generateButton': 'Generate certificate',
  'certificate.downloadButton': 'Download certificate',
  'certificate.checkingAvailability': 'Checking certificate availability...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Classified correctly',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Not classified correctly',
  // section blocks
  'section.block.note': 'Note',
  'section.block.example': 'Example',
  'section.block.keyTerminology': 'Key terminology',
  'section.partSummary.spectrumTextPart1': 'Please join the',
  'section.partSummary.spectrum': 'Elements of AI community',
  'section.partSummary.spectrumTextPart2': ' to discuss and ask questions about this chapter.',
  'section.partSummary.feedbackTitle': 'Give us your feedback on this part',
  'section.partSummary.feedbackPlaceholder': 'Write your feedback here..',
  'section.partSummary.feedbackSuccess': 'Thanks for your feedback!',
  // 404
  'globals.404.title': '404 - Page not found',
  'globals.404.intro': "You just hit a route that doesn't exist... the sadness.",
  'globals.loading': 'Loading...',
  // footer / menu
  'globals.menu': 'Menu',
  'globals.menu.about': 'About',
  'globals.menu.faq': 'FAQ',
  'globals.menu.privacyPolicy': 'Privacy Policy',
  'globals.menu.termsofService': 'Terms of Service',
  'globals.menu.myProfile': 'My profile',
  // Dashboard/Index
  'dashboard.title': 'Welcome to the course!',
  'dashboard.congratulations.title': 'Congratulations!',
  'dashboard.congratulations.message': 'You finished the Elements of AI course',
  'dashboard.congratulations.downloadCertificate': 'Download certificate',
  'dashboard.notLoggedIn.title': 'Welcome!',
  'dashboard.notLoggedIn.message': 'Sign up or log in to get complete access to the course. It’s free!',
  // Account
  'account.certificate': 'Certificate',
  'account.myProfile': 'My profile',
  'account.profileInformation': 'Profile information',
  'account.credits': 'ECTS credits',
  'account.changePassword': 'Change password',
  'account.changeLanguage': 'Change language',
  'account.goToCourse': 'Go to course',
  'account.buildingAINew': 'New course in English',
  'account.accept': 'Accept',
  'account.decline': 'Decline',
  'account.courses': 'Courses',
  // Sign Up page
  'signUp.description': 'Fill the form below to create a new mooc.fi account',
  'signUp.email': 'Your email',
  'signUp.firstName': 'Your first name',
  'signUp.lastName': 'Your last name',
  'signUp.postalCode': 'Postal code',
  'signUp.country': 'Where do you live?',
  'signUp.Password': 'Password',
  'signUp.confirmPassword': 'Confirm password',
  'signUp.howToStudyTitle': 'How do you want to study?',
  'signUp.6weeksTitle': '6 weeks course',
  'signUp.6weeksSubTitle': 'Recommended (appr 5h per week)',
  'signUp.6weeksText': 'Having a deadline is a proven way to help you finish the course.',
  'signUp.selfPacedTitle': 'Self paced',
  'signUp.selfPacedText': 'You can do the course in your own pace.',
  'signUp.ectsTitle': 'Are you applying for ECTS credits?',
  'signUp.ectsTitleSwe': 'Would you like to apply for ECTS credits? ',
  'signUp.ectsStudent': 'I am a student at University of Helsinki. If you check this box and fill in your student number you will automatically get the ECTS credits.',
  'signUp.ectsStudentSwe': 'After completing the course, you can apply for ECTS credits from Linköping University. You can find the instructions ',
  'signup.ectsStudentSweLink': 'here.',
  'signUp.ectsOpenUni': 'Check this box to receive email with instructions on how to apply for ECTS credits',
  'signUp.studentNumber': 'University of Helsinki student number (if you are a student)',
  'signUp.privacyOptIn': 'I allow the use of collected data about my study behavior for research purposes. The data contains information from material usage and the course exercises. No individuals can be identified from publications.',
  'signUp.marketingOptIn': 'I am ok with receiving updates about upcoming language versions and information regarding new courses. I agree to share my contact information in order to receive tailored messages on third party platforms. Read more in our Privacy Policy.',
  'signUp.acceptPolicyFirst': 'I accept the Privacy Policy and Terms of Service.',
  'signUp.privacyLink': 'Read our privacy policy',
  'signUp.error': 'There are errors in your form. Please check your form.',
  // Sign in page
  'signIn.title': 'Sign in',
  'signIn.intro': 'Use the form below to sign in with your mooc.fi account',
  'signIn.email': 'Your email',
  'signIn.password': 'Your password',
  'signIn.noAccount': "Don't have an account?",
  'signIn.signUpNow': 'Sign up now',
  // Forgot password
  'pwForget.title': 'Forgot password',
  'pwForget.description': 'Fill in your mooc.fi account email and we will send you a password reset link',
  'pwForget.sendResetLink': 'Send reset link',
  'pwForget.completedDescription': 'You should soon receive an email from mooc.fi containing a password reset link. If you do not see it check your spam folder.',
  'pwForget.problemDescription': 'If you encounter any problems, please contact mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent': 'Password reset email sent',
  'pwForget.emailPlaceholder': 'Email Address',
  // password change
  'pwChange.authError': 'Authentication failed. Please try to sign in again',
  'pwChange.unexpError': 'Failed to update the password because of unexpected error',
  'pwChange.required': 'Required',
  'pwChange.pwMustMatch': 'Passwords must match',
  'pwChange.currentPassword': 'Current password',
  'pwChange.newPassword': 'New password',
  'pwChange.confirmPassword': 'Confirm new password',
  'pwChange.changingPassword': 'Changing password...',
  'pwChange.changePassword': 'Change password',
  'pwChange.passwordChanged': 'Password changed',
  // user details
  'userDetails.authError': 'Authentication failed. Please try to sign in again',
  'userDetails.unexpError': 'Failed to update the profile because of unexpected error',
  'userDetails.required': 'Required',
  'userDetails.invalidEmail': 'Invalid email address',
  'userDetails.email': 'Email',
  'userDetails.emailAddress': 'Email address',
  'userDetails.firstName': 'First name',
  'userDetails.lastName': 'Last name',
  'userDetails.language': 'Language',
  'userDetails.updatingProfile': 'Updating profile...',
  'userDetails.updateProfile': 'Update profile',
  'userDetails.profileUpdated': 'Profile updated',
  // languages
  'languages.en.long': 'English',
  'languages.fi.long': 'Finnish',
  'languages.se.long': 'Swedish',
  'languages.de.long': 'German (Germany)',
  'languages.ee.long': 'Estonian',
  'languages.it.long': 'Italian',
  'languages.fr.long': 'French (France)',
  'languages.no.long': 'Norwegian',
  'languages.lv.long': "Latvian",
  'languages.mt.long': "Maltese",
  'languages.fr-be.long': "French (Belgium)",
  'languages.lt.long': "Lithuanian",
  'languages.nl-be.long': "Dutch (Belgium)",
  'languages.en-ie.long': "English (Ireland)",
  "languages.pl.long": "Polish",
  "languages.hr.long": "Croatian",
  'languages.da.long': 'Danish',
  'languages.ro.long': 'Romanian',
  'languages.en-lu.long': 'English (Luxembourg)',
  'languages.cs.long': 'Czech',
  'languages.bg.long' : 'Bulgarian',
  'languages.sl.long': 'Slovenian',
  'languages.de-at.long': 'German (Austria)',
  'languages.nl.long': 'Dutch (Netherlands)',
  'languages.sk.long': 'Slovak',
  'languages.pt.long': 'Portuguese',
  'languages.es.long': 'Spanish',
  'languages.el.long': 'Greek',
  'languages.ga.long': 'Irish',
  'languages.is.long': 'Icelandic',
  // Email verfification
  'emailVerification.completedMessage': 'Your email address has been verified',
  'emailVerification.verified': 'Verified',
  // Exercises
  'exercise.loading': 'Loading exercise...',
  'exercise.signUp': 'Sign up to solve exercises',
  'exercise.score': 'Your score:',
  'exercise.wrongLocale': 'Wrong locale',
  // Part tempplate
  'partTemplate.intro1': 'In Chapter ',
  'partTemplate.intro2': ', we will cover the following sections. Click below to get started:',
  'partTemplate.spectrumPart1': 'Please join the',
  'partTemplate.spectrum': 'Elements of AI community',
  'partTemplate.spectrumPart2': ' to discuss and ask questions about this chapter.',
  // missing info
  'missingInfo.title': 'Missing info',
  'missingInfo.body': 'We usually ask these questions when users sign up, but you logged in with an existing mooc.fi account. Please fill the form below to continue.',
  'missingInfo.privacyPolicy': 'Read our privacy policy',
  'missingInfo.error': 'There are errors in your form. Please check your form.',
  'missingInfo.privacyPolicyUpdateBody': "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
  'missingInfo.privacyPolicyUpdateLink': 'You can review the privacy policy here.',
  // routes
  'routes.LANDING': '/en-lu',
  'routes.SIGN_UP': '/en-lu/signup',
  'routes.SIGN_IN': '/en-lu/signin',
  'routes.MISSING_INFO': '/en-lu/missinginfo',
  'routes.PASSWORD_FORGET': '/en-lu/pw-forget',
  'routes.HOME': '/en-lu/home',
  'routes.ACCOUNT': '/en-lu/account',
  'routes.ACCEPT_POLICY': '/en-lu/accept-policy',
  'routes.PRIVACY': 'https://www.elementsofai.lu/faq/privacy-policy-2',
  'routes.FAQ': 'https://www.elementsofai.lu/faq',
  'routes.ABOUT': 'https://www.elementsofai.lu',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/intro-course-help',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.com/en-lu/faq/how-can-i-get-2-ects-credits',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Cups of coffee per day',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Lines of code written',
  // meta
  'meta.title': 'A free online course',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Learn more about the University of Helsinki and Reaktor’s AI course for students and business professionals - no programming or math skills required.',
  'meta.sitename': 'Elements of AI',
  // ECTS
  'ects.text': 'You will receive instructions on how to apply for ECTS credits here after completing the course.',
  'ects.textCompleted': 'If you have a finnish social security number, you can apply for credits by clicking ',
  'ects.link': 'here.',
  'ects.info': 'Would you like to apply for ECTS credits?',
  'ects.readMore': 'Read more',
  // Countries
  'countryselect.select': "Please select",
  'Finland': 'Finland',
  'Estonia': 'Estonia',
  'Germany': 'Germany',
  'Sweden': 'Sweden',
  'Norway': 'Norway',
  'Latvia': 'Latvia',
  "Countries": {
    'Afganistan': 'Afghanistan',
    'Åland Islands': 'Åland Islands',
    'Albania': 'Albania',
    'Algeria': 'Algeria',
    'American Samoa': 'American Samoa',
    'Andorra': 'Andorra',
    'Angola': 'Angola',
    'Anguilla': 'Anguilla',
    'Antarctica': 'Antarctica',
    'Antigua and Barbuda': 'Antigua and Barbuda',
    'Argentina': 'Argentina',
    'Armenia': 'Armenia',
    'Aruba': 'Aruba',
    'Australia': 'Australia',
    'Austria': 'Austria',
    'Azerbaijan': 'Azerbaijan',
    'Bahamas': 'Bahamas',
    'Bahrain': 'Bahrain',
    'Bangladesh': 'Bangladesh',
    'Barbados': 'Barbados',
    'Belarus': 'Belarus',
    'Belgium': 'Belgium',
    'Belize': 'Belize',
    'Benin': 'Benin',
    'Bermuda': 'Bermuda',
    'Bhutan': 'Bhutan',
    'Bolivia': 'Bolivia',
    'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
    'Botswana': 'Botswana',
    'Bouvet Island': 'Bouvet Island',
    'Brazil': 'Brazil',
    'British Indian Ocean Territory': 'British Indian Ocean Territory',
    'Brunei Darussalam': 'Brunei Darussalam',
    'Bulgaria': 'Bulgaria',
    'Burkina Faso': 'Burkina Faso',
    'Burundi': 'Burundi',
    'Cambodia': 'Cambodia',
    'Cameroon': 'Cameroon',
    'Canada': 'Canada',
    'Cape Verde': 'Cape Verde',
    'Cayman Islands': 'Cayman Islands',
    'Central African Republic': 'Central African Republic',
    'Chad': 'Chad',
    'Chile': 'Chile',
    'China': 'China',
    'Christmas Island': 'Christmas Island',
    'Cocos (Keeling) Islands': 'Cocos (Keeling) Islands',
    'Colombia': 'Colombia',
    'Comoros': 'Comoros',
    'Congo': 'Congo',
    'Congo, The Democratic Republic of The': "Congo, The Democratic Republic of The",
    'Cook Islands': 'Cook Islands',
    'Costa Rica': 'Costa Rica',
    "Cote D'ivoire": "Cote D'ivoire",
    'Croatia': 'Croatia',
    'Cuba': 'Cuba',
    'Cyprus': 'Cyprus',
    'Czech Republic': 'Czech Republic',
    'Denmark': 'Denmark',
    'Djibouti': 'Djibouti',
    'Dominica': 'Dominica',
    'Dominican Republic': 'Dominican Republic',
    'Ecuador': 'Ecuador',
    'Egypt': 'Egypt',
    'El Salvador': 'El Salvador',
    'Equatorial Guinea': 'Equatorial Guinea',
    'Eritrea': 'Eritrea',
    'Estonia': 'Estonia',
    'Ethiopia': 'Ethiopia',
    'Falkland Islands (Malvinas)': 'Falkland Islands (Malvinas)',
    'Faroe Islands': 'Faroe Islands',
    'Fiji': 'Fiji',
    'Finland': 'Finland',
    'France': 'France',
    'French Guiana': 'French Guiana',
    'French Polynesia': 'French Polynesia',
    'French Southern Territories': 'French Southern Territories',
    'Gabon': 'Gabon',
    'Gambia': 'Gambia',
    'Georgia': 'Georgia',
    'Germany': 'Germany',
    'Ghana': 'Ghana',
    'Gibraltar': 'Gibraltar',
    'Greece': 'Greece',
    'Greenland': 'Greenland',
    'Grenada': 'Grenada',
    'Guadeloupe': 'Guadeloupe',
    'Guam': 'Guam',
    'Guatemala': 'Guatemala',
    'Guernsey': 'Guernsey',
    'Guinea': 'Guinea',
    'Guinea-Bissau': 'Guinea-bissau',
    'Guyana': 'Guyana',
    'Haiti': 'Haiti',
    'Heard Island and Mcdonald Islands': 'Heard Island and Mcdonald Islands',
    'Holy See (Vatican City State)': 'Holy See (Vatican City State)',
    'Honduras': 'Honduras',
    'Hong Kong': 'Hong Kong',
    'Hungary': 'Hungary',
    'Iceland': 'Iceland',
    'India': 'India',
    'Indonesia': 'Indonesia',
    'Iran, Islamic Republic of': 'Iran, Islamic Republic of',
    'Iraq': 'Iraq',
    'Ireland': 'Ireland',
    'Isle of Man': 'Isle of Man',
    'Israel': 'Israel',
    'Italy': 'Italy',
    'Jamaica': 'Jamaica',
    'Japan': 'Japan',
    'Jersey': 'Jersey',
    'Jordan': 'Jordan',
    'Kazakhstan': 'Kazakhstan',
    'Kenya': 'Kenya',
    'Kiribati': 'Kiribati',
    "Korea, Democratic People's Republic of": "Korea, Democratic People's Republic of",
    'Korea, Republic of': 'Korea, Republic of',
    'Kosovo': 'Kosovo',
    'Kuwait': 'Kuwait',
    'Kyrgyzstan': 'Kyrgyzstan',
    "Lao People's Democratic Republic": "Lao People's Democratic Republic",
    'Latvia': 'Latvia',
    'Lebanon': 'Lebanon',
    'Lesotho': 'Lesotho',
    'Liberia': 'Liberia',
    'Libyan Arab Jamahiriya': 'Libyan Arab Jamahiriya',
    'Liechtenstein': 'Liechtenstein',
    'Lithuania': 'Lithuania',
    'Luxembourg': 'Luxembourg',
    'Macao': "Macao",
    'Macedonia, The Former Yugoslav Republic of': 'Macedonia, The Former Yugoslav Republic of',
    'Madagascar': 'Madagascar',
    'Malawi': 'Malawi',
    'Malaysia': 'Malaysia',
    'Maldives': 'Maldives',
    'Mali': 'Mali',
    'Malta': 'Malta',
    'Marshall Islands': 'Marshall Islands',
    'Martinique': 'Martinique',
    'Mauritania': 'Mauritania',
    'Mauritius': 'Mauritius',
    'Mayotte': 'Mayotte',
    'Mexico': 'Mexico',
    'Micronesia, Federated States of': 'Micronesia, Federated States of',
    'Moldova, Republic of': 'Moldova, Republic of',
    'Monaco': 'Monaco',
    'Mongolia': 'Mongolia',
    'Montenegro': 'Montenegro',
    'Montserrat': 'Montserrat',
    'Marocco': 'Morocco',
    'Mozambique': 'Mozambique',
    'Myanmar': 'Myanmar',
    'Namibia': 'Namibia',
    'Nauru': 'Nauru',
    'Nepal': 'Nepal',
    'Netherlands': 'Netherlands',
    'Netherlands Antilles': 'Netherlands Antilles',
    'New Caledonia': 'New Caledonia',
    'New Zealand': 'New Zealand',
    'Nicaragua': 'Nicaragua',
    'Niger': 'Niger',
    'Nigeria': 'Nigeria',
    'Niue': 'Niue',
    'Norfolk Island': 'Norfolk Island',
    'Northern Mariana Islands': 'Northern Mariana Islands',
    'Norway': 'Norway',
    'Oman': 'Oman',
    'Pakistan': 'Pakistan',
    'Palau': 'Palau',
    'Palestinian Territory, Occupied': 'Palestinian Territory, Occupied',
    'Panama': 'Panama',
    'Papua New Guinea': 'Papua New Guinea',
    'Paraguay': 'Paraguay',
    'Peru': 'Peru',
    'Philippines': 'Philippines',
    'Pitcairn': 'Pitcairn',
    'Poland': 'Poland',
    'Portugal': 'Portugal',
    'Puerto Rico': 'Puerto Rico',
    'Qatar': 'Qatar',
    'Reunion': 'Reunion',
    'Romania': 'Romania',
    'Russian Federation': 'Russian Federation',
    'Rwanda': 'Rwanda',
    'Saint Helena': 'Saint Helena',
    'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
    'Saint Lucia': 'Saint Lucia',
    'Saint Pierre and Miquelon': 'Saint Pierre and Miquelon',
    'Saint Vincent and The Grenadines': 'Saint Vincent and The Grenadines',
    'Samoa': 'Samoa',
    'San Marino': 'San Marino',
    'Sao Tome and Principe': 'Sao Tome and Principe',
    'Saudi Arabia': 'Saudi Arabia',
    'Senegal': 'Senegal',
    'Serbia': 'Serbia',
    'Seychelles': 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    'Singapore': 'Singapore',
    'Slovakia': 'Slovakia',
    'Slovenia': 'Slovenia',
    'Solomon Islands': 'Solomon Islands',
    'Somalia': 'Somalia',
    'South Africa': 'South Africa',
    'South Georgia and The South Sandwich Islands': 'South Georgia and The South Sandwich Islands',
    'Spain': 'Spain',
    'Sri Lanka': 'Sri Lanka',
    'Sudan': 'Sudan',
    'Suriname': 'Suriname',
    'Svalbard and Jan Mayen': 'Svalbard and Jan Mayen',
    'Swaziland': 'Swaziland',
    'Sweden': 'Sweden',
    'Swizerland': 'Switzerland',
    'Syrian Arab Republic': 'Syrian Arab Republic',
    'Taiwan': 'Taiwan',
    'Tajikistan': 'Tajikistan',
    'Tanzania, United Republic of': 'Tanzania, United Republic of',
    'Thailand': 'Thailand',
    'Timor-leste': 'Timor-leste',
    'Togo': 'Togo',
    'Tokelau': 'Tokelau',
    'Tonga': 'Tonga',
    'Trinidad and Tobago': 'Trinidad and Tobago',
    'Tunisia': 'Tunisia',
    'Turkey': 'Turkey',
    'Turkmenistan': 'Turkmenistan',
    'Turks and Caicos Islands': 'Turks and Caicos Islands',
    'Tuvalu': 'Tuvalu',
    'Uganda': 'Uganda',
    'Ukraine': 'Ukraine',
    'United Arab Emirates': 'United Arab Emirates',
    'United Kingdom': 'United Kingdom',
    'United States': 'United States',
    'United States Minor Outlying Islands': 'United States Minor Outlying Islands',
    'Uruguay': 'Uruguay',
    'Uzbekistan': 'Uzbekistan',
    'Vanuatu': 'Vanuatu',
    'Venezuela': 'Venezuela',
    'Viet Nam': 'Viet Nam',
    'Virgin Islands, British': 'Virgin Islands, British',
    'Virgin Islands, U.S.': 'Virgin Islands, U.S.',
    'Wallis and Futuna': 'Wallis and Futuna',
    'Western Sahara': 'Western Sahara',
    'Yemen': 'Yemen',
    'Zambia': 'Zambia',
    'Zimbabwe': 'Zimbabwe'
  },
  //Alt texts
  'alt.example-1': 'two self driving cars',
  'alt.example-2': 'hand holding a mobile phone',
  'alt.example-3': "woman’s face",
  'alt.defining-ai': 'jumble of words related to AI',
  'alt.turing-test': 'man chatting on computer with a robot and a woman',
  'alt.chinese-room': 'the chinese room experiment',
  'alt.chicken-crossing': 'robot in a boat looking at a chicken, fox, and chicken feed bag',
  'alt.a-to-b': 'hand holding a phone using a navigation app',
  'alt.tic-tac-toe': 'two kids playing tic tac toe',
  'alt.self-driving-car': 'a woman walks past a self-driwing car',
  'alt.spam-or-ham': 'a normal email and a spam email',
  'alt.mnist': 'MNIST data set',
  'alt.nearest-neighbor': 'two people shopping',
  'alt.nearest-neighbor-graph': 'nearest neighbor classifier',
  'alt.recommendation': 'man and woman listening to music on the phone',
  'alt.price-real-estate': 'three different houses',
  'alt.supervised-learning': 'human teaching a robot',
  'alt.dice': 'dice',
  'alt.chess': 'chessboard',
  'alt.poker': 'hand holding playing cards',
  'alt.eyes': 'three people with brown eyes, one person with blue eyes',
  'alt.search-1': 'single path leading to a goal',
  'alt.search-2': 'four different paths leading to the same goal',
  'alt.chicken-crossing-1': 'chicken crossing initial state',
  'alt.chicken-crossing-2': 'chicken crossing first transition',
  'alt.chicken-crossing-3': 'chicken crossing all transitions',
  'alt.chicken-crossing-4': 'chicken crossing with best path',
  'alt.chicken-crossing-5': 'chicken crossing initial state',
  'alt.implication-1': 'man and a woman',
  'alt.implication-2': 'eyes',
  'alt.implication-3': 'surveillance camera',
  'alt.implication-4': 'robot working on a laptop ',
  'alt.oddchange': 'sun and clouds',
  'alt.brain': 'drawing of a brain',
  'alt.carstop': 'self driving car at stop sign',
  'alt.gan': 'robot holding a picture of cat and a picture of a stop sign ',
  'alt.hammock': 'robot fanning a man in a hammock',
  'alt.game-tree-1': 'game tree 1',
  'alt.game-tree-2': 'game tree 2',
  'alt.game-tree-3': 'game tree 3',
  'alt.game-tree-4': 'game tree 4',
  'alt.bayes-rule-1': 'image illustrating false positives and negatives for cancer screening  ',
  'alt.bayes-rule-2': 'image illustrating false positives and negatives for cancer screening  ',
  'alt.defineai': 'jumble of words related to AI',
  'alt.winter': 'robot sleeping in the snow',
  'alt.terminator': 'scary robot',
}
