// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'ee',
  'globals.signIn': 'Logi sisse',
  'globals.logIn': 'Logi sisse',
  'globals.signUp': 'Registreeru',
  'globals.signOut': 'Logige välja',
  'globals.logOut': 'Logige välja',
  'globals.submit': 'Esita',
  'globals.submitting': 'Esitan',
  'globals.yourEmail': 'Teie email',
  'globals.yourPassword': 'Teie parool',
  'globals.courseOverview': 'Kursuse ülevaade',
  'globals.forgotPassword': 'Unustasite parooli?',
  'globals.invalidCredentials': 'Kehtetud isikuandmed',
  'globals.section': 'Osa',
  'globals.exercises': 'Harjutused',
  'globals.chapter': 'Peatükk',
  'globals.introToAI': 'Tasuta veebipõhine sissejuhatus tehisintellekti',
  'globals.buildingAI': 'Tehisintellekti ehitamine',
  'globals.acceptPolicy': 'Accept policy',
  // section
  'section.footerEndOfTheChapter': 'Lõpetasite peatüki {chapter}!',
  'section.footerEndOfTheCourseTitle': 'Olete jõudnud kursusega lõpule!',
  'section.footerEndOfTheCourseText': 'Kui olete sooritanud kõik harjutused ja vastastikused hinnagud, vaadatakse teie vastused meie meeskonna poolt üle.',
  'section.nextSection': 'Järgmine osa',
  'section.nextChapter': 'Järgmine peatükk',
  'section.start': 'Alusta',
  'section.logisticRegressionExample.slope': 'Slope',
  'section.logisticRegressionExample.intercept': 'Lähtekonstant',
  'section.footerEndOfTheCourseBAI': 'Jätkake teekonda oma esimese tehisintellekti idee elluviimise suunas.',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Ootab teie arvustust',
  'quiz.rejectedPeerReview': 'Kaaslase hinnanguga tagasi lükatud',
  'quiz.unsubmittedAnswer': 'Esitamata vastus',
  'quiz.pendingPeerReview': 'Ootel hinnang',
  // quiz
  'quiz.status.answered': 'Vastatud',
  'quiz.status.rejected': 'Tagasilükatud vastus, proovige uuesti',
  'quiz.status.unanswered': 'Vastamata',
  'quiz.status.exercisesCompleted': 'Lõpetatud harjutused',
  'quiz.status.correctAnswers': 'Õiged vastused',
  'quiz.message.rejectedInReview': 'Teie eelmine vastus lükati hinnangus tagasi. Palun proovige uuesti.',
  'quiz.youAnswered': 'Te vastasite',
  'quiz.yourAnswer': 'Teie vastus...',
  'quiz.yourScore': 'Teie tulemus:',
  'quiz.quizNotLoaded': 'Ei saanud harjutust laadida',
  'quiz.words': 'Sõnade arv:',
  'quiz.exampleAnswer': 'Näidisvastus:',
  // peer review
  'peerreview.question1': 'Vastus vastab teemale',
  'peerreview.question2': 'Vastus on põhjalik',
  'peerreview.question3': 'Vastus on hästi põhjendatud',
  'peerreview.question4': 'Vastust on kerge jälgida',
  'peerreview.thankYou': 'Aitäh, et hindasite oma kursusekaaslasi',
  'peerreview.giveMoreReviews': 'Kui teil on vaba aega,',
  'peerreview.giveMoreReviewsButton': 'andke rohkem hinnanguid',
  'peerreview.notEnoughAnswers': 'Siin peaks olema vastastikune hinnang, aga seda harjutust pole veel piisavalt palju inimesi sooritanud.',
  'peerreview.selectAnswerMessage': 'On aeg vastastikuseks hindamiseks! Allpool on mõned teiste õpilaste vastused. Valige neist üks ja andke sellele hinnag. Korrake seda tegevust vähemalt kolm korda.',
  'peerreview.minimumPeerReviewWarning': 'Harjutust saab aktsepteerida ainult pärast seda, kui olete andnud hinnangu vähemalt kolmele kaaslasele. ',
  'peerreview.select': 'Vali',
  'peerreview.reportSpam': 'Teata spämmist',
  'peerreview.feedback': 'Teie vastus on ülevaadatud ja aktsepteeritud. Teie kaaslased arvasid sellest nii:',
  // exercise17
  'quiz.exercise17.xLabel': 'Hariduse aastaid',
  'quiz.exercise17.yLabel': 'Oodatav eluiga aastates',
  'quiz.exercise17.textPart1': 'Vaatame seost koolis veedetud aastate (lasteaiast ülikoolini, k.a.) ja oodatava eluea vahel. Siin on andmed kolme erineva riigi kohta, mis on joonisel esitatud punktidena:',
  'quiz.exercise17.textPart2': 'Meil on üks riik, kus keskmine koolis veedetud aastate arv on 10 ja oodatav eluiga 57 aastat; teine riik, kus keskmine koolis veedetud aastate arv on 13 ja oodatav eluiga 53 aastat; ning kolmas riik, kus keskmine koolis veedetud aastate arv on 20 ja oodatav eluiga 80 aastat.',
  'quiz.exercise17.textPart3': 'Te saate lohistada pideva joone lõpp-punkte, paigutamaks selle joone nii, et see järgiks andmeelementide trende. Pange tähele, et ei ole võimalik joont andmeelementidega täielikult sobitada. Sellest pole midagi. Mõned andmeelemendid asetsevad joone kohal ja mõned all. Tähtis on see, et joon kirjeldaks üldist trendi. ',
  'quiz.exercise17.textPart4': 'Pärast joone paigutamist saate ennustada oodatavat eluiga. ',
  'quiz.exercise17.textPart5': 'Mida te saate antud andmete põhjal öelda nende inimeste oodatava eluea kohta, kellel on 15 aastat haridust? Pange tähele, et isegi, kui te saate joont sättides mingi ennustuse aastase täpsusega, ei ole tingimata võimalik teha kindlat ennustust. Oma vastuses võtke arvesse piiratud andmehulk.',
  // exercise18
  'quiz.exercise18.xLabel': 'Hariduse aastaid',
  'quiz.exercise18.yLabel': 'Oodatav eluiga aastates',
  'quiz.exercise18.textPart1': 'Eelmises harjutuses olid meil andmed ainult kolme riigi kohta. Kogu andmestik sisaldab andmeid 14 erineva riigi kohta, mis on esitatud siin graafikul:',
  'quiz.exercise18.textPart2': 'Kas teie ennustus 15 aasta pikkuse haridusega inimeste oodatava eluea kohta nende andmete põhjal muutub? Kui jah, siis miks?',
  'quiz.exercise18.textPart3': 'Missugune järgnevatest valikutest sobiks kõige paremini teie hinnanguga nende inimeste oodatava eluea kohta, kellel on 15 aastat haridust? Valige kõige parem vastusevariant, mis teie arvates on põhjendatud pideva joone mudeli sobitamisel ülaltoodud andmetega.',
  // exercise19
  'quiz.exercise19.xLabel': 'Õpitud tundide arv',
  'quiz.exercise19.yLabel': 'Läbimise tõenäosus',
  'quiz.exercise19.textPart1': 'Iga punkt joonisel vastab ühele üliõpilasele. Joonise allosas on skaala selle kohta, kui palju tunde üliõpilane eksamiks õppis. Eksami sooritanud üliõpilased on esitatud punktidena graafiku ülaosas ja eksamil läbikukkunud allosas. Kasutame skaalat vasakul, et näidata eksami läbimise ennustatud tõenäosust, mille me saame logistilise regressiooni mudelilt. Selgitame seda allpool. Sellelt jooniselt näete jämedalt seda, et õppimisele rohkem aega pühendanud üliõpilastel olid paremad võimalused kursuse läbimiseks. Eriti äärmuslikud juhtumid on intuitiivsed: vähem kui tunnise tööga on kursust läbida väga raske, aga tehes palju tööd, on enamik üliõpilastest edukad. Aga kuidas on nendega, kelle õppimisele kulutatud aeg jääb kuhugi äärmuste vahele? Milline on teie võimalus läbi saada, kui õpite 6 tundi?',
  'quiz.exercise19.textPart2': 'Me saame arvutada eksami läbimise tõenäosuse logistilise regressiooni abil. Kõverjoont joonisel saab tõlgendada läbimise tõenäosusena: näiteks pärast 5 tundi õppimist on läbimise tõenäosus natuke rohkem kui 20%. Me ei lasku kõverjoone saamise detailidesse, aga see on sarnane kaalude leidmisele lineaarses regressioonis.',
  'quiz.exercise19.textPart3': 'Kui te sooviksite, et ülikooli eksami läbimise tõenäosus oleks 80%, ligikaudu mitu tundi peaksite selleks õppima ülaltoodud joonise põhjal? ',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Sisend',
  'quiz.exercise22.sigmoidYLabel': 'Sigmoidväljund',
  'quiz.exercise22.stepXLabel': 'Sisend',
  'quiz.exercise22.stepYLabel': 'Astmefunktsiooni väljund',
  'quiz.exercise22.identityXLabel': 'Sisend',
  'quiz.exercise22.identityYLabel': 'Identsusväljund',
  'quiz.exercise22.textPart1': 'Allpool on kolm erinevate omadustega aktivatsioonifunktsioonide graafikut. Esiteks sigmoidfunktsioon, teiseks astmefunktsioon ja viimaks identsusfunktsioon. ',
  // exercise23
  'quiz.exercise23.happyFaces': 'õnnelikud näod õigesti klassifitseeritud',
  'quiz.exercise23.sadFaces': 'kurvad näod õigesti klassifitseeritud',
  // certificate availability
  'certificate.failedToCheck': 'Tunnistuse olemasolu kontroll ebaõnnestus',
  'certificate.failedToGenerate': 'Tunnistuse loomine ebaõnnestus',
  'certificate.notYetAvailable': 'Saate oma tunnistuse siit pärast seda, kui oleme selle ametlikult kinnitanud. See võib võtta aega. Täname teid kannatlikkuse eest!',
  'certificate.updateName': 'Teie tunnistus on valmis! Palun ajakohastage oma ees- ja perekonnanime, et see kajastuks teie sertifikaadil, kasutades selleks oma profiililehel olevat vormi.',
  'certificate.generate': 'Looge oma tunnistus allpool.',
  'certificate.generateTextPart1': 'Tunnistust luuakse...',
  'certificate.generateTextPart2': 'Palun kontrollige, et see on õige, sest nime ei saa hiljem muuta. Kui te soovite nime muuta, saate seda teha alloleval vormil.',
  'certificate.generateButton': 'Loo tunnistus',
  'certificate.downloadButton': 'Lae alla tunnistus',
  'certificate.checkingAvailability': 'Kontrollin tunnistuse olemasolu...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Õigesti klassifitseeritud',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Valesti klassifitseeritud',
  // section blocks
  'section.block.note': 'Märkus',
  'section.block.example': 'Näide',
  'section.block.keyTerminology': 'Põhiterminoloogia',
  'section.partSummary.spectrumTextPart1': 'Palun ühinege ',
  'section.partSummary.spectrum': '"Elements of AI" kogukonnaga',
  'section.partSummary.spectrumTextPart2': ', et seda peatükki arutada ja küsimusi esitada.',
  'section.partSummary.feedbackTitle': 'Andke meile selle osa kohta tagasisidet',
  'section.partSummary.feedbackPlaceholder': 'Kirjutage oma tagasiside siia...',
  'section.partSummary.feedbackSuccess': 'Aitäh tagasiside eest!',
  // 404
  'globals.404.title': '404 - lehekülge ei leitud',
  'globals.404.intro': "Te sattusite kohta, mida pole olemas ...kurbus.",
  'globals.loading': 'Laen...',
  // footer / menu
  'globals.menu': 'Menüü',
  'globals.menu.about': 'Info',
  'globals.menu.faq': 'KKK',
  'globals.menu.privacyPolicy': 'Privaatsuspoliitika',
  'globals.menu.myProfile': 'Minu profiil',
  'globals.menu.termsofService': 'Kasutustingimused',
  // Dashboard/Index
  'dashboard.title': 'Tere tulemast kursusele!',
  'dashboard.congratulations.title': 'Palju õnne!',
  'dashboard.congratulations.message': 'Olete lõpetanud kursuse "The Elements of AI"',
  'dashboard.congratulations.downloadCertificate': 'Lae alla tunnistus',
  'dashboard.notLoggedIn.title': 'Tere tulemast!',
  'dashboard.notLoggedIn.message': 'Registreeru või logi sisse, et saada ligipääs kogu kursusele. Kursus on tasuta!',
  // Account
  'account.certificate': 'Tunnistus',
  'account.myProfile': 'Minu profiil',
  'account.credits': 'Ainepunktid',
  'account.changePassword': 'Muuda parooli',
  'account.changeLanguage': 'Muuda keelt',
  'account.profileInformation': 'Profiili info',
  'account.goToCourse': 'Mine kursusele',
  'account.buildingAINew': 'Uus kursus inglise keeles',
  'account.accept': 'Nõustun',
  'account.decline': 'Keeldun',
  'account.courses': 'Kursused',
  // Sign Up page
  'signUp.description': 'Täitke allolev vorm, et luua uus mooc.fi konto',
  'signUp.email': 'Teie email',
  'signUp.firstName': 'Teie eesnimi',
  'signUp.lastName': 'Perekonnanimi',
  'signUp.postalCode': 'Sihtnumber',
  'signUp.country': 'Kus te elate?',
  'signUp.Password': 'Parool',
  'signUp.confirmPassword': 'Kinnitage parool',
  'signUp.howToStudyTitle': 'Kuidas te soovite õppida?',
  'signUp.6weeksTitle': '6-nädalane kursus',
  'signUp.6weeksSubTitle': 'Soovitatud (u. 5 tundi nädalas)',
  'signUp.6weeksText': 'Tähtajad on end tõestanud viis aidata teil kursust lõpetada',
  'signUp.selfPacedTitle': 'Oma tempos',
  'signUp.selfPacedText': 'Te võite kursust sooritada oma tempos',
  'signUp.ectsTitle': 'Kas taotlete ainepunkte?',
  'signUp.ectsTitleSwe': 'Would you like to apply for ECTS credits? ',
  'signUp.ectsStudent': 'Olen Helsingi Ülikooli üliõpilane. Kui te märgite selle kasti ja kirjutate oma üliõpilaskoodi, saate ainepunktid automaatselt.',
  'signUp.ectsStudentSwe': 'After completing the course, you can apply for ECTS credits from Linköping University. You can find the instructions ',
  'signup.ectsStudentSweLink': 'here.',
  'signUp.ectsOpenUni': 'Märkige siia linnuke, et saada email juhistega, kuidas taotleda ainepunkte.',
  'signUp.studentNumber': 'Helsingi Ülikooli üliõpilaskood (kui olete üliõpilane)',
  'signUp.privacyOptIn': 'Luban kasutada kogutud andmeid minu õpikäitumisest uurimiseesmärkidel. Andmed sisaldavad infot materjali kasutamise ja kursuse harjutuste kohta. Ühtegi isikut ei ole võimalik avaldatu põhjal tuvastada.',
  'signUp.marketingOptIn': 'Nõustun saama infot uute keeleversioonide ja kursuste kohta. Olen nõus jagama oma kontaktandmeid, et saada kohandatud sõnumeid kolmandate osapoolte platvormidel. Lisateavet meie privaatsuspoliitika kohta.',
  'signUp.privacyLink': 'Lugege meie privaatsuspoliitikat',
  'signUp.error': 'Teie vormil esineb vigu. Palun kontrollige seda.',
  'signUp.acceptPolicyFirst': 'Nõustun privaatsuspoliitika ja teenusetingimustega.',
  // Sign in page
  'signIn.title': 'Logige sisse',
  'signIn.intro': 'Kasutage allolevat vormi, et logida sisse oma mooc.fi kontoga',
  'signIn.email': 'Teie email',
  'signIn.password': 'Teie parool',
  'signIn.noAccount': "Kas teil pole kontot?",
  'signIn.signUpNow': 'Registreeruge nüüd',
  // Forgot password
  'pwForget.title': 'Unustasin parooli',
  'pwForget.description': 'Kirjutage oma mooc.fi konto email ja me saadame teile parooli lähtestamise lingi',
  'pwForget.sendResetLink': 'Saada muutmise link',
  'pwForget.completedDescription': 'Varsti peaksite saama emaili mooc.fi-lt, mis sisaldab parooli lähtestamise linki. Kui te seda ei näe, kontrollige rämpsposti kausta.',
  'pwForget.problemDescription': 'Kui teil tekib probleeme, võtke palun ühendust mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent': 'Parooli muutmise email saadetud',
  'pwForget.emailPlaceholder': 'Emaili aadress',
  // password change
  'pwChange.authError': 'Autentimine ebaõnnestus. Palun proovige uuesti sisse logida.',
  'pwChange.unexpError': 'Parooli uuendamine ebaõnnestus ootamatu vea tõttu',
  'pwChange.required': 'Nõutav',
  'pwChange.pwMustMatch': 'Paroolid peavad kattuma',
  'pwChange.currentPassword': 'Praegune parool',
  'pwChange.newPassword': 'Uus parool',
  'pwChange.confirmPassword': 'Kinnitage uus parool',
  'pwChange.changingPassword': 'Muudan parooli...',
  'pwChange.changePassword': 'Muuda parooli',
  'pwChange.passwordChanged': 'Parool muudetud',
  // user details
  'userDetails.authError': 'Autentimine ebaõnnestus. Palun proovige uuesti sisse logida.',
  'userDetails.unexpError': 'Profiili uuendamine ebaõnnestus ootamatu vea tõttu',
  'userDetails.required': 'Nõutav',
  'userDetails.invalidEmail': 'Kehtetu emaili aadress',
  'userDetails.email': 'Email',
  'userDetails.emailAddress': 'Emaili aadress',
  'userDetails.firstName': 'Eesnimi',
  'userDetails.lastName': 'Perekonnanimi',
  'userDetails.language': 'Keel',
  'userDetails.updatingProfile': 'Uuendan profiili...',
  'userDetails.updateProfile': 'Uuenda profiili',
  'userDetails.profileUpdated': 'Profiil uuendatud',
  // languages
  'languages.en.long': 'Inglise keel',
  'languages.fi.long': 'Soome keel',
  'languages.se.long': 'Rootsi keel',
  'languages.de.long': 'Saksa keel (Saksamaa)',
  'languages.ee.long': 'Eesti keel',
  'languages.it.long': 'Itaalia keel',
  'languages.fr.long': 'Prantsuse keel (Prantsusmaa)',
  'languages.no.long': 'Norra keel',
  'languages.sl.long': 'Sloveeni keel',
  'languages.el.long': 'Kreeka keel',
  'languages.pt.long': 'Portugali keel',
  'languages.nl.long': 'Hollandi keel (Holland)',
  'languages.sk.long': 'Slovaki keel',
  'languages.cs.long': 'Tšehhi keel',
  'languages.lv.long': 'Läti keel',
  'languages.da.long': 'Taani keel',
  'languages.ro.long': 'Rumeenia keel',
  'languages.pl.long': 'Poola keel',
  'languages.ga.long': 'Iiri keel',
  'languages.is.long': 'Islandi keel',
  'languages.de-at.long': 'Saksa keel (Austria)',
  'languages.en-ie.long': 'Inglise keel (Iirimaa)',
  'languages.mt.long': 'Malta keel',
  'languages.fr-be.long': 'Prantsuse keel (Belgia)',
  'languages.lt.long': 'Leedu keel',
  'languages.nl-be.long': 'Hollandi keel (Belgia)',
  'languages.hr.long': 'Horvaadi keel',
  'languages.en-lu.long': 'Inglise keel (Luksemburg)',
  'languages.bg.long': 'Bulgaaria keel',
  'languages.es.long': 'Hispaania keel',
  // Email verfification
  'emailVerification.completedMessage': 'Teie emaili aadress on kontrollitud',
  'emailVerification.verified': 'Kontrollitud',
  // Exercises
  'exercise.loading': 'Laen harjutust...',
  'exercise.signUp': 'Registreeru, et hakata harjutusi lahendama:',
  'exercise.score': 'Teie tulemus:',
  'exercise.wrongLocale': 'Vale koht',
  // Part tempplate
  'partTemplate.intro1': 'Peatükis ',
  'partTemplate.intro2': ' käsitleme järgmisi teemasid. Alustamiseks klõpsake allpool:',
  'partTemplate.spectrumPart1': 'Palun ühinege ',
  'partTemplate.spectrum': '"Elements of AI" kogukonnaga',
  'partTemplate.spectrumPart2': ', et seda peatükki arutada ja küsimusi esitada.',
  // missing info
  'missingInfo.title': 'Puuduv info',
  'missingInfo.body': 'Me küsime neid küsimusi kasutajatelt tavaliselt registeerumisel, aga teie logisite sisse olemasoleva mooc.fi kontoga. Jätkamiseks palun täitke allolev vorm.',
  'missingInfo.privacyPolicy': 'Lugege meie privaatsuspoliitikat',
  'missingInfo.error': 'Teie vormil esineb vigu. Palun kontrollige seda.',
  'missingInfo.privacyPolicyUpdateBody': "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
  'missingInfo.privacyPolicyUpdateLink': 'Lugege meie privaatsuspoliitikat siin.',
  // routes
  'routes.LANDING': '/ee/',
  'routes.SIGN_UP': '/ee/signup',
  'routes.SIGN_IN': '/ee/signin',
  'routes.MISSING_INFO': '/ee/missinginfo',
  'routes.PASSWORD_FORGET': '/ee/pw-forget',
  'routes.HOME': '/ee/home',
  'routes.ACCOUNT': '/ee/account',
  'routes.PRIVACY': 'https://www.elementsofai.ee/faq/privaatsuspoliitika',
  'routes.FAQ': 'https://www.elementsofai.ee/faq',
  'routes.ABOUT': 'https://www.elementsofai.ee',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/ee-eesti-kogukond',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.ee/faq/kuidas-saada-2eapd',
  'routes.ACCEPT_POLICY': '/ee/accept-policy',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Tassi kohvi päevas',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Kirjutatud koodirida',
  // meta
  'meta.title': 'Tasuta e-kursus',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Uurige rohkem Helsingi Ülikooli ja Reaktori AI kursuse kohta üliõpilastele ja äriinimestele - ei nõua programmeerimis- ega matemaatikaoskusi.',
  'meta.sitename': 'Elements of AI',
  'meta.title.og': 'A free online introduction to artificial intelligence for non-experts',
  // ECTS
  'ects.text': 'Siit saate juhised ainepunktide taotlemiseks pärast kursuse lõpetamist.',
  'ects.textCompleted': 'Kui teil on soome isikukood, saate ainepunkte taotleda klõpsates ',
  'ects.link': 'siin.',
  'ects.info': 'Kas taotlete ainepunkte?',
  'ects.readMore': 'Loe rohkem',
  // Countries
  'countryselect.select': "Palun vali",
  'Estonia': "Eesti",
  'Finland': "Soome",
  'Germany': "Saksamaa",
  'Sweden': "Rootsi",
  'Norway': "Norra",
  "Latvia": "Läti",
  "Netherlands": "Holland",
  'Italy': "Itaalia",
  'France': 'Prantsusmaa',
  "Countries": {
    'Afghanistan': "Afganistan",
    'Åland Islands': "Ahvenamaa",
    'Albania': "Albaania",
    'Algeria': "Alžeeria",
    'American Samoa': "Ameerika Samoa",
    'Andorra': "Andorra",
    'Angola': "Angoola",
    'Anguilla': "Anguilla",
    'Antarctica': "Antarktika",
    'Antigua and Barbuda': "Antigua ja Barbuda",
    'Argentina': "Argentiina",
    'Armenia': "Armeenia",
    'Aruba': "Aruba",
    'Australia': "Austraalia",
    'Austria': "Austria",
    'Azerbaijan': "Aserbaidžaan",
    'Bahamas': "Bahama",
    'Bahrain': "Bahrein",
    'Bangladesh': "Bangladesh",
    'Barbados': "Barbados",
    'Belarus': "Valgevene",
    'Belgium': "Belgia",
    'Belize': "Belize",
    'Benin': "Benin",
    'Bermuda': "Bermuda",
    'Bhutan': "Bhutan",
    'Bolivia': "Boliivia",
    'Bosnia and Herzegovina': "Bosnia ja Hertsegoviina",
    'Botswana': "Botswana",
    'Bouvet Island': "Bouveti saar",
    'Brazil': "Brasiilia",
    'British Indian Ocean Territory': "Briti India ookeani ala",
    'Brunei Darussalam': "Brunei Darussalam",
    'Bulgaria': "Bulgaaria",
    'Burkina Faso': "Burkina Faso",
    'Burundi': "Burundi",
    'Cambodia': "Kambodža",
    'Cameroon': "Kamerun",
    'Canada': "Kanada",
    'Cape Verde': "Roheneemesaared",
    'Cayman Islands': "Kaimani saared",
    'Central African Republic': "Kesk-Aafrika Vabariik",
    'Chad': "Tšaad",
    'Chile': "Tšiili",
    'China': "Hiina",
    'Christmas Island': "Jõulusaar",
    'Cocos (Keeling) Islands': "Kookossaared (Keelingi) saared",
    'Colombia': "Kolumbia",
    'Comoros': "Komoorid",
    'Congo': "Kongo",
    'Congo, The Democratic Republic of The': "Kongo Demokraatlik Vabariik",
    'Cook Islands': "Cooki saared",
    'Costa Rica': "Costa Rica",
    "Cote D'ivoire": "Elevandiluurannik",
    'Croatia': "Horvaatia",
    'Cuba': "Kuuba",
    'Cyprus': "Küpros",
    'Czech Republic': "Tšehhi Vabariik",
    'Denmark': "Taani",
    'Djibouti': "Djibouti",
    'Dominica': "Dominica",
    'Dominican Republic': "Dominikaani Vabariik",
    'Ecuador': "Ecuador",
    'Egypt': "Egiptus",
    'El Salvador': "El Salvador",
    'Equatorial Guinea': "Ekvatoriaalne Guinea",
    'Eritrea': "Eritrea",
    'Estonia': "Eesti",
    'Ethiopia': "Etioopia",
    'Falkland Islands (Malvinas)': "Falklandi saared (Malvinas)",
    'Faroe Islands': "Fääri saared",
    'Fiji': "Fidži",
    'Finland': "Soome",
    'France': "Prantsusmaa",
    'French Guiana': "Prantsuse Guajaana",
    'French Polynesia': "Prantsuse Polüneesia",
    'French Southern Territories': "Prantsuse lõunapiirkonnad",
    'Gabon': "Gabon",
    'Georgia': "Gruusia",
    'Germany': "Saksamaa",
    'Ghana': "Ghana",
    'Gibraltar': "Gibraltar",
    'Greece': "Kreeka",
    'Greenland': "Gröönimaa",
    'Grenada': "Grenada",
    'Guadeloupe': "Guadeloupe",
    'Guam': "Guam",
    'Guatemala': "Guatemala",
    'Guernsey': "Guernsey",
    'Guinea': "Guinea",
    'Guinea-Bissau': "Guinea-Bissau",
    'Guyana': "Guajaana",
    'Haiti': "Haiti",
    'Heard Island and Mcdonald Islands': "Kuuldesaar ja Mcdonaldi saared",
    'Holy See (Vatican City State)': "Püha Tool (Vatikani Linnriik)",
    'Honduras': "Honduras",
    'Hong Kong': "Hongkong",
    'Hungary': "Ungari",
    'Iceland': "Island",
    'India': "India",
    'Indonesia': "Indoneesia",
    'Iran, Islamic Republic of': "Iraan, Islami Vabariik",
    'Iraq': "Iraak",
    'Ireland': "Iirimaa",
    'Isle of Man': "Man",
    'Israel': "Iisrael",
    'Italy': "Itaalia",
    'Jamaica': "Jamaica",
    'Japan': "Jaapan",
    'Jersey': "Jersey",
    'Jordan': "Jordaania",
    'Kazakhstan': "Kasahstan",
    'Kenya': "Keenia",
    'Kiribati': "Kiribati",
    "Korea, Democratic People's Republic of": "Korea, Korea Rahvademokraatlik Vabariik",
    'Korea, Republic of': "Korea Vabariik",
    'Kosovo': 'Kosovo',
    'Kuwait': "Kuveit",
    'Kyrgyzstan': "Kõrgõzstan",
    "Lao People's Democratic Republic": "Laose Demokraatlik Rahvavabariik",
    'Latvia': "Läti",
    'Lebanon': "Liibanon",
    'Lesotho': "Lesotho",
    'Liberia': "Libeeria",
    'Libyan Arab Jamahiriya': "Liibüa Araabia Jamahiriya",
    'Liechtenstein': "Liechtenstein",
    'Lithuania': "Leedu",
    'Luxembourg': "Luksemburg",
    'Macao': "Aomen",
    'Macedonia, The Former Yugoslav Republic of': "Makedoonia, endine Jugoslaavia Vabariik",
    'Madagascar': "Madagaskar",
    'Malawi': "Malawi",
    'Malaysia': "Malaisia",
    'Maldives': "Maldiivid",
    'Mali': "Malis",
    'Malta': "Malta",
    'Marshall Islands': "Marshalli saared",
    'Martinique': "Martinique",
    'Mauritania': "Mauritaania",
    'Mauritius': "Mauritius",
    'Mayotte': "Mayotte",
    'Mexico': "Mehhikos",
    'Micronesia, Federated States of': "Mikroneesia,",
    'Moldova, Republic of': "Moldova Vabariik",
    'Monaco': "Monaco",
    'Mongolia': "Mongoolia",
    'Montenegro': "Montenegro",
    'Montserrat': "Montserrat",
    'Marocco': "Maroko",
    'Mozambique': "Mosambiik",
    'Myanmar': "Myanmar",
    'Namibia': "Namiibia",
    'Nauru': "Nauru",
    'Nepal': "Nepaal",
    'Netherlands': "Holland",
    'Netherlands Antilles': "Hollandi Antillid",
    'New Caledonia': "Uus-Kaledoonia",
    'New Zealand': "Uus-Meremaa",
    'Nicaragua': "Nicaragua",
    'Niger': "Niger",
    'Nigeria': "Nigeeria",
    'Niue': "Niue",
    'Norfolk Island': "Norfolki saar",
    'Northern Mariana Islands': "Põhja-Mariaanid",
    'Norway': "Norra",
    'Oman': "Omaan",
    'Pakistan': "Pakistan",
    'Palau': "Palau",
    'Palestinian Territory, Occupied': "Palestiina ala, okupeeritud",
    'Panama': "Panama",
    'Papua New Guinea': "Paapua Uus-Guinea",
    'Paraguay': "Paraguay",
    'Peru': "Peruu",
    'Philippines': "Filipiinid",
    'Pitcairn': "Pitcairn",
    'Poland': "Poola",
    'Portugal': "Portugal",
    'Puerto Rico': "Puerto Rico",
    'Qatar': "Katar",
    'Reunion': "Kokkutulek",
    'Romania': "Rumeenia",
    'Russian Federation': "Venemaa Föderatsioon",
    'Rwanda': "Rwanda",
    'Saint Helena': "Püha Helena",
    'Saint Kitts and Nevis': "Saint Kitts ja Nevis",
    'Saint Lucia': "Saint Lucia",
    'Saint Pierre and Miquelon': "Saint Pierre ja Miquelon",
    'Saint Vincent and The Grenadines': "Saint Vincent ja Grenadiinid",
    'Samoa': "Samoa",
    'San Marino': "San Marino",
    'Sao Tome and Principe': "São Tomé ja Principe",
    'Saudi Arabia': "Saudi Araabia",
    'Senegal': "Senegal",
    'Serbia': "Serbia",
    'Seychelles': "Seišellid",
    'Sierra Leone': "Sierra Leone",
    'Singapore': "Singapur",
    'Slovakia': "Slovakkia",
    'Slovenia': "Sloveenia",
    'Solomon Islands': "Saalomoni saared",
    'Somalia': "Somaalia",
    'South Africa': "Lõuna-Aafrika",
    'South Georgia and The South Sandwich Islands': "Lõuna-Georgia ja Lõuna-Sandwichi saared",
    'Spain': "Hispaania",
    'Sri Lanka': "Sri Lanka",
    'Sudan': "Sudaan",
    'Suriname': "Suriname",
    'Svalbard and Jan Mayen': "Svalbard ja Jan Mayen",
    'Swaziland': "Svaasimaa",
    'Sweden': "Rootsi",
    'Swizerland': "Šveits",
    'Syrian Arab Republic': "Süüria Araabia Vabariik",
    'Taiwan': "Taiwan",
    'Tajikistan': "Tadžikistan",
    'Tanzania, United Republic of': "Tansaania,",
    'Thailand': "Tai",
    'Timor-leste': "Ida-Timor",
    'Togo': "Togo",
    'Tokelau': "Tokelau",
    'Tonga': "Tonga",
    'Trinidad and Tobago': "Trinidad ja Tobago",
    'Tunisia': "Tuneesia",
    'Turkey': "Türgi",
    'Turkmenistan': "Türkmenistan",
    'Turks and Caicos Islands': "Türgi ja Caicose saared",
    'Tuvalu': "Tuvalu",
    'Uganda': "Uganda",
    'Ukraine': "Ukraina",
    'United Arab Emirates': "Araabia Ühendemiraadid",
    'United Kingdom': "Ühendkuningriik",
    'United States': "Ühendriigid",
    'United States Minor Outlying Islands': "Ühendriikide hajasaared",
    'Uruguay': "Uruguay",
    'Uzbekistan': "Usbekistan",
    'Vanuatu': "Vanuatu",
    'Venezuela': "Venezuela",
    'Viet Nam': "Vietnami",
    'Virgin Islands, British': "Briti Neitsisaared",
    'Virgin Islands, U.S.': "USA Neitsisaared",
    'Wallis and Futuna': "Wallis ja Futuna",
    'Western Sahara': "Lääne-Sahara",
    'Yemen': "Jeemen",
    'Zambia': "Sambia",
    'Zimbabwe': "Zimbabwe"
  },
  //Alt texts
  'alt.example-1': 'kaks isejuhtivat autot',
  'alt.example-2': 'mobiiltelefoni hoidev käsi',
  'alt.example-3': "naise nägu",
  'alt.defining-ai': 'tehisintellektiga seotud sõnade kogum',
  'alt.turing-test': 'mees tšätib arvutis roboti ja naisega',
  'alt.chinese-room': 'Hiina toa argument',
  'alt.chicken-crossing': 'robot paadis koos kana, rebase ja kanasöödakotiga',
  'alt.a-to-b': 'käsi hoiab telefoni, mis kasutab navigatsioonirakendust',
  'alt.tic-tac-toe': 'kaks last mängivad trips-traps-trulli',
  'alt.self-driving-car': 'naine kõnnib isejuhtiva auto ees',
  'alt.spam-or-ham': 'tavaline e-kiri ja rämpspost',
  'alt.mnist': 'MNIST-andmestik',
  'alt.nearest-neighbor': 'kaks inimest teevad sisseoste',
  'alt.nearest-neighbor-graph': 'lähima naabri klassifitseerija',
  'alt.recommendation': 'mees ja naine kuulavad telefoniga muusikat',
  'alt.price-real-estate': 'kolm erinevat maja',
  'alt.supervised-learning': 'inimene õpetab robotit',
  'alt.dice': 'täringut',
  'alt.chess': 'malelaud',
  'alt.poker': 'käsi hoiab mängukaarte',
  'alt.eyes': 'kolm pruunisilmset inimest, üks sinisilmne inimene',
  'alt.search-1': 'ainus tee, mis viib eesmärgini',
  'alt.search-2': 'neli erinevat teed viivad sama eesmärgini',
  'alt.chicken-crossing-1': 'kana üle jõe viimine – lähteolek',
  'alt.chicken-crossing-2': 'kana üle jõe viimine – esimene üleviimine',
  'alt.chicken-crossing-3': 'kana üle jõe viimine – kõik üleviimised',
  'alt.chicken-crossing-4': 'kana üle jõe viimine – parim tee',
  'alt.chicken-crossing-5': 'kana üle jõe viimine – lähteolek',
  'alt.implication-1': 'mees ja naine',
  'alt.implication-2': 'silmad',
  'alt.implication-3': 'valvekaamera',
  'alt.implication-4': 'sülearvutiga töötav robot',
  'alt.oddchange': 'päike ja pilved',
  'alt.brain': 'aju joonistamine',
  'alt.carstop': 'isejuhtiv sõiduk stoppmärgi ees',
  'alt.gan': 'robot hoiab kassipilti ja stoppmärgi pilti',
  'alt.hammock': 'robot lehvitab võrkkiiges lamavale mehele tuult',
  'alt.game-tree-1': 'mängupuu 1',
  'alt.game-tree-2': 'mängupuu 2',
  'alt.game-tree-3': 'mängupuu 3',
  'alt.game-tree-4': 'mängupuu 4',
  'alt.bayes-rule-1': 'pilt, millel on kujutatud vähi sõeluuringu valepositiivsed ja valenegatiivsed tulemused',
  'alt.bayes-rule-2': 'pilt, millel on kujutatud vähi sõeluuringu valepositiivsed ja valenegatiivsed tulemused',
  'alt.defineai': 'tehisintellektiga seotud sõnade kogum',
  'alt.winter': 'robot magab lumes',
  'alt.terminator': 'hirmutav robot'
}
