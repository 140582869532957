import _ from "lodash";

import {
  getProgressWithValidation,
  getCourseState
} from "../../services/quiznator";
import {
  setEmptyAnswers,
  updateAnswerStates,
  updateAnswerStatuses,
  fetchAnswersWithValidationSuccess
} from "../answer/actions";
import { initPeerReviews } from "../peerReview/actions";
import * as quizTypes from "../../constants/quiztypes";
import { precise_round } from "../../utilities/mathutil";
import { config } from "../course-config";

import { updateCourseState } from "../courseState/actions";

export const SET_STATS_INIT_STATE = "SET_STATE_INIT_STATE";

export const SET_COMPLETED = "SET_COMPLETED";
export const SET_BATCH_COMPLETED = "SET_BATCH_COMPLETED";

export const FETCH_PROGRESS_WITH_VALIDATION = "FETCH_PROGRESS_WITH_VALIDATION";
export const FETCH_PROGRESS_WITH_VALIDATION_SUCCESS =
  "FETCH_PROGRESS_WITH_VALIDATION_SUCCESS";
export const FETCH_PROGRESS_WITH_VALIDATION_FAILURE =
  "FETCH_PROGRESS_WITH_VALIDATION_FAILURE";

export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const UPDATE_PROGRESS_SUCCESS = "UPDATE_PROGRESS_SUCCESS";
export const UPDATE_PROGRESS_FAIL = "UPDATE_PROGRESS_FAIL";

export const START_RECALCULATE_VALIDATION = "START_RECALCULATE_VALIDATION";
export const FINISH_RECALCULATE_VALIDATION = "FINISH_RECALCULATE_VALIDATION";

export const FETCH_COURSE_STATE = "FETCH_COURSE_STATE";
export const FETCH_COURSE_STATE_SUCCESS = "FETCH_COURSE_STATE_SUCCESS";
export const FETCH_COURSE_STATE_FAILURE = "FETCH_COURSE_STATE_FAILURE";

export const UPDATE_ESSAYS_WAITING_PEER_REVIEWS =
  "UPDATE_ESSAYS_WAITING_PEER_REVIEWS";

function setCompletedAction(data) {
  return {
    type: SET_COMPLETED,
    payload: data
  };
}

function setBatchCompletedAction(data) {
  return {
    type: SET_BATCH_COMPLETED,
    payload: data
  };
}

export function updateEssaysWaitingPeerReviews(data) {
  return (dispatch, getState) => {
    const { quizzes, peerReviews, answers } = getState();

    let newEssaysAwaitingPeerReviewsGiven = [];
    let newEssaysAwaitingConfirmation = [];

    const nextPeerReviewById = _.get(peerReviews, data.sourceQuizId);
    const nextPeerReviews = {
      ...peerReviews,
      [data.sourceQuizId]: {
        ...nextPeerReviewById,
        given:
          !!nextPeerReviewById && !!nextPeerReviewById.given
            ? _.union(nextPeerReviewById.given, data)
            : [data]
      }
    };

    Object.keys(answers).forEach(key => {
      updatePeerReviewStatuses({
        quiz: _.get(quizzes, key, null),
        answer: _.get(answers, key, null),
        peerReview: _.get(nextPeerReviews, key, null),
        essaysAwaitingPeerReviewsGiven: newEssaysAwaitingPeerReviewsGiven,
        essaysAwaitingConfirmation: newEssaysAwaitingConfirmation
      });
    });

    dispatch({
      type: UPDATE_ESSAYS_WAITING_PEER_REVIEWS,
      payload: {
        essaysAwaitingPeerReviewsGiven: newEssaysAwaitingPeerReviewsGiven,
        essaysAwaitingConfirmation: newEssaysAwaitingConfirmation
      }
    });
  };
}

function startRecalculateValidationAction() {
  return dispatch => dispatch({ type: START_RECALCULATE_VALIDATION });
}

function finishRecalculateValidationAction(data) {
  return {
    type: FINISH_RECALCULATE_VALIDATION,
    data
  };
}

export function initStats(options = {}) {
  return (dispatch, getState) => {
    const { stats, tags } = getState();
    const { firstTime, onlyUpdate, courseId = null } = options;
    //.filter(id => !_.includes(ignored, id))

    return dispatch(
      fetchProgressWithValidation([], {
        stripAnswers: true,
        onlyUpdate,
        courseId
      })
    )
      .then(progress => {
        const statsTags = tags
          ? Object.keys(_.omit(tags, ["ignore", "error"]))
          : [];
        const quizIds =
          statsTags.length > 0
            ? _.flattenDeep(statsTags.map(k => tags[k]))
            : _.uniq(
                _.flatten([
                  progress.answered,
                  progress.notAnswered,
                  progress.rejected
                ]).map(e => e.quiz._id.toString())
              );

        if (progress.error) {
          return Promise.reject(progress.error);
        }
        // TODO: do I handle the rejected here?
        const answeredQuizIds = (progress.answered || []).map(
          entry => entry.quiz._id
        );
        const rejectedQuizIds = (progress.rejected || []).map(
          entry => entry.quiz._id
        );

        dispatch(
          setBatchCompleted({
            quizIds: answeredQuizIds
          })
        );

        const { answers } = getState();

        const filtered = quizIds.filter(quizId => {
          // clarity ...

          if (!answers[quizId]) {
            return true;
          }

          // what was the point of reset, anyway, other than init?
          if (
            _.includes(rejectedQuizIds, quizId) &&
            !_.get(answers, `[${quizId}].changed`, false)
          ) {
            return true;
          }

          return false;
        });

        if (filtered.length > 0) {
          dispatch(setEmptyAnswers(filtered));
        }

        if (firstTime) {
          // dispatch(setFetchCourseStateTimeout())
        }

        return Promise.resolve(progress);
      })
      .catch(err => Promise.reject(err));
  };
}

export function fetchProgressWithValidation(quizIds = [], options = {}) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_PROGRESS_WITH_VALIDATION, quizIds });

    return getProgressWithValidation(quizIds, {
      ...options,
      stripAnswers: true
    })
      .then(progress => {
        const answered = _.get(progress, "answered", []).map(entry =>
          entry.quiz._id.toString()
        );
        const notAnswered = _.get(progress, "notAnswered", []).map(entry =>
          entry.quiz._id.toString()
        );
        const rejected = _.reduce(
          _.get(progress, "rejected", []),
          (obj, entry) => {
            obj[entry.quiz._id] = {
              answer: _.get(entry, "answer[0]", undefined),
              ...entry.validation
            };
            return obj;
          },
          {}
        );

        dispatch({
          type: FETCH_PROGRESS_WITH_VALIDATION_SUCCESS,
          payload: {
            ...progress.validation,
            answered,
            notAnswered,
            rejected,
            essaysAwaitingPeerReviewsGiven:
              progress.essaysAwaitingPeerReviewsGiven,
            essaysAwaitingConfirmation: progress.essaysAwaitingConfirmation
          }
        });

        return progress;
      })
      .catch(err => {
        dispatch({ type: FETCH_PROGRESS_WITH_VALIDATION_FAILURE, error: err });
        return Promise.reject(err);
      });
  };
}

export function setCompleted(data) {
  return dispatch => dispatch(setCompletedAction(data));
}

export function setBatchCompleted(data) {
  return dispatch => dispatch(setBatchCompletedAction(data));
}

export function setStatsInitState() {
  return dispatch => dispatch({ type: SET_STATS_INIT_STATE });
}

export function updateProgress(loggedIn) {
  return (dispatch, getState) => {
    const { validating } = _.get(getState(), "stats", {});

    if (validating) {
      // already validating somehow, don't do anything
      // TODO: doesn't this have a chance of getting stuck to true?
      return;
    }
    dispatch({ type: UPDATE_PROGRESS });

    if (!loggedIn) {
      dispatch({ type: UPDATE_PROGRESS_FAIL, error: "not logged in" });
    } else {
      dispatch(initStats({ onlyUpdate: true }))
        .then(progress => {
          dispatch(updateCourseState(progress));
          dispatch(updateAnswerStatuses(progress));
          dispatch(initPeerReviews(progress));
        })
        .then(() => dispatch({ type: UPDATE_PROGRESS_SUCCESS }))
        .catch(err => dispatch({ type: UPDATE_PROGRESS_FAIL, error: err }));
    }
  };
}

function updatePeerReviewStatuses({
  quiz,
  answer,
  peerReview,
  essaysAwaitingPeerReviewsGiven,
  essaysAwaitingConfirmation
}) {
  if (_.get(quiz, `data.type`, null) === quizTypes.ESSAY) {
    if (!!_.get(answer, `data`, null) && _.get(answer, `submitted`)) {
      if (
        _.get(peerReview, `given`, []).length <
        config.MINIMUM_PEER_REVIEWS_GIVEN
      ) {
        essaysAwaitingPeerReviewsGiven.push(quiz.data._id.toString());
      }
      if (!_.get(answer, `confirmed`) && !_.get(answer, `rejected`)) {
        essaysAwaitingConfirmation.push(quiz.data._id.toString());
      }
    }
  }
  /* 
  return { essaysAwaitingPeerReviewsGiven, essaysAwaitingPeerReviewsGiven }
 */
}

// ------------------------ deprecated? ---------------------------
// note: not used for now, since course state comes from progress
export function setFetchCourseStateTimeout(interval = 300000) {
  // 5 mins
  return dispatch => {
    setInterval(() => {
      getCourseState()
        .then(courseState => {
          dispatch({ type: FETCH_COURSE_STATE_SUCCESS, data: courseState });
          dispatch(checkUpdatedAnswerStates(courseState));
        })
        .catch(err =>
          dispatch({ type: FETCH_COURSE_STATE_FAILURE, error: err })
        );
    }, interval);
  };
}

// note: not used now, as above
function checkUpdatedAnswerStates(courseState) {
  return (dispatch, getState) => {
    const { stats, quizzes, answers } = getState();

    const newAnswers = _.get(courseState, "completion.data.answerValidation");
    const updatedAnswers = [];

    if (!newAnswers) {
      return updatedAnswers;
    }

    newAnswers.forEach(answer => {
      const quiz = _.get(quizzes, `[${answer.quizId}].data`);
      const oldAnswer = _.get(answers, answer.quizId);

      if (!quiz || (!!quiz && quiz.type !== quizTypes.ESSAY)) {
        return;
      }

      // TODO: inject validation stuff here or just stick them in coursestate

      if (
        !oldAnswer ||
        (!!oldAnswer.answerId &&
          !!answer.answerId &&
          oldAnswer.answerId !== answer.answerId)
      ) {
        return;
      }

      if (oldAnswer.changed || (!!oldAnswer.data && !oldAnswer.submitted)) {
        return;
      }

      if (
        answer.confirmed !== oldAnswer.confirmed ||
        answer.rejected !== oldAnswer.rejected
      ) {
        dispatch(updateAnswerStates({ oldAnswer, newAnswer: answer }));
        updatedAnswers.push(answer); // TODO: aargh
      }
    });

    if (updatedAnswers.length === 0) {
      return updatedAnswers;
    }

    updatedAnswers.forEach(answer => {
      const answerWithMockValidation = {
        ...answer,
        validation: {
          points: answer.points,
          maxPoints: answer.maxPoints,
          normalizedPoints: answer.normalizedPoints
        }
      };
      dispatch(recalculateValidation(answer.quizId, answerWithMockValidation)); // eh
    });

    return updatedAnswers;
  };
}

export function recalculateValidation(quizId, answer) {
  return (dispatch, getState) => {
    dispatch(startRecalculateValidationAction());

    const { quizzes, answers, peerReviews, stats, tags } = getState();

    const quizIds = _.flattenDeep(Object.keys(tags).map(tag => tags[tag]));
    // don't bother trying to recalculate if quiz is not in the course

    if (
      !quizId ||
      !tags ||
      (!!quizId && !!tags && !_.includes(quizIds, quizId))
    ) {
      return;
    }

    const quiz = _.get(quizzes, quizId);

    if (!quiz) {
      return;
    }

    const ignored = quizIds.filter(ignoredQuizId =>
      _.includes(_.get(quizzes, `[${ignoredQuizId}].data.tags`, []), "ignore")
    );

    let newAnswered = stats.answered.slice(0);
    let newNotAnswered = stats.notAnswered.slice(0);
    let newRejected = {
      ...stats.rejected
    };

    if (!answer.rejected) {
      newAnswered.push(quizId);

      if (_.includes(newRejected, quizId)) {
        newRejected = newRejected.filter(id => id !== quizId);
      }

      if (_.includes(newNotAnswered, answer.quizId)) {
        newNotAnswered = newNotAnswered.filter(id => id !== answer.quizId);
      }
    } else {
      // rejected: delete from (not)answered
      if (_.includes(newAnswered, quizId)) {
        newAnswered = newAnswered.fill(id => id !== quizId);
      }

      if (_.includes(newNotAnswered, answer.quizId)) {
        newNotAnswered = newNotAnswered.filter(id => id !== answer.quizId);
      }

      newRejected = {
        ...newRejected,
        [quizId]: {
          answer,
          ...answer.validation
        }
      };
    }

    const nextAnswerState = {
      ...answers,
      [answer.quizId]: {
        data: answer.data, // we don't need all fields
        confirmed: answer.confirmed,
        rejected: answer.rejected,
        submitted: true,
        validation: answer.validation
      } // TODO: not actually when rejected
    };

    let normalizedPoints = 0;
    let maxNormalizedPoints = _.pullAll(
      _.union(newAnswered, newNotAnswered, Object.keys(newRejected)),
      ignored
    ).length;
    let maxCompletedPoints = 0;
    let maxCompletedNormalizedPoints =
      newAnswered.length - _.intersection(newAnswered, ignored).length;

    let confirmedAmount = newAnswered
      .map(quizId => {
        return (
          _.get(nextAnswerState, `[${quizId}].confirmed`, false) &&
          !_.includes(ignored, quizId)
        );
      })
      .filter(v => !!v).length;
    let confirmedIgnoredAmount = newAnswered
      .map(quizId => {
        return (
          _.get(nextAnswerState, `[${quizId}].confirmed`, false) &&
          _.includes(ignored, quizId)
        );
      })
      .filter(v => !!v).length;
    let ignoredAmount = ignored.length;
    let points = 0;
    let maxPoints =
      _.sum(
        newAnswered.map(quizId => {
          return !_.includes(ignored, quizId)
            ? _.get(nextAnswerState, `[${quizId}].validation.maxPoints`, 0)
            : 0;
        })
      ) +
      _.sum(
        newNotAnswered.map(quizId => {
          return !_.includes(ignored, quizId)
            ? _.get(nextAnswerState, `[${quizId}].validation.maxPoints`, 0)
            : 0;
        })
      ) +
      _.sum(
        (Object.keys(newRejected) || []).map(quizId => {
          return !_.includes(ignored, quizId)
            ? newRejected[quizId].maxPoints
            : 0;
        })
      );

    let score = 0;
    let pointsPercentage = 0;
    let progress = 0;

    let newEssaysAwaitingPeerReviewsGiven = [];
    let newEssaysAwaitingConfirmation = [];

    Object.keys(nextAnswerState).forEach(key => {
      const stateAnswer = nextAnswerState[key];
      if (!!stateAnswer.validation) {
        if (!_.includes(ignored, key)) {
          points += _.get(stateAnswer, "validation.points", 0);
          normalizedPoints += _.get(
            stateAnswer,
            "validation.normalizedPoints",
            0
          );

          if (stateAnswer.confirmed) {
            maxCompletedPoints += _.get(stateAnswer, "validation.maxPoints", 0);
          }
        }
      }

      updatePeerReviewStatuses({
        quiz: _.get(quizzes, key, null),
        answer: stateAnswer,
        peerReview: _.get(peerReviews, key, null),
        essaysAwaitingPeerReviewsGiven: newEssaysAwaitingPeerReviewsGiven,
        essaysAwaitingConfirmation: newEssaysAwaitingConfirmation
      });
    });

    score =
      maxNormalizedPoints > 0
        ? precise_round((normalizedPoints / maxNormalizedPoints) * 100, 2)
        : 0;
    pointsPercentage =
      maxPoints > 0 ? precise_round((points / maxPoints) * 100, 0) : 0;
    progress =
      maxNormalizedPoints > 0
        ? precise_round((confirmedAmount / maxNormalizedPoints) * 100, 2)
        : 0;

    if (
      quiz.type === quizTypes.ESSAY &&
      (!answer.peerReviews ||
        (!!answer.peerReviews &&
          answer.peerReviews.given &&
          answer.peerReviews.given.length < config.MINIMUM_PEER_REVIEWS_GIVEN))
    ) {
      if (!~newEssaysAwaitingPeerReviewsGiven.indexOf(answer.quizId)) {
        newEssaysAwaitingPeerReviewsGiven = [
          ...newEssaysAwaitingPeerReviewsGiven,
          answer.quizId
        ];
      }
    }

    dispatch(
      finishRecalculateValidationAction({
        answered: newAnswered,
        notAnswered: newNotAnswered,
        rejected: newRejected,
        confirmedAmount,
        confirmedIgnoredAmount,
        ignoredAmount,
        essaysAwaitingPeerReviewsGiven: newEssaysAwaitingPeerReviewsGiven,
        essaysAwaitingConfirmation: newEssaysAwaitingConfirmation,
        points,
        maxPoints,
        score,
        pointsPercentage,
        progress,
        normalizedPoints,
        maxNormalizedPoints,
        maxCompletedPoints,
        maxCompletedNormalizedPoints,
        validating: false
      })
    );
  };
}
