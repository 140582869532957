import styled from "styled-components";

const SpamButton = styled.button`
  font-size: 1rem;
  outline: none !important;
  font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
  line-height: 2rem;
  border-radius: 2rem;
  background: ${props => (props.selected ? "#4844a3" : "white")};
  border: none;
  width: ${props => (props.disabled ? "80%" : "100%")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  transition: all 0.2s ease;
  display: block;
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.04);
  padding: 0.75rem 1rem;
  color: ${props => (props.selected ? "white" : "#4844a3")};
  /* max-width: 150px; */
  max-width: 125px;
  padding: 0.75rem 1rem;
  @media screen and (min-width: 72em) {
    max-width: 200px;
    max-width: 200px;
  }
  :hover {
    cursor: pointer;
  }
`;

export default SpamButton;
