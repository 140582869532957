import * as actionTypes from "./actions";
import { RESET_STATE } from "../actions";
import { combineReducers } from "redux";
import dateToUnix from "../../utilities/datetounix";

// expire quiz in 5 minutes
export const expiryDate = {
  expireAt: dateToUnix(new Date()) + 180
};

export const initialState = {
  initializing: false // wheeee
  /*
    [quizId]: {
      data: <quiz data>,
      expiryDate: <expiry as datetounix>
      receivedAt: <date>
      loading,
    }
  */
};

export default function quizzes(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_QUIZZES_INIT_STATE:
    case RESET_STATE:
      return { ...initialState };
    case actionTypes.CHANGE_QUIZ_STATE:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          data: action.data,
          ...expiryDate
        }
      });
    case actionTypes.REQUEST_QUIZ:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          loading: true,
          error: ""
        }
      });
    case actionTypes.RECEIVE_QUIZ:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          loading: false,
          data: action.data,
          receivedAt: action.receivedAt,
          ...expiryDate
        }
      });
    case actionTypes.REQUEST_QUIZZES:
      let newRequestedQuizStates = {};

      action.quizIds.forEach(quizId => {
        newRequestedQuizStates = {
          ...newRequestedQuizStates,
          [quizId]: {
            ...state[quizId],
            loading: true,
            error: ""
          }
        };
      });

      return Object.assign({}, state, {
        ...newRequestedQuizStates
      });
    case actionTypes.RECEIVE_QUIZZES:
      let newReceivedQuizStates = {};

      if (!action.data) {
        return state;
      }
      action.data.forEach(quiz => {
        newReceivedQuizStates = {
          ...newReceivedQuizStates,
          [quiz._id]: {
            ...state[quiz._id],
            loading: false,
            data: quiz,
            receivedAt: action.receivedAt,
            ...expiryDate
          }
        };
      });

      return Object.assign({}, state, {
        ...newReceivedQuizStates
      });
    case actionTypes.FETCH_QUIZ_FAILURE:
      return Object.assign({}, state, {
        [action.quizId]: {
          ...state[action.quizId],
          loading: false,
          error: action.error
        }
      });
    case actionTypes.FETCH_STRIPPED_QUIZZES_FAILURE:
      let newStrippedQuizzesStates = {};

      action.quizIds.forEach(quizId => {
        newStrippedQuizzesStates = {
          ...newStrippedQuizzesStates,
          [quizId]: {
            loading: false,
            error: action.error
          }
        };
      });

      return Object.assign({}, state, {
        ...newStrippedQuizzesStates
      });
    case actionTypes.INIT_QUIZZES:
      return { initializing: true, error: "" };
    /*       return Object.assign({}, state, {
          initializing: true
        }) */
    case actionTypes.INIT_QUIZZES_DONE:
      return Object.assign({}, state, {
        ...state,
        initializing: false,
        error: ""
      });
    case actionTypes.INIT_QUIZZES_ERROR:
      return Object.assign({}, state, {
        initializing: false,
        error: action.error
      });
    default:
      return state;
  }
}
