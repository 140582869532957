import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  background-color: #f6f4f4;
  max-width: 700px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 1.5rem 0.5rem;
  > div:first-of-type {
    order: 1;
    display: flex;
    > :first-child {
      margin-right: 1rem;
    }
    h6 {
      margin: auto;
      color: #000000;
      font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
      font-size: 1.25rem;
      font-weight: bold;
    }
    .answered {
      color: #000000;
    }
    .rejected {
      color: #000000;
    }
  }
  > div:nth-of-type(2) {
    order: 3;
    margin-top: 2rem;
    > p:nth-of-type(1) {
      display: none;
    }
    > h6:nth-of-type(2) {
      font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
      font-size: 2.25rem;
    }
  }
  > div:nth-of-type(3) {
    display: none;
    order: 2;
    align-self: flex-end;
    margin-top: -44px;
    div {
      font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
    }
    > div:first-of-type {
      line-height: 1;
    }
  }
  h2 {
    font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
    font-size: 2.25rem;
    font-weight: normal;
  }

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    padding: 0.5rem 1.5rem;
  }
`;
