import * as React from "react";
import styled from "styled-components";
import rightIcon from "../../images/right-icon.svg";
import wrongIcon from "../../images/wrong-icon.svg";

export default props => {
  const Button = styled.button`
    display: flex;
    color: #000000;
    background: ${props =>
      props.revealed
        ? props.buttonState === "selected-correct"
          ? "#4EDEBE"
          : props.buttonState === "selected-wrong"
            ? "#FFB5C8"
            : props.buttonState === "not-selected-correct"
              ? "#4EDEBE"
              : "#FFB5C8"
        : props.buttonState === "selected"
          ? "#E1DEFD"
          : "#ffffff"};
    border: ${props =>
      props.buttonState === "selected"
        ? "2px solid #000000"
        : props.buttonState === "selected-correct"
          ? "2px solid #000000"
          : props.buttonState === "selected-wrong"
            ? "2px solid #000000"
            : props.buttonState === "not-selected-correct"
              ? "none"
              : "none"};
    border-radius: 2rem;
    font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
    ${props =>
      !props.revealed && "box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"}
    outline: none;
    padding: 0.6rem 0.75rem;
    font-size: 0.875rem;
    margin-right: 8px;
    /* max-height: 3rem; */
    max-height: 2.5rem;
    @media screen and (min-width: 72em) {
      font-size: 0.95rem;
      margin-bottom: 6px;
      padding: 0.85rem 1.5rem;
      max-height: 3rem;
      margin-right: 12px;
    }
    :hover {
      cursor: pointer;
    }
    ::after {
      content: "";
      position: relative;
      bottom: 8px;
      ${props =>
      props.revealed &&
      `
      background: ${props.buttonState === "selected-correct"
        ? `url(${rightIcon})`
        : props.buttonState === "selected-wrong"
          ? `url(${wrongIcon})`
          : props.buttonState === "not-selected-correct"
            ? `url(${rightIcon})`
            : props.buttonState !== "selected" && `url(${wrongIcon})`
      };
      margin-bottom: -8px;
      height: 28px;
      max-height: 2.25rem;
      `}
    }
  `;

  const buttonState = props.selected
    ? props.revealed
      ? props.correct
        ? "selected-correct"
        : "selected-wrong"
      : "selected"
    : props.correct
      ? "not-selected-correct"
      : undefined;

  return <Button buttonState={buttonState} {...props} />;
};
