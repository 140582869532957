//Certain greek letters are showing outside of the unicode-range when strong
//Therefore, the wrong font is loading
//No great way to pass the language key into this component (?)
//Short-term solution, get lang based on URL for Greek.

const url = typeof window !== "undefined" ? window.location.href : '';
let splitUrl = url.split("/")
let splitDomain = url.split(".")

export default {
  topInfoBar: `
        color: #29264c;
        background-color: #f6f4f4;
        max-width: 700px;
        flex-direction: column;
        align-items: flex-start;
        margin-left: auto;
        margin-right: auto;
        padding: 1.5rem 0 0.5rem;
        > div:first-of-type {
          order: 1;
          display: flex;
          > :first-child {
            margin-right: 1rem;
          }
          h6 {
            margin: auto;
            color: #29264c80;
            font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
            font-size: 1.25rem;
            font-weight: normal;
          }
          .answered {
            color: #19ca94;
          }
          .rejected {
            color: #FF556E;
          }
        }
        > div:nth-of-type(2) {
          order: 3;
          margin-top: 2rem;
          > h6:nth-of-type(1) {
            display: none;
          }
          > h6:nth-of-type(2) {
            font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
            font-weight: normal;
            font-size: 2.25rem;
          }
        }
        > div:nth-of-type(3) {
          order: 2;
          align-self: flex-end;
          margin-top: -44px;
          div {
            font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
            font-weight: normal;
          }
          > div:first-of-type {
            line-height: 1;
          }
        }
        h5 {
          font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
          font-size: 2.25rem;
          font-weight: normal;      
        }

      `,
  mainDiv: `
        padding-top: 5rem;
        padding-bottom: 0;
        background-color: #f6f4f4;
        @font-face {
          font-family: "tt-norms-pro-bold";
          src: url(/int-fonts/TT-Norms-Pro-Bold.ttf) format("truetype");
          src: url(/int-fonts/TT-Norms-Pro-Bold.woff2) format("woff2"),
            url(/int-fonts/TT-Norms-Pro-Bold.woff) format("woff");
          font-weight: normal;
          font-style: normal;
        }
      
        @font-face {
          font-family: "tt-norms-pro-light";
          src: url(/int-fonts/TT-Norms-Pro-Light.ttf) format("truetype");
          src: url(/int-fonts/TT-Norms-Pro-Light.woff2) format("woff2"),
            url(/int-fonts/TT-Norms-Pro-Light.woff) format("woff");
          font-weight: normal;
          font-style: normal;
        }
      
        @font-face {
          font-family: "tt-norms-pro-regular";
          src: url(/int-fonts/TT-Norms-Pro-Regular.ttf) format("truetype");
          src: url(/int-fonts/TT-Norms-Pro-Regular.woff2) format("woff2"),
            url(/int-fonts/TT-Norms-Pro-Regular.woff) format("woff");
          font-weight: normal;
          font-style: normal;
        }
        @font-face {
          font-family: "alkes-regular";
          src: url(/int-fonts/Alkes-Regular.otf) format("opentype");
          src: url(/int-fonts/Alkes-Regular.woff2) format("woff2"),
            url(/int-fonts/Alkes-Regular.woff) format("woff");
          font-weight: 400;
          font-style: normal;
          font-feature-settings: "ss01", "ss02";
        }
      
        @font-face {
          font-family: "alkes-semibold";
          src: url(/int-fonts/Alkes-Semibold.otf) format("opentype");
            src: url(/int-fonts/Alkes-Semibold.woff2) format("woff2"),
            url(/int-fonts/Alkes-Semibold.woff) format("woff");
          font-weight: 400;
          font-style: normal;
          font-feature-settings: "ss01", "ss02";
        }

        @font-face {
          font-family: "tt-norms-pro-bold";
          src: url(/int-fonts/TT-Norms-Pro-Bold.ttf) format("truetype");
          src: url(/int-fonts/TT-Norms-Pro-Bold.woff2) format("woff2"),
            url(/int-fonts/TT-Norms-Pro-Bold.woff) format("woff");
          font-weight: normal;
          font-style: normal;
          unicode-range:U+03??;
        }
      
        @font-face {
          font-family: "tt-norms-pro-light";
          src: url(/int-fonts/TT-Norms-Pro-Light.ttf) format("truetype");
          src: url(/int-fonts/TT-Norms-Pro-Light.woff2) format("woff2"),
            url(/int-fonts/TT-Norms-Pro-Light.woff) format("woff");
          font-weight: normal;
          font-style: normal;
          unicode-range:U+03??;
        }
      
        @font-face {
          font-family:  "tt-norms-pro-regular";
          src: url(/int-fonts/TT-Norms-Pro-Regular.ttf) format("truetype");
          src: url(/int-fonts/TT-Norms-Pro-Regular.woff2) format("woff2"),
            url(/int-fonts/TT-Norms-Pro-Regular.woff) format("woff");
          font-weight: normal;
          font-style: normal;
          unicode-range:U+03??;
        }
        @font-face {
          font-family:  "alkes-regular";
          src: url(/int-fonts/Alkes-Regular.otf) format("opentype");
          src: url(/int-fonts/Alkes-Regular.woff2) format("woff2"),
            url(/int-fonts/Alkes-Regular.woff) format("woff");
          font-weight: 400;
          font-style: normal;
          font-feature-settings: "ss01", "ss02";
          unicode-range:U+03??;
        }
      
        @font-face {
          font-family:  "alkes-semibold";
          src: url(/int-fonts/Alkes-Semibold.otf) format("opentype");
            src: url(/int-fonts/Alkes-Semibold.woff2) format("woff2"),
            url(/int-fonts/Alkes-Semibold.woff) format("woff");
          font-weight: 400;
          font-style: normal;
          font-feature-settings: "ss01", "ss02";
          unicode-range:U+03??;
        }
      
        body {
          font-family: "tiempos-regular", "alkes-regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        #quiz-type-label {
          display: none;
        }
      `,
  itemWrapper: `
        background-color: #ebe9ef;
      `,
  optionContainer: `
        flex-direction: row;
        justify-content: center;
        margin-top: 2rem;
        margin-left: -1rem:
    `,
  optionWrapper: `
        background-color: #ebe9ef;
        margin-top: 0.4rem;
        margin-left: 1rem;
      `,
  multipleChoiceItemContent: `
        max-width: 1450px;
        margin: auto;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        > div:first-of-type {
          margin-right: 1rem;
          > div {
            p {
              font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
              color: #29264c;
            }
            p:first-of-type {
              color: #29264c;
              font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
              font-size: 1.25rem;
              font-weight: normal;
              letter-spacing: normal;
              line-height: 1.5;
              padding-bottom: 0;
            }
        }
          div:nth-of-type(2) {
            div:first-of-type {
              margin-left: 0;  
            }   
          }
        }
      `,
  narrowOpenItemContent: `
        max-width: 750px;
        margin: auto;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        .openAnswered {
          > :nth-child(1) {
            font-size: 1.25rem;
          }
          > :nth-child(2) {
            display: none;
          }
          > :nth-child(3) {
            font-size: 1.25rem;
            font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
            font-weight: normal;
          }
          > :nth-child(4) {
            p {
              font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
            }
            margin: 0.5rem 0 0 0;
          }
          > :nth-child(5) {
          }
          .MuiPaper-root {
            border-radius: 0px;
            box-shadow: none;
            background-color: inherit;
          }
        }
        .MuiFormControl-root {
          border-radius: 0.5rem;
          margin-top: 0;
          background-color: white;
        }
        .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
          border-style: solid;
          border-color: #4844a3;
          border-radius: 0.5rem;
          border-image: initial;
          outline: none;
        }
      `,
  wideOpenItemContent: `
      max-width: 1400px;
      margin: auto;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      .open {
        display: flex;
        justify-content: space-between;
        > div:nth-child(1) {
          width: 300px;
        }
        > div:nth-child(2) {
          min-width: 2rem;
        }
        > div:nth-child(3) {
          width: 200px;
        }
        @media screen and (max-width: 600px) {
          display: flex;
          flex-direction: column;
          > div {
            width: 100%;
          }
          > div:nth-child(3) {
            width: 100%;
          }
          > div:nth-child(2) {
            display: none;
          }
        }
      }
      .openAnswered {
        > :nth-child(1) {
          font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
          font-size: 1.25rem;
          font-weight: normal;
        }
        > :nth-child(2) {
          display: none;
        }
        > :nth-child(3) {
          display: inline-block;
          font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
        }
        > :nth-child(4) {
          display: inline-block;
          margin: 0 0.5rem;
          padding: 0 1.5rem;
          p {
            font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
          }
        }
        > :nth-child(5) {
        }
        .MuiPaper-root {
          border-radius: 0px;
          box-shadow: none;
          background-color: inherit;
        }
      }
      .MuiFormControl-root {
        border-radius: 0.5rem;
        height: 45px;
        margin-top: 0;
        background-color: white;
      }
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-style: solid;
        border-color: #4844a3;
        border-radius: 0.5rem;
        border-image: initial;
        outline: none;
        height: 48.5px;
      }
      .MuiInputLabel-formControl {
        top: -6px;
      }
      .MuiOutlinedInput-input {
        padding: 11.5px 14px;
      }
    `,
  essayItemContent: `
        max-width: 700px;
        margin: auto;
        padding: 0 1.5rem;
        > :first-child {
          font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
          font-weight: normal;
          font-size: 1.25rem;
        }
        > :nth-child(3) {
          p:first-of-type {
            font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
            font-weight: normal;
            font-size: 1.25rem;
          }
          p:last-of-type {
            font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
            font-size: 1rem;
          }
        }
        h6 {
          font-size: 1.25rem;
        }
      `,
  upperContent: `
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem;
        p {
          padding: 0;
          font-family: ${splitUrl[3] === "el" || splitDomain[2] === "gr" ? "tt-norms-pro-regular" : "walsheim-regular"};          
          font-size: 1.125rem;
          letter-spacing: normal;
          line-height: 1.7;
        }
        strong {
          font-family: ${splitUrl[3] === "el" || splitDomain[2] === "gr" ? "tt-norms-pro-bold" : "walsheim-bold"};
          font-weight: normal;
          font-size: 1.125rem;      
          line-height: 1.7;
        }
      `,
  submitMessageDiv: `
      margin: 1rem 0 5rem 0;
      padding: 0;
      border: 0;
      p {
        font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
        font-size: 1.125rem;
        margin: 1rem 0px !important;
      }
      > p:first-of-type {
        font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
        font-weight: normal;
      }
      div:first-of-type {
        border-left: 6px solid #19CA94;
        padding: 0.25rem 0 0 1rem;
      }
    `,
  answerPaper: `
      margin-bottom: 1rem;
      margin-top: 0.75rem;
      background-color: #ffffffbf;
      white-space: pre-wrap;
      font-size: 1rem;
      font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
      padding: 2rem;
      border-radius: 8px;
    `,
  answerField: `
    margin: 1.5rem 0 2rem;
    .MuiInputBase-root  {
      font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
    }
    .MuiOutlinedInput-multiline {
      border-radius: 0.5rem;
    }
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-style: solid;
      border-color: #4844a3;
      border-radius: 0.5rem;
      border-image: initial;
      outline: none;
    }
  `,
  quizBody: `
    img {
      width: 100%;
    }
    p {
      margin: 1rem 0 !important;
    }
  `,
  stepper: `
    display: none;
    background-color: #f6f4f4;
    padding: 24px 0px;
    .MuiStepIcon-active {
      color: #4844a3;
    }
  `,
  submitGroup: `
    p {
      display: none;
    }
  `,
  messageGroup: `
    
  `,
  questionBlockWrapper: `
    > :nth-child(odd) {
      .likert-react_src-___styles__container-striped {
        padding: 1rem;
        flex-direction: column;
        font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
        .likert-react_src-review-scale-___styles__scale {
          margin: 0.5rem 0 0 0.3rem;
        }
        .likert-react_src-___styles__question {
          max-width: 100%;
        }
        @media screen and (min-width: 32em) {
          .likert-react_src-___styles__question {
            max-width: 60%;
          }
          .likert-react_src-review-scale-___styles__scale {
            margin: 0 0 0 0.3rem;
          }
          flex-direction: row;
        }
      }
      .likert-react_src-___styles__container-striped:nth-child(odd) {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
    > :nth-child(even) {
      .likert-react_src-___styles__container-striped {
        padding: 1rem;
        flex-direction: column;
        font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
        .likert-react_src-review-scale-___styles__scale {
          margin: 0.5rem 0 0 0.3rem;
        }
        .likert-react_src-___styles__question {
          max-width: 100%;
        }
        @media screen and (min-width: 32em) {
          .likert-react_src-___styles__question {
            max-width: 60%;
          }
          .likert-react_src-review-scale-___styles__scale {
            margin: 0 0 0 0.3rem;
          }
          flex-direction: row;
        }
      }
      .likert-react_src-___styles__container-striped:nth-child(odd) {
        background-color: rgba(255, 255, 255, 0.75);
      }
    }
  `,
  buttonWrapper: `
    margin-bottom: 3rem;
  `,
  peerReviewContainer: `
    > p:first-of-type {
      font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
      font-weight: normal;
    }
    > button:first-of-type {
      font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
      font-weight: normal;
    }
  `,
  peerReviewGuidance: `
    > p:first-of-type {
      font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
      font-weight: normal;
    }
    > p:last-of-type {
      font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
    }
  `,
  peerReviewForm: `
    p {
      font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
    }
    > div > p:first-of-type {
      font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
      font-weight: normal;
    }
`,
  receivedPeerReviews: `
    p {
      font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
      font-weight: normal;
    }
    p:nth-of-type(2) {
      font-family:  walsheim-regular, tt-norms-pro-regular, sans-serif;
    }
    button {
      font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
      font-weight: normal;
    }
  `
};
