import * as React from "react";
import styled from "styled-components";
import ExerciseAnsweredIcon2 from "../../images/exercise-answered2.svg";
import ExerciseRejectedIcon2 from "../../images/exercise-rejected2.svg";

//Certain greek letters are showing outside of the unicode-range when strong
//Therefore, the wrong font is loading
//No great way to pass the language key into this component (?)
//Short-term solution, get lang based on URL for Greek.

const url = typeof window !== "undefined" ? window.location.href : '';
let splitUrl = url.split("/")
let splitDomain = url.split(".")

const Container = styled.div`
  > div:first-of-type {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    img {
      height: 2.75rem;
      width: 2.75rem;
      margin: 0.25rem 0.75rem 0 0;
      color: #000000;
    }
    > p {
      font-size: 1.25rem;
      font-family: walsheim-bold, tt-norms-pro-bold, sans-serif;
      margin: 0;
    }
  }
  > div:nth-of-type(2) {
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin: 0.5rem 0 !important;
    p:nth-of-type(1) {
      font-size: 1rem;
      font-family: ${splitUrl[3] === "el" || splitDomain[2] === "gr" ? "tt-norms-pro-regular" : "walsheim-regular"};
    }
    p:nth-of-type(2) {
      font-size: 1rem;
      font-family: ${splitUrl[3] === "el" || splitDomain[2] === "gr" ? "tt-norms-pro-regular" : "walsheim-regular"};
      line-height: 1.5;
      color: #29264c;
      font-weight: normal;
      margin-top: -0.25rem;
      padding: 0 0 0 0.5rem;
    }
    border-left: 4px solid ${props => (props.correct ? "#68DDB9" : "#FFB5C8")};
  }
`;

export default props => {
  return (
    <Container correct={props.correct}>
      <div>
        <img
          src={props.correct ? ExerciseAnsweredIcon2 : ExerciseRejectedIcon2}
          alt=""
        />
        <p>{props.message}</p>
      </div>
      <div>{props.children}</div>
    </Container>
  );
};
